import {useQuery} from "react-query";
import {facilityApi} from "../../../services/FacilityService";
import {getAuthorizationHeader} from "../../../models/admin-session/selectors";

type Params = {
  facilityId: string;
};

export const usePccFacilityMappingQuery = ({facilityId}: Params) =>
  useQuery(
    ["getPccFacilityMapping", facilityId],
    async ({queryKey: [_, facilityId]}) => {
      const {data} = await facilityApi.getFacilityMappingUsingGET(
        facilityId,
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      cacheTime: 0,
    },
  );
