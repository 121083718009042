import React from "react";
import {flatten} from "lodash";

import {ConnectedReportingBlock} from "../../atoms/reporting-block";
import {
  LIST_OF_RESIDENTS_REPORT_ID,
  ReportListOfResidents,
  PHOTOS_REPORT_ID,
  ReportPhotos,
  ARBITRATION_AGREEMENT_REPORT_ID,
  ReportArbitrationAgreement,
} from "./reports";
import {RequiredUserPermissionsSome} from "../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {ReportingGroup} from "../../atoms/admin-reporting/atoms/reporting-group/ReportingGroup";
import {DocumentTitle} from "../../atoms/document-title";
import {ReportingLayout} from "./reporting-layout";
import {ReportContextConsumer} from "./ReportContext";
import {getSectionItemsPermissions} from "./permissions.helpers";
import {
  reportingOverview,
  reportingCompletionTime,
  reportingPhotoPayerSourceId,
  reportingOverviewSection,
  ChartTypes,
} from "./constants";
import {
  TIME_TO_CLOSE_BY_FACILITY_CHART_ID,
  AnalyticsTimeToCloseByFacility,
} from "./analytics";
import {Banner} from "../../atoms/banner";

const pagePermissions = getSectionItemsPermissions(
  flatten(
    [
      reportingOverview,
      reportingCompletionTime,
      reportingPhotoPayerSourceId,
      reportingOverviewSection,
    ].map(section => section.sectionItems),
  ),
);

// TODO(reports/single-source-of-truth): create list struct with reports, export it and render components with Array.map
export const PageReportingGeneral = () => (
  <>
    <ReportingLayout>
      <DocumentTitle title={"Reside - Admin - Reporting | General"} />
      <RequiredUserPermissionsSome
        permissions={pagePermissions}
        noPermissionChildren={
          <Banner title="No reports are available for this section.">
            <p>Please choose a different report from the left menu.</p>
          </Banner>
        }
      >
        <ReportContextConsumer>
          {({baseDateRange, activeFacilitiesIds}) => (
            <>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportingOverview.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportingOverview.id}
                  title={reportingOverview.title}
                >
                  <ConnectedReportingBlock
                    chartId={LIST_OF_RESIDENTS_REPORT_ID}
                  >
                    {reportingBlockSetters => (
                      <ReportListOfResidents
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportingCompletionTime.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportingCompletionTime.id}
                  title={reportingCompletionTime.title}
                >
                  <ConnectedReportingBlock
                    chartId={TIME_TO_CLOSE_BY_FACILITY_CHART_ID}
                    chartType={ChartTypes.analytics}
                  >
                    {reportingBlockSetters => (
                      <AnalyticsTimeToCloseByFacility
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportingPhotoPayerSourceId.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportingPhotoPayerSourceId.id}
                  title={reportingPhotoPayerSourceId.title}
                >
                  <ConnectedReportingBlock chartId={PHOTOS_REPORT_ID}>
                    {reportingBlockSetters => (
                      <ReportPhotos
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportingOverviewSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportingOverviewSection.id}
                  title={reportingOverviewSection.title}
                >
                  <ConnectedReportingBlock
                    chartId={ARBITRATION_AGREEMENT_REPORT_ID}
                  >
                    {reportingBlockSetters => (
                      <ReportArbitrationAgreement
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
            </>
          )}
        </ReportContextConsumer>
      </RequiredUserPermissionsSome>
    </ReportingLayout>
  </>
);
