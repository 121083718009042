import {FlexRow, styled, Tag} from "@reside/ui";

export const FacilitySettingsDropdownWrapper = styled.span`
  .rc-tree-node-content-wrapper {
    align-items: center;
  }

  .rc-tree-icon__customize {
    order: 2;
    margin-left: 10px;
  }

  .rc-tree-title {
    order: 1;
    width: 200px;
  }
`;
export const Header = styled(FlexRow)`
  margin-bottom: 24px;
  align-items: center;
`;

export const PccTag = styled(Tag)<{isVisible: boolean}>`
  min-width: 80px;
  visibility: ${({isVisible}) => (isVisible ? "visible" : "hidden")};
`;
