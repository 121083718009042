import React from "react";
import {DropdownMenu, DropdownMenuLink} from "@reside/ui";
import {AdministrationMenuDropdownButton} from "./AdministrationMenuDropdownButton";
import {getAdministrationMenuTabs} from "./constants";
import {AdministrationMenuProps} from "../types";

/** Administration-related tabs. */
export const AdministrationMenu = ({
  role,
  firstFacilityId,
}: AdministrationMenuProps): JSX.Element => {
  const administrationMenuTabs = getAdministrationMenuTabs(firstFacilityId);

  return (
    <DropdownMenu
      trigger={(open: boolean) => (
        <AdministrationMenuDropdownButton open={open} />
      )}
    >
      {administrationMenuTabs
        .filter(tab => tab.roles.includes(role))
        .map(tab => {
          return (
            <DropdownMenuLink key={tab.id} to={tab.link} data-test-id={tab.id}>
              {tab.name}
            </DropdownMenuLink>
          );
        })}
    </DropdownMenu>
  );
};
