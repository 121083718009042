import React, {memo} from "react";
import {get} from "lodash";
import {TableUserDto} from "@reside/reside-api-app";

import {
  AggregationType,
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  ChartProps,
  Props,
  MapNames,
  AnalyticsDataStateToBarStack,
  createDataToBarStack,
  withCompare,
  useOnDataPathChange,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

export const mapStatusNames: MapNames = name => {
  switch (name) {
    case "COMPLETED_ON_TIME":
      return "Completed On time";

    case "COMPLETED_LATE":
      return "Completed Late";

    default:
      return "Unknown";
  }
};

export const mapRate = (value: number): string => {
  return (value / 3600).toFixed(2);
};

const dataProcess_AnalyticsAdmissionsCompletionOnTime: AnalyticsDataStateToBarStack =
  createDataToBarStack(
    AggregationType.SUM,
    AggregationType.SUM,
    mapStatusNames,
    (x: number) => `${x}`,
    true,
  );

const AnalyticsAdmissionsCompletionOnTimeChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const {stack, max} = dataProcess_AnalyticsAdmissionsCompletionOnTime(data);

    return (
      <BarChart
        data={stack}
        max={max}
        labelLeft="Completion Status"
        labelRight="Value"
        full={isFullReport}
        leftSortCustomMap={{
          "Completed On time": 1,
          "Completed Late": 0,
        }}
        defaultSortColumn="left"
        tickCount={max < 5 ? 2 : undefined}
        axTickFormat={x => `${Math.floor(x)}`}
        hoverDataPreprocessor={(values, line, index) => {
          const sum = get(
            line.original[index].aggregations,
            AggregationType.SUM,
            0,
          );

          return [`${sum}/${max}`];
        }}
      />
    );
  },
);

export const ADMISSIONS_COMPLETION_ON_TIME_CHART_ID =
  "completion-on-time" as const;

export const AnalyticsAdmissionsCompletionOnTime = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.COMPLETION_TIME}
  >
    <div className="chart-header">
      <CardTitle
        title="Admission Completion On Time"
        subtitle="by Admissions"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.admissionsCompletionOnTime,
        facilities: props.facilities,
        query: {...props.dateRange},
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsAdmissionsCompletionOnTimeChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
