import React, {useState, useCallback, FormEvent} from "react";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import {connect} from "react-redux";
import {RoleDto} from "@reside/reside-api-app";

import {GeneralSearch} from "../search-box";
import {readableRole} from "../list-table/helpers";

import {AppState, select} from "../../store";
import {UserService} from "../../services/UserService";

const AdminUsersSearchRenderer = ({
  onSelect,
  visibleRoles,
}: {
  visibleRoles: ReadonlyArray<RoleDto.NameEnum>;
  onSelect?: (user: any) => void;
}) => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const {data = [], isLoading} = useQuery(
    ["searchUsers", searchQuery],
    async ({queryKey: [_, searchQuery]}) => {
      const {data} = await UserService.search(searchQuery, visibleRoles);

      return data;
    },
    {enabled: searchQuery.length > 1},
  );

  const handleSelect = useCallback(
    user => {
      if (onSelect) {
        onSelect(data.find(item => item.id === user.id));
      } else {
        history.push(`/admin/users/${user.id}/edit/detail`);
      }
    },
    [history, onSelect, data],
  );

  const reduceItems = () => [
    {
      label: "User",
      data: data.map(user => ({
        id: user.id,
        value: `${user.firstName} ${user.lastName}`,
        description: readableRole(user.role),
      })),
    },
  ];

  return (
    <GeneralSearch
      placeholder="Search"
      items={reduceItems()}
      isSearching={isLoading}
      inputValue={searchQuery}
      onChange={handleSelect}
      onInputChange={event => {
        setSearchQuery(event.target.value);
      }}
      onPressSpace={(event: FormEvent<HTMLInputElement>) => {
        if (searchQuery.trim().length > 0) setSearchQuery(searchQuery + " ");
      }}
      itemToString={item => item?.value || ""}
    />
  );
};

const mapState = (state: AppState) => ({
  visibleRoles: select.adminSession.visibleRoles(state),
});

export const AdminUsersSearch = connect(mapState)(AdminUsersSearchRenderer);
