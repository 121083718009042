import {AdminActionRow} from "../../atoms/admin-action-row";
import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {ButtonLink} from "../../atoms/button-link";
import {DocumentTitle} from "../../atoms/document-title";
import {FacilityDropdownTreeSelect} from "../../atoms/facility-dropdown-tree-select";
import {ResidentStatusSelector} from "./atoms/ResidentStatusSelector";
import {ResidentsListTable} from "./atoms/ResidentsListTable";
import {SearchResidentsField} from "./atoms/SearchResidentsField";
import {SearchResidentsContextProvider} from "./context/useFilterResidentsContext";

/**
 * Renders a list table of all residents filterable by facilities and/or a search term.
 */
export const PageAdminResidents = (): JSX.Element => {
  return (
    <AdminLayoutFullHeightAndTwoCells>
      <DocumentTitle title="Reside - Admin - Residents" />
      <SearchResidentsContextProvider>
        <AdminActionRow
          facilitySelector={<FacilityDropdownTreeSelect />}
          search={<SearchResidentsField />}
          filter={<ResidentStatusSelector />}
          actions={
            <ButtonLink
              outline={false}
              to="/admin/residents/create"
              color="primary"
            >
              New Resident
            </ButtonLink>
          }
        />
        <ResidentsListTable />
      </SearchResidentsContextProvider>
    </AdminLayoutFullHeightAndTwoCells>
  );
};
