import React, {useState} from "react";
import {Formik} from "formik";
import {
  styled,
  Button,
  BlockField,
  Paragraph,
  Text,
  H1,
  ModalContent,
  ModalFooterButtons,
  SlideBlock,
  ExternalLink,
} from "@reside/ui";
import {Blocks} from "@reside/forms";
import {useTimeoutFn} from "react-use";

import {getPdfUrl} from "../../../utils/url";
import {Footer} from "../../footer";
import {Column} from "../block-slide/BlockSlide";
import {Draft} from "../model";

type Props = Readonly<{
  draft: Draft;
  isSigning: boolean;
  onRequestClose: () => void;
  signAdmission: () => void;
  signOverlay: SlideBlock;
}>;

export const SignOverlay = ({
  draft,
  isSigning,
  onRequestClose,
  signAdmission,
  signOverlay,
}: Props) => {
  const [previewDisabled, setPreviewDisabled] = useState(false);
  const [, , reset] = useTimeoutFn(() => {
    setPreviewDisabled(false);
  }, 5000);

  const disablePreview = async () => {
    setPreviewDisabled(true);
    reset();
  };

  return (
    <>
      <FullHeightColumn>
        <ModalContent>
          {/**
           * Note: dynamic form rendering requires formik context. Here we don't have real form, but it might appear, so we provide empty formik.
           */}
          <Formik initialValues={{}} onSubmit={() => {}}>
            <>
              {signOverlay
                ? signOverlay.children.map((block: Blocks) => (
                    <BlockField key={block.id} block={block} />
                  ))
                : renderDefaultOverlay()}
            </>
          </Formik>
          <Text fontSize={[3, 4]}>
            <ExternalLink
              disabled={previewDisabled}
              onClick={disablePreview}
              href={getPdfUrl(draft.instance.id)}
            >
              Preview Admission in PDF.
            </ExternalLink>
          </Text>
        </ModalContent>
      </FullHeightColumn>
      <ModalFooterButtons>
        <Footer
          hasShadow
          left={
            <Button
              testId="sign-refuse-button"
              onClick={onRequestClose}
              outline
              size="large"
            >
              Close
            </Button>
          }
          right={
            <Button
              testId="sign-confirm-button"
              color="primary"
              disabled={isSigning || previewDisabled}
              onClick={signAdmission}
              size="large"
            >
              {isSigning ? "Signing..." : "Confirm"}
            </Button>
          }
        />
      </ModalFooterButtons>
    </>
  );
};

const renderDefaultOverlay = () => (
  <>
    <H1 fontSize={[5, 6, 7, 8]} fontWeight="bold">
      Sign Admission
    </H1>
    <Paragraph fontSize={[3, 4]}>
      You are about to SIGN this Admission. This action cannot be undone - once
      the Admission is signed, our Facility Representative will review its
      content and come back to you with potential issues. Once the content of
      the Admission is correct, it will be approved and will become a valid
      contract between the Facility and the Resident.
    </Paragraph>
    <Paragraph fontSize={[3, 4]}>
      Please confirm that you are sure about this action.
    </Paragraph>
  </>
);

const FullHeightColumn = styled(Column)`
  height: 100%;
`;
