import {groupBy, sortBy} from "lodash";
import {SimpleFacilityDto} from "@reside/reside-api-app";

export const createFacilitiesTree = (
  facilities: ReadonlyArray<SimpleFacilityDto>,
) =>
  sortBy(
    Object.entries(
      groupBy(facilities, ({organization}) => organization.id),
    ).map(([organizationId, facilities]) => ({
      key: organizationId,
      title: facilities[0].organization.name,
      children: sortBy(
        facilities.map(({id, name, pccIntegrationStatus}) => ({
          key: id,
          title: name,
          pccIntegrationStatus,
        })),
        "title",
      ),
    })),
    "title",
  );
