import React from "react";

import {AdmissionFlag} from "@reside/reside-api-admission";
import {FlagItem} from "./";

type Props = Readonly<{
  flags: ReadonlyArray<AdmissionFlag>;
  onFlagItemClicked: (flagId: string, slideId: string) => void;
}>;

export const FlagList = ({flags, onFlagItemClicked}: Props) => (
  <>
    {flags.map(flag => (
      <FlagItem onClick={onFlagItemClicked} key={flag.id} flag={flag} />
    ))}
  </>
);

FlagList.defaultProps = {
  flags: [],
};
