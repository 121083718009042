import {styled} from "@reside/ui";
import {Layout, LayoutContent} from "../../atoms/admin-layout";

export const FullWidthDashboardHeader = styled.div`
  // The header is positioned 'over' the grid to prevent dropdowns/popups from the grid to overflow on top of it
  background: ${({theme}) => theme.color.darkBlue5};
  z-index: 997;
  padding: 25px ${({theme}) => theme.space[7]}px 14px
    ${({theme}) => theme.space[7]}px;

  @media (max-width: 1170px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const DashboardLayout = styled(Layout)`
  min-height: 100vh;
`;
export const FullWidthDashboardLayoutContent = styled(LayoutContent)`
  flex-grow: 1;
  max-width: none;
  padding-top: 0 !important;
  padding-left: ${({theme}) => theme.space[7]}px;
  padding-right: ${({theme}) => theme.space[7]}px;
`;
