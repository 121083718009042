import React, {useCallback, useEffect} from "react";
import {makeFileVariable} from "@reside/ui";
import Alert from "react-s-alert";
import {pickBy} from "lodash";
import {FileService} from "../../services/FileService";
import {usePointClickCareStatus} from "../../hooks/usePointClickCareStatus";
import {BackdropSpinner} from "../../atoms/spinner";
import {Banner} from "../../atoms/banner";
import {ErrorBoundary} from "../../atoms/error-boundary";
import {FacilitySettingsForm} from "./atoms/facility-settings-form";
import {FacilitySettingsHeader} from "./atoms/facility-settings-header";
import {FacilitySettingsService} from "../../services/FacilitySettingsService";
import {useFacilitySettingsQuery} from "./hooks/useFacilitySettingsQuery";
import {UserDetail} from "../../store/users/usersModel";
import {useTemplateVersionQuery} from "../../hooks/useTemplateVersionQuery";
import {useFacilitySettingsTemplateQuery} from "../../hooks/useTemplateQuery";
import {useFacilityDocumentUploadCategoriesQuery} from "./hooks";
import {Facilities} from "../../models/types/AdmissionForm";
import {useFacilitySettingsParams} from "./hooks/useFacilitySettingsParams";

export const PageBody = ({
  user,
  facilities,
}: {
  user: UserDetail;
  facilities: Facilities;
}) => {
  const {facilityId} = useFacilitySettingsParams();

  const {
    isFetching: isFetchingVariables,
    error: fetchSettingsError,
    data: variables,
  } = useFacilitySettingsQuery({
    facilityId,
  });

  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  const {data: documentUploadCategory, isFetching: isLoadingPccCategories} =
    useFacilityDocumentUploadCategoriesQuery(facilities);

  const {data: {version} = {}} = useTemplateVersionQuery({
    facilityId,
  });

  const {
    data: template,
    isLoading: isLoadingTemplate,
    error: templateError,
    remove,
  } = useFacilitySettingsTemplateQuery({
    versionId: version?.id,
  });

  useEffect(() => {
    return () => remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const uploadFilesAndSaveSettings = useCallback(
    async values => {
      const files: Record<string, File> = pickBy(
        values,
        value => value instanceof File,
      );

      try {
        const fileReferences = await Promise.all(
          Object.entries(files).map(async ([variableName, file]) => {
            const data = new FormData();

            data.append("file", file);
            data.append("name", variableName);
            data.append("fileType", "VARIABLE");

            const {data: fileReference} = await FileService.upload(data);

            return [variableName, makeFileVariable(fileReference)];
          }),
        );

        const finalValues = {...values, ...Object.fromEntries(fileReferences)};

        try {
          await FacilitySettingsService.updateSettings(facilityId, finalValues);
          Alert.success("Facility Settings were successfully updated!");

          return finalValues;
        } catch (error) {
          Alert.error(
            "Failed to upload the Facility Settings JSON. Please try again.",
          );
        }
      } catch (error) {
        Alert.error(
          error?.data?.message ??
            "Failed to upload some files in Facility Settings. Please try again.",
        );
      }

      return null;
    },
    [facilityId],
  );

  /**
   * We pass key to Form to re-mount tree when we navigate again to /facility-settings.
   * Otherwise the <Prompt /> catches the navigation *twice* (because the dirty form is still rendered).
   */
  const HACK_PROMPT_KEY = facilityId;

  return (
    <BackdropSpinner
      active={
        isFetchingVariables || isLoadingTemplate || isLoadingPccCategories
      }
    >
      {templateError ? (
        <>
          <FacilitySettingsHeader />
          {templateError.status === 404 ? (
            <Banner title="Template not found">
              <p>Facility is missing the JSON template.</p>
            </Banner>
          ) : (
            <Banner title="Failed to get the Template">
              <p>There was error while fetching the template.</p>
              <p style={{overflow: "hidden"}}>{templateError.data?.message}</p>
            </Banner>
          )}
        </>
      ) : template && variables ? (
        <ErrorBoundary>
          <FacilitySettingsForm
            key={HACK_PROMPT_KEY}
            initialValues={{
              ...(variables as any),
              userRole: user.role.name,
              isPccEnabled: isPccEnabled as any,
            }}
            header={<FacilitySettingsHeader />}
            template={template}
            templateNames={user.facilities.map(
              facility => facility.templateName,
            )}
            onSubmit={uploadFilesAndSaveSettings}
            isPccEnabled={isPccEnabled}
            queryVariables={{documentUploadCategory}}
          />
        </ErrorBoundary>
      ) : (
        <>
          <FacilitySettingsHeader />
          {fetchSettingsError && (
            <Banner title="Failed to fetch the settings.">
              <p>There was error while fetching the Facility Settings.</p>
            </Banner>
          )}
        </>
      )}
    </BackdropSpinner>
  );
};
