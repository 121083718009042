import {useQuery} from "react-query";

import {FacilityService} from "../../../services/FacilityService";

type Params = {organizationId: string};

export const useFacilitiesQuery = ({organizationId}: Params) =>
  useQuery(
    ["useFacilitiesQuery", organizationId],
    async ({queryKey: [_, organizationId]}) => {
      const {data} = await FacilityService.findByOrganization(organizationId);
      return data;
    },
    {
      enabled: Boolean(organizationId),
    },
  );
