import React, {HTMLProps} from "react";
import {styled, theme, Icon} from "@reside/ui";

import {
  AdmissionContextConsumer,
  useAdmissionContext,
} from "../../../AdmissionContext";

export const NavigationButtons = () => (
  <AdmissionContextConsumer>
    {({
      draft,
      currentSlide,
      uiState,
      uiActions: {goToPreviousSlide, goToNextSlide, pause},
    }) => (
      <NavigationButtonsRenderer
        onPrev={() => {
          goToPreviousSlide();
        }}
        prevDisabled={uiState.slideIdx === 0}
        onPause={() => {
          pause();
        }}
        pauseDisabled={draft.computed.readOnly}
        onNext={() => {
          goToNextSlide();
        }}
        nextDisabled={currentSlide.state.last}
      />
    )}
  </AdmissionContextConsumer>
);

type RendererProps = Readonly<{
  onNext: () => void;
  onPrev: () => void;
  onPause: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  pauseDisabled: boolean;
}>;

export const NavigationButtonsRenderer = ({
  onNext,
  onPrev,
  onPause,
  nextDisabled,
  prevDisabled,
  pauseDisabled,
}: RendererProps) => {
  const textSize = useAdmissionContext()?.uiState?.textSize || 1;

  return (
    <NavigationButtonsWrapper textSize={textSize}>
      <GoToPreviousSlideButton onClick={onPrev} disabled={prevDisabled} />
      <PauseButton onClick={onPause} disabled={pauseDisabled} />
      <GoToNextSlideButton onClick={onNext} disabled={nextDisabled} />
    </NavigationButtonsWrapper>
  );
};

NavigationButtonsRenderer.defaultProps = {
  nextDisabled: false,
  prevDisabled: false,
  pauseDisabled: false,
};

const GoToPreviousSlideButton = ({
  disabled,
  onClick,
}: HTMLProps<HTMLButtonElement>) => (
  <button
    type="button"
    id="go-to-previous-slide-button"
    disabled={disabled}
    onClick={onClick}
  >
    <Icon name="left" size={10} color={theme.color.darkBlue100} />
  </button>
);

const GoToNextSlideButton = ({
  disabled,
  onClick,
}: HTMLProps<HTMLButtonElement>) => (
  <button
    type="button"
    id="go-to-next-slide-button"
    disabled={disabled}
    onClick={onClick}
  >
    <Icon name="right" size={10} color={theme.color.darkBlue100} />
  </button>
);

const PauseButton = ({disabled, onClick}: HTMLProps<HTMLButtonElement>) => (
  <button
    type="button"
    id="pause-admission-button"
    onClick={onClick}
    disabled={disabled}
  >
    <Icon name="pause" size={10} color={theme.color.darkBlue100} />
  </button>
);

const NavigationButtonsWrapper = styled.div<{textSize: number}>`
  min-width: max-content;
  padding: 0 20px;

  button {
    width: Min(2.5rem, ${({textSize}) => 1.75 * textSize}rem);
    height: Min(2.5rem, ${({textSize}) => 1.75 * textSize}rem);
    padding: 0 0 1px 0;
    margin: 0 4px;
    border: 0;
    border-radius: 50%;
    background-color: ${({theme}) => theme.color.gray10};
    cursor: pointer;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
      filter: saturate(25%);
    }

    &:not([disabled]) {
      &:hover {
        color: ${({theme}) => theme.color.deepOrange100};
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
        i {
          color: ${({theme}) => theme.color.deepOrange100};
        }
      }
    }

    > i {
      font-size: ${({textSize}) => 0.625 * textSize}rem;
    }
  }
`;
