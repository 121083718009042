import update from "immutability-helper";
import {createModel} from "@rematch/core";
import {createSelector} from "reselect";
import {RoleDto, TableUserDto} from "@reside/reside-api-app";
import {mapValues} from "lodash";

import {DateRangePresets} from "../../atoms/reporting-compare";
import {createFacilitiesTree} from "./AdminSessionModel.helpers";
import {resetPermission} from "../../store/user-permissions/user-permissions.actions";
import {visibleRolesFor} from "../../utils/constants";
import {makeAuthorizationHeader} from "../../utils/token";
import {getFacilityFeatureFlags} from "../../services/FacilityService";

const INITIAL_STATE = {
  token: null,
  user: null,
  activeFacilities: {},
  activeDateRange: DateRangePresets[1], // TODO(RESIDE-1101): Remove when ReportContext is used everywhere
  facilities: [],
};

const getAdminSession = state => state.adminSession;

export const getSessionUser = createSelector(
  getAdminSession,
  session => session.user,
);

export const adminSessionModel = createModel({
  name: "adminSession",

  state: INITIAL_STATE,

  reducers: {
    reset() {
      return INITIAL_STATE;
    },
    resetPassword() {
      return INITIAL_STATE;
    },
    /**
     * Update token when user logged in or when refreshed token is set.
     * @param token
     * @param isLoginToken Flag whether we are doing login operation, or the token is set from refreshed token header
     */
    setToken(state, {token, isLoginToken}) {
      if (isLoginToken) {
        return update(state, {token: {$set: token}});
      } else {
        // Only if user is logged in we apply refreshed token
        // Otherwise we ignore the storing of refreshed tokens
        return state.user ? update(state, {token: {$set: token}}) : state;
      }
    },
    setUser(state, {user, isUserLogin}) {
      if (isUserLogin) {
        return update(state, {user: {$set: user}});
      } else {
        // Only if user is logged in we update the user
        // Otherwise we don't update the user
        return state.user ? update(state, {user: {$set: user}}) : state;
      }
    },
    setFacilities(state, payload) {
      return update(state, {
        facilities: {
          $set: payload,
        },
      });
    },
    setActiveFacilities(state, payload) {
      return update(state, {activeFacilities: {$set: payload}});
    },
    setActiveDateRange(state, payload) {
      return update(state, {
        activeDateRange: {
          id: {$set: payload.id},
          from: {$set: payload.from},
          to: {$set: payload.to},
          label: {$set: payload.label},
        },
      });
    },
  },

  selectors: (slice, createSelector) => ({
    getAdminSession,
    getToken: () => state => getAdminSession(state).token,
    getAuthorizationHeader() {
      return createSelector(this.getToken, makeAuthorizationHeader);
    },
    isLoggedIn() {
      return createSelector(this.getToken, token => !!token);
    },
    sessionStatus: () => state => getAdminSession(state).sessionStatus,
    user: () => state => getAdminSession(state)?.user,
    userRoleName() {
      return createSelector(this.user, user => user?.role.name);
    },
    isResideAdmin() {
      return createSelector(
        this.user,
        user => user?.role?.name === RoleDto.NameEnum.RESIDE_ADMIN,
      );
    },
    isOrgAdmin() {
      return createSelector(
        this.user,
        user => user?.role?.name === RoleDto.NameEnum.ORGANIZATION_ADMIN,
      );
    },
    organizationFeatureFlags() {
      return createSelector(
        this.user,
        user => user?.organization?.featureFlags,
      );
    },
    hasClearSlideHiddenFieldsFlag() {
      return createSelector(
        this.organizationFeatureFlags,
        featureFlags => featureFlags?.CLEAR_HIDDEN_FIELDS,
      );
    },
    isResideOrOrganizationAdmin() {
      return createSelector(
        this.user,
        user =>
          user?.role?.name === RoleDto.NameEnum.RESIDE_ADMIN ||
          user?.role?.name === RoleDto.NameEnum.ORGANIZATION_ADMIN,
      );
    },

    visibleRoles() {
      return createSelector(this.user, user => visibleRolesFor(user.role.name));
    },
    hasReportingAccess() {
      return createSelector(this.user, user =>
        user.permissions?.some(
          permission =>
            permission.type === "REPORTING" || permission.type === "ANALYTICS",
        ),
      );
    },
    hasQuicksightDashboardsPermission() {
      return createSelector(this.user, user =>
        user.permissions?.some(
          permission =>
            permission.name ===
            TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS,
        ),
      );
    },

    all() {
      return slice(state => ({
        facilities: state.facilities,
        activeFacilities: state.activeFacilities,
        activeDateRange: state.activeDateRange,
      }));
    },

    /** Ids of facilites selected in filters */
    activeFacilitiesTreeKeys() {
      return slice(state => state.activeFacilities.checkedKeys);
    },
    hasSelectedFacilities() {
      return slice(
        state =>
          !state.activeFacilities.checkedKeys ||
          state.activeFacilities.checkedKeys.length > 0,
      );
    },
    activeFacilitiesIds() {
      return slice(
        state =>
          state.activeFacilities.checkedLeafKeys ??
          state.facilities.map(facility => facility.id),
      );
    },
    facilities() {
      return slice(state => state.facilities);
    },
    firstFacility() {
      return createSelector(
        this.facilities,
        ([firstFacility] = []) => firstFacility,
      );
    },
    facilitiesTree() {
      return createSelector(this.facilities, facilities =>
        createFacilitiesTree(facilities),
      );
    },
    facilitiesById() {
      return createSelector(this.facilities, facilities =>
        Object.fromEntries(facilities.map(facility => [facility.id, facility])),
      );
    },
    facilityFlagsById() {
      return createSelector(this.facilitiesById, facilitiesById =>
        mapValues(facilitiesById, getFacilityFeatureFlags),
      );
    },
  }),

  effects: dispatch => ({
    async logout() {
      sessionStorage.clear();
      dispatch.adminSession.reset();
      dispatch.admissions.reset();
      dispatch.dashboard.reset();
      dispatch(resetPermission());
    },
  }),
});
