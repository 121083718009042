import React from "react";
import {Icon} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";

import {SORT_DIRECTION} from "../../../../utils/constants";
import {Column, ListTable} from "../../../../atoms/list-table";
import {TextCellEllipsis} from "../../../../atoms/list-table/SharedCells";
import {readableRole} from "../../../../atoms/list-table/helpers";
import "./ListTableUserPermissions.scss";

type Row = {
  rowData: TableUserDto;
  rowIndex: number;
};

type Props = {
  height: number;
  users: ReadonlyArray<TableUserDto>;
  onRowClick: (id: string, row: Row) => void;
  sortPredicates?: Record<string, string[]>;
  onSort: (sortBy: string[], direction: "ASC" | "DESC") => void;
};

export const ListTableUserPermissions = ({
  users,
  height,
  onRowClick,
  sortPredicates,
  onSort,
}: Props) => (
  <ListTable
    basic
    widthPercent
    sortedData={users}
    height={height}
    getRowId={(row: Row) => row.rowData.id}
    rowHeight={120}
    noRowsMessage="No Users found with these parameters..."
    sortPredicates={sortPredicates}
    sortBy="user"
    sortDirection="ASC"
    showDoubleArrowOnNotSorted
    nextSortDirection={{
      [SORT_DIRECTION.ASC]: SORT_DIRECTION.DESC,
      [SORT_DIRECTION.DESC]: SORT_DIRECTION.ASC,
    }}
    indicatorName={{
      [SORT_DIRECTION.ASC]: "sort-up",
      [SORT_DIRECTION.DESC]: "sort-down",
    }}
    onRowClick={onRowClick}
    onSort={(sortBy: keyof typeof sortPredicates, direction: "ASC" | "DESC") =>
      onSort(sortPredicates[sortBy], direction)
    }
  >
    <Column
      cellRenderer={({rowData: {firstName, lastName}}: Row) => (
        <TextCellEllipsis>
          {firstName} {lastName}
        </TextCellEllipsis>
      )}
      label="User"
      sortKey="user"
      width={22}
    />
    <Column
      label="Role"
      sortKey="role"
      width={18}
      cellRenderer={(row: Row) => (
        <TextCellEllipsis>
          {readableRole(row.rowData.role as any)}
        </TextCellEllipsis>
      )}
    />
    <Column
      label="Organization"
      sortKey="organization"
      width={15}
      cellRenderer={(row: Row) => (
        <TextCellEllipsis>{row.rowData.organization?.name}</TextCellEllipsis>
      )}
    />
    <Column
      label="Facility"
      width={23}
      cellRenderer={(row: Row) => (
        <TextCellEllipsis>
          {row.rowData.facilities.map(facility => facility.name).join(", ")}
        </TextCellEllipsis>
      )}
    />
    <Column
      label="Permission"
      sortKey="permission"
      width={17}
      cellRenderer={(row: Row) => (
        <TextCellEllipsis>
          {row.rowData.permissions.length} Permissions
        </TextCellEllipsis>
      )}
    />
    <Column
      label=""
      cellRenderer={() => <Icon name="right" color="#C7CAE1" size={10} />}
    />
  </ListTable>
);

ListTableUserPermissions.defaultProps = {
  sortPredicates: {
    user: ["firstName", "lastName"],
    role: ["role"],
  },
};
