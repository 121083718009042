export const FILE_TYPES = {
  ORIGINAL: "originalFileId",
  PROCESSED: "processedFileId",
};

export const FILE_STATUSES = {
  SCHEDULED: "SCHEDULED",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
  SUCCEEDED: "SUCCEEDED",
  INCOMPLETED: "INCOMPLETED",
} as const;

export const FILE_ACTIONS = {
  USER_IMPORT: "USER_IMPORT",
  USER_EXPORT: "USER_EXPORT",
} as const;
