import {useQuery} from "react-query";

import {OrganizationsService} from "../../../services/OrganizationsService";

export const useOrganizationsQuery = () =>
  useQuery("useOrganizationsQuery", async () => {
    const {data} = await OrganizationsService.get({
      size: 999,
      sort: "name,asc",
    });

    return data.content;
  });
