import {
  AdmissionEntryControllerApi,
  EmailAdmissionEntryLinkDto,
  VerificationAnswersDto,
} from "@reside/reside-api-admission";
import portableFetch from "portable-fetch";

import {BASE_PATH} from "../config";
import {getCommonAuthorizationHeader} from "./api/selectors";
import {getAdmissionAuthorizationHeader} from "../models/admission-session/selectors";

export const admissionEntryApi = new AdmissionEntryControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);

export const AdmissionEntryService = {
  emailLoginLink: (data: EmailAdmissionEntryLinkDto) =>
    admissionEntryApi.sendAdmissionEntryLinkEmailUsingPOST(data),

  generateAdmissionEntryToken: (admissionId: string) =>
    admissionEntryApi.generateAdmissionEntryTokenUsingGET(
      admissionId,
      getCommonAuthorizationHeader(),
    ),

  enterAdmission: (data: VerificationAnswersDto) =>
    admissionEntryApi.enterAdmissionUsingPOST(
      data,
      getAdmissionAuthorizationHeader(),
    ),
};
