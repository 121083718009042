import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {DocumentTitle} from "../../atoms/document-title";
import {SftpConfigCreate, SftpConfigEdit, SubMenu} from "./atoms";
import {SftpConfigurations} from "./SftpConfigurations";
import {TemplateFields} from "./TemplateFields";
import {EventTriggers} from "./EventTriggers";
import {EventTriggerCreate} from "./EventTriggerCreate";
import {EventTriggerViewer} from "./EventTriggerViewer";
import {EventTriggerEdit} from "./EventTriggerEdit";
import {Layout} from "./styles";

export const HubAndSpokeRouter = () => {
  const match = useRouteMatch();

  return (
    <>
      <DocumentTitle title="Reside - Admin - Hub & Spoke" />
      <Layout>
        <SubMenu />
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            component={() => (
              <Redirect to={`${match.path}/sftp-configurations`} />
            )}
          />
          <Route
            exact
            path={`${match.path}/sftp-configurations`}
            component={SftpConfigurations}
          />
          <Route
            exact
            path={`${match.path}/sftp-configurations/create`}
            component={SftpConfigCreate}
          />
          <Route
            exact
            path={`${match.path}/sftp-configurations/:sftpServerId/edit`}
            component={SftpConfigEdit}
          />
          <Route
            exact
            path={`${match.path}/template-fields`}
            component={TemplateFields}
          />
          <Route
            exact
            path={`${match.path}/event-triggers`}
            component={EventTriggers}
          />
          <Route
            exact
            path={`${match.path}/event-triggers/create`}
            component={EventTriggerCreate}
          />
          <Route
            exact
            path={`${match.path}/event-triggers/edit/:eventTriggerId`}
            component={EventTriggerEdit}
          />
          <Route
            exact
            path={`${match.path}/event-triggers/:eventTriggerId`}
            component={EventTriggerViewer}
          />
        </Switch>
      </Layout>
    </>
  );
};
