import {useState} from "react";
import {useQuery} from "react-query";
import {useHistory} from "react-router-dom";
import {styled, FlexRow, DropdownSelect, DropdownSelectText} from "@reside/ui";
import {RoleDto} from "@reside/reside-api-app";

import {Space} from "../../atoms/space";
import {AdminRoles} from "../../atoms/admin-roles";
import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {Spinner} from "../../atoms/spinner";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {AdminOrganizationsSearch} from "../../atoms/admin-search";
import {OrganizationsService} from "../../services/OrganizationsService";
import {ButtonLink} from "../../atoms/button-link";
import {AdminOrganizationsTable} from "../../atoms/admin-table";
import {logErrorToSentry} from "../../utils/sentry";
import {ThinScrollbarArea} from "@reside/ui";

const STATUSES = [
  {id: null, label: "All"},
  {id: "ACTIVE", label: "Active"},
  {id: "INACTIVE", label: "Inactive"},
];

const MinWidth = styled.div<{width: number}>`
  min-width: ${({width}) => width}px;
`;

export const PageAdminOrganizations = () => {
  const history = useHistory();
  const [status, setStatus] = useState(STATUSES[0]);

  const {data, isLoading} = useQuery(
    ["getOrganizations", status],
    async () => {
      const {data} = await OrganizationsService.get({
        size: 999,
        sort: "name,asc",
        status: status.id,
      });

      return data.content;
    },
    {
      onError: (err: any) => {
        logErrorToSentry(err, {
          STATUSES,
          history,
          status,
          data,
          isLoading,
        });
      },
    },
  );

  return (
    <AdminLayoutFullHeightAndTwoCells>
      <DocumentTitle title="Reside - Admin - Organizations" />
      {/**TODO - Refactor AdminActionRow component so that it can be used dynamically in this place as well. */}
      <FlexRow>
        <AdminPageTitle>Organizations</AdminPageTitle>
        <Space>
          <MinWidth width={100}>
            <DropdownSelect
              trigger={({isOpened}) => (
                <DropdownSelectText isOpened={isOpened} label={status.label} />
              )}
              selectItem={setStatus}
              selectedItem={status}
              items={STATUSES}
              label="Status:"
            />
          </MinWidth>
          <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
            <MinWidth width={300}>
              <AdminOrganizationsSearch />
            </MinWidth>
            <ButtonLink
              outline={false}
              to="/admin/organizations/create"
              testId="addOrganizationButton"
              color="primary"
            >
              New Organization
            </ButtonLink>
          </AdminRoles>
        </Space>
      </FlexRow>
      <ThinScrollbarArea>
        {isLoading ? (
          <Spinner />
        ) : (
          <AdminOrganizationsTable
            tableName="organizations"
            organizations={data}
            onRowClick={(id: string) =>
              history.push(`/admin/organizations/${id}/edit`)
            }
          />
        )}
      </ThinScrollbarArea>
    </AdminLayoutFullHeightAndTwoCells>
  );
};
