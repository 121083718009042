import React, {FunctionComponent} from "react";
import {Dispatch, bindActionCreators} from "redux";
import {
  connect,
  MergeProps,
  MapStateToProps,
  MapDispatchToPropsFunction,
} from "react-redux";
import {TableUserDto} from "@reside/reside-api-app";

import {UserId} from "../../store/user-permissions/user-permissions.common";
import {AppState, select} from "../../store/store";

import {
  selectUserHasPermission,
  selectUserPermissions,
} from "../../store/user-permissions/user-permissions.selectors";
import {createActions} from "../../store/user-permissions/user-permissions.actions";
import {RPFU, useFetchPermissions} from "./RequestPermissionsForUser";

interface OwnProps {
  permission: TableUserDto.PermissionsEnum;
  onChildRendered?: () => void;
  children: any;
}

interface StateProps {
  currentUserId: UserId;
  currentUserPermissions: TableUserDto.PermissionsEnum[];
  hasRequiredPermission: boolean;
}

interface DispatchProps {
  dispatch: Dispatch;
}

interface MergedProps extends OwnProps, StateProps {
  requestPermissionsForUser: RPFU;
}

const Renderer: FunctionComponent<MergedProps> = props => {
  useFetchPermissions(props.requestPermissionsForUser);

  if (props.hasRequiredPermission) {
    if (props.onChildRendered) {
      props.onChildRendered();
    }

    return <>{props.children}</>;
  }

  return null;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps,
) => ({
  currentUserId: select.adminSession.user(state).id,
  currentUserPermissions: selectUserPermissions(state),
  hasRequiredPermission: selectUserHasPermission(ownProps.permission)(state),
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    dispatch,
  };
};

const mergeProps: MergeProps<
  StateProps,
  DispatchProps,
  OwnProps,
  MergedProps
> = (stateProps, dispatchProps, ownProps) => {
  const actions = createActions(stateProps.currentUserId);
  const mergeProps = bindActionCreators(
    {
      requestPermissionsForUser: actions.requestPermissionsForUser,
    },
    dispatchProps.dispatch,
  );

  return {
    ...ownProps,
    ...stateProps,
    ...mergeProps,
  };
};

export const RequiredUserPermission = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Renderer);
