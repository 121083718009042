"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
var DOCUMENT_PATH_NAME_REGEX = /^((?!-)[a-z0-9._\-{}/]*\.pdf)$/i;
exports.Validator = {
    name: "document-path",
    errorMessage: 'Target file path must be a valid path to a file and may only contain alpha-numeric characters, as well as "._-{}/".',
    validate: function (value) {
        var noFileExtension = value.substring(0, value.lastIndexOf("."));
        return DOCUMENT_PATH_NAME_REGEX.test(value) && noFileExtension.length > 0;
    }
};
