import * as Sentry from "@sentry/browser";
import {reactAppEnvironment} from "../config";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 1,
    environment: reactAppEnvironment,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
