import React, {useRef, useState, useCallback, ChangeEvent} from "react";
import {GetInputPropsOptions} from "downshift";
import {cx} from "@emotion/css";
import {styled, Icon} from "@reside/ui";

type Props = Readonly<{
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressSpace?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | string[];
  downshift: (options?: GetInputPropsOptions) => object;
  placeholder: string;
  onClearClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  showClearButton: boolean;
  testId?: string;
  autoFocus?: boolean;
  autoComplete?: string;
}>;

export const RoundedSearchBox = ({
  autoComplete = "off",
  autoFocus,
  downshift,
  onClearClick,
  onInputChange,
  onPressEnter,
  onPressSpace,
  placeholder,
  showClearButton = false,
  testId = "search-box",
  value,
}: Props) => {
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const onClear = useCallback(
    event => {
      ref.current?.focus();
      onClearClick(event);
    },
    [onClearClick],
  );

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="search" color="#A8B6CA" size={15} />
      </IconWrapper>
      {onPressEnter && (
        <Enter visible={(value && focused) || (value !== "" && focused)}>
          PRESS ENTER
        </Enter>
      )}
      <Input
        ref={ref}
        type="text"
        className={cx({
          hiddenEnter: !onPressEnter,
        })}
        {...downshift({
          onChange: onInputChange,
          onKeyDown: event => {
            if (event.code === "Space") {
              event.preventDefault();
              event.stopPropagation();
              onPressSpace?.(event);
            }
            if (event.key === "Enter") {
              onPressEnter?.(event);
            }
          },
        })}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        data-test-id={testId}
        autoComplete={autoComplete}
      />
      {showClearButton && onClearClick && (
        <ClearButton onClick={onClear} data-test-id="clear-search-box">
          <Icon name="close" color="#A8B6CA" size={11} />
        </ClearButton>
      )}
    </Wrapper>
  );
};

const Enter = styled.span<{visible: boolean}>`
  position: absolute;
  right: 0;
  padding: 9px 20px 9px 10px;
  font-size: 15px;
  color: ${({theme}) => theme.color.darkBlue100};
  font-weight: 500;
  pointer-events: none;
  opacity: 0;

  &.visible {
    -webkit-animation-name: visible;
    animation-name: visible;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    opacity: 0.75;
    visibility: visible;
  }
  @-webkit-keyframes visible {
    0% {
      -webkit-transform: translateY(-9px);
      transform: translateY(-9px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 0.75;
    }
  }
  @keyframes visible {
    0% {
      -webkit-transform: translateY(-9px);
      transform: translateY(-9xp);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 0.75;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding-top: 3px;
  padding-left: 32px;
  padding-right: 110px;
  font-weight: 500;
  font-size: 15px;
  font-family: Gilroy, sans-serif;
  background-color: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.darkBlue10};
  border-radius: 100px;

  color: ${({theme}) => theme.color.darkBlue100};

  &.hiddenEnter {
    padding-right: 26px;
  }
  #243745 ::placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  :-ms-input-placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  ::-ms-input-placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  &:focus {
    border-color: ${({theme}) => theme.color.primary};
    outline: none;
    ::placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
    :-ms-input-placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
    ::-ms-input-placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
  }
`;

const IconWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 8px;
  bottom: 9px;
  left: 8px;
  pointer-events: none;
  text-align: left;
`;

const ClearButton = styled.span`
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 9px;
`;
