import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Alert from "react-s-alert";
import {connect} from "react-redux";
import {omit} from "lodash";
import {useLocation} from "react-router-dom";
import {Admission, ChecklistItem} from "@reside/reside-api-admission";

import {AdmissionsService} from "../../services/AdmissionsService";
import {useAdmissionsByStatusQueryUtils} from "./hooks";
import {getFacilityFeatureFlags} from "../../services/FacilityService";
import {select} from "../../store";

/**
 * TODO: the facilities should be accessed from separate hook
 */
type StateProps = {
  facilityFlagsById: Record<string, ReturnType<typeof getFacilityFeatureFlags>>;
  activeFacilitiesIds: string[];
};

/**
 * AdmissionId -> checklistItemId -> error message.
 */
type ChecklistErrors = Record<string, string>;
type ChecklistErrorsByAdmission = Record<string, ChecklistErrors>;

type ContextProps = {
  getAdmissionChecklistErrors: (admissionId: string) => ChecklistErrors;
  setAdmissionChecklistErrors: (
    admissionId: string,
    errors: ChecklistErrors,
  ) => void;
  clearAdmissionChecklistErrors: (
    admissionId: string,
    checklistItemId: string,
  ) => void;
  resetAdmissionChecklistErrors: () => void;
};

export const DashboardAdmissionsContext = createContext<
  StateProps & ContextProps
>({
  facilityFlagsById: {},
  activeFacilitiesIds: [],
  getAdmissionChecklistErrors: () => ({}),
  setAdmissionChecklistErrors: () => {},
  clearAdmissionChecklistErrors: () => {},
  resetAdmissionChecklistErrors: () => {},
});

export const useDashboardAdmissionsContext = () => {
  const {facilityFlagsById, activeFacilitiesIds, ...context} = useContext(
    DashboardAdmissionsContext,
  );

  const {
    refetchAdmissionsByStatusQuery,
    setAdmissionsByStatusQueryData,
    getAdmissionsByStatusQueryData,
  } = useAdmissionsByStatusQueryUtils();

  const refreshDashboard = () =>
    Object.keys(Admission.StatusEnum).forEach(status =>
      refetchAdmissionsByStatusQuery({
        status: status as Admission.StatusEnum,
        facilityIds: activeFacilitiesIds,
      }),
    );

  const updateAdmission = ({admission}: {admission: Admission}) => {
    const currentData = getAdmissionsByStatusQueryData({
      status: admission.status,
      facilityIds: activeFacilitiesIds,
    });
    setAdmissionsByStatusQueryData(
      {facilityIds: activeFacilitiesIds, status: admission.status},
      {
        ...currentData,
        pages: currentData.pages.map(page => {
          return {
            ...page,
            content: page?.content?.map(apiAdmission =>
              apiAdmission.id === admission.id ? admission : apiAdmission,
            ),
          };
        }),
      },
    );
  };

  const patchAdmissionChecklistItem = async ({
    admissionId,
    checklistItemId,
    data,
  }: {
    admissionId: string;
    checklistItemId: string;
    data: ChecklistItem;
  }) => {
    try {
      const {data: admission} =
        await AdmissionsService.patchAdmissionChecklistItem({
          admissionId,
          checklistItemId,
          data,
        });

      updateAdmission({admission: admission as any});
    } catch (error) {
      Alert.error("Failed to update dashboard checkbox. Please try reloading.");
    }
  };

  return {
    facilityFlagsById,
    updateAdmission,
    refreshDashboard,
    patchAdmissionChecklistItem,
    activeFacilitiesIds,
    ...context,
  };
};

const DashboardAdmissionsContextProviderRenderer: FunctionComponent<
  StateProps
> = ({children, facilityFlagsById, activeFacilitiesIds}) => {
  const {pathname} = useLocation();

  const [checklistErrors, setChecklistErrors] =
    useState<ChecklistErrorsByAdmission>({});

  const getAdmissionChecklistErrors = useCallback(
    (admissionId: string) => checklistErrors[admissionId],
    [checklistErrors],
  );

  const setAdmissionChecklistErrors = useCallback(
    (admissionId: string, errors: ChecklistErrors) => {
      setChecklistErrors({...checklistErrors, [admissionId]: errors});
    },
    [checklistErrors],
  );

  const clearAdmissionChecklistErrors = useCallback(
    (admissionId: string, checklistItemId: string) => {
      setChecklistErrors({
        ...checklistErrors,
        [admissionId]: omit(
          checklistErrors[admissionId] ?? {},
          checklistItemId,
        ),
      });
    },
    [checklistErrors],
  );

  const resetAdmissionChecklistErrors = useCallback(
    () => setChecklistErrors({}),
    [],
  );

  /**
   * Clear old errors when leaving dashboard.
   */
  useEffect(() => {
    if (pathname !== "/admin/dashboard") {
      resetAdmissionChecklistErrors();
    }
  }, [resetAdmissionChecklistErrors, pathname]);

  return (
    <DashboardAdmissionsContext.Provider
      value={{
        facilityFlagsById,
        activeFacilitiesIds,
        getAdmissionChecklistErrors,
        setAdmissionChecklistErrors,
        clearAdmissionChecklistErrors,
        resetAdmissionChecklistErrors,
      }}
    >
      {children}
    </DashboardAdmissionsContext.Provider>
  );
};

export const DashboardAdmissionsContextProvider = connect<StateProps>(() =>
  select((models: any) => ({
    activeFacilitiesIds: models.adminSession.activeFacilitiesIds,
    facilityFlagsById: models.adminSession.facilityFlagsById,
  })),
)(DashboardAdmissionsContextProviderRenderer);
