import React, {useState, ReactNode, FunctionComponent} from "react";

import {numberToPercent} from "../../../../pages/page-reporting/analytics/helpers";
import {findValueOrZero} from "../../chart.helpers";

export const HoverLabelWrapper: FunctionComponent<{
  labelComponent: ReactNode;
}> = props => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
      className="graph-bar-floating-wrapper"
    >
      {visible && props.labelComponent}
      {props.children}
    </div>
  );
};

export const HoverLabel: FunctionComponent<{
  values: number[] | {x: string; y: number}[];
  legend: {name: string}[];
  tickFormat?: (val: any) => string;
  colorScale: string[];
}> = props => {
  const isValueObjectOrEmpty =
    (props.values.length > 0 &&
      Object.prototype.hasOwnProperty.call(props.values[0], "x")) ||
    props.values.length === 0;

  return props.legend ? (
    <div className="graph-bar-floating-label">
      {props.legend.map((l, i) => (
        <span key={i}>
          <i
            className={`tick-${i}`}
            style={{backgroundColor: props.colorScale[i] || ""}}
          />{" "}
          {isValueObjectOrEmpty
            ? numberToPercent(
                findValueOrZero(
                  props.values as {x: string; y: number}[],
                  l.name,
                ),
              )
            : props.tickFormat
            ? props.tickFormat(props.values[i])
            : props.values[i]}
        </span>
      ))}
    </div>
  ) : (
    <div className="graph-bar-floating-label">
      {(props.values as number[]).map((v: number, i: number) => (
        <span key={i}>
          <i
            className={`tick-${i}`}
            style={{backgroundColor: props.colorScale[i] || ""}}
          />{" "}
          {props.tickFormat ? props.tickFormat(v) : v}
        </span>
      ))}
    </div>
  );
};
