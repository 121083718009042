import React, {Fragment} from "react";
import {ChecklistItem} from "@reside/reside-api-admission";
import {
  FieldError,
  TaskCheckboxField,
  TaskCheckboxContextProvider,
} from "@reside/ui";

import {usePccDocumentUploadModalContext} from "../pcc-document-upload-modal";
import {useDashboardAdmissionsContext} from "../../DashboardAdmissionsContext";
import {admissionHasActivePccResidentAccountStatus} from "../../../../services/PccService";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";
import {PccDocumentUploadTaskCheckbox} from "../pcc-document-upload-task-checkbox";
import {DisabledNotApplicableStatusOption} from "../cell/admission-checklist/DisableNotApplicableStatusOption";

export const ApprovalChecklist = ({
  checklist,
  admission,
}: {
  admission: AdmissionFromSearch;
  checklist: ReadonlyArray<ChecklistItem>;
}) => {
  const {facilityFlagsById} = useDashboardAdmissionsContext();
  const {isPccDashboardCheckboxesFeatureEnabled, openPccUploadModal} =
    usePccDocumentUploadModalContext();

  const onUpload = (checklistItem: ChecklistItem) =>
    openPccUploadModal({
      admission,
      checklistItem,
    });

  const {facilityHasPccTurnedOn, facilityHasPccUploadActive} =
    facilityFlagsById[admission.facilityId];

  const isPccDocumentUploadEnabled =
    isPccDashboardCheckboxesFeatureEnabled &&
    facilityHasPccTurnedOn &&
    facilityHasPccUploadActive &&
    admissionHasActivePccResidentAccountStatus(admission);

  return (
    <TaskCheckboxContextProvider>
      {checklist.map(checklistItem => (
        <Fragment key={checklistItem.id}>
          <TaskCheckboxField
            name={checklistItem.id}
            label={checklistItem.description}
            tooltipLength={63}
            renderNotApplicableStatusOption={
              isPccDocumentUploadEnabled && checklistItem.documentUploadRequired
                ? DisabledNotApplicableStatusOption
                : undefined
            }
            onComplete={
              isPccDocumentUploadEnabled && checklistItem.documentUploadRequired
                ? ({setCheckboxClosed}) => {
                    setCheckboxClosed();
                    onUpload(checklistItem);
                  }
                : undefined
            }
          >
            {isPccDocumentUploadEnabled
              ? props => (
                  <PccDocumentUploadTaskCheckbox
                    {...props}
                    checklistItem={checklistItem}
                    onUpload={onUpload}
                  />
                )
              : undefined}
          </TaskCheckboxField>
          <FieldError name={`${checklistItem.id}.checked`} />
        </Fragment>
      ))}
    </TaskCheckboxContextProvider>
  );
};
