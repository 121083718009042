import React, {ReactNode} from "react";
import {
  styled,
  DropdownSelect,
  DropdownSelectText,
  ThinScrollbar,
  FlexColumn,
  Toggle,
} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";
import {css} from "@emotion/react";
import {connect} from "react-redux";

import {select} from "../../../store";

import {IDateRangeMomentGlobal} from "../../../store/reporting/reporting.common";

import {Layout, LayoutContent} from "../../../atoms/admin-layout";
import {ReportingActionRow} from "../../../atoms/admin-reporting/atoms/reporting-action-row";
import {
  CompareWrapper,
  CustomDateRangePreset,
  DateRangePresets,
} from "../../../atoms/reporting-compare";
import {PermissionManagementModal} from "../atoms/permission-management-modal";
import {FacilityDropdownTreeSelect} from "../../../atoms/facility-dropdown-tree-select";
import {ReportContextConsumer} from "../ReportContext";
import {UseChecklistItems} from "../reporting-menu/useChecklistItems";
import {ReportingMenu} from "../reporting-menu";
import {PDFSpinner} from "../PDFSpinner";
import {BlankSlate} from "../BlankSlate";
import {UseQuicksightCurrentUserDashboards} from "../hooks/useQuicksightCurrentUserDashboards";

import {RequiredUserPermissionsSome} from "../../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {PermissionModalContextProvider} from "../atoms/permission-management-modal/PermissionManagementModalContext";
import {ScrollSpyProvider} from "../../../hooks/useScrollSpy";
import {AdminNavigationHeader} from "../../../atoms/admin-navigation-header";

import "./style.scss";
import {Copyright} from "../../../atoms/copyright";

type StateProps = {
  hasQuicksightDashboardsPermission: boolean;
};

type Props = {
  children: ReactNode;
  isActionRowVisible?: boolean;
};

const Renderer = ({
  children,
  isActionRowVisible = true,
  hasQuicksightDashboardsPermission,
}: Props & StateProps) => (
  <WideLayout>
    <AdminNavigationHeader />
    <WideLayoutContent disabled={!hasQuicksightDashboardsPermission}>
      <ScrollSpyProvider>
        <PermissionModalContextProvider>
          <ReportContextConsumer>
            {({
              activeFacilitiesIds,
              pageLoading,
              baseDateRange,
              setBaseDateRange,
            }) => {
              return (
                <FullHeightRow>
                  <UseQuicksightCurrentUserDashboards>
                    {({data: dashboardItems}) => (
                      <RequiredUserPermissionsSome
                        permissions={[
                          TableUserDto.PermissionsEnum.CHECKLIST_ITEMS,
                        ]}
                        noPermissionChildren={
                          <ReportingMenu dashboardItems={dashboardItems} />
                        }
                      >
                        <UseChecklistItems>
                          {({
                            /**
                             * When the checklistItems is undefined, the query is in progress so we display dummy menu item "Loading..."
                             */
                            data: checklistItems,
                          }) => (
                            <ReportingMenu
                              dashboardItems={dashboardItems}
                              checklistItems={checklistItems}
                            />
                          )}
                        </UseChecklistItems>
                      </RequiredUserPermissionsSome>
                    )}
                  </UseQuicksightCurrentUserDashboards>
                  <ReportsColumn>
                    {isActionRowVisible && (
                      <ReportingActionRow
                        facilitySelector={
                          <div className="facility-dropdown-tree-select">
                            <FacilityDropdownTreeSelect />
                          </div>
                        }
                        dateSelector={
                          <Toggle>
                            {({
                              isOpened,
                              setIsOpened,
                              outsideClickIgnoreClass,
                            }) => (
                              <div style={{position: "relative"}}>
                                {isOpened && (
                                  <CompareWrapper
                                    global
                                    className={outsideClickIgnoreClass}
                                    chartId="global"
                                    appliedDateRange={{
                                      ...baseDateRange,
                                      from: baseDateRange.from,
                                      to: baseDateRange.to,
                                    }}
                                    appliedCompareDateRange={baseDateRange}
                                    onApply={dateRange => {
                                      setIsOpened(false);
                                      setBaseDateRange(dateRange);
                                    }}
                                  />
                                )}
                                <DropdownSelect<IDateRangeMomentGlobal>
                                  trigger={({isOpened}) => (
                                    <DropdownSelectText
                                      outline
                                      bigger
                                      isOpened={isOpened}
                                      label={baseDateRange.label}
                                    />
                                  )}
                                  selectItem={selectedPreset =>
                                    selectedPreset.id ===
                                    CustomDateRangePreset.id
                                      ? setIsOpened(true)
                                      : setBaseDateRange(selectedPreset)
                                  }
                                  selectedItem={baseDateRange as any}
                                  items={[
                                    ...DateRangePresets.map(dateRange => ({
                                      ...dateRange,
                                      from: dateRange.from(),
                                      to: dateRange.to(),
                                    })),
                                    {
                                      ...CustomDateRangePreset,
                                      from: baseDateRange.from,
                                      to: baseDateRange.to,
                                    },
                                  ]}
                                />
                              </div>
                            )}
                          </Toggle>
                        }
                      />
                    )}
                    <Scrollable className="js-reporting-scroll-container">
                      <PDFSpinner isLoading={pageLoading} />
                      <PermissionManagementModal />
                      <BlankSlate facilities={activeFacilitiesIds}>
                        {children}
                      </BlankSlate>
                    </Scrollable>
                  </ReportsColumn>
                </FullHeightRow>
              );
            }}
          </ReportContextConsumer>
        </PermissionModalContextProvider>
      </ScrollSpyProvider>
    </WideLayoutContent>
    <Copyright />
  </WideLayout>
);

const mapStateToProps = (state: any): StateProps => ({
  hasQuicksightDashboardsPermission:
    select.adminSession.hasQuicksightDashboardsPermission(state),
});

export const ReportingLayout = connect(mapStateToProps)(Renderer);

const WideLayout = styled(Layout)`
  min-height: 100vh;
  max-height: 100vh;
`;

const WideLayoutContent = styled(LayoutContent)<{disabled: boolean}>`
  flex-grow: 1;
  overflow: hidden;

  // TODO: remove the 'disabled' flag, once the wide layout is default (after full-width dashboard is shipped)
  ${({disabled, theme}) =>
    !disabled &&
    css`
      max-width: none;
      padding: 20px ${theme.space[7]}px;
    `}
`;

const FullHeightRow = styled(ThinScrollbar)`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const Scrollable = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const ReportsColumn = styled(FlexColumn)`
  overflow: hidden;
  flex: 2; // Must be greater than the menu column
`;
