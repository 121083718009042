import React from "react";
import {styled, Button} from "@reside/ui";
import {useNetworkState} from "react-use";

type Props = Readonly<{
  onReloadClick?: () => void;
}>;

export const NetworkStatusBanner = ({onReloadClick}: Props) => {
  const {online = true} = useNetworkState();

  return (
    !online && (
      <Banner>
        <Message>
          Application is currently experiencing connectivity issues. Please try
          reloading.
        </Message>
        <Button color="primary" outline onClick={onReloadClick}>
          Reload
        </Button>
      </Banner>
    )
  );
};

NetworkStatusBanner.defaultProps = {
  onReloadClick: () => {
    if (
      window.confirm("All unsaved work will be lost. Do you want to reload?")
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.location.reload(true);
    }
  },
};

export const Banner = styled.div`
  position: fixed;
  min-height: 60px;
  top: 20px;
  right: 20px;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  background: white;
  z-index: 1003;
`;

export const Message = styled.div`
  margin: 0 20px;
`;
