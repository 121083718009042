import React from "react";
import {Grid} from "@reside/ui";
import {TableUserDto, RoleDto} from "@reside/reside-api-app";

import {PermissionsMacroCheckbox} from "../permissions-macro-checkbox";
import {
  GeneralTypes,
  CheckboxesTypes,
  DashboardsTypes,
  OtherTypes,
} from "./permissionTypes";
import {AdminRoles} from "../../../../atoms/admin-roles";
import {QuicksightDashboardsMacroCheckbox} from "../quicksight-dashboards-macro-checkbox";

type Props = Readonly<{
  userId: string;
  permissionsPersisted: boolean;
  activePermissions: ReadonlyArray<TableUserDto.PermissionsEnum>;
  onSetPermission: (
    permissions: ReadonlyArray<TableUserDto.PermissionsEnum>,
  ) => void;
}>;

export const PermissionManagementForm = ({
  userId,
  permissionsPersisted,
  activePermissions,
  onSetPermission,
}: Props) => (
  <>
    <Grid columns={2}>
      <PermissionsMacroCheckbox
        label="General"
        permissions={GeneralTypes}
        activePermissions={activePermissions}
        onSetPermission={onSetPermission}
      />
      <div>
        <PermissionsMacroCheckbox
          label="Checkboxes"
          permissions={CheckboxesTypes}
          activePermissions={activePermissions}
          onSetPermission={onSetPermission}
        />
        <PermissionsMacroCheckbox
          label="Dashboards"
          permissions={DashboardsTypes}
          activePermissions={activePermissions}
          onSetPermission={onSetPermission}
        />
        {activePermissions.includes(
          TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS,
        ) &&
          permissionsPersisted && (
            <QuicksightDashboardsMacroCheckbox userId={userId} />
          )}
      </div>
    </Grid>
    <Grid columns={2}>
      <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
        <PermissionsMacroCheckbox
          label="Other"
          permissions={OtherTypes}
          activePermissions={activePermissions}
          onSetPermission={onSetPermission}
        />
      </AdminRoles>
    </Grid>
  </>
);
