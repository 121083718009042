import React from "react";
import {connect} from "react-redux";
import {UserDto} from "@reside/reside-api-app";
import {CheckboxItem, H4} from "@reside/ui";
import {select} from "../../../../store";
import {QuicksightDashboard} from "../../../../services/QuicksightService";
import {useQuicksightAdminDashboards} from "../../hooks/useQuicksightAdminDashboards";
import {useQuicksightDashboardsPermissionMutation} from "../../hooks/useQuicksightDashboardsPermissionMutation";
import {useQuicksightUserDashboards} from "../../hooks/useQuicksightUserDashboards";
import {PermissionColumn} from "../permissions-macro-checkbox";
import {ClearQuicksightCacheButton} from "../clear-quicksight-cache-button";

const RoleRenderer = ({
  user,
  userId,
  isResideAdmin,
}: {
  isResideAdmin: boolean;
  userId: string;
  user: UserDto;
}) =>
  isResideAdmin ? (
    <ResideAdminRenderer userId={userId} />
  ) : (
    <BasicUserRenderer userId={userId} user={user} />
  );

/**
 * When the user managing permissions is not reside admin, his own permission are those he can grant to other users.
 */
const BasicUserRenderer = ({user, userId}: {userId: string; user: UserDto}) => {
  const {
    data: userDashboards = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuicksightUserDashboards(user.id);

  return (
    <Renderer
      userId={userId}
      dashboardOptions={userDashboards}
      isLoadingDashboardOptions={isLoading || isFetching}
      refetchDashboardOptions={refetch}
    />
  );
};

/**
 * Reside admin users don't have permission associated with the user account, but fetch all available dashboards instead.
 */
const ResideAdminRenderer = ({userId}: {userId: string}) => {
  const {
    data: options = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuicksightAdminDashboards();

  return (
    <>
      <Renderer
        userId={userId}
        dashboardOptions={options}
        isLoadingDashboardOptions={isLoading || isFetching}
        refetchDashboardOptions={refetch}
      />
      <ClearQuicksightCacheButton onSuccess={refetch} />
    </>
  );
};

const Renderer = ({
  userId,
  dashboardOptions,
  isLoadingDashboardOptions,
  refetchDashboardOptions,
}: {
  userId: string;
  dashboardOptions: ReadonlyArray<Partial<QuicksightDashboard>>;
  isLoadingDashboardOptions: boolean;
  refetchDashboardOptions: () => void;
}) => {
  const {
    data: userDashboards = [],
    isLoading: isLoadingUserDashboards,
    isFetching: isFetchingUserDashboards,
    refetch,
  } = useQuicksightUserDashboards(userId);

  const {mutate, isLoading: isSavingPermissions} =
    useQuicksightDashboardsPermissionMutation({
      onConflict: () => {
        refetchDashboardOptions();
      },
    });

  const loading =
    isLoadingDashboardOptions ||
    isLoadingUserDashboards ||
    isFetchingUserDashboards;

  const checkedValues = userDashboards.map(dashboard => dashboard.id);

  return (
    <PermissionColumn>
      <H4>
        {isSavingPermissions
          ? "Quicksight Dashboards (Saving...)"
          : "Quicksight Dashboards"}
      </H4>
      {loading
        ? "Loading..."
        : dashboardOptions.map(({id: dashboardId, name}) => (
            <CheckboxItem
              key={dashboardId}
              value={dashboardId}
              label={name}
              checked={checkedValues.includes(dashboardId)}
              disabled={loading || isSavingPermissions}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                mutate({
                  userId,
                  dashboard: {
                    dashboardId,
                    checked: event.currentTarget.checked,
                  },
                  onSuccess: refetch,
                })
              }
            />
          ))}
    </PermissionColumn>
  );
};

const mapStateToProps = (state: any) => ({
  user: select.adminSession.user(state),
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const QuicksightDashboardsMacroCheckbox =
  connect(mapStateToProps)(RoleRenderer);
