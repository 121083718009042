import React, {memo} from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsMedicareByFacility,
  numberToPercent,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsMedicareByFacilityChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const barData = dataProcess_AnalyticsMedicareByFacility(data);

    return (
      <BarChart
        data={barData.stack}
        max={barData.max}
        labelLeft="Facility"
        labelRight="Percentage"
        full={isFullReport}
        tickFormat={x => numberToPercent(x)}
        axTickFormat={x => numberToPercent(x)}
        dataLegend={[
          {name: "Patients with Medicare"},
          {name: "Patients without Medicare"},
        ]}
        fullWidth
      />
    );
  },
);

export const FACILITY_MEDICARE_CHART_ID = "facility-medicare" as const;

export const AnalyticsMedicareByFacility = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.PATIENTS_WITH_MEDICAL_SUPPLEMENT}
  >
    <div className="chart-header">
      <CardTitle
        title="Medicare by facility"
        subtitle="What % of patient where a Medicare applies"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.medicareByFacility,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsMedicareByFacilityChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
