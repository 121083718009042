import React, { createContext, useContext, useState, } from "react";
/**
 * The context permits to have one checkbox dropdown open at a time.
 */
var TaskCheckboxContext = createContext(undefined);
export var useTaskCheckboxContext = function () { return useContext(TaskCheckboxContext); };
export var TaskCheckboxContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(undefined), openedCheckboxElement = _b[0], setOpenedCheckboxElement = _b[1];
    var setCheckboxClosed = function () {
        setOpenedCheckboxElement(undefined);
    };
    return (React.createElement(TaskCheckboxContext.Provider, { value: {
            openedCheckboxElement: openedCheckboxElement,
            setOpenedCheckboxElement: setOpenedCheckboxElement,
            setCheckboxClosed: setCheckboxClosed,
        } }, children));
};
