import {RouteComponentProps, useHistory} from "react-router";
import {FormCard, Button, H2, H3} from "@reside/ui";
import {useEventTriggersQuery} from "../../services/hooks/useEventTriggersQuery";
import {Spinner} from "../../atoms/spinner";
import {FormWrapper} from "./styles";
import {EventTriggerViewerForm} from "./EventTriggerViewerForm";

export const EventTriggerViewer = ({
  match,
}: RouteComponentProps<{eventTriggerId: string}>) => {
  const history = useHistory();
  const {eventTriggerId} = match.params;
  const {data: eventTriggerData, isLoading: isLoadingEventTrigger} =
    useEventTriggersQuery({
      eventTriggerId,
      options: [{enabled: !!eventTriggerId}],
    });

  const eventTrigger = Array.isArray(eventTriggerData)
    ? eventTriggerData[0]
    : eventTriggerData;

  return (
    <FormWrapper>
      <Button type="link" onClick={() => history.goBack()}>
        Go Back
      </Button>
      <FormCard title="Event Trigger Viewer">
        {isLoadingEventTrigger ? (
          <Spinner />
        ) : (
          <>
            <H2>Template Field: {eventTrigger.template_field.friendly_name}</H2>
            <H3>Template Name: {eventTrigger.template_field.template_name}</H3>
            <H3>
              Facilities:{" "}
              {eventTrigger.facilities
                .map(facility => facility.name)
                .join(", ")}
            </H3>
            <EventTriggerViewerForm eventTrigger={eventTrigger} />
          </>
        )}
      </FormCard>
    </FormWrapper>
  );
};
