import React from "react";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {ErrorResponseDto} from "@reside/reside-api-admission";

import {
  AdmissionsService,
  isMissingMandatoryChecklistItemException,
} from "../../services/AdmissionsService";
import {ArchiveModal} from "./atoms/ArchiveModal";
import {ModalName, useDashboardModalContext} from "./DashboardModalContext";
import {useDashboardAdmissionsContext} from "./DashboardAdmissionsContext";
import {getAdmissionResidentName} from "./helper";
import {getFormValidationErrors} from "../../utils/formUtils";

export const DashboardArchiveModal = () => {
  const {refreshDashboard, setAdmissionChecklistErrors} =
    useDashboardAdmissionsContext();
  const {admission, isModalOpened, toggleModal} = useDashboardModalContext(
    ModalName.ARCHIVE,
  );

  const admissionId = admission?.id;

  const {mutate, isLoading} = useMutation(
    (admissionId: string) => AdmissionsService.archive(admissionId),
    {
      onSuccess: () => {
        Alert.success("Admission was successfully archived.");
        refreshDashboard();
      },
      onError: async (resp: any) => {
        const error: ErrorResponseDto = await resp.json();

        if (isMissingMandatoryChecklistItemException(error)) {
          setAdmissionChecklistErrors(
            admissionId,
            getFormValidationErrors(error.validationErrors),
          );

          const [firstError] = error.validationErrors;

          Alert.error(firstError.message);
        } else {
          Alert.error("Unable to archive the admission.");
        }
      },
      onSettled: () => toggleModal(),
    },
  );

  return (
    <ArchiveModal
      residentName={admission ? getAdmissionResidentName(admission) : ""}
      isOpen={isModalOpened}
      isLoading={isLoading}
      onRequestClose={() => toggleModal()}
      onArchive={() => mutate(admissionId)}
    />
  );
};
