import React, {FunctionComponent, ReactNode} from "react";
import Div100vh from "react-div-100vh";
import {css} from "@emotion/css";
import {styled} from "@reside/ui";

type Props = Readonly<{
  backgroundImageUrl?: string;
  footer?: ReactNode;
}>;

export const Layout: FunctionComponent<Props> = ({
  backgroundImageUrl,
  children,
  footer,
}) => (
  <Wrapper
    className={
      backgroundImageUrl &&
      css`
        background-image: url(${backgroundImageUrl});
      `
    }
  >
    <LayoutContent>{children}</LayoutContent>
    {footer}
  </Wrapper>
);

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.color.white};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  overflow: auto;
`;

export const LayoutContent = styled.div`
  flex-grow: 1;
  display: flex;
  padding: ${({theme}) => theme.space[5]}px;

  @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
    padding: ${({theme}) => theme.space[9]}px;
  }
`;
