import React from "react";

import {NavbarIconButton} from "../../navbar-top/atoms/navbar-icon-button";
import {styled} from "@reside/ui";
type Props = Readonly<{
  darkBackground: boolean;
  onClick: () => void;
}>;

export const TocControlTrigger = ({darkBackground, onClick}: Props) => (
  <NavbarIconButtonStyled
    frameless
    iconName="index"
    onClick={onClick}
    testId="open-toc-button"
    darkBackground={darkBackground}
  >
    Summary
  </NavbarIconButtonStyled>
);

TocControlTrigger.defaultProps = {
  darkBackground: false,
};

const NavbarIconButtonStyled = styled(NavbarIconButton)`
  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    i {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
