import React from "react";
import {toDate} from "date-fns";

import {styled} from "@reside/ui";
import {AdmissionFlag} from "@reside/reside-api-admission";
import {RelativeDateTime} from "../relative-date-time";

type Props = Readonly<{
  flag: AdmissionFlag;
  onClick: (flagId: string, slideId: string) => void;
}>;

export const FlagItem = ({flag, onClick}: Props) => (
  <Container onClick={() => onClick(flag.id, flag.sectionId)}>
    <Header>
      <BodyWrapper>
        <SectionLink>{flag.sectionName}</SectionLink>
      </BodyWrapper>
      <RelativeDateTimeWrapper>
        <RelativeDateTime dateTime={toDate(flag.created)} />
      </RelativeDateTimeWrapper>
    </Header>
    <BodyWrapper>
      <SlideName>{flag.slideName}</SlideName>
      <Comment>&quot;{flag.comment}&quot;</Comment>
    </BodyWrapper>
  </Container>
);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.25em;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

const SectionLink = styled.div`
  font-size: ${({theme}) => theme.fontSize[2]}px;
  margin: 6px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({theme}) => theme.color.primary};
`;

const SlideName = styled.div`
  font-weight: ${({theme}) => theme.fontWeight.bold};
  font-size: ${({theme}) => theme.fontSize[3]}px;
  line-height: 1.2; // Fix bold font cut on 'g' letter
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 6px;
`;

const Comment = styled.div`
  margin-top: 4px;
  line-height: 1.15em;
  color: ${({theme}) => theme.color.gray100};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RelativeDateTimeWrapper = styled.div`
  margin-left: 15px;
  color: ${({theme}) => theme.color.gray100};
`;

const Container = styled.div`
  margin: 0;
  padding: 8px 16px 16px;
  cursor: pointer;
  background-color: ${({theme}) => theme.color.white};

  & + & {
    border-top: 1px solid ${({theme}) => theme.color.gray10};
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background: ${({theme}) => theme.color.lightBlue10};
  }
`;
