import React, {useEffect, useMemo, ReactElement, ComponentType} from "react";
import {connect, MapStateToProps, MapDispatchToProps} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import Axios from "axios";

import {createActions, createSelectors} from "../../store/reporting";
import {
  DataPath,
  StartFetchAnalyticsActionFactory,
  IReportingAnalyticsDataState,
  DataStatePath,
} from "../../store/reporting/reporting.common";
import {AppState} from "../../store";
import {
  useDataPathsDeepCompareMemoize,
  useDataPathChange,
  dataPathsDeepCompareEquals,
} from "./ReportingContext.helpers";
import {Spinner} from "../../atoms/spinner";

type OwnProps = Readonly<{
  /**
   * Whether fetching data is enabled.
   * Used for lazy loading.
   */
  enabled?: boolean;
  children(
    byDataPaths: DataStatePath[] | undefined,
    isFullReport?: boolean,
  ): ReactElement;
  dataPaths: DataPath[];
  loaderComponent?: ReactElement;
  onDataPathChange?: (dataPath: DataPath) => void;
  isFullReport?: boolean;
}>;

interface StateProps {
  byDataPaths: IReportingAnalyticsDataState[] | undefined;
}

interface ConnectedProps extends OwnProps, StateProps, DispatchProps {}

interface DispatchProps {
  arrFetchAnalytics: {fetchAnalytics: StartFetchAnalyticsActionFactory}[];
}

const useFetchAnalytics = (
  dataPaths: DataPath[],
  arrFetchAnalytics: {fetchAnalytics: StartFetchAnalyticsActionFactory}[],
  shouldFetch: boolean,
) => {
  if (dataPaths.length !== arrFetchAnalytics.length) {
    throw new Error("dataPaths and arrFetchAnalytics do not match"); // replace with warning
  }
  const cancelTokenRefs = React.useRef(
    dataPaths.map(_ => Axios.CancelToken.source()),
  );
  const effectCancelableFetch = () => {
    if (shouldFetch) {
      cancelTokenRefs.current = dataPaths.map(_ => Axios.CancelToken.source()); // this feels wrong, probably should invalidate only one
      arrFetchAnalytics.forEach((fao, i) => {
        fao.fetchAnalytics(cancelTokenRefs.current[i]);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCancelableFetch, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDataPathsDeepCompareMemoize(dataPaths),
    shouldFetch,
  ]);
};

const ReportingPresenter = ({
  enabled = true,
  children,
  loaderComponent,
  byDataPaths,
  dataPaths,
  onDataPathChange,
  isFullReport,
  arrFetchAnalytics,
}: ConnectedProps) => {
  const data = useMemo(() => {
    return byDataPaths.map((byDataPath, i) => ({
      dataState: byDataPath,
      dataPath: dataPaths[i],
    }));
  }, [byDataPaths, dataPaths]);

  useFetchAnalytics(dataPaths, arrFetchAnalytics, enabled);
  // Only first dataPath is notified (because csv exports are currently working only for one)
  useDataPathChange(dataPaths[0], onDataPathChange);

  const showSpinner =
    loaderComponent && (!byDataPaths || byDataPaths.every(x => x?.fetching));

  return (
    <div>{showSpinner ? loaderComponent : children(data, isFullReport)}</div>
  );
};

ReportingPresenter.defaultProps = {
  loaderComponent: <Spinner relative />,
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps,
) => {
  const selectorsArr = ownProps.dataPaths.map(dataPath =>
    createSelectors(dataPath),
  );

  return {
    byDataPaths: selectorsArr.map(selectors => selectors.byDataPath(state)),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch,
  ownProps: OwnProps,
) => {
  return {
    arrFetchAnalytics: ownProps.dataPaths.map(dataPath => {
      const actions = createActions(dataPath);

      return bindActionCreators(
        {fetchAnalytics: actions.fetchAnalytics},
        dispatch,
      );
    }),
  };
};

const iconnect = connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: (nextStateProps, prevStateProps) => {
    if (
      !!nextStateProps.byDataPaths !== !!prevStateProps.byDataPaths ||
      nextStateProps.byDataPaths.length !== prevStateProps.byDataPaths.length
    ) {
      return false;
    }

    for (let i = 0; i < nextStateProps.byDataPaths.length; i++) {
      const p1 = nextStateProps.byDataPaths[i];
      const p2 = prevStateProps.byDataPaths[i];

      if (!p1 && !p2) {
        return true;
      }

      if (
        !!p1 !== !!p2 ||
        p1.fetching !== p2.fetching ||
        p1.success !== p2.success ||
        !!p1.data !== !!p2.data
      ) {
        return false;
      }
    }

    return true;
  },
  areOwnPropsEqual: (nextOwnProps, prevOwnProps) => {
    return (
      dataPathsDeepCompareEquals(
        nextOwnProps.dataPaths,
        prevOwnProps.dataPaths,
      ) &&
      nextOwnProps.loaderComponent === prevOwnProps.loaderComponent &&
      nextOwnProps.onDataPathChange === prevOwnProps.onDataPathChange &&
      nextOwnProps.isFullReport === prevOwnProps.isFullReport
      // ignore children prop
    );
  },
});

export const ReportingContext: ComponentType<OwnProps> =
  iconnect(ReportingPresenter);
