import React, {useEffect, useRef} from "react";
import {connect, MapStateToProps, MapDispatchToProps} from "react-redux";
import {bindActionCreators} from "redux";
import axios from "axios";

import {AppState} from "../../store";
import {
  ReportsDataPath,
  IReportingReportsDataState,
  StartFetchReportsActionFactory,
  StartLoadMore,
  ReportsDataStatePath,
} from "../../store/reporting-reports/reporting-reports.common";
import {createSelectorsReports} from "../../store/reporting-reports/reporting-reports.selectors";
import {createActions} from "../../store/reporting-reports/reporting-reports.actions";
import {
  useDataPathsDeepCompareMemoize,
  useDataPathChange,
} from "./ReportingContextReports.helpers";

type OwnProps = Readonly<{
  children(
    byDataPath: ReportsDataStatePath[] | undefined,
    loadMore: StartLoadMore,
  ): React.ReactElement;
  dataPaths: ReportsDataPath[];
  enabled: boolean;
  onDataPathChange?: (dataPath: ReportsDataPath) => void;
}>;

type StateProps = Readonly<{
  byDataPaths: IReportingReportsDataState[] | undefined;
}>;

type DispatchProps = Readonly<{
  actions: {
    startFetchReports: StartFetchReportsActionFactory;
    loadMore: StartLoadMore;
  }[];
}>;

type Props = OwnProps & StateProps & DispatchProps;

const useFetchReports = (
  dataPaths: ReportsDataPath[],
  actions: DispatchProps["actions"],
  shouldFetch: boolean,
) => {
  if (dataPaths.length !== actions.length) {
    throw new Error("dataPaths and actions lengths do not match"); // replace with warning
  }
  const cancelTokenRefs = useRef(
    dataPaths.map(() => axios.CancelToken.source()),
  );

  const effectCancelableFetch = () => {
    if (shouldFetch) {
      cancelTokenRefs.current = dataPaths.map(() => axios.CancelToken.source()); // this feels wrong, probably should invalidate only one
      actions.forEach((action, i) => {
        action.startFetchReports(cancelTokenRefs.current[i], 0);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCancelableFetch, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDataPathsDeepCompareMemoize(dataPaths),
    shouldFetch,
  ]);
};

const ReportingPresenter = ({
  children,
  byDataPaths,
  dataPaths,
  actions,
  enabled,
  onDataPathChange,
}: Props) => {
  useFetchReports(dataPaths, actions, enabled);
  useDataPathChange(dataPaths[0], onDataPathChange);

  const loadMore = () => {
    actions.forEach(({loadMore}) => loadMore());
  };

  return (
    <div>
      {children(
        byDataPaths.map((byDataPath, i) => ({
          dataState: byDataPath,
          dataPath: dataPaths[i],
        })),
        loadMore,
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps,
) => {
  const selectorsArr = ownProps.dataPaths.map(dataPath =>
    createSelectorsReports(dataPath),
  );

  return {
    byDataPaths: selectorsArr.map(selectors => selectors.byDataPath(state)),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps,
) => ({
  actions: ownProps.dataPaths.map(dataPath => {
    const actions = createActions(dataPath);

    return bindActionCreators(
      {
        startFetchReports: actions.fetchReports,
        loadMore: actions.loadMore,
      },
      dispatch,
    );
  }),
});

export const ReportingContextReports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportingPresenter);
