import React from "react";
import {
  CompareDateLabels,
  getAggregationValues,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportAdmission,
  ReportAggregationTypes,
  ReportsDataStatePath,
  ReportsType,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  IconCheckCell,
  IconCrossCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";
import {Aggregation} from "../../../atoms/admin-reporting/atoms/aggregation";
import {theme} from "@reside/ui";

export const PHOTOS_REPORT_ID = "photos" as const;

const renderPhotosAggregations = (d: ReportsDataStatePath[]) => {
  // TODO: categories
  return (
    <>
      <Aggregation
        reports
        subject="IN TOTAL"
        values={getAggregationValues(d, ReportAggregationTypes.total)}
      />

      <Aggregation
        reports
        subject="HAS PHOTO ID"
        values={getAggregationValues(d, ReportAggregationTypes.hasPhotoId)}
      />

      <Aggregation
        reports
        subject="WITHOUT PHOTO ID"
        values={getAggregationValues(d, ReportAggregationTypes.hasNoPhotoId)}
      />

      <Aggregation
        reports
        subject="HAS PRIMARY PAYER SOURCE ID"
        values={getAggregationValues(d, ReportAggregationTypes.hasInsuranceId)}
      />

      <Aggregation
        reports
        subject="WITHOUT PRIMARY PAYER SOURCE ID"
        values={getAggregationValues(
          d,
          ReportAggregationTypes.hasNoInsuranceId,
        )}
      />
    </>
  );
};

export const ReportPhotos = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.facilityName,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle
          title="Photo ID and Primary Payer Source ID"
          subtitle="by Admissions"
        />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>
      <RequiredUserPermission permission={TableUserDto.PermissionsEnum.PHOTOS}>
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.photos,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <div className="aggregation-row">
                {renderPhotosAggregations(d)}
              </div>
              <ListTableReporting
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Admissions found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportAdmission>
                  sortKey={SortField.residentName}
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.residentName}
                    </TextCellEllipsis>
                  )}
                  label="Resident name"
                  width={35}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.facilityName}
                    </TextCellEllipsis>
                  )}
                  label="Facility name"
                  sortKey={SortField.facilityName}
                  width={35}
                />
                <Column<ReportAdmission>
                  label="Primary payer source Id"
                  cellRenderer={row =>
                    row.rowData.hasInsuranceId ? (
                      <IconCheckCell accepted />
                    ) : (
                      <IconCrossCell color={theme.color.gray100} />
                    )
                  }
                  width={15}
                />
                <Column<ReportAdmission>
                  label="Photo Id"
                  sortKey={SortField.hasPhotoId}
                  cellRenderer={row =>
                    row.rowData.hasPhotoId ? (
                      <IconCheckCell accepted />
                    ) : (
                      <IconCrossCell color={theme.color.gray100} />
                    )
                  }
                  width={15}
                />
              </ListTableReporting>

              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
