import {RoleDto, TableUserDto} from "@reside/reside-api-app";

import {http} from "../../services/api";
import {
  UserId,
  UserPermissionsResponseItem,
  UsersByPermissionType,
} from "./user-permissions.common";
import {CancelTokenSource} from "axios";

interface IResolvedVal {
  success: boolean;
  data: UserPermissionsResponseItem[];
}

interface User {
  assignedFacilities?: any;
  fullName: string;
  facilities?: any[];
  facilityName: string;
  id: string;
  clerk: boolean;
  resident: boolean;
  resideAdmin: boolean;
  typeValue: RoleDto.NameEnum;
  role: {name: RoleDto.NameEnum};
}

interface ResponseUsersByPermission {
  category: "OVERVIEW_OVERVIEW" | "ANALYTICS_ADMISSIONS" | "REPORTS_ADMISSION";
  id: string;
  name: TableUserDto.PermissionsEnum;
  status: "ACTIVE";
  type: "REPORTING";
  users: User[];
}

export const resolverGetUserPermissions = async (
  userId: UserId,
  cancelTokenSource: CancelTokenSource,
): Promise<IResolvedVal> => {
  try {
    const response = await http.get<UserPermissionsResponseItem[]>(
      `/user-permissions/user/${userId}`,
      {
        cancelTokenSource,
      },
    );

    return {
      success: true,
      data: response.data,
    };
  } catch {
    return {
      success: false,
      data: [],
    };
  }
};

export const resolverGetPermissionTypeUsers = async (
  permissionType: "REPORTING",
  cancelTokenSource: CancelTokenSource,
): Promise<{
  success: boolean;
  data: UsersByPermissionType;
}> => {
  try {
    const response = await http.get<ResponseUsersByPermission[]>(
      `/user-permissions/${permissionType}`,
      {
        cancelTokenSource,
      },
    );

    const output = response.data.reduce<UsersByPermissionType>(
      (acc, permissionExtended) => ({
        ...acc,
        [permissionExtended.name]: permissionExtended.users.map(user => ({
          id: user.id,
          fullName: user.fullName,
          typeValue: user.typeValue,
          facilities: user.assignedFacilities,
          role: {
            name: user.role.name,
          },
          facilityName:
            user.assignedFacilities.length > 0
              ? user.assignedFacilities[0].facility.name
              : "",
        })),
      }),
      {},
    );

    return {
      success: true,
      data: output,
    };
  } catch {
    return {
      success: false,
      data: {},
    };
  }
};

export const resolverMyPermissions = async (
  cancelTokenSource: CancelTokenSource,
): Promise<IResolvedVal> => {
  try {
    const response = await http.get<UserPermissionsResponseItem[]>(
      "/user-permissions/",
      {cancelTokenSource},
    );

    return {
      success: true,
      data: response.data,
    };
  } catch {
    return {
      success: false,
      data: [],
    };
  }
};

export const resolverGrantPermission = async (
  userId: UserId,
  permission: TableUserDto.PermissionsEnum,
) => {
  try {
    await http.post(
      `/user-permissions/grant?permissionName=${permission}&userId=${userId}`,
    );

    return {success: true};
  } catch {
    return {success: false};
  }
};

export const resolverRevokePermission = async (
  userId: UserId,
  permission: TableUserDto.PermissionsEnum,
) => {
  try {
    await http.post(
      `/user-permissions/deny?permissionName=${permission}&userId=${userId}`,
    );

    return {success: true};
  } catch {
    return {success: false};
  }
};
