import React from "react";
import {Button} from "@reside/ui";
import {Admission} from "@reside/reside-api-admission";

import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {getResidentTestId} from "../../../helper";
import {
  ModalName,
  useDashboardModalContext,
} from "../../../DashboardModalContext";

export const ApproveAdmissionButton = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => {
  const {toggleModal} = useDashboardModalContext(ModalName.APPROVE);

  return (
    <Button
      size="small"
      onClick={() => {
        toggleModal(admission);
      }}
      testId={getResidentTestId(admission, Admission.StatusEnum.APPROVED)}
    >
      Approve
    </Button>
  );
};
