import update from "immutability-helper";
import {
  IReportingReportsAction,
  StateWithReportsType,
  isReportsDataPath,
  IReportingReportsDataState,
  SET_REPORTS,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  PUSH_REPORTS,
  isAdmissionResponse,
  isFacilityResponse,
  IReportingReportsPayload,
  isSlideResponse,
  isStaffResponse,
  PER_PAGE,
  IReportingReportsPayloadBase,
} from "./reporting-reports.common";
import {Reducer} from "redux";
import {
  StateWithFromTo,
  StateWithTo,
  StateWithFrom,
} from "../reporting/reporting.common";

const INITIAL_STATE_WITH_TYPE = {};

export const withReportsType: <S>(
  baseReducer: Reducer<S, IReportingReportsAction>,
) => Reducer<StateWithReportsType<S>, IReportingReportsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_TYPE, action) => {
    if (action.payload && isReportsDataPath(action.payload.path)) {
      const key = action.payload.path?.key || action.payload.path.type;

      const selectedState = state[key];

      return update(state, {
        [key]: {
          $apply: (obj: any) =>
            update(obj || {}, {$set: baseReducer(selectedState, action)}),
        },
      });
    }

    return state;
  };

const INITIAL_STATE_WITH_FROMTO = {};

const withFrom: <S>(
  baseReducer: Reducer<S, IReportingReportsAction>,
) => Reducer<StateWithFrom<S>, IReportingReportsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      const selectedState = state[action.payload.path.query.from];

      if (selectedState !== undefined) {
        return update(state, {
          [action.payload.path.query.from]: {
            $apply: (obj: any) => update(obj, {$set: baseReducer(obj, action)}),
          },
        });
      } else {
        return update(state, {
          [action.payload.path.query.from]: {
            $set: baseReducer(selectedState, action),
          },
        });
      }
    }

    return state;
  };

const withTo: <S>(
  baseReducer: Reducer<S, IReportingReportsAction>,
) => Reducer<StateWithTo<S>, IReportingReportsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      const selectedState = state[action.payload.path.query.to];

      if (selectedState !== undefined) {
        return update(state, {
          [action.payload.path.query.to]: {
            $apply: (obj: any) =>
              update(obj, {
                // this is bad because it trashes whole state under,
                $set: baseReducer(selectedState, action),
              }),
          },
        });
      } else {
        return update(state, {
          [action.payload.path.query.to]: {
            $set: baseReducer(selectedState, action),
          },
        });
      }
    }

    return state;
  };

export const withFromTo: <S>(
  baseReducer: Reducer<S, IReportingReportsAction>,
) => Reducer<StateWithFromTo<S>, IReportingReportsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      return withFrom(withTo(baseReducer))(state, action);
    }

    return state;
  };

const INITIAL_STATE_REPORTS = {};

const isReportingReportsPayload = (
  payload: IReportingReportsPayloadBase | IReportingReportsPayload,
): payload is IReportingReportsPayload =>
  (payload as IReportingReportsPayload).data !== undefined;

export function getRecords(
  payload: IReportingReportsPayloadBase | IReportingReportsPayload,
) {
  if (!isReportingReportsPayload(payload)) {
    return;
  }

  if (isReportsDataPath(payload.path)) {
    if (isFacilityResponse(payload.data, payload.path)) {
      return payload.data.facilityRecords;
    }
    if (isSlideResponse(payload.data, payload.path)) {
      return payload.data.slides;
    }
    if (isStaffResponse(payload.data, payload.path)) {
      return payload.data.staffRecords;
    }
    if (isAdmissionResponse(payload.data, payload.path)) {
      return payload.data.admissions;
    }
  }
}

function totalToPages(total: number) {
  return Math.ceil(total / PER_PAGE) || 0;
}

function reportsReducer(
  state: IReportingReportsDataState = INITIAL_STATE_REPORTS,
  action: IReportingReportsAction,
): IReportingReportsDataState {
  if (action.payload?.path && isReportsDataPath(action.payload.path)) {
    const records = getRecords(action.payload);

    switch (action.type) {
      case SET_REPORTS:
        return update(state, {
          data: {$set: records},
          total: {$set: action.payload.data.total},
          hasPhotoId: {$set: action.payload.data.hasPhotoId},
          hasNoPhotoId: {$set: action.payload.data.hasNoPhotoId},
          hasInsuranceId: {$set: action.payload.data.hasInsuranceId},
          hasNoInsuranceId: {$set: action.payload.data.hasNoInsuranceId},
          preFlight: {$set: action.payload.data.preFlight},
          inProgress: {$set: action.payload.data.inProgress},
          signed: {$set: action.payload.data.signed},
          approved: {$set: action.payload.data.approved},
          archived: {$set: action.payload.data.archived},
          cancelled: {$set: action.payload.data.cancelled},
          negativeAnswersTotal: {
            $set: action.payload.data.negativeAnswersTotal,
          },
          positiveAnswersTotal: {
            $set: action.payload.data.positiveAnswersTotal,
          },
          totalPages: {$set: totalToPages(action.payload.data.total)},
          currentPage: {$set: action.payload.page},
          perPage: {$set: action.payload.perPage},
          sortDirection: {$set: action.payload.path.query.sortDirection},
          sortField: {$set: action.payload.path.query.sortField},
          facilities: {$set: action.payload.path.query.facilities},
        });
      case PUSH_REPORTS:
        return update(state, {
          data: {
            $apply: (data: any) => update(data || [], {$push: records || []}),
          },
          total: {$set: action.payload.data.total},
          hasPhotoId: {$set: action.payload.data.hasPhotoId},
          hasNoPhotoId: {$set: action.payload.data.hasNoPhotoId},
          hasInsuranceId: {$set: action.payload.data.hasInsuranceId},
          hasNoInsuranceId: {$set: action.payload.data.hasNoInsuranceId},
          preFlight: {$set: action.payload.data.preFlight},
          inProgress: {$set: action.payload.data.inProgress},
          signed: {$set: action.payload.data.signed},
          approved: {$set: action.payload.data.approved},
          archived: {$set: action.payload.data.archived},
          cancelled: {$set: action.payload.data.cancelled},
          negativeAnswersTotal: {
            $set: action.payload.data.negativeAnswersTotal,
          },
          positiveAnswersTotal: {
            $set: action.payload.data.positiveAnswersTotal,
          },
          totalPages: {$set: totalToPages(action.payload.data.total)},
          currentPage: {$set: action.payload.page},
          perPage: {$set: action.payload.perPage},
          sortDirection: {$set: action.payload.path.query.sortDirection},
          sortField: {$set: action.payload.path.query.sortField},
          facilities: {$set: action.payload.path.query.facilities},
        });
      case FETCH_BEGIN:
        return {...state, fetching: true, success: undefined};
      case FETCH_SUCCESS:
        return {
          ...state,
          fetching: false,
          success: JSON.stringify(action.payload.path),
        };
      case FETCH_FAILURE:
        return {...state, fetching: false, success: undefined};
      default:
        return state;
    }
  }

  return state;
}

// pretoze reporty nemaju compare tak nepotrebujeme drzat viacero casovych rozsahov
export const reportsRootReducer = withReportsType(withFromTo(reportsReducer));
