import React from "react";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {AdmissionNotificationModel} from "@reside/reside-api-admission";
import {DropdownMenuItem} from "@reside/ui";

import {getResidentTestId} from "../../../helper";
import {hasPrimaryContactWithMail, hasResidentEmail} from "../../utils";
import {AdmissionsService} from "../../../../../services/AdmissionsService";
import {buildResponseErrorMessage} from "../../../../../services/api/errorHelpers";

export const ResendEmailDropdownItem = ({admission}: {admission: any}) => {
  const {mutate} = useMutation(
    () =>
      AdmissionsService.sendNotification(admission.id, {
        type: AdmissionNotificationModel.TypeEnum.ADMISSION_IN_PROGRESS,
      }),

    {
      onError: async response => {
        Alert.error(
          await buildResponseErrorMessage(
            response,
            "An error occurred while sending the email.",
          ),
        );
      },
      onSuccess: () => {
        Alert.success("Email has been successfully sent.");
      },
    },
  );

  return hasResidentEmail(admission) || hasPrimaryContactWithMail(admission) ? (
    <DropdownMenuItem
      onClick={() => {
        if (
          window.confirm(
            "This will send an email notification to the Resident and/or Resident Representative.",
          )
        ) {
          mutate();
        }
      }}
      testId={getResidentTestId(admission, "resendEmail")}
    >
      Resend Email
    </DropdownMenuItem>
  ) : null;
};
