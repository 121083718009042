import {use100vh} from "react-div-100vh";
import {css} from "@emotion/react";
import {styled} from "@reside/ui";
import {Slide} from "@reside/forms";

const FullscreenStyle = styled.div<Pick<Slide, "backgroundUrl"> & {h: number}>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-size: cover;
  background-position: center top;
  background-color: ${({theme}) => theme.color.white};

  @supports (height: 100dvh) {
    height: 100dvh;
  }
  @supports not (height: 100dvh) {
    height: ${({h}) => h}px;
  }

  ${({backgroundUrl}) =>
    backgroundUrl &&
    css`
      background-image: url(${backgroundUrl});
    `}

  *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: ${({theme}) => theme.color.gray20};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

/**
 * FullscreenBackground is a component that takes up the entire screen and can
 * display a background image. It is used in the Admission Explorer to display
 * the background image for the admission slides.
 *
 * @param backgroundUrl - The url of the background image to display.
 * @param children - The content to display on top of the background image.
 *
 * @returns A component that can display a background image that takes up the entire screen.
 *
 * @example
 * <FullscreenBackground backgroundUrl={backgroundUrl}>
 *  <div>Content to display on top of the background image</div>
 * </FullscreenBackground>
 *
 **/

export function FullscreenBackground({
  backgroundUrl,
  children,
}: {
  children: React.ReactNode;
  backgroundUrl?: string;
}) {
  const height = use100vh();
  return (
    <FullscreenStyle backgroundUrl={backgroundUrl} h={height}>
      {children}
    </FullscreenStyle>
  );
}
