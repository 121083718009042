import React from "react";

import {Banner} from "../../atoms/banner";
import {ButtonLink} from "../../atoms/button-link";

export const Page404 = () => (
  <Banner title="This page no longer exists.">
    <ButtonLink to="/">Back Home</ButtonLink>
  </Banner>
);
