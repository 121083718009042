import {Modal, ModalContent, Text, styled, H2, Box} from "@reside/ui";
import {useQuery} from "react-query";
import {useChangeHistoryModalContext} from "./ChangeHistoryModalContext";
import {AdmissionsService} from "../../../../services/AdmissionsService";
import {getAdmissionResidentName} from "../../helper";
import {parseISO, format} from "date-fns";
import {AdmissionStatusTransition} from "@reside/reside-api-admission";
import {mapTransitionStatus} from "../../../../utils/helpers/mapTransitionStatus";

export const ChangeHistoryModal = () => {
  const {admission, closeModal} = useChangeHistoryModalContext();

  return (
    <ChangeHistoryModalRenderer
      isOpen={Boolean(admission)}
      onClose={() => {
        closeModal();
      }}
    />
  );
};

type ChangeHistoryModalContentProps = Readonly<{
  isOpen: boolean;

  isLoading?: boolean;
  onClose: () => void;
}>;

export const ChangeHistoryModalRenderer = ({
  isOpen,
  onClose,
}: ChangeHistoryModalContentProps) => {
  const {admission} = useChangeHistoryModalContext();

  const {data, isLoading} = useQuery({
    queryFn: () => AdmissionsService.transitions(admission.id),
    enabled: isOpen && Boolean(admission),
  });

  const admissionUser = admission ? getAdmissionResidentName(admission) : "";

  const renderContent = data?.data?.length ? (
    <div>
      <H2>{admissionUser} Document Change History</H2>
      <Box gap={6}>
        <Text color="gray100" fontWeight="bold" fontSize={14} as="p">
          Admission ID: <Text color="primary">{admission?.code}</Text>
        </Text>
        <Text color="gray100" fontWeight="bold" fontSize={14} as="p">
          Current Status:{" "}
          <Text color="primary">
            {mapTransitionStatus(
              admission?.status as AdmissionStatusTransition.StatusEnum,
            )}
          </Text>
        </Text>
      </Box>
      <table>
        <thead>
          <tr>
            <th>DATE</th>
            <th>STATUS</th>
            <th>USER</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((_admission, ix) => (
            <tr key={ix}>
              <TableData>
                {format(parseISO(_admission.created), "MMM-dd-yyyy")}
              </TableData>
              <TableData>
                <Text>{mapTransitionStatus(_admission.status)}</Text>
                <Text as="p" fontSize={1} color="gray100">
                  {_admission?.description}
                </Text>
              </TableData>
              <TableData>{_admission.username ?? ""}</TableData>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <Text fontSize={16}>No transitions found</Text>
  );

  return (
    <Modal size="small" isOpen={isOpen} onRequestClose={onClose}>
      {() => {
        return (
          <ModalContent>
            {isLoading ? <p>...</p> : <>{renderContent}</>}
          </ModalContent>
        );
      }}
    </Modal>
  );
};

const TableData = styled.td`
  padding: 8px 0px;
  width: 33.33%;
`;
