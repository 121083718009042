import {ReactElement, useEffect} from "react";
import {useHistory} from "react-router-dom";

export const useScrollToTopOnHistoryChange = ({
  selector,
}: {
  selector: string;
}) => {
  const {listen} = useHistory();

  useEffect(() => {
    return listen((location, action) => {
      const shouldHandle = ["PUSH", "REPLACE"].includes(action);

      if (!shouldHandle) {
        return;
      }

      requestAnimationFrame(() => scrollToTop(selector));
    });
  }, [selector, listen]);
};

export const UseScrollToTopOnHistoryChange = ({
  selector,
  children = null,
}: {
  selector: string;
  children?: ReactElement;
}) => {
  useScrollToTopOnHistoryChange({selector});

  return children;
};

export const scrollToTop = (selector = "html") => {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollTop = 0;
  }
};
