import React from "react";
import {connect} from "react-redux";
import {useFormikContext} from "formik";
import {useHistory} from "react-router-dom";
import {DataNode} from "rc-tree/lib/interface";
import {RoleDto} from "@reside/reside-api-app";
import {Button} from "@reside/ui";

import {usePointClickCareStatus} from "../../../../hooks/usePointClickCareStatus";
import {useFacilitySettingsParams} from "../../hooks/useFacilitySettingsParams";
import {SingleFacilityDropdownSelect} from "./SingleFacilityDropdownSelect";
import {Facilities} from "../../../../models/types/AdmissionForm";
import {FacilityDeleteButton} from "../facility-delete-button";
import {ButtonLink} from "../../../../atoms/button-link";
import {AdminRoles} from "../../../../atoms/admin-roles";
import {select, AppState} from "../../../../store";
import {Space} from "../../../../atoms/space";
import {Header} from "./styles";

export type Props = Readonly<{
  facilities: Facilities;
  facilitiesTree: DataNode[];
}>;

const Renderer = ({facilities, facilitiesTree}: Props) => {
  const {facilityId} = useFacilitySettingsParams();

  const history = useHistory();

  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  const setFacilityId = (facilityId: string) =>
    history.push(`/admin/facility/${facilityId}/facility-settings`);

  const facility = facilities.find(facility => facility.id === facilityId);

  const formik = useFormikContext();

  // TODO: remove vysypana ryza
  const activeId = facility?.id || facilitiesTree[0]?.children[0]?.key;

  return (
    <Header>
      <SingleFacilityDropdownSelect
        facilitiesTree={facilitiesTree}
        activeId={activeId}
        onChange={setFacilityId}
      />
      {isPccEnabled && (
        <ButtonLink to={`/admin/facility/${activeId}/activity-log`}>
          Activity Log
        </ButtonLink>
      )}

      <Space>
        {formik && (
          <>
            <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
              <FacilityDeleteButton />
            </AdminRoles>
            <ButtonLink to="/admin/dashboard">Cancel</ButtonLink>
            <Button
              color="success"
              testId="saveFacilitySettingsBtn"
              disabled={formik.isSubmitting || !formik.dirty}
              onClick={formik.submitForm}
            >
              {formik.isSubmitting ? "Saving..." : "Save"}
            </Button>
          </>
        )}
      </Space>
    </Header>
  );
};

export const FacilitySettingsHeader = connect((state: AppState) => ({
  facilities: select.adminSession.facilities(state),
  facilitiesTree: select.adminSession.facilitiesTree(state),
}))(Renderer);
