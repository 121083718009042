var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { styled, theme } from "../theme";
import { Icon } from "../icon";
import { Button } from "../button";
var getIconSize = function (buttonSize) {
    return buttonSize === "small" ? 10 : 12;
};
export var DropdownMenuIconButton = function (_a) {
    var open = _a.open, _b = _a.className, className = _b === void 0 ? "" : _b, props = __rest(_a, ["open", "className"]);
    return (React.createElement(Wrapper, { className: className },
        React.createElement(DropdownButton, __assign({ className: "icon-only" }, props),
            React.createElement(Icon, { name: open ? "up" : "down", size: getIconSize(props.size), color: theme.color.darkBlue100 }))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: inline-flex;\n"], ["\n  align-items: center;\n  display: inline-flex;\n"])));
var DropdownButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.icon-only.medium {\n    padding: 9px 13px 7px 13px;\n  }\n  &.icon-only.small {\n    padding: 6px 4px 4px 11px;\n  }\n  &.icon-only.large {\n    padding: 9px 25px 7px 25px;\n    min-width: auto;\n    min-height: 40px;\n    height: auto;\n    font-size: 16px;\n\n    ", " {\n      min-width: 80px;\n      height: 70px;\n      font-size: 20px;\n    }\n  }\n"], ["\n  &.icon-only.medium {\n    padding: 9px 13px 7px 13px;\n  }\n  &.icon-only.small {\n    padding: 6px 4px 4px 11px;\n  }\n  &.icon-only.large {\n    padding: 9px 25px 7px 25px;\n    min-width: auto;\n    min-height: 40px;\n    height: auto;\n    font-size: 16px;\n\n    ", " {\n      min-width: 80px;\n      height: 70px;\n      font-size: 20px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.lg;
});
var templateObject_1, templateObject_2;
