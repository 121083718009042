import React from "react";
import {Tag} from "@reside/ui";
import {resolveResidentStatus} from "../helpers";
import {ResidentStatus} from "../types";

export const ResidentStatusTag = ({
  status,
}: {
  status: ResidentStatus;
}): JSX.Element => {
  if (!status) return null;

  const {tagColor, tagText} = resolveResidentStatus(status);

  return <Tag color={tagColor}>{tagText}</Tag>;
};
