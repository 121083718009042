import {RoleDto} from "@reside/reside-api-app";

import {select} from "../../store";
import {createRouteGuard} from "../../routers/createRouteGuard";

const createAdminHasRoleGuard = (...roles: ReadonlyArray<RoleDto.NameEnum>) =>
  createRouteGuard({
    authenticatedSelector: state =>
      roles.includes(select.adminSession.userRoleName(state)),
    redirectPath: "/admin/dashboard",
  });

export const AdminIsNotResideAdminGuard = createAdminHasRoleGuard(
  RoleDto.NameEnum.CLERK,
  RoleDto.NameEnum.FACILITY_ADMIN,
  RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
  RoleDto.NameEnum.REGIONAL_ADMIN,
  RoleDto.NameEnum.ORGANIZATION_ADMIN,
);

export const AdminIsResideAdminGuard = createAdminHasRoleGuard(
  RoleDto.NameEnum.RESIDE_ADMIN,
);

export const AdminIsNotClerkGuard = createAdminHasRoleGuard(
  RoleDto.NameEnum.FACILITY_ADMIN,
  RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
  RoleDto.NameEnum.REGIONAL_ADMIN,
  RoleDto.NameEnum.ORGANIZATION_ADMIN,
  RoleDto.NameEnum.RESIDE_ADMIN,
);

export const AdminIsOrganizationAdminGuard = createAdminHasRoleGuard(
  RoleDto.NameEnum.RESIDE_ADMIN,
  RoleDto.NameEnum.ORGANIZATION_ADMIN,
);
