import React, {memo} from "react";
import {get} from "lodash";
import {TableUserDto} from "@reside/reside-api-app";

import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {
  AggregationType,
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsPercentageByFacilitySumAsMax,
  numberToPercent,
  withCompare,
  changeDataIds,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsAdmissionsStaffCompletionRateChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const {stack, max} =
      dataProcess_AnalyticsPercentageByFacilitySumAsMax(data);

    return (
      <BarChart
        data={stack}
        max={max}
        full={isFullReport}
        labelLeft="Staff"
        labelRight="Completion Rate"
        dataLegend={[{name: "Percentage of Approved Admissions"}]}
        axTickFormat={v => numberToPercent(v / max)}
        hoverDataPreprocessor={(values, line, index) => {
          const sum = get(
            line.original[index].aggregations,
            AggregationType.SUM,
            0,
          );
          const count = get(
            line.original[index].aggregations,
            AggregationType.COUNT,
            0,
          );

          return [`${sum}/${count}`];
        }}
        small
      />
    );
  },
);

export const STAFF_COMPLETION_RATE_CHART_ID = "staff-completion-rate" as const;

export const AnalyticsAdmissionsStaffCompletionRate = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.ADMISSION_COMPLETION_RATE}
  >
    <div className="chart-header">
      <CardTitle
        title="Admission Completion Rate Per Facility Staff"
        subtitle="by Facility"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.admissionsStaffCompletionRate,
        facilities: props.facilities,
        query: {...props.dateRange},
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsAdmissionsStaffCompletionRateChart
          data={changeDataIds(data)}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
