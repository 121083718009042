import React from "react";
import {RouteComponentProps} from "react-router-dom";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import {SftpConfigForm} from "./SftpConfigForm";
import {DocumentTitle} from "../../../atoms/document-title";
import {Spinner} from "../../../atoms/spinner";
import {HubAndSpokeService} from "../../../services/HubAndSpokeService";

export const SftpConfigCreate = ({history}: RouteComponentProps) => {
  const {mutate: createSftpConfig, isLoading} = useMutation(
    HubAndSpokeService.createSftpConfig,
    {
      onSuccess: () => {
        Alert.success("SFTP configuration was successfully created.");
        history.push("/admin/hub-and-spoke/sftp-configurations");
      },
      onError: async (error: any) => {
        Alert.error(error?.data?.detail);
      },
    },
  );
  return (
    <>
      <DocumentTitle title="Reside - Admin - New SFTP Configuration" />
      {!isLoading ? (
        <SftpConfigForm onSubmit={createSftpConfig} />
      ) : (
        <Spinner />
      )}
    </>
  );
};
