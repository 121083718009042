var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Field } from "formik";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { Manager, Popper, Reference } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";
import { css } from "@emotion/react";
import { css as classCss } from "@emotion/css";
import { ChecklistItem } from "@reside/reside-api-admission";
import { DATE_FORMAT } from "../../date-picker/date";
import { styled } from "../../theme";
import { DatePicker } from "../../date-picker";
import { Icon } from "../../icon";
import { Text } from "../../text";
import { Tooltip } from "../../tooltip";
import { Checkbox } from "../atoms/checkbox";
import { DropdownSelectList, DropdownSelectOption } from "../../dropdown-select";
import { useTaskCheckboxContext } from "./TaskCheckboxContext";
var CheckedStatusEnum = ChecklistItem.CheckedStatusEnum;
export { CheckedStatusEnum };
var defaultProps = {
    tooltipLength: 22,
    Checkbox: Checkbox,
    CheckboxWrapper: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n    margin-bottom: 0.625em;\n\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  "], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n    margin-bottom: 0.625em;\n\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  "]))),
    ClickOutsideWrapper: styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))),
    Label: styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    cursor: pointer;\n    width: 100%;\n    align-items: center;\n    padding: 4px 6px;\n\n    span {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      display: block;\n    }\n\n    ", ";\n\n    ", ";\n  "], ["\n    display: flex;\n    cursor: pointer;\n    width: 100%;\n    align-items: center;\n    padding: 4px 6px;\n\n    span {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      display: block;\n    }\n\n    ", ";\n\n    ", ";\n  "])), function (_a) {
        var disabled = _a.disabled, theme = _a.theme;
        return disabled
            ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            cursor: not-allowed;\n          "], ["\n            cursor: not-allowed;\n          "]))) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            &:hover {\n              background: ", ";\n            }\n          "], ["\n            &:hover {\n              background: ", ";\n            }\n          "])), theme.color.lightBlue10);
    }, function (_a) {
        var isOpened = _a.isOpened, theme = _a.theme;
        return isOpened && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        background: ", ";\n      "], ["\n        background: ", ";\n      "])), theme.color.lightBlue10);
    }),
    LabelText: styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 1.25em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 8px;\n\n    ", ";\n  "], ["\n    font-size: 1.25em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 8px;\n\n    ", ";\n  "])), function (_a) {
        var strikeThrough = _a.strikeThrough, theme = _a.theme;
        return strikeThrough && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        text-decoration: line-through;\n        color: ", ";\n      "], ["\n        text-decoration: line-through;\n        color: ", ";\n      "])), theme.color.darkBlue70);
    }),
    DatePicker: styled(DatePicker)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    z-index: 1000;\n  "], ["\n    z-index: 1000;\n  "]))),
    children: function (_a) {
        var Checkbox = _a.Checkbox, Label = _a.Label, FormattedDate = _a.FormattedDate, name = _a.name, hasCheckedStatus = _a.hasCheckedStatus;
        return (React.createElement(React.Fragment, null,
            React.createElement(Checkbox, null),
            React.createElement(Label, null),
            React.createElement(FormattedDate, null)));
    },
    renderNotApplicableStatusOption: function (_a) {
        var icon = _a.icon, onClick = _a.onClick, active = _a.active, StyledDropdownSelectOption = _a.StyledDropdownSelectOption;
        return (React.createElement(StyledDropdownSelectOption, { active: active, onClick: onClick },
            icon,
            "Not Applicable"));
    },
};
var emptyChecklistItem = {
    checked: false,
};
var emptyChecklistItemWithStatus = __assign(__assign({}, emptyChecklistItem), { checkedStatus: CheckedStatusEnum.OPEN });
var isFinalStatus = function (status) {
    return status === CheckedStatusEnum.NOT_APPLICABLE ||
        status === CheckedStatusEnum.COMPLETED;
};
/**
 * Adds the status to "bare" checklist item.
 * Necessary for items checked before the feature flag for CHECKLIST_ITEM_STATUS is turned off.
 */
var coalesceCheckedStatus = function (fieldValue) {
    var _a;
    return ((_a = fieldValue.checkedStatus) !== null && _a !== void 0 ? _a : (fieldValue.checked ? CheckedStatusEnum.COMPLETED : CheckedStatusEnum.OPEN));
};
export var TaskCheckboxField = function (_a) {
    var label = _a.label, name = _a.name, disabled = _a.disabled, onComplete = _a.onComplete, children = _a.children, CheckboxWrapper = _a.CheckboxWrapper, Checkbox = _a.Checkbox, Label = _a.Label, LabelText = _a.LabelText, ClickOutsideWrapper = _a.ClickOutsideWrapper, renderNotApplicableStatusOption = _a.renderNotApplicableStatusOption, tooltipLength = _a.tooltipLength, DatePicker = _a.DatePicker, size = _a.size, props = __rest(_a, ["label", "name", "disabled", "onComplete", "children", "CheckboxWrapper", "Checkbox", "Label", "LabelText", "ClickOutsideWrapper", "renderNotApplicableStatusOption", "tooltipLength", "DatePicker", "size"]);
    var _b = useTaskCheckboxContext(), openedCheckboxElement = _b.openedCheckboxElement, setOpenedCheckboxElement = _b.setOpenedCheckboxElement, setCheckboxClosed = _b.setCheckboxClosed;
    var emptyValue = emptyChecklistItemWithStatus;
    return (React.createElement(Field, { name: name }, function (_a) {
        var form = _a.form, _b = _a.field, _c = _b.value, fieldValue = _c === void 0 ? emptyValue : _c, field = __rest(_b, ["value"]);
        var currentCheckedStatus = coalesceCheckedStatus(fieldValue);
        var handleStatusChange = function (checkedStatus) {
            var _a;
            if (currentCheckedStatus === checkedStatus) {
                // Do nothing when picked the same status
                setCheckboxClosed();
                return;
            }
            form.setFieldTouched(name, true, true);
            var newValue = __assign(__assign({}, fieldValue), { checkedStatus: checkedStatus, checked: isFinalStatus(checkedStatus), checkedDate: (_a = fieldValue.checkedDate) !== null && _a !== void 0 ? _a : format(new Date(), DATE_FORMAT.ISO_DATE) });
            form.setFieldValue(name, newValue, true);
            setCheckboxClosed();
        };
        var handleDateChange = function (date, unit) {
            if (unit !== "day") {
                return;
            }
            form.setFieldTouched(name, true, true);
            var newValue = __assign(__assign({}, fieldValue), { checked: true, checkedDate: format(date, DATE_FORMAT.ISO_DATE) });
            form.setFieldValue(name, newValue, true);
            setCheckboxClosed();
        };
        var isFinal = isFinalStatus(currentCheckedStatus);
        var isOpened = (openedCheckboxElement === null || openedCheckboxElement === void 0 ? void 0 : openedCheckboxElement.name) === name;
        var isSmall = size === "small";
        return (React.createElement(Manager, null,
            React.createElement(Reference, null, function (_a) {
                var ref = _a.ref;
                return (React.createElement("div", { ref: ref },
                    React.createElement(CheckboxWrapper, null,
                        React.createElement(Label, { disabled: disabled, isOpened: isOpened }, children({
                            hasCheckedStatus: Boolean(fieldValue.checkedStatus),
                            name: name,
                            Checkbox: function (customProps) { return (React.createElement(React.Fragment, null,
                                React.createElement(Checkbox, __assign({}, field, props, { name: name, disabled: disabled, size: size, color: isFinal ? "mint100" : "primary", checked: fieldValue.checked ||
                                        currentCheckedStatus ===
                                            CheckedStatusEnum.IN_PROGRESS, Icon: function (iconProps) {
                                        return currentCheckedStatus ===
                                            CheckedStatusEnum.IN_PROGRESS ? (React.createElement(Icon, __assign({}, iconProps, { name: "angle-double-right", size: isSmall ? 16 : 20, className: isSmall
                                                ? classCss(templateObject_10 || (templateObject_10 = __makeTemplateObject(["margin-left: 1px;"], ["margin-left: 1px;"]))) : classCss(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n                                        margin-top: 1px;\n                                        margin-left: 2px;\n                                      "], ["\n                                        margin-top: 1px;\n                                        margin-left: 2px;\n                                      "]))) }))) : currentCheckedStatus ===
                                            CheckedStatusEnum.NOT_APPLICABLE ? (React.createElement(Icon, __assign({}, iconProps, { name: "block", size: isSmall ? 12 : 18, className: classCss(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n                                      margin-top: 2px;\n                                    "], ["\n                                      margin-top: 2px;\n                                    "]))) }))) : (React.createElement(Icon, __assign({}, iconProps, { name: "check", className: isSmall
                                                ? ""
                                                : classCss(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n                                        margin-top: 2px;\n                                        margin-left: 1px;\n                                      "], ["\n                                        margin-top: 2px;\n                                        margin-left: 1px;\n                                      "]))) })));
                                    }, onChange: function (event) {
                                        if (event.target.checked) {
                                            setOpenedCheckboxElement({
                                                name: name,
                                                element: "STATUS_DROPDOWN",
                                            });
                                        }
                                        else {
                                            form.setFieldTouched(name, true, true);
                                            form.setFieldValue(name, emptyValue, true);
                                        }
                                    } }, customProps)),
                                React.createElement(Icon, { className: classCss(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n                                margin-left: 3px;\n                              "], ["\n                                margin-left: 3px;\n                              "]))), size: isSmall ? 8 : 10, color: disabled ? "darkBlue30" : "darkBlue70", name: isOpened &&
                                        openedCheckboxElement.element ===
                                            "STATUS_DROPDOWN"
                                        ? "up"
                                        : "down" }))); },
                            Label: function () { return (React.createElement(WithTooltip, { visible: tooltipLength < label.length, label: label, disabled: disabled },
                                React.createElement(LabelText, { strikeThrough: isFinal }, label))); },
                            FormattedDate: function (customProps) { return (React.createElement(FormattedDate, __assign({ disabled: disabled, onClick: function (event) {
                                    if (!disabled) {
                                        /**
                                         * When date is clicked, we don't want to uncheck the checkbox, only update the date.
                                         */
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenedCheckboxElement({
                                            name: name,
                                            element: "DATEPICKER",
                                        });
                                    }
                                } }, customProps), fieldValue.checkedDate
                                ? format(parseISO(fieldValue.checkedDate), DATE_FORMAT.SHORT_DAY_IN_MONTH)
                                : null)); },
                        })))));
            }),
            isOpened && openedCheckboxElement.element === "STATUS_DROPDOWN" ? (React.createElement(ClickOutsideWrapper, null,
                React.createElement(OutsideClickHandler, { onOutsideClick: function () {
                        form.setFieldTouched(name, true, true);
                        setCheckboxClosed();
                    } },
                    React.createElement(Popper, { placement: "bottom-start" }, function (_a) {
                        var ref = _a.ref, style = _a.style, placement = _a.placement;
                        return (React.createElement(StyledDropdownSelectList, { ref: ref, style: style, "data-placement": placement },
                            React.createElement(StyledDropdownSelectOption, { active: currentCheckedStatus === CheckedStatusEnum.OPEN, onClick: function () {
                                    if (currentCheckedStatus === CheckedStatusEnum.OPEN) {
                                        // Do "nothing" if picking the same status
                                        setCheckboxClosed();
                                        return;
                                    }
                                    // Update checklist only when had status
                                    form.setFieldTouched(name, true, true);
                                    form.setFieldValue(name, emptyValue, true);
                                    setCheckboxClosed();
                                } },
                                React.createElement(Icon, { size: 14, color: "darkBlue70", name: "circle-empty" }),
                                "Open"),
                            React.createElement(StyledDropdownSelectOption, { active: currentCheckedStatus ===
                                    CheckedStatusEnum.IN_PROGRESS, onClick: function () {
                                    return handleStatusChange(CheckedStatusEnum.IN_PROGRESS);
                                } },
                                React.createElement(Icon, { size: 18, color: "primary", name: "angle-double-right" }),
                                "In Progress"),
                            React.createElement(StyledDropdownSelectOption, { active: currentCheckedStatus === CheckedStatusEnum.COMPLETED, onClick: function () {
                                    var setStatusCompleted = function () {
                                        return handleStatusChange(CheckedStatusEnum.COMPLETED);
                                    };
                                    onComplete
                                        ? onComplete({
                                            setCheckboxClosed: setCheckboxClosed,
                                            setStatusCompleted: setStatusCompleted,
                                        })
                                        : setStatusCompleted();
                                } },
                                React.createElement(Icon, { size: 14, color: "mint100", name: "check" }),
                                "Completed"),
                            renderNotApplicableStatusOption({
                                StyledDropdownSelectOption: StyledDropdownSelectOption,
                                onClick: function () {
                                    return handleStatusChange(CheckedStatusEnum.NOT_APPLICABLE);
                                },
                                active: currentCheckedStatus ===
                                    CheckedStatusEnum.NOT_APPLICABLE,
                                icon: (React.createElement(Icon, { size: 18, color: "mint100", name: "block", className: classCss(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n                                top: 2px;\n                              "], ["\n                                top: 2px;\n                              "]))) })),
                            })));
                    })))) : isOpened && openedCheckboxElement.element === "DATEPICKER" ? (React.createElement(ClickOutsideWrapper, null,
                React.createElement(OutsideClickHandler, { onOutsideClick: function () {
                        form.setFieldTouched(name, true, true);
                        setCheckboxClosed();
                    } },
                    React.createElement(Popper, { placement: "bottom" }, function (_a) {
                        var ref = _a.ref, style = _a.style;
                        return (React.createElement(DatePicker, { ref: ref, style: style, date: deserializeDateValue(fieldValue.checkedDate), range: {
                                before: [getDateRange(15), "day"],
                                after: [getDateRange(1), "day"],
                            }, onChange: handleDateChange }));
                    })))) : null));
    }));
};
TaskCheckboxField.defaultProps = defaultProps;
export var TaskCheckboxSmall = function (props) { return (React.createElement(TaskCheckboxField, __assign({}, props))); };
TaskCheckboxSmall.defaultProps = __assign(__assign({}, TaskCheckboxField.defaultProps), { size: "small", Checkbox: styled(TaskCheckboxField.defaultProps.Checkbox)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    border-width: 1px;\n  "], ["\n    border-width: 1px;\n  "]))), CheckboxWrapper: styled(TaskCheckboxField.defaultProps.CheckboxWrapper)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "], ["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "]))), Label: styled(TaskCheckboxField.defaultProps.Label)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    line-height: 1;\n  "], ["\n    line-height: 1;\n  "]))), LabelText: styled(TaskCheckboxField.defaultProps.LabelText)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    font-size: 1em;\n  "], ["\n    font-size: 1em;\n  "]))) });
export var TaskCheckboxFadedSmall = function (props) { return (React.createElement(TaskCheckboxField, __assign({}, props))); };
TaskCheckboxFadedSmall.defaultProps = __assign(__assign({}, TaskCheckboxField.defaultProps), { size: "small", Checkbox: styled(TaskCheckboxField.defaultProps.Checkbox)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    border-width: 1px;\n\n    ", "\n  "], ["\n    border-width: 1px;\n\n    ", "\n  "])), function (_a) {
        var checked = _a.checked, disabled = _a.disabled, theme = _a.theme;
        return !checked &&
            !disabled && css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n        border-color: ", ";\n        background: ", ";\n      "], ["\n        border-color: ", ";\n        background: ", ";\n      "])), theme.color.darkBlue100, theme.color.white);
    }), CheckboxWrapper: styled(TaskCheckboxField.defaultProps.CheckboxWrapper)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n\n    ", "\n  "], ["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n\n    ", "\n  "])), function (_a) {
        var checked = _a.checked, disabled = _a.disabled, theme = _a.theme;
        return (checked || disabled) && css(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n        color: ", ";\n      "], ["\n        color: ", ";\n      "])), theme.color.gray100);
    }), Label: styled(TaskCheckboxField.defaultProps.Label)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    line-height: 1;\n  "], ["\n    line-height: 1;\n  "]))), LabelText: styled(TaskCheckboxField.defaultProps.LabelText)(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n    padding: 0 5px;\n    font-size: 1em;\n  "], ["\n    padding: 0 5px;\n    font-size: 1em;\n  "]))) });
var getDateRange = function (years) {
    var today = new Date();
    var nextYear = today.getFullYear() + years;
    var expectedDay = new Date(nextYear, 11, 31);
    return differenceInCalendarDays(expectedDay, today);
};
var deserializeDateValue = function (value) {
    return value ? parseISO(value) : new Date();
};
var WithTooltip = function (_a) {
    var children = _a.children, visible = _a.visible, label = _a.label, disabled = _a.disabled;
    return (React.createElement(React.Fragment, null, visible ? (React.createElement(StyledTooltip, { content: label, disabled: disabled }, children)) : (children)));
};
var FormattedDate = styled.time(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  white-space: nowrap;\n  margin-left: auto;\n\n  ", ";\n"], ["\n  white-space: nowrap;\n  margin-left: auto;\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
var StyledTooltip = styled(Tooltip)(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n\n  ", ";\n"], ["\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
var StyledDropdownSelectList = styled(DropdownSelectList)(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  margin-top: 0;\n"], ["\n  margin-top: 0;\n"])));
var StyledDropdownSelectOption = styled(DropdownSelectOption)(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  position: relative;\n  padding: 8px 12px 8px 40px;\n\n  .icon {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 38px;\n    line-height: 33px;\n    display: inline-block;\n    text-align: center;\n  }\n"], ["\n  position: relative;\n  padding: 8px 12px 8px 40px;\n\n  .icon {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 38px;\n    line-height: 33px;\n    display: inline-block;\n    text-align: center;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31;
