import {DomainPropType, TextAnchorType, VictoryThemeDefinition} from "victory";
import {theme} from "@reside/ui";

export const colorScalePayer = [
  theme.color.darkBlue100,
  theme.color.primary,
  theme.color.slateBlue100,
  theme.color.mint100,
  theme.color.golden100,
  theme.color.deepOrange100,
];

export const colorScale = [
  theme.color.darkBlue100,
  theme.color.gray100,
  theme.color.slateBlue100,
  theme.color.mint100,
  theme.color.golden100,
  theme.color.deepOrange100,
];

export const colorScaleRemaining = theme.color.gray10;

// TODO(future): use theme variables (drop '#')
export const colorScalePie = [
  "2BB7D9",
  "203c63",
  "ff3a62",
  "6cd7c3",
  "5dd9fc",
  "ff785e",
  "1bd760",
  "a8b6ca",
  "0358e1",
  "a600bc",
  "3582ff",
  "8482f9",
  "cc3636",
];

export const colorComparePositive = theme.color.green100;
export const colorCompareNegative = theme.color.orange100;

const colorsBlueGray = [theme.color.darkBlue100, theme.color.gray100];

const legendColorScale = [
  theme.color.darkBlue100,
  theme.color.gray100,
  theme.color.primary,
  theme.color.gray10,
];

// Layout
const padding = 8;

const baseProps = {
  padding: 50,
  width: 300,
  height: 300,
  colorScale,
};

// Labels
const baseLabelStyles = {
  fontFamily: "'Gilroy', sans-serif",
  fontSize: 13,
  letterSpacing: "normal",
  padding,
  fill: theme.color.primary,
  stroke: "transparent",
  strokeWidth: 0,
};

const baseLegendLabelStyles = {
  fontFamily: "'Gilroy', sans-serif",
  fontSize: 11,
  letterSpacing: "normal",
  padding,
  fill: theme.color.darkBlue100,
};

const centeredLabelStyles = {
  textAnchor: "middle" as TextAnchorType,
  ...baseLabelStyles,
};

// Strokes
const strokeLinecap = "round";
const strokeLinejoin = "round";

export const chartTheme: VictoryThemeDefinition = {
  axis: {
    ...baseProps,
    style: {
      axis: {
        fill: "transparent",
        stroke: theme.color.borderGray,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: {
        ...centeredLabelStyles,
        padding,
        stroke: "transparent",
      },
      grid: {
        fill: "none",
        stroke: theme.color.borderLightGray,
        strokeDasharray: "0, 0",
        strokeLinecap,
        strokeLinejoin,
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        fontSize: 15,
        stroke: "transparent",
        strokeWidth: 0,
        strokeLinecap,
        strokeLinejoin,
      },
      tickLabels: {
        ...baseLabelStyles,
        fill: theme.color.darkBlue70,
      },
    },
  },
  legend: {
    ...baseProps,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      labels: baseLegendLabelStyles,
      title: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
    colorScale: legendColorScale,
  },
  bar: {
    ...baseProps,
    colorScale: colorsBlueGray,
    style: {
      data: {
        opacity: 1,
        width: 20,
        fill: theme.color.darkBlue100,
      },
    },
  },
  line: {
    ...baseProps,
    style: {
      data: {
        fill: "transparent",
        opacity: 1,
        stroke: theme.color.primary,
        strokeWidth: 1,
      },
      labels: centeredLabelStyles,
    },
  },
  pie: {
    width: 400,
    height: 400,
    colorScale,
    style: {
      data: {
        padding,
        stroke: theme.color.borderGray,
        strokeWidth: 1,
      },
      labels: {
        ...baseLabelStyles,
        padding: 20,
      },
    },
  },
  scatter: {
    ...baseProps,

    style: {
      data: {
        fill: theme.color.primary,
        opacity: 1,
        stroke: theme.color.white,
        strokeWidth: 1,
      },
      labels: centeredLabelStyles,
    },
  },
  stack: {
    ...baseProps,
  },
  tooltip: {
    style: {
      ...centeredLabelStyles,
      padding: 5,
      pointerEvents: "none",
    },
    flyoutStyle: {
      fill: theme.color.white,
      stroke: theme.color.darkBlue100,
      strokeWidth: 0.1,
      pointerEvents: "none",
    },
    cornerRadius: 5,
    pointerLength: 10,
  },
};

export type ChartProps<S> = Readonly<{
  data: S[];
  xFormat?: (datum: any, dataset?: any) => string;
  yFormat?: (datum: any, dataset?: any) => string;
  domain?: DomainPropType;
  full?: boolean;
  dataLegend?: any[];
}>;

export const getColorScale = (
  n: number,
  sourceColors: any[],
  ignoreRemainingColor?: boolean,
) => {
  let resultColors = [...sourceColors];

  if (n > sourceColors.length) {
    const numMissingColors = n - sourceColors.length;
    const fraction = Math.ceil(numMissingColors / (sourceColors.length - 1));
    let sourceColorIndex = 0;
    let index = 1;

    while (resultColors.length < n) {
      const sourceColor = sourceColors[sourceColorIndex];
      const opacity = 255 - Math.floor((255 / (fraction + 1)) * index);
      const newColor = sourceColor + opacity.toString(16);

      resultColors.splice(sourceColorIndex + index, 0, newColor);
      if (index === fraction) {
        index = 0;
        sourceColorIndex++;
      }
      index++;
    }
  } else {
    resultColors = resultColors.filter((_, i) => i < n);
  }
  if (ignoreRemainingColor) {
    return resultColors;
  } else {
    resultColors.pop();
    resultColors.push(colorScaleRemaining);

    return resultColors;
  }
};

export const findValueOrZero = (
  values: {x: string; y: number}[],
  legend: string,
) => {
  const result = values.find(element => {
    return element.x === legend;
  });

  return result ? result.y : 0;
};
