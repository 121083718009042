import React from "react";
import {Button} from "@reside/ui";
import Alert from "react-s-alert";
import {useMutation} from "react-query";

import {UserService} from "../../services/UserService";

type Props = {
  email: string;
  successMessage?: string;
};

export const ResetPasswordButton = ({
  email,
  successMessage = "Email for password reset has been sent.",
}: Props) => {
  const {mutate: resetPassword, isLoading} = useMutation(
    (email: string) => UserService.resetPassword({email}),
    {
      onSuccess: () => {
        Alert.success(successMessage);
      },
      onError: () => {
        Alert.error("An error occurs when resetting password.");
      },
    },
  );

  return (
    <Button
      style={{marginTop: "24px"}}
      testId="resetPassword"
      color="primary"
      outline
      disabled={isLoading}
      onClick={() => resetPassword(email)}
    >
      Reset password
    </Button>
  );
};
