import React, {useMemo} from "react";
import {useQuery} from "react-query";
import {RouteComponentProps} from "react-router-dom";
import {styled} from "@reside/ui";

import {AdmissionsService} from "../../services/AdmissionsService";
import {AdminLayout} from "../../atoms/admin-layout";
import {AdminActionRow} from "../../atoms/admin-action-row";
import {DocumentTitle} from "../../atoms/document-title";
import {AdminAdmissionsTable} from "../../atoms/admin-admissions-table";
import {AdminAdmissionsSearch} from "../../atoms/admin-search";
import {Spinner} from "../../atoms/spinner";

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const PageAdminSearch = ({location}: RouteComponentProps) => {
  const phrase = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("phrase");
  }, [location.search]);

  const {data, refetch} = useQuery(
    ["searchAdmissions", phrase],
    async ({queryKey: [_, phrase]}) => {
      const {data} = await AdmissionsService.search(phrase);

      return data.content;
    },
  );

  return (
    <AdminLayout>
      <DocumentTitle title="Reside - Admin - Search Results" />
      <AdminActionRow
        title="Search Admissions"
        search={<AdminAdmissionsSearch phrase={phrase} />}
      />
      <Wrapper>
        {data ? (
          <AdminAdmissionsTable
            tableName={Math.random().toString()}
            admissions={data}
            onAdmissionDeleted={() => {
              refetch();
            }}
          />
        ) : (
          <Spinner />
        )}
      </Wrapper>
    </AdminLayout>
  );
};
