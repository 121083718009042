import {styled} from "@reside/ui";

export const DashboardCell = styled.div`
  background-color: ${({theme}) => theme.color.white};
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 #6276916b;
  margin-bottom: 15px;
  padding: 15px;
`;

export const CellHeader = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

export const CellTextBig = styled.div`
  font-size: 17px;
  font-weight: ${({theme}) => theme.fontWeight.heavy};
  line-height: 20px;
  word-break: break-all;
  * {
    color: ${({theme}) => theme.color.darkBlue100};
  }
`;

export const CellTextSmall = styled.span`
  color: ${({theme}) => theme.color.gray100};
  font-size: 14px;
  font-weight: ${({theme}) => theme.fontWeight.medium};
  line-height: 16px;
`;

export const CellFooter = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
`;

export const Right = styled.div`
  display: flex;
`;
