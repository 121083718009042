import React from "react";
import {Icon, Button, Text, styled, theme} from "@reside/ui";
import {format} from "date-fns";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router";
import {Column, ListTable} from "../../../atoms/list-table";
import {getAge, parseAPIDate} from "../helpers";
import {TextCell} from "../../../atoms/list-table/SharedCells";
import {Resident} from "../types";
import {useLinkResidentToAdmissionContext} from "../context/useLinkResidentToAdmissionContext";
import {Radio} from "./styles";
import {useFetchResidentsViaPost} from "../hooks/useFetchResidentsViaPost";
import {BackdropSpinner} from "../../../atoms/spinner";
import {AppState, select} from "../../../store";
import {useFilterResidentsContext} from "../context/useFilterResidentsContext";
import {ResidentStatusTag} from "./ResidentStatusTag";
import {UserDto} from "@reside/reside-api-app";
import {isResideAdmin} from "../../../utils/helpers/users";

type Row = {rowData: Resident; rowIndex: number};

const HouseIcon = styled(Icon)`
  margin-right: 6px;
`;

const ResidentsListTableRenderer = ({
  /** Link to resident details should not be shown everywhere (eg. in modal) */
  openDetailsOnClick = true,
  activeFacilitiesTreeKeys,
  user,
}: {
  openDetailsOnClick?: boolean;
  activeFacilitiesTreeKeys: [];
  user: UserDto;
}): JSX.Element => {
  const CellRenderer = (value: string | number | Date) => (
    <TextCell>{value}</TextCell>
  );

  const AdminCellRenderer = (name: string, organization: string) => (
    <div style={{lineHeight: "20px"}}>
      <Text>{name}</Text>
      <br />
      <HouseIcon size={14} color={theme.color.gray100} name="house" />
      <Text color="gray100">{organization}</Text>
    </div>
  );

  const history = useHistory();
  const {resident_uuid} = useParams<{resident_uuid: string}>();
  const {searchTerm, selectedStatus} = useFilterResidentsContext();

  const {
    residents,
    isFetchingResidents,
    sort,
    loadMoreResidents,
    totalCount,
    pageSize,
  } = useFetchResidentsViaPost(
    searchTerm,
    activeFacilitiesTreeKeys,
    selectedStatus,
  );

  const {admission} = useLinkResidentToAdmissionContext();

  return (
    <BackdropSpinner active={isFetchingResidents}>
      <ListTable<Resident>
        key="12345"
        data={residents || []}
        sortPredicates={{
          name: [],
          date_of_birth: [],
          created: [],
          created_by: [],
        }}
        getRowId={row => row.rowData.id}
        noRowsMessage="No residents to display"
        showDoubleArrowOnNotSorted
        onSort={(sortBy, sortDirection) =>
          sort(sortBy, sortDirection.toLowerCase())
        }
        onRowClick={(_, row) =>
          openDetailsOnClick && history.push(`residents/${row.rowData.id}`)
        }
      >
        <Column
          cellRenderer={({rowData}: Row) => {
            return !openDetailsOnClick ? (
              <Radio
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                }}
                name="resident"
                type="radio"
                disabled={rowData.id === resident_uuid}
                /** Stringify object, so it can be used as a value of radio button. */
                value={JSON.stringify({
                  resident_id: rowData?.id,
                  admission_id: admission?.id,
                })}
              />
            ) : null;
          }}
          width={!openDetailsOnClick ? 100 : 10}
        />

        <Column
          cellRenderer={({rowData}: Row) => {
            const name = `${rowData.first_name} ${
              rowData.middle_name ? rowData.middle_name : ""
            } ${rowData.last_name}`;

            return isResideAdmin(user)
              ? AdminCellRenderer(name, rowData.organization.name)
              : CellRenderer(name);
          }}
          label="Resident"
          sortKey="name"
          width={250}
        />
        <Column
          cellRenderer={({rowData}: Row) => CellRenderer(rowData.resident_id)}
          label="Resident ID"
          width={200}
        />
        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(
              rowData.date_of_birth
                ? format(parseAPIDate(rowData.date_of_birth), "MM/dd/yyyy")
                : "—",
            )
          }
          label="Date of Birth"
          sortKey="date_of_birth"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(
              rowData.date_of_birth
                ? getAge(rowData.date_of_birth).toString()
                : "—",
            )
          }
          label="Age"
          width={100}
        />
        <Column
          cellRenderer={({rowData}: Row) => CellRenderer(rowData.gender)}
          label="Gender"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(rowData.admissions.length)
          }
          label="Admissions"
          width={150}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(format(new Date(rowData.created), "MM/dd/yyyy"))
          }
          label="Created"
          sortKey="created"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) => (
            <ResidentStatusTag status={rowData?.status} />
          )}
          label="Status"
          width={150}
        />
      </ListTable>

      {pageSize < totalCount && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            marginTop: "30px",
            height: "90px",
          }}
        >
          <Button onClick={() => loadMoreResidents()}>Load More</Button>
          <Text fontWeight="bold">
            {pageSize} of {totalCount}
          </Text>
        </div>
      )}
    </BackdropSpinner>
  );
};

const mapState = (state: AppState) => {
  return {
    activeFacilitiesTreeKeys:
      select.adminSession.activeFacilitiesTreeKeys(state) ||
      select.adminSession.activeFacilitiesIds(state), // Until any facility is selected in filter activeFacilitiesTreeKeys has undefined value. In that case provide ids of all facilities so that filtering is correct
    user: select.adminSession.user(state),
  };
};

export const ResidentsListTable = connect(mapState)(ResidentsListTableRenderer);
