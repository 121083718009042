var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState, } from "react";
import { Menu } from "@headlessui/react";
import { styled } from "../theme";
export var DropdownMenu = function (_a) {
    var trigger = _a.trigger, children = _a.children, closeOnScroll = _a.closeOnScroll, onOpen = _a.onOpen, onClose = _a.onClose;
    return (React.createElement(Menu, { as: Container }, function (_a) {
        var close = _a.close, open = _a.open;
        return (React.createElement(React.Fragment, null,
            closeOnScroll ? React.createElement(CloseOnScroll, { close: close }) : undefined,
            React.createElement(TrackEvents, { open: open, onOpen: onOpen, onClose: onClose }),
            React.createElement(Menu.Button, { as: "div" }, function (_a) {
                var open = _a.open;
                return trigger(open);
            }),
            React.createElement(Menu.Items, { as: DropdownMenuStyled }, children)));
    }));
};
var CloseOnScroll = function (_a) {
    var close = _a.close, children = _a.children;
    var handleScroll = function () {
        close();
    };
    useEffect(function () {
        window.addEventListener("scroll", handleScroll, true);
        return function () { return window.removeEventListener("scroll", handleScroll, true); };
    }, []);
    return React.createElement(React.Fragment, null, children);
};
var TrackEvents = function (_a) {
    var open = _a.open, onOpen = _a.onOpen, onClose = _a.onClose, children = _a.children;
    var _b = useState(open), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        if (open !== isOpen) {
            setIsOpen(open);
            if (!open && !!onClose)
                onClose();
            if (open && !!onOpen)
                onOpen();
        }
    }, [open]);
    return React.createElement(React.Fragment, null, children);
};
export var DropdownMenuStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  width: 270px;\n  margin-top: 8px;\n  padding: 10px;\n  max-height: 80vh;\n  background-color: ", ";\n  border-radius: 4px;\n  border: 1px solid ", ";\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);\n  overflow: auto;\n  z-index: 1000;\n\n  a {\n    text-decoration: none;\n  }\n\n  &:focus-visible {\n    outline: none;\n    box-shadow: 0px 0px 2px ", ",\n      0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  position: absolute;\n  right: 0;\n  width: 270px;\n  margin-top: 8px;\n  padding: 10px;\n  max-height: 80vh;\n  background-color: ", ";\n  border-radius: 4px;\n  border: 1px solid ", ";\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);\n  overflow: auto;\n  z-index: 1000;\n\n  a {\n    text-decoration: none;\n  }\n\n  &:focus-visible {\n    outline: none;\n    box-shadow: 0px 0px 2px ", ",\n      0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n"], ["\n  display: inline-block;\n  position: relative;\n"])));
var templateObject_1, templateObject_2;
