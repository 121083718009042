var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../theme";
export var Badge = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  background: ", ";\n  color: ", ";\n  font-size: 12px;\n  font-weight: 500;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  background: ", ";\n  color: ", ";\n  font-size: 12px;\n  font-weight: 500;\n  justify-content: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.deepOrange100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
});
var templateObject_1;
