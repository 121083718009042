import {
  ADMISSION_STATUS_ORDER,
  readableAdmissionStatus,
} from "../../../atoms/admission-status-tag";
import {Status} from "./types";

export const STATUSES: ReadonlyArray<Status> = [
  {id: null, label: "All"},
  ...ADMISSION_STATUS_ORDER.map(status => ({
    id: status,
    label: readableAdmissionStatus(status),
  })),
];
