import {createTransform} from "redux-persist";

const whitelistedKeys = ["slideIdx"];

export const makeTransform = modelName => {
  return createTransform(
    // transform state on its way to being serialized and persisted.
    inboundState => {
      return Object.entries(inboundState).reduce((prev, [id, state]) => {
        prev[id] = whitelistedKeys.reduce((prev, key) => {
          prev[key] = state[key];

          return prev;
        }, {});

        return prev;
      }, {});
    },
    // transform state being rehydrated
    outboundState => {
      return outboundState;
    },
    // define which reducers this transform gets called for.
    {whitelist: [modelName]},
  );
};
