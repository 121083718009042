import React from "react";
import {Formik, Form} from "formik";
import {useMutation} from "react-query";
import {RouteComponentProps} from "react-router-dom";
import {validator} from "@reside/forms";
import {
  Button,
  PublicFormLayout,
  Text,
  Paragraph,
  PasswordField,
  styled,
} from "@reside/ui";
import {VerificationUserDto} from "@reside/reside-api-app";

import {AsyncRequestError} from "../../atoms/error";
import {UserService} from "../../services/UserService";
import {ButtonLink} from "../../atoms/button-link";

type Props = RouteComponentProps<{token: string}>;

export const PageConfirmNewUser = ({
  match: {
    params: {token},
  },
}: Props) => {
  const {mutate, status, error} = useMutation(UserService.verifyAccount);

  return (
    <Formik<VerificationUserDto>
      initialValues={{
        password: "",
        confirmPassword: "",
        token,
      }}
      onSubmit={values => mutate(values)}
      validate={values =>
        validator.validateAll(
          values,
          {
            password: "required|min:8",
            confirmPassword: "required|same:password",
          },
          {
            same: "Password and confirmation password do not match.",
          },
        )
      }
    >
      {formik => (
        <PublicFormLayout
          title="Welcome to Reside!"
          footer={
            <Center>
              <ButtonLink
                to="/admin"
                underline
                style={{border: "none", background: "transparent"}}
              >
                Return to Log In
              </ButtonLink>
            </Center>
          }
        >
          {status === "success" ? (
            <Center>
              <Paragraph fontWeight="medium" fontSize={3}>
                Password was successfully created.
              </Paragraph>
            </Center>
          ) : (
            <Form>
              <Center>
                <Paragraph fontWeight="medium" fontSize={3}>
                  Your new account has been created.
                </Paragraph>
                <Paragraph fontWeight="medium" fontSize={3}>
                  Please setup your password which you will be using for RESIDE
                  login:
                </Paragraph>
              </Center>
              <PasswordField name="password" label="Password" />
              <PasswordField name="confirmPassword" label="Confirm Password" />
              <AsyncRequestError error={error} />
              <ButtonCenter>
                <Button
                  type="submit"
                  color="primary"
                  disabled={status === "loading"}
                  onClick={formik.submitForm}
                >
                  {status === "loading" ? (
                    <Text>Submitting...</Text>
                  ) : (
                    <Text>Submit</Text>
                  )}
                </Button>
              </ButtonCenter>
            </Form>
          )}
        </PublicFormLayout>
      )}
    </Formik>
  );
};

const ButtonCenter = styled.div`
  width: fit-content;
  margin: 18px auto;
`;

const Center = styled.div`
  text-align: center;
`;
