import React from "react";
import {format} from "date-fns";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import {Caption, Text} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {AdmissionFlag} from "@reside/reside-api-admission";

import "./Flag.scss";

type Props = Readonly<{
  // TODO: Remove as Flag should not know about slides
  slideIndex: number;
  onFlagDelete: (flagId: string) => Promise<void>;
  flag: AdmissionFlag;
  deleteDisabled: boolean;
}>;

export const Flag = ({
  slideIndex,
  onFlagDelete,
  flag,
  deleteDisabled,
}: Props) => {
  const {mutate, isLoading} = useMutation(onFlagDelete, {
    onSuccess: () => {
      Alert.success("The flag was successfully resolved.");
    },
    onError: () => {
      Alert.error("Failed to resolve flag.");
    },
  });

  const resolveFlag = async (flag: AdmissionFlag) => {
    if (
      window.confirm("Are you sure you want to mark this flag as “Resolved”?")
    ) {
      mutate(flag.id);
    }
  };

  return (
    <div className="admin-flag-container">
      {flag && (
        <div id={flag.id} className="admin-flag-container__flag">
          <div className="admin-flag-container__flag__caption-container">
            <Text fontWeight="bold" color="darkBlue80">
              {flag.author}
            </Text>
            <Caption className="admin-flag-container__flag__caption-container__caption">
              {format(new Date(flag.created), DATE_FORMAT.FULL_DATE_TIME)}
            </Caption>
          </div>
          <div className="admin-flag-container__flag__description-container">
            <Text className="admin-flag-container__flag__description-container__description">
              {flag.comment}
            </Text>
          </div>
          {!deleteDisabled && (
            <div
              className="admin-flag-container__flag__resolve-button"
              onClick={() => {
                if (!isLoading) {
                  resolveFlag(flag);
                }
              }}
              data-test-id={`resolveFlag-${slideIndex}`}
            >
              {isLoading ? "Resolving..." : "Resolved"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
