import {FormikHelpers} from "formik";
import {ValidationError} from "@reside/reside-api-admission";

export const getFormValidationErrors = (
  validationErrors: ReadonlyArray<ValidationError>,
) =>
  validationErrors.reduce<Record<string, string>>(
    (formErrors, {message, field}) => ({...formErrors, [field]: message}),
    {},
  );

export function setValidationErrors<T>(
  response: any,
  formikRef: FormikHelpers<T>,
) {
  formikRef.setErrors(
    getFormValidationErrors(response.data.validationErrors) as any,
  );
}
