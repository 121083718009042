import React from "react";
import {DropdownMenuItem} from "@reside/ui";

import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {getResidentTestId} from "../../../helper";
import {withAccessToken} from "../../../../../utils/url";

export const DownloadPartialPDFDropdownItem = ({
  admission,
  ...props
}: {
  admission: AdmissionFromSearch;
}) => (
  <>
    {admission.resources?.partialPdfs?.map(({downloadUrl, group}) => (
      <DropdownMenuItem
        key={group}
        onClick={() => {
          openPartialPDF(downloadUrl);
        }}
        testId={getResidentTestId(admission, `downloadPdf-${group}`)}
        {...props}
      >
        Download {group}
      </DropdownMenuItem>
    ))}
  </>
);

const openPartialPDF = (url: string) =>
  window.open(withAccessToken(url), "_blank");
