import {RouteComponentProps, useParams} from "react-router-dom";

export type PreflightRouteParams = Readonly<{
  facilityId: string;
  admissionId?: string;
}>;

export type PreflightRouteComponentProps =
  RouteComponentProps<PreflightRouteParams>;

export const createPreflightUrl = ({
  facilityId,
  admissionId = "",
}: PreflightRouteParams): string =>
  `/admin/facility/${facilityId}/admissions/${admissionId}`;

export const usePreflightRouteParams = () => useParams<PreflightRouteParams>();
