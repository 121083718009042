import {intersection, includes} from "lodash";
import {
  Template,
  Values,
  blockIsVisible,
  computeChildrenBlock,
  isResidentExperienceContextNode,
  isSlideNode,
  isTableOfContentNode,
  ResidentExperienceTemplate,
} from "@reside/forms";
import {Admission} from "@reside/reside-api-admission";

import {isFileReference} from "../utils/helpers/questions";
import {AdmissionAnswerObjects} from "../services/AdmissionsService";
import {getPreflightedAnswers} from "./AdmissionsModel.helpers";

export enum RepresentativeRoles {
  REPRESENTATIVE = "REPRESENTATIVE",
  RESIDENT = "RESIDENT",
}

// TODO: well-known answer ids?
export const REPRESENTATIVE_ROLE = "representative_role";
export const RESIDENT_FIRST_NAME = "resident_firstName";
export const RESIDENT_LAST_NAME = "resident_lastName";
export const RESIDENT_PCC_ACCOUNT_STATUS = "resident_pccAccountStatus";
export const RESIDENT_DATE_OF_BIRTH = "resident_dateOfBirth";
export const REPRESENTATIVE_FIRST_NAME = "representative_firstName";
export const REPRESENTATIVE_LAST_NAME = "representative_lastName";

export const MAIN_SIGNATURE = "mainSignature";
export const MAIN_SIGNATURE_DATE = "c896825d-a7eb-4772-8d57-a612d5a0311d";
export const MAIN_SIGNATURE_SLIDE_ID = "f99aee58-1402-49de-aa4f-88755303c905";

export const shouldClearSignatures = (values: Values, newValues: Values) => {
  const prevRole = values[REPRESENTATIVE_ROLE];
  const nextRole = (newValues ?? {})[REPRESENTATIVE_ROLE];

  if (!prevRole || !nextRole) {
    return false;
  }

  return prevRole !== nextRole;
};

export const calculatePrintName = (values: Values) => {
  const role = values[REPRESENTATIVE_ROLE];

  const residentName = `${values[RESIDENT_FIRST_NAME]} ${values[RESIDENT_LAST_NAME]}`;
  const representativeName = `${values[REPRESENTATIVE_FIRST_NAME]} ${values[REPRESENTATIVE_LAST_NAME]}`;

  return role === RepresentativeRoles.RESIDENT
    ? residentName
    : representativeName;
};

export const getQuestionType = (key: string, template: Template) => {
  if (Object.prototype.hasOwnProperty.call(template.references, key)) {
    const type = template.references[key].type;

    return {
      questionType: type,
      fileReference: isFileReference(type),
    };
  }

  return {};
};

export const getPreFlighted = (key: string, template: Template) => {
  if (!Object.prototype.hasOwnProperty.call(template.references, key)) {
    return false;
  }

  return Boolean(template.references[key]?.preFlighted);
};

export const getAnswersFromFormValues = ({
  values,
  admissionAnswersObjects,
  modifier,
  updated = Math.floor(Date.now() / 1000),
  template,
}: {
  values: Values;
  admissionAnswersObjects: AdmissionAnswerObjects;
  modifier: string;
  updated: number;
  template: Template;
}): AdmissionAnswerObjects =>
  Object.entries(values).reduce((answers, [key, value]) => {
    const hasAnswerObject = Object.prototype.hasOwnProperty.call(
      admissionAnswersObjects,
      key,
    );

    const hasRelevantValue =
      value !== "" && value !== undefined && value !== null;

    if (!hasAnswerObject && !hasRelevantValue) {
      return answers;
    }

    return {
      ...answers,
      [key]: {
        ...(admissionAnswersObjects[key] ?? {}),
        ...getQuestionType(key, template),
        preFlight: getPreFlighted(key, template),
        questionId: key,
        value,
        metadata: {
          modifier,
          updated,
        },
      },
    };
  }, {});

export function updateCompletedSlides({
  answers,
  values,
  visibleSlideIds,
  completedVisibleSlideIds,
}: {
  answers: Values;
  values: Values;
  visibleSlideIds: ReadonlyArray<string>;
  completedVisibleSlideIds: ReadonlyArray<string>;
}) {
  //This is just check to prevent having completedVisibleSlides different than actual available visibleSlides (e.g. can happen after changing of PayerSource)
  const updatedCompletedSlides = intersection(
    completedVisibleSlideIds,
    visibleSlideIds,
  );

  if (!shouldClearSignatures(answers, values)) {
    return updatedCompletedSlides;
  }

  return updatedCompletedSlides.filter(
    slideId => slideId !== MAIN_SIGNATURE_SLIDE_ID,
  );
}

export function updateLastViewedSlideIdx({
  lastViewedSlideIdx,
  visibleSlideIds,
  allSlideIds,
}: {
  lastViewedSlideIdx: number;
  visibleSlideIds: ReadonlyArray<string>;
  allSlideIds: ReadonlyArray<string>;
}) {
  //If LastSlide is not visible reset to first slide
  return includes(visibleSlideIds, allSlideIds[lastViewedSlideIdx])
    ? lastViewedSlideIdx
    : 0;
}

export function clearSignaturesIfRepresentativeDidChange({
  answers,
  values,
}: {
  answers: Values;
  values: Values;
}) {
  if (!shouldClearSignatures(answers, values)) {
    return values;
  }

  return {
    ...values,
    [MAIN_SIGNATURE]: "",
    [MAIN_SIGNATURE_DATE]: "",
  };
}

/**
 * Filter out pdf nodes & pdf sections, so only resident experience content stays.
 * Static step, should be done once after the template is fetched.
 */
export const extractResidentExperienceTemplate = (
  template: ResidentExperienceTemplate,
): Template => {
  const filterResidentExperienceNodes = (nodes: any) =>
    nodes
      .filter((node: any) => isResidentExperienceContextNode(node))
      .map((node: any) =>
        node.children
          ? {
              ...node,
              children: filterResidentExperienceNodes(node.children),
            }
          : node,
      );

  const pruneEmptyChildren = ({children, ...node}: any) => {
    if (!children) {
      return node;
    }

    const prunedChildren = children
      .map(pruneEmptyChildren)
      .filter((node: any) => !node.children || node.children.length > 0);

    return {
      ...node,
      children: prunedChildren,
    };
  };

  return pruneEmptyChildren({
    ...template,
    children: template.children.map(sectionGroup => ({
      ...sectionGroup,
      children: filterResidentExperienceNodes(sectionGroup.children),
    })),
  });
};

/**
 * Note: Its expected that the template no longer has PDF context nodes.
 */
export const extractResidentExperienceSlides = ({
  template,
  instance,
  answers,
  computed,
  computeChildrenBlocks = true,
}: {
  template: Template;
  instance: Admission;
  answers: Values;
  computed: Partial<{readOnly: boolean}>;
  computeChildrenBlocks: boolean;
}) => {
  const preflightedAnswers = getPreflightedAnswers(instance.answers);
  const slides: any[] = [];

  template.children.forEach((group, groupIndex, groups) => {
    const isLastGroup = groupIndex + 1 === groups.length;

    group.children.forEach((section, sectionIndex, sections) => {
      const isLastSection = sectionIndex + 1 === sections.length;
      const sectionIsVisible = blockIsVisible(answers, section);
      const sectionSlides = section.children.filter(isSlideNode);

      sectionSlides
        .filter(slide => !slide.afterAdmission)
        .forEach((slide, slideIndex, sectionSlides) => {
          const flag = instance.flags.find(flag => flag.slideId === slide.id);
          const [firstChild] = slide.children;

          slides.push({
            ...slide,
            children: computeChildrenBlocks
              ? slide.children.map(node =>
                  computeChildrenBlock({
                    node,
                    answers,
                    template,
                    computed,
                    preflightedAnswers,
                  }),
                )
              : slide.children,
            sectionId: section.id,
            sectionTitle: section.title,
            state: {
              complete:
                instance.completedSlides.includes(slide.id) ||
                isTableOfContentNode(firstChild),
              fullscreen:
                !!slide.backgroundUrl || isTableOfContentNode(firstChild),
              last:
                isLastGroup &&
                isLastSection &&
                slideIndex + 1 === sectionSlides.length,
              visible: sectionIsVisible && blockIsVisible(answers, slide),
              flag: flag ? flag.id : null,
            },
          });
        });
    });
  });

  return slides;
};

/**
 * Helper to read well-known answer ids.
 * Use the following accessor functions, instead of reading raw answers.
 */
const readAnswerValue = (answerId: string) => (admission?: Admission) =>
  admission?.answers?.[answerId]?.value ?? "";

export const readFacilityNameAnswer = readAnswerValue("facilityName");

export const readFacilityOrganizationNameAnswer = readAnswerValue(
  "facilityOrganizationName",
);

export const readResidentFirstNameAnswer = readAnswerValue(RESIDENT_FIRST_NAME);

export const readResidentLastNameAnswer = readAnswerValue(RESIDENT_LAST_NAME);

export const readResidentNameAnswer = (admission: Admission) =>
  `${readResidentFirstNameAnswer(admission)} ${readResidentLastNameAnswer(
    admission,
  )}`;

export const readResidentEmailAnswer = readAnswerValue("resident_email");

export const readResidentPhoneAnswer = readAnswerValue("resident_phone");

export const readDateOfAdmissionAnswer = readAnswerValue(
  "preflight_dateOfAdmission",
);

export const readResidentPccAccountStatus = readAnswerValue(
  RESIDENT_PCC_ACCOUNT_STATUS,
);
