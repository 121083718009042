import React, {
  createContext,
  useMemo,
  useState,
  ReactNode,
  useContext,
} from "react";
import {connect} from "react-redux";

import {
  Facility,
  IDateRangeGlobal,
} from "../../store/reporting/reporting.common";
import {select} from "../../store";
import {setActiveFacilities} from "../../store/reporting/reporting.actions";
import {DateRangePresets} from "../../atoms/reporting-compare";

const DEFAULT_DATE_RANGE_VALUE = 1;

type OwnProps = Readonly<{
  children: ReactNode;
}>;

type StateProps = Readonly<{
  activeFacilitiesIds: string[];
}>;

type DispatchProps = Readonly<{
  setActiveFacilities: typeof setActiveFacilities;
}>;

type Props = OwnProps & StateProps & DispatchProps;

export type Context = Readonly<{
  /**
   * Boolean used to show/hide full page spinner when the PDF export is in progress.
   */
  pageLoading: boolean;
  setPageLoading: (pageLoading: boolean) => void;
  baseDateRange: IDateRangeGlobal;
  activeFacilitiesIds: Facility[];
  setActiveFacilities: typeof setActiveFacilities;
  setBaseDateRange: (range: IDateRangeGlobal) => void;
}>;

const ReportContext = createContext<Context>(undefined);

type ConsumerProps = Readonly<{
  children: (context: Context) => ReactNode;
}>;

export const useReportContext = () => useContext(ReportContext);

export const ReportContextConsumer = ({children}: ConsumerProps) => (
  <ReportContext.Consumer>
    {context => {
      if (context === undefined) {
        throw new Error(
          "ReportContextConsumer must be used within the ReportContextProvider",
        );
      }

      return children(context);
    }}
  </ReportContext.Consumer>
);

export const ReportContextProvider = ({
  children,
  activeFacilitiesIds,
  setActiveFacilities,
}: Props) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [baseDateRange, setBaseDateRange] = useState<IDateRangeGlobal>({
    ...DateRangePresets[DEFAULT_DATE_RANGE_VALUE],
    to: DateRangePresets[DEFAULT_DATE_RANGE_VALUE].to(),
    from: DateRangePresets[DEFAULT_DATE_RANGE_VALUE].from(),
  });

  const value = useMemo(
    () => ({
      pageLoading,
      setPageLoading,
      baseDateRange,
      activeFacilitiesIds,
      setActiveFacilities,
      setBaseDateRange,
    }),
    [
      pageLoading,
      baseDateRange,
      activeFacilitiesIds,
      setActiveFacilities,
      setBaseDateRange,
    ],
  );

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};

export const mapStateToProps = (state: any) => ({
  activeFacilitiesIds: select.adminSession.activeFacilitiesIds(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  reset: dispatch.adminSession.reset,
  setActiveFacilities: (payload: Parameters<typeof setActiveFacilities>[0]) =>
    dispatch.adminSession.setActiveFacilities(payload),
});

export const ConnectedReportContextProvider = connect<
  StateProps,
  DispatchProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(ReportContextProvider);
