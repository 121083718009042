import React, {memo} from "react";

import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {CardTitle} from "../../../atoms/card-title";
import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsTimeByStage,
  withCompare,
  millisecondsToTimeDuration,
} from "./helpers";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsTimeByStageChart = memo(({data, isFullReport}: ChartProps) => {
  const barData = dataProcess_AnalyticsTimeByStage(data);

  return (
    <BarChart
      data={barData.stack}
      max={barData.max}
      labelLeft="Status"
      labelRight="Avg. Time"
      full={isFullReport}
      tickFormat={x => millisecondsToTimeDuration(x)}
      axTickFormat={x => millisecondsToTimeDuration(x)}
    />
  );
});

export const ADMISSIONS_TIME_BY_STAGE_CHART_ID = "time-by-stage" as const;

export const AnalyticsTimeByStage = (props: Props) => (
  <RequiredUserPermission
    permission={
      TableUserDto.PermissionsEnum.AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION
    }
  >
    <div className="chart-header">
      <CardTitle title="Life cycle of Admission" subtitle="by Facility" />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>

    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.timeByStage,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsTimeByStageChart data={data} isFullReport={isFullReport} />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
