import {useQuery} from "react-query";
import Alert from "react-s-alert";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useSftpConfigsQuery = () =>
  useQuery(
    ["GET_SFTP_CONFIGS"],
    async () => {
      const {data} = await HubAndSpokeService.getSftpConfigs();
      return data;
    },
    {
      onError: (err: any) => {
        Alert.error(err.json());
      },
    },
  );
