import React, {forwardRef, ReactNode, Ref} from "react";
import {styled, Icon, IconName, Color} from "@reside/ui";
import {css} from "@emotion/react";

const defaultProps = {
  disabled: false,
  frameless: false,
  color: "primary" as Color,
  testId: "",
  Button: styled.button`
    padding: 0;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    background-color: transparent;
    font-size: ${({theme}) => theme.fontSize[4]}px;
    line-height: 22px;
    font-family: Gilroy, sans-serif;
    color: ${({theme}) => theme.color.darkBlue100};

    &:hover {
      opacity: 0.5;
      transform: translateY(-1px);
    }
  `,
  Icon: styled(Icon)<{frameless?: boolean; background?: string}>`
    display: inline-block;
    margin-right: ${({theme}) => theme.space[2]}px;
    vertical-align: middle;

    ${({background}) =>
      background &&
      css`
        background: ${background};
      `};

    ${({frameless, theme}) =>
      !frameless &&
      css`
        width: 36px;
        height: 36px;
        line-height: 34px;
        text-align: center;
        border: 1px solid ${theme.color.primary};
        box-shadow: 0 2px 10px 0 rgba(117, 121, 128, 0.2);
        margin-right: ${theme.space[3]}px;
        border-radius: ${theme.space[1]}px;
      `};
  `,
  Title: styled.span`
    display: none;
    font-size: ${({theme}) => theme.fontSize[3]}px;
    margin-right: ${({theme}) => theme.space[3]}px;

    @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
      display: inline;
    }
  `,
};

type Props = Readonly<{
  iconName: IconName;
  background?: string;
  className?: string;
  color?: Color;
  disabled?: boolean;
  frameless?: boolean;
  testId?: string;
  onClick: () => void;
  children?: ReactNode;
  Button?: typeof defaultProps.Button;
  Icon?: typeof defaultProps.Icon;
  Title?: typeof defaultProps.Title;
}>;

export const IconButton = forwardRef(
  (
    {
      className,
      color,
      background,
      iconName,
      children,
      disabled,
      frameless,
      testId,
      onClick,
      Button,
      Icon,
      Title,
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <Button
      type="button"
      ref={ref}
      disabled={disabled}
      className={className}
      onClick={onClick}
      data-test-id={testId}
    >
      <Icon
        name={iconName}
        size={18}
        color={color}
        frameless={frameless}
        background={background}
      />
      <Title>{children}</Title>
    </Button>
  ),
);

IconButton.defaultProps = defaultProps;
