/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {flatten} from "lodash";

import {ConnectedReportingBlock} from "../../atoms/reporting-block";
import {RequiredUserPermissionsSome} from "../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {DocumentTitle} from "../../atoms/document-title";
import {ReportContextConsumer} from "./ReportContext";
import {getSectionItemsPermissions} from "./permissions.helpers";
import {
  analyticsResidentsAdmissionSection,
  analyticsResidentsArbitrationSection,
  analyticsResidentsMedicaidSection,
  analyticsResidentsMedicareSection,
  analyticsStaffAdmissionSection,
  reportsSlideAnswersAdmissionSection,
  reportsSlideAnswersAdvancedDirectiveSection,
  reportsSlideAnswersFuneralSection,
  reportsSlideAnswersLaundrySection,
  reportsSlideAnswersMedicaidSection,
  reportsSlideAnswersMedicareSection,
  reportsSlideAnswersSurrogateSection,
  reportsSlideAnswersTrustFundSection,
  reportsSlideAnswersVaccinesSection,
  reportsStaffAdmissionSection,
  ChartTypes,
} from "./constants";
import {
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  ADMISSIONS_TOTAL_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  AnalyticsAdmissionsCompletionOnTime,
  AnalyticsAdmissionsCompletionOverall,
  AnalyticsAdmissionsFacilityCompletionRate,
  AnalyticsAdmissionsStaffCompletionRate,
  AnalyticsAdmissionsTotal,
  AnalyticsAdmissionsTotalResidents,
  AnalyticsArbitrationAgreementRate,
  AnalyticsFlagsAge,
  AnalyticsFlagsBySlidePie,
  AnalyticsManagedMedicaidByFacility,
  AnalyticsManagedMedicaidTimeSeries,
  AnalyticsPayerSourceAge65_75,
  AnalyticsPayerSourceAge65_75ByCountry,
  AnalyticsPayerSourceAge75_85,
  AnalyticsPayerSourceAge75_85ByCountry,
  AnalyticsPayerSourceAge85,
  AnalyticsPayerSourceAge85ByCountry,
  AnalyticsReAdmissionRateByFacility,
  AnalyticsReAdmissionRateTimeSeries,
  AnalyticsRepresentativeRateByFacility,
  AnalyticsRepresentativeRateTimeSeries,
  AnalyticsTimeByStage,
  AnalyticsWithoutMedicalSupplementByFacility,
  AnalyticsWithoutMedicalSupplementTimeSeries,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  RE_ADMISSION_RATE_BY_FACILITY_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
} from "./analytics";
import {
  AD_HELP_REPORT_ID,
  AD_PROVIDED_REPORT_ID,
  FACILITY_CHANGES_REPORT_ID,
  FUNERAL_REPORT_ID,
  INCOME_REPORT_ID,
  INFLUENZA_REPORT_ID,
  LAUNDRY_REPORT_ID,
  MEDICAID_REQUEST_REPORT_ID,
  MEDICARE_WAIVER_REPORT_ID,
  PNEUMOCOCCAL_REPORT_ID,
  ReportADHelp,
  ReportExistingADProvided,
  ReportFacilityChanges,
  ReportFuneral,
  ReportInfluenza,
  ReportLaundry,
  ReportMedicaidRequest,
  ReportMedicareWaiver,
  ReportPersonalResourceIncomeAgreement,
  ReportPneumococcal,
  ReportSlideInteractions,
  ReportSNFABNEstimatedCostAdmission,
  ReportStaff,
  ReportSurrogateDecisionMaking,
  ReportSurrogateDecisionMakingHelp,
  ReportTrustFund,
  SLIDE_INTERACTIONS_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_REPORT_ID,
  SURROGATE_HELP_REPORT_ID,
  SURROGATE_REPORT_ID,
  TRUST_FUND_REPORT_ID,
} from "./reports";
import {ReportingLayout} from "./reporting-layout";
import {ReportingGroup} from "../../atoms/admin-reporting/atoms/reporting-group/ReportingGroup";
import {ReportingRow} from "../../atoms/admin-reporting/atoms/reporting-row";
import {Banner} from "../../atoms/banner";

const pagePermissions = getSectionItemsPermissions(
  flatten(
    [
      analyticsResidentsAdmissionSection,
      analyticsResidentsArbitrationSection,
      analyticsResidentsMedicaidSection,
      analyticsResidentsMedicareSection,
      analyticsStaffAdmissionSection,

      reportsSlideAnswersAdmissionSection,
      reportsSlideAnswersTrustFundSection,
      reportsSlideAnswersLaundrySection,
      reportsSlideAnswersVaccinesSection,
      reportsSlideAnswersSurrogateSection,
      reportsSlideAnswersAdvancedDirectiveSection,
      reportsSlideAnswersFuneralSection,
      reportsSlideAnswersMedicareSection,
      reportsSlideAnswersMedicaidSection,
      reportsStaffAdmissionSection,
    ].map(section => section.sectionItems),
  ),
);

export const PageReportingOther = () => (
  <>
    <ReportingLayout>
      <DocumentTitle title={"Reside - Admin - Reporting | Other"} />
      <RequiredUserPermissionsSome
        permissions={pagePermissions}
        noPermissionChildren={
          <Banner title="No reports are available for this section.">
            <p>Please choose a different report from the left menu.</p>
          </Banner>
        }
      >
        <ReportContextConsumer>
          {({baseDateRange, activeFacilitiesIds}) => (
            <>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  analyticsResidentsAdmissionSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={analyticsResidentsAdmissionSection.id}
                  title={analyticsResidentsAdmissionSection.title}
                >
                  <>
                    <ConnectedReportingBlock
                      overview
                      hideSeeFullReportLink
                      chartId={ADMISSIONS_TOTAL_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsAdmissionsTotal
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      chartId={ADMISSIONS_TOTAL_RESIDENTS_CHART_ID}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsAdmissionsTotalResidents
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        chartType={ChartTypes.analytics}
                        half
                        chartId={FACILITY_COMPLETION_RATE_CHART_ID}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsAdmissionsFacilityCompletionRate
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={STAFF_COMPLETION_RATE_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsAdmissionsStaffCompletionRate
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                    <ConnectedReportingBlock
                      chartId={ADMISSIONS_COMPLETION_ON_TIME_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsAdmissionsCompletionOnTime
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      chartId={ADMISSIONS_COMPLETION_OVERALL_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsAdmissionsCompletionOverall
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      chartId={FLAGS_BY_SLIDE_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <>
                          <AnalyticsFlagsBySlidePie
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        </>
                      )}
                    </ConnectedReportingBlock>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        half
                        chartId={REPRESENTATIVE_RATE_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsRepresentativeRateTimeSeries
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsRepresentativeRateByFacility
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_65_75_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge65_75
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge65_75ByCountry
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_75_85_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge75_85
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge75_85ByCountry
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_85_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge85
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={PAYER_SOURCE_85_BY_COUNTRY_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsPayerSourceAge85ByCountry
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                    <ReportingRow>
                      <ConnectedReportingBlock
                        half
                        chartId={RE_ADMISSION_RATE_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsReAdmissionRateTimeSeries
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                      <ConnectedReportingBlock
                        half
                        chartId={RE_ADMISSION_RATE_BY_FACILITY_CHART_ID}
                        chartType={ChartTypes.analytics}
                      >
                        {reportingBlockSetters => (
                          <AnalyticsReAdmissionRateByFacility
                            dateRange={baseDateRange}
                            facilities={activeFacilitiesIds}
                            reportingBlockSetters={reportingBlockSetters}
                          />
                        )}
                      </ConnectedReportingBlock>
                    </ReportingRow>
                  </>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  analyticsResidentsArbitrationSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={analyticsResidentsArbitrationSection.id}
                  title={analyticsResidentsArbitrationSection.title}
                >
                  <>
                    <ConnectedReportingBlock
                      chartId={ARBITRATION_AGREEMENT_RATE_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsArbitrationAgreementRate
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  analyticsResidentsMedicaidSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={analyticsResidentsMedicaidSection.id}
                  title={analyticsResidentsMedicaidSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock
                      half
                      chartId={MANAGED_MEDICAID_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsManagedMedicaidTimeSeries
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      half
                      chartId={MANAGED_MEDICAID_BY_FACILITY_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsManagedMedicaidByFacility
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  analyticsResidentsMedicareSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={analyticsResidentsMedicareSection.id}
                  title={analyticsResidentsMedicareSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock
                      half
                      chartId={WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsWithoutMedicalSupplementTimeSeries
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      half
                      chartId={WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID}
                      chartType={ChartTypes.analytics}
                    >
                      {reportingBlockSetters => (
                        <AnalyticsWithoutMedicalSupplementByFacility
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  analyticsStaffAdmissionSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={analyticsStaffAdmissionSection.id}
                  title={analyticsStaffAdmissionSection.title}
                >
                  <ConnectedReportingBlock
                    chartId={FLAGS_AGE_CHART_ID}
                    chartType={ChartTypes.analytics}
                  >
                    {reportingBlockSetters => (
                      <AnalyticsFlagsAge
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                  <ConnectedReportingBlock
                    chartId={ADMISSIONS_TIME_BY_STAGE_CHART_ID}
                    chartType={ChartTypes.analytics}
                  >
                    {reportingBlockSetters => (
                      <AnalyticsTimeByStage
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>

              {/* Reporting */}
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersAdmissionSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersAdmissionSection.id}
                  title={reportsSlideAnswersAdmissionSection.title}
                >
                  <ConnectedReportingBlock
                    chartId={SLIDE_INTERACTIONS_REPORT_ID}
                  >
                    {reportingBlockSetters => (
                      <ReportSlideInteractions
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                  <ConnectedReportingBlock chartId={STAFF_REPORT_ID}>
                    {reportingBlockSetters => (
                      <ReportStaff
                        dateRange={baseDateRange}
                        facilities={activeFacilitiesIds}
                        reportingBlockSetters={reportingBlockSetters}
                      />
                    )}
                  </ConnectedReportingBlock>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersTrustFundSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersTrustFundSection.id}
                  title={reportsSlideAnswersTrustFundSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={INCOME_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportPersonalResourceIncomeAgreement
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock chartId={TRUST_FUND_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportTrustFund
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersLaundrySection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersLaundrySection.id}
                  title={reportsSlideAnswersLaundrySection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={LAUNDRY_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportLaundry
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersVaccinesSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersVaccinesSection.id}
                  title={reportsSlideAnswersVaccinesSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={PNEUMOCOCCAL_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportPneumococcal
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock chartId={INFLUENZA_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportInfluenza
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersSurrogateSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersSurrogateSection.id}
                  title={reportsSlideAnswersSurrogateSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={SURROGATE_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportSurrogateDecisionMaking
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock chartId={SURROGATE_HELP_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportSurrogateDecisionMakingHelp
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersAdvancedDirectiveSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersAdvancedDirectiveSection.id}
                  title={reportsSlideAnswersAdvancedDirectiveSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={AD_PROVIDED_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportExistingADProvided
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock chartId={AD_HELP_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportADHelp
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersFuneralSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersFuneralSection.id}
                  title={reportsSlideAnswersFuneralSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock chartId={FUNERAL_REPORT_ID}>
                      {reportingBlockSetters => (
                        <ReportFuneral
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersMedicareSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersMedicareSection.id}
                  title={reportsSlideAnswersMedicareSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock
                      chartId={MEDICARE_WAIVER_REPORT_ID}
                    >
                      {reportingBlockSetters => (
                        <ReportMedicareWaiver
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsSlideAnswersMedicaidSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsSlideAnswersMedicaidSection.id}
                  title={reportsSlideAnswersMedicaidSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock
                      chartId={MEDICAID_REQUEST_REPORT_ID}
                    >
                      {reportingBlockSetters => (
                        <ReportMedicaidRequest
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
              <RequiredUserPermissionsSome
                permissions={getSectionItemsPermissions(
                  reportsStaffAdmissionSection.sectionItems,
                )}
              >
                <ReportingGroup
                  id={reportsStaffAdmissionSection.id}
                  title={reportsStaffAdmissionSection.title}
                >
                  <ReportingRow>
                    <ConnectedReportingBlock
                      chartId={SNFABN_COST_ADMISSION_REPORT_ID}
                    >
                      {reportingBlockSetters => (
                        <ReportSNFABNEstimatedCostAdmission
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                    <ConnectedReportingBlock
                      chartId={FACILITY_CHANGES_REPORT_ID}
                    >
                      {reportingBlockSetters => (
                        <ReportFacilityChanges
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          reportingBlockSetters={reportingBlockSetters}
                        />
                      )}
                    </ConnectedReportingBlock>
                  </ReportingRow>
                </ReportingGroup>
              </RequiredUserPermissionsSome>
            </>
          )}
        </ReportContextConsumer>
      </RequiredUserPermissionsSome>
    </ReportingLayout>
  </>
);
