import Alert from "react-s-alert";
import portableFetch from "portable-fetch";
import {
  Admission,
  PointClickCareControllerApi,
  AnswerOfobject,
} from "@reside/reside-api-admission";

import {http} from "./api";
import {GENDER_MAP} from "../utils/constants/user";
import {UsStateCode} from "../utils/constants";
import {AdmissionFromSearch, AdmissionsService} from "./AdmissionsService";
import {BASE_PATH} from "../config";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {
  readResidentPccAccountStatus,
  RESIDENT_PCC_ACCOUNT_STATUS,
} from "../models/AdmissionModel.helpers";

type Address = Readonly<{
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postalCode?: string;
  state?: UsStateCode;
}>;

export enum PayerRank {
  Primary = "Primary",
  Secondary = "Secondary",
}

export enum PayerType {
  managedCare = "managedCare",
  medicaid = "medicaid",
  medicareA = "medicareA",
  medicareB = "medicareB",
  medicareD = "medicareD",
  other = "other",
  outpatient = "outpatient",
  private = "private",
}

type PayerSource = Readonly<{
  id?: string;
  otherPayerTypeName: string;
  payerType: PayerType;
  remainingDays: string;
}>;

export type PccPatient = Readonly<{
  birthDate?: string;
  firstName?: string;
  gender?: GENDER_MAP;
  lastName?: string;
  patientId?: number;
}>;

export type PccPatientDetail = Readonly<{
  admissionDate?: string;
  advanceDirectivesConsents?: boolean;
  birthDate?: string;
  email?: string;
  firstName?: string;
  gender?: GENDER_MAP;
  homePhone?: string;
  lastName?: string;
  previousAddress?: Address;
  roomId?: number;
  socialBeneficiaryIdentifier?: string;
  primaryPayerSource: PayerSource;
  secondaryPayerSource: PayerSource;
}>;

export type PccActivityLogBase = Readonly<{
  activityId?: string;
  admissionId?: string;
  errorCode?: "MISSING_PATIENT_ACCOUNT";
  eventType?:
    | "CREATE_PATIENT"
    | "UPLOAD_DOCUMENT"
    | "UPLOAD_ADMISSION_PACKET"
    | "EVENT_LISTENER_ACTIVATE_MAPPING"
    | "EVENT_LISTENER_DELETE_MAPPING";
  facilityId?: string;
  organizationId?: string;
  retryable?: boolean;
  status?: "SUCCESS" | "FAILED" | "SKIPPED" | "PENDING";
}>;

export type PccActivityLog = PccActivityLogBase &
  Readonly<{
    id?: string;
    message?: string;
    time?: string;
  }>;

export type ActivityLogsUsingGETQueryParams = PccActivityLogBase &
  Readonly<{
    /**
     * Results page you want to retrieve (0..N)
     */
    page?: number;
    /**
     * Number of records per page.
     */
    size?: number;
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
  }>;

export interface CheckResidentPccAccountStatusUsingGETQueryParams {
  facilityId: string;
  admissionId: string;
}

export type RetryActivityDto = Readonly<{
  /**
   * Activity UUID
   */
  activityId: string;
  facilityId: string;
}>;

export interface CreatePccAccountDto {
  facilityId: string;
  admissionId: string;
}

export interface FindResidentsUsingGETQueryParams {
  birthDate: string;
  facilityId: string;
  firstName: string;
  gender: string;
  lastName: string;
}

export interface GetResidentUsingGETQueryParams {
  facilityId: string;
  patientId?: number;
  admissionId?: string;
}

type PccUploadCategory = Readonly<{
  id: number;
  name: string;
}>;

export type PccUploadCategories = ReadonlyArray<PccUploadCategory>;

export enum PccStatusCodes {
  Ok = 200,
  PccIntegrationTurnedOff = 412,
  PccRequestLimitReached = 429,
}

export const handlePccRequestLimitReached = (error: any) => {
  if (error?.status !== PccStatusCodes.PccRequestLimitReached) {
    return false;
  }

  Alert.error(
    "Daily amount of PointClickCare requests has been reached, please try again tomorrow.",
  );

  return true;
};

const pcc = "/point-click-care";

export enum PccResidentAccountStatus {
  SKIPPED = "SKIPPED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export const admissionHasPccResidentAccountStatus =
  (status: PccResidentAccountStatus) =>
  (admission: Admission | AdmissionFromSearch) =>
    readResidentPccAccountStatus(admission as any) === status;

export const admissionHasSkippedPccResidentAccountStatus =
  admissionHasPccResidentAccountStatus(PccResidentAccountStatus.SKIPPED);
export const admissionHasPendingPccResidentAccountStatus =
  admissionHasPccResidentAccountStatus(PccResidentAccountStatus.PENDING);
export const admissionHasActivePccResidentAccountStatus =
  admissionHasPccResidentAccountStatus(PccResidentAccountStatus.ACTIVE);

export enum PccAccountStatus {
  AccountExists = "AccountExists",
  AccountDoesNotExist = "AccountDoesNotExist",
}

export enum PccIntegrationStatus {
  PccTurnedOn = "PccTurnedOn",
  PccTurnedOff = "PccTurnedOff",
  PccMappingDoesNotExist = "PccMappingDoesNotExist",
}

export const PccService = {
  // Returns facility based activity logs for authenticated users. Pagination is mandatory, default is page=0&size=20&sort=time,desc. Search params: ?facilityId=facilityIdentifier || ?organizationId=organizationIdentifier
  get: (data?: ActivityLogsUsingGETQueryParams) =>
    http.get<ReadonlyArray<PccActivityLog>>(`${pcc}/activity-logs`, data),

  // Retries activity specified by id
  retryActivity: (data: RetryActivityDto) =>
    http.post(`${pcc}/activity-logs/retry`, data),

  // Check if resident has PointClickCare account
  // Call this only when PCC is enabled, otherwise you get HTTP 412
  getResidentAccountStatus: (
    data: CheckResidentPccAccountStatusUsingGETQueryParams,
  ) => http.get<{status: PccAccountStatus}>(`${pcc}/account-status`, data),

  // Create new PointClickCare account and appropriate mapping
  createPccAccount: (data: CreatePccAccountDto) =>
    http.post<CreatePccAccountDto>(`${pcc}/accounts`, data),

  // Skips creating PointClickCare account for specified patient/resident
  skipPccAccount: (data: CreatePccAccountDto) =>
    http.post<CreatePccAccountDto>(`${pcc}/accounts/skipped`, data),

  // Returns list of residents based on criteria
  findResidents: (data: FindResidentsUsingGETQueryParams) =>
    http.get<ReadonlyArray<PccPatient>>(`${pcc}/residents`, data),

  // Returns resident by ID
  getResident: (data: GetResidentUsingGETQueryParams) =>
    http.get<PccPatientDetail>(`${pcc}/resident`, data),

  getDocumentUploadCategories: (facilityId: string) =>
    pccApi.getDocumentUploadCategoriesUsingGET(
      facilityId,
      getAuthorizationHeader(),
    ),

  postChecklistItemDocumentToPcc: (
    admissionId: string,
    checklistItemId: string,
    documentCategoryId: number,
    file: File,
  ) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("documentCategoryId", String(documentCategoryId));

    return http.post<Admission>(
      `${pcc}/integrations/admissions/${admissionId}/checklist-items/${checklistItemId}/document-upload`,
      formData,
    );
  },
};

export const changePccStatusToActive = async (admissionId: string) => {
  return AdmissionsService.patch(admissionId, {
    answers: {
      [RESIDENT_PCC_ACCOUNT_STATUS]: {
        questionType: AnswerOfobject.QuestionTypeEnum.TEXT,
        questionId: RESIDENT_PCC_ACCOUNT_STATUS,
        value: PccResidentAccountStatus.ACTIVE,
      } as any,
    },
  });
};

export const pccApi = new PointClickCareControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);
