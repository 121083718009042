import React from "react";
import {
  AdmissionsDeleteAdmissionModal,
  AdmissionsDeleteModalProvider,
} from "../../../atoms/admin-admissions-table/admission-delete-modal";
import {
  AdmissionsRestoreAdmissionModal,
  AdmissionsRestoreModalProvider,
} from "../../../atoms/admin-admissions-table/admission-restore-modal";
import {
  ChangeHistoryModal,
  ChangeHistoryModalProvider,
} from "../../../atoms/dashboard-table/atoms/change-history-modal";
import {LinkResidentToAdmissionModalProvider} from "../context/useLinkResidentToAdmissionContext";
import {LinkAnotherResidentModal} from "./link-another-resident-modal";
import {useFetchResident} from "../hooks/useFetchResident";

export const ModalsProviders = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {refetch} = useFetchResident();

  return (
    <LinkResidentToAdmissionModalProvider>
      <AdmissionsDeleteModalProvider>
        <AdmissionsRestoreModalProvider>
          <ChangeHistoryModalProvider>
            <LinkAnotherResidentModal />
            <AdmissionsDeleteAdmissionModal onDeleted={() => refetch()} />
            <AdmissionsRestoreAdmissionModal onRestored={() => refetch()} />
            <ChangeHistoryModal />
            {children}
          </ChangeHistoryModalProvider>
        </AdmissionsRestoreModalProvider>
      </AdmissionsDeleteModalProvider>
    </LinkResidentToAdmissionModalProvider>
  );
};
