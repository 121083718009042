"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
// _-,:/.'()&+​01234789abcdefghijklmnopqrstuvwxyz
var ORGANIZATION_NAME_REGEX = new RegExp(/^[-,.'()&\w\s]*$/);
var UNDERSCORE = new RegExp(/_/);
exports.Validator = {
    name: "organization-name",
    errorMessage: "This field may only contain alpha-numeric characters, as well as dashes, dots and parentheses.",
    validate: function (value) {
        return ORGANIZATION_NAME_REGEX.test(value) && !UNDERSCORE.test(value);
    },
};
