/* eslint-disable react-hooks/rules-of-hooks */
//TODO: refactor hooks in non react functions
import React, {useMemo, ReactNode} from "react";
import {v4} from "uuid";
import {get, flatten, groupBy, identity, ary, sumBy} from "lodash";
import {
  format,
  addDays,
  parseISO,
  eachDayOfInterval,
  differenceInCalendarDays,
} from "date-fns";
import {readableStatus} from "../../../atoms/list-table/helpers";
import {ReportingBlockSetters} from "../../../atoms/reporting-block";
import {
  IReportingAnalyticsDataState,
  AnalyticSegment,
  AggregationType,
  DateStr,
  IFromTo,
  MetricValue,
  DataPath,
  DataStatePath,
  Facility,
} from "../../../store/reporting/reporting.common";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";

export type Props = {
  children?: ReactNode;
  dateRange: IFromTo;
  facilities: Facility[];
  reportingBlockSetters?: ReportingBlockSetters;
  onChildRendered?: () => void;
};

export type ChartProps = Readonly<{
  data: DataStatePath[] | undefined;
  isFullReport: boolean;
}>;

interface BarStackItem {
  name: string;
  value: number;
}

interface BarStack3Item extends BarStackItem {
  label?: string;
}

export type MapNames = (name: string) => string;

export type BarStack3 = [BarStack3Item[], BarStack3Item[], BarStack3Item[]];

export interface BarStackNew {
  left: string;
  right: string;
  values: number[][];
  original?: any;
}

export interface BarStackObject {
  left: string;
  right: string;
  values: Record<string, unknown>[][];
  legends?: any;
}

export interface PieStack {
  y: number;
  x: string;
}

export interface PieData {
  stack: PieStack[];
  additional_data: any;
}

export interface InputBar<TStack> {
  splits: string[];
  names: string[];
  stack: TStack;
  percents: string[];
}

export interface InputBarRateNew {
  max: number;
  stack: BarStackNew[];
  legends?: any;
}

export interface InputBarRateObject {
  max: number;
  stack: BarStackObject[];
  legends?: any;
}

export type AnalyticsDataStateToBarStack = (
  analyticsDataStatePaths: DataStatePath[],
) => InputBarRateNew;

export const useOnDataPathChange = (
  reportingBlockSetters?: ReportingBlockSetters,
) => {
  return useMemo(() => {
    return reportingBlockSetters
      ? reportingBlockSetters.setExportDataPath
      : undefined;
  }, [reportingBlockSetters]);
};

export const getData = (d: IReportingAnalyticsDataState | undefined) =>
  d ? (d.data ? d.data.data || [] : []) : [];

const dateStrFromDate = (date: Date): DateStr =>
  format(date, DATE_FORMAT.ISO_DATE);

export const domainFromData: (
  data: MetricValue[],
) => [number, number] = data => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  data.forEach(metricValue => {
    min = Math.min(min, metricValue.value);
    max = Math.max(max, metricValue.value);
  });

  return [0, Math.max(Math.floor(max * 1.1), 1)];
};

export const fillInData = (
  d: AnalyticSegment["data"] | undefined,
  dateRange: IFromTo,
) => {
  if (!d) {
    return [];
  }

  const allDays = eachDayOfInterval({
    start: parseISO(dateRange.from),
    end: parseISO(dateRange.to),
  }).map(x => ({
    date: x,
    value: 0,
  }));

  if (!d || d.length === 0) {
    return allDays;
  }

  const sorted = d.slice().sort((a: any, b: any) => {
    return a.date < b.date ? 1 : -1;
  });

  allDays.reverse();

  let i = 0;
  const fixed = allDays.map(day => {
    if (i >= sorted.length) {
      return day;
    }
    const selectedData = sorted[i];
    const currentDate = dateStrFromDate(day.date);

    if (currentDate === selectedData.date) {
      i++;

      return {date: new Date(selectedData.date), value: selectedData.value};
    }

    return day;
  });

  fixed.reverse();

  return fixed;
};

export const getAggregationValues = (
  dataStatePaths: DataStatePath[] | null | undefined,
  type: AggregationType,
) => {
  return dataStatePaths.map(dataStatePath => {
    const analyticsDataState = dataStatePath.dataState;

    if (analyticsDataState?.data && analyticsDataState.data.aggregations) {
      return analyticsDataState.data.aggregations[type];
    }

    return 0;
  });
};

export const findMax = (
  segments: AnalyticSegment[],
  aggregationName: AggregationType,
) =>
  segments
    .filter(segment => segment.aggregations)
    .map(segment => segment.aggregations[aggregationName])
    .filter(Number.isFinite)
    .reduce(ary(Math.max, 2), 0);

const sumSegments = (
  segments: AnalyticSegment[],
  aggregationType: AggregationType,
) =>
  sumBy(segments, segment =>
    get(segment, `aggregations.${aggregationType}`, 0),
  );

const zip: <T>(rows: T[][]) => T[][] = rows =>
  rows[0].map((_, c) => rows.map(row => row[c]));

const allValues: <T, O>(
  objArr: T[],
  accessor: (t: T) => O,
  defaultValue?: any,
) => O = (objArr, accessor, defaultValue = "") => {
  return objArr && objArr.length > 0
    ? objArr.map(accessor).join("/")
    : defaultValue;
};

const reduceForBarRateNew: (
  dataStatePaths: DataStatePath[],
  aggType: AggregationType,
  max: number,
  rightAggregation: AggregationType,
  mapNames?: MapNames,
  formatterRight?: (value: number, max: number) => string,
) => BarStackNew[] = (
  dataStatePaths,
  aggType,
  max,
  rightAggregation,
  mapNames = identity,
  formatterRight,
) => {
  const unmergedLines = dataStatePaths.map(dataStatePath => {
    const segments: AnalyticSegment[] = get(
      dataStatePath,
      "dataState.data.segments",
      [],
    );

    const out: BarStackNew[] = segments.map(segment => {
      const name =
        segment.segmentInfo.type !== "NONE"
          ? mapNames(segment.segmentInfo.name)
          : "NONE";

      const value = get(segment, `aggregations.${aggType}`, 0);

      return {
        original: segment,
        left: name,
        values: [[value, max - value]],
        right:
          segment?.aggregations &&
          !isNaN(segment.aggregations[rightAggregation])
            ? formatterRight
              ? formatterRight(segment.aggregations[rightAggregation], max)
              : `${segment.aggregations[rightAggregation]}`
            : "-",
      };
    }, []);

    return out;
  });

  const groupedData: any = Object.values(
    groupBy(flatten(unmergedLines), (obj: any) => {
      return obj.original.segmentInfo.id;
    }),
  );

  return groupedData.map((group: BarStackNew[]) => {
    return {
      left: group.map(item => item.left).join("/"),
      right: group.map(item => item.right).join("/"),
      original: group.reduce(
        (values, line) => (line ? [...values, line.original] : values),
        [],
      ),
      values: group.reduce(
        (values, line) => (line ? [...values, ...line.values] : values),
        [],
      ),
    };
  });
};

const defaultBarRate: InputBarRateNew = {
  stack: [{left: "", right: "", values: []}],
  max: 1,
};

const defaultBarRateWithLegend: InputBarRateObject = {
  stack: [{left: "", right: "", values: [], legends: []}],
  max: 1,
};

export const numberToPercent = (a: number) => `${(a * 100).toFixed(2)}%`;

const hasSomeSegment = (dataStatePath: DataStatePath) =>
  get(dataStatePath, "dataState.data.segments", []).length > 0;

const formatterPercents = Intl.NumberFormat("en-US", {style: "percent"});

const findDataPathSegmentMax = (
  dataStatePaths: DataStatePath[],
  aggregationType: AggregationType,
) =>
  findMax(
    flatten(
      dataStatePaths.map(path => get(path, "dataState.data.segments", [])),
    ),
    aggregationType,
  );

const sumDataPathSegments = (
  dataStatePaths: DataStatePath[],
  aggregationType: AggregationType,
) =>
  sumBy(dataStatePaths, path =>
    sumSegments(get(path, "dataState.data.segments", []), aggregationType),
  );

/*
Data Processors
*/

export const createDataToBarStack: (
  aggType: AggregationType,
  secondaryAggType: AggregationType,
  mapNames?: MapNames,
  formatterRight?: (value: number, max: number) => string,
  sumAsMax?: boolean,
  fixedMax?: AggregationType,
) => AnalyticsDataStateToBarStack =
  (aggType, secondaryAggType, mapNames, formatterRight, sumAsMax, fixedMax) =>
  dataStatePaths => {
    if (dataStatePaths.some(hasSomeSegment)) {
      const segmentMax = fixedMax
        ? findDataPathSegmentMax(dataStatePaths, fixedMax)
        : sumAsMax
        ? sumDataPathSegments(dataStatePaths, aggType)
        : findDataPathSegmentMax(dataStatePaths, aggType);

      const max =
        aggType === AggregationType.WEIGHTED_AVG && segmentMax <= 1
          ? 1
          : segmentMax;

      return {
        stack: reduceForBarRateNew(
          dataStatePaths,
          aggType,
          max,
          secondaryAggType,
          mapNames,
          formatterRight,
        ),
        max,
      };
    }

    return defaultBarRate;
  };

export const dataProcess_AnalyticsPayerSourceAge: AnalyticsDataStateToBarStack =
  dataStatePaths => {
    if (dataStatePaths.some(hasSomeSegment)) {
      const max = findDataPathSegmentMax(
        dataStatePaths,
        AggregationType.WEIGHTED_AVG,
      );

      const unmergedLines: BarStackObject[][] = dataStatePaths.map(
        dataStatePath => {
          if (!get(dataStatePath, "dataState.data")) {
            return defaultBarRateWithLegend.stack;
          }

          const {segments} = dataStatePath.dataState.data;

          const out: BarStackObject[] = segments.map(segment => {
            const name =
              segment.segmentInfo.type !== "NONE"
                ? segment.segmentInfo.name
                : "NONE";

            const localSum = sumSegments(
              segment.segments,
              AggregationType.COUNT,
            );

            const values = segment.segments
              .map(item => ({
                x: item.segmentInfo.name,
                y:
                  localSum !== 0
                    ? get(item, `aggregations.${AggregationType.COUNT}`, 0) /
                      localSum
                    : 0,
              }))
              .filter((item: any) => {
                return item.y !== 0;
              })
              .sort((a: any, b: any) => {
                if (a.x < b.x) {
                  return -1;
                }
                if (a.x > b.x) {
                  return 1;
                }

                return 0;
              });

            const legends = values.map(item => {
              return item.x;
            });

            return {
              left: name,
              values: [values],
              legends: legends,
              right: null,
            };
          }, []);

          return out;
        },
      );

      let legends = unmergedLines.map((lines: BarStackObject[]) => {
        return lines
          .reduce(
            (legends, line) => (line ? [...legends, ...line.legends] : legends),
            [],
          )
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          });
      })[0];

      legends = legends.sort().map(item => {
        return {
          name: item,
        };
      });

      const stack = zip(unmergedLines).map((lines: BarStackObject[]) => {
        const left = allValues(lines, line => (line ? line.left : "-"));
        const right = allValues(lines, line => (line ? line.right : "-"));

        return {
          left,
          right,
          values: lines.reduce(
            (values, line) => (line ? [...values, ...line.values] : values),
            [],
          ),
        };
      });

      return {
        stack: stack,
        max: max,
        legends: legends,
      };
    }

    return {
      stack: [],
      max: 1,
    };
  };

export const dataProcess_AnalyticsTimeByStage = createDataToBarStack(
  AggregationType.WEIGHTED_AVG,
  AggregationType.WEIGHTED_AVG,
  name => readableStatus(name as any),
  (number: number) => millisecondsToTimeDuration(number),
);

export const dataProcess_AnalyticsManagedMedicadRate: AnalyticsDataStateToBarStack =
  createDataToBarStack(AggregationType.WEIGHTED_AVG, AggregationType.COUNT);

export const dataProcess_AnalyticsMedicareByFacility: AnalyticsDataStateToBarStack =
  createDataToBarStack(
    AggregationType.WEIGHTED_AVG,
    AggregationType.WEIGHTED_AVG,
    undefined,
    formatterPercents.format,
  );

export const dataProcess_AnalyticsTimeToCloseByFacility: AnalyticsDataStateToBarStack =
  createDataToBarStack(
    AggregationType.WEIGHTED_AVG,
    AggregationType.WEIGHTED_AVG,
    undefined,
    (seconds: number) => secondsToTimeDuration(seconds),
  );

export const dataProcess_AnalyticsPercentageByFacility: AnalyticsDataStateToBarStack =
  createDataToBarStack(
    AggregationType.WEIGHTED_AVG,
    AggregationType.WEIGHTED_AVG,
    undefined,
    formatterPercents.format,
  );

export const dataProcess_AnalyticsPercentageByFacilitySumAsMax: AnalyticsDataStateToBarStack =
  createDataToBarStack(
    AggregationType.SUM,
    AggregationType.SUM,
    undefined,
    (number: number, max: number) => `${((number / max) * 100).toFixed(2)}%`,
    false,
    AggregationType.COUNT,
  );

const shiftData = (data: {date: Date; value: number}[], startDate: DateStr) => {
  return data.map(d => ({
    date:
      data.length === 0
        ? d.date
        : addDays(
            d.date,
            differenceInCalendarDays(parseISO(startDate), data[0].date),
          ),
    originalDate: d.date,
    value: d.value,
  }));
};

export const lineGraphData = (dataStatePaths: DataStatePath[]) => {
  const data = dataStatePaths.map(({dataState, dataPath}) =>
    shiftData(
      fillInData(getData(dataState), {
        from: dataPath.query.from,
        to: dataPath.query.to,
      }),
      dataStatePaths[0].dataPath.query.from,
    ),
  );

  return data ? data.slice(0).reverse() : [];
};

export const withCompareCompute: (
  dataPath: DataPath,
  fromTo?: IFromTo,
) => DataPath[] = (dataPath, fromTo) => {
  if (fromTo) {
    const dataPath2 = JSON.parse(JSON.stringify(dataPath));

    dataPath2.query.from = fromTo.from;
    dataPath2.query.to = fromTo.to;

    return [dataPath, dataPath2];
  }

  return [dataPath];
};
export const withCompare: (
  dataPath: DataPath,
  fromTo?: IFromTo,
) => DataPath[] = (dataPath, fromTo) => {
  return React.useMemo(
    () => withCompareCompute(dataPath, fromTo),
    [dataPath, fromTo],
  );
};

export const dataProcess_AnalyticsFlagsBySlidePie: (
  analyticsDataStatePaths: DataStatePath[],
) => PieData[] = dataStatePaths => {
  if (dataStatePaths) {
    return dataStatePaths.map(dataStatePath => {
      const segments = get(dataStatePath, "dataState.data.segments", null);
      const additional_data = get(
        dataStatePath,
        "dataState.data.additionalData",
        null,
      );
      let data;

      if (segments) {
        data = segments.map((segment: any) => ({
          x: segment.segmentInfo.name,
          y: segment.aggregations.SUM,
        }));
      } else {
        data = [];
      }

      return {
        stack: data,
        additional_data: additional_data,
      };
    });
  }

  return [];
};

export const changeDataIds = (
  payload: DataStatePath[] | undefined,
): DataStatePath[] => {
  if (!payload) return payload;
  const {dataState} = payload[0] || {};
  const {data} = dataState || {};
  const {segments} = data || {};

  if (!segments) return payload;

  const newSegments = segments.map(segment => ({
    ...segment,
    segmentInfo: {
      ...segment.segmentInfo,
      id: v4(),
    },
  }));

  return [
    {
      ...payload[0],
      dataState: {
        ...dataState,
        data: {
          ...data,
          segments: newSegments,
        },
      },
    },
  ];
};

export type TimeDurationGroups = {
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const MILLISECONDS_PER_WEEK = 604800000;
export const MILLISECONDS_PER_DAY = 86400000;
export const MILLISECONDS_PER_HOUR = 3600000;
export const MILLISECONDS_PER_MINUTE = 60000;
export const MILLISECONDS_PER_SECOND = 1000;

export const millisecondsToTimeDurationGroups = (
  milliseconds: number,
): TimeDurationGroups => {
  const weeks = Math.floor(milliseconds / MILLISECONDS_PER_WEEK);

  let remainder = milliseconds % MILLISECONDS_PER_WEEK;

  const days = Math.floor(remainder / MILLISECONDS_PER_DAY);

  remainder = remainder % MILLISECONDS_PER_DAY;

  const hours = Math.floor(remainder / MILLISECONDS_PER_HOUR);

  remainder = remainder % MILLISECONDS_PER_HOUR;

  const minutes = Math.floor(remainder / MILLISECONDS_PER_MINUTE);

  remainder = remainder % MILLISECONDS_PER_MINUTE;

  const seconds = Math.floor(remainder / MILLISECONDS_PER_SECOND);

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const millisecondsToTimeDuration = (
  milliseconds: number,
  options = {precision: 3},
) => {
  const {weeks, days, hours, minutes} =
    millisecondsToTimeDurationGroups(milliseconds);

  return (
    [
      weeks > 0 && `${weeks}w`,
      days > 0 && `${days}d`,
      hours > 0 && `${hours}h`,
      minutes > 0 && `${minutes}m`,
    ]
      .filter(item => typeof item === "string" && item !== "")
      .slice(0, options.precision)
      .join(" ") || "0"
  );
};

export const secondsToTimeDuration = (
  inputSeconds: number,
  options = {precision: 3},
): string => millisecondsToTimeDuration(inputSeconds * 1000, options);
