import React from "react";
import {SideMenu, SideMenuItem} from "@reside/ui";
import {UserDto} from "@reside/reside-api-app";

const MENU_OPTIONS: Array<{slug: string; label: string}> = [
  {
    slug: "profile",
    label: "User Details",
  },
  {
    slug: "change-password",
    label: "Change Password",
  },
];

export const SidebarMenu = ({user}: {user: UserDto}) => (
  <SideMenu>
    {MENU_OPTIONS.map(({slug, label}) => (
      <SideMenuItem key={slug}>
        {({Link}) => <Link to={`/admin/user/${user.id}/${slug}`}>{label}</Link>}
      </SideMenuItem>
    ))}
  </SideMenu>
);
