import {http} from "./api";

export const FacilitySettingsService = {
  updateSettings: (facilityId: string, settings: FacilitySettingsVariables) =>
    http.put<FacilitySettingsVariables>(
      `/facilities/${facilityId}/settings`,
      settings,
    ),
};

export type FileVariable = {
  type: "FILE";
  id: string;
  /**
   * Public link to the resource.
   */
  url: string;
};

export type FieldArrayItem = {
  /**
   * Unique id to find specific item in the array field.
   */
  id: string;
  [key: string]: number | string | ReadonlyArray<string>;
};

/**
 * Possible facility settings values.
 */
export type FacilitySettingsVariable =
  | number
  | string
  | FileVariable
  | ReadonlyArray<string>
  | ReadonlyArray<FieldArrayItem>;

export type FacilitySettingsVariables = Record<
  string,
  FacilitySettingsVariable
>;
