import React, {
  createContext,
  RefObject,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {useScroll} from "react-use";
import useComponentSize from "@rehooks/component-size";

type ScrollContextValue = Readonly<{
  scrollRef: RefObject<HTMLDivElement>;
  bottomTolerance: number;
}>;

const ScrollContext = createContext<ScrollContextValue>({
  scrollRef: null,
  bottomTolerance: 0,
});

type ScrollState = Readonly<{
  scrollable: boolean;
  didScroll: boolean;
  scrolledToBottom: boolean;
  scrolledToBottomBefore: boolean;
}>;

export const useScrollContext = (): ScrollState => {
  const {scrollRef, bottomTolerance} = useContext(ScrollContext);
  /**
   * We could read the scrollTop from the 'current' but we need the `useScroll` hook to watch for scrolling so we re-render onScroll.
   */
  const {y: scrollTop} = useScroll(scrollRef);

  // Tracking height changes for proper function of the hook when form shrinks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {height} = useComponentSize(scrollRef);
  const [didScroll, setDidScroll] = useState(false);
  const [scrolledToBottomBefore, setScrolledToBottomBefore] = useState(false);
  const scrollHeight = scrollRef.current?.scrollHeight ?? 0;
  const offsetHeight = scrollRef.current?.offsetHeight ?? 0;

  const scrollableHeight = scrollHeight - offsetHeight;
  const scrolledToBottom = scrollTop + bottomTolerance >= scrollableHeight;

  if (scrollRef.current) {
    /**
     * Only when the scroll was measured, we can track historical events.
     */
    if (!didScroll && scrollTop > 0) {
      setDidScroll(true);
    }

    if (!scrolledToBottomBefore && scrolledToBottom) {
      setScrolledToBottomBefore(true);
    }
  }

  return {
    scrollable: scrollableHeight > 0,
    didScroll,
    scrolledToBottomBefore,
    scrolledToBottom,
  };
};

type Props = Readonly<{
  bottomTolerance: number;
  children: (scrollRef: RefObject<HTMLDivElement>) => JSX.Element;
}>;

export const ScrollContextProvider = ({children, bottomTolerance}: Props) => {
  const scrollRef = useRef(null);

  return (
    <ScrollContext.Provider
      value={useMemo(() => ({scrollRef, bottomTolerance}), [bottomTolerance])}
    >
      {children(scrollRef)}
    </ScrollContext.Provider>
  );
};
