var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext, useState } from "react";
import { Translated, translate } from "./translated";
import { Markdown, interpolate } from "../markdown";
/**
 * Wrap your dynamic form in the <BlockComponentContext.Provider /> in order to customize the rendered block components.
 */
export var BlockComponentContext = createContext({
    Translated: Translated,
    translate: translate,
    interpolate: interpolate,
    Markdown: Markdown,
});
export var useBlockComponentContext = function () {
    return useContext(BlockComponentContext);
};
export var BlockComponentContextProvider = function (_a) {
    var value = _a.value, children = _a.children;
    /**
     * Here we use the useState as useMemo, because we don't expect value to be changed.
     */
    var components = useState(function () { return (__assign({ Translated: Translated, translate: translate, interpolate: interpolate, Markdown: Markdown }, value)); })[0];
    return (React.createElement(BlockComponentContext.Provider, { value: components }, children));
};
