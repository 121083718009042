import React from "react";
import {Formik} from "formik";
import {validator} from "@reside/forms";
import {
  FormCard,
  FormGrid,
  FormSlide,
  SelectField,
  TextAreaField,
  TextField,
  ThinScrollbar,
} from "@reside/ui";
import {StyledScrollableErrorArea} from "./styled";
import {SftpConfigFormHeader} from "./SftpConfigFormHeader";
import {SftpConfig} from "../../../services/HubAndSpokeService";
import {SftpAuthMethods} from "../../../utils/constants";

const InitialValues = {
  name: "",
  notes: "",
  hostname: "",
  port: 0,
  username: "",
  auth_type: "",
  auth_value: "",
};

type Props = Readonly<{
  initialValues?: SftpConfig;
  onSubmit: (values: SftpConfig) => void;
}>;

export const SftpConfigForm = ({initialValues, onSubmit}: Props) => {
  const isEditMode = !!initialValues;
  const initialFormValues = isEditMode ? initialValues : InitialValues;

  const getValidationRules = () => {
    return {
      name: "required",
      hostname: "required",
      port: "required",
      username: "required",
      auth_type: "required",
      auth_value: "required",
    };
  };

  const validate = (values: SftpConfig) =>
    validator.validateAll(values, getValidationRules());

  return (
    // skipcq: JS-0415
    <Formik<SftpConfig>
      enableReinitialize
      initialValues={{...initialFormValues}}
      validate={validate}
      onSubmit={values => {
        onSubmit({
          ...values,
          hostname: values.hostname.toLowerCase(),
        });
      }}
    >
      {({values}) => (
        // skipcq: JS-0415
        <>
          <SftpConfigFormHeader />
          <ThinScrollbar>
            <StyledScrollableErrorArea>
              <FormGrid>
                <FormSlide>
                  <FormCard title="Details">
                    <TextField label="Name" name="name" />
                    <TextField label="Notes" name="notes" />
                    <TextField
                      label="Hostname/IP Address"
                      name="hostname"
                      capitalize={false}
                    />
                    <TextField label="Port" name="port" type="number" />
                    <TextField
                      label="Username"
                      name="username"
                      capitalize={false}
                    />
                    <SelectField
                      label="Authentication method"
                      name="auth_type"
                      options={[
                        {
                          value: SftpAuthMethods.password,
                          label: "Password",
                        },
                        {
                          value: SftpAuthMethods.privateKey,
                          label: "Private Key",
                        },
                      ]}
                    />
                    {values.auth_type === SftpAuthMethods.privateKey && (
                      <TextAreaField
                        label="Private Key"
                        name="auth_value"
                        type="text"
                      />
                    )}
                    {values.auth_type === SftpAuthMethods.password && (
                      <TextField
                        label="Password"
                        name="auth_value"
                        capitalize={false}
                        type="password"
                      />
                    )}
                  </FormCard>
                </FormSlide>
              </FormGrid>
            </StyledScrollableErrorArea>
          </ThinScrollbar>
        </>
      )}
    </Formik>
  );
};
