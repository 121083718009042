import {useQuery} from "react-query";
import {connect} from "react-redux";

import {
  QuicksightDashboard,
  QuicksightService,
} from "../../../services/QuicksightService";
import {select} from "../../../store";
import {LOADING_PLACEHOLDER} from "../reporting-menu";

export const useQuicksightCurrentUserDashboards = ({
  enabled,
}: {
  enabled: boolean;
}) =>
  useQuery(
    "quicksightCurrentUserDashboards",
    async () => {
      const {data} = await QuicksightService.getCurrentUserDashboards();

      return data.dashboards as ReadonlyArray<QuicksightDashboard>;
    },
    {
      enabled,
      placeholderData: [{id: LOADING_PLACEHOLDER, name: "Loading..."}],
    },
  );

export const ComponentHook = ({
  enabled,
  children,
}: {
  enabled: boolean;
  children: (
    query: ReturnType<typeof useQuicksightCurrentUserDashboards>,
  ) => JSX.Element;
}) => children(useQuicksightCurrentUserDashboards({enabled}));

const mapState = (state: any) => ({
  enabled:
    !select.adminSession.isResideAdmin(state) &&
    select.adminSession.hasQuicksightDashboardsPermission(state),
});

export const UseQuicksightCurrentUserDashboards =
  connect(mapState)(ComponentHook);
