export const getVisibleSlides = slides =>
  slides.filter(s => s.state.visible === true);

export const getCompleteSlides = slides => slides.filter(s => s.state.complete);

export const getFlaggedSlides = slides => slides.filter(s => !!s.state.flag);

export const getIncompleteSlides = slides =>
  slides.filter(s => s.state.complete === false || !!s.state.flag);

export const getSectionSlides = (slides, sectionId) =>
  slides.filter(s => s.sectionId === sectionId);
