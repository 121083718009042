import {ExportButton} from "./ExportButton";
import {DocumentUploadContainer} from "./styles";
import {Card} from "../styles";
import {UploadFileField} from "./UploadFileField";

/** Part of the page that contains upload field or export csv. */
export const DocumentUpload = ({organizationId}: {organizationId: string}) => {
  return (
    <Card>
      <ExportButton organizationId={organizationId} />

      <DocumentUploadContainer>
        <UploadFileField organizationId={organizationId} />
      </DocumentUploadContainer>
    </Card>
  );
};
