import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  CompareDateLabels,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportStaff as ReportStaffType,
  ReportsType,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  TextCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";

export const STAFF_REPORT_ID = "staff" as const;

export const ReportStaff = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.staffName,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle
          title="Facility Staff - List of facilities with completion rate of admissions: Total Number of admissions vs. Total number of approved admissions"
          subtitle="by Admissions"
        />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>

      <RequiredUserPermission
        permission={TableUserDto.PermissionsEnum.STAFF_ADMISSIONS}
      >
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.staff,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled={props.reportingBlockSetters.enabled}
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <ListTableReporting
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Staff found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportStaffType>
                  sortKey={SortField.staffName}
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.staffName}
                    </TextCellEllipsis>
                  )}
                  label="Name"
                  width={50}
                />
                <Column<ReportStaffType>
                  sortKey={SortField.approvedAdmissions}
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.approvedAdmissions}</TextCell>
                  )}
                  label="Approved Admissions"
                  width={25}
                />
                <Column<ReportStaffType>
                  sortKey={SortField.totalAdmissions}
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.totalAdmissions}</TextCell>
                  )}
                  label="Total Admissions"
                  width={25}
                />
              </ListTableReporting>

              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
