import {useCopyToClipboard} from "react-use";

import {Button, Paragraph} from "@reside/ui";

export const CopyToClipboardButton = ({link}: {link: string}) => {
  const [clipboard, copyToClipboard] = useCopyToClipboard();

  return (
    <>
      <Button
        size="medium"
        icon="duplicate"
        color="primary"
        outline
        testId={`loginLink-${link}`}
        onClick={() => copyToClipboard(link)}
      >
        Copy Magic Link
      </Button>
      {clipboard.error ? (
        <Paragraph color="deepOrange100">
          Unable to copy to clipboard.
        </Paragraph>
      ) : (
        clipboard.value && <Paragraph color="mint100">Copied!</Paragraph>
      )}
    </>
  );
};
