var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { Field } from "formik";
import Alert from "react-s-alert";
import { styled } from "../../theme";
import { FlexRow } from "../../box";
import { FieldError } from "../atoms/field-error";
import { FieldLabel } from "../atoms/FieldLabel";
import { LockIcon } from "../atoms/LockIcon";
import { SnapshotControl } from "../atoms/snapshot-control";
import { imageOfSupportedMimeType, readFilesAsBase64 } from "./utils";
export var SnapshotControlField = function (_a) {
    var label = _a.label, name = _a.name, disabled = _a.disabled, textSize = _a.textSize, transformUrl = _a.transformUrl, loadImageFiles = _a.loadImageFiles;
    var setImage = function (form) { return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var fileArray, normalizedFileArray, files;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fileArray = Array.from(event.currentTarget.files);
                    if (!fileArray.every(imageOfSupportedMimeType)) {
                        Alert.error("Unsupported media type for uploaded file");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, loadImageFiles(fileArray)];
                case 1:
                    normalizedFileArray = _a.sent();
                    return [4 /*yield*/, readFilesAsBase64(normalizedFileArray)];
                case 2:
                    files = _a.sent();
                    form.setFieldValue(name, files[0], true);
                    form.setFieldTouched(name, true, true);
                    return [2 /*return*/];
            }
        });
    }); }; };
    return (React.createElement(Field, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        return (React.createElement(Wrapper, null,
            React.createElement(FlexRow, null,
                React.createElement(FieldLabel, { disabled: disabled }, label),
                disabled && React.createElement(LockIcon, null)),
            React.createElement(SnapshotControl, { name: name, image: field.value, disabled: disabled, textSize: textSize, onClear: function () {
                    form.setFieldValue(name, "", true);
                    form.setFieldTouched(name, true, true);
                }, onChange: setImage(form), transformUrl: transformUrl }),
            React.createElement(FieldError, { name: name })));
    }));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0.625em 0;\n"], ["\n  margin: 0.625em 0;\n"])));
var templateObject_1;
