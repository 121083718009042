import {Color} from "@reside/ui";
import {PccActivityLogDto} from "@reside/reside-api-admission";

const {EventTypeEnum, StatusEnum} = PccActivityLogDto;

export type StatusEnumType = keyof typeof StatusEnum;

const ACTIVITY_LOG_STATUS_DESC: Record<StatusEnumType, string> = {
  [StatusEnum.SUCCESS]: "Success",
  [StatusEnum.FAILED]: "Failed",
  [StatusEnum.PENDING]: "Pending",
  [StatusEnum.SKIPPED]: "Skipped",
};

const ACTIVITY_LOG_STATUS_COLOR: Record<StatusEnumType, Color> = {
  [StatusEnum.SUCCESS]: "mint100",
  [StatusEnum.FAILED]: "deepOrange100",
  [StatusEnum.PENDING]: "golden100",
  [StatusEnum.SKIPPED]: "darkBlue70",
};

export const readableActivityLogStatus = (status: StatusEnumType) =>
  ACTIVITY_LOG_STATUS_DESC[status];

export const colorMapActivityLogStatus = (status: StatusEnumType) =>
  ACTIVITY_LOG_STATUS_COLOR[status];

export type EventTypeEnumType = keyof typeof EventTypeEnum;

const ACTIVITY_LOG_EVENT_DESC: Record<EventTypeEnumType, string> = {
  [EventTypeEnum.CREATE_PATIENT]: "Create patient",
  [EventTypeEnum.UPLOAD_DOCUMENT]: "Upload document",
  [EventTypeEnum.UPLOAD_ADMISSION_PACKET]: "Upload admission packet",
  [EventTypeEnum.EVENT_LISTENER_ACTIVATE_MAPPING]: "Event listener activate",
  [EventTypeEnum.EVENT_LISTENER_DELETE_MAPPING]: "Event listener delete",
};

export const readableActivityLogEvent = (key: EventTypeEnumType) =>
  ACTIVITY_LOG_EVENT_DESC[key];
