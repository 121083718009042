import React from "react";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import {ProfileUserDto} from "@reside/reside-api-app";

import {dispatch} from "../../store";
import {useUserQuery} from "../page-admin-user-edit/hooks/useUserQuery";
import {UserService} from "../../services/UserService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";
import {DocumentTitle} from "../../atoms/document-title";
import {
  PageAdminProfileLayout,
  ProfileFormLayout,
} from "./atoms/PageAdminProfileLayout";
import {EditProfileForm} from "./atoms/edit-profile-form";
import {useUserProfileRouteParams} from "./route";
import {ProfileFormik} from "./atoms/edit-profile-form/ProfileFormik";

export const PageAdminProfileEdit = () => {
  const {userId} = useUserProfileRouteParams();

  const {data: user, refetch, isLoading} = useUserQuery({userId});

  const {mutate} = useMutation(
    (values: ProfileUserDto) => UserService.patch(user.id, values),

    {
      onSuccess: ({data: updatedUser}) => {
        dispatch.adminSession.setUser({
          user: updatedUser,
          isUserLogin: false,
        });

        refetch();

        Alert.success("Profile was successfully updated.");
      },
      onError: async (resp: any) => {
        Alert.error(
          await buildResponseErrorMessage(
            resp,
            "There was an error updating the profile",
          ),
        );
      },
    },
  );

  return (
    <>
      <DocumentTitle
        title={`Reside - Admin - Profile${
          user ? ` - ${user.firstName} ${user.lastName}` : ""
        }`}
      />
      <PageAdminProfileLayout user={user} isLoading={isLoading}>
        <ProfileFormik
          key={user?.updated}
          initialValues={user}
          onSubmit={mutate}
        >
          <ProfileFormLayout user={user}>
            <EditProfileForm user={user} />
          </ProfileFormLayout>
        </ProfileFormik>
      </PageAdminProfileLayout>
    </>
  );
};
