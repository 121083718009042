import {useEffect, useRef} from "react";
import {
  isWithResolution,
  DataPath,
} from "../../store/reporting/reporting.common";

export const dataPathsDeepCompareEquals = (a: DataPath[], b: DataPath[]) => {
  if (a === b) {
    return true;
  }

  if (!!a !== !!b) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    const dpa = a[i];
    const dpb = b[i];

    if (
      dpa.facilities !== dpb.facilities || // TODO: diff arrays
      dpa.query.from !== dpb.query.from ||
      dpa.query.to !== dpb.query.to ||
      dpa.type !== dpb.type ||
      dpa.what !== dpb.what
    ) {
      return false;
    }
    if (isWithResolution(dpa) && isWithResolution(dpb)) {
      if (dpa.query.resolution !== dpb.query.resolution) {
        return false;
      }
    }
  }

  return true;
};

export const useDataPathChange = (
  dataPath: DataPath,
  onDataPathChange?: (dataPath: DataPath) => void,
) => {
  const effect = () => {
    if (onDataPathChange && !!dataPath) {
      onDataPathChange(dataPath);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [useDataPathsDeepCompareMemoize([dataPath])]);
};

export const useDataPathsDeepCompareMemoize = (value: DataPath[]) => {
  const ref = useRef<DataPath[]>();

  if (!dataPathsDeepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
