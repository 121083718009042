import {useState} from "react";

export type Direction = "ASC" | "DESC";

export type Sort = Readonly<{
  properties: ReadonlyArray<string>;
  direction: Direction;
}>;

export type Pageable = Readonly<{
  page: number;
  size: number;
  sort?: ReadonlyArray<string>; // TODO(RESIDE-5064): using sort on some requests and properties for the other?!
  properties?: ReadonlyArray<string>;
  direction?: Direction;
}>;

export interface Page<T> {
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  size: number;
  content: ReadonlyArray<T>;
}

export const nextPage = (pageable: Pageable): Pageable => ({
  ...pageable,
  page: pageable.page + 1,
});

export const withSort = (pageable: Pageable, sort: Sort): Pageable => ({
  ...pageable,
  sort: sort.properties,
  direction: sort.direction,
});

export const serializePageable = (pageable: Pageable) => ({
  page: pageable.page,
  size: pageable.size,
  sort: pageable.sort.map(
    sortProperty => `${sortProperty},${pageable.direction}`,
  ),
});

export const DefaultUnsortedPageable: Pageable = {
  page: 0,
  size: 30,
  sort: [],
};

export const useSort = (
  initialProperties: ReadonlyArray<string>,
  initialDirection: Direction = "DESC",
) => {
  const [direction, setDirection] = useState<Direction>(initialDirection);
  const [properties, setProperties] = useState(initialProperties);

  const setSort = ({direction, properties}: Sort) => {
    setProperties(properties);
    setDirection(direction);
  };

  return {sort: {direction, properties}, setSort};
};
