import React, {FunctionComponent, ReactNode} from "react";
import {FormHeader, Button} from "@reside/ui";

import {Space} from "../../../atoms/space";
import {ButtonLink} from "../../../atoms/button-link";
import {useUserFormikContext} from "./UserFormik";

export type Props = {
  title: ReactNode;
};

export const UserFormHeader: FunctionComponent<Props> = ({title, children}) => {
  const {isSubmitting, submitForm} = useUserFormikContext();

  return (
    <FormHeader>
      {({Wrapper, Left, Right}) => (
        <Wrapper>
          {title}
          <Left>
            <Space>{children}</Space>
          </Left>
          <Right>
            <Space>
              <ButtonLink to="/admin/users">Cancel</ButtonLink>
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={submitForm}
                testId="saveButton"
                color="success"
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Space>
          </Right>
        </Wrapper>
      )}
    </FormHeader>
  );
};
