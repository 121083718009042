import React from "react";
import Alert from "react-s-alert";
import {useHistory, useParams} from "react-router-dom";
import {useMutation} from "react-query";
import {
  H3,
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Box,
} from "@reside/ui";

import {UserService} from "../../../services/UserService";

type Props = Readonly<{
  isOpen: boolean;
  onRequestClose: () => void;
}>;

export const UserDeleteModal = ({isOpen, onRequestClose}: Props) => {
  const history = useHistory();
  const {userId} = useParams<{userId: string}>();

  const {mutate, isLoading} = useMutation(UserService.delete, {
    onSuccess: () => {
      Alert.success("User was successfully deleted.");
      history.push("/admin/users/");
    },
    onSettled: () => {
      onRequestClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {({closeModal}) => (
        <>
          <ModalContent>
            <H3>
              Are you sure you want to delete this user? This action is
              irreversible.
            </H3>
          </ModalContent>
          <ModalFooterButtons>
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                outline
                color="primary"
                onClick={closeModal}
                testId="closeDeleteUserButton"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="danger"
                testId="deleteUserButton"
                disabled={isLoading}
                onClick={() => mutate(userId)}
              >
                {isLoading ? "Deleting..." : "Delete"}
              </Button>
            </Box>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};
