import {useInfiniteQuery} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {usePointClickCareStatus} from "../../../hooks/usePointClickCareStatus";
import {
  pccApi,
  handlePccRequestLimitReached,
} from "../../../services/PccService";
import {ACTIVITIES_LOG_QUERY, ACTIVITY_LOG_PAGE_SIZE} from "./constants";
import {Facilities} from "../../../models/types/AdmissionForm";

export const useFetchActivitiesLog = (
  facilities: Facilities,
  facilityId: string,
) => {
  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  return useInfiniteQuery(
    ACTIVITIES_LOG_QUERY,
    async ({pageParam = 0}) => {
      const {data} = await pccApi.getActivityLogRecordsUsingGET(
        facilityId,
        getAuthorizationHeader(),
        undefined,
        pageParam,
        ACTIVITY_LOG_PAGE_SIZE,
      );
      return data;
    },
    {
      refetchOnMount: "always",
      onError: error => handlePccRequestLimitReached(error),
      enabled: isPccEnabled,
      getNextPageParam: (_, allPages) => allPages.length,
    },
  );
};
