import React from "react";
import {H1, Modal, ModalContent, Text, FlexRowCenter} from "@reside/ui";
import {FacilityDropdownTreeSelect} from "../../../../atoms/facility-dropdown-tree-select";
import {SearchResidentsField} from "../SearchResidentsField";
import {useLinkResidentToAdmissionContext} from "../../context/useLinkResidentToAdmissionContext";
import {LinkAnotherResidentRadioForm} from "./LinkAnotherResidentRadioForm";
import {SearchResidentsContextProvider} from "../../context/useFilterResidentsContext";


export const LinkAnotherResidentModal = (): JSX.Element => {
  const {admission, toggleModal} = useLinkResidentToAdmissionContext();

  return (
    <Modal
      isOpen={!!admission}
      onRequestClose={() => {
        toggleModal();
      }}
      size="large"
    >
      {({closeModal}) => (
        <ModalContent style={{overflowX: "hidden"}}>
          <H1>Link Admission</H1>
          <Text style={{marginBottom: "40px"}}>
            Select another resident to link this admission. The current resident
            will be unlinked.
          </Text>

          <SearchResidentsContextProvider>
            <FlexRowCenter
              style={{marginBottom: "40px", justifyContent: "space-between"}}
            >
              <FacilityDropdownTreeSelect />

              <SearchResidentsField />
            </FlexRowCenter>

            <LinkAnotherResidentRadioForm closeModal={closeModal} />
          </SearchResidentsContextProvider>
        </ModalContent>
      )}
    </Modal>
  );
};
