import React from "react";

import {NavbarIconButton} from "../../navbar-top/atoms/navbar-icon-button";
import {styled} from "@reside/ui";
type Props = Readonly<{
  active?: boolean;
  disabled?: boolean;
  darkBackground?: boolean;
  onClick: () => void;
}>;

export const HelpControlTrigger = ({
  active,
  disabled,
  darkBackground,
  onClick,
}: Props) => (
  <NavbarIconButtonStyled
    frameless
    iconName={active ? "flag-alt" : "flag-outlined"}
    testId="open-help-button"
    darkBackground={darkBackground}
    darkColor="pink100"
    disabled={disabled}
    onClick={onClick}
  >
    Help
  </NavbarIconButtonStyled>
);

const NavbarIconButtonStyled = styled(NavbarIconButton)`
  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    i {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

HelpControlTrigger.defaultProps = {
  active: false,
  disabled: false,
  darkBackground: false,
};
