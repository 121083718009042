import React, {useMemo} from "react";
import {NavLink, useParams} from "react-router-dom";
import {NavHashLink} from "react-router-hash-link";
import {css} from "@emotion/css";
import {FlexColumn, styled, theme} from "@reside/ui";
import {RoleDto} from "@reside/reside-api-app";

import {AdminRoles} from "../../../atoms/admin-roles";
import {RequiredUserPermissionsSome} from "../../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {
  MenuItem,
  MenuCategory,
  MenuContainer,
  MenuGroup,
  MenuGroupTitle,
} from "../../../atoms/menu";
import {TemporaryHideChart} from "../TemporaryHideChart";
import {
  getMenuDefinition,
  getMenuItemPermissions,
  getMenuItemSections,
  mapChecklistItemToMenuDefinition,
  mapDashboardsToMenuDefinition,
} from "./helpers";
import {ChecklistItem} from "../../../services/ReportingService";
import {QuicksightDashboard} from "../../../services/QuicksightService";
import {usePermissionManagementModalContext} from "../atoms/permission-management-modal/PermissionManagementModalContext";
import {useScrollSpyContext} from "../../../hooks/useScrollSpy";

type Props = Readonly<{
  checklistItems?: ReadonlyArray<ChecklistItem>;
  dashboardItems?: ReadonlyArray<QuicksightDashboard>;
}>;

export const LOADING_PLACEHOLDER = "loading";

export const ReportingMenu = ({
  checklistItems = [],
  dashboardItems = [],
}: Props) => {
  const {chartId} = useParams<{chartId: string}>();
  const {activeId} = useScrollSpyContext();

  /**
   * Build helper structures containing dynamic checkbox items.
   */
  const {menu} = useMemo(() => {
    const checkboxMenu = mapChecklistItemToMenuDefinition(checklistItems);
    const dashboardMenu = mapDashboardsToMenuDefinition(dashboardItems);
    const menu = getMenuDefinition(checkboxMenu, dashboardMenu);

    return {menu};
  }, [checklistItems, dashboardItems]);

  return (
    <ReportingMenuColumn>
      {menu.map(category => {
        const {children: subCategories} = category;

        return (
          <RequiredUserPermissionsSome
            key={category.urlComponent}
            permissions={getMenuItemPermissions(category)}
          >
            <TemporaryHideChart chartIds={getMenuItemSections(category)}>
              <ReportingMenuContainer>
                <MenuCategory
                  active
                  iconName={category.icon}
                  title={category.label}
                />
                {subCategories.length > 0 && (
                  <CategoryItems>
                    {subCategories.map(subCategory => {
                      const {children: sections} = subCategory;

                      return (
                        <RequiredUserPermissionsSome
                          key={subCategory.urlComponent}
                          permissions={getMenuItemPermissions(subCategory)}
                        >
                          <TemporaryHideChart
                            chartIds={getMenuItemSections(subCategory)}
                          >
                            <MenuGroup>
                              <MenuGroupTitle>
                                {subCategory.label}
                              </MenuGroupTitle>
                              {
                                <>
                                  {sections.map(section => {
                                    const isActive =
                                      section.sectionItems.includes(chartId) ||
                                      activeId === section.urlComponent;

                                    return (
                                      <RequiredUserPermissionsSome
                                        key={section.urlComponent}
                                        permissions={getMenuItemPermissions(
                                          section,
                                        )}
                                      >
                                        <TemporaryHideChart
                                          chartIds={section.sectionItems}
                                        >
                                          <MenuItemEllipsis active={isActive}>
                                            {section.urlComponent ===
                                            LOADING_PLACEHOLDER ? (
                                              section.label
                                            ) : (
                                              <NavHashLink
                                                smooth
                                                className={css`
                                                  color: inherit;
                                                  text-decoration: none;
                                                `}
                                                activeClassName={css`
                                                  color: ${theme.color.primary};
                                                `}
                                                to={`/admin/${category.urlComponent}/${subCategory.urlComponent}#${section.urlComponent}`}
                                                isActive={() => isActive}
                                                title={section.label}
                                              >
                                                {section.label}
                                              </NavHashLink>
                                            )}
                                          </MenuItemEllipsis>
                                        </TemporaryHideChart>
                                      </RequiredUserPermissionsSome>
                                    );
                                  })}
                                </>
                              }
                            </MenuGroup>
                          </TemporaryHideChart>
                        </RequiredUserPermissionsSome>
                      );
                    })}
                  </CategoryItems>
                )}
              </ReportingMenuContainer>
            </TemporaryHideChart>
          </RequiredUserPermissionsSome>
        );
      })}
      <AdminRoles
        roles={[
          RoleDto.NameEnum.RESIDE_ADMIN,
          RoleDto.NameEnum.ORGANIZATION_ADMIN,
        ]}
      >
        <ReportingMenuContainer>
          <PermissionsCategoryLink />
        </ReportingMenuContainer>
      </AdminRoles>
    </ReportingMenuColumn>
  );
};

const PermissionsCategoryLink = () => {
  const {toggleModal} = usePermissionManagementModalContext();

  return (
    <CategoryNavLink to="#" onClick={toggleModal}>
      <MenuCategory iconName="permissions" title="Permissions" />
    </CategoryNavLink>
  );
};

const ReportingMenuColumn = styled(FlexColumn)`
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 20px;
  max-width: 320px;
  overflow: auto;
`;

const CategoryItems = styled.div`
  margin-top: 24px;
  margin-left: 32px;
`;

const CategoryNavLink = styled(NavLink)`
  text-decoration: none;

  &:hover {
    span {
      opacity: 0.5;
    }
  }
`;

const ReportingMenuContainer = styled(MenuContainer)`
  width: 100%;
`;

const MenuItemEllipsis = styled(MenuItem)`
  a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc((1ex + 0.5rem) * 2);
    line-height: calc(1ex + 0.5rem);
    width: 100%;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }
`;
