import React, {useState, useCallback, FormEvent} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {useQuery} from "react-query";
import {Admission} from "@reside/reside-api-admission";
import {styled} from "@reside/ui";

import {GeneralSearch} from "../search-box";
import {
  AdmissionsService,
  AdmissionStatus,
} from "../../services/AdmissionsService";
import {createPreflightUrl} from "../../pages/page-admin-preflight/route";
import {readResidentNameAnswer} from "../../models/AdmissionModel.helpers";
import {AppState, select} from "../../store";
import {DataItem} from "../search-box/Dropdown";

const AdminSearchRenderer = ({
  phrase,
  isResideAdmin,
}: {
  phrase?: string;
  isResideAdmin: boolean;
}) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>(phrase);

  const {data = [], isLoading} = useQuery(
    ["searchAdmissions", searchValue],
    async ({queryKey: [_, query]}) => {
      const {data} = await AdmissionsService.search(query);

      return data.content;
    },
    {
      enabled: searchValue?.trim().length > 1,
    },
  );

  const onSelect = (
    admission: DataItem & {status: Admission.StatusEnum; facilityId: string},
  ) => {
    if (!admission) {
      return;
    }

    if (admission.status === AdmissionStatus.PRE_FLIGHT && !isResideAdmin) {
      history.push(
        createPreflightUrl({
          facilityId: admission.facilityId,
          admissionId: admission.id,
        }),
      );

      return;
    }

    history.push(`/admin/admissions/${admission.id}`);
  };

  const onPressEnter = useCallback(
    event => {
      event.preventDownshiftDefault = true;
      const isEmpty = event.target.value.trim().length < 1;
      if (isEmpty) history.push("/admin/admissions");
      else history.push(`/admin/search?phrase=${event.target.value}`);
    },
    [history],
  );

  const reduceItems = (admissions: ReadonlyArray<Admission>) => [
    {
      label: "Admission",
      data: admissions.map(admission => ({
        id: admission.id,
        description: `${admission.code}`,
        value: readResidentNameAnswer(admission),
        facilityId: admission.facilityId,
        status: admission.status,
      })),
    },
  ];

  return (
    <Wrapper>
      <GeneralSearch
        placeholder="Search"
        items={reduceItems(data)}
        isSearching={isLoading}
        inputValue={searchValue}
        onChange={onSelect}
        onInputChange={event => setSearchValue(event.target.value)}
        onPressSpace={(event: FormEvent<HTMLInputElement>) => {
          if (searchValue?.trim().length > 0) setSearchValue(`${searchValue} `);
        }}
        itemToString={item => item?.value || ""}
        onPressEnter={onPressEnter}
      />
    </Wrapper>
  );
};

const mapState = (state: AppState) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const AdminAdmissionsSearch = connect(mapState)(AdminSearchRenderer);

const Wrapper = styled.div`
  min-width: 380px;
`;
