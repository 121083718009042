var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { styled, theme } from "../../../theme";
import { Icon } from "../../../icon";
export var Checkbox = function (_a) {
    var size = _a.size, className = _a.className, checked = _a.checked, disabled = _a.disabled, color = _a.color, onChange = _a.onChange, testId = _a.testId, Icon = _a.Icon, props = __rest(_a, ["size", "className", "checked", "disabled", "color", "onChange", "testId", "Icon"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", __assign({}, props, { checked: checked, disabled: disabled, onChange: disabled ? undefined : onChange, "data-test-id": testId, hidden: true, type: "checkbox" })),
        React.createElement(CheckboxIcon, { className: className, size: size, color: color, checked: checked, disabled: disabled }, checked && React.createElement(Icon, { name: "check", color: theme.color.white }))));
};
Checkbox.defaultProps = {
    checked: false,
    disabled: false,
    size: "normal",
    color: "primary",
    onChange: function () { },
    Icon: Icon,
};
var getCheckboxWidth = function (size) { return (size === "small" ? 1.2 : 1.5); };
var CheckboxIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 0.3em;\n  border: 2px solid ", ";\n  // Hack: min- values are required for flexbox sizing https://github.com/philipwalton/flexbugs/issues/184\n  min-height: ", "em;\n  min-width: ", "em;\n  height: ", "em;\n  width: ", "em;\n  background-color: ", ";\n\n  i {\n    display: grid;\n    font-size: 0.75em;\n  }\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 0.3em;\n  border: 2px solid ", ";\n  // Hack: min- values are required for flexbox sizing https://github.com/philipwalton/flexbugs/issues/184\n  min-height: ", "em;\n  min-width: ", "em;\n  height: ", "em;\n  width: ", "em;\n  background-color: ", ";\n\n  i {\n    display: grid;\n    font-size: 0.75em;\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return theme.color[color];
}, function (_a) {
    var size = _a.size;
    return getCheckboxWidth(size);
}, function (_a) {
    var size = _a.size;
    return getCheckboxWidth(size);
}, function (_a) {
    var size = _a.size;
    return getCheckboxWidth(size);
}, function (_a) {
    var size = _a.size;
    return getCheckboxWidth(size);
}, function (_a) {
    var checked = _a.checked, color = _a.color, theme = _a.theme;
    return checked ? theme.color[color] : "transparent";
}, function (_a) {
    var disabled = _a.disabled, checked = _a.checked, theme = _a.theme;
    return disabled &&
        "\n      background-color: ".concat(checked ? theme.color.gray100 : "transparent", ";\n      border-color: ").concat(theme.color.gray100, ";\n    ");
});
var templateObject_1;
