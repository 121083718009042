import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  FunctionComponent,
} from "react";
import {AdmissionFromSearch} from "../../services/AdmissionsService";

export enum ModalName {
  APPROVE = "APPROVE",
  ARCHIVE = "ARCHIVE",
  CANCEL = "CANCEL",
  RETURN_TO_RESIDENT = "RETURN_TO_RESIDENT",
}

const DashboardModalContext = createContext<{
  admission?: AdmissionFromSearch;
  setAdmission?: (admission: AdmissionFromSearch) => void;
  toggleModal: ({
    admission,
    modalName,
  }: {
    admission?: AdmissionFromSearch;
    modalName: ModalName;
  }) => void;
  isModalOpened: (modalName: ModalName) => boolean;
}>({
  admission: undefined,
  setAdmission: () => {},
  toggleModal: () => {},
  isModalOpened: () => false,
});

export const useDashboardModalContext = (modalName: ModalName) => {
  const {isModalOpened, toggleModal, admission, setAdmission} = useContext(
    DashboardModalContext,
  );

  return {
    admission,
    setAdmission,
    isModalOpened: isModalOpened(modalName),
    toggleModal: (admission?: AdmissionFromSearch) =>
      toggleModal({admission, modalName}),
  };
};

export const DashboardModalContextProvider: FunctionComponent = ({
  children,
}) => {
  const [admission, setAdmission] = useState<AdmissionFromSearch>();
  const [openedModalName, setOpenedModalName] = useState<ModalName>();

  const toggleModal = useCallback(
    ({
      admission,
      modalName,
    }: {
      admission?: AdmissionFromSearch;
      modalName: ModalName;
    }) => {
      setAdmission(admission);
      setOpenedModalName(openedModalName =>
        openedModalName === modalName ? undefined : modalName,
      );
    },
    [],
  );

  const isModalOpened = useCallback(
    (modalName: ModalName) => openedModalName === modalName,
    [openedModalName],
  );

  return (
    <DashboardModalContext.Provider
      value={useMemo(
        () => ({
          admission,
          setAdmission,
          toggleModal,
          isModalOpened,
        }),
        [admission, isModalOpened, toggleModal],
      )}
    >
      {children}
    </DashboardModalContext.Provider>
  );
};
