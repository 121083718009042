import update from "immutability-helper";
import {
  extractResidentExperienceTemplate,
  getAnswersFromFormValues,
} from "../AdmissionModel.helpers";

export const initialState = {
  instances: {},
  templates: {},
  fetchStatus: {},
};

export const removeWhenIncludedInFactory = idsToBeRemoved => {
  return slice =>
    slice.filter(data => idsToBeRemoved.includes(data.id) === false);
};

export const updateAnswers = (updatedAnswers, template) => answers => {
  return {
    ...answers,
    ...getAnswersFromFormValues({
      values: updatedAnswers,
      admissionAnswersObjects: answers,
      template,
    }),
  };
};

export const AdmissionsModelReducers = {
  reset() {
    return initialState;
  },
  setAdmissionById(state, {admissionId, admission}) {
    return update(state, {
      instances: {[admissionId]: {$set: admission}},
    });
  },
  pushObject(state, {instance, template}) {
    return update(state, {
      instances: {[instance.id]: {$set: instance}},
      templates: {
        [instance.templateId]: {
          $set: extractResidentExperienceTemplate(template),
        },
      },
    });
  },
  pushObjectForPrelude(state, {instance}) {
    return update(state, {
      instances: {[instance.id]: {$set: instance}},
    });
  },
  removeFlags(state, {admissionId, flagIds}) {
    return update(state, {
      instances: {
        [admissionId]: {
          flags: fn.removeWhenIncludedIn(flagIds),
        },
      },
    });
  },
  pushNote(state, {admissionId, note}) {
    return update(state, {
      instances: {
        [admissionId]: {notes: {$push: [note]}},
      },
    });
  },
  removeNote(state, {admissionId, noteId}) {
    return update(state, {
      instances: {
        [admissionId]: {
          notes: slice => slice.filter(note => note.id !== noteId),
        },
      },
    });
  },
  completeSlide(state, {admissionId, slideId}) {
    return update(state, {
      instances: {
        [admissionId]: {
          completedSlides: slice => Array.from(new Set([...slice, slideId])),
        },
      },
    });
  },
  removeCompleteSlide(state, {admissionId, slideId}) {
    return update(state, {
      instances: {
        [admissionId]: {
          completedSlides: slice => slice.filter(s => s !== slideId),
        },
      },
    });
  },
  setAnswers(state, {admissionId, answers: updatedAnswers}) {
    const instance = state.instances[admissionId];
    const template = state.templates[instance.templateId];

    return update(state, {
      instances: {
        [admissionId]: {
          answers: fn.updateAnswers(updatedAnswers, template),
        },
      },
    });
  },
  setSignatureDate(state, {admissionId, signatureDate}) {
    return update(state, {
      instances: {
        [admissionId]: {
          signatureDate: {$set: signatureDate},
        },
      },
    });
  },
  setSatisfactorySurvey(state, {admissionId, satisfactorySurvey}) {
    return update(state, {
      instances: {
        [admissionId]: {
          satisfactorySurvey: {
            $set: Object.entries(satisfactorySurvey).reduce(
              (obj, [key, value]) => {
                obj[key] = value;

                return obj;
              },
              {},
            ),
          },
        },
      },
    });
  },
  setLastViewedSlideIdx(state, {admissionId, lastViewedSlideIdx}) {
    return update(state, {
      instances: {
        [admissionId]: {lastViewedSlideIdx: {$set: lastViewedSlideIdx}},
      },
    });
  },
  setFetchStatus: (state, {admissionId, loaded}) => {
    return update(state, {fetchStatus: {[admissionId]: {$set: loaded}}});
  },
};

export const fn = {
  removeWhenIncludedIn: removeWhenIncludedInFactory,
  updateAnswers,
};
