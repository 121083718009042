import {
  CreateOrganizationDto,
  UpdateOrganizationDto,
  PageOfOrganizationDto,
  OrganizationControllerApi,
} from "@reside/reside-api-app";
import {http} from "./api";
import portableFetch from "portable-fetch";
import {ApiResponse} from "apisauce";

import {BASE_PATH} from "../config";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {ImportExportHistory} from "../pages/page-admin-organizations-users/utils/types";

export const OrganizationsService = {
  // Note: can't use generated endpoint, as we can't pass query to it.
  get: (query: any): Promise<ApiResponse<PageOfOrganizationDto>> =>
    http.get("/organizations", query),

  find: (organizationIdOrCode: string) =>
    organizationApi.getOrganizationUsingGET(
      organizationIdOrCode,
      getAuthorizationHeader(),
    ),

  create: (data: CreateOrganizationDto) =>
    organizationApi.createOrganizationUsingPOST(data, getAuthorizationHeader()),

  update: (organizationId: string, data: UpdateOrganizationDto) =>
    organizationApi.updateOrganizationUsingPUT(
      data,
      organizationId,
      getAuthorizationHeader(),
    ),

  importExportUsers: {
    getImportExportHistory: (organizationId: string) =>
      http.get<Array<ImportExportHistory>>(
        `import-export/organizations/${organizationId}/users`,
      ),
    uploadFile: (organizationId: string, file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      return http.post(
        `import-export/organizations/${organizationId}/users`,
        formData,
      );
    },
  },
};

export const organizationApi = new OrganizationControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);
