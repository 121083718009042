import {createModel} from "@rematch/core";
import update from "immutability-helper";

export const InitialState = {
  language: "en",
  catalogs: {
    en: {
      messages: {},
    },
  },
};

export const model = createModel({
  name: "locale",

  state: InitialState,

  reducers: {
    mergeMessagesIntoCatalogs,
    // Listen to external actions
    "admissions/pushObject": mergeMessagesIntoCatalogs,
  },

  selectors: {
    catalogs: () => state => state.locale.catalogs,
    language: () => state => state.locale.language,
  },
});

function mergeMessagesIntoCatalogs(state, payload) {
  let nextState = state;

  Object.entries(payload.template.translations).forEach(
    ([language, messageBag]) => {
      nextState = update(nextState, {
        catalogs: {
          [language.toLowerCase()]: {messages: {$merge: messageBag}},
        },
      });
    },
  );

  return nextState;
}
