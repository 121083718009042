"use strict";
// TODO Refactor name to better reflect meaning of this validation function (maybe call it "not in")
// TODO Write description for every custom validator
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
exports.Validator = {
    name: "required_unless_checked",
    isImplicit: true,
    errorMessage: "This field is required.",
    validate: function (value, requirement) {
        if (value === void 0) { value = ""; }
        if (requirement === void 0) { requirement = ""; }
        if (typeof value !== "string") {
            return false;
        }
        var requirementArr = requirement.split(",").filter(function (val) { return val !== ""; });
        var valueArr = value.split(":").filter(function (val) { return val !== ""; });
        if (valueArr.length === 0 || requirementArr.length === 0) {
            return true;
        }
        return !valueArr.some(function (v) { return requirementArr.includes(v); });
    },
};
