import React from "react";
import {Button} from "@reside/ui";

import {getResidentTestId} from "../../../helper";
import {
  ModalName,
  useDashboardModalContext,
} from "../../../DashboardModalContext";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const ArchiveAdmissionButton = ({admission}: Props) => {
  const {toggleModal: toggleArchiveModal} = useDashboardModalContext(
    ModalName.ARCHIVE,
  );

  return (
    <Button
      size="small"
      testId={getResidentTestId(admission, "ARCHIVED")}
      onClick={() => toggleArchiveModal(admission)}
    >
      Archive
    </Button>
  );
};
