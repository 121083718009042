import {isValid, parse, format} from "date-fns";
import {capitalize} from "lodash";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";

const FIELD_NAME_REGEX = /([A-Z][a-z])/g;

export const formatFieldName = (fieldName: string) => {
  return capitalize(
    fieldName.replace(FIELD_NAME_REGEX, " $1").trim().toLowerCase(),
  );
};

export const formatUnderscoreStrings = (fieldName: string) => {
  return capitalize(fieldName.replace("_", " ").trim().toLowerCase());
};

export const formatData = (data: any[]) =>
  data.map((row: any[]) => {
    return row.map((cell: any) => {
      if (typeof cell === "boolean") {
        return cell ? "Yes" : "No";
      } else if (isValid(parse(cell, DATE_FORMAT.ISO_DATE, null))) {
        return format(
          parse(cell, DATE_FORMAT.ISO_DATE, null),
          DATE_FORMAT.FULL_DATE,
        );
      }

      return cell;
    });
  });

export const formatHeaders = (headers: string[]) =>
  headers.map(formatFieldName);
