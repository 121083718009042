import {QuestionType} from "@reside/forms";
import {ErrorResponseDto} from "@reside/reside-api-admission";
import {
  Admission,
  AdmissionDocumentControllerApi,
  AdmissionNotificationModel,
  AdmissionFlag,
  PatchAdmissionDto,
  ChecklistItem,
  AdmissionNote,
} from "@reside/reside-api-admission";
import portableFetch from "portable-fetch";
import {ApiErrorData, http} from "./api";
import {Override} from "../utils/helpers/tsUtils";
import {BASE_PATH} from "../config";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {getCommonAuthorizationHeader} from "./api/selectors";

export const AdmissionStatus = Admission.StatusEnum;
export type AdmissionStatusEnum = keyof typeof AdmissionStatus;

export type AdmissionAnswerObject<Value = string | string[] | any> = {
  created?: number;
  fileReference?: boolean;
  hidden?: boolean;
  metadata?: any;
  preFlight?: boolean;
  questionId?: string;
  questionType: QuestionType;
  value: Value;
};

export type AdmissionAnswerObjects = Record<string, AdmissionAnswerObject>;

export const admissionsApi = new AdmissionDocumentControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);

export const AdmissionsService = {
  get: (id: string) => http.get<Admission>(`/v3/admissions/${id}`),

  patch: (admissionId: string, admission: PatchAdmissionDto) =>
    http.patch<Admission>(`/v3/admissions/${admissionId}`, admission),

  patchAfterAdmissionAnswers: (
    admissionId: string,
    admission: PatchAdmissionDto,
  ) =>
    http.patch<Admission>(
      `/v3/admissions/${admissionId}/after-admission`,
      admission,
    ),

  patchNotes: (admissionId: string, admissionNotesData: AdmissionNote[]) =>
    admissionsApi.updateAdmissionNoteUsingPATCH(
      admissionNotesData,
      admissionId,
      getAuthorizationHeader(),
    ),

  preview: (admissionId: string) =>
    admissionsApi.getPreviewUsingGET(admissionId, getAuthorizationHeader()),

  create: (data: Admission) => http.post<Admission>("/v3/admissions", data),

  update: (id: string, data: Admission) =>
    http.put<Admission>(`/v3/admissions/${id}`, data),

  restore: (id: string) =>
    admissionsApi.restoreAdmissionUsingPATCH(id, getAuthorizationHeader()),

  transitions: (id: string) =>
    admissionsApi.getAdmissionTransitionsUsingGET(id, getAuthorizationHeader()),

  setInProgress: (admissionId: string) =>
    admissionsApi.sendAdmissionToResidentUsingPATCH(
      admissionId,
      getAuthorizationHeader(),
    ),

  delete: (admissionId: string, reason: string) =>
    admissionsApi.deleteAdmissionUsingPATCH(
      {reason},
      admissionId,
      getAuthorizationHeader(),
    ),

  sign: (admissionId: string) =>
    admissionsApi.signAdmissionUsingPATCH(
      admissionId,
      getAuthorizationHeader(),
    ),

  approve: (admissionId: string, data: any) =>
    admissionsApi.approveAdmissionUsingPATCH(
      data,
      admissionId,
      getAuthorizationHeader(),
    ),

  archive: (admissionId: string) =>
    admissionsApi.archiveAdmissionUsingPATCH(
      admissionId,
      getAuthorizationHeader(),
    ),

  patchAdmissionChecklistItem: ({
    admissionId,
    checklistItemId,
    data,
  }: {
    admissionId: string;
    checklistItemId: string;
    data: ChecklistItem;
  }) =>
    admissionsApi.patchChecklistItemUsingPATCH(
      data,
      admissionId,
      checklistItemId,
      getAuthorizationHeader(),
    ),

  sendNotification: (admissionId: string, data: AdmissionNotificationModel) =>
    admissionsApi.sendNotificationUsingPOST(
      data,
      admissionId,
      getAuthorizationHeader(),
    ),

  search: (query: string) =>
    admissionsApi.searchAdmissionsByQueryUsingGET(
      query,
      getAuthorizationHeader(),
    ),

  getFlags: (admissionId: string) =>
    admissionsApi.getAdmissionFlagsUsingGET(
      admissionId,
      getAuthorizationHeader(),
    ),

  deleteFlag: (admissionId: string, flagId: string) =>
    admissionsApi.deleteAdmissionFlagUsingDELETE(
      admissionId,
      flagId,
      getCommonAuthorizationHeader(),
    ),

  createFlag: (admissionId: string, flag: AdmissionFlag) =>
    admissionsApi.createAdmissionFlagUsingPOST(
      flag,
      admissionId,
      getCommonAuthorizationHeader(),
    ),
};

export type SignError = ApiErrorData &
  Readonly<{
    data: Readonly<{
      isValid: boolean;
      errors: ReadonlyArray<SlideValidationError>;
    }>;
  }>;

type SlideValidationError = Readonly<{
  slideId: string;
  errors: ReadonlyArray<ValidationError>;
}>;

type ValidationError = Readonly<{
  field: string;
  message: string;
}>;

export type PatchAdmissionChecklistItem =
  typeof AdmissionsService.patchAdmissionChecklistItem;

// TODO: Add dashboard checkboxes into model
export type AdmissionFromSearch = Override<
  Pick<
    Admission,
    | "id"
    | "code"
    | "facilityId"
    | "answers"
    | "facilityTimeZoneId"
    | "checklist"
    | "completionPercentage"
    | "status"
    | "signatureDate"
    | "created"
    | "updated"
    | "flags"
    | "resources"
    | "dateOfAdmission"
    | "pcc"
  >,
  {
    status: AdmissionStatusEnum;
    checklist: ReadonlyArray<ChecklistItem>;
  }
>;

export const isMissingMandatoryChecklistItemException = (
  error: ErrorResponseDto,
) => error.exception.includes("MissingMandatoryChecklistItemException");
