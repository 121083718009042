import {Column, ListTable} from "../list-table";
import {OrganizationAddressCell} from "../list-table/OrganizationAddressCell";
import {StatusCell, TextCell} from "../list-table/SharedCells";
import {ContactCell} from "../list-table/ContactCell";

export function AdminOrganizationsTable(props) {
  return (
    <ListTable
      key={props.tableName}
      data={props.organizations}
      getRowId={row => row.rowData.id}
      showDoubleArrowOnNotSorted
      sortPredicates={{
        name: ["name"],
        address: [
          "address.number",
          "address.street",
          "address.city",
          "address.state.abbreviation",
          "address.zip",
        ],
        contact: ["phone", "email"],
        status: ["status"],
      }}
      sortBy="name"
      sortDirection="ASC"
      onRowClick={props.onRowClick}
    >
      <Column
        cellRenderer={row => <TextCell>{row.rowData.name}</TextCell>}
        label="Name"
        sortKey="name"
        width={200}
      />
      <Column
        cellRenderer={row => (
          <OrganizationAddressCell data={row.rowData.address} />
        )}
        label="Address"
        sortKey="address"
        width={300}
      />
      <Column
        cellRenderer={row => <StatusCell data={row.rowData.status} />}
        label="Status"
        sortKey="status"
        width={150}
      />
      <Column
        cellRenderer={row => <ContactCell data={row.rowData} />}
        label="Contact"
        sortKey="contact"
        width={200}
      />
    </ListTable>
  );
}
