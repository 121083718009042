import {Button} from "@reside/ui";
import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import {select} from "../../../../store";
import {createPreflightUrl} from "../../../../pages/page-admin-preflight/route";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";
import {getResidentTestId} from "../../helper";

type StateProps = Readonly<{
  isResideAdmin: boolean;
}>;

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

const EditPreflightButtonRenderer = ({
  isResideAdmin,
  admission,
}: Props & StateProps) => {
  const history = useHistory();
  const {id: admissionId, facilityId} = admission;
  const onEditPreflight = () =>
    history.push(createPreflightUrl({facilityId, admissionId}));

  return (
    <Button
      size="small"
      onClick={onEditPreflight}
      testId={getResidentTestId(admission, "editPccPreflight")}
      disabled={isResideAdmin}
    >
      Edit
    </Button>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const EditPreflightButton = connect(mapStateToProps)(
  EditPreflightButtonRenderer,
);
