import React from "react";
import { Grid } from "../../grid";
import { BlockField } from "../";
export var BlockColumn = function (_a) {
    var children = _a.children, columns = _a.columns, columnEnd = _a.columnEnd, columnStart = _a.columnStart, onCatch = _a.onCatch;
    return (React.createElement(Grid, { columns: columns, columnStart: columnStart, columnEnd: columnEnd }, children.map(function (block) { return (React.createElement(BlockField, { key: block.id, block: block, onCatch: onCatch })); })));
};
BlockColumn.defaultProps = {
    columns: 2,
};
