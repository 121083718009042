import {uniq} from "lodash";
import {TableUserDto} from "@reside/reside-api-app";
import {
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  ADMISSIONS_TOTAL_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  FACILITY_MEDICARE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  RE_ADMISSION_RATE_BY_FACILITY_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  TIME_TO_CLOSE_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
} from "./analytics";
import {
  AD_HELP_REPORT_ID,
  AD_PROVIDED_REPORT_ID,
  ARBITRATION_AGREEMENT_REPORT_ID,
  CHECKLIST_ITEM_REPORT_ID,
  FACILITY_CHANGES_REPORT_ID,
  FUNERAL_REPORT_ID,
  INCOME_REPORT_ID,
  INFLUENZA_REPORT_ID,
  LAUNDRY_REPORT_ID,
  LIST_OF_RESIDENTS_REPORT_ID,
  MEDICAID_REQUEST_REPORT_ID,
  MEDICARE_WAIVER_REPORT_ID,
  PHOTOS_REPORT_ID,
  PNEUMOCOCCAL_REPORT_ID,
  SLIDE_INTERACTIONS_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_REPORT_ID,
  SURROGATE_HELP_REPORT_ID,
  SURROGATE_REPORT_ID,
  TOTAL_ADMISSIONS_REPORT_ID,
  TRUST_FUND_REPORT_ID,
} from "./reports";
import {DASHBOARD_REPORT_ID} from "./reports/ReportQuicksightDashboard";

// TODO: Charts have defined permissions already, use that so we have single source of truth
export const BLOCK_PERMISSIONS: {
  [key: string]: TableUserDto.PermissionsEnum[];
} = {
  [ADMISSIONS_TOTAL_CHART_ID]: [
    TableUserDto.PermissionsEnum.APPROVED_ARCHIVED_ADMISSIONS,
    TableUserDto.PermissionsEnum
      .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE,
    TableUserDto.PermissionsEnum.AVG_TIME_SPENT_TO_CLOSE_ADMISSION,
  ],
  [TIME_TO_CLOSE_BY_FACILITY_CHART_ID]: [
    TableUserDto.PermissionsEnum.COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED,
  ],
  [REPRESENTATIVE_RATE_CHART_ID]: [
    TableUserDto.PermissionsEnum
      .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE,
  ],
  [REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID]: [
    TableUserDto.PermissionsEnum
      .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE,
  ],
  [MANAGED_MEDICAID_CHART_ID]: [
    TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID,
  ],
  [MANAGED_MEDICAID_BY_FACILITY_CHART_ID]: [
    TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID,
  ],
  [WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID]: [
    TableUserDto.PermissionsEnum.PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT,
  ],
  [WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID]: [
    TableUserDto.PermissionsEnum.PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT,
  ],
  [RE_ADMISSION_RATE_CHART_ID]: [TableUserDto.PermissionsEnum.READMISSION_RATE],
  [RE_ADMISSION_RATE_BY_FACILITY_CHART_ID]: [
    TableUserDto.PermissionsEnum.READMISSION_RATE,
  ],
  [ADMISSIONS_TOTAL_RESIDENTS_CHART_ID]: [
    TableUserDto.PermissionsEnum.ANALYTICS_ADMISSION_IN_TOTAL,
    TableUserDto.PermissionsEnum.READMISSION_RATE,
    TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID,
    TableUserDto.PermissionsEnum.PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT,
  ],
  [FACILITY_COMPLETION_RATE_CHART_ID]: [
    TableUserDto.PermissionsEnum.ADMISSION_COMPLETION_RATE,
  ],
  [STAFF_COMPLETION_RATE_CHART_ID]: [
    TableUserDto.PermissionsEnum.ADMISSION_COMPLETION_RATE,
  ],
  [ADMISSIONS_COMPLETION_ON_TIME_CHART_ID]: [
    TableUserDto.PermissionsEnum.COMPLETION_TIME,
  ],
  [ADMISSIONS_COMPLETION_OVERALL_CHART_ID]: [
    TableUserDto.PermissionsEnum.COMPLETION_TIME,
  ],
  [ARBITRATION_AGREEMENT_RATE_CHART_ID]: [
    TableUserDto.PermissionsEnum.ARBITRATION_AGREEMENT_COMPLETED,
  ],
  [FACILITY_MEDICARE_CHART_ID]: [
    TableUserDto.PermissionsEnum.PATIENTS_WITH_MEDICAL_SUPPLEMENT,
  ],
  [ADMISSIONS_TIME_BY_STAGE_CHART_ID]: [
    TableUserDto.PermissionsEnum.AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION,
  ],
  [FLAGS_AGE_CHART_ID]: [TableUserDto.PermissionsEnum.AGE_OF_HELP_FLAGS],
  [FLAGS_BY_SLIDE_CHART_ID]: [
    TableUserDto.PermissionsEnum.MOST_FREQUENTLY_FLAGGED_SLIDES,
  ],
  [PAYER_SOURCE_65_75_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_65_75,
  ],
  [PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_65_75,
  ],
  [PAYER_SOURCE_75_85_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_75_85,
  ],
  [PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_75_85,
  ],
  [PAYER_SOURCE_85_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_85,
  ],
  [PAYER_SOURCE_85_BY_COUNTRY_CHART_ID]: [
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_85,
  ],
  [TOTAL_ADMISSIONS_REPORT_ID]: [
    TableUserDto.PermissionsEnum.ADMISSION_IN_TOTAL,
  ],
  [LIST_OF_RESIDENTS_REPORT_ID]: [
    TableUserDto.PermissionsEnum.ADMISSION_IN_TOTAL,
  ],
  [ARBITRATION_AGREEMENT_REPORT_ID]: [
    TableUserDto.PermissionsEnum.ARBITRATION_AGREEMENT,
  ],
  [INCOME_REPORT_ID]: [
    TableUserDto.PermissionsEnum.INCOMES_AND_PERSONAL_RESOURCE,
  ],
  [TRUST_FUND_REPORT_ID]: [TableUserDto.PermissionsEnum.TRUST_FUND_POLICY],
  [PNEUMOCOCCAL_REPORT_ID]: [
    TableUserDto.PermissionsEnum.PNEUMOCOCCAL_VACCINES,
  ],
  [INFLUENZA_REPORT_ID]: [TableUserDto.PermissionsEnum.INFLUENZA_VACCINES],
  [SURROGATE_REPORT_ID]: [
    TableUserDto.PermissionsEnum.SURROGATE_DECISION_MAKING,
  ],
  [SURROGATE_HELP_REPORT_ID]: [
    TableUserDto.PermissionsEnum
      .SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION,
  ],
  [AD_HELP_REPORT_ID]: [
    TableUserDto.PermissionsEnum
      .RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE,
  ],
  [AD_PROVIDED_REPORT_ID]: [
    TableUserDto.PermissionsEnum.EXISTING_ADVANCED_DIRECTIVE_PROVIDED,
  ],
  [MEDICARE_WAIVER_REPORT_ID]: [TableUserDto.PermissionsEnum.MEDICARE_WAIVER],
  [MEDICAID_REQUEST_REPORT_ID]: [
    TableUserDto.PermissionsEnum.RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID,
  ],
  [SNFABN_COST_ADMISSION_REPORT_ID]: [
    TableUserDto.PermissionsEnum.ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE,
  ],
  [STAFF_REPORT_ID]: [TableUserDto.PermissionsEnum.STAFF_ADMISSIONS],
  [FACILITY_CHANGES_REPORT_ID]: [TableUserDto.PermissionsEnum.FACILITY_CHANGES],
  [FUNERAL_REPORT_ID]: [TableUserDto.PermissionsEnum.FUNERAL],
  [LAUNDRY_REPORT_ID]: [TableUserDto.PermissionsEnum.LAUNDRY_CONSENT],
  [PHOTOS_REPORT_ID]: [TableUserDto.PermissionsEnum.PHOTOS],
  [SLIDE_INTERACTIONS_REPORT_ID]: [
    TableUserDto.PermissionsEnum.SLIDE_INTERACTIONS,
  ],
  [CHECKLIST_ITEM_REPORT_ID]: [TableUserDto.PermissionsEnum.CHECKLIST_ITEMS],
  [DASHBOARD_REPORT_ID]: [TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS],
};

export const PERMISSION_BLOCKS = Object.entries(BLOCK_PERMISSIONS)
  .map(([block, permissions]) => [permissions, block])
  .reduce<{[k in TableUserDto.PermissionsEnum]?: string[]}>(
    (data, [permissions, block]) => ({
      ...data,
      ...(permissions as TableUserDto.PermissionsEnum[]).reduce(
        (acc, permission) => ({
          ...acc,
          [permission]: data[permission]
            ? [...data[permission], block]
            : [block],
        }),
        {},
      ),
    }),
    {},
  );

export const getSectionItemsPermissions = (
  sectionItems: ReadonlyArray<string>,
) =>
  uniq(
    sectionItems.reduce(
      (permissions, section) => [
        ...permissions,
        ...(BLOCK_PERMISSIONS[section] ?? []),
      ],
      [],
    ),
  );

export const getSectionsForPermissions = (
  permissions: ReadonlyArray<TableUserDto.PermissionsEnum>,
) =>
  permissions.reduce(
    (sections, permission) => [
      ...sections,
      ...(PERMISSION_BLOCKS[permission] || []),
    ],
    [],
  );
