import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from "react";
import {ChecklistItem} from "@reside/reside-api-admission";

import {PccDocumentUploadModal} from "./";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";

type ContextValue = {
  admission: AdmissionFromSearch;
  checklistItem?: ChecklistItem;
};

const PccDocumentUploadModalContext = createContext<{
  admission: AdmissionFromSearch;
  checklistItem?: ChecklistItem;
  isPccDashboardCheckboxesFeatureEnabled: boolean;
  openPccUploadModal: (props: ContextValue) => void;
  closePccUploadModal: () => void;
}>({
  admission: undefined,
  checklistItem: undefined,
  isPccDashboardCheckboxesFeatureEnabled: true,
  openPccUploadModal: () => {},
  closePccUploadModal: () => {},
});

const initialState: ContextValue = {
  admission: undefined,
  checklistItem: undefined,
};

export const usePccDocumentUploadModalContext = () =>
  useContext(PccDocumentUploadModalContext);

export const PccDashboardCheckboxesContextProvider = ({
  isPccDashboardCheckboxesFeatureEnabled,
  children,
}: {
  isPccDashboardCheckboxesFeatureEnabled: boolean;
  children: ReactNode;
}) => {
  const [{admission, checklistItem}, setState] = useState(initialState);

  const openPccUploadModal = ({admission, checklistItem}: ContextValue) => {
    setState({
      admission,
      checklistItem,
    });
  };

  const closePccUploadModal = () => setState(initialState);

  return (
    <PccDocumentUploadModalContext.Provider
      value={useMemo(
        () => ({
          admission,
          checklistItem,
          isPccDashboardCheckboxesFeatureEnabled,
          openPccUploadModal,
          closePccUploadModal,
        }),
        [admission, checklistItem, isPccDashboardCheckboxesFeatureEnabled],
      )}
    >
      {children}
      {/**
       * NOTE: The modal must be rendered AFTER children, so it's after all the other modals in the DOM.
       * That way it can be opened from ApproveAdmissionModal & be stacked on top of it!
       */}
      {isPccDashboardCheckboxesFeatureEnabled && (
        <PccDocumentUploadModal
          admission={admission}
          checklistItem={checklistItem}
          onRequestClose={closePccUploadModal}
        />
      )}
    </PccDocumentUploadModalContext.Provider>
  );
};
