import {useMutation, useQueryClient} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useDeleteSftpConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (sftpConfigId: string) => {
      return await HubAndSpokeService.deleteSftpConfig(sftpConfigId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("GET_SFTP_CONFIGS");
        Alert.success("Configuration successfully deleted!");
      },
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to delete configuration.");
      },
    },
  );
};
