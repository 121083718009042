var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled, theme } from "../theme";
export var ProgressBar = function (_a) {
    var progress = _a.progress, _b = _a.width, width = _b === void 0 ? 65 : _b, _c = _a.height, height = _c === void 0 ? 10 : _c, _d = _a.progressColor, progressColor = _d === void 0 ? progressColorMap : _d, testId = _a.testId, children = _a.children;
    return (React.createElement(Meter, { width: width, height: height },
        children,
        React.createElement("div", { style: {
                width: "".concat(progress, "%"),
                height: height,
                backgroundColor: progressColor(progress),
            }, "data-test-id": testId })));
};
function progressColorMap(progress) {
    if (progress < 25)
        return theme.color.orange100;
    else if (progress < 75)
        return theme.color.yellow100;
    else
        return theme.color.green100;
}
var Meter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  width: ", "px;\n  height: ", "px;\n  background: rgba(168, 182, 202, 0.23);\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  width: ", "px;\n  height: ", "px;\n  background: rgba(168, 182, 202, 0.23);\n"])), function (_a) {
    var width = _a.width;
    return width;
}, function (_a) {
    var height = _a.height;
    return height;
});
var templateObject_1;
