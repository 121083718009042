import React from "react";
import {PccFacilityMappingDto} from "@reside/reside-api-app";
import {useFormikContext} from "formik";
import {Button} from "@reside/ui";
import {useFormikIsFieldDirty} from "../../../../../hooks/useFormikIsFieldDirty";
import {Footer} from "./styles";

/**
 * Component that renders the form buttons for PCC Facility Mapping settings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isPccFacilityMappingUpdating - Indicates whether the PCC Facility Mapping is currently being updated.
 * @returns {JSX.Element} JSX element representing the form buttons.
 *
 * @see {@link useFormikIsFieldDirty} for more details on the dirty input check logic.
 *
 */
export const FormButtons = ({
  isPccFacilityMappingUpdating,
}: {
  isPccFacilityMappingUpdating: boolean;
}): JSX.Element => {
  const isInputDirty = useFormikIsFieldDirty();

  const {values, submitForm, setFieldValue} =
    useFormikContext<PccFacilityMappingDto>();

  return (
    <Footer>
      <Button
        color="success"
        testId="pccSettingsBtn"
        disabled={
          isPccFacilityMappingUpdating ||
          (!isInputDirty("pccFacilityId") &&
            !isInputDirty("pccOrganizationId") &&
            !isInputDirty("defaultDocumentCategoryIdentifier"))
        }
        onClick={submitForm}
      >
        Save
      </Button>

      <Button
        color="primary"
        outline
        testId="pccSettingsBtn"
        disabled={
          isPccFacilityMappingUpdating ||
          (!values.pccFacilityId && !values.pccOrganizationId)
        }
        onClick={() => {
          setFieldValue("pccOrganizationId", "");

          setFieldValue("pccFacilityId", "" as unknown as number);
        }}
      >
        Clear PCC ID fields
      </Button>
    </Footer>
  );
};
