export const SHOULD_START_TIMEOUT_LOGOUT_ADMIN =
  "SHOULD_START_TIMEOUT_LOGOUT_ADMIN";
export const STARTED_TIMEOUT_LOGOUT_ADMIN = "STARTED_TIMEOUT_LOGOUT_ADMIN";
export const SHOULD_LOGOUT_ADMIN = "SHOULD_LOGOUT_ADMIN";

export const SHOULD_START_TIMEOUT_LOGOUT_RE = "SHOULD_START_TIMEOUT_LOGOUT_RE";
export const STARTED_TIMEOUT_LOGOUT_RE = "STARTED_TIMEOUT_LOGOUT_RE";
export const SHOULD_LOGOUT_RE = "SHOULD_LOGOUT_RE";
export const LOGGED_OUT_DUE_TO_INACTIVITY = "LOGGED_OUT_DUE_TO_INACTIVITY";

/** If none of these events have been conducted for more than 15 minutes auto logout user. */
export const EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart",
  "mousewheel",
  "touchmove",
  "touchend",
  "touchcancel",
  "keydown",
];

/**There are different times that should be used to logout user */
export const AUTO_LOGOUT_TIMES: {
  RESIDENT_EXPERIENCE_OR_ADMIN_PAGE: number;
  INACTIVITY_ACCROSS_APPLICATION: number;
} = {
  RESIDENT_EXPERIENCE_OR_ADMIN_PAGE: 90000,
  INACTIVITY_ACCROSS_APPLICATION: 900000,
};
