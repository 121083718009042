import {connect} from "react-redux";

import {Text, styled, ExternalLink} from "@reside/ui";

import {AppState, select} from "../../store";
import {UserDto} from "@reside/reside-api-app";

type CopyrightProps = {
  user: UserDto;
};

const CopyrightRenderer = ({user}: CopyrightProps) => {
  const currentYear = new Date().getFullYear();

  return (
    <CopyrightFooter>
      <CopyrightInfo>
        Reside
        <CopyrightLink href="https://www.linkedin.com/company/reside-admissions">
          {" "}
          💙{" "}
        </CopyrightLink>
        {user.firstName} - ©{currentYear}
        <CopyrightLink href="https://resideadmissions.com">
          {" "}
          Reside Admission Inc.
        </CopyrightLink>
      </CopyrightInfo>
    </CopyrightFooter>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const Copyright = connect(mapStateToProps)(CopyrightRenderer);

const CopyrightFooter = styled.footer`
  position: fixed;
  inset-inline: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${({theme}: any) => theme.space[4]}px;
  padding-block: ${({theme}: any) => theme.space[1] * 0.5}px;
  pointer-events: none;
  user-select: none;
  background-color: ${({theme}: any) => theme.color.darkBlue5};
`;

const CopyrightInfo = styled(Text)`
  font-size: 0.675rem;
  margin-left: auto;
`;

const CopyrightLink = styled(ExternalLink)`
  text-decoration: none;
  pointer-events: all;
`;
