import React, {ChangeEvent, useRef} from "react";
import {Icon} from "@reside/ui";
import {Input, Wrapper, IconWrapper, ClearButton} from "./styles";
import {useFilterResidentsContext} from "../context/useFilterResidentsContext";

export const SearchResidentsField = (): JSX.Element => {
  const ref = useRef(null);
  const {searchTerm, setSearchTerm} = useFilterResidentsContext();


  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="search" color="#A8B6CA" size={15} />
      </IconWrapper>
      <Input
        ref={ref}
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchTerm(e.target.value);
        }}
      />

      <ClearButton
        onClick={() => {
          ref.current.value = "";
          setSearchTerm("");
        }}
        showClearButton={!!ref?.current?.value}
      >
        <Icon name="clear" color="#A8B6CA" size={15} />
      </ClearButton>
    </Wrapper>
  );
};
