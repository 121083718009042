import {PccFacilityMappingDto} from "@reside/reside-api-app";

/**
 * Checks if a PCC facility mapping object contains data. Use it to prevent seting the initial values to empty object which is returend from the API in absence of data.
 *
 * @param {PccFacilityMappingDto} pccFacilityMapping - The PCC facility mapping object to check.
 * @returns {boolean} Returns `true` if the object contains data, `false` otherwise.
 *
 */
export const hasPccMappingData = (pccFacilityMapping: PccFacilityMappingDto) =>
  Object.keys(pccFacilityMapping ?? {}).length > 0;
