import React, {FunctionComponent} from "react";
import {AdminIcon, IconWrapper, Wrapper} from "./styles";

export const AdministrationMenuDropdownButton: FunctionComponent<{
  open: boolean;
  className?: string;
}> = ({open, children, ...rest}) => (
  <Wrapper className={rest.className} {...rest}>
    {children}
    <IconWrapper>
      <AdminIcon name="admin" size={22} open={open} />
    </IconWrapper>
  </Wrapper>
);
