import React, {FunctionComponent} from "react";
import {styled} from "@reside/ui";
import {AdminNavigationHeader} from "../admin-navigation-header";
import {Copyright} from "../copyright";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const AdminLayout: FunctionComponent<Props> = ({
  children,
  className,
}: Props) => (
  <Layout>
    {/* Pass className down to LayoutContent so we can create a styled-component based on AdminLayout */}
    <LayoutContent className={className}>{children}</LayoutContent>
    <AdminNavigationHeader />
    <Copyright />
  </Layout>
);

export const Layout = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  width: 100%;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 25px 0;
  width: 100%;
  max-width: 1280px;

  @media (max-width: 1170px) {
    padding: 25px 15px;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
  }

  @media (max-width: 460px) {
    padding: 14px 7px;
  }
`;

export const AdminLayoutFullHeightAndTwoCells = styled(AdminLayout)`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;
  height: calc(100vh - 70px); // 70px is the height of the header
`;
