var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Card } from "../card";
import { H3, H2 } from "../text";
import { styled } from "../theme";
export var ErrorMessage = function (_a) {
    var title = _a.title, message = _a.message;
    return (React.createElement(Message, null,
        React.createElement(H2, { color: "orange100" }, title),
        React.createElement(H3, null, message)));
};
var Message = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 2px solid ", ";\n"], ["\n  border: 2px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.orange100;
});
var templateObject_1;
