import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  DateRangePicker as ReactDateRangePicker,
  DateRangePickerShape,
} from "react-dates";

import "./style.scss";

export const DateRangePicker = (props: DateRangePickerShape) => (
  <ReactDateRangePicker {...props} hideKeyboardShortcutsPanel />
);
