var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createRef, Component } from "react";
import ReactModal from "react-modal";
import { cx } from "@emotion/css";
import { css } from "@emotion/react";
import { styled } from "../theme";
import { Icon } from "../icon";
import { H1, Text } from "../text";
import { Box } from "../box";
import { BlankButton } from "../button";
import { isMobile } from "../is-mobile";
if (process.env.NODE_ENV !== "test") {
    ReactModal.setAppElement("#root");
}
if (process.env.NODE_ENV === "test") {
    ReactModal.setAppElement("body");
}
// TODO: refactor to functional component
// TODO: use variables from theme in the components
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpen: _this.props.isOpen,
        };
        _this.triggerRef = createRef();
        _this.closeModal = function () {
            if (_this.props.onRequestClose) {
                _this.props.onRequestClose();
            }
            _this.setState({ isOpen: false });
        };
        _this.openModal = function () { return _this.setState({ isOpen: true }); };
        return _this;
    }
    Modal.getDerivedStateFromProps = function (props, state) {
        if (props.isOpen === undefined) {
            return null;
        }
        if (props.isOpen === state.isOpen) {
            return null;
        }
        return { isOpen: props.isOpen };
    };
    Modal.prototype.componentDidMount = function () {
        if (this.triggerRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.triggerRef.current.addEventListener("click", this.openModal);
        }
    };
    Modal.prototype.componentWillUnmount = function () {
        if (this.triggerRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.triggerRef.current.removeEventListener("click", this.openModal);
        }
    };
    Modal.prototype.render = function () {
        var _a = this.props, className = _a.className, fullScreen = _a.fullScreen, trigger = _a.trigger, renderTrigger = _a.renderTrigger, title = _a.title, size = _a.size, noCloseButton = _a.noCloseButton, closeButtonPosition = _a.closeButtonPosition, topControls = _a.topControls, children = _a.children, Header = _a.Header, Title = _a.Title, CloseButton = _a.CloseButton, Scrollable = _a.Scrollable, Content = _a.Content, Footer = _a.Footer, FooterButtons = _a.FooterButtons, modalProps = __rest(_a, ["className", "fullScreen", "trigger", "renderTrigger", "title", "size", "noCloseButton", "closeButtonPosition", "topControls", "children", "Header", "Title", "CloseButton", "Scrollable", "Content", "Footer", "FooterButtons"]);
        var isOpen = this.state.isOpen;
        var _trigger = trigger
            ? trigger(this.triggerRef)
            : renderTrigger
                ? renderTrigger({
                    openModal: this.openModal,
                    closeModal: this.closeModal,
                })
                : null;
        var commonClasses = {
            "is-fullscreen": fullScreen || isMobile,
            "is-window": !fullScreen || !isMobile,
        };
        return (React.createElement(React.Fragment, null,
            _trigger,
            React.createElement(ReactModal, __assign({}, modalProps, { 
                // Keep custom classNames to not use the default styles
                overlayClassName: cx("ReactModal__Overlay", size, commonClasses), className: cx("ReactModal__Content", size, commonClasses), isOpen: isOpen, onRequestClose: this.closeModal }),
                React.createElement(ControlledContentRenderer, { shouldRerender: isOpen },
                    (title || topControls || noCloseButton !== true) && (React.createElement(Header, null,
                        title && React.createElement(Title, null, title),
                        !noCloseButton && (React.createElement(CloseButton, { onClick: this.closeModal, position: closeButtonPosition })),
                        topControls)),
                    React.createElement(Scrollable, null, children({
                        closeModal: this.closeModal,
                        openModal: this.openModal,
                        Content: Content,
                        Footer: Footer,
                        FooterButtons: FooterButtons,
                    }))))));
    };
    Modal.defaultProps = {
        fullScreen: false,
        closeButtonPosition: "left",
        closeTimeoutMS: 500,
        Header: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: 100%;\n      min-height: 60px;\n      margin: 0 auto;\n      display: flex;\n      flex-shrink: 0;\n      align-items: baseline;\n      justify-content: space-between;\n      position: relative;\n    "], ["\n      width: 100%;\n      min-height: 60px;\n      margin: 0 auto;\n      display: flex;\n      flex-shrink: 0;\n      align-items: baseline;\n      justify-content: space-between;\n      position: relative;\n    "]))),
        Title: styled(H1)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin: 25px 100px 25px 50px;\n    "], ["\n      margin: 25px 100px 25px 50px;\n    "]))),
        CloseButton: styled(function (_a) {
            var onClick = _a.onClick, props = __rest(_a, ["onClick"]);
            return (React.createElement(BlankButtonWithPosition, __assign({}, props, { onClick: onClick }),
                React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                    React.createElement(Icon, { name: "close", size: 22 }),
                    React.createElement(Text, { fontSize: 4, pt: 1, pl: 2 }, "Close"))));
        })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      position: absolute;\n      cursor: pointer;\n      padding: ", "px;\n      top: 50%;\n      transform: translateY(-50%);\n      z-index: 1;\n\n      i:before {\n        display: block;\n        margin: 0;\n      }\n\n      &:hover {\n        opacity: 0.7;\n      }\n    "], ["\n      position: absolute;\n      cursor: pointer;\n      padding: ", "px;\n      top: 50%;\n      transform: translateY(-50%);\n      z-index: 1;\n\n      i:before {\n        display: block;\n        margin: 0;\n      }\n\n      &:hover {\n        opacity: 0.7;\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.space[3];
        }),
        Scrollable: styled(function (props) { return (React.createElement("div", __assign({}, props, { className: "".concat(props.className, " ReactModal__Scrollable") }))); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n      flex-grow: 1;\n      overflow: auto;\n      padding: ", "px ", "px;\n      -webkit-overflow-scrolling: touch;\n\n      ", " {\n        padding: ", "px\n          ", "px;\n      }\n    "], ["\n      display: flex;\n      flex-direction: column;\n      flex-grow: 1;\n      overflow: auto;\n      padding: ", "px ", "px;\n      -webkit-overflow-scrolling: touch;\n\n      ", " {\n        padding: ", "px\n          ", "px;\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.space[3];
        }, function (_a) {
            var theme = _a.theme;
            return theme.space[2];
        }, function (_a) {
            var theme = _a.theme;
            return theme.media.md;
        }, function (_a) {
            var theme = _a.theme;
            return theme.space[4];
        }, function (_a) {
            var theme = _a.theme;
            return theme.space[3];
        }),
        Content: styled(function (props) { return (React.createElement("div", __assign({}, props, { className: "".concat(props.className, " ReactModal__InnerContent") }))); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: flex;\n      flex-grow: 1;\n      flex-direction: column;\n      padding-bottom: 2em;\n\n      ", " {\n        height: inherit;\n      }\n    "], ["\n      display: flex;\n      flex-grow: 1;\n      flex-direction: column;\n      padding-bottom: 2em;\n\n      ", " {\n        height: inherit;\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.media.md;
        }),
        Footer: styled(function (props) { return (React.createElement("div", __assign({}, props, { className: "".concat(props.className, " ReactModal__Footer") }))); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      padding: 0;\n    "], ["\n      padding: 0;\n    "]))),
        FooterButtons: styled(function (props) { return (React.createElement(Modal.defaultProps.Footer, __assign({}, props, { className: props.className }))); })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      display: flex;\n      justify-content: flex-end;\n      width: 100%;\n\n      padding-top: ", "px;\n\n      ", "\n\n      > * {\n        margin-left: 20px;\n      }\n\n      > *:first-of-type {\n        margin-left: 0;\n      }\n    "], ["\n      display: flex;\n      justify-content: flex-end;\n      width: 100%;\n\n      padding-top: ", "px;\n\n      ", "\n\n      > * {\n        margin-left: 20px;\n      }\n\n      > *:first-of-type {\n        margin-left: 0;\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.space[4];
        }, function (_a) {
            var flexStart = _a.flexStart;
            return flexStart && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          justify-content: flex-start;\n        "], ["\n          justify-content: flex-start;\n        "])));
        }),
    };
    return Modal;
}(Component));
export { Modal };
var ControlledContentRenderer = /** @class */ (function (_super) {
    __extends(ControlledContentRenderer, _super);
    function ControlledContentRenderer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ControlledContentRenderer.prototype.shouldComponentUpdate = function (nextProps) {
        return nextProps.shouldRerender;
    };
    ControlledContentRenderer.prototype.render = function () {
        return this.props.children;
    };
    return ControlledContentRenderer;
}(Component));
var BlankButtonWithPosition = styled(BlankButton)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var position = _a.position;
    return position === "left" && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      left: 0;\n    "], ["\n      left: 0;\n    "])));
}, function (_a) {
    var position = _a.position;
    return position === "right" && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      right: 0;\n    "], ["\n      right: 0;\n    "])));
});
export var ModalContent = Modal.defaultProps.Content;
export var ModalFooter = Modal.defaultProps.Footer;
export var ModalFooterButtons = Modal.defaultProps.FooterButtons;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
