import React from "react";
import {Admission} from "@reside/reside-api-admission";

import {AdmissionFromSearch} from "../../services/AdmissionsService";
import {
  AdmissionPreflightCell,
  AdmissionInProgressCell,
  AdmissionSignedCell,
  AdmissionApprovedCell,
} from "./atoms/cell";

export const AdmissionCellRenderer = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => {
  switch (admission.status) {
    case Admission.StatusEnum.PRE_FLIGHT:
      return <AdmissionPreflightCell admission={admission} />;
    case Admission.StatusEnum.IN_PROGRESS:
      return <AdmissionInProgressCell admission={admission} />;
    case Admission.StatusEnum.SIGNED:
      return <AdmissionSignedCell admission={admission} />;
    case Admission.StatusEnum.APPROVED:
      return <AdmissionApprovedCell admission={admission} />;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Could not render cell of status "${admission.status}"`);

      return null;
  }
};
