import React from "react";
import {useMutation} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import Alert from "react-s-alert";

import {AdminLayout} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {BackdropSpinner, Spinner} from "../../atoms/spinner";
import {useTemplateNamesQuery} from "../../services/hooks/useTemplateNamesQuery";
import {FacilityForm} from "./facility-form";
import {FacilityService} from "../../services/FacilityService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";

type OrganizationRouteParams = Readonly<{
  organizationId: string;
}>;

export const PageAdminFacilityCreate = () => {
  const history = useHistory();
  const {organizationId} = useParams<OrganizationRouteParams>();

  const onCancel = () =>
    history.push(`/admin/organizations/${organizationId}/edit`);

  const {data: templateNames = [], isFetching: isFetchingTemplateNames} =
    useTemplateNamesQuery({
      onError: async (response: any) => {
        Alert.error(
          await buildResponseErrorMessage(
            response,
            "Unable to load template names",
          ),
        );
      },
    });

  const {mutate, isLoading} = useMutation(FacilityService.create, {
    onSuccess: () => {
      Alert.success("Facility was successfully created.");
      history.push("/admin/organizations");
    },
    onError: async resp => {
      Alert.error(await buildResponseErrorMessage(resp));
    },
  });

  return (
    <AdminLayout>
      <DocumentTitle title="Reside - Admin - Create facility" />
      {isFetchingTemplateNames ? (
        <Spinner />
      ) : (
        <BackdropSpinner active={isLoading}>
          <FacilityForm
            onSubmit={values => mutate(values)}
            onCancel={onCancel}
            templateOptions={templateNames}
            organizationId={organizationId}
          />{" "}
        </BackdropSpinner>
      )}
    </AdminLayout>
  );
};
