import React, {Component, createRef, ReactNode} from "react";
import {NavHashLink} from "react-router-hash-link";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {css, cx} from "@emotion/css";
import html2canvas from "html2canvas";
import qs from "qs";
import axios from "axios";
import {capitalize, get} from "lodash";
import {styled, Icon} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";

import {UseIsInViewport} from "../../hooks/useIsInViewport";
import {
  AnyDataPath,
  Facility,
  IFromTo,
  MAIN_URLS,
} from "../../store/reporting/reporting.common";
import {BLOCK_PERMISSIONS} from "../../pages/page-reporting/permissions.helpers";
import {createActions} from "../../store/reporting-reports/reporting-reports.actions";
import {
  isReportsDataPath,
  PER_PAGE,
  ReportsDataPath,
} from "../../store/reporting-reports/reporting-reports.common";
import {dispatch} from "../../store";
import {RequiredUserPermissionsSome} from "../admin-user-permissions/RequiredUserPermissionsSome";
import {TemporaryHideChart} from "../../pages/page-reporting/TemporaryHideChart";
import {ReportContextConsumer} from "../../pages/page-reporting/ReportContext";
import {ChartTypes} from "../../pages/page-reporting/constants";
import {FileExtensionItem} from "./file-export-dropdown-select/FileExportDropdownSelect";
import {Header} from "./Header";
import {
  downloadCSV,
  downloadPDF,
  downloadXLSX,
  FileExtension,
} from "./downloadExport";

import "./ReportingBlock.scss";

export type ReportingBlockSetters = Readonly<{
  setExportDataPath: (exportDataPath: AnyDataPath) => void;
  exportCanvasToPDF: (canvas: HTMLCanvasElement) => void;
  half?: boolean;
  isFullReport?: boolean;
  enabled: boolean;
}>;

export type UrlProps = Readonly<{
  type: ChartTypes;
  chartId?: string;
  facilityId?: string;
  chartGroup?: string;
  from1?: string;
  to1?: string;
}>;

type OwnProps = Readonly<{
  chartId: string;
  chartType?: ChartTypes;
  children: (reportingBlockSetters: ReportingBlockSetters) => ReactNode;
  dateRange: IFromTo;
  facilities: Facility[];
  half?: boolean;
  setPageLoading?: (state: boolean) => void;
  overview?: boolean;
  hideSeeFullReportLink?: boolean;
  permissionInChart?: ReadonlyArray<TableUserDto.PermissionsEnum>;
  isDynamicCheckbox?: boolean;
}>;

type Props = OwnProps & RouteComponentProps<UrlProps>;

type State = Readonly<{
  disableHalf: boolean;
  exportDataPath?: AnyDataPath;
}>;

export class ReportingBlockRenderer extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    chartType: ChartTypes.reports,
    hideSeeFullReportLink: false,
    permissionInChart: [],
    isDynamicCheckbox: false,
  };

  state: State = {
    exportDataPath: undefined,
    disableHalf: false,
  };

  bodyRef = createRef<HTMLDivElement>();

  getExportDataPath = () => {
    const {exportDataPath} = this.state;
    const {facilities} = this.props;

    return {
      ...exportDataPath,
      query: {
        ...exportDataPath?.query,
        facilities,
      },
    } as AnyDataPath;
  };

  getCanvasFromReport = () => {
    this.setState({disableHalf: true});
    const exportElement = this.bodyRef.current as HTMLElement;
    const parentContainer = exportElement.parentElement as HTMLElement;
    const button = parentContainer.getElementsByTagName("button")[0];

    if (button) {
      button.style.display = "none";
    }

    parentContainer.style.overflow = "hidden";
    parentContainer.style.width = `${exportElement.clientWidth}px`;
    exportElement.style.width = "1280px";

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // setTimtout is used to wait for rerendering graphs to full width
        html2canvas(exportElement, {
          windowWidth: 1920,
          windowHeight: 1080,
          scale: 2,
          logging: false,
          onclone: document => {
            this.setState({disableHalf: false});
            exportElement.style.width = "";
            parentContainer.style.width = "";
            parentContainer.style.overflow = "";
            // this extra style expand scrollable content
            const head = document.head;
            const style = document.createElement("style");

            style.type = "text/css";
            style.appendChild(
              document.createTextNode(
                ".chart-header { align-items: stretch !important; }" +
                  ".graph-bar-wrapper { max-height: none !important; } " +
                  ".list-table-reporting-wrapper { max-height: none !important; } " +
                  ".list-table-reporting tbody { height: auto !important; }" +
                  ".list-table-reporting-full+a { display: none; }" +
                  ".reporting-block__body { padding: 0 !important; }" +
                  ".fakeCheck { font-weight: bold; font-size: 11px; }" +
                  ".ellipsis span { display: inline-block !important; overflow: visible !important; white-space: normal !important; }" +
                  ".graph-bar tbody { height: auto !important; }" +
                  ".additional-data { max-height: none !important; }",
              ),
            );
            head.appendChild(style);

            const allChecks = document.querySelectorAll(
              ".list-table-reporting td .icon-check",
            );

            allChecks.forEach(item => {
              const fakeCheck = document.createElement("span");

              fakeCheck.innerHTML = "&#10004;";
              fakeCheck.className = "fakeCheck";
              item.parentNode.replaceChild(fakeCheck, item);
            });
          },
        })
          .then((canvas: any) => {
            resolve(canvas);
            if (button) {
              button.style.display = "flex";
            }
          })
          .catch((error: any) => {
            reject(error);
            if (button) {
              button.style.display = "flex";
            }
          });
      }, 0);
    });
  };

  onPDFExport = () => {
    const exportDataPath = this.getExportDataPath();

    const actions = createActions(exportDataPath as ReportsDataPath);

    this.props.setPageLoading?.(true);

    if (isReportsDataPath(exportDataPath)) {
      /**
       * If we have full report with load more in "Reports page" we need to load all data to DOM.
       */
      dispatch(actions.loadAllForPDF()).then((oldPage: number) => {
        this.getCanvasFromReport().then(canvas => {
          const cancelTokenSource = axios.CancelToken.source();

          dispatch(
            actions.fetchReports(
              cancelTokenSource,
              0,
              (oldPage + 1) * PER_PAGE,
              true,
            ),
          );
          downloadPDF(
            canvas as HTMLCanvasElement,
            this.getExportDataPath(),
          ).then(() => {
            this.props.setPageLoading?.(false);
          });
        });
      });
    } else {
      this.getCanvasFromReport().then(canvas => {
        downloadPDF(canvas as HTMLCanvasElement, this.getExportDataPath()).then(
          () => {
            this.props.setPageLoading?.(false);
          },
        );
      });
    }
  };

  exportCanvasToPDF = (canvas: HTMLCanvasElement) => {
    downloadPDF(canvas, this.getExportDataPath()).then(() => {
      this.props.setPageLoading?.(false);
    });
  };

  onExport = ({id: extension}: FileExtensionItem) => {
    if (extension === FileExtension.PDF) {
      this.onPDFExport();
    } else if (extension === FileExtension.CSV) {
      downloadCSV(this.getExportDataPath());
    } else if (extension === FileExtension.XLSX) {
      downloadXLSX(this.getExportDataPath());
    }
  };

  setExportDataPath = (exportDataPath: AnyDataPath) =>
    this.setState({exportDataPath});

  isFullReport = () =>
    Boolean(
      this.props.match.params.chartId &&
        this.props.match.params.chartId === this.props.chartId,
    );

  linkToFullReport = () => {
    const {dateRange, chartId, match, chartType} = this.props;

    const baseUrl = match.url
      .replace(/\/+$/, "")
      .replace("/admin/reporting/", `/admin/reporting/${chartType}/`);

    if (dateRange?.from && dateRange.to) {
      const urlParts = [baseUrl, chartId, dateRange.from, dateRange.to];

      return urlParts.join("/");
    }
  };

  render() {
    const {
      facilities,
      match,
      children,
      chartId,
      hideSeeFullReportLink,
      permissionInChart,
      isDynamicCheckbox,
    } = this.props;

    if (match.params.chartId && match.params.chartId !== chartId) {
      return null;
    }

    const isFullReport = this.isFullReport();

    return (
      <RequiredUserPermissionsSome
        permissions={
          isDynamicCheckbox
            ? BLOCK_PERMISSIONS["CHECKLIST_ITEM_REPORT_ID"]
            : BLOCK_PERMISSIONS[chartId] || permissionInChart
        }
      >
        <UseIsInViewport>
          {({ref, wasInViewport}) => (
            <>
              {isFullReport && (
                <NavHashLink
                  to={linkBack(this.props)}
                  className={css`
                    display: block;
                    text-decoration: none;
                    margin-bottom: 16px;
                  `}
                >
                  <Icon name="left" size={10} />
                  Back to {capitalize(match.params.type)}
                </NavHashLink>
              )}
              <Column
                className={cx({
                  half:
                    this.props.half && !this.state.disableHalf && !isFullReport,
                })}
              >
                <ReportWrapper>
                  <Header
                    dataPath={this.getExportDataPath()}
                    onExport={this.onExport}
                  />
                  <ReportBody>
                    <div ref={ref}>
                      <div ref={this.bodyRef}>
                        {(children as any)({
                          setExportDataPath: this.setExportDataPath,
                          exportCanvasToPDF: this.exportCanvasToPDF,
                          isFullReport: isFullReport,
                          half: this.props.half,
                          enabled: wasInViewport,
                        })}
                      </div>
                    </div>
                  </ReportBody>
                  {!isFullReport && !hideSeeFullReportLink && (
                    <ReportFooter>
                      <Link
                        to={{
                          pathname: this.linkToFullReport(),
                          search: qs.stringify(
                            {facilities},
                            {arrayFormat: "brackets"},
                          ),
                          state: {
                            fromOverview: this.props.overview,
                            previousUrl: match.url,
                          },
                        }}
                      >
                        See full report <Icon name="right" size={8} />
                      </Link>
                    </ReportFooter>
                  )}
                </ReportWrapper>
              </Column>
            </>
          )}
        </UseIsInViewport>
      </RequiredUserPermissionsSome>
    );
  }
}

export const ReportingBlock = withRouter<Props, typeof ReportingBlockRenderer>(
  ReportingBlockRenderer,
);

// TODO: remove once the ReportingBlock consumes the ReportContext
export const ConnectedReportingBlock = (
  props: Omit<OwnProps, "dateRange" | "facilities" | "setPageLoading">,
) => (
  <TemporaryHideChart chartIds={[props.chartId]}>
    <ReportContextConsumer>
      {({baseDateRange, activeFacilitiesIds, setPageLoading}) => (
        <ReportingBlock
          dateRange={baseDateRange}
          facilities={activeFacilitiesIds}
          setPageLoading={setPageLoading}
          {...props}
        />
      )}
    </ReportContextConsumer>
  </TemporaryHideChart>
);

const linkBack = ({match, location}: Props) => {
  const urlParts = [MAIN_URLS.OVERVIEW];
  const fromOverview = get(location, "state.fromOverview", false);

  if (!fromOverview) {
    urlParts.push(match.params.chartGroup || "");
  }

  return `${urlParts.join("/")}#${match.params.chartId}`;
};

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  &.half {
    flex: 1 1 100%;

    .chart-wrapper {
      max-width: 60%;
    }

    @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
      flex: 0 0 50%;

      &:first-of-type {
        padding-right: 8px;
      }

      &:last-of-type {
        padding-left: 8px;
      }
    }
  }
`;

export const ReportWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border: 1px solid ${({theme}) => theme.color.gray10};
  border-radius: 0 0 4px 4px;
  background-color: ${({theme}) => theme.color.white};
`;

export const ReportHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  background-color: ${({theme}) => theme.color.lightBlue10};
  min-height: 44px;

  button {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
    margin-left: 20px;
    font-weight: bold;
    color: ${({theme}) => theme.color.darkBlue100};
    font-size: 11px;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      color: ${({theme}) => theme.color.primary};
    }
  }
`;

export const ReportBody = styled.div`
  padding: 30px 30px 50px 30px;
  box-sizing: border-box;
  flex: 1;

  .no-data {
    text-align: center;
    padding: 120px 10px;
  }

  .spinner {
    margin-top: 32px;
  }
`;

export const ReportFooter = styled.div`
  margin: 0 0 20px 30px;

  a {
    text-decoration: none;
  }
`;
