"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
var whitelistDomainValidator_1 = require("./whitelistDomainValidator");
exports.Validator = {
    name: "reside-domain",
    errorMessage: "Domain \"".concat(whitelistDomainValidator_1.RESIDE_DOMAIN, "\" is already included."),
    validate: function (value) {
        var enteredDomainNames = value.split(",").map(function (v) { return v.trim(); });
        return !enteredDomainNames.includes(whitelistDomainValidator_1.RESIDE_DOMAIN);
    },
};
