import React from "react";
import {DataNode} from "rc-tree/lib/interface";
import {RoleDto} from "@reside/reside-api-app";

import {FieldArrayItem} from "../../../services/FacilitySettingsService";
import {PccIntegrationStatus} from "../../../services/PccService";
import {AdminRoles} from "../../../atoms/admin-roles";
import {getNormalizedHashId} from "../../../utils/helpers/getNormalizedHashId";
import {toApi, withAccessToken} from "../../../utils/url";
import {PccTag} from "../atoms/facility-settings-header/styles";

export const createFacilitiesTreeWithPccIcons = (
  facilitiesTree: ReadonlyArray<DataNode>,
) =>
  facilitiesTree.map(org => ({
    ...org,
    children: org.children?.map((facility: any) => ({
      ...facility,
      icon: (
        <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
          <PccTag
            isVisible={
              facility.pccIntegrationStatus ===
                PccIntegrationStatus.PccTurnedOn ||
              facility.pccIntegrationStatus ===
                PccIntegrationStatus.PccTurnedOff
            }
            color={
              facility.pccIntegrationStatus === PccIntegrationStatus.PccTurnedOn
                ? "green100"
                : "deepOrange300"
            }
            outline={
              facility.pccIntegrationStatus ===
              PccIntegrationStatus.PccTurnedOff
            }
          >
            {facility.pccIntegrationStatus === PccIntegrationStatus.PccTurnedOn
              ? "PCC ON"
              : "PCC OFF"}
          </PccTag>
        </AdminRoles>
      ),
    })),
  }));

export const getChecklistWithNormalizedIds = (
  checklist: ReadonlyArray<FieldArrayItem>,
) => {
  if (!Array.isArray(checklist)) {
    return undefined;
  }

  return checklist.reduce((acc, {checklistItemDescription, ...other}) => {
    const id = getNormalizedHashId(checklistItemDescription as string);

    if (acc.some((existing: {id: string}) => existing.id === id)) {
      return acc;
    }

    return [...acc, {...other, id, checklistItemDescription}];
  }, []);
};

export const templateUrl = (facilityId: string, templateName: string) =>
  withAccessToken(
    toApi(`v3/documents/template/${facilityId}/${templateName}/pdf/latest`),
  );
