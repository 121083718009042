import React, {Component, ReactNode} from "react";
import ResizeObserver from "resize-observer-polyfill";

type State = Readonly<{
  width: number;
}>;

type Props = Readonly<{
  children: (state: State) => ReactNode;
}>;

export class ContainerWidthSizer extends Component<Props, State> {
  state: State = {
    width: 0,
  };

  container!: HTMLDivElement | null;
  resizeObserver: ResizeObserver;

  componentDidMount() {
    if (this.container) {
      this.setState({
        width: this.container.getBoundingClientRect().width,
      });

      if (ResizeObserver) {
        // ResizeObserver loop limit exceeded (benign error)
        // Sentry issue: https://reside-admissions.sentry.io/issues/1333099804/?project=1368266
        // Solution: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        this.resizeObserver = new ResizeObserver(
          (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame(() => {
              if (!Array.isArray(entries) || !entries.length) {
                return;
              }
              let minWidth = Number.MAX_VALUE;

              for (const entry of entries) {
                const cr = entry.contentRect;

                minWidth = Math.min(cr.width, minWidth);
              }

              if (minWidth !== Number.MAX_VALUE) {
                this.setState({
                  width: minWidth,
                });
              }
            });
          },
        );
        this.resizeObserver.observe(this.container);
      }
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    return (
      <div ref={c => (this.container = c)}>
        {this.props.children(this.state)}
      </div>
    );
  }
}
