import apisauce from "apisauce";

const client = apisauce.create({baseURL: "/"});

const cacheBuster = (url: string) => `${url}?cb=${Date.now()}`;

export const AppService = {
  async getAvailableAppVersion() {
    const response = await client.get<string>(cacheBuster("/"));

    return {
      ...response,
      data: {
        appVersion: extractAppVersion(response.data),
      },
    };
  },
};

export const extractAppVersion = (html: string): string => {
  if (typeof html === "string") {
    const element = document.createElement("div");
    element.innerHTML = html;

    return getAppVersion(element);
  }
};

export const getAppVersion = (element: HTMLDocument | HTMLElement) =>
  element.querySelector('meta[name="app-version"]')?.getAttribute("content");
