import React from "react";
import {format, parseISO} from "date-fns";
import {styled, theme, FlexRow} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";

import {IFromTo} from "../../../store/reporting/reporting.common";

export type Props = Readonly<{
  dateRange?: IFromTo;
  global?: boolean;
  tickColor?: string;
  border?: boolean;
  onClose?: () => void;
}>;

export const CompareDateLabel = ({
  dateRange,
  global,
  border,
  tickColor,
  onClose,
}: Props) => {
  if (!dateRange) {
    return null;
  }
  let circleStyle: any = {};

  if (border) {
    circleStyle = {
      background: `linear-gradient(90deg, ${theme.color.darkBlue100}, ${
        theme.color.darkBlue100
      } 50%, ${tickColor ? tickColor : theme.color.gray100} 50%)`,
    };
  } else {
    if (tickColor) {
      circleStyle.background = tickColor;
    }
  }

  if (!global && !tickColor) {
    circleStyle.filter = "grayscale(0.3) opacity(0.5)";
  }

  return (
    <Range>
      <i className="circle" style={circleStyle} />
      <time>{format(parseISO(dateRange.from), DATE_FORMAT.SHORT_DAY)}</time>
      <span>-</span>
      <time>{format(parseISO(dateRange.to), DATE_FORMAT.SHORT_DAY)}</time>
      {!global && (
        <button onClick={onClose}>
          <i className="icon icon-close" />
        </button>
      )}
    </Range>
  );
};

const Range = styled(FlexRow)`
  padding: 4px 0 4px 6px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.color.gray100};
  line-height: 0.8em;
  align-items: center;

  .icon {
    color: ${({theme}) => theme.color.darkBlue100};
    font-size: 0.65em;
  }

  time {
    position: relative;
    top: 1px;
    font-size: 0.75em;
    padding: 0 6px;
    font-weight: 500;
    white-space: nowrap;
  }

  .circle {
    display: inline-block;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.color.darkBlue100};
  }
`;
