import {FlexRowCenter, ScrollableErrorArea, styled} from "@reside/ui";
import {Form} from "formik";

export const FlexRowStyled = styled(FlexRowCenter)`
  margin-top: 1rem;
`;

export const StyledScrollableErrorArea = styled(ScrollableErrorArea)`
  max-height: calc(100vh - 190px);
  overflow: auto;
`;

export const StyledForm = styled(Form)`
  h2 {
    font-size: 17px;
  }
`;
