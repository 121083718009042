import React from "react";
import {connect} from "react-redux";
import {Admission} from "@reside/reside-api-admission";
import {Box, Button, styled} from "@reside/ui";

import {AppState, select} from "../../store";
import {Spinner} from "../spinner";
import {ColumnBody, ColumnHeader} from "./Column";
import {AdmissionCellList} from "./AdmissionCellList";
import {useAdmissionsByStatusQuery} from "./hooks";
import {readableAdmissionStatus} from "../admission-status-tag";

const plural = (
  totalElements: number,
  zero: string,
  one: string,
  other: string,
) => {
  switch (totalElements) {
    case 0:
      return zero;
    case 1:
      return `${totalElements} ${one}`;
    default:
      return `${totalElements} ${other}`;
  }
};

export const DashboardGridColumnHeaderRenderer = ({
  status,
  facilityIds,
}: {
  status: Admission.StatusEnum;
  facilityIds: ReadonlyArray<string>;
}) => {
  const {data} = useAdmissionsByStatusQuery({facilityIds, status});
  const [{totalElements = 0} = {}] = data?.pages ?? [];

  return (
    <ColumnBody>
      <ColumnHeader
        title={readableAdmissionStatus(status)}
        subtitle={plural(
          facilityIds?.length ? totalElements : 0,
          "NO ADMISSIONS",
          "ADMISSION",
          "ADMISSIONS",
        )}
      />
    </ColumnBody>
  );
};

const Renderer = ({
  status,
  facilityIds,
  loadMoreButtonId,
}: {
  status: Admission.StatusEnum;
  loadMoreButtonId: string;
  facilityIds: ReadonlyArray<string>;
}) => {
  const {data, hasNextPage, fetchNextPage, isFetching} =
    useAdmissionsByStatusQuery({facilityIds, status});

  const readableStatus = readableAdmissionStatus(status);

  return (
    <ColumnBody
      key={status}
      data-test-id={`${readableStatus.toLowerCase()}-column`}
    >
      {data && (
        <AdmissionCellList
          admissions={
            facilityIds?.length ? data.pages.flatMap(item => item.content) : []
          }
        />
      )}

      <Box flexDirection="row" justifyContent="space-around">
        {hasNextPage && facilityIds.length > 0 && !isFetching && (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              fetchNextPage();
            }}
            testId={loadMoreButtonId}
          >
            Load More
          </Button>
        )}
        {isFetching && (
          <Offset>
            <Spinner relative />
          </Offset>
        )}
      </Box>
    </ColumnBody>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilityIds: select.adminSession.activeFacilitiesIds(state),
});
export const DashboardGridColumn = connect(mapStateToProps)(Renderer);

export const DashboardGridColumnHeader = connect(mapStateToProps)(
  DashboardGridColumnHeaderRenderer,
);

const Offset = styled.div`
  margin-top: ${({theme}) => theme.space[6]}px;
`;
