import {zonedTimeToUtc} from "date-fns-tz";
import {format} from "date-fns";
import {LOCAL_TIME_ZONE, DATE_FORMAT} from "@reside/ui/dist/date-picker/date";

import {DataItem} from "./LineChart";

export const getXFormatClean = (
  xFormat: (datum: any, dataSet?: any) => string,
  maxLen: number,
) => (xFormat ? xFormat : formatDateHof(maxLen));

const formatDateHof = (dataLength: number) => (date: Date | string | number) =>
  format(
    zonedTimeToUtc(date, LOCAL_TIME_ZONE),
    dataLength > 300
      ? DATE_FORMAT.DAY_SHORT_MONTH_YEAR
      : DATE_FORMAT.DAY_SHORT_MONTH,
  );

export const getMinMax = (data: Readonly<DataItem>[][]) =>
  data.reduce(
    (accumulator: number[], dataset: any) => {
      const nestedResult = dataset.reduce(
        (accumulator_nested: number[], item: any) => {
          return [
            Math.min(item.value, accumulator_nested[0]),
            Math.max(item.value, accumulator_nested[1]),
          ];
        },
        [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
      );

      return [
        Math.min(nestedResult[0], accumulator[0]),
        Math.max(nestedResult[1], accumulator[1]),
      ];
    },
    [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
  );

export const formatPercent = (y: any) => `${y} %`;
