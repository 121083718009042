import {
  CommonDataPath,
  DateStr,
  Facility,
  IFromTo,
  AnyDataPath,
  StateWithFromTo,
} from "../reporting/reporting.common";
import {PayloadAction} from "typesafe-actions";
import {AdmissionStatusEnum} from "../../services/AdmissionsService";
import {CancelTokenSource} from "axios";
import {ChecklistItem} from "../../services/ReportingService";

/*
  Actions
*/

export const SET_REPORTS = "@REPORTING/REPORTS/SET_REPORTS";
export const PUSH_REPORTS = "@REPORTING/REPORTS/PUSH_REPORTS";
export const FETCH_BEGIN = "@REPORTING/REPORTS/FETCH_BEGIN";
export const FETCH_SUCCESS = "@REPORTING/REPORTS/FETCH_SUCCESS";
export const FETCH_FAILURE = "@REPORTING/REPORTS/FETCH_FAILURE";

export type IReportingReportsAction =
  | PayloadAction<typeof SET_REPORTS, IReportingReportsPayload>
  | PayloadAction<typeof PUSH_REPORTS, IReportingReportsPayload>
  | PayloadAction<typeof FETCH_BEGIN, IReportingReportsPayloadBase>
  | PayloadAction<typeof FETCH_SUCCESS, IReportingReportsPayloadBase>
  | PayloadAction<typeof FETCH_FAILURE, IReportingReportsPayload>;

export interface IReportingReportsPayloadBase {
  path: ReportsDataPath;
}

export interface IReportingReportsPayload extends IReportingReportsPayloadBase {
  data: ReportingReportsResponse;
  page: number;
  perPage?: number;
}

/*
  With Reports Type
*/

export enum ReportsTypeAdmission {
  advancedDirectiveHelp = "/reports/advanced-directive-help",
  arbitrationAgreement = "/reports/arbitration-agreement",
  contractSign = "/reports/contract-sign",
  existingAdvancedDirectiveProvided = "/reports/existing-advanced-directive-provided",
  funeral = "/reports/funeral",
  influenza = "/reports/influenza",
  laundry = "/reports/laundry",
  medicaidRequest = "/reports/medicaid-request",
  medicareWaiver = "/reports/medicare-waiver",
  personalResourceIncomeAgreement = "/reports/personal-resource-income-agreement",
  pneumococcal = "/reports/pneumococcal",
  surrogateDecisionMaking = "/reports/surrogate-decision-making",
  surrogateDecisionMakingHelp = "/reports/surrogate-decision-making-help",
  totalAdmissions = "/reports/total-admissions",
  trustFund = "/reports/trust-fund",
  photos = "/reports/photos",
  SNFABNEstimatedCostAdmission = "/reports/snfabn-estimated-cost-admission",
  checklistItems = "/reports/checklist-items",
}

export enum ReportsTypeFacility {
  facilityChanges = "/reports/facility-changes",
  SNFABNEstimatedCostFacility = "/reports/snfabn-estimated-cost-facility",
}

export enum ReportsTypeSlide {
  slideInteractions = "/reports/slide-interactions",
}

export enum ReportsTypeStaff {
  staff = "/reports/staff",
}

export const ReportsType = {
  ...ReportsTypeAdmission,
  ...ReportsTypeFacility,
  ...ReportsTypeSlide,
  ...ReportsTypeStaff,
};

// export type ReportsType =
//   | ReportsTypeAdmission
//   | ReportsTypeFacility
//   | ReportsTypeSlide
//   | ReportsTypeStaff;

export type ReportsKey =
  | keyof typeof ReportsType
  // String since the Type can be /reports/checklist-items/UUID"
  | string;

export const ReportsTypeNames: {[rt: string]: string} = {
  [ReportsType.SNFABNEstimatedCostAdmission]: "SNFABN Estimated Cost Admission",
  [ReportsType.SNFABNEstimatedCostFacility]: "SNFABN Estimated Cost Facility",
  [ReportsType.advancedDirectiveHelp]: "Advanced Directive Help",
  [ReportsType.arbitrationAgreement]: "Arbitration Agreement",
  [ReportsType.contractSign]: "Contract Sign",
  [ReportsType.existingAdvancedDirectiveProvided]:
    "Existing Advanced DirectiveProvided",
  [ReportsType.facilityChanges]: "Facility Changes",
  [ReportsType.funeral]: "Funeral",
  [ReportsType.influenza]: "Influenza",
  [ReportsType.laundry]: "Laundry",
  [ReportsType.medicaidRequest]: "Medicaid Request",
  [ReportsType.medicareWaiver]: "Medicare Waiver",
  [ReportsType.personalResourceIncomeAgreement]:
    "Personal Resource Income Agreement",
  [ReportsType.photos]: "Photos",
  [ReportsType.pneumococcal]: "Pneumococcal",
  [ReportsType.slideInteractions]: "Slide Interactions",
  [ReportsType.staff]: "Staff",
  [ReportsType.surrogateDecisionMaking]: "Surrogate Decision Making",
  [ReportsType.surrogateDecisionMakingHelp]: "Surrogate Decision Making Help",
  [ReportsType.totalAdmissions]: "Total Admissions",
  [ReportsType.trustFund]: "Trust Fund",
  [ReportsType.checklistItems]: "Admission Checklist Items",
};

export const ReportsColumnOrder: {[at: string]: string[]} = {
  [ReportsType.SNFABNEstimatedCostAdmission]: [
    "residentName",
    "estimatedCost",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
    "estimationDate",
    "estimatedBy",
  ],
  [ReportsType.SNFABNEstimatedCostFacility]: ["name", "estimatedCost"],
  [ReportsType.advancedDirectiveHelp]: [],
  [ReportsType.arbitrationAgreement]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "updatedDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.contractSign]: [],
  [ReportsType.existingAdvancedDirectiveProvided]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.facilityChanges]: [
    "name",
    "variableName",
    "value",
    "changeAction",
    "changeOriginator",
    "changeDate",
  ],
  [ReportsType.funeral]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.influenza]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.laundry]: [
    "residentName",
    "facilityName",
    "roomNumber",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.medicaidRequest]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.medicareWaiver]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.personalResourceIncomeAgreement]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.photos]: [
    "residentName",
    "facilityName",
    "hasInsuranceId",
    "hasPhotoId",
  ],
  [ReportsType.pneumococcal]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.slideInteractions]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
  [ReportsType.staff]: ["staffName", "approvedAdmissions", "totalAdmissions"],
  [ReportsType.surrogateDecisionMaking]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
  ],
  [ReportsType.surrogateDecisionMakingHelp]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
  ],
  [ReportsType.totalAdmissions]: [
    "residentName",
    "facilityName",
    "admissionStatus",
    "admissionDate",
    "updatedDate",
  ],
  [ReportsType.trustFund]: [
    "residentName",
    "facilityName",
    "admissionDate",
    "answerIsPositive",
    "answerIsNegative",
  ],
};

export type StateWithReportsType<T> = {[reportType in ReportsKey]?: T};

export type ReportingReportsState<T> = StateWithReportsType<StateWithFromTo<T>>;

/*
  Data Path
*/

export interface GIDataPath<T, Q> extends CommonDataPath {
  type: T;
  query: Q;
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}

// TODO: remove this useless enum
export enum SortField {
  facilityName = "facilityName",
  slideName = "slideName",
  residentName = "residentName",
  admissionDate = "admissionDate",
  answerDate = "answerDate",
  createdDate = "createdDate",
  answerIsPositive = "answerIsPositive",
  answerIsNegative = "answerIsNegative",
  admissionStatus = "admissionStatus",
  updatedDate = "updatedDate",
  roomNumber = "roomNumber",
  name = "name",
  variableName = "variableName",
  variableValue = "variableValue",
  changeAction = "changeAction",
  changeOriginator = "changeOriginator",
  changeDate = "changeDate",
  estimatedCost = "estimatedCost",
  estimatedBy = "estimatedBy",
  hasInsuranceId = "hasInsuranceId",
  hasNoInsuranceId = "hasNoInsuranceId",
  hasPhotoId = "hasPhotoId",
  hasNoPhotoId = "hasNoPhotoId",
  staffName = "staffName",
  slideId = "slideId",
  clicks = "clicks",
  timeMilis = "timeMilis",
  avgTimeMilis = "avgTimeMilis",
  approvedAdmissions = "approvedAdmissions",
  totalAdmissions = "totalAdmissions",
  sentToResidentDate = "sentToResidentDate",
  estimator = "estimator",
}

export interface Sortable {
  sortField: SortField;
  sortDirection: SortDirection;
}

export interface ReportsQuery extends IFromTo, Sortable {
  facilities?: string[];
}

export type ReportsDataPath = GIDataPath<ReportsKey, ReportsQuery> & {
  key?: string;
  checklistItem?: ChecklistItem;
};

const REPORTS_PATHS = new Map(
  Object.entries(ReportsType).map(entry => entry.reverse()) as any,
);

export function isReportsDataPath(
  path: AnyDataPath | undefined,
): path is ReportsDataPath {
  if (path === undefined) {
    return false; // maybe antipattern?
  }

  return path && REPORTS_PATHS.has(path.type);
}

export const isReportingTypeCheckListItems = (
  path: AnyDataPath | undefined,
): path is ReportsDataPath =>
  isReportsDataPath(path) && path.type === ReportsTypeAdmission.checklistItems;

export const getCheckListItemKey = (path: AnyDataPath | undefined) =>
  isReportingTypeCheckListItems(path)
    ? `${path.type}/${path.checklistItem.id}`
    : path.type;

enum ReportTypeEnum {
  TOTAL_ADMISSIONS = "TOTAL_ADMISSIONS",
  ARBITRATION_AGREEMENT = "ARBITRATION_AGREEMENT",
  TRUST_FUND_POLICY = "TRUST_FUND_POLICY",
  LAUNDRY = "LAUNDRY",
  SURROGATE_DECISION_MAKING = "SURROGATE_DECISION_MAKING",
  SURROGATE_REQUEST_FOR_MORE_HELP = "SURROGATE_REQUEST_FOR_MORE_HELP",
  FUNERAL = "FUNERAL",
  PNEUMOCOCCAL = "PNEUMOCOCCAL",
  INFLUENZA = "INFLUENZA",
  REQUESTING_MEDICAID_APPLICATION = "REQUESTING_MEDICAID_APPLICATION",
  EXISTING_ADVANCED_DIRECTIVE_PROVIDED = "EXISTING_ADVANCED_DIRECTIVE_PROVIDED",
  MEDICARE_WAIVE = "MEDICARE_WAIVE",
  ADVANCED_DIRECTIVE_HELP = "ADVANCED_DIRECTIVE_HELP",
  PERSONAL_RESOURCE_AND_INCOME_AGREEMENT = "PERSONAL_RESOURCE_AND_INCOME_AGREEMENT",
  CONTRACT_SIGN = "CONTRACT_SIGN",
}

enum ReportTypeEnumFacility {
  FACILITY_CHANGES = "FACILITY_CHANGES",
  SNFABN_ESTIMATED_COST_PER_ADDMISION = "SNFABN_ESTIMATED_COST_PER_ADDMISION,",
  SNFABN_ESTIMATED_COST_PER_FACILITY = "SNFABN_ESTIMATED_COST_PER_FACILITY",
}

enum VariableName {
  PRIVACY_OFFICER_FIRST_NAME = "PRIVACY_OFFICER_FIRST_NAME",
  PRIVACY_OFFICER_LAST_NAME = "PRIVACY_OFFICER_LAST_NAME",
  PRIVACY_OFFICER_PHONE = "PRIVACY_OFFICER_PHONE",
  SMOKING_POLICY = "SMOKING_POLICY",
  SMOKING_POLICY_ENABLED = "SMOKING_POLICY_ENABLED",
  SMOKING_POLICY_DISABLED = "SMOKING_POLICY_DISABLED",
  SMOKING_AREAS_MAP = "SMOKING_AREAS_MAP",
  LAUNDRY_SERVICES = "LAUNDRY_SERVICES",
  LAUNDRY_SERVICES_ENABLED = "LAUNDRY_SERVICES_ENABLED",
  LAUNDRY_SERVICES_DESCRIPTION = "LAUNDRY_SERVICES_DESCRIPTION",
  DEPOSIT = "DEPOSIT",
  ESTIMATED_COST_FOR_SNFABN = "ESTIMATED_COST_FOR_SNFABN",
  FACILITY_MISSION = "FACILITY_MISSION",
  FACILITY_MARKETING_PICTURE_1 = "FACILITY_MARKETING_PICTURE_1",
  FACILITY_MARKETING_PICTURE_2 = "FACILITY_MARKETING_PICTURE_2",
  FACILITY_MARKETING_PICTURE_3 = "FACILITY_MARKETING_PICTURE_3",
  BED_RATE_HOLD_PERCENTAGE = "BED_RATE_HOLD_PERCENTAGE",
  LOCAL_SOCIAL_SECURITY_OFFICER_PHONE_NUMBER = "LOCAL_SOCIAL_SECURITY_OFFICER_PHONE_NUMBER",
  ILLINOIS_DEPARTMENT_OF_HUMAN_SERVICES_REGIONAL_OFFICE_PHONE_NUMBER = "ILLINOIS_DEPARTMENT_OF_HUMAN_SERVICES_REGIONAL_OFFICE_PHONE_NUMBER",
  PHYSICIAN_FIRST_NAME = "PHYSICIAN_FIRST_NAME",
  PHYSICIAN_LAST_NAME = "PHYSICIAN_LAST_NAME",
  PHYSICIAN_NPI = "PHYSICIAN_NPI",
  PHYSICIAN_PHONE = "PHYSICIAN_PHONE",
  SOCIAL_SERVICES_DIRECTOR_EMAIL = "SOCIAL_SERVICES_DIRECTOR_EMAIL",
  BUSINESS_OFFICE_MANAGER_EMAIL = "BUSINESS_OFFICE_MANAGER_EMAIL",
  FACILITY_HANDBOOK = "FACILITY_HANDBOOK",
  ROOM_RATES_EFFECTIVE_DATE = "ROOM_RATES_EFFECTIVE_DATE",
  NUMBER_OF_BEDS = "NUMBER_OF_BEDS",
  ADMINISTRATOR_NAME = "ADMINISTRATOR_NAME",
  ROOM_AND_BOARD_FEE = "ROOM_AND_BOARD_FEE",
  CARE = "CARE",
  REASON_MEDICARE_MAY_NOT_PAY = "REASON_MEDICARE_MAY_NOT_PAY",
  FACILITY_LOCATIONS = "FACILITY_LOCATIONS",
  PERSONAL_REPRESENTATIVE = "PERSONAL_REPRESENTATIVE",
  MEDICARE_DEDUCTIBLE_RATE = "MEDICARE_DEDUCTIBLE_RATE",
  MEDICARE_CO_INSURANCE_RATE = "MEDICARE_CO_INSURANCE_RATE",
  MEDICARE_DATE = "MEDICARE_DATE",
}

export interface ReportAdmission {
  admissionDate: DateStr;
  createdDate: DateStr;
  answerDate: DateStr;
  admissionStatus: AdmissionStatusEnum;
  answerIsPositive: boolean;
  answerIsNegative: boolean;
  estimatedBy: string;
  estimator: string;
  estimatedCost: number;
  estimationDate: DateStr;
  facilityId: Facility;
  facilityName: string;
  id: string;
  reportType: ReportTypeEnum;
  residentName: string;
  roomNumber: string;
  signatureDate: DateStr;
  updatedDate: DateStr;
  hasPhotoId?: boolean;
  hasInsuranceId?: boolean;
  sentToResidentDate: DateStr;
}

export interface ReportFacility {
  changeAction: "ADD" | "REMOVE";
  changeDate: DateStr;
  changeOriginator: string;
  estimatedCost: number;
  facilityId: Facility;
  id: string;
  name: string;
  reportType: ReportTypeEnumFacility;
  variableName: VariableName;
  variableValue: string;
}

export interface ReportSlide {
  clicks: number;
  slideId: string;
  slideName: string;
  timeMilis: number;
  avgTimeMilis: number;
}

export interface ReportStaff {
  approvedAdmissions: number;
  staffId: string;
  staffName: string;
  totalAdmissions: number;
}

interface BaseResponse {
  total: number;

  preFlight?: number;
  inProgress?: number;
  signed?: number;
  approved?: number;
  archived?: number;
  cancelled?: number;

  hasPhotoId?: number;
  hasNoPhotoId?: number;
  hasInsuranceId?: number;
  hasNoInsuranceId?: number;

  negativeAnswersTotal: number;
  positiveAnswersTotal: number;
}

interface AdmissionResponse extends BaseResponse {
  admissions: ReportAdmission[];
}

interface FacilityResponse extends BaseResponse {
  facilityRecords: ReportFacility[];
}

interface StaffResponse extends BaseResponse {
  staffRecords: ReportStaff[];
}

interface SlideResponse extends BaseResponse {
  slides: ReportSlide[];
}

export type ReportResponse =
  | AdmissionResponse
  | FacilityResponse
  | StaffResponse
  | SlideResponse;
export type ReportData =
  | ReportSlide[]
  | ReportStaff[]
  | ReportFacility[]
  | ReportAdmission[];
export type ReportItem =
  | ReportSlide
  | ReportStaff
  | ReportFacility
  | ReportAdmission;

export function isFacilityResponse(
  response: ReportingReportsResponse,
  path: ReportsDataPath,
): response is FacilityResponse {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.values(ReportsTypeFacility).includes(path.type);
}

export function isAdmissionResponse(
  response: ReportingReportsResponse,
  path: ReportsDataPath,
): response is AdmissionResponse {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.values(ReportsTypeAdmission).includes(path.type);
}

export function isStaffResponse(
  response: ReportingReportsResponse,
  path: ReportsDataPath,
): response is StaffResponse {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.values(ReportsTypeStaff).includes(path.type);
}

export function isSlideResponse(
  response: ReportingReportsResponse,
  path: ReportsDataPath,
): response is SlideResponse {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.values(ReportsTypeSlide).includes(path.type);
}

export type ReportingReportsResponse =
  | AdmissionResponse
  | FacilityResponse
  | StaffResponse
  | SlideResponse;

export interface IReportingReportsDataState {
  data?: ReportData;
  total?: number;
  negativeAnswersTotal?: number;
  positiveAnswersTotal?: number;
  fetching?: boolean;
  success?: string;
  totalPages?: number;
  currentPage?: number;
  sortDirection?: SortDirection;
  sortField?: SortField;
  perPage?: number;
  facilities?: string[];

  // total admissions
  preFlight?: number;
  inProgress?: number;
  signed?: number;
  approved?: number;
  archived?: number;
  cancelled?: number;

  // photo
  hasPhotoId?: number;
  hasNoPhotoId?: number;
  hasInsuranceId?: number;
  hasNoInsuranceId?: number;
}

export interface ReportsDataStatePath {
  dataState: IReportingReportsDataState;
  dataPath: ReportsDataPath;
}

export enum ReportAggregationTypes {
  total = "total",
  negativeAnswersTotal = "negativeAnswersTotal",
  positiveAnswersTotal = "positiveAnswersTotal",
  // total
  preFlight = "preFlight",
  inProgress = "inProgress",
  signed = "signed",
  approved = "approved",
  archived = "archived",
  cancelled = "cancelled",
  // photo
  hasPhotoId = "hasPhotoId",
  hasNoPhotoId = "hasNoPhotoId",
  hasInsuranceId = "hasInsuranceId",
  hasNoInsuranceId = "hasNoInsuranceId",
}

/**
 * Data resolver helper
 */

export interface ResolvedResponse {
  success: boolean;
  data?: ReportingReportsResponse;
}

export interface DataResolver {
  (
    query: ReportsDataPath,
    page: number,
    cancelTokenSource: CancelTokenSource,
    perPage?: number,
  ): Promise<ResolvedResponse>;
}

export interface StartFetchReportsActionFactory {
  (
    cancelTokenSource: CancelTokenSource,
    page?: number,
    perPage?: number,
    forceReset?: boolean,
  ): void;
}

export interface StartLoadMore {
  (): void;
}

export const PER_PAGE = 50;
