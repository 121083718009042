import React, {ReactNode} from "react";

import {styled} from "@reside/ui";
type Props = Readonly<{
  left?: ReactNode;
  right: ReactNode;
  hasShadow?: boolean;
  className?: string;
}>;

export const Footer = ({className, left, right, hasShadow}: Props) => (
  <Container hasShadow={hasShadow} className={className}>
    {left}
    <Space />
    {right}
  </Container>
);

Footer.defaultProps = {
  hasShadow: false,
};

const Space = styled.div`
  flex-grow: 1;
`;

const Container = styled.footer<{hasShadow: boolean}>`
  display: flex;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: ${({theme}) => theme.space[5]}px;
  background-color: ${({theme, hasShadow}) =>
    hasShadow ? theme.color.white : "transparent"};
  box-shadow: ${({theme, hasShadow}) =>
    hasShadow
      ? `-20px -20px 40px 0 ${theme.color.white},
      -20px -20px 20px 0 ${theme.color.white}`
      : "none"};
  @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
    padding: ${({theme}) => theme.space[6]}px ${({theme}) => theme.space[9]}px;
  }
`;
