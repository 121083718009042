import React from "react";
import {Admission} from "@reside/reside-api-admission";
import {DropdownMenuItem} from "@reside/ui";

import {
  ModalName,
  useDashboardModalContext,
} from "../../../DashboardModalContext";
import {getResidentTestId} from "../../../helper";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";

export const ReturnToResidentDropdownItem = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => {
  const {toggleModal} = useDashboardModalContext(ModalName.RETURN_TO_RESIDENT);

  return (
    <DropdownMenuItem
      onClick={() => {
        toggleModal(admission);
      }}
      testId={getResidentTestId(admission, Admission.StatusEnum.IN_PROGRESS)}
    >
      Return to Resident
    </DropdownMenuItem>
  );
};
