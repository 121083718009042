import React from "react";

import {Text, styled, Paragraph} from "@reside/ui";

import {
  AdmissionId,
  CellHeader,
  CellTextBig,
  DashboardCell,
} from "../../../../dashboard-cell";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {RelativeDate} from "../../../../relative-date";
import {readableAdmissionStatus} from "../../../../admission-status-tag";
import {getAdmissionResidentName, getResidentTestId} from "../../../helper";
import {SmallTag} from "../../days-of-admission-tag";
import {AdmissionChecklist} from "../admission-checklist";
import {PccReadmittedActions} from "../PccReadmittedActions";
import {PccAdmittedActions} from "../PccAdmittedActions";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const AdmissionPreflightCell = (props: Props) => {
  const {
    updated,
    created,
    pcc: {readmittedDate, admittedDate} = {},
  } = props.admission;

  const isNewPreflight = updated === created;

  const isPccAdmitted = admittedDate && isNewPreflight;

  const isPccReadmitted = readmittedDate && isNewPreflight;

  return isPccAdmitted ? (
    <PccAdmittedCell {...props} />
  ) : isPccReadmitted ? (
    <PccReadmittedCell {...props} />
  ) : (
    <DefaultPreflightCell {...props} />
  );
};

const DefaultPreflightCell = ({admission}: Props) => {
  const {updated, code} = admission;

  return (
    <DashboardCell>
      <CellHeader>
        <RelativeDate date={updated} />
        <PccAdmittedActions admission={admission} />
      </CellHeader>
      <Row>
        <div>
          <ResidentName admission={admission} />
          <AdmissionId code={code} />
        </div>
      </Row>
      <AdmissionChecklist admission={admission} />
    </DashboardCell>
  );
};

const PccAdmittedCell = ({admission}: Props) => {
  const {code, updated} = admission;
  return (
    <DashboardCell>
      <CellHeader>
        <RelativeDate date={updated} />
        <PccAdmittedActions admission={admission} />
      </CellHeader>
      <Row>
        <div>
          <ResidentName admission={admission} />
          <AdmissionId code={code} />
        </div>
      </Row>
      <div>
        <AdmissionChecklist admission={admission} />
        <SmallTag color="yellow100">New</SmallTag>
      </div>
    </DashboardCell>
  );
};

const PccReadmittedCell = ({admission}: Props) => {
  const {
    updated,
    pcc: {relatedAdmissionStatus},
  } = admission;
  return (
    <DashboardCell>
      <CellHeader>
        <RelativeDate date={updated} />
        <PccReadmittedActions admission={admission} />
      </CellHeader>
      <Row>
        <ResidentName admission={admission} />
      </Row>
      <Paragraph>
        This resident has been re-admitted in PointClickCare.{" "}
        {relatedAdmissionStatus
          ? `There is an
        existing admission in RESIDE with status ${readableAdmissionStatus(
          relatedAdmissionStatus,
        )}.`
          : "There seems to be no existing admission in RESIDE."}
      </Paragraph>
    </DashboardCell>
  );
};

const ResidentName = ({admission}: Props) => (
  <CellTextBig
    data-test-id={getResidentTestId(admission, "noAction")}
    style={{display: "flex"}}
  >
    <Text>{getAdmissionResidentName(admission)}</Text>
  </CellTextBig>
);

const Row = styled.div`
  display: flex;
`;
