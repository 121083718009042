var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../theme";
export var ExternalLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled &&
        "\n      color: ".concat(theme.color.gray100, ";\n      cursor: not-allowed;\n      pointer-events: none;\n    ");
});
ExternalLink.defaultProps = {
    target: "_blank",
    rel: "noopener noreferrer",
    disabled: false,
};
var templateObject_1;
