import React, {memo, ReactNode} from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {colorScalePayer} from "../../../atoms/admin-reporting/chart.helpers";
import {
  AnalyticsType,
  AnalyticsTypeWithoutResolution,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsPayerSourceAge,
  withCompare,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsPayerSourceAgeByCountryChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const barData = dataProcess_AnalyticsPayerSourceAge(data);

    return (
      <BarChart
        data={barData.stack}
        labelLeft="Source"
        max={barData.max}
        dataLegend={barData.legends}
        full={isFullReport}
        multiBarColoring
        tickFormat={x => `${x * 100}%`}
        axTickFormat={x => `${x * 100}%`}
        colorScale={colorScalePayer}
        fullWidth
      />
    );
  },
);

const AnalyticsPayerSourceAgeByCountry =
  (
    type: AnalyticsTypeWithoutResolution,
    permission: TableUserDto.PermissionsEnum,
    title: ReactNode,
  ) =>
  (props: Props) =>
    (
      <RequiredUserPermission
        permission={permission}
        onChildRendered={props.onChildRendered}
      >
        <div className="chart-header">
          <CardTitle title={title} subtitle="by Admissions" />
          <CompareDateLabelsWrapper>
            <CompareDateLabel dateRange={props.dateRange} global border />
          </CompareDateLabelsWrapper>
        </div>
        <ReportingContext
          dataPaths={withCompare({
            what: ReportingContextType.analyticsWithoutResolution,
            type,
            query: {...props.dateRange},
            facilities: props.facilities,
          })}
          /* eslint-disable-next-line react-hooks/rules-of-hooks */
          onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
          isFullReport={props.reportingBlockSetters.isFullReport}
          enabled={props.reportingBlockSetters.enabled}
        >
          {(data, isFullReport) => (
            <AnalyticsPayerSourceAgeByCountryChart
              data={data}
              isFullReport={isFullReport}
            />
          )}
        </ReportingContext>
      </RequiredUserPermission>
    );

export const PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID =
  "payer-source-65-75-by-country" as const;

export const AnalyticsPayerSourceAge65_75ByCountry =
  AnalyticsPayerSourceAgeByCountry(
    AnalyticsType.payerSourceAge65_75ByCountry,
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_65_75,
    "% of Resident 65-75 by payer source, zip code",
  );

export const PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID =
  "payer-source-75-85-by-country" as const;

export const AnalyticsPayerSourceAge75_85ByCountry =
  AnalyticsPayerSourceAgeByCountry(
    AnalyticsType.payerSourceAge75_85ByCountry,
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_75_85,
    "% of Resident 75-85 by payer source, zip code",
  );

export const PAYER_SOURCE_85_BY_COUNTRY_CHART_ID =
  "payer-source-85-by-country" as const;

export const AnalyticsPayerSourceAge85ByCountry =
  AnalyticsPayerSourceAgeByCountry(
    AnalyticsType.payerSourceAge85ByCountry,
    TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_85,
    "% of Resident 85+ by payer source, zip code",
  );
