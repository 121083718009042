import React from "react";
import {styled} from "@reside/ui";

const FONT_RATIO = 15 / 34;

export const Avatar = ({
  size,
  name,
  className,
}: {
  className?: string;
  size: number;
  name: string;
}) => (
  <Wrapper
    className={className}
    style={{
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
    }}
  >
    <Initials style={{fontSize: size * FONT_RATIO}}>
      {name
        .split(" ")
        .slice(0, 2)
        .map(str => str[0])
        .join("")}
    </Initials>
  </Wrapper>
);

const Wrapper = styled.div`
  display: inline-block;
  background-color: ${({theme}) => theme.color.gray100};
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const Initials = styled.span`
  color: white;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
