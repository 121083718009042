import React from "react";
import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {AdmissionsService} from "../../services/AdmissionsService";
import {ReturnToResidentModal} from "./atoms/return-to-resident-modal";
import {ModalName, useDashboardModalContext} from "./DashboardModalContext";
import {useDashboardAdmissionsContext} from "./DashboardAdmissionsContext";
import {
  readResidentFirstNameAnswer,
  readResidentLastNameAnswer,
} from "../../models/AdmissionModel.helpers";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";

export const DashboardReturnToResidentModal = () => {
  const {refreshDashboard} = useDashboardAdmissionsContext();
  const {admission, isModalOpened, toggleModal} = useDashboardModalContext(
    ModalName.RETURN_TO_RESIDENT,
  );
  const admissionId = admission?.id;

  const {mutate, isLoading} = useMutation(
    () => AdmissionsService.setInProgress(admissionId),
    {
      onSuccess: () => {
        Alert.success("Admission was successfully returned to resident.");
        refreshDashboard();
      },
      onError: async resp => {
        Alert.error(
          await buildResponseErrorMessage(
            resp,
            "Unable to return the admission to the resident.",
          ),
        );
      },
      onSettled: () => {
        toggleModal();
      },
    },
  );

  return (
    <ReturnToResidentModal
      isOpen={isModalOpened}
      residentFirstName={readResidentFirstNameAnswer(admission as any)}
      residentLastName={readResidentLastNameAnswer(admission as any)}
      onRequestClose={() => toggleModal()}
      isLoading={isLoading}
      onSubmit={() => mutate()}
    />
  );
};
