import React from "react";
import {Formik} from "formik";
import {
  Button,
  Paragraph,
  H1,
  FormikAutoSaveValues,
  ModalContent,
  ModalFooterButtons,
} from "@reside/ui";
import {SatisfactorySurvey} from "@reside/reside-api-admission";

import {SurveyForm} from "../../survey-form";
import {Footer} from "../../../footer";
import {Column} from "../../block-slide/BlockSlide";

type Props = Readonly<{
  facilityName: string;
  values: SatisfactorySurvey;
  onSubmit: (values: SatisfactorySurvey) => Promise<void>;
  onChange: (values: SatisfactorySurvey) => void;
}>;

export const SatisfactorySurveyOverlay = ({
  facilityName,
  values,
  onChange,
  onSubmit,
}: Props) => {
  const getInitialValues = (): SatisfactorySurvey => ({
    admissionExperienceAndOverallExperience:
      values?.admissionExperienceAndOverallExperience || null,
    admissionExperienceAndEaseOfUse:
      values?.admissionExperienceAndEaseOfUse || null,
    facilityImpressionDidImprove: values?.facilityImpressionDidImprove || null,
  });

  return (
    <Formik<SatisfactorySurvey>
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
    >
      {({submitForm, isSubmitting}) => (
        <>
          <Column>
            <ModalContent>
              <div>
                <H1 fontSize={[5, 6, 7, 8]} fontWeight="bold">
                  Congratulations!
                </H1>
                <Paragraph fontSize={[3, 4]}>
                  You have completed your Admission with {facilityName}!<br />
                  Your Admission documents will be sent to your email address.
                </Paragraph>
                <Paragraph fontSize={[3, 4]}>
                  Welcome to {facilityName}!
                </Paragraph>
              </div>
              <div>
                <FormikAutoSaveValues onChangeValues={onChange} />
                <SurveyForm />
              </div>
            </ModalContent>
          </Column>
          <ModalFooterButtons>
            <Footer
              hasShadow
              right={
                <Button
                  size="large"
                  color="primary"
                  testId="signDoneButton"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting" : "Done"}
                </Button>
              }
            />
          </ModalFooterButtons>
        </>
      )}
    </Formik>
  );
};
