var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { SideMenu, SideMenuItem } from "../side-menu";
import { Badge } from "../badge";
import { styled } from "../theme";
export var SideMenuWithErrorCount = function (_a) {
    var items = _a.items;
    return (React.createElement(SideMenu, null, items.map(function (item) {
        return (React.createElement(SideMenuItem, { key: item.path }, function (_a) {
            var Link = _a.Link;
            return (React.createElement(Link, { to: item.path, disabled: item.disabled, style: {} },
                React.createElement(TitleAndBadge, null,
                    item.title,
                    item.errorCount > 0 && (React.createElement(Badge, { style: {
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "0",
                        } }, item.errorCount)))));
        }));
    })));
};
var TitleAndBadge = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"])));
var templateObject_1;
