import {useMutation} from "react-query";
import {ResidentService} from "../../../services/ResidentServices";
import Alert from "react-s-alert";
import {useHistory} from "react-router";

export const useActivateResident = () => {
  const history = useHistory();

  return useMutation(
    (resident_id: string) => ResidentService.activateResident(resident_id),
    {
      onError: () => {
        Alert.error("Failed to restore resident.");
      },
      onSuccess: () => {
        Alert.success("Resident was successfully restore.");
        history.push("/admin/residents");
      },
    },
  );
};
