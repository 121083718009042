import React from "react";
import {RouteComponentProps} from "react-router-dom";
import Alert from "react-s-alert";
import {useMutation, useQuery} from "react-query";
import {SftpConfigForm} from "./SftpConfigForm";
import {DocumentTitle} from "../../../atoms/document-title";
import {Spinner} from "../../../atoms/spinner";
import {
  HubAndSpokeService,
  SftpConfig,
} from "../../../services/HubAndSpokeService";

export const SftpConfigEdit = ({
  match,
}: RouteComponentProps<{sftpServerId: string}>) => {
  const {sftpServerId} = match.params;

  const {data: sftpServer, isLoading}: {data: SftpConfig; isLoading: boolean} =
    useQuery(["getSftpServer", sftpServerId], async () => {
      const {data} = await HubAndSpokeService.findSftpConfig(sftpServerId);
      return data;
    });

  const {mutate: updateSftpConfig} = useMutation(
    (data: SftpConfig) =>
      HubAndSpokeService.updateSftpConfig(sftpServerId, data),
    {
      onSuccess: () => {
        Alert.success("SFTP configuration was successfully updated.");
      },
      onError: async (error: any) => {
        Alert.error(error?.data?.detail);
      },
    },
  );

  return (
    <>
      <DocumentTitle title="Reside - Admin - Edit SFTP Configurations" />
      {!isLoading && sftpServer ? (
        <SftpConfigForm
          onSubmit={updateSftpConfig}
          initialValues={sftpServer}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};
