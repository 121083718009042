import React, {useEffect} from "react";
import {useFormikContext} from "formik";
import {DateFieldInput, SignatureAsImage, Text} from "@reside/ui";

import {AdmissionStatus} from "../../../services/AdmissionsService";
import {
  MAIN_SIGNATURE,
  MAIN_SIGNATURE_DATE,
} from "../../../models/AdmissionModel.helpers";
import {AdmissionContextType} from "../AdmissionContext";
import {withAccessToken} from "../../../utils/url";
import {Title, Section, Strong} from "./styles";
import {Baloons} from "./atoms/Baloons";
import {SlideParagraph} from "./atoms/SlideParagraph";
import {TextLink} from "./atoms/TextLink";

type Props = Pick<AdmissionContextType, "draft" | "uiActions" | "uiState">;

export const LastSlideWithSignatureValuesSetter = (props: Props) => {
  const {setValues} = useFormikContext();

  const signatureDate = props.draft?.answers?.[MAIN_SIGNATURE_DATE];

  useEffect(() => {
    /**
     * Only inputs in the slide JSON are populated in the Formik by default. The last slide does not have real JSON inputs,
     * so we set the values for presentation purpose into disabled fields manually.
     */
    setValues({
      [MAIN_SIGNATURE_DATE]: signatureDate,
    });
  }, [setValues, signatureDate]);

  return <LastSlide {...props} />;
};

export const LastSlide = ({draft, uiActions, uiState}: Props) => {
  return draft.instance.status === AdmissionStatus.IN_PROGRESS ? (
    <>
      <Baloons />
      <Title>You are almost done!</Title>
      <Section>
        <SlideParagraph>
          You have gone through the Admission. By clicking on{" "}
          <Strong>&quot;Accept&quot;</Strong> and{" "}
          <Strong>&quot;Confirm&quot;</Strong> you are acknowledging that you
          have read each section of the Resident Contract and Attachment Forms
          and that you accept the Resident Contract and all Terms and Conditions
          stated within.
        </SlideParagraph>
        <SlideParagraph>
          Click on <Strong>&quot;Accept&quot;</Strong> to preview the final PDF
          in the next slide, then click on <Strong>&quot;Confirm&quot;</Strong>{" "}
          to complete Admission.
        </SlideParagraph>
      </Section>

      <Section>
        {draft.computed.completionPercentage !== 100 && (
          <SlideParagraph>
            In order to sign your Admission, please complete all slides.
          </SlideParagraph>
        )}
        {(draft.instance.flags.length > 0 ||
          draft.computed.completionPercentage !== 100) && (
          <Text fontSize={4} data-test-id="finish-incomplete-slides-link">
            Please{" "}
            <TextLink
              onClick={() => {
                if (!uiState.showIncompleteSlidesOnly) {
                  uiActions.toggleShowIncompleteSlidesOnlyFlag();
                }
                uiActions.openToc();
              }}
            />{" "}
            to finish incomplete slides or view slides flagged for help from an
            admissions staff member.
          </Text>
        )}
      </Section>
      <Text fontSize={4} data-test-id="update-signature-link">
        If needed, please <TextLink onClick={() => uiActions.goToSlide(1)} /> to
        update signature on &quot;Let&apos;s Get Started&quot; slide.
      </Text>
      <SlideParagraph>
        <Strong>Print Name: </Strong>
        {draft.computed.printName}
      </SlideParagraph>

      <SignatureAsImage
        label="Signature of Resident/Authorized Representative"
        urlWithAccessToken={
          (draft?.answers?.[MAIN_SIGNATURE] as string)
            ? withAccessToken(draft?.answers?.[MAIN_SIGNATURE] as string)
            : ""
        }
        hasSignature={!!draft?.answers?.[MAIN_SIGNATURE]}
        onErrorButtonClick={() => uiActions.goToSlide(1)}
        errorButtonText="Sign Admission"
      />

      <DateFieldInput disabled name={MAIN_SIGNATURE_DATE} label="Date" />

      {draft.instance.flags.length > 0 && (
        <SlideParagraph>
          A Facility Representative will contact you regarding your flagged
          slides. Please remember that in order to complete your Admission, all
          flags need to be removed.
        </SlideParagraph>
      )}
    </>
  ) : (
    <>
      <Baloons />
      <Title>Thank You!</Title>
      <SlideParagraph>You have already signed this Admission.</SlideParagraph>
    </>
  );
};
