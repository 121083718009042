import React, {ReactNode} from "react";
import {styled, Icon} from "@reside/ui";

import {colorCompareNegative, colorComparePositive} from "../../chart.helpers";
import {AggregationChart} from "./AggregationChart";

type Props = Readonly<{
  subject?: ReactNode;
  attribute?: string;
  values?: readonly number[];
  units?: string;
  formatOptions?: Intl.NumberFormatOptions;
  emptyValue?: string;
  implicitCompare?: boolean;
  barGraph?: boolean;
  reports?: boolean;
}>;

export const Aggregation = ({
  subject,
  attribute,
  values,
  emptyValue,
  units,
  reports,
  implicitCompare,
  formatOptions,
  barGraph,
}: Props) => {
  const formatter = Intl.NumberFormat("en-US", formatOptions);
  const [currentValue, previousValue] = values;
  const hasCurrentValue = !isNaN(currentValue);
  const hasPreviousValue = !isNaN(previousValue);

  const renderCompareValue = () =>
    !implicitCompare && (
      <CompareValue>
        {"/"}
        <strong>
          {hasPreviousValue ? formatter.format(previousValue) : emptyValue}
        </strong>
        {hasPreviousValue && units}
      </CompareValue>
    );

  const renderCompareRelative = () => {
    if (hasCurrentValue && hasPreviousValue && currentValue !== previousValue) {
      const rate = previousValue === 0 ? 1 : currentValue / previousValue - 1;
      const color = rate > 0 ? colorComparePositive : colorCompareNegative;

      return (
        <>
          <CompareText style={{color}}>
            <Icon
              name={rate > 0 ? "arrow-up" : "arrow-down"}
              color={color}
              size={10}
            />{" "}
            {formatterPercent.format(rate * 100)}%{" "}
            {implicitCompare ? <>VS. LAST PERIOD</> : <>VS. COMPARED PERIOD</>}
          </CompareText>
          {barGraph && (
            <CompareGraph>
              <AggregationChart
                color={color}
                rate={Math.abs(rate) > 1 ? 1 : Math.abs(rate)}
              />
            </CompareGraph>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const value = hasCurrentValue ? formatter.format(currentValue) : emptyValue;

  return reports ? (
    <ReportsWrapper>
      <ReportsValue>
        <strong>{value}</strong>
        {hasCurrentValue && units}
        {renderCompareValue()}
      </ReportsValue>
      <ReportsSubject>{subject}</ReportsSubject>
      {renderCompareRelative()}
    </ReportsWrapper>
  ) : (
    <Wrapper>
      <Subject>{subject}</Subject>
      <Attribute>{attribute}</Attribute>
      <Value>
        <strong>{value}</strong>
        {hasCurrentValue && units}
        {renderCompareValue()}
      </Value>
      {renderCompareRelative()}
    </Wrapper>
  );
};

const formatterPercent = Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const defaultFormatOptions: Intl.NumberFormatOptions = {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
};

Aggregation.defaultProps = {
  values: [],
  emptyValue: "-",
  formatOptions: defaultFormatOptions,
  reports: false,
  implicitCompare: true,
};

const Wrapper = styled.div`
  color: ${({theme}) => theme.color.darkBlue100};
  padding-right: 25px;
  margin-bottom: 10px;
  min-width: 220px;
`;

const Subject = styled.div`
  font-size: 17px;
  line-height: 28px;
  font-weight: ${({theme}) => theme.fontWeight.heavy};

  @media (max-width: ${({theme}) => theme.breakpoint.xl}px) {
    font-size: 15px;
    line-height: 1.8em;
  }
`;

const Attribute = styled.div`
  font-size: 12px;

  @media (max-width: ${({theme}) => theme.breakpoint.xl}px) {
    font-size: 11px;
    line-height: 1.2em;
  }
`;

const Value = styled.div`
  font-size: 45px;
  line-height: 41px;
  padding-top: 10px;

  strong {
    font-weight: ${({theme}) => theme.fontWeight.heavy};
  }

  @media (max-width: ${({theme}) => theme.breakpoint.xl}px) {
    font-size: 38px;
  }
`;

const ReportsWrapper = styled.div`
  color: ${({theme}) => theme.color.darkBlue100};
  padding-right: 25px;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  min-width: 115px;
  box-sizing: border-box;

  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    width: auto;
  }
`;

const ReportsSubject = styled.div`
  font-size: 13px;
  line-height: 24px;
  font-weight: normal;
  text-transform: uppercase;
`;

const ReportsValue = styled.div`
  font-size: 45px;
  line-height: 42px;
  letter-spacing: 2px;
`;

const CompareText = styled.span`
  font-size: 11px;
  font-weight: ${({theme}) => theme.fontWeight.medium};
`;

const CompareValue = styled.span`
  color: ${({theme}) => theme.color.gray100};
  font-size: 0.9em;
`;

const CompareGraph = styled.div`
  width: 130px;
`;
