import {CancelTokenSource} from "axios";
import {LOCAL_TIME_ZONE} from "@reside/ui/dist/date-picker/date";

import {
  ResolvedResponse,
  DataResolver,
  DataPath,
  isAnalyticsDataPath,
  ReportingAnalyticsResponse,
  getRequestUnitTimestamps,
} from "./reporting.common";
import {http} from "../../services/api";

export const resolverAnalytics: DataResolver = async (
  dataPath: DataPath,
  cancelTokenSource: CancelTokenSource,
) => {
  const resolvedValue: ResolvedResponse = {
    success: false,
    data: {},
  };
  let responsePromise = null;
  const {resolution} = dataPath.query as any;

  if (isAnalyticsDataPath(dataPath)) {
    responsePromise = http.post<ReportingAnalyticsResponse>(
      dataPath.type,
      {
        ...dataPath.query,
        ...getRequestUnitTimestamps(dataPath.query.from, dataPath.query.to),
        facilityIds: dataPath.facilities ? dataPath.facilities : [],
        dataResolution: resolution,
        localTimeZone: LOCAL_TIME_ZONE,
      },
      {
        cancelToken: cancelTokenSource.token,
        headers: {
          "Timezone-Offset": LOCAL_TIME_ZONE,
        },
      },
    );
  }
  if (responsePromise) {
    const response = await responsePromise;

    if (response.ok && response.data) {
      resolvedValue.success = true;
      resolvedValue.data = response.data;
    }
  }

  return resolvedValue;
};
