var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import { Field } from "formik";
import { MinimalPlayer } from "../../video";
import { styled } from "../../theme";
import { Caption } from "./Caption";
import { LanguageControls } from "./LanguageControls";
export var BlockMandatoryVideo = function (_a) {
    var children = _a.children, reference = _a.reference;
    var _b = useState(0), activeMutationIndex = _b[0], setActiveMutationIndex = _b[1];
    var currentMutation = children[activeMutationIndex];
    return (React.createElement(Container, null,
        React.createElement(LanguageControls, { activeMutationIndex: activeMutationIndex, mutations: children, onChange: function (index) { return setActiveMutationIndex(index); } }),
        React.createElement(VideoFrame, { key: currentMutation.language.code },
            React.createElement(Field, { name: reference.id }, function (formik) { return (React.createElement(MinimalPlayer, { key: currentMutation.language.code, controls: false, playsInline: true, source: currentMutation.video.source, poster: currentMutation.video.poster, width: currentMutation.video.width, height: currentMutation.video.height, onEnd: function () {
                    formik.form.setFieldTouched(formik.field.name, true);
                    formik.form.setFieldValue(formik.field.name, "yes");
                } })); })),
        React.createElement(Caption, { fieldName: reference.id, translationKey: currentMutation.caption.translationKey })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: content-box;\n"], ["\n  box-sizing: content-box;\n"])));
var VideoFrame = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  display: flex;\n  justify-content: center;\n"], ["\n  text-align: center;\n  display: flex;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2;
