"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
var flatten_1 = require("../../flatten");
exports.Validator = {
    name: "max_date",
    isImplicit: false,
    errorMessage: "This date must be before :max_date.",
    validate: function (value, requirement) {
        var _a = requirement.split(","), dateField = _a[0], maxDateField = _a[1];
        // TODO: write a test and refactor
        var dateVal = (0, flatten_1.flatten)(this.validator.input)[dateField];
        var maxDateVal = (0, flatten_1.flatten)(this.validator.input)[maxDateField];
        return Number(new Date(dateVal)) >= Number(new Date(maxDateVal));
    },
};
