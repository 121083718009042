import {useState} from "react";
import {useMutation} from "react-query";
import {useDebounce, useDeepCompareEffect} from "react-use";
import Alert from "react-s-alert";
import {TableUserDto} from "@reside/reside-api-app";

import {UserService} from "../../../../../services/UserService";
import {buildResponseErrorMessage} from "../../../../../services/api/errorHelpers";

export const useUserPermissionsMutation = (user: TableUserDto) => {
  const [activePermissions, setActivePermissions] = useState<
    ReadonlyArray<TableUserDto.PermissionsEnum>
  >([]);
  const [isInitial, setIsInitial] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const {mutate, isSuccess, isIdle} = useMutation(
    (activePermissions: ReadonlyArray<TableUserDto.PermissionsEnum>) =>
      UserService.putReportingPermissions(user.id, activePermissions),
    {
      onError: async (resp: any) => {
        Alert.warning(
          await buildResponseErrorMessage(
            resp,
            "There was an error while saving permissions",
          ),
        );
      },
      onSettled: () => {
        setIsSaving(false);
      },
    },
  );

  useDebounce(
    () => {
      if (!isInitial) {
        mutate(activePermissions);
      }
    },
    1000,
    [activePermissions],
  );

  const onSetPermission = (
    permissions: ReadonlyArray<TableUserDto.PermissionsEnum>,
  ) => {
    setActivePermissions(permissions);
    setIsSaving(true);

    if (isInitial) {
      setIsInitial(false);
    }
  };

  useDeepCompareEffect(() => {
    if (!user) {
      setIsInitial(true);
      setIsSaving(false);
    } else {
      setActivePermissions(user.permissions);
    }
  }, [user?.permissions]);

  return {
    activePermissions,
    isSaving,
    isSuccess: isSuccess || isIdle,
    onSetPermission,
    setActivePermissions,
  };
};
