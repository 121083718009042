import {Avatar} from "../../avatar";
import {Button, Text, Link} from "./styles";
import {AdminNavigationHeaderProps} from "../types";

/** User profile as a standalone link is used on big screens. */
export const UserProfileAsLink = ({user}: AdminNavigationHeaderProps) => {
  const name = `${user.firstName} ${user.lastName}`;

  return (
    <Button
      flat
      style={{
        position: "relative",
        padding: "0 0.5rem",
      }}
    >
      <Avatar size={32} name={name} />
      <Text>{name}</Text>
      <Link to={`/admin/user/${user.id}/profile`} data-test-id="profileLink" />
    </Button>
  );
};
