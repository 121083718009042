import {Selector} from "react-redux";
import {createSelector} from "reselect";
import {TableUserDto} from "@reside/reside-api-app";

import {UserId, UserByPermission} from "./user-permissions.common";
import {AppState, select} from "../store";

type UserIdSelector = (state: AppState) => UserId;

const selectSessionUserId = (state: AppState) =>
  select.adminSession.user(state).id;

const createUserPermissionStateSelector =
  (selectUserId: UserIdSelector = selectSessionUserId) =>
  (state: AppState) =>
    state.userPermissions.users[selectUserId(state)];

export const createUserPermissionsSelector = (
  selectUserId: UserIdSelector = selectSessionUserId,
) =>
  createSelector(
    createUserPermissionStateSelector(selectUserId),
    user => user?.permissions ?? [],
  );

export const selectUserPermissions = createUserPermissionsSelector();

export const selectUserHasPermission = (
  permission: TableUserDto.PermissionsEnum,
) =>
  createSelector(selectUserPermissions, userPermissions =>
    userPermissions.includes(permission),
  );

export const createShouldFetchPermissionsSelector = (
  selectUserId: UserIdSelector = selectSessionUserId,
) =>
  createSelector(createUserPermissionStateSelector(selectUserId), user => {
    if (user) {
      if (
        Object.prototype.hasOwnProperty.call(user, "success") &&
        user.success
      ) {
        return false;
      } else {
        return !user.fetching;
      }
    }

    return true;
  });

export const selectShouldFetchUsersByPermission = (state: AppState) =>
  !state.userPermissions.usersByPermission.fetching;

const usersByPermission: Selector<
  AppState,
  UserByPermission[],
  {permissionType: TableUserDto.PermissionsEnum}
> = (
  state: AppState,
  props: {permissionType: TableUserDto.PermissionsEnum},
) => {
  if (
    props.permissionType &&
    state &&
    state.userPermissions &&
    state.userPermissions.usersByPermission &&
    state.userPermissions.usersByPermission.byName
  ) {
    return state.userPermissions.usersByPermission.byName[props.permissionType];
  }

  return undefined;
};

export const selectors = {
  usersByPermission,
};
