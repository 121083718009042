import {CancelTokenSource} from "axios";
import {LOCAL_TIME_ZONE} from "@reside/ui/dist/date-picker/date";

import {http} from "../../services/api";
import {
  isReportsDataPath,
  ReportingReportsResponse,
  DataResolver,
  ReportsDataPath,
  ResolvedResponse,
  PER_PAGE,
} from "./reporting-reports.common";
import {getRequestUnitTimestamps} from "../reporting/reporting.common";

export const resolverReports: DataResolver = async (
  dataPath: ReportsDataPath,
  page: number,
  cancelTokenSource: CancelTokenSource,
  perPage?: number,
): Promise<ResolvedResponse> => {
  if (isReportsDataPath(dataPath)) {
    try {
      const response = await http.post<ReportingReportsResponse>(
        dataPath.key ?? dataPath.type,
        {
          ...getRequestUnitTimestamps(dataPath.query.from, dataPath.query.to),
          facilityIds: dataPath.query.facilities
            ? dataPath.query.facilities
            : [],
          pageable: {
            page,
            size: perPage || PER_PAGE,
            direction: dataPath.query.sortDirection,
            properties: [dataPath.query.sortField],
          },
          localTimeZone: LOCAL_TIME_ZONE,
        },
        {
          cancelToken: cancelTokenSource.token,
          headers: {
            "Timezone-Offset": LOCAL_TIME_ZONE,
          },
        },
      );

      return {
        success: true,
        data: response.data,
      };
    } catch {}
  }

  return {
    success: false,
    data: undefined,
  };
};
