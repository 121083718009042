import React from "react";
import {Button, Icon, DropdownSelect} from "@reside/ui";

import {FileExtension} from "../downloadExport";

export type FileExtensionItem = Readonly<{
  id: FileExtension;
  label: string;
}>;

export type Props = Readonly<{
  onSelect: (item: FileExtensionItem) => void;
  disabled?: boolean;
}>;

export const FileExportDropdownSelect = ({onSelect, disabled}: Props) => (
  <DropdownSelect<FileExtensionItem>
    trigger={() => (
      <Button
        ghost
        flat
        color="neutral"
        size="small"
        disabled={disabled}
        icon={<Icon name="export" size={11} />}
      >
        EXPORT
      </Button>
    )}
    disabled={disabled}
    items={[
      {
        id: FileExtension.CSV,
        label: "CSV",
      },
      {
        id: FileExtension.XLSX,
        label: "Excel",
      },
      {
        id: FileExtension.PDF,
        label: "PDF",
      },
    ]}
    selectItem={onSelect}
  />
);
