import React, {useEffect} from "react";
import {useFormikContext} from "formik";

/**
 * This hook will inject pcc status into initial values and
 * resets the dirty status
 */
export const useFormikReinitializePcc = (isPccEnabled: boolean) => {
  const {resetForm, values} = useFormikContext();

  useEffect(() => {
    resetForm({values: {...(values as any), isPccEnabled}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPccEnabled]);
};

/**
 * Element to use useFormikReinitializePcc
 */
export const FormikReinitializePcc = ({
  isPccEnabled,
}: {
  isPccEnabled: boolean;
}) => {
  useFormikReinitializePcc(isPccEnabled);
  return <></>;
};
