"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = exports.MAX_LENGTH_RULE_NAME = void 0;
exports.MAX_LENGTH_RULE_NAME = "max_length";
exports.Validator = {
    name: exports.MAX_LENGTH_RULE_NAME,
    errorMessage: "This field may not be greater than :".concat(exports.MAX_LENGTH_RULE_NAME, " characters."),
    validate: function (value, requirement) {
        return value.toString().length <= Number(requirement);
    },
};
