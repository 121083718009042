import React, {FormEvent, useState, useCallback, useMemo} from "react";
import {styled, Button, Icon, DropdownMenu, DropdownMenuItem} from "@reside/ui";
import {connect} from "react-redux";
import {SimpleFacilityDto} from "@reside/reside-api-app";

import {select} from "../../store";
import {history} from "../../utils/history";
import {GeneralSearch} from "../search-box";
import {createPreflightUrl} from "../../pages/page-admin-preflight/route";

type Props = Readonly<{
  facilities: ReadonlyArray<SimpleFacilityDto>;
}>;

export const Renderer = ({facilities = []}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const searchVisible = facilities.length >= 5;
  const dropDownVisible = facilities.length > 0;
  const isSearchEmpty = searchValue === "";

  const navigateToPreflight = useCallback(
    (facilityId: string) => history.push(createPreflightUrl({facilityId})),
    [],
  );

  const filteredFacilities = useMemo(
    () =>
      facilities.filter(facility =>
        facility.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue, facilities],
  );

  return dropDownVisible ? (
    <DropdownMenu
      trigger={open => (
        <Button color="primary" testId="createAdmissionDropdown">
          <>
            Create New Admission For
            <ArrowIcon name={open ? "up" : "down"} size={12} />
          </>
        </Button>
      )}
    >
      <>
        {searchVisible && (
          <>
            <Offset>
              <GeneralSearch
                hideSuggestion
                isSearching={false}
                inputValue={searchValue}
                showClearButton={!isSearchEmpty}
                onInputChange={(event: FormEvent<HTMLInputElement>) =>
                  setSearchValue(event.currentTarget.value)
                }
                onPressSpace={(event: FormEvent<HTMLInputElement>) => {
                  if (searchValue.trim().length > 0)
                    setSearchValue(searchValue + " ");
                }}
                placeholder="Search by facility"
                testId="createAdmissionSearchBox"
                onClearClick={() => setSearchValue("")}
              />
            </Offset>
            {filteredFacilities.length === 0 && (
              <EmptySearch>No matching facility</EmptySearch>
            )}
          </>
        )}
        <>
          {filteredFacilities.map(item => (
            <DropdownMenuItem
              key={item.id}
              onClick={() => {
                navigateToPreflight(item.id);
              }}
            >
              {item.name}
            </DropdownMenuItem>
          ))}
        </>
      </>
    </DropdownMenu>
  ) : facilities.length ? (
    <Button
      color="primary"
      onClick={() => navigateToPreflight(facilities[0].id)}
      testId="createAdmissionButton"
    >
      Create New Admission
    </Button>
  ) : null;
};

const mapStateToProps = (state: any) => ({
  facilities: select.adminSession.facilities(state),
});

export const FacilityDropdownMenu = connect(mapStateToProps)(Renderer);

const ArrowIcon = styled(Icon)`
  padding-left: 10px;
`;

const EmptySearch = styled.div`
  margin: 16px 12px;
  text-align: center;
`;

const Offset = styled.div`
  margin-bottom: ${({theme}) => theme.space[3]}px;
`;
