import {Card, ValidationBlockBoundary, styled} from "@reside/ui";

export const PreflightCard = styled(Card)`
  padding: 30px 80px 10px 80px;
`;

export const ButtonSpace = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 60px 80px;
  button + button {
    margin-left: 25px;
  }
`;

export const ValidationBlock: typeof ValidationBlockBoundary.defaultProps.ValidationBlock =
  styled(ValidationBlockBoundary.defaultProps.ValidationBlock)`
    padding: 0 10px;
  ` as any;
