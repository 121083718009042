import {Switch, Redirect, RouteComponentProps} from "react-router-dom";

import {SentryRoute as Route} from "../../utils/sentryRoute";
import {AdminLayout} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {FeatureFlagsHeader} from "./atoms/";
import {PageAdmin404} from "../page-admin-404";
import {PageAdminFeatureFlagsByFacilities} from "./PageAdminFeatureFlagsByFacilities";
import {PageAdminFeatureFlagsByOrganizations} from "./PageAdminFeatureFlagsByOrganizations";

export const FeatureFlagsRouter = ({match}: RouteComponentProps) => (
  <>
    <DocumentTitle title={"Reside - Admin - Feature Flags"} />
    <AdminLayout>
      <FeatureFlagsHeader />
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          component={() => <Redirect to={`${match.path}/organizations`} />}
        />
        <Route
          exact
          path={`${match.path}/organizations`}
          component={PageAdminFeatureFlagsByOrganizations}
        />
        <Route
          exact
          path={`${match.path}/facilities`}
          component={PageAdminFeatureFlagsByFacilities}
        />
        <Route component={PageAdmin404} />
      </Switch>
    </AdminLayout>
  </>
);
