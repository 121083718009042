import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {AdmissionsService} from "../../../services/AdmissionsService";
import {readResidentNameAnswer} from "../../../models/AdmissionModel.helpers";
import {useAdmissionsRestoreModalContext} from "./context";
import {RestoreAdmissionModal} from "../../restore-admission-modal";

type StateProps = Readonly<{
  onRestored?: () => void;
}>;

export const AdmissionsRestoreAdmissionModal = ({onRestored}: StateProps) => {
  const {admission, toggle} = useAdmissionsRestoreModalContext();

  const {mutate, isLoading} = useMutation(
    ({admissionId}: {admissionId: string}) =>
      AdmissionsService.restore(admissionId),
    {
      onError: () => {
        Alert.error("Failed to restore the Admission.");
      },
      onSuccess: () => {
        Alert.success("Admission was successfully restored.");
        onRestored?.();
      },
      onSettled: () => {
        toggle();
      },
    },
  );

  return (
    <RestoreAdmissionModal
      isOpen={!!admission}
      outdatedContract={admission?.outdatedContract}
      admissionId={admission?.id}
      admissionCode={admission?.code}
      residentName={readResidentNameAnswer(admission)}
      isLoading={isLoading}
      onCancel={() => mutate({admissionId: admission?.id})}
      onRequestClose={() => toggle()}
      admissionStatus={admission?.status}
    />
  );
};
