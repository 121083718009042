import React, {memo} from "react";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsManagedMedicaidTimeSeriesChart = memo(({data}: ChartProps) => (
  <LineChart data={lineGraphData(data)} />
));

export const MANAGED_MEDICAID_CHART_ID = "managed-medicaid" as const;

export const AnalyticsManagedMedicaidTimeSeries = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID}
  >
    <div className="chart-header">
      <CardTitle
        title="Percent of residents with Medicaid"
        subtitle="by Admissions"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.managedMedicaidRate,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsManagedMedicaidTimeSeriesChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
