import {useQuery} from "react-query";
import {
  QuicksightDashboard,
  QuicksightService,
} from "../../../services/QuicksightService";

export const useQuicksightUserDashboards = (userId: string) =>
  useQuery(["quicksightUserDashboards", userId], async () => {
    const {data} = await QuicksightService.getUserDashboards(userId);

    return data.dashboards as ReadonlyArray<QuicksightDashboard>;
  });
