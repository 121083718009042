import React from "react";
import {Formik} from "formik";
import {Trans} from "@lingui/macro";
import {Values} from "@reside/forms";
import {Button} from "@reside/ui";

import {Footer} from "../../footer";
import {SlideBlock} from "../model";
import {BlockSlide} from "../block-slide";

type Props = Readonly<{
  slide: SlideBlock;
  onSubmit: (values: Values) => void;
  initialValues: Values;
}>;

export const BlockSlideOverlay = ({slide, onSubmit, initialValues}: Props) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formik => (
        <>
          <BlockSlide {...slide} />
          <Footer
            right={
              <Button
                testId="signConfirmButton"
                color="primary"
                size="large"
                type="submit"
                onClick={formik.submitForm}
              >
                <Trans id={slide.ctaButton.title.translationKey} />
              </Button>
            }
          />
        </>
      )}
    </Formik>
  );
};
