import {Button, Icon, Text} from "@reside/ui";
import {WarningContainer} from "./styles";

export const AutoLogoutWarningBar = ({
  resetAutoLogout,
  secondsRemaining,
}: {
  resetAutoLogout: () => void;
  secondsRemaining: number;
}): JSX.Element => {
  return (
    <WarningContainer>
      <Icon name="info" />
      <Text>
        Your session will expire in 00:
        {secondsRemaining < 10 ? `0${secondsRemaining}` : secondsRemaining}{" "}
        seconds for security purposes. Click &quot;Stay logged in&quot; to
        remain signed in.
      </Text>
      <Button
        color="neutral"
        onClick={() => {
          resetAutoLogout();
        }}
      >
        Stay logged in
      </Button>
    </WarningContainer>
  );
};
