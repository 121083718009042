import React from "react";
import Alert from "react-s-alert";
import {RouteComponentProps} from "react-router-dom";
import {useMutation} from "react-query";
import {CreateStaffUserDto} from "@reside/reside-api-app";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminLayout} from "../../atoms/admin-layout";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {UserService} from "../../services/UserService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";
import {UserFormHeader} from "./atoms/UserFormHeader";
import {UserFormik} from "./atoms/UserFormik";

export const PageAdminUserCreate = ({history}: RouteComponentProps) => {
  const {mutate: createUser} = useMutation(UserService.create, {
    onSuccess: () => {
      Alert.success("User was successfully created.");
      history.push("/admin/users");
    },
    onError: async (resp: any) => {
      Alert.error(
        await buildResponseErrorMessage(
          resp,
          "An error occurs when creating user.",
        ),
      );
    },
  });

  return (
    <>
      <DocumentTitle title="Reside - Admin - New User" />
      <AdminLayout>
        <UserFormik
          initialValues={{
            phone: "",
            roleId: "",
            firstName: "",
            lastName: "",
            email: "",
            organizationId: "",
            facilities: [],
            type: CreateStaffUserDto.TypeEnum.CLERK,
          }}
          onSubmit={createUser}
          userFormHeader={
            <UserFormHeader title={<AdminPageTitle>New User</AdminPageTitle>} />
          }
        />
      </AdminLayout>
    </>
  );
};
