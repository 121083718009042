import {styled} from "@reside/ui";
import {useLocation, useRouteMatch} from "react-router-dom";

import {ButtonGroup} from "../../../atoms/button-group";
import {FacilityDropdownTreeSelect} from "../../../atoms/facility-dropdown-tree-select";
import {ButtonLink} from "../../../atoms/button-link";

export const FeatureFlagsHeader = () => {
  const location = useLocation();
  const match = useRouteMatch();

  const orgButtonActive = location.pathname.includes("/organizations");

  return (
    <>
      <Wrapper>
        {location.pathname.includes("facilities") && (
          <FacilityDropdownTreeSelect />
        )}
        <ButtonGroup>
          <ButtonLink
            to={`${match.path}/organizations`}
            color={orgButtonActive ? "primary" : "default"}
            outline={false}
            testId="feature-flags-organizations"
          >
            By organizations
          </ButtonLink>

          <ButtonLink
            to={`${match.path}/facilities`}
            color={!orgButtonActive ? "primary" : "default"}
            outline={false}
            testId="feature-flags-facilities"
          >
            By facilities
          </ButtonLink>
        </ButtonGroup>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  justify-content: space-between;
`;
