import React, {useMemo, createContext, ReactNode} from "react";
import {connect} from "react-redux";

import {select} from "../../store";
import {TableOfContent} from "./model";

type Props = Readonly<{
  selectFullTOC: () => TableOfContent;
  selectIncompleteSlides: () => TableOfContent;
  children: ReactNode;
}>;

type Context = Omit<Props, "children">;

export const ToCContext = createContext<Context>(undefined);

export const ToCContextConsumer = ({
  children,
}: {
  children: (context: Context) => ReactNode;
}) => (
  <ToCContext.Consumer>
    {context => {
      if (context === undefined) {
        throw new Error(
          "ToCContextConsumer must be used within the ToCContextProvider",
        );
      }

      return children(context);
    }}
  </ToCContext.Consumer>
);

export const ToCContextProvider = (props: Props) => {
  const {selectFullTOC, selectIncompleteSlides, children} = props;

  const value = useMemo(() => {
    return {
      selectFullTOC,
      selectIncompleteSlides,
    };
  }, [selectFullTOC, selectIncompleteSlides]);

  return <ToCContext.Provider value={value}>{children}</ToCContext.Provider>;
};

type StateProps = Readonly<{
  selectFullTOC: () => TableOfContent;
  selectIncompleteSlides: () => TableOfContent;
}>;

type OwnProps = Readonly<{
  admissionId: string;
  children: ReactNode;
}>;

const mapStateToProps = (state: any, {admissionId}: OwnProps) => ({
  selectFullTOC: () => select.admissions.fullTOC({admissionId})(state),
  selectIncompleteSlides: () =>
    select.admissions.incompleteSlides({
      admissionId,
    })(state),
});

export const ConnectedTableOfContentsProvider = connect<
  StateProps,
  unknown,
  OwnProps
>(mapStateToProps)(
  ({
    selectFullTOC,
    selectIncompleteSlides,
    children,
  }: StateProps & OwnProps) => (
    <ToCContextProvider
      selectFullTOC={selectFullTOC}
      selectIncompleteSlides={selectIncompleteSlides}
    >
      {children}
    </ToCContextProvider>
  ),
);
