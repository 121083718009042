import React from "react";
import {styled, BlockField, Card, FlagIcon} from "@reside/ui";

import {SlideBlock} from "../../admission-explorer/model";

type Props = Readonly<{
  slide: SlideBlock;
}>;

export const SlideCard = (props: Props) => (
  <BodyCard dark={props.slide.darkBackground}>
    <Header>{props.slide.state.flag && <FlagIcon />}</Header>
    {props.slide.children.map(block => (
      <BlockField key={block.id} block={block} />
    ))}
  </BodyCard>
);

const BodyCard = styled(Card)<{dark: boolean}>`
  flex: 1;
  margin-bottom: 15px;
  padding: 15px;
  height: fit-content;
  ${({dark, theme}) => dark && `background-color: ${theme.color.darkBlue10}`}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;
