export const domainNamesStringToList = (domainNames: string): Array<string> =>
  domainNames
    .split(",")
    .map((domainName: string) => domainName.trim().toLowerCase());

export const domainNamesListToString = (domainNames: Array<string>): string =>
  domainNames.join(", ").toLowerCase();

export const formatDomainNames = (domainNames: string): string => {
  return domainNamesListToString(domainNamesStringToList(domainNames));
};
