import React, {memo} from "react";
import {differenceInDays, startOfToday, parseISO, addDays} from "date-fns";

import {styled, Color, Tag} from "@reside/ui";

type Props = Readonly<{
  /**
   * Date of admission.
   */
  date: string;
}>;

/**
 * Displays number of days remaining for completing admission.
 * In the state Illinois, you have 3 days to complete the admission since the date of admission.
 */
export const DaysOfAdmissionTag = memo(({date}: Props) => {
  const days = computeRemainingDoa(date);

  return (
    days < 3 && (
      <SmallTag color={getColorForDoa(days)}>
        {days < 0
          ? `${pluralize(Math.abs(days), "", "day", "days")} after`
          : days === 0
          ? "Due today"
          : `${days + 1} days remaining`}
      </SmallTag>
    )
  );
});

/**
 * Note we add 2 days, because 'dateOfAdmission' is first day, it makes 3 in total.
 */
export const computeRemainingDoa = (dateOfAdmission: string) =>
  differenceInDays(addDays(parseISO(dateOfAdmission), 2), startOfToday());

const getColorForDoa = (days: number): Color => {
  if (days === 2) {
    return "green100";
  } else if (days === 1) {
    return "golden100";
  } else if (days === 0) {
    return "deepOrange100";
  } else {
    return "deepOrange300";
  }
};

const pluralize = (
  itemCount: number,
  none: string,
  one: string,
  many: string,
) => {
  switch (itemCount) {
    case 0:
      return none;
    case 1:
      return `${itemCount} ${one}`;
    default:
      return `${itemCount} ${many}`;
  }
};

export const SmallTag = styled(Tag)`
  padding: 4px 8px;
  line-height: 1;
`;
