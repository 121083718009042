export var convertImgToBase64URL = function (url) {
    return new Promise(function (resolve, reject) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL());
            canvas = null;
        };
        img.onerror = function () {
            reject(new Error("Could not convert \"".concat(url, "\" to data URL.")));
        };
        img.src = url;
    });
};
