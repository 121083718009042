import React, {useEffect} from "react";
import {connect} from "react-redux";

import {select} from "../../store";
import {AdmissionExplorer} from "../../atoms/admission-explorer";
import {Spinner} from "../../atoms/spinner";
import {bindUiActionsForInstance} from "../../models/AdmissionExperienceUiModel";
import {bindAdmissionActionsForInstance} from "../../models/AdmissionsModel";
import {
  CompletionCheck,
  Draft,
  DraftActions,
  UIActions,
  UIState,
} from "../../atoms/admission-explorer/model";
import {AdmissionExperienceRouteProps} from "./route";
import {Dispatch} from "../../store/store";

type OwnProps = AdmissionExperienceRouteProps;

type StateProps = Readonly<{
  admissionIsLoaded: boolean;
  draft: Draft;
  uiState: UIState;
  completionChecklist: CompletionCheck[];
  loadingPatchAdmission: boolean;
  hasClearHiddenFieldsFlag: boolean;
}>;

type DispatchProps = Readonly<{
  draftActions: DraftActions;
  uiActions: UIActions;
  logout: () => void;
}>;

const Renderer = ({
  admissionIsLoaded,
  draft,
  draftActions,
  uiState,
  uiActions,
  completionChecklist,
  logout,
  loadingPatchAdmission,
  hasClearHiddenFieldsFlag,
}: OwnProps & DispatchProps & StateProps) => {
  useEffect(() => {
    if (!draft) {
      draftActions.findAsync();
    }
  }, [draft, draftActions]);

  const draftIsLoading = !draft || !admissionIsLoaded;

  return draftIsLoading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <AdmissionExplorer
      draft={draft}
      draftActions={draftActions}
      uiState={uiState}
      uiActions={uiActions}
      completionChecklist={completionChecklist}
      logoutSession={logout}
      loadingPatchAdmission={loadingPatchAdmission}
      hasClearHiddenFieldsFlag={hasClearHiddenFieldsFlag}
    />
  );
};

const mapState = (state: any, ownProps: OwnProps) => {
  const {admissionId} = ownProps.match.params;

  return {
    draft: select.admissions.experience({
      admissionId,
      computeChildrenBlocks: false,
    })(state),
    uiState: select.admissionExperienceUi.get({admissionId})(state),
    completionChecklist: select.admissions.completionChecklist({admissionId})(
      state,
    ),
    admissionIsLoaded: select.admissions.fetchStatus({admissionId})(state),
    loadingPatchAdmission:
      !!state.loading.effects.admissions.patchAdmissionByValues ||
      !!state.loading.effects.admissions.patchLastViewedSlideIndex,
    hasClearHiddenFieldsFlag:
      select.adminSession.hasClearSlideHiddenFieldsFlag(state),
  };
};

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => {
  const {admissionId} = ownProps.match.params;

  return {
    logout: dispatch.admissionSession.logout,
    draftActions: bindAdmissionActionsForInstance(admissionId)(dispatch),
    uiActions: bindUiActionsForInstance(admissionId)(dispatch),
  };
};

export const PageAdmissionExperience = connect(mapState, mapDispatch)(Renderer);
