export type ReactAppEnvironment =
  | "DEVELOP"
  | "TEST"
  | "STAGING"
  | "STAGE-NEW"
  | "PRODUCTION";

export const reactAppEnvironment =
  (process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment) || "DEVELOP";

export const isProd = reactAppEnvironment === "PRODUCTION";
export const isDev = reactAppEnvironment === "DEVELOP";
export const isTest = reactAppEnvironment === "TEST";
export const isStg =
  reactAppEnvironment === "STAGING" || reactAppEnvironment === "STAGE-NEW";

/**
 * TODO: Use url variables without "/api" suffix
 */
export const BASE_PATH = process.env.REACT_APP_API_URL.substr(
  0,
  process.env.REACT_APP_API_URL.length - 4, // length of "/api" string,
);

export const FRONTEND_BASE_NAME =
  process.env.REACT_APP_FRONTEND_BASE_NAME || "";
