import React, {
  Component,
  Children,
  Fragment,
  ReactElement,
  ReactNode,
} from "react";
import {cx} from "@emotion/css";
import {format, parseISO} from "date-fns";
import {theme, Icon} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";

import {
  SortDirection,
  SortField,
  ReportsDataStatePath,
  ReportItem,
  ReportData,
} from "../../../store/reporting-reports/reporting-reports.common";
import {IFromTo} from "../../../store/reporting/reporting.common";
import {TextCellEllipsis} from "../../list-table/SharedCells";
import {colorScale} from "../chart.helpers";

import "./ListTableReporting.scss";

export type ColumnElement<T> = ReactElement<ColumnProps<T>, "Column">;

type Props<T> = Readonly<{
  data: ReportsDataStatePath[];
  sortField?: SortField;
  sortDirection: SortDirection;
  noRowsMessage: ReactNode;
  children: ColumnElement<T>[] | ColumnElement<T>;
  full: boolean;
  onSortChange?: (sortField: SortField, sortDirection: SortDirection) => void;
}>;

type RowData<T> = Readonly<{
  rowData: T;
  rowIndex: number;
}>;

export type ColumnProps<T> = Readonly<{
  sortKey?: SortField;
  width: number;
  label?: ReactNode;
  textRight?: boolean;
  cellRenderer: (rowData: RowData<T>) => ReactNode;
}>;

export function Column<T>(props: ColumnProps<T>): JSX.Element {
  return null;
}

interface ZippedDataItem<L> {
  rowData: L;
  compareIdx: number;
  path: ReportsDataStatePath["dataPath"];
}

const zipData: <L extends ReportItem>(
  data: ReportsDataStatePath[],
) => ZippedDataItem<L>[] = data => {
  const maxLen = data.reduce((a, b) => {
    return b?.dataState && b.dataState.data
      ? Math.max(a, b.dataState.data.length)
      : a;
  }, 0);

  const acc: any[] = [];

  for (let i = 0; i < maxLen; i++) {
    for (let j = 0; j < data.length; j++) {
      const d = data[j];

      if (d?.dataState && d.dataState.data && d.dataState.data.length > i) {
        acc.push({
          rowData: d.dataState.data[i],
          compareIdx: j,
          path: d.dataPath,
        });
      }
    }
  }

  return acc;
};

export class ListTableReporting<T extends ReportItem> extends Component<
  Props<T>
> {
  static defaultProps = {
    sortDirection: SortDirection.ASC,
    noRowsMessage: "No results found",
    full: false,
  };

  setSortParams = (sortField: SortField) => {
    const nextSortDirection: SortDirection = {
      [SortDirection.ASC]: SortDirection.DESC,
      [SortDirection.DESC]: SortDirection.ASC,
      [SortDirection.NONE]: SortDirection.NONE,
    }[this.props.sortDirection];

    if (this.props.onSortChange) {
      this.props.onSortChange(
        sortField,
        this.props.sortField !== sortField
          ? SortDirection.DESC
          : nextSortDirection,
      );
    }
  };

  render() {
    const renderEmptyTable = () => (
      <tbody>
        <tr>
          <td
            colSpan={Children.count(this.props.children)}
            className="table__no-rows-renderer"
          >
            {this.props.noRowsMessage}
          </td>
        </tr>
      </tbody>
    );

    const renderHeader = (data: ReportData) => {
      if (
        this.props.data &&
        this.props.data.length === 0 &&
        this.props.noRowsMessage
      ) {
        return null;
      }

      return (
        <thead>
          <tr>
            {this.props.data.length > 1 ? <th style={{width: "3%"}} /> : null}
            {Children.map(
              this.props.children,
              (columnElement: ReactElement<ColumnProps<T>>, idx) => {
                const sortKey = columnElement.props.sortKey;
                const isSortable = data && data.length > 0 && sortKey;
                const isSortedByThisColumn = this.props.sortField === sortKey;

                return (
                  <th
                    key={idx}
                    className={cx(
                      {textRight: columnElement.props.textRight},
                      isSortable ? "sortable" : "notSortable",
                      isSortedByThisColumn ? "sorting" : "",
                    )}
                    style={
                      columnElement.props.width && {
                        width: `${columnElement.props.width + "%"}`,
                      }
                    }
                    onClick={() => {
                      isSortable && this.setSortParams(sortKey);
                    }}
                  >
                    {columnElement.props.label}
                    {isSortable && isSortedByThisColumn && (
                      <Icon
                        name={
                          this.props.sortDirection === SortDirection.ASC
                            ? "sort-up"
                            : "sort-down"
                        }
                        color={theme.color.gray100}
                        size={8}
                        className="listtable-reporting-0-52"
                      />
                    )}
                    {isSortable && !isSortedByThisColumn && (
                      <Icon
                        name="arrows"
                        color={theme.color.gray100}
                        size={8}
                        className="listtable-reporting-0-52"
                      />
                    )}
                  </th>
                );
              },
            )}
            {this.props.data.length > 1 ? (
              <th style={{width: "10%"}}>Compare Period</th>
            ) : null}
          </tr>
        </thead>
      );
    };

    const renderCompareColumn = (fromTo: IFromTo) => {
      const formatDate = (date: string) =>
        date ? format(parseISO(date), DATE_FORMAT.SHORT_DAY) : "-";

      return (
        <td style={{width: "10%"}}>
          <TextCellEllipsis small>
            {`${formatDate(fromTo.from)} - ${formatDate(fromTo.to)}`}
          </TextCellEllipsis>
        </td>
      );
    };

    const renderCompareDot = (i: number) => {
      return (
        <td style={{width: "5%"}}>
          <i
            className={cx("circle")}
            style={{
              background: colorScale[i],
            }}
          />
        </td>
      );
    };

    const renderBody = () => (
      <tbody
        className={cx({
          empty: this.props.data && this.props.data.length === 0,
          "limit-height": !this.props.full,
        })}
      >
        {this.props.data &&
          zipData<T>(this.props.data).map(
            ({rowData, compareIdx, path}, rowIdx: number) => {
              return (
                <Fragment key={rowIdx}>
                  <tr>
                    {this.props.data.length > 1
                      ? renderCompareDot(compareIdx)
                      : null}
                    {Children.map(
                      this.props.children,
                      (columnElement: ColumnElement<T>, cellIdx) => (
                        <td
                          key={cellIdx}
                          style={
                            columnElement.props.width && {
                              width: `${columnElement.props.width + "%"}`,
                            }
                          }
                        >
                          {rowData
                            ? columnElement.props.cellRenderer({
                                rowIndex: rowIdx,
                                rowData: rowData,
                              })
                            : "-"}
                        </td>
                      ),
                    )}
                    {this.props.data.length > 1
                      ? renderCompareColumn(path.query)
                      : null}
                  </tr>
                </Fragment>
              );
            },
          )}
      </tbody>
    );

    const data =
      this.props.data &&
      this.props.data.length > 0 &&
      this.props.data[0] &&
      this.props.data[0].dataState &&
      this.props.data[0].dataState.data;

    return (
      <div
        className={cx({
          "list-table-reporting-wrapper": true,
          "list-table-reporting-full": this.props.full,
        })}
      >
        <table className="list-table-reporting">
          {renderHeader(data)}
          {renderBody()}
          {this.props.data &&
            this.props.data.length === 0 &&
            renderEmptyTable()}
        </table>
      </div>
    );
  }
}
