import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {RoleDto} from "@reside/reside-api-app";

import {AdminRoles} from "../../atoms/admin-roles";
import {
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  RE_ADMISSION_RATE_BY_FACILITY_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
} from "./analytics";
import {
  FACILITY_CHANGES_REPORT_ID,
  PNEUMOCOCCAL_REPORT_ID,
  SLIDE_INTERACTIONS_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_REPORT_ID,
  TRUST_FUND_REPORT_ID,
} from "./reports";
import {selectUserPermissions} from "../../store/user-permissions/user-permissions.selectors";
import {getSectionsForPermissions} from "./permissions.helpers";

const TemporaryResideAdminSectionsOnly = [
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  FACILITY_CHANGES_REPORT_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  PNEUMOCOCCAL_REPORT_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  RE_ADMISSION_RATE_BY_FACILITY_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  SLIDE_INTERACTIONS_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  STAFF_REPORT_ID,
  TRUST_FUND_REPORT_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
];

type StateProps = Readonly<{
  hasAccessToChart: (chartId: string) => boolean;
}>;

export type Props = Readonly<{
  chartIds: ReadonlyArray<string>;
  children: ReactNode;
}>;

const TemporaryHideChartRenderer = ({
  chartIds,
  hasAccessToChart,
  children,
}: Props & StateProps) => (
  <AdminRoles
    roles={
      chartIds
        .filter(hasAccessToChart)
        .some(
          chartId => !TemporaryResideAdminSectionsOnly.includes(chartId as any),
        )
        ? undefined
        : [RoleDto.NameEnum.RESIDE_ADMIN]
    }
  >
    {children}
  </AdminRoles>
);

const mapStateToProps = (state: any) => {
  const sectionsForPermissions = getSectionsForPermissions(
    selectUserPermissions(state),
  );

  return {
    hasAccessToChart: (chartId: string) =>
      sectionsForPermissions.includes(chartId),
  };
};

export const TemporaryHideChart = connect<StateProps>(mapStateToProps)(
  TemporaryHideChartRenderer,
);
