import React from "react";
import {styled} from "@reside/ui";

import {AdmissionFromSearch} from "../../services/AdmissionsService";
import {AdmissionDropdownMenu} from "./AdmissionDropdownMenu";

export const ActionMenuCell = ({
  data,
  onOpen,
  onClose,
}: {
  data: AdmissionFromSearch;
  onOpen?: () => void;
  onClose?: () => void;
}) => (
  <CellWrapper
    onClick={e => {
      e.stopPropagation();
    }}
  >
    <AdmissionDropdownMenu admission={data} onOpen={onOpen} onClose={onClose} />
  </CellWrapper>
);

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
