import React from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";

import {DateStr} from "../../../../store/reporting/reporting.common";
import {ContainerWidthSizer} from "../../ContainerWidthSizer";

import {colorScale, chartTheme, ChartProps} from "../../chart.helpers";
import {getXFormatClean, getMinMax, formatPercent} from "./LineChart.helpers";

import "./style.scss";

const MAX_DATES = 5;

const defaultProps: ChartProps<DataItem[]> = {
  data: [],
};

export type DataItem = Readonly<{
  date: DateStr | Date;
  value: number;
  originalDate: DateStr | Date;
}>;

export const LineChart = ({
  data,
  xFormat,
  yFormat,
  domain,
}: ChartProps<DataItem[]>) => {
  const maxLen = Math.max(...data.map(d => d.length || 0));

  const xFormatClean = getXFormatClean(xFormat, maxLen);

  let yDates: any = null;

  if (maxLen < MAX_DATES) {
    yDates = data.reduce(
      (accumulator: (Date | DateStr)[], currentValue) =>
        accumulator.concat(
          currentValue.map(item => {
            return item.date;
          }),
        ),
      [],
    );
  }
  let [min, max] = getMinMax(data);

  if (min === max) {
    min -= min === 0 ? 0 : 1;
    max += max === 100 ? 0 : 1;
  }

  return (
    <div className="line-graph">
      <ContainerWidthSizer>
        {({width}) => (
          <VictoryChart
            theme={chartTheme}
            scale={{x: "time"}}
            width={width}
            domain={domain}
            domainPadding={20}
            padding={{
              top: 20,
              right: 10,
              bottom: 50,
              left: 55,
            }}
          >
            <VictoryAxis
              dependentAxis
              domain={{y: [min < 0 ? 0 : min, max || 1]}}
              tickFormat={yFormat ? yFormat : formatPercent}
              crossAxis={false}
              style={{
                axis: {
                  stroke: "transparent",
                },
              }}
            />
            <VictoryAxis
              tickFormat={xFormatClean}
              style={{
                grid: {
                  stroke: "transparent",
                  strokeWidth: 0,
                },
              }}
              crossAxis={false}
              fixLabelOverlap
              tickCount={Math.min(maxLen, 9)}
              tickValues={yDates}
            />
            {data.map((values, index) => {
              const cleanValues = values ? values : [];

              return (
                <VictoryLine
                  key={index}
                  data={cleanValues}
                  x="originalDate"
                  y="value"
                  sortKey="date"
                  style={{
                    data: {
                      stroke: colorScale[data.length - index - 1],
                    },
                  }}
                />
              );
            })}
            {data.map((values, index) => {
              const cleanValues = values ? values : [];

              return (
                <VictoryScatter
                  key={index}
                  data={cleanValues}
                  x="originalDate"
                  y="value"
                  labels={cleanValues.map(
                    point =>
                      `${
                        yFormat
                          ? yFormat(point.value)
                          : formatPercent(point.value.toFixed(2))
                      } (${xFormatClean(point.originalDate)})`,
                  )}
                  labelComponent={<VictoryTooltip theme={chartTheme} />}
                  style={{
                    data: {
                      fill: colorScale[data.length - index - 1],
                    },
                  }}
                />
              );
            })}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    </div>
  );
};

LineChart.defaultProps = defaultProps;
