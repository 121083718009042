import {DocumentControllerApi} from "@reside/reside-api-document";
import portableFetch from "portable-fetch";

import {BASE_PATH} from "../config";
import {getCommonAuthorizationHeader} from "./api/selectors";

export const documentApi = new DocumentControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);

export const DocumentService = {
  getTemplateNames: () =>
    documentApi.getTemplatesNamesUsingGET(getCommonAuthorizationHeader()),

  getTemplate: (templateId: string) =>
    documentApi.getTemplateUsingGET(templateId, getCommonAuthorizationHeader()),

  getLatestTemplateVersion: (facilityId: string) =>
    documentApi.getLatestTemplateVersionUsingGET(
      facilityId,
      getCommonAuthorizationHeader(),
    ),

  getTemplateVersion: (facilityId: string, versionId: string) =>
    documentApi.getTemplateVersionUsingGET(
      facilityId,
      versionId,
      getCommonAuthorizationHeader(),
    ),

  getPreflightTemplate: (templateId: string) =>
    documentApi.getPreflightTemplateUsingGET(
      templateId,
      getCommonAuthorizationHeader(),
    ),

  getFacilitySettingsTemplate: (templateId: string) =>
    documentApi.getFacilitySettingsTemplateUsingGET(
      templateId,
      getCommonAuthorizationHeader(),
    ),

  getPreludeExperienceTemplate: (templateId: string) =>
    documentApi.getResidentExperiencePreludeTemplateUsingGET(
      templateId,
      getCommonAuthorizationHeader(),
    ),
};
