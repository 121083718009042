import {createModel} from "@rematch/core";
import {v4} from "uuid";
import Alert from "react-s-alert";
import {extractAnswerValues} from "@reside/forms";

import {select, store} from "../store";

import {
  buildCompletionChecklist,
  buildToc,
  calculateCompletionPercentage,
  computeProps,
  createFlag,
  extractAfterAdmissionSlides,
  extractFlags,
  extractNotes,
} from "./AdmissionsModel.helpers";
import {
  getAnswersFromFormValues,
  calculatePrintName,
  updateCompletedSlides,
  clearSignaturesIfRepresentativeDidChange,
  updateLastViewedSlideIdx,
  extractResidentExperienceSlides,
} from "./AdmissionModel.helpers";
import {
  initialState,
  AdmissionsModelReducers,
} from "./admissions/AdmissionsModel.reducers";
import {admissionsApi, AdmissionsService} from "../services/AdmissionsService";
import {DocumentService} from "../services/DocumentService";
import {getAdmissionAuthorizationHeader} from "./admission-session/selectors";

export const model = createModel({
  name: "admissions",

  state: {...initialState},

  reducers: AdmissionsModelReducers,

  selectors: (slice, createSelector, hasProps) => ({
    fetchStatus: hasProps((models, {admissionId}) => {
      return slice(state => {
        return !!state.fetchStatus[admissionId];
      });
    }),
    instance: hasProps((models, {admissionId}) => {
      return slice(state => state.instances[admissionId]);
    }),
    answers() {
      return createSelector([this.instance], getInstance => {
        return rootState => {
          const instance = getInstance(rootState);

          return instance ? extractAnswerValues(instance) : null;
        };
      });
    },
    template() {
      return createSelector([this.instance], getInstance => {
        return rootState => {
          const instance = getInstance(rootState);

          return instance
            ? slice(rootState).templates[instance.templateId]
            : null;
        };
      });
    },
    flags() {
      return createSelector([this.instance], getInstance => {
        return rootState => {
          const instance = getInstance(rootState);

          return instance ? extractFlags(instance) : null;
        };
      });
    },
    notes() {
      return createSelector([this.instance], getInstance => {
        return rootState => {
          const instance = getInstance(rootState);

          return instance ? extractNotes(instance) : null;
        };
      });
    },
    computed(models) {
      return createSelector(
        [
          this.instance,
          this.template,
          this.answers,
          models.admissionExperienceUi.get,
          props => props,
        ],
        (getInstance, getTemplate, getAnswers, getUiState, props) => {
          return rootState => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {admissionId, ...overrides} = props;
            const instance = getInstance(rootState);
            const template = getTemplate(rootState);
            const answers = getAnswers(rootState);
            const uiState = getUiState(rootState);
            const computed =
              instance && answers
                ? computeProps(instance, answers, overrides)
                : null;
            const slides =
              instance && template && answers && computed
                ? extractResidentExperienceSlides({
                    template,
                    instance,
                    answers,
                    computed,
                    uiState,
                  })
                : null;
            const completionPercentage = calculateCompletionPercentage(slides);

            return slides && computed
              ? {...computed, completionPercentage}
              : null;
          };
        },
      );
    },
    afterAdmissionSlides(models) {
      return createSelector(
        [
          this.template,
          this.answers,
          this.computed,
          models.admissionExperienceUi.get,
        ],
        (getTemplate, getAnswers, getComputed, getUiState) => {
          return rootState => {
            const template = getTemplate(rootState);
            const answers = getAnswers(rootState);
            const computed = getComputed(rootState);
            const uiState = getUiState(rootState);

            return template && answers && computed
              ? extractAfterAdmissionSlides({
                  template,
                  answers,
                  computed,
                  uiState,
                })
              : [];
          };
        },
      );
    },
    slides(models) {
      return createSelector(
        [
          this.instance,
          this.template,
          this.answers,
          this.computed,
          models.admissionExperienceUi.get,
          props => props,
        ],
        (
          getInstance,
          getTemplate,
          getAnswers,
          getComputed,
          getUiState,
          props,
        ) => {
          return rootState => {
            const instance = getInstance(rootState);
            const template = getTemplate(rootState);
            const answers = getAnswers(rootState);
            const computed = getComputed(rootState);
            const uiState = getUiState(rootState);

            return instance && template && answers && computed
              ? extractResidentExperienceSlides({
                  template,
                  instance,
                  answers,
                  computed,
                  uiState,
                  computeChildrenBlocks: props.computeChildrenBlocks,
                })
              : null;
          };
        },
      );
    },
    visibleSlides() {
      return createSelector([this.slides], getSlides => {
        return rootState => {
          const slides = getSlides(rootState);

          return slides.filter(slide => slide.state.visible);
        };
      });
    },
    completedVisibleSlides() {
      return createSelector([this.visibleSlides], getVisibleSlides => {
        return rootState => {
          const visibleSlides = getVisibleSlides(rootState);

          return visibleSlides
            .filter(slide => slide.state.complete)
            .map(slide => slide.id);
        };
      });
    },
    completionPercentage() {
      return createSelector(
        [this.instance, this.completedVisibleSlides, this.visibleSlides],
        (getInstance, getCompletedVisibleSlides, getVisibleSlides) => {
          return rootState => {
            const admission = getInstance(rootState);

            if (["SIGNED", "APPROVED"].includes(admission.status)) {
              return 100;
            }

            const completedVisibleSlides = getCompletedVisibleSlides(rootState);
            const visibleSlides = getVisibleSlides(rootState);

            return Math.round(
              (completedVisibleSlides.length / visibleSlides.length) * 100,
            );
          };
        },
      );
    },
    experience() {
      return createSelector(
        [
          this.instance,
          this.template,
          this.flags,
          this.answers,
          this.computed,
          this.slides,
          this.afterAdmissionSlides,
        ],
        (
          getInstance,
          getTemplate,
          getFlags,
          getAnswers,
          getComputed,
          getSlides,
          getAfterAdmissionSlides,
        ) => {
          return rootState => {
            const instance = getInstance(rootState);
            const template = getTemplate(rootState);
            const answers = getAnswers(rootState);
            const flags = getFlags(rootState);
            const computed = getComputed(rootState);
            const slides = getSlides(rootState);
            const afterAdmissionSlides = getAfterAdmissionSlides(rootState);

            return instance && answers && flags && computed && slides
              ? {
                  answers,
                  instance,
                  slides,
                  template,
                  flags,
                  computed,
                  afterAdmissionSlides,
                }
              : null;
          };
        },
      );
    },
    prelude() {
      return createSelector(
        [this.instance, this.template, this.answers],
        (getInstance, getTemplate, getAnswers) => {
          return rootState => {
            const instance = getInstance(rootState);
            const template = getTemplate(rootState);
            const answers = getAnswers(rootState);

            return instance && answers
              ? {
                  answers,
                  instance,
                  template,
                }
              : null;
          };
        },
      );
    },
    fullTOC() {
      return createSelector(
        [this.template, this.slides],
        (getTemplate, getSlides) => {
          return rootState => {
            const template = getTemplate(rootState);
            const slides = getSlides(rootState);

            return template && slides ? buildToc(template, slides) : null;
          };
        },
      );
    },
    incompleteSlides() {
      return createSelector(
        [this.template, this.slides],
        (getTemplate, getSlides) => {
          return rootState => {
            const template = getTemplate(rootState);
            const slides = getSlides(rootState);

            return template && slides ? buildToc(template, slides, true) : null;
          };
        },
      );
    },
    completionChecklist() {
      return createSelector([this.slides], getSlides => {
        return rootState => {
          const slides = getSlides(rootState);

          return slides ? buildCompletionChecklist(slides) : null;
        };
      });
    },
    lastViewedSlideIdx: hasProps((models, {admissionId}) => {
      return slice(state => state.instances[admissionId].lastViewedSlideIdx);
    }),
  }),

  effects: models => ({
    async findAsync({admissionId, fetchTemplate = true}) {
      models.admissions.setFetchStatus({
        admissionId,
        loaded: false,
      });

      try {
        const response = await AdmissionsService.get(admissionId);

        const {data: template} =
          fetchTemplate &&
          (await DocumentService.getTemplate(response.data.templateId));

        if (template) {
          models.admissions.pushObject({
            instance: response.data,
            template,
          });
        } else {
          models.admissions.pushObjectForPrelude({
            instance: response.data,
          });
        }

        models.admissions.setFetchStatus({
          admissionId,
          loaded: true,
        });

        return response;
      } catch (response) {
        return response;
      }
    },

    async postNoteEffect(action, rootState) {
      const {admission} = select(models => ({
        admission: models.admissions.instance({
          admissionId: action.admissionId,
        }),
      }))(rootState);

      const note = {
        id: v4(),
        slideId: action.slideId,
        note: action.note,
        author: `${action.user.firstName} ${action.user.lastName}`,
        created: Math.floor(Date.now() / 1000),
        status: "NEW",
      };

      models.admissions.pushNote({
        admissionId: action.admissionId,
        note,
      });

      try {
        const response = await AdmissionsService.patchNotes(
          action.admissionId,
          [...admission.notes, {...note, message: note.note}],
        );

        models.admissions.setAdmissionById({
          admissionId: action.admissionId,
          admission: response.data,
        });
        Alert.success("The note was successfully added.");
      } catch {
        Alert.error("Failed to save Admission note");
      }
    },

    async removeNoteEffect({admissionId, noteId}, rootState) {
      const {admission} = select(models => ({
        admission: models.admissions.instance({
          admissionId: admissionId,
        }),
      }))(rootState);

      models.admissions.removeNote({
        admissionId,
        noteId,
      });

      try {
        const response = await AdmissionsService.patchNotes(
          admissionId,
          admission.notes.filter(({id}) => id !== noteId),
        );

        models.admissions.setAdmissionById({
          admissionId: admissionId,
          admission: response.data,
        });
        Alert.success("The note was successfully removed.");
      } catch {
        Alert.error("Failed to remove Admission note");
      }
    },

    async sign({admissionId}) {
      const response = await admissionsApi.signAdmissionUsingPATCH(
        admissionId,
        getAdmissionAuthorizationHeader(),
      );

      models.admissions.setAdmissionById({
        admissionId,
        admission: response.data,
      });

      return response;
    },

    async syncAdmissionSlides({admissionId, representativeAnswers}, rootState) {
      const {answers} = select(models => ({
        answers: models.admissions.answers({admissionId}),
      }))(rootState);

      models.admissions.setFetchStatus({
        admissionId,
        loaded: false,
      });

      const values = clearSignaturesIfRepresentativeDidChange({
        answers,
        values: representativeAnswers,
      });

      await models.admissions.patchAdmissionByValues({
        admissionId,
        values,
      });

      models.admissions.setFetchStatus({
        admissionId,
        loaded: true,
      });
    },

    setAnswersEffect(action, rootState) {
      const {answers} = select(models => ({
        answers: models.admissions.answers({admissionId: action.admissionId}),
      }))(rootState);

      const updatedAnswers = {
        ...answers,
        ...action.answers,
      };

      models.admissions.setAnswers({
        admissionId: action.admissionId,
        answers: updatedAnswers,
      });
    },

    async patchLastViewedSlideIndex({admissionId}, rootState) {
      const {lastViewedSlideIdx} = select(models => ({
        lastViewedSlideIdx: models.admissions.lastViewedSlideIdx({
          admissionId,
        }),
      }))(rootState);

      try {
        const response = await AdmissionsService.patch(admissionId, {
          lastViewedSlideIdx,
        });

        models.admissions.setAdmissionById({
          admissionId,
          admission: response.data,
        });
      } catch {
        Alert.error("Failed to save Admission answers");
      }
    },

    async patchAdmissionByValues({admissionId, values}, rootState) {
      const {answers, template} = select(models => ({
        answers: models.admissions.answers({admissionId}),
        template: models.admissions.template({admissionId}),
      }))(rootState);

      await models.admissions.setAnswersEffect({
        admissionId,
        answers: values,
      });

      const {
        admission,
        completedVisibleSlides,
        completionPercentage,
        lastViewedSlideIdx,
        visibleSlides,
        allSlides,
      } = select(models => ({
        admission: models.admissions.instance({admissionId}),
        completedVisibleSlides: models.admissions.completedVisibleSlides({
          admissionId,
        }),
        completionPercentage: models.admissions.completionPercentage({
          admissionId,
        }),
        lastViewedSlideIdx: models.admissions.lastViewedSlideIdx({
          admissionId,
        }),
        visibleSlides: models.admissions.visibleSlides({admissionId}),
        allSlides: models.admissions.slides({admissionId}),
      }))(rootState);

      const admissionPatch = {
        answers: getAnswersFromFormValues({
          values,
          admissionAnswersObjects: admission.answers,
          modifier: calculatePrintName({...answers, ...values}),
          template,
        }),
        completedSlides: updateCompletedSlides({
          answers,
          values,
          visibleSlideIds: visibleSlides.map(slide => slide.id),
          completedVisibleSlideIds: completedVisibleSlides,
        }),
        completionPercentage,
        lastViewedSlideIdx: updateLastViewedSlideIdx({
          lastViewedSlideIdx,
          visibleSlideIds: visibleSlides.map(slide => slide.id),
          allSlideIds: allSlides.map(slide => slide.id),
        }),
      };

      const response = await AdmissionsService.patch(
        admissionId,
        admissionPatch,
      );

      models.admissions.setAdmissionById({
        admissionId,
        admission: response.data,
      });
    },

    async patchAfterAdmissionByValues({admissionId, values}, rootState) {
      const {answers, template} = select(models => ({
        answers: models.admissions.answers({admissionId}),
        template: models.admissions.template({admissionId}),
      }))(rootState);

      await models.admissions.setAnswersEffect({
        admissionId,
        answers: values,
      });

      const {admission} = select(models => ({
        admission: models.admissions.instance({admissionId}),
      }))(rootState);

      const admissionPatch = {
        answers: getAnswersFromFormValues({
          values,
          admissionAnswersObjects: admission.answers,
          modifier: calculatePrintName({...answers, ...values}),
          template,
        }),
      };

      try {
        const response = await AdmissionsService.patchAfterAdmissionAnswers(
          admissionId,
          admissionPatch,
        );

        models.admissions.setAdmissionById({
          admissionId,
          admission: response.data,
        });
      } catch {
        Alert.error("Failed to save after admission answers.");
      }
    },

    // dispatched effects can not catch errors
    async patchAdmissionByValuesWithAlert({admissionId, values}) {
      try {
        await models.admissions.patchAdmissionByValues({
          admissionId: admissionId,
          values,
        });
      } catch {
        Alert.error(
          "Failed to save Admission answers, return to previous slide and submit again",
        );
      }
    },
  }),
});

export function bindAdmissionActionsForInstance(admissionId) {
  return dispatch => ({
    // Reduce actions
    slideOnSubmit: async ({values}) => {
      await dispatch.admissions.patchAdmissionByValuesWithAlert({
        admissionId,
        values,
      });
    },
    set: ({admission, template}) =>
      dispatch.admissions.pushObject({instance: admission, template}),
    setAfterAdmissionAnswers: async answers => {
      dispatch.admissions.setAnswersEffect({
        admissionId,
        answers,
      });

      await dispatch.admissions.patchAfterAdmissionByValues({
        admissionId,
        values: answers,
      });
    },
    setAnswers: answers =>
      dispatch.admissions.setAnswersEffect({admissionId, answers}),
    setSatisfactorySurvey: async satisfactorySurvey => {
      dispatch.admissions.setSatisfactorySurvey({
        admissionId,
        satisfactorySurvey,
      });
      try {
        await AdmissionsService.patch(admissionId, {
          satisfactorySurvey,
        });
      } catch {
        Alert.error("Failed to save Satisfaction survey");
      }
    },
    completeSlide: slideId => {
      dispatch.admissions.completeSlide({admissionId, slideId});
    },
    removeCompleteSlide: slideId => {
      dispatch.admissions.removeCompleteSlide({admissionId, slideId});
    },
    postNote: props =>
      dispatch.admissions.postNoteEffect({admissionId, ...props}),
    removeNote: noteId =>
      dispatch.admissions.removeNoteEffect({admissionId, noteId}),
    flagSlide: async ({comment, slideId}) => {
      const {answers} = select(models => ({
        answers: models.admissions.answers({admissionId}),
      }))(store.getState());

      const flag = createFlag({
        author: calculatePrintName(answers),
        comment,
        slideId,
      });

      const response = await AdmissionsService.createFlag(admissionId, flag);

      dispatch.admissions.setAdmissionById({
        admissionId,
        admission: response.data,
      });
    },
    unflagSlide: async flagId => {
      await AdmissionsService.deleteFlag(admissionId, flagId);
      dispatch.admissions.removeFlags({admissionId, flagIds: [flagId]});

      const response = await AdmissionsService.get(admissionId);

      dispatch.admissions.setAdmissionById({
        admissionId,
        admission: response.data,
      });
    },
    sign: async slideId => {
      const {admission} = select(models => ({
        admission: models.admissions.instance({admissionId}),
      }))(store.getState());

      await dispatch.admissions.sign({admissionId});

      /**
       * Mark last slide as completed after admission is signed, so we don't have to rollback when sign fails.
       */
      try {
        await AdmissionsService.patch(admissionId, {
          completedSlides: [...admission.completedSlides, slideId],
        });
      } catch {
        Alert.error("Failed to add Admission completed slide");
      }
    },
    // Async actions
    findAsync: fetchTemplate =>
      dispatch.admissions.findAsync({admissionId, fetchTemplate}),
  });
}
