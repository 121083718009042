import React, {Component, ReactElement, ReactNode} from "react";
import {setupI18n} from "@lingui/core";
import {I18nProvider as LinguiI18nProvider} from "@lingui/react";
import {Trans} from "@lingui/macro";
import {TransPropsWithoutI18n} from "@lingui/macro/Trans";
import {I18nProviderProps} from "@lingui/react/I18nProvider";
import {connect} from "react-redux";

import {select} from "../../store";
import {InitialState} from "../../models/LocaleModel";

/**
 * Intentional space value, because markdown renderer will crash on empty string.
 */
const BLANK_VALUE = " ";

/**
 * i18n instance
 */
export let i18n = setupI18n(InitialState);

export const t = (id: string) => (i18n === null ? id : i18n._(id));

/**
 *  Render translation only if it exists, otherwise fall-back to empty value.
 */
export const TransIfExists = (
  id: string,
  renderer: ReactNode,
  emptyValue = "",
): string | ReactElement<TransPropsWithoutI18n> =>
  t(id) === BLANK_VALUE ? emptyValue : <Trans id={id} render={renderer} />;

type Props = Readonly<{children: ReactNode}> &
  Pick<I18nProviderProps, "catalogs" | "language">;

class I18nProviderRenderer extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.catalogs !== prevProps.catalogs ||
      this.props.language !== prevProps.language ||
      this.props.children !== prevProps.children
    ) {
      i18n = setupI18n({
        language: this.props.language,
        catalogs: this.props.catalogs,
      });
    }
  }

  render() {
    return (
      <LinguiI18nProvider
        catalogs={this.props.catalogs}
        language={this.props.language}
        i18n={i18n}
        missing={() => BLANK_VALUE}
      >
        {this.props.children}
      </LinguiI18nProvider>
    );
  }
}

const injectLocaleProps = (state: any) => ({
  catalogs: select.locale.catalogs(state),
  language: select.locale.language(state),
});

export const I18nProvider = connect(injectLocaleProps)(I18nProviderRenderer);
