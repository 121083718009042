import {useEffect, useState} from "react";
import {LOGGED_OUT_DUE_TO_INACTIVITY} from "../utils/constants/autoLogout";
import {getAuthorizationHeader} from "../models/admin-session/selectors";

export const useIsAutoLoggedOut = (): boolean | null => {
  const [isAutoLoggedOut, setIsAutoLoggedOut] = useState<boolean | null>(null);

  const isUserLoggedIn = getAuthorizationHeader();

  useEffect(() => {
    setIsAutoLoggedOut(
      JSON.parse(sessionStorage.getItem(LOGGED_OUT_DUE_TO_INACTIVITY)),
    );
  }, [isUserLoggedIn]);

  return isAutoLoggedOut;
};
