import React from "react";

import {styled} from "@reside/ui";
import {Spinner} from "../../atoms/spinner";

type Props = Readonly<{
  isLoading: boolean;
}>;

export const PDFSpinner = ({isLoading}: Props) => (
  <Overlay isLoading={isLoading}>
    <StickToCenter>
      <Spinner />
      <h4 style={{marginTop: "100px"}}>Exporting PDF...</h4>
    </StickToCenter>
  </Overlay>
);

const Overlay = styled.div<{isLoading: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 102;
  display: ${({isLoading}) => (isLoading ? "block" : "none")};
`;

const StickToCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 50%;
  left: 0;
  transform: none;
`;
