import React from "react";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {useFormikContext} from "formik";
import {Button} from "@reside/ui";
import {Header} from "./styled";
import {AdminPageTitle} from "../../../atoms/admin-layout/AdminPageTitle";
import {ButtonLink} from "../../../atoms/button-link";
import {Space} from "../../../atoms/space";
import {
  HubAndSpokeService,
  SftpConfig,
} from "../../../services/HubAndSpokeService";

export const SftpConfigFormHeader = () => {
  const {values, submitForm} = useFormikContext<SftpConfig>();

  const {mutate, isLoading} = useMutation(HubAndSpokeService.testConnectivity, {
    onSuccess: () => {
      Alert.success("Connection successful.");
    },
    onError: (error: any) => {
      Alert.error(error.data.detail);
    },
  });

  return (
    <Header
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div>
        <AdminPageTitle>SFTP Configurations</AdminPageTitle>
      </div>
      <Button
        onClick={() =>
          mutate({
            hostname: values.hostname,
            port: values.port,
            username: values.username,
            auth_type: values.auth_type,
            auth_value: values.auth_value,
          })
        }
        color="success"
        disabled={isLoading}
      >
        {isLoading ? "Connecting..." : "Test connectivity"}
      </Button>
      <Space>
        <ButtonLink
          to="/admin/hub-and-spoke/sftp-configurations"
          disabled={isLoading}
        >
          Cancel
        </ButtonLink>
        <Button onClick={submitForm} color="success" disabled={isLoading}>
          Save
        </Button>
      </Space>
    </Header>
  );
};
