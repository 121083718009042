import React from "react";
import {Box} from "@reside/ui";
import {styled} from "@reside/ui";

import {ButtonLink} from "../../../atoms/button-link";
import {Facility} from "../../../models/types/AdmissionForm";

type Props = Readonly<{
  facility: Facility;
}>;

export const ActivityLogHeader = ({facility}: Props) => (
  <Box
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    className="pageadminfacilitysettings-btn-1"
  >
    <Wrapper>
      Facility: <strong>{facility.name}</strong>
    </Wrapper>
    <ButtonLink to={`/admin/facility/${facility.id}/facility-settings`}>
      Facility Settings
    </ButtonLink>
  </Box>
);

const Wrapper = styled.div`
  font-size: 23px;
`;
