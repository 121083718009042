import {useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  DropdownSelect,
  DropdownSelectText,
  ThinScrollbarArea,
} from "@reside/ui";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminAdmissionsTable} from "../../atoms/admin-admissions-table";
import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {AdminActionRow} from "../../atoms/admin-action-row";
import {select, AppState} from "../../store";
import {SortDirection} from "../../store/reporting-reports/reporting-reports.common";
import {Spinner} from "../../atoms/spinner";
import {updateTableKey} from "../../atoms/list-table/helpers";
import {FacilityDropdownTreeSelect} from "../../atoms/facility-dropdown-tree-select";
import {useAdmissionsByStatusQuery} from "../../atoms/dashboard-table/hooks";
import {Wrapper} from "./styles";
import {PageAdminAdmissionsProps, Status} from "./utils/types";
import {STATUSES} from "./utils/constants";
import {isResideAdmin} from "../../utils/helpers/users";
import {AdminAdmissionsSearch} from "../../atoms/admin-search";

/**
 * Page that will display all admissions and enable filtering by facilities and status.
 * If user wants to filter by facilites using dropdown @activeFacilitiesTreeKeys array is filled and used for filtering.
 * If logged in user is not allowed to see the dropdown by being allowed to see admissions belonging only to his facility,@hasSelectedFacility will check if that facility exists on array of facilities he is part of.
 * @isAllowedToSeeAdmissions prop will prevent unwanted users from seeing all admissions.
 *
 * */

const Renderer = ({
  activeFacilitiesTreeKeys,
  hasSelectedFacilities,
  isAllowedToSeeAdmissions,
}: PageAdminAdmissionsProps) => {
  const [admissionStatus, setAdmissionStatus] = useState(STATUSES[0]);
  const [sortBy, setSortBy] = useState("dateOfAdmission");
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
  } = useAdmissionsByStatusQuery({
    sortBy,
    sortDirection: sortDirection as any,
    status: admissionStatus.id,
    facilityIds: activeFacilitiesTreeKeys,
    pageSize: 30,
    isAllowedToSeeAdmissions,
  });

  const admissions = data?.pages.flatMap(item => item?.content);

  const selectItem = (item: Status) => {
    if (admissionStatus === item) return;
    setAdmissionStatus(item);
  };

  const onSort = (sortBy: string, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection || SortDirection.NONE);
  };

  return (
    <>
      <DocumentTitle title="Reside - Admin - Admissions" />
      <AdminLayoutFullHeightAndTwoCells>
        <AdminActionRow
          facilitySelector={<FacilityDropdownTreeSelect />}
          filter={
            <DropdownSelect
              trigger={({isOpened}) => (
                <DropdownSelectText
                  isOpened={isOpened}
                  label={admissionStatus.label}
                />
              )}
              selectItem={selectItem}
              selectedItem={admissionStatus}
              items={STATUSES as Status[]}
              label="Status:"
            />
          }
          search={<AdminAdmissionsSearch />}
        />
        <ThinScrollbarArea>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <AdminAdmissionsTable
                admissions={
                  hasSelectedFacilities && admissions
                    ? (admissions as any) // TODO: fix types and incompatibility between Admission<AdmissionAnswerObjects>[] and Admission[]
                    : []
                }
                onSort={onSort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                tableName={updateTableKey(admissionStatus, "")}
                onAdmissionDeleted={() => {
                  refetch();
                }}
                isAllowedToSeeAdmissions={isAllowedToSeeAdmissions}
              />
              {hasNextPage &&
                hasSelectedFacilities &&
                isAllowedToSeeAdmissions && (
                  <Wrapper>
                    <Button
                      size="medium"
                      color="primary"
                      disabled={
                        isFetchingNextPage ||
                        (activeFacilitiesTreeKeys &&
                          activeFacilitiesTreeKeys.length === 0)
                      }
                      onClick={() => fetchNextPage()}
                    >
                      {isFetchingNextPage ? "Loading..." : "Load More"}
                    </Button>
                  </Wrapper>
                )}
            </>
          )}
        </ThinScrollbarArea>
      </AdminLayoutFullHeightAndTwoCells>
    </>
  );
};

const mapState = (state: AppState) => {
  const {facilities} = select.adminSession.user(state);

  return {
    activeFacilitiesTreeKeys:
      select.adminSession.activeFacilitiesTreeKeys(state),
    hasSelectedFacilities: select.adminSession.hasSelectedFacilities(state),
    isAllowedToSeeAdmissions:
      Boolean(facilities.length) ||
      isResideAdmin(select.adminSession.user(state)),
  };
};

export const PageAdminAdmissions = connect(mapState)(Renderer);
