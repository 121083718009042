import React from "react";
import {Box, H1, H2, FlexColumn, FlexRow, styled} from "@reside/ui";
import {connect} from "react-redux";
import Div100vh from "react-div-100vh";

import {Dispatch} from "../../store";
import {LayoutContent} from "../../atoms/layout";
import {IconButton} from "../../atoms/icon-button";
import {VerifyAnswersForm} from "./atoms/verify-answers-form";

type DispatchProps = Readonly<{
  setToken: (token: string) => void;
  onLogout: () => void;
}>;

export const PageAdmissionVerifyAnswersRenderer = ({
  setToken,
  onLogout,
}: DispatchProps) => (
  <Layout>
    <LayoutContentColumn>
      <FlexRow>
        <img src="/assets/reside_logo.svg" width="250" alt="Reside" />
      </FlexRow>
      <div>
        <Box textAlign="center" marginTop={30}>
          <H1 fontSize={[5, 6, 7]} lineHeight={["32px", "40px", "70px"]}>
            Welcome to the Reside Resident Experience
          </H1>
        </Box>
        <Body>
          <Center>
            <Box textAlign="center" marginBottom={20}>
              <H2>Let’s get started...</H2>
            </Box>
            <VerifyAnswersForm
              onVerified={setToken}
              onUnauthorized={onLogout}
            />
          </Center>
        </Body>
      </div>
      <footer>
        <IconButton background="white" iconName="house" onClick={onLogout}>
          Exit
        </IconButton>
      </footer>
    </LayoutContentColumn>
  </Layout>
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setToken: dispatch.admissionSession.setToken,
  onLogout: dispatch.admissionSession.logout,
});

export const PageAdmissionVerifyAnswers = connect(
  null,
  mapDispatchToProps,
)(PageAdmissionVerifyAnswersRenderer);

const Layout = styled(Div100vh)`
  overflow-y: auto;
  &::after {
    content: "";
    background-image: url("/assets/getStartedBg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const LayoutContentColumn = styled(LayoutContent)`
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const Body = styled(FlexColumn)`
  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    flex-direction: row;
`;
