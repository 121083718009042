import {UserDto} from "@reside/reside-api-app";

export function userAnnounceKit({id, firstName, lastName, email}: UserDto) {
  return {
    id,
    firstName,
    lastName,
    email,
  };
}

export function segmentationDataAnnounceKit({
  role,
  facilities,
  organization,
}: UserDto) {
  return {
    roleName: role.name,
    facilities: facilities?.map(facility => facility.name).join(","), // AnnounceKit only supports string fields for segmentation
    organization: organization?.name,
  };
}
