import React from "react";
import {Redirect, BrowserRouter, Router, Switch} from "react-router-dom";
import {FRONTEND_BASE_NAME} from "../config";
import {SentryRoute as Route} from "../utils/sentryRoute";

import {AdmissionRoutes} from "./AdmissionRoutes";
import {AdminRoutes} from "./AdminRoutes";
import {ADMISSION_WELCOME_ROUTE} from "../pages/page-admission-welcome/route";

import {PagePasswordChange} from "../pages/page-password-change";
import {PagePasswordReset} from "../pages/page-password-reset";
import {PageConfirmNewUser} from "../pages/page-confirm-new-user";
import {history} from "../utils/history";

export const AppRouter = () => (
  <BrowserRouter basename={FRONTEND_BASE_NAME}>
    <Router history={history}>
      <Switch>
        <Route path="/admission" component={AdmissionRoutes} />
        <Route path="/admin" render={AdminRoutes} />
        <Route path="/request-reset-email" component={PagePasswordReset} />
        <Route path="/confirm-new-user/:token" component={PageConfirmNewUser} />
        <Route path="/confirm-password/:token" component={PagePasswordChange} />
        <Redirect from="/" to={ADMISSION_WELCOME_ROUTE} />
      </Switch>
    </Router>
  </BrowserRouter>
);
