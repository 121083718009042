import {useQuery} from "react-query";
import {DocumentService} from "../DocumentService";

export const useTemplateNamesQuery = (queryConfig = {}) =>
  useQuery(
    "templateNames",
    async () => {
      const {data} = await DocumentService.getTemplateNames();
      return data;
    },
    queryConfig,
  );
