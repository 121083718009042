import React from "react";
import {styled, Icon, BlankButton} from "@reside/ui";
import "react-s-alert/dist/s-alert-default.css";

import "./AlertWrapper.scss";

type Props = Readonly<{
  classNames: string;
  id: string;
  styles: any;
  message: any;
  handleClose: () => void;
}>;

export const AlertWrapper = ({
  classNames,
  id,
  styles,
  message,
  handleClose,
}: Props) => (
  <Wrapper className={classNames} id={id} style={styles}>
    <Message className="s-alert-box-inner">{message}</Message>
    <IconButton onClick={handleClose}>
      <Icon name="close" size={22} />
    </IconButton>
  </Wrapper>
);

const Wrapper = styled.div`
  ${({theme}) => theme.media.md} {
    min-width: 400px;
    border-radius: 5px;
  }
  z-index: 1010;
`;

const Message = styled.div`
  ${({theme}) => theme.media.md} {
    padding-right: 15px;
  }
`;

const IconButton = styled(BlankButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
