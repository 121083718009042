import {cx} from "@emotion/css";
import {isEqual} from "lodash/fp";
import {theme, Button, Box, Icon, Text} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";

import {AvatarCell, TextCell} from "../../../atoms/list-table/SharedCells";
import {Sort} from "../../../services/api/pagination";
import {history} from "../../../utils/history";
import {SORT_DIRECTION} from "../../../utils/constants";
import {RoleCell, UserStatusCell} from "../../../atoms/list-table/UsersCells";
import {ContactCell} from "../../../atoms/list-table/ContactCell";

type UsersTableProps = {
  sort: Sort;
  onChangeSort: (sort?: Sort) => void;
  users: ReadonlyArray<TableUserDto>;
  showLoadMore: boolean;
  onLoadMore: () => void;
};

export const UsersTable = ({
  sort,
  users,
  showLoadMore,
  onChangeSort,
  onLoadMore,
}: UsersTableProps) => {
  if (users.length === 0) {
    return (
      <EmptyTable noRowsMessage="No users found with these parameters..." />
    );
  }

  return (
    <>
      <table className={cx("list-table")}>
        <TableHeader sort={sort} onChangeSort={onChangeSort} />
        <TableBody users={users} />
      </table>
      {showLoadMore && (
        <Box flexDirection="row" justifyContent="space-around">
          <Button color="primary" onClick={onLoadMore} testId="loadMoreBtn">
            Load More
          </Button>
        </Box>
      )}
    </>
  );
};

type TableHeaderProps = {
  sort: Sort;
  onChangeSort: (sort?: Sort) => void;
};

const TableHeader = ({sort, onChangeSort}: TableHeaderProps) => {
  const nextSortDirection: any = {
    [SORT_DIRECTION.ASC]: SORT_DIRECTION.DESC,
    [SORT_DIRECTION.DESC]: SORT_DIRECTION.ASC,
    null: SORT_DIRECTION.ASC,
  };

  const setSortProperties = (properties: string[]) => {
    const direction = isEqual(sort.properties, properties)
      ? nextSortDirection[sort.direction]
      : "ASC";

    if (direction) {
      onChangeSort({properties, direction});
    } else {
      onChangeSort({properties: [], direction: "DESC"});
    }
  };

  const getSortIcon = (properties: string[]) => {
    if (isEqual(sort.properties, properties)) {
      switch (sort.direction) {
        case "ASC":
          return SortUpIcon;
        case "DESC":
          return SortDownIcon;
        default:
          return UnsortedIcon;
      }
    }
    return UnsortedIcon;
  };

  const headerCells = headerDefinition
    .map(item =>
      item.sortProperties
        ? {
            ...item,
            onClick: () => setSortProperties(item.sortProperties),
            getSortIcon,
            sorted: sort.properties === item.sortProperties,
          }
        : item,
    )
    .map((props, index) => <TableHeaderCell key={index} {...props} />);

  return (
    <thead>
      <tr>{headerCells}</tr>
    </thead>
  );
};

const headerDefinition: HeaderCellProps[] = [
  {
    label: "User",
    sortProperties: ["firstName", "lastName"],
  },
  {
    label: "Organization",
    sortProperties: ["assignedOrganization.organization"],
  },
  {
    label: "Facility",
    sortProperties: ["assignedFacilities.facility"],
  },
  {
    label: "Role",
    sortProperties: ["role"],
  },
  {
    label: "Status",
    sortProperties: ["status"],
  },
  {
    label: "Contact",
    sortProperties: ["phone", "email"],
  },
];

export const initialSortProperties = headerDefinition[0].sortProperties;

const SortUpIcon = (
  <Icon
    name="sort-up"
    className="listtable-0-52"
    color={theme.color.gray100}
    size={9}
    aria-hidden
  />
);

const SortDownIcon = (
  <Icon
    name="sort-down"
    className="listtable-0-52"
    color={theme.color.gray100}
    size={9}
    aria-hidden
  />
);

const UnsortedIcon = (
  <Icon
    name="arrows"
    className="listtable-0-52"
    color={theme.color.gray100}
    size={9}
    aria-hidden
  />
);

type HeaderCellProps = {
  label: string;
  sortProperties?: string[];
  width?: number;
  onClick?: () => void;
  getSortIcon?: (sortProperties: string[]) => JSX.Element | null;
  sorted?: boolean;
};

const TableHeaderCell = ({
  label,
  width,
  sortProperties,
  getSortIcon,
  onClick,
  sorted,
}: HeaderCellProps) => (
  <th
    className={cx(
      sortProperties ? "sortable" : "noSortable",
      sorted ? "sorting" : "",
    )}
    onClick={() => onClick?.()}
    style={{width: width + "%"}}
    data-test-id={`header-${label}`}
  >
    {label}
    {sortProperties && getSortIcon?.(sortProperties)}
  </th>
);

const TableBody = ({users}: {users: ReadonlyArray<TableUserDto>}) => {
  const rows = users.map((user: any) => (
    <TableRow
      key={user.id}
      data={user}
      onRowClick={user => history.push(`/admin/users/${user.id}/edit`)}
    />
  ));

  return <tbody>{rows}</tbody>;
};

type TableRowProps = {
  data: TableUserDto;
  onRowClick: (item: TableUserDto) => void;
};

const TableRow = ({data, onRowClick}: TableRowProps) => (
  <>
    <tr
      className={cx("list-table__row", "list-table__row--clickable")}
      onClick={() => onRowClick(data)}
    >
      <td>
        <AvatarCell data={data} />
      </td>
      <td>
        <OrganizationCell data={data} />
      </td>
      <td>
        <FacilityCell data={data} />
      </td>
      <td style={{width: 200}}>
        <RoleCell data={data.role} />
      </td>
      <td style={{width: 150}}>
        <UserStatusCell data={data.status} />
      </td>
      <td style={{width: 200}}>
        <ContactCell data={data} />
      </td>
    </tr>
    <tr className="list-table__empty-row">
      <td colSpan={6} />
    </tr>
  </>
);

const OrganizationCell = (props: {data: TableUserDto}) =>
  props.data.organization ? (
    <TextCell>{props.data.organization.name}</TextCell>
  ) : (
    <TextCell subtle>-</TextCell>
  );

const FacilityCell = ({data: {facilities}}: {data: TableUserDto}) => {
  if (!facilities || facilities.length === 0) {
    return <TextCell subtle>-</TextCell>;
  }

  if (facilities.length === 1) {
    return <TextCell>{facilities[0].name}</TextCell>;
  }

  return <TextCell subtle>{facilities.length} Facilities</TextCell>;
};

const EmptyTable = (props: {noRowsMessage: string}) => (
  <table className={cx("list-table")}>
    <tbody>
      <tr>
        <td className="table__no-rows-renderer">
          <Text fontSize="1.25em" fontWeight="medium" color="gray100">
            {props.noRowsMessage}
          </Text>
        </td>
      </tr>
    </tbody>
  </table>
);
