import React from "react";
import { SelectField } from "../select-field";
export var GenderField = function (_a) {
    var label = _a.label, name = _a.name, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (React.createElement(SelectField, { disabled: disabled, getOptionLabel: function (option) { return option; }, getOptionValue: function (option) { return option.toUpperCase(); }, label: label, name: name, options: Object.values(GENDERS) }));
};
export var GENDERS;
(function (GENDERS) {
    GENDERS["MALE"] = "Male";
    GENDERS["FEMALE"] = "Female";
    GENDERS["UNKNOWN"] = "Unknown";
})(GENDERS || (GENDERS = {}));
