import React from "react";
import {Formik} from "formik";
import {UserDto} from "@reside/reside-api-app";

import {Draft, SlideBlock, UIState} from "../../admission-explorer/model";
import {isClerk, isResideAdmin} from "../../../utils/helpers/users";
import {Flag} from "../flag";
import {SlideCard} from "../slide-card";
import {getInitialValuesForSection, isSlideVisible} from "../helpers";
import {InternalNotes} from "../../internal-notes";

import "./Slides.scss";

export const Slides = (props: {
  user: UserDto;
  uiState: UIState;
  draftActions: any;
  draft: Draft<SlideBlock>;
  notes: any;
}) => {
  const visibleSlides = props.draft.slides.filter(slide =>
    isSlideVisible(
      slide,
      props.uiState.sectionId,
      props.uiState.showIncompleteSlidesOnly,
    ),
  );

  const postNote = (slideId: string) => async (note: any) => {
    return await props.draftActions.postNote({
      slideId: slideId,
      note: note,
      user: props.user,
    });
  };

  return (
    <div className="slides-0-37">
      {visibleSlides.map((slide, index) => {
        const flag = slide.state.flag
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            props.draft.flags[slide.state.flag]
          : null;
        const renderInternalNotes = () => (
          <div className="slides-0-38">
            <InternalNotes
              notes={props.notes[slide.id] || []}
              onPost={postNote(slide.id)}
              onRemove={props.draftActions.removeNote}
              slideIndex={index}
            />
          </div>
        );

        return (
          <Formik
            key={slide.id}
            initialValues={getInitialValuesForSection(
              visibleSlides,
              props.draft.answers,
            )}
            onSubmit={() => {}}
          >
            {() => (
              <div key={slide.id} className="slides-0-39">
                <SlideCard slide={slide} />
                <div className="slides-0-40">
                  <Flag
                    flag={flag}
                    onFlagDelete={props.draftActions.unflagSlide}
                    deleteDisabled={isResideAdmin(props.user)}
                    slideIndex={index}
                  />
                  {isClerk(props.user) && renderInternalNotes()}
                </div>
              </div>
            )}
          </Formik>
        );
      })}
    </div>
  );
};
