import React, {memo} from "react";

import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsTimeToCloseByFacility,
  withCompare,
  secondsToTimeDuration,
} from "./helpers";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsTimeToCloseByFacilityChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const barData = dataProcess_AnalyticsTimeToCloseByFacility(data);

    return (
      <BarChart
        data={barData.stack}
        max={barData.max}
        labelLeft="Facility name"
        labelRight="Avg. time"
        full={isFullReport}
        tickFormat={seconds => secondsToTimeDuration(seconds)}
        axTickFormat={seconds => secondsToTimeDuration(seconds)}
        hoverDataPreprocessor={data => [data[0]]}
      />
    );
  },
);

export const TIME_TO_CLOSE_BY_FACILITY_CHART_ID =
  "time-to-close-by-facility" as const;

export const AnalyticsTimeToCloseByFacility = (props: Props) => (
  <RequiredUserPermission
    permission={
      TableUserDto.PermissionsEnum.COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED
    }
  >
    <div className="chart-header">
      <CardTitle
        title={"Average Completion Time per Admission"}
        subtitle="by Facility"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.timeToCloseFromSignatureByFacility,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled
    >
      {(data, isFullReport) => (
        <AnalyticsTimeToCloseByFacilityChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
