import React from "react";
import {css} from "@emotion/css";

import {styled, Text, Icon} from "@reside/ui";

export const ContactCell = ({
  data,
}: {
  data: Partial<{phone: string; email: string}>;
}) => {
  const {phone, email} = data;

  return (
    <Space>
      {phone && (
        <div>
          <LabelIcon size={14} name="phone" />
          <Text>{phone}</Text>
        </div>
      )}
      {email && (
        <div>
          <LabelIcon size={14} name="email" />
          <Text
            className={css`
              word-break: break-all;
            `}
          >
            {email}
          </Text>
        </div>
      )}
    </Space>
  );
};

const LabelIcon = styled(Icon)`
  color: ${({theme}) => theme.color.gray100};
  margin-right: 6px;
`;

export const Space = styled.div`
  line-height: 20px;
`;
