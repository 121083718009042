import React from "react";
import RcPagination, {PaginationProps} from "rc-pagination";
import "rc-pagination/assets/index.css";

import "./style.scss";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const locale = require("rc-pagination/lib/locale/en_US");

export const Pagination = (props: PaginationProps) => (
  <RcPagination {...props} />
);

Pagination.defaultProps = {
  locale,
};
