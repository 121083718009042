import React, {Component} from "react";
import {styled} from "@reside/ui";

import {Trigger} from "./atoms/Trigger";
import {NoteForm} from "./atoms/NoteForm";
import {NotesList} from "./atoms/NotesList";

export class InternalNotes extends Component<
  any,
  {listIsOpen: boolean; formIsOpen: boolean}
> {
  state = {
    listIsOpen: false,
    formIsOpen: false,
  };

  toggleList = () => {
    this.setState({listIsOpen: !this.state.listIsOpen});
  };

  toggleForm = () => {
    this.setState({
      formIsOpen: !this.state.formIsOpen,
      listIsOpen: !this.state.listIsOpen,
    });
  };

  submitForm = async ({note}: {note: string}) => {
    await this.props.onPost(note);
    this.toggleForm();
  };

  removeNote = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      await this.props.onRemove(id);
    }
  };

  renderNoteForm = () =>
    this.state.formIsOpen && (
      <NoteForm
        onSubmit={this.submitForm}
        onClose={this.toggleForm}
        slideIndex={this.props.slideIndex}
      />
    );

  renderNotesList = () =>
    this.state.listIsOpen && (
      <NotesList
        notes={this.props.notes}
        onClose={this.toggleList}
        onAddNote={this.toggleForm}
        onRemoveNote={this.removeNote}
        slideIndex={this.props.slideIndex}
      />
    );

  renderTrigger = () =>
    !this.state.formIsOpen &&
    !this.state.listIsOpen && (
      <Trigger
        onOpen={this.toggleList}
        notesCnt={this.props.notes.length}
        slideIndex={this.props.slideIndex}
      />
    );

  render() {
    return (
      <Container>
        {this.renderNoteForm()}
        {this.renderNotesList()}
        {this.renderTrigger()}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  border: 1px solid #ebedf0;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
`;
