import {DataNode} from "rc-tree/lib/interface";

export const modifyAndCheckFacilitiesTree = (
  facilitiesTree: ReadonlyArray<DataNode>,
) => {
  if (!Array.isArray(facilitiesTree)) {
    return {
      singleOrg: true,
      singleFacility: true,
      modifiedFacilityTree: [],
    };
  }
  const singleOrg = facilitiesTree.length === 1;

  const singleFacility =
    facilitiesTree.reduce((acc, cur) => acc + cur?.children?.length ?? 0, 0) ===
    1;

  const modifiedFacilityTree = facilitiesTree.map(parent => ({
    ...parent,
    className: `facility-parent ${singleOrg ? "hidden-parent" : ""}`,
    checkable: !singleOrg,
  }));

  return {singleOrg, singleFacility, modifiedFacilityTree};
};
