import {BlockField} from "@reside/ui";
import {Form, Formik} from "formik";
import {EventTrigger} from "../../services/HubAndSpokeService";
import {
  CreateFormControlWithReference,
  CreateQuestionReferenceFromTemplateField,
} from "./helpers";

export const EventTriggerViewerForm = ({
  eventTrigger,
}: {
  eventTrigger: EventTrigger;
}) => {
  const templateField = eventTrigger.template_field;
  const reference = CreateQuestionReferenceFromTemplateField(templateField);
  const block = CreateFormControlWithReference(reference);

  return (
    <Formik
      initialValues={{
        [templateField.question_id]: undefined,
      }}
      onSubmit={() => {}}
    >
      {({values}) => {
        const isFulfilled = Array.isArray(values[templateField.question_id])
          ? values[templateField.question_id].includes(
              eventTrigger.template_field.answer_id,
            )
          : values[templateField.question_id] ===
            eventTrigger.template_field.answer_id;

        return (
          <Form>
            <BlockField block={block as any} />
            {isFulfilled && (
              <div>
                <p>
                  Upload "{eventTrigger.document}" document to{" "}
                  {eventTrigger.sftp_config.hostname}
                </p>
                <p>Document path: {eventTrigger.document_path}</p>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
