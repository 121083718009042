var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
import { H2, Text } from "../text";
import { Card } from "../card";
export var FormCard = function (_a) {
    var title = _a.title, description = _a.description, children = _a.children;
    return (React.createElement(StyledCard, null,
        React.createElement(H2, null, title),
        description && React.createElement(Text, { fontSize: 16 }, description),
        React.createElement(Space, null, children)));
};
var StyledCard = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", "px;\n  margin-bottom: ", "px;\n"], ["\n  padding: ", "px;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space[7];
}, function (_a) {
    var theme = _a.theme;
    return theme.space[5];
});
var Space = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: ", "px;\n"], ["\n  margin-top: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space[5];
});
var templateObject_1, templateObject_2;
