import {PreflightSlideBlock, ValidationErrors} from "../../types";

export const selectMenuProps = ({
  slides = [],
  uiState = {activeSlideIndex: 0},
  uiActions = {setActiveSlideIndex: index => {}},
  hasErrorsVisible = false,
}: {
  slides?: PreflightSlideBlock[];
  uiState?: {activeSlideIndex: number};
  uiActions?: {setActiveSlideIndex: (index: number) => void};
  hasErrorsVisible?: boolean;
}) => ({
  slides: slides.map(
    (
      slide,
      index: number,
    ): {
      title: {
        translationKey: string;
      };
      onClick: () => void;
      isActive: boolean;
      errors?: ValidationErrors;
      errorsCount?: number;
    } => ({
      title: slide.title,
      isActive: uiState.activeSlideIndex === index,
      onClick: () => {
        uiActions.setActiveSlideIndex(index);
      },
      errors: slide.state.errors || {},
      errorsCount: Object.keys(slide.state.errors || {}).length,
    }),
  ),
  hasErrorsVisible,
});
