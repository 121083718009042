import {ChangeEvent} from "react";
import {SelectField, Button} from "@reside/ui";
import {Form, useFormikContext} from "formik";
import {connect} from "react-redux";
import {BackdropSpinner} from "../../atoms/spinner";
import {AppState, select} from "../../store";
import {DropdownTreeSelect} from "../../atoms/dropdown-tree-select";
import {DataNode} from "rc-tree/lib/interface";
import {useEventTriggerFormFields} from "./hooks/useEventTriggerFormFields";
import {EventTriggerForm} from "../../services/HubAndSpokeService";
import {truncateAnswer} from "./helpers";
import {isEmpty} from "lodash";
import {DocumentPaths} from "./DocumentPaths";

export const EventTriggersFormFieldsRenderer = ({
  facilitiesTree,
  onSubmit,
  isLoading = false,
}: {
  facilitiesTree: ReadonlyArray<DataNode>;
  onSubmit: (values: EventTriggerForm) => void;
  isLoading: boolean;
}): JSX.Element => {
  const {
    activateBackdrop,
    templateNames,
    setTemplateName,
    templateFields,
    pdfTemplates,
    facilities,
    setFacilities,
    sftpConfigs,
  } = useEventTriggerFormFields();

  const {values, errors, setFieldValue} = useFormikContext<EventTriggerForm>();

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit({...values, facilities});
      }}
    >
      <BackdropSpinner active={activateBackdrop}>
        <SelectField
          options={templateNames.map(template => ({
            label: template,
            value: template,
          }))}
          label="Template"
          name="templateName"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            const newTemplateName = event.target.value;
            setTemplateName(newTemplateName);
            setFieldValue("template_field_id", "");
          }}
        />
        {templateFields && (
          <SelectField
            name="template_field_id"
            label="Template Field"
            options={templateFields.reduce((accumulator, templateField) => {
              if (templateField.is_available_in_rule) {
                return [
                  ...accumulator,
                  {
                    label: templateField.friendly_name
                      ? templateField.friendly_name
                      : truncateAnswer(templateField.answer_translation_text),
                    value: templateField.id,
                  },
                ];
              }
              return accumulator;
            }, [])}
          />
        )}
        {pdfTemplates && (
          <SelectField
            name="document"
            label="Document"
            options={pdfTemplates.map(pdfTemplate => ({
              label: pdfTemplate,
              value: pdfTemplate,
            }))}
          />
        )}
        <br />
        <DropdownTreeSelect
          checkedKeys={facilities || []}
          onCheck={(_checkedKeys, checkedLeafKeys) => {
            setFacilities(checkedLeafKeys);
          }}
          treeData={facilitiesTree}
          triggerLabel="Facilities"
        />
        {sftpConfigs && (
          <SelectField
            name="sftp_config_id"
            label="SFTP Config"
            options={sftpConfigs.map(sftpConfig => ({
              label: sftpConfig.name
                ? `${sftpConfig.name} - ${sftpConfig.hostname}`
                : sftpConfig.hostname,
              value: sftpConfig.id,
            }))}
          />
        )}
        <DocumentPaths />
        {
          <Button
            style={{marginTop: "30px"}}
            type="submit"
            size="medium"
            disabled={
              isLoading ||
              !(
                facilities &&
                facilities.length > 0 &&
                values.document &&
                values.sftp_config_id &&
                values.template_field_id &&
                isEmpty(errors)
              )
            }
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
        }
      </BackdropSpinner>
    </Form>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilitiesTree: select.adminSession.facilitiesTree(state),
});

export const EventTriggersFormFields = connect(mapStateToProps)(
  EventTriggersFormFieldsRenderer,
);
