import {ReactText} from "react";
import {DataNode} from "rc-tree/lib/interface";

export const summarizeTree = (
  checkedLeafKeys: ReactText[],
  leafs: DataNode[],
) => {
  if (checkedLeafKeys.length === 0) {
    return "None";
  }
  if (checkedLeafKeys.length === 1) {
    const leaf = leafs.find(({key}) => key === checkedLeafKeys[0]);

    return leaf ? leaf.title : "";
  }
  if (checkedLeafKeys.length === leafs.length) {
    return "All";
  }

  return `${checkedLeafKeys.length} selected`;
};
