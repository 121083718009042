import React from "react";
import {useFormikContext} from "formik";
import {FlexRow, Button} from "@reside/ui";

import {Space} from "../../../../atoms/space";
import {ButtonLink} from "../../../../atoms/button-link";

export const SubmitButtons = () => {
  const {submitForm, isValid, dirty, isSubmitting} = useFormikContext();

  return (
    <>
      <FlexRow></FlexRow>
      <Space>
        <ButtonLink to="/admin">Cancel</ButtonLink>
        <Button
          testId="saveProfileButton"
          color="success"
          onClick={submitForm}
          disabled={!isValid || !dirty || isSubmitting}
          type="submit"
        >
          Save
        </Button>
      </Space>
    </>
  );
};
