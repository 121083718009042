import React from "react";
import {styled, Button, Caption, FlexRow} from "@reside/ui";

import {Space} from "../../space";
import {AdmissionStatus} from "../../../services/AdmissionsService";
import {Draft} from "../../admission-explorer/model";
import {AdminPageTitle} from "../../admin-layout/AdminPageTitle";
import {PccStatusTag} from "../../pcc-status-tag";
import {
  readResidentEmailAnswer,
  readResidentNameAnswer,
  readResidentPhoneAnswer,
  readResidentPccAccountStatus,
} from "../../../models/AdmissionModel.helpers";
import {AdmissionStatusTag} from "../../admission-status-tag";
import {LayoutContent} from "../../admin-layout";
import {NotResideAdmin} from "../../admin-roles/AdminRoles";

const isEditable = (draft: Draft) =>
  [AdmissionStatus.PRE_FLIGHT, AdmissionStatus.IN_PROGRESS].some(
    status => status === draft.instance.status,
  );

type Props = Readonly<{
  draft: Draft;
  onEdit: () => void;
  onClose: () => void;
}>;

export const Header = ({draft, onEdit, onClose}: Props) => {
  const {instance} = draft;

  const {answers, status, code} = instance;

  const {admission_cancellation} = answers;

  return (
    <HeaderWrapper>
      <LayoutContent>
        <Row>
          <NameAndStatus>
            <OffsetRight>
              <AdminPageTitle>
                {readResidentNameAnswer(instance)}
              </AdminPageTitle>
            </OffsetRight>
            <OffsetRight>
              <AdmissionStatusTag status={status} />
            </OffsetRight>
            {readResidentPccAccountStatus(instance) && (
              <PccStatusTag status={readResidentPccAccountStatus(instance)} />
            )}
          </NameAndStatus>
          <Row>
            <Captions>
              <Caption fontSize={1}>Admission code: {code}</Caption>
              <Caption>Phone: {readResidentPhoneAnswer(instance)}</Caption>
              <Caption>
                E-mail address: {readResidentEmailAnswer(instance) ?? "N/A"}
              </Caption>
              {status === AdmissionStatus.CANCELLED && (
                <Caption>
                  Reason for deletion:{" "}
                  {admission_cancellation?.value?.reason || ""}
                </Caption>
              )}
            </Captions>
          </Row>
          <ButtonSpace>
            {isEditable(draft) && (
              <NotResideAdmin>
                <Button color="primary" onClick={onEdit} testId="editBtn">
                  Edit
                </Button>
              </NotResideAdmin>
            )}
            <Button
              color="primary"
              outline
              onClick={onClose}
              testId="cancelBtn"
            >
              Cancel
            </Button>
          </ButtonSpace>
        </Row>
      </LayoutContent>
    </HeaderWrapper>
  );
};

const Captions = styled.div`
  padding: 0 ${({theme}) => theme.space[1]}px;
`;

const OffsetRight = styled.div`
  margin-right: ${({theme}) => theme.space[4]}px;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  width: 100%;
  top: 70px; // 70px is the height of the header
  left: 0;
  background: ${({theme}) => theme.color.darkBlue5};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  z-index: 1;
`;

const Row = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
`;

const NameAndStatus = styled(FlexRow)`
  align-items: center;
  margin-right: ${({theme}) => theme.space[4]}px;
`;

const ButtonSpace = styled(Space)`
  flex-grow: 1;
  justify-content: flex-end;
`;
