import {Admission} from "@reside/reside-api-admission";
import {AdmissionStatusEnum} from "../../services/AdmissionsService";

export const shouldSaveAccess = (admissionStatus: AdmissionStatusEnum) =>
  (
    [
      Admission.StatusEnum.IN_PROGRESS,
      Admission.StatusEnum.SIGNED,
      Admission.StatusEnum.APPROVED,
    ] as ReadonlyArray<AdmissionStatusEnum>
  ).includes(admissionStatus);

export const shouldUpdateAdmissionState = (
  admissionStatus: Admission.StatusEnum,
) => admissionStatus === Admission.StatusEnum.IN_PROGRESS;
