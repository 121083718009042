import Axios, {CancelTokenSource} from "axios";
import {action} from "typesafe-actions";
import {Dispatch} from "redux";
import {isEqual} from "lodash";

import {AppState} from "../store";
import {
  isReportsDataPath,
  IReportingReportsPayload,
  IReportingReportsPayloadBase,
  ReportsDataPath,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  FETCH_BEGIN,
  SET_REPORTS,
  PUSH_REPORTS,
} from "./reporting-reports.common";
import {resolverReports} from "./reporting-reports.resolvers";
import {createSelectorsReports} from "./reporting-reports.selectors";

const shouldFetch = (
  state: AppState,
  path: ReportsDataPath,
  page?: number,
  perPage?: number,
): boolean => {
  const selectedState = createSelectorsReports(path).byDataPath(state);

  if (selectedState === undefined) {
    return true;
  }
  if (selectedState.fetching) {
    return false;
  }
  if (selectedState.success) {
    if (selectedState.success === JSON.stringify(path)) {
      return (
        (page &&
          selectedState.currentPage < page &&
          page < selectedState.totalPages) ||
        perPage !== selectedState.perPage
      );
    }
  }

  return true;
};

const shouldReset = (state: AppState, path: ReportsDataPath): boolean => {
  const reports = createSelectorsReports(path).byDataPath(state);

  return (
    reports.sortDirection !== path.query.sortDirection ||
    reports.sortField !== path.query.sortField ||
    !isEqual(reports.facilities, path.query.facilities)
  );
};

export const createActions = (path: ReportsDataPath) => {
  const setReports = (payload: IReportingReportsPayload) =>
    action(SET_REPORTS, payload);
  const fetchBegin = (payload: IReportingReportsPayloadBase) =>
    action(FETCH_BEGIN, payload);
  const fetchSuccess = (payload: IReportingReportsPayloadBase) =>
    action(FETCH_SUCCESS, payload);
  const fetchFailure = (payload: IReportingReportsPayloadBase) =>
    action(FETCH_FAILURE, payload);
  const pushReports = (payload: IReportingReportsPayload) =>
    action(PUSH_REPORTS, payload);

  const fetchReports =
    (
      cancelTokenSource: CancelTokenSource,
      page?: number,
      perPage?: number,
      forceReset?: boolean,
    ) =>
    async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
      if (isReportsDataPath(path)) {
        if (shouldFetch(getState(), path, page, perPage)) {
          dispatch(fetchBegin({path}));
          try {
            const reset = shouldReset(getState(), path);
            const response = await resolverReports(
              path,
              page,
              cancelTokenSource,
              perPage,
            );

            if (response.data) {
              dispatch(fetchSuccess({path}));
              if (page === undefined || reset || forceReset) {
                dispatch(
                  setReports({path, data: response.data, page, perPage}),
                );
              } else {
                dispatch(
                  pushReports({path, data: response.data, page, perPage}),
                );
              }
            } else {
              dispatch(fetchFailure({path}));
            }
          } catch {
            dispatch(fetchFailure({path}));
          }
        }
      }
    };

  const loadMore =
    () =>
    async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
      const dataState = createSelectorsReports(path).byDataPath(getState());

      if (dataState.currentPage >= dataState.totalPages) {
        return;
      }

      const cancelTokenSource = Axios.CancelToken.source();

      return fetchReports(cancelTokenSource, dataState.currentPage + 1)(
        dispatch,
        getState,
      );
    };

  const loadAllForPDF =
    () =>
    async (
      dispatch: Dispatch,
      getState: () => AppState,
    ): Promise<number | undefined> => {
      const dataState = createSelectorsReports(path).byDataPath(getState());

      if (
        dataState?.currentPage !== undefined &&
        dataState?.totalPages !== undefined &&
        dataState?.currentPage >= dataState?.totalPages
      ) {
        return;
      }

      const cancelTokenSource = Axios.CancelToken.source();

      await fetchReports(cancelTokenSource, 0, 9999, true)(dispatch, getState);

      const oldPage = dataState.currentPage;

      return oldPage;
    };

  return {
    setReports,
    fetchReports,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    pushReports,
    loadMore,
    loadAllForPDF,
  };
};
