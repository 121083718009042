import React from "react";
import {connect} from "react-redux";
import {ErrorResponseDto} from "@reside/reside-api-admission";
import Alert from "react-s-alert";

import {
  AdmissionsService,
  isMissingMandatoryChecklistItemException,
} from "../../services/AdmissionsService";

import {ModalName, useDashboardModalContext} from "./DashboardModalContext";
import {useDashboardAdmissionsContext} from "./DashboardAdmissionsContext";
import {getChecklistFormValues} from "./helper";
import {select} from "../../store";
import {ApproveModal} from "./atoms/approve-modal";
import {UserDetail} from "../../store/users/usersModel";
import {getFormValidationErrors} from "../../utils/formUtils";

type Props = Readonly<{
  user: UserDetail;
}>;

export const DashboardApproveModalRenderer = ({user}: Props) => {
  const {refreshDashboard, setAdmissionChecklistErrors} =
    useDashboardAdmissionsContext();

  const {admission, isModalOpened, toggleModal} = useDashboardModalContext(
    ModalName.APPROVE,
  );

  const admissionId = admission?.id;

  return (
    <ApproveModal
      admission={admission}
      isOpen={isModalOpened}
      onRequestClose={() => toggleModal()}
      onApprove={async data => {
        try {
          await AdmissionsService.approve(admissionId, data);

          refreshDashboard();
        } catch (resp) {
          const error: ErrorResponseDto = await resp.json();

          if (isMissingMandatoryChecklistItemException(error)) {
            setAdmissionChecklistErrors(
              admissionId,
              getFormValidationErrors(error.validationErrors),
            );

            const [firstError] = error.validationErrors;

            Alert.error(firstError.message);
          } else {
            Alert.error("Unable to approve the admission.");
          }

          throw error;
        }
      }}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        ...getChecklistFormValues(admission?.checklist ?? []),
      }}
    />
  );
};

const mapStateToProps = (state: any): Props => ({
  user: select.adminSession.user(state),
});

export const DashboardApproveModal = connect<Props>(mapStateToProps)(
  DashboardApproveModalRenderer,
);
