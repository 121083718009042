import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {Box} from "@reside/ui";

import {select} from "../../store";
import {Space} from "../space";
import {FacilityDropdownMenu} from "../facility-dropdown-menu";
import {AdminPageTitle} from "../admin-layout/AdminPageTitle";

import "./AdminActionRow.scss";

const AdminActionRowRenderer = ({
  title = null,
  filter = null,
  search = null,
  facilitySelector = null,
  actions = null,
  isResideAdmin,
}: {
  title?: ReactNode;
  filter?: ReactNode;
  search?: ReactNode;
  actions?: ReactNode;
  facilitySelector?: ReactNode;
  isResideAdmin: boolean;
}) => (
  <Box
    flexDirection="row"
    alignItems="center"
    flexShrink={0}
    className="adminactionrow-0-1"
  >
    <Box flexDirection="row" flex="2" alignItems="center">
      {facilitySelector}
      {title && <AdminPageTitle>{title}</AdminPageTitle>}
    </Box>
    <Space>
      {filter}
      {search}
      {actions}
      {!isResideAdmin && <FacilityDropdownMenu />}
    </Space>
  </Box>
);

const mapState = (state: any) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

/** Action row adjustable to suit the needs of your page. You can pass it custom components for searching, filtering or facility selector. */
export const AdminActionRow = connect(mapState)(AdminActionRowRenderer);
