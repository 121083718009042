import React from "react";
import {theme, Icon, Tooltip, styled} from "@reside/ui";

import {PdfUploadStatus} from "../../../../utils/constants";

type Props = {
  pdfUploadStatus: "PENDING" | "ERROR" | "SUCCESS";
};

const PDF_TOOLTIP_MESSAGES = {
  PENDING: "PDF is being uploaded to PointClickCare.",
  ERROR:
    "PDF upload to PointClickCare Failed. Check Activity Log in Facility Settings for more info.",
  SUCCESS: "PDF is successfully uploaded to PointClickCare.",
};

export const PdfUploadStatusIcon = ({pdfUploadStatus}: Props) => (
  <StyledTooltip content={PDF_TOOLTIP_MESSAGES[pdfUploadStatus]}>
    <Icon
      name="file-pdf"
      color={
        pdfUploadStatus === PdfUploadStatus.PENDING
          ? theme.color.gray100
          : pdfUploadStatus === PdfUploadStatus.SUCCESS
          ? theme.color.mint100
          : theme.color.deepOrange100
      }
      data-test-id="pdfStatusIcon"
    />
  </StyledTooltip>
);

const StyledTooltip = styled(Tooltip)`
  cursor: default;
`;
