import {CancelTokenSource} from "axios";
import {action, ActionType, createStandardAction} from "typesafe-actions";
import {
  SET_ANALYTICS,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  DataPath,
  IReportingAnalyticsPayload,
  isAnalyticsDataPath,
  IReportingAnalyticsPayloadBase,
  ActiveFacilities,
} from "./reporting.common";
import {Dispatch} from "redux";
import {resolverAnalytics} from "./reporting.resolvers";
import {AppState} from "../store";
import {createSelectors} from "./reporting.selectors";

export const setActiveFacilities = createStandardAction(
  "reporting/SET_ACTIVE_FACILITIES",
)<ActiveFacilities>();

const actions = {setActiveFacilities};

export type ReportAction = ActionType<typeof actions>;

const shouldFetch = (state: AppState, path: DataPath): boolean => {
  const selectedState = createSelectors(path).byDataPath(state);

  if (selectedState === undefined) {
    return true;
  }
  if (selectedState.fetching) {
    return false;
  }

  return !selectedState.success;
};

export const createActions = (path: DataPath) => {
  const setAnalytics = (payload: IReportingAnalyticsPayload) =>
    action(SET_ANALYTICS, payload);
  const fetchBegin = (payload: IReportingAnalyticsPayloadBase) =>
    action(FETCH_BEGIN, payload);
  const fetchSuccess = (payload: IReportingAnalyticsPayloadBase) =>
    action(FETCH_SUCCESS, payload);
  const fetchFailure = (payload: IReportingAnalyticsPayload) =>
    action(FETCH_FAILURE, payload);

  const fetchAnalytics =
    (cancelTokenSource: CancelTokenSource) =>
    async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
      if (isAnalyticsDataPath(path)) {
        if (shouldFetch(getState(), path)) {
          dispatch(fetchBegin({path: path}));
          try {
            const response = await resolverAnalytics(path, cancelTokenSource);

            if (response.data) {
              dispatch(fetchSuccess({path: path}));
              dispatch(setAnalytics({path: path, data: response.data}));
            } else {
              dispatch(fetchFailure({path: path, data: {}}));
            }
          } catch (e) {
            dispatch(fetchFailure({path: path, data: e}));
          }
        }
      }
    };

  return {
    setAnalytics,
    fetchAnalytics,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
  };
};
