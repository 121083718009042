import React from "react";
import {Formik, Form} from "formik";
import {RouteComponentProps} from "react-router-dom";
import {useMutation, MutationStatus} from "react-query";
import {validator} from "@reside/forms";
import {
  styled,
  PublicFormLayout,
  Button,
  Paragraph,
  PasswordField,
} from "@reside/ui";
import {ChangePasswordDto} from "@reside/reside-api-app";

import {UserService} from "../../services/UserService";
import {Error} from "../../atoms/error";
import {ButtonLink} from "../../atoms/button-link";

type ChangePasswordRouteParams = Readonly<{
  token: string;
}>;

export const PagePasswordChange = ({
  match: {
    params: {token},
  },
}: RouteComponentProps<ChangePasswordRouteParams>) => {
  const {mutate, status} = useMutation(UserService.changePassword);

  return (
    <Formik<ChangePasswordDto>
      initialValues={{
        password: "",
        confirmPassword: "",
        token,
      }}
      onSubmit={values => mutate(values)}
      validate={values =>
        validator.validateAll(
          values,
          {
            password: "required|min:8",
            confirmPassword: "required|same:password",
          },
          {
            same: "New password and confirmation password do not match.",
          },
        )
      }
    >
      {({submitForm}) => (
        <PagePasswordChangeRenderer status={status} onSubmit={submitForm} />
      )}
    </Formik>
  );
};

type Props = Readonly<{
  status: MutationStatus;
  onSubmit: () => void;
}>;

export const PagePasswordChangeRenderer = ({status, onSubmit}: Props) => (
  <PublicFormLayout
    title="Create Password"
    footer={
      <Center>
        <ButtonLink
          to="/admin"
          underline
          style={{border: "none", background: "transparent"}}
        >
          Return to Log In
        </ButtonLink>
      </Center>
    }
  >
    {status === "success" ? (
      <Center>
        <Paragraph fontWeight="medium" fontSize={3}>
          Password was changed successfully.
        </Paragraph>
      </Center>
    ) : (
      <Form>
        <PasswordField name="password" label="Password" />
        <PasswordField name="confirmPassword" label="Confirm Password" />
        {status === "error" && (
          <Error
            message={
              "Activation token has expired, ask your admin for renewal."
            }
          />
        )}
        <ButtonCenter>
          <Button
            type="submit"
            color="primary"
            disabled={status === "loading"}
            onClick={onSubmit}
          >
            {status === "loading" ? <>Submitting...</> : <>Submit</>}
          </Button>
        </ButtonCenter>
      </Form>
    )}
  </PublicFormLayout>
);

const ButtonCenter = styled.div`
  width: fit-content;
  margin: 18px auto 0;
`;

const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`;
