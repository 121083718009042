import {QuestionReferences, BranchNode, validator} from "@reside/forms";
import {
  EventTriggerForm,
  TemplateField,
} from "../../services/HubAndSpokeService";
import unflatten from "obj-unflatten";
import {PathVariablesEnum} from "./consts";
import {format, getUnixTime} from "date-fns";

const VALIDATORS = {
  document_path: "required|document-path",
};

export const truncateAnswer = (answerText: string) =>
  answerText.length > 120 ? `${answerText.substring(0, 120)}...` : answerText;

export function CreateQuestionReferenceFromTemplateField(
  templateField: TemplateField,
): QuestionReferences<BranchNode> {
  return {
    id: templateField.question_id,
    type: templateField.question_type as any,
    translationKey: templateField.question_translation_text,
    choices: [
      {
        id: templateField.answer_id,
        translationKey: templateField.answer_translation_text,
      },
    ],
  };
}

export function CreateFormControlWithReference(
  reference: QuestionReferences<BranchNode>,
) {
  return {
    id: `form:control-${reference.id}`,
    type: "form:control",
    reference,
    name: `form:control-${reference.id}`,
    state: {
      visible: true,
    },
  };
}

export const validate = (formValues: EventTriggerForm) => {
  return unflatten(validator.validateAll(formValues, VALIDATORS));
};

export const generatePathPreview = (targetPath: string): string => {
  const replaceVariables = (variable: string): string => {
    switch (variable) {
      case PathVariablesEnum.FacilityName:
        return "Example_Care";
      case PathVariablesEnum.OrganizationName:
        return "Example_Health_Inc";
      case PathVariablesEnum.ResidentName:
        return "Mickey_Jay_Johnstone";
      case PathVariablesEnum.ResidentLastName:
        return "Johnstone";
      case PathVariablesEnum.AdmissionDate:
        return format(new Date(), "yyyyMMdd");
      case PathVariablesEnum.CurrentDate:
        return format(new Date(), "yyyyMMdd");
      case PathVariablesEnum.CurrentDateTime:
        return format(new Date(), "yyyyMMddHHmmSS");
      case PathVariablesEnum.CurrentUnixTime:
        return getUnixTime(new Date()).toString();
      case PathVariablesEnum.UUID:
        return "3xAmPle-ex4m-pL3-xamp-ex4mP1e";
      default:
        return "";
    }
  };

  return targetPath.replace(/{[^}]+}/g, replaceVariables);
};
