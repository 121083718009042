import React from "react";
import {styled, Text, Icon, Toggle} from "@reside/ui";

import {NavbarIconButton} from "../navbar-top/atoms/navbar-icon-button";

type TextSizeOption = Readonly<{
  size: number;
  scale: number;
  label: string;
}>;

const SIZES: ReadonlyArray<TextSizeOption> = [
  {size: 16, scale: 1, label: "Small"},
  {size: 20, scale: 1.25, label: "Medium"},
  {size: 24, scale: 2, label: "Big"},
];

type Props = Readonly<{
  textSize: number;
  darkBackground?: boolean;
  onChange: (scale: number) => void;
}>;

export const TextSizeControl = ({
  textSize,
  darkBackground,
  onChange,
}: Props) => (
  <Toggle>
    {({isOpened, setIsOpened}) => (
      <Wrapper textSize={textSize}>
        <NavbarIconButton
          frameless
          iconName="text-size"
          testId="open-change-size-button"
          darkBackground={darkBackground}
          onClick={() => setIsOpened(!isOpened)}
        >
          Size
        </NavbarIconButton>
        {isOpened && (
          <Menu textSize={textSize}>
            {SIZES.map(({scale, size, label}) => (
              <Option
                data-test-id={`change-size-${scale}-button`}
                key={size}
                onClick={() => {
                  onChange(scale);
                  setIsOpened(!isOpened);
                }}
              >
                <Icon name="a-letter" size={size} />
                <StyledText fontWeight="normal" fontSize={`${size}px`}>
                  {label}
                </StyledText>
              </Option>
            ))}
          </Menu>
        )}
      </Wrapper>
    )}
  </Toggle>
);

TextSizeControl.defaultProps = {
  darkBackground: false,
};

const Menu = styled.div<{textSize: number}>`
  background-color: ${({theme}) => theme.color.white};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.color.primary};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  justify-content: space-around;
  top: 100%;
  right: 0;
  transform: translateY(10px);
  display: flex;
  z-index: 2;

  & > div {
    border-right: 1px solid ${({theme}) => theme.color.primary};
  }

  & > div:last-child {
    border: 0;
  }

  & > div.active {
    color: ${({theme}) => theme.color.deepOrange100};
  }

  transform: ${({textSize}) =>
    `scale(${(textSize - 1) / 4 + 1}) translate(-${textSize - 1}rem,${
      (textSize / 10) * 100
    }%)`};
`;

const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({theme}) => theme.color.primary};
  cursor: pointer;
  width: 100px;
  padding: 20px 0;

  &:hover {
    background-color: rgba(43, 183, 217, 0.15);
  }
`;

const StyledText = styled(Text)`
  padding-top: 5px;
`;

const Wrapper = styled.div<{textSize: number}>`
  position: relative;
  margin: 0 5px;

  > button {
    display: flex;
    align-items: center;

    &:hover {
      transition-delay: 100ms;
      > :is(i, span) {
        transition-delay: inherit;
      }
    }
    i {
      font-size: Min(${({textSize}) => textSize * 1.375}rem, 2rem);
    }
    span {
      font-size: Min(${({textSize}) => textSize}rem, 1.45rem);
    }
  }
`;
