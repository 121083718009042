import {Button, Tag, styled} from "@reside/ui";
import {Theme} from "@emotion/react";
import {TextCell} from "../../../atoms/list-table/SharedCells";

export const DocumentUploadContainer = styled.section`
  padding: ${({theme}: {theme: Theme}) => theme.space[4]}px;
  border: 1px solid ${({theme}: {theme: Theme}) => theme.color.gray10};
  border-radius: ${({theme}: {theme: Theme}) => theme.space[1]}px;
  background-color: ${({theme}: {theme: Theme}) => theme.color.white};
`;

export const UploadButton = styled(Button)`
  margin-top: 0.6rem;
  padding-block: 0.7rem !important;
`;

export const AvailableButton = styled(Button)<{available: string}>`
  ${({available, theme}) =>
    available
      ? `

    &:hover {
        span {
        text-decoration: underline;
      }
    }
    `
      : `
    transform: translateY(0) !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;

    span {
      color: ${theme.color.darkBlue100};
    }
  `}
`;

export const TextCellStyle = styled(TextCell)`
  white-space: nowrap;
`;

export const CapitalizedTextTag = styled(Tag)`
  text-transform: capitalize;
`;
