import React, {memo} from "react";
import {Box} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {
  AggregationManagedMedicaidRate,
  AggregationMissingMedicaidRate,
  AggregationReadmissionRate,
} from "../Aggregations";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
  Resolution,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsAdmissionsTotalResidentsChart = memo(({data}: ChartProps) => (
  <LineChart data={lineGraphData(data)} yFormat={y => `${y}`} />
));

export const ADMISSIONS_TOTAL_RESIDENTS_CHART_ID =
  "admissions-total-residents" as const;

export const AnalyticsAdmissionsTotalResidents = (props: Props) => (
  <>
    <Box flexDirection="row" flexWrap="wrap" marginBottom="40px">
      <AggregationReadmissionRate
        dateRange={props.dateRange}
        facilities={props.facilities}
        compareDateRange={props.dateRange}
      />
      <AggregationManagedMedicaidRate
        dateRange={props.dateRange}
        facilities={props.facilities}
        compareDateRange={props.dateRange}
      />
      <AggregationMissingMedicaidRate
        dateRange={props.dateRange}
        facilities={props.facilities}
        compareDateRange={props.dateRange}
      />
    </Box>
    <RequiredUserPermission
      permission={TableUserDto.PermissionsEnum.ANALYTICS_ADMISSION_IN_TOTAL}
    >
      <div className="chart-header">
        <CardTitle small title="TOTAL NUMBER OF RESIDENTS OVER TIME" />
        <CompareDateLabelsWrapper>
          <CompareDateLabel dateRange={props.dateRange} global />
        </CompareDateLabelsWrapper>
      </div>
      <ReportingContext
        dataPaths={withCompare({
          what: ReportingContextType.analyticsWithResolution,
          type: AnalyticsType.totalAdmissions,
          facilities: props.facilities,
          query: {...props.dateRange, resolution: Resolution.DAY},
        })}
        onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
        isFullReport={props.reportingBlockSetters.isFullReport}
        enabled={props.reportingBlockSetters.enabled}
      >
        {(data, isFullReport) => (
          <AnalyticsAdmissionsTotalResidentsChart
            data={data}
            isFullReport={isFullReport}
          />
        )}
      </ReportingContext>
    </RequiredUserPermission>
  </>
);
