import React from "react";
import {Provider} from "react-redux";
import {ThemeProvider} from "@reside/ui";
import * as Sentry from "@sentry/react";

import {store, PersistGate} from "./store";
import {AppRouter} from "./routers/AppRouter";
import {AppUpdateReload} from "./hooks/useAppUpdateReload";
import {I18nProvider} from "./atoms/i18n-provider";
import {ErrorBoundary} from "./atoms/error-boundary";
import {Alert} from "./atoms/alert";
import {ReactQueryClientProvider} from "./atoms/react-query-client-provider";
import {NetworkStatusBanner} from "./atoms/network-status-banner";

export const Renderer = () => (
  <Provider store={store}>
    <ReactQueryClientProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <I18nProvider>
            <AppUpdateReload />
            <NetworkStatusBanner />
            <PersistGate>
              <AppRouter />
              <Alert />
            </PersistGate>
          </I18nProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </ReactQueryClientProvider>
  </Provider>
);

export const App = Sentry.withProfiler(Renderer);
