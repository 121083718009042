import {useMutation} from "react-query";
import {Formik, Form} from "formik";
import {validator} from "@reside/forms";
import {PublicFormLayout, Button, TextField, PasswordField} from "@reside/ui";

import {AuthService} from "../../services/AuthService";
import {dispatch} from "../../store";
import {DocumentTitle} from "../../atoms/document-title";
import {RequestError} from "../../atoms/error";
import {AutoLogoutInfoBar} from "../../atoms/auto-logout";
import {ButtonCenter, StyledLink} from "./style";

export const PageAdminLogin = () => {
  const {mutate, error, isLoading} = useMutation(AuthService.signIn, {
    onMutate: () => {
      dispatch.adminSession.reset();
    },
    onSuccess: ({data: {user, token}}) => {
      dispatch.adminSession.setUser({
        user,
        isUserLogin: true,
      });
      dispatch.adminSession.setToken({
        token,
        isLoginToken: true,
      });
    },
  });

  return (
    <Formik
      initialValues={{username: "", password: ""}}
      onSubmit={values => {
        mutate({
          ...values,
          username: values.username.toLowerCase(),
        });
      }}
      validate={values =>
        validator.validateAll(values, {
          username: "required",
          password: "required",
        })
      }
    >
      {() => (
        <>
          <AutoLogoutInfoBar />
          <DocumentTitle title="Reside - Admin - Log In" />
          <PublicFormLayout title="Log In">
            <Form>
              <TextField
                name="username"
                type="email"
                label="Email Address"
                testId="input-email"
              />
              <PasswordField
                name="password"
                label="Password"
                testId="input-password"
              />
              <StyledLink
                to="/request-reset-email"
                data-test-id="forgot-password"
              >
                Forgot password?
              </StyledLink>
              {error && (
                <RequestError payload={error} data-test-id="validator-error" />
              )}
              <ButtonCenter>
                <Button
                  testId="loginButton"
                  type="submit"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Log in"}
                </Button>
              </ButtonCenter>
            </Form>
          </PublicFormLayout>
        </>
      )}
    </Formik>
  );
};
