import {createModel} from "@rematch/core";
import update from "immutability-helper";
import {select} from "../store";
import {
  findNextVisibleSlideIndex,
  findPreviousVisibleSlideIndex,
  getPageIndexFromUrl,
  navigateToPageIndex,
} from "./AdmissionExperienceUiModel.helpers";

const DEFAULT_STATE = {
  flagUiIsOpen: false,
  showIncompleteSlidesOnly: false,
  paused: false,
  signModalIsOpen: false,
  slideIdx: 0,
  textSize: 1,
  tocIsOpen: false,
  slideStartTime: Date.now(),
};

export const model = createModel({
  name: "admissionExperienceUi",

  state: {},

  selectors: (slice, createSelector, hasProps) => ({
    get: hasProps((state, {admissionId}) => {
      return slice(state => ({
        ...DEFAULT_STATE,
        ...state[admissionId],
        slideIdx: getPageIndexFromUrl(),
      }));
    }),
  }),

  reducers: {
    "admissionSession/startSession": (state, payload) => {
      return update(state, {[payload.admissionId]: {$set: DEFAULT_STATE}});
    },

    "admissions/findAsync": (state, payload) => {
      return update(state, {[payload.admissionId]: {$set: DEFAULT_STATE}});
    },

    setSlideIdx(state, {id, slideIdx}) {
      return update(state, {[id]: {slideIdx: {$set: slideIdx}}});
    },

    toggleShowIncompleteSlidesOnlyFlag(state, payload) {
      return update(state, {
        [payload.id]: {showIncompleteSlidesOnly: slice => !slice},
      });
    },

    pause(state, payload) {
      return update(state, {[payload.id]: {paused: {$set: true}}});
    },

    resume(state, payload) {
      return update(state, {[payload.id]: {paused: {$set: false}}});
    },

    openToc(state, payload) {
      return update(state, {[payload.id]: {tocIsOpen: {$set: true}}});
    },

    closeToc(state, payload) {
      return update(state, {[payload.id]: {tocIsOpen: {$set: false}}});
    },

    setTextSize(state, payload) {
      return update(state, {
        [payload.id]: {textSize: {$set: payload.size}},
      });
    },

    openFlagUi(state, payload) {
      return update(state, {[payload.id]: {flagUiIsOpen: {$set: true}}});
    },

    closeFlagUi(state, payload) {
      return update(state, {[payload.id]: {flagUiIsOpen: {$set: false}}});
    },

    openSignModal(state, payload) {
      return update(state, {
        [payload.id]: {signModalIsOpen: {$set: true}},
      });
    },

    closeSignModal(state, payload) {
      return update(state, {
        [payload.id]: {signModalIsOpen: {$set: false}},
      });
    },

    setSlideStartTime(state, payload) {
      return update(state, {
        [payload.id]: {slideStartTime: {$set: payload.time}},
      });
    },
  },

  effects: models => ({
    refreshPosition({admissionId}, rootState) {
      const {instance} = select(models => ({
        instance: models.admissions.instance({admissionId}),
        slides: models.admissions.slides({admissionId}),
      }))(rootState);

      models.admissionExperienceUi.goToSlideEffect({
        id: admissionId,
        slideIdx: instance.lastViewedSlideIdx,
      });
    },
    goToSlideEffect({id, slideIdx}) {
      navigateToPageIndex(slideIdx);
      models.admissionExperienceUi.setSlideIdx({id, slideIdx});
      models.admissions.setLastViewedSlideIdx({
        admissionId: id,
        lastViewedSlideIdx: slideIdx,
      });
    },
    goToErrorSlideEffect({id, slideId}, rootState) {
      const {slides} = select(models => ({
        slides: models.admissions.slides({admissionId: id}),
      }))(rootState);

      const slideIdx = slides.findIndex(({id}) => id === slideId);

      if (slideIdx >= 0) {
        navigateToPageIndex(slideIdx, true);

        models.admissionExperienceUi.setSlideIdx({id, slideIdx});
        models.admissions.setLastViewedSlideIdx({
          admissionId: id,
          lastViewedSlideIdx: slideIdx,
        });
      }
    },
    goToPrevOrNextSlideEffect({id, direction}, rootState) {
      const {slides, uiState} = select(models => ({
        slides: models.admissions.slides({admissionId: id}),
        uiState: models.admissionExperienceUi.get({admissionId: id}),
      }))(rootState);
      const findIndex = {
        previous: findPreviousVisibleSlideIndex,
        next: findNextVisibleSlideIndex,
      }[direction];
      const slideIdx = findIndex(uiState, slides);

      models.admissionExperienceUi.goToSlideEffect({id, slideIdx});
    },
    goToNextSlideEffect({id}) {
      models.admissionExperienceUi.goToPrevOrNextSlideEffect({
        id,
        direction: "next",
      });
    },
    goToPreviousSlideEffect({id}) {
      models.admissionExperienceUi.goToPrevOrNextSlideEffect({
        id,
        direction: "previous",
      });
    },
  }),
});

export function bindUiActionsForInstance(admissionId) {
  return dispatch => ({
    setNextSlideIndex: async () => {
      await dispatch.admissionExperienceUi.goToNextSlideEffect({
        id: admissionId,
      });
    },
    goToNextSlide: async () => {
      await dispatch.admissionExperienceUi.goToNextSlideEffect({
        id: admissionId,
      });

      await dispatch.admissions.patchLastViewedSlideIndex({
        admissionId,
      });
    },
    goToPreviousSlide: async () => {
      await dispatch.admissionExperienceUi.goToPreviousSlideEffect({
        id: admissionId,
      });

      await dispatch.admissions.patchLastViewedSlideIndex({
        admissionId,
      });
    },
    goToSlide: async slideIdx => {
      await dispatch.admissionExperienceUi.goToSlideEffect({
        id: admissionId,
        slideIdx,
      });

      await dispatch.admissions.patchLastViewedSlideIndex({
        admissionId,
      });
    },
    goToErrorSlide: async slideId => {
      await dispatch.admissionExperienceUi.goToErrorSlideEffect({
        id: admissionId,
        slideId,
      });

      await dispatch.admissions.patchLastViewedSlideIndex({
        admissionId,
      });
    },
    pause: async () => {
      dispatch.admissionExperienceUi.pause({id: admissionId});
    },
    resume: () => dispatch.admissionExperienceUi.resume({id: admissionId}),
    closeToc: () => dispatch.admissionExperienceUi.closeToc({id: admissionId}),
    openToc: () => dispatch.admissionExperienceUi.openToc({id: admissionId}),
    setTextSize: size =>
      dispatch.admissionExperienceUi.setTextSize({id: admissionId, size}),
    openFlagUi: () =>
      dispatch.admissionExperienceUi.openFlagUi({id: admissionId}),
    closeFlagUi: () =>
      dispatch.admissionExperienceUi.closeFlagUi({id: admissionId}),
    toggleShowIncompleteSlidesOnlyFlag: () =>
      dispatch.admissionExperienceUi.toggleShowIncompleteSlidesOnlyFlag({
        id: admissionId,
      }),
    openSignModal: () =>
      dispatch.admissionExperienceUi.openSignModal({id: admissionId}),
    closeSignModal: () =>
      dispatch.admissionExperienceUi.closeSignModal({id: admissionId}),
  });
}
