import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
} from "react";

import {Admission} from "@reside/reside-api-admission";
import {AdmissionFromSearch} from "../../../services/AdmissionsService";

const context = createContext({
  admission: undefined,
  toggleModal: (admission?: Admission | AdmissionFromSearch) => {},
});

export const useLinkResidentToAdmissionContext = () => useContext(context);

export const LinkResidentToAdmissionModalProvider: FunctionComponent = ({
  children,
}) => {
  const [admission, setAdmission] = useState<Admission | AdmissionFromSearch>(
    undefined,
  );

  return (
    <context.Provider value={{admission, toggleModal: setAdmission}}>
      {children}
    </context.Provider>
  );
};
