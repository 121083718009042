import React from "react";
import {NodeType, TableOfContentBlock} from "@reside/forms";
import {
  styled,
  FullScreenModal,
  ModalContent,
  ModalFooterButtons,
  Button,
  useBlockComponentContext,
} from "@reside/ui";

import {Draft} from "../../model";
import {IconButton} from "../../../icon-button";
import {TextSizeControl} from "../../text-size-control";
import {AdmissionContextConsumer} from "../../AdmissionContext";
import {Footer} from "../../../footer";

export const selectTableOfContentBlockFromDraft = (
  draft: Draft,
): TableOfContentBlock | undefined =>
  draft.slides
    .filter(slide => slide.excludeFromTOC === true)
    ?.flatMap(slide => slide.children)
    ?.find(
      block => block.type === NodeType.TABLE_OF_CONTENT,
    ) as TableOfContentBlock;

export const TocControlModal = () => {
  const {BlockTableOfContent} = useBlockComponentContext();

  return (
    <AdmissionContextConsumer>
      {({
        draft,
        uiState: {textSize, tocIsOpen},
        uiActions: {closeToc, pause, setTextSize},
      }) => (
        <FullScreenModal
          isOpen={tocIsOpen}
          topControls={
            <TextSizeControlWrapper>
              <TextSizeControl textSize={textSize} onChange={setTextSize} />
            </TextSizeControlWrapper>
          }
          onRequestClose={closeToc}
        >
          {() => (
            <ContextContainer textSize={textSize}>
              <ModalContent>
                <ScrollableArea textSize={16 * textSize}>
                  <BlockTableOfContent
                    {...selectTableOfContentBlockFromDraft(draft)}
                  />
                </ScrollableArea>
              </ModalContent>

              <ModalFooterButtons>
                <Footer
                  hasShadow
                  left={
                    draft.computed.readOnly === false && (
                      <IconButton
                        iconName="pause"
                        testId="pause-button"
                        onClick={() => {
                          closeToc();
                          pause();
                        }}
                      >
                        Pause
                      </IconButton>
                    )
                  }
                  right={
                    <Button size="large" color="primary" onClick={closeToc}>
                      Close
                    </Button>
                  }
                />
              </ModalFooterButtons>
            </ContextContainer>
          )}
        </FullScreenModal>
      )}
    </AdmissionContextConsumer>
  );
};

const ContextContainer = styled.div<{textSize: number}>`
  --translate-x: ${({textSize}) => ((textSize - 1) / 3) * 75}px;
  --scale: ${({textSize}) => (textSize - 1) / 4 + 1};

  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  .ReactModal__InnerContent {
    padding-bottom: 0;

    > div {
      padding-bottom: 2rem;
    }
  }
  .ReactModal__Footer {
    padding-block: 0;
    margin-block: 0;

    > footer {
      @media (min-width: 768px) {
        padding-block: 1rem 0;
        --normal: 1;
        --invert: -1;
      }
    }
  }

  [data-test-id="pause-button"],
  [data-test-id="go-back-button"] {
    transform: translateX(calc(var(--translate-x) * var(--invert, 1)))
      scale(var(--scale));
    &:hover {
      transform: translate(
          calc(var(--translate-x) * var(--invert, 1)),
          -${({textSize}) => textSize}px
        )
        scale(var(--scale));
    }
  }
  .primary.large {
    transform: translateX(calc(var(--translate-x) * var(--normal, -1)))
      scale(var(--scale));
    &:hover {
      transform: translate(
          calc(var(--translate-x) * var(--normal, -1)),
          -${({textSize}) => textSize}px
        )
        scale(var(--scale));
    }
  }
  .primary.large,
  [data-test-id="pause-button"],
  [data-test-id="go-back-button"] {
    &:hover {
      transition-delay: 100ms;
      > i,
      > span {
        transition-delay: inherit;
      }
    }
  }
`;

const ScrollableArea = styled.div<{textSize: number}>`
  flex: 1;
  overflow: auto;
  padding: 0 ${({theme}) => theme.space[2]}px;
  ${({theme}) => theme.media.lg} {
    padding: 0 ${({theme}) => theme.space[7]}px;
  }
  ${({textSize}) => `font-size: ${textSize}px`}
`;

const TextSizeControlWrapper = styled.div`
  display: none;

  ${({theme}) => theme.media.md} {
    display: block;
    margin: ${({theme}) => theme.space[5]}px ${({theme}) => theme.space[3]}px 0
      auto;
  }
`;
