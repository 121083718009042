import React from "react";

import {Facility, IFromTo} from "../../../store/reporting/reporting.common";
import {ReportingBlockSetters} from "../../../atoms/reporting-block";
import {
  ReportAggregationTypes,
  ReportsDataStatePath,
  SortDirection,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {styled} from "@reside/ui";
export type ReportProps = Readonly<{
  facilities: Facility[];
  dateRange: IFromTo;
  reportingBlockSetters?: ReportingBlockSetters;
}>;

export const hasMorePages = (
  d: ReportsDataStatePath[],
  isFullReport: boolean,
) =>
  isFullReport &&
  d.reduce(
    (a, k) =>
      a && k.dataState && k.dataState.currentPage + 1 < k.dataState.totalPages,
    true,
  );

export const getAggregationValues = (
  dataStatePaths: ReportsDataStatePath[],
  field: ReportAggregationTypes,
) => {
  return dataStatePaths.map(
    dataStatePath =>
      !!dataStatePath &&
      !!dataStatePath.dataState &&
      dataStatePath.dataState[field],
  );
};

export const CompareDateLabels = (props: {dateRange: IFromTo}) => (
  <CompareDateLabelsWrapper>
    <CompareDateLabel dateRange={props.dateRange} global />
  </CompareDateLabelsWrapper>
);

export const formatterMs = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  maximumSignificantDigits: 3,
  style: "decimal",
});

const MILISECONDS_IN_MINUTE = 60000;

export const formatMs = (ms: number) => {
  return formatterMs.format(ms / MILISECONDS_IN_MINUTE) + " ";
};

type SetSort = (sortField: SortField, sortDirection: SortDirection) => void;

export const useServerSort: (
  sortInput: any,
  sortDir?: any,
) => [SortDirection, SortField, SetSort] = (
  defaultField: SortField,
  defaultDirection?: SortDirection,
) => {
  const [sortDirection, setSortDirection] = React.useState(
    defaultDirection || SortDirection.DESC,
  );
  const [sortField, setSortField] = React.useState(defaultField);

  const setSort: SetSort = (sortFieldIn, sortDirectionIn) => {
    setSortField(sortFieldIn);
    setSortDirection(sortDirectionIn);
  };

  return [sortDirection, sortField, setSort];
};

export const CompareDateLabelsWrapper = styled.div`
  max-width: 200px;
  text-align: right;
  display: flex;
  flex-flow: column;
`;
