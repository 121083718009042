import React, {ReactNode} from "react";
import {css} from "@emotion/css";
import {H2} from "@reside/ui";

type Props = Readonly<{
  facilities?: string[];
  children: ReactNode;
}>;

// TODO: throwaway after we have Reporting Context
export const BlankSlate = ({facilities, children}: Props) => (
  <>
    {facilities && facilities.length === 0 ? (
      <H2 className={s.blankSlateTitle}>
        No selected facilities, please select some.
      </H2>
    ) : (
      children
    )}
  </>
);

const s = {
  blankSlateTitle: css`
    text-align: center;
  `,
};
