import React from "react";
import {DataNode} from "rc-tree/lib/interface";
import {connect} from "react-redux";
import {styled} from "@reside/ui";

import {ActiveFacilities} from "../../store/reporting/reporting.common";
import {AppState, select} from "../../store";
import {DropdownTreeSelect} from "../dropdown-tree-select";

import {modifyAndCheckFacilitiesTree} from "./helpers";

type Props = Readonly<{
  activeFacilitiesTreeKeys: ReadonlyArray<string>;
  facilitiesTree: ReadonlyArray<DataNode>;
  hasOnlyOne?: boolean;
  hideTrigger?: boolean;
  searchPlaceholder?: string;
  setActiveFacilities: (data: ActiveFacilities) => void;
  triggerLabel?: string;
  autoComplete?: string;
}>;

export const FacilityDropdownTreeSelectRenderer = ({
  activeFacilitiesTreeKeys,
  facilitiesTree,
  hasOnlyOne,
  hideTrigger,
  searchPlaceholder,
  setActiveFacilities,
  triggerLabel,
  autoComplete,
}: Props) => (
  <Wrapper>
    <DropdownTreeSelect
      checkedKeys={activeFacilitiesTreeKeys}
      EmptySearch={() => <span>No matching organization or facility</span>}
      hideTrigger={hideTrigger}
      hasOnlyOne={hasOnlyOne}
      placement="bottom-start"
      searchPlaceholder={searchPlaceholder}
      treeData={facilitiesTree}
      triggerLabel={triggerLabel}
      onCheck={(checkedKeys, checkedLeafKeys) => {
        setActiveFacilities({
          checkedKeys,
          checkedLeafKeys,
        });
      }}
      autoComplete={autoComplete}
    />
  </Wrapper>
);

FacilityDropdownTreeSelectRenderer.defaultProps = {
  hideTrigger: false,
  searchPlaceholder: "Search by organization or facility",
  triggerLabel: "Facilities",
};

type StateProps = {
  activeFacilitiesTreeKeys: ReadonlyArray<string>;
  facilitiesTree: ReadonlyArray<DataNode>;
};

type DispatchProps = {
  setActiveFacilities: (data: ActiveFacilities) => void;
};

const Connected = ({
  activeFacilitiesTreeKeys,
  facilitiesTree,
  setActiveFacilities,
}: StateProps & DispatchProps) => {
  const {modifiedFacilityTree, singleFacility, singleOrg} =
    modifyAndCheckFacilitiesTree(facilitiesTree);

  return (
    modifiedFacilityTree?.length > 0 && (
      <FacilitySelectWrapper singleOrg={singleOrg}>
        <FacilityDropdownTreeSelectRenderer
          activeFacilitiesTreeKeys={activeFacilitiesTreeKeys}
          facilitiesTree={modifiedFacilityTree}
          hideTrigger={singleFacility}
          setActiveFacilities={setActiveFacilities}
          triggerLabel={singleFacility ? "Facility" : "Facilities"}
        />
      </FacilitySelectWrapper>
    )
  );
};

const mapStateToProps = (state: AppState) => ({
  facilitiesTree: select.adminSession.facilitiesTree(state),
  activeFacilitiesTreeKeys: select.adminSession.activeFacilitiesTreeKeys(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveFacilities: (payload: ActiveFacilities) => {
    dispatch.adminSession.setActiveFacilities(payload);
  },
});

export const FacilityDropdownTreeSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Connected);

const FacilitySelectWrapper = styled.div<{singleOrg: boolean}>`
  .hidden-parent
    > .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
    display: none;
  }
  .rc-tree-child-tree {
    padding-left: ${({singleOrg}) => singleOrg && "0px"};
  }
`;

const Wrapper = styled.div`
  .trigger {
    font-size: 23px;
  }

  .rc-tree {
    overflow-y: scroll;
  }
`;
