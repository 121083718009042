import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  CompareDateLabels,
  formatMs,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportSlide,
  ReportsType,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {TextCell} from "../../../atoms/list-table/SharedCells";

export const SLIDE_INTERACTIONS_REPORT_ID = "slide-interactions" as const;

export const ReportSlideInteractions = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.timeMilis,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle
          title="Time spend on Resident Experience slides"
          subtitle="by Admissions"
        />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>

      <RequiredUserPermission
        permission={TableUserDto.PermissionsEnum.SLIDE_INTERACTIONS}
      >
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.slideInteractions,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled={props.reportingBlockSetters.enabled}
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <ListTableReporting
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Slides found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportSlide>
                  sortKey={SortField.slideName}
                  cellRenderer={row => (
                    <TextCell
                      small
                    >{`Slide nr. ${row.rowData.slideName}`}</TextCell>
                  )}
                  label="Slide Name"
                  width={20}
                />
                <Column<ReportSlide>
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.slideId}</TextCell>
                  )}
                  label="Slide Id"
                  sortKey={SortField.slideId}
                  width={20}
                />
                <Column<ReportSlide>
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.clicks}</TextCell>
                  )}
                  label="Clicks"
                  sortKey={SortField.clicks}
                  width={20}
                />
                <Column<ReportSlide>
                  cellRenderer={row => (
                    <TextCell small>
                      {formatMs(row.rowData.avgTimeMilis)} minutes
                    </TextCell>
                  )}
                  label="Avg. time"
                  sortKey={SortField.avgTimeMilis}
                  width={20}
                />
                <Column<ReportSlide>
                  cellRenderer={row => (
                    <TextCell small>
                      {formatMs(row.rowData.timeMilis)} minutes
                    </TextCell>
                  )}
                  label="Total time spent"
                  sortKey={SortField.timeMilis}
                  width={20}
                />
              </ListTableReporting>

              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
