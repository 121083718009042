import {Box, ScrollableErrorArea, styled} from "@reside/ui";

export const Header = styled(Box)`
  margin-bottom: 2em;
`;

export const StyledScrollableErrorArea = styled(ScrollableErrorArea)`
  max-height: calc(100vh - 190px);
  overflow: auto;
`;
