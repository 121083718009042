import {useQuery} from "react-query";

import {UserService} from "../../../services/UserService";

type Params = {userId: string};

export const useUserQuery = ({userId}: Params) =>
  useQuery(
    ["useUserQuery", userId],
    async ({queryKey: [_, userId]}) => {
      const {data} = await UserService.find(userId);
      return data;
    },
    {
      enabled: Boolean(userId),
    },
  );
