export enum GENDER_MAP {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum GenderMapIds {
  Male = "92b4ceb4-b646-45fb-8293-63068ad8fee8",
  Female = "e3fff89e-5018-4bb2-bf3f-ea9baa669b52",
  Unknown = "acc1b69c-9950-49e7-a4b2-f5310bfe026d",
}
