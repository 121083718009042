var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
import { Icon } from "../icon";
export var DropdownMenuDots = function (_a) {
    var className = _a.className, disabled = _a.disabled, testId = _a.testId;
    return (React.createElement(Container, { className: className, disabled: disabled, "data-test-id": testId },
        React.createElement(StyledIcon, { name: "dots", size: 17, disabled: disabled })));
};
DropdownMenuDots.defaultProps = {
    disabled: false,
    testId: undefined,
};
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: translate(0.5px, 0.5px);\n  ", "\n"], ["\n  transform: translate(0.5px, 0.5px);\n  ", "\n"])), function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled && "color: ".concat(theme.color.borderLightGray);
});
var Container = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  border: 1px solid\n    ", ";\n  border-radius: 15px;\n  padding: 0 5px;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  border: 1px solid\n    ", ";\n  border-radius: 15px;\n  padding: 0 5px;\n  color: ", ";\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return disabled ? theme.color.borderLightGray : theme.color.gray100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
});
var templateObject_1, templateObject_2;
