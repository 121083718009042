import {useQuery} from "react-query";
import {ResidentService} from "../../../services/ResidentServices";
import {useParams} from "react-router";
import Alert from "react-s-alert";

export const useFetchResident = () => {
  const {resident_uuid} = useParams<{resident_uuid: string}>();

  return useQuery(
    ["fetch_resident"],
    async () => {
      const {data} = await ResidentService.getOneResident(resident_uuid);

      return data;
    },
    {
      enabled: !!resident_uuid,
      onError: () => Alert.error("Failed to load resident"),
      refetchOnMount: true,
    },
  );
};
