import {AdminPccActivityLogsTable} from "./atoms/AdminActivityLogsTable";
import {Spinner} from "../../atoms/spinner";
import {ActivityLogHeader} from "./atoms";
import {useFetchActivitiesLog} from "./hooks";
import {Facilities} from "../../models/types/AdmissionForm";

export const AdminPccActivityLogsTableContainer = ({
  facilities,
  facilityId,
}: {
  facilityId: string;
  facilities: Facilities;
}) => {
  const facility = facilities.find(facility => facility.id === facilityId);

  const {isFetching, data, fetchNextPage} = useFetchActivitiesLog(
    facilities,
    facility.id,
  );

  return !facility && isFetching && !data?.pages.length ? (
    <Spinner />
  ) : (
    <AdminPccActivityLogsTable
      activityLogs={data?.pages.flat() || []}
      facilityId={facility.id}
      header={<ActivityLogHeader facility={facility} />}
      isLoading={isFetching}
      loadMoreEnabled
      organizationId={facility.organization.id}
      tableName="activity logs"
      loadMore={fetchNextPage}
    />
  );
};
