import React from "react";
import {Text, Tag} from "@reside/ui";

import {colorMap, readableStatus, readableRole} from "./helpers";

export function TextCell(props) {
  return (
    <Text fontSize={3} fontWeight="medium">
      {props.children}
    </Text>
  );
}

export function RoleCell(props) {
  return <Text fontSize={3}>{readableRole(props.data)}</Text>;
}

export function UserStatusCell(props) {
  return (
    <Tag color={colorMap(props.data)} fontColor="black">
      {readableStatus(props.data)}
    </Tag>
  );
}
