import React from "react";
import {connect} from "react-redux";
import {Dictionary} from "lodash";
import {
  Text,
  Tooltip,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuDots,
} from "@reside/ui";
import {Admission} from "@reside/reside-api-admission";
import {select, AppState} from "../../store";
import {
  AdmissionFromSearch,
  AdmissionStatusEnum,
} from "../../services/AdmissionsService";

import {getResidentTestId} from "../dashboard-table/helper";
import {useAdmissionsDeleteModalContext} from "./admission-delete-modal";
import {DownloadPDFDropdownItem} from "../dashboard-table/atoms/cell/DownloadPDFDropdownItem";
import {DownloadPartialPDFDropdownItem} from "../dashboard-table/atoms/cell/admission-approved-cell/DownloadPartialPDFDropdownItem";
import {useAdmissionsRestoreModalContext} from "./admission-restore-modal/context";
import {useChangeHistoryModalContext} from "../dashboard-table/atoms/change-history-modal";
import {useLinkResidentToAdmissionContext} from "../../pages/page-admin-residents/context/useLinkResidentToAdmissionContext";

type Props = Readonly<{
  admission: Admission | AdmissionFromSearch;
  isOrgAdmin: boolean;
  isResideAdmin: boolean;
  facilityFlagsById: Dictionary<{[key: string]: boolean}>;
  onOpen?: () => void;
  onClose?: () => void;
}>;

const isDeleted = (admission: Admission | AdmissionFromSearch) =>
  admission.status === Admission.StatusEnum.CANCELLED;

const canDownloadPdf = (admission: Admission | AdmissionFromSearch) =>
  (
    [
      Admission.StatusEnum.IN_PROGRESS,
      Admission.StatusEnum.APPROVED,
      Admission.StatusEnum.ARCHIVED,
      Admission.StatusEnum.CANCELLED,
    ] as ReadonlyArray<AdmissionStatusEnum>
  ).includes(admission.status);

const canDownloadPartialPdf = (admission: Admission | AdmissionFromSearch) =>
  (
    [
      Admission.StatusEnum.APPROVED,
      Admission.StatusEnum.ARCHIVED,
      Admission.StatusEnum.CANCELLED,
    ] as ReadonlyArray<AdmissionStatusEnum>
  ).includes(admission.status);

export const Renderer = ({
  admission,
  isOrgAdmin,
  isResideAdmin,
  facilityFlagsById,
  onOpen,
  onClose,
}: Props) => {
  /** TODO (DEV-2003) - Refactor this to be served from only one context. */
  const {toggle: toggleCancelModal} = useAdmissionsDeleteModalContext();
  const {toggle: toggleRestoreModal} = useAdmissionsRestoreModalContext();
  const {toggleModal: toggleLinkResidentToAdmissionModal} =
    useLinkResidentToAdmissionContext();
  const {openModal} = useChangeHistoryModalContext();
  const hasOrgAdminOnlyDeleteAdmissions =
    facilityFlagsById[admission.facilityId]
      ?.facilityHasOrgAdminOnlyDeleteAdmissions;
  const isDisabled = hasOrgAdminOnlyDeleteAdmissions ? !isOrgAdmin : false;
  // Check this to show "Link another resident" in dropdown button only on residents page.
  const isResidentPage = window.location.pathname.includes("residents");

  return (
    <DropdownMenu
      trigger={() => (
        <Tooltip content="More Options">
          <DropdownMenuDots testId={getResidentTestId(admission, "dropdown")} />
        </Tooltip>
      )}
      onOpen={onOpen}
      onClose={onClose}
    >
      {canDownloadPdf(admission) && (
        <DownloadPDFDropdownItem
          admission={admission as any}
          data-test-id="download-pdf"
        />
      )}
      {canDownloadPartialPdf(admission) && (
        <DownloadPartialPDFDropdownItem
          admission={admission as any}
          data-test-id="download-pdf"
        />
      )}

      {isResideAdmin && isResidentPage && (
        <DropdownMenuItem
          onClick={() => {
            toggleLinkResidentToAdmissionModal(admission);
          }}
        >
          <Text>Link to another resident</Text>
        </DropdownMenuItem>
      )}

      {!isDeleted(admission) && !isResidentPage && (
        <DropdownMenuItem
          testId={getResidentTestId(admission, Admission.StatusEnum.CANCELLED)}
          onClick={() => !isDisabled && toggleCancelModal(admission)}
          disabled={isDisabled}
        >
          <Text color="deepOrange100">Delete</Text>
        </DropdownMenuItem>
      )}
      {isDeleted(admission) && !isResidentPage && (
        <DropdownMenuItem
          onClick={() => !isDisabled && toggleRestoreModal(admission)}
          disabled={isDisabled}
        >
          <Text>Restore Admission</Text>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        onClick={() => openModal(admission as AdmissionFromSearch)}
      >
        <Text>View Document History</Text>
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

const mapStateToProps = (state: AppState) => ({
  isOrgAdmin: select.adminSession.isOrgAdmin(state),
  facilityFlagsById: select.adminSession.facilityFlagsById(state),
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const AdmissionDropdownMenu = connect(mapStateToProps)(Renderer);
