import React from "react";
import _ from "lodash";
import {Trans} from "@lingui/react";
import {
  Text,
  Markdown,
  BlockComponentContextType,
  TableOfContentRenderer,
  CompletionChecklist,
} from "@reside/ui";
import {BlockMandatoryVideo} from "@reside/ui/dist/form-blocks/block-mandatory-video";
import {BlockTableOfContent} from "@reside/ui/dist/form-blocks/block-table-of-content";
import {interpolate, FormInterpolation} from "@reside/ui";

import {components} from "../block-components";
import {withAccessToken, getUrlForEnv} from "../../utils/url";
import {TimezoneSignatureField} from "../timezone-signature-field";
import {
  AdmissionContextConsumer,
  useAdmissionContext,
} from "./AdmissionContext";
import {ToCContextConsumer} from "./ToCContext";

const useInterpolated = (data: FormInterpolation) => {
  const {draft} = useAdmissionContext();

  return interpolate({
    admission: draft.instance,
    answers: draft.answers,
    computed: draft.computed,
    ...data,
  });
};

export const admissionBlockComponents: BlockComponentContextType = {
  ...components,
  interpolate: useInterpolated,
  SignatureField: props => (
    <AdmissionContextConsumer>
      {({draft}) => (
        <TimezoneSignatureField
          {...props}
          transformUrl={withAccessToken}
          timeZone={draft.instance.facilityTimeZoneId}
        />
      )}
    </AdmissionContextConsumer>
  ),
  Markdown: ({values, ...props}) => (
    <AdmissionContextConsumer>
      {({draft}) => (
        <Markdown
          {...props}
          transformUrl={withAccessToken}
          values={{
            ...values,
            admission: draft.instance,
            answers: draft.answers,
            computed: draft.computed,
          }}
        />
      )}
    </AdmissionContextConsumer>
  ),
  BlockMandatoryVideo: props => (
    <AdmissionContextConsumer>
      {({draft}) => (
        <BlockMandatoryVideo
          {...props}
          children={props.children.map(child => ({
            ...child,
            video: {
              ...child.video,
              source: getUrlForEnv(_.get(draft, child.video.source) ?? ""),
              poster: getUrlForEnv(_.get(draft, child.video.poster) ?? ""),
            },
          }))}
        />
      )}
    </AdmissionContextConsumer>
  ),
  BlockTableOfContent: props => (
    <AdmissionContextConsumer>
      {({uiActions, uiState}) => (
        <ToCContextConsumer>
          {({selectFullTOC, selectIncompleteSlides}) => (
            <BlockTableOfContent
              {...props}
              TableWrapper={() => (
                <TableOfContentRenderer
                  slideIndex={uiState.slideIdx}
                  fullTOC={selectFullTOC()}
                  onToggleIncomplete={
                    uiActions.toggleShowIncompleteSlidesOnlyFlag
                  }
                  showIncompleteSlidesOnly={uiState.showIncompleteSlidesOnly}
                  incompleteSlides={selectIncompleteSlides()}
                  onSlideClick={(index: number) => {
                    uiActions.goToSlide(index);
                    uiActions.closeToc();
                  }}
                />
              )}
            />
          )}
        </ToCContextConsumer>
      )}
    </AdmissionContextConsumer>
  ),
  BlockCompletionChecklist: ({translationKey}) => (
    <AdmissionContextConsumer>
      {({completionChecklist}) => (
        <>
          <div className="blockcompletionchecklist-0-54">
            <Text
              fontSize="1.25em"
              lineHeight="1.75em"
              className="blockcompletionchecklist-0-55"
            >
              <Trans id={translationKey} />
            </Text>
          </div>
          <CompletionChecklist
            completionChecklist={completionChecklist.map(
              ({title, ...item}) => ({...item, text: title.translationKey}),
            )}
            renderText={text => <Trans id={text} />}
          />
        </>
      )}
    </AdmissionContextConsumer>
  ),
};
