import React, {ComponentType} from "react";
import Alert from "react-s-alert";
import {RouteComponentProps} from "react-router-dom";
import {useEffectOnce} from "react-use";

import {ADMISSION_WELCOME_ROUTE} from "../page-admission-welcome/route";
import {useTokenSearchParam} from "./hooks/useTokenSearchParam";

export type TokenProps = Readonly<{token: string}>;

/**
 * A route guard which renders the connected component only if a valid token was extracted from the history location.
 */
export const withTokenSearchParam =
  (Component: ComponentType<RouteComponentProps & TokenProps>) =>
  (props: RouteComponentProps) => {
    const token = useTokenSearchParam();

    useEffectOnce(() => {
      if (!token) {
        props.history.push(ADMISSION_WELCOME_ROUTE);
        Alert.error(
          "Provided link is invalid. Please request a new one with email.",
        );
      }
    });

    return token ? <Component {...props} token={token} /> : null;
  };
