import {styled, TaskCheckboxFadedSmall, FieldErrorText} from "@reside/ui";
import {BackdropSpinner} from "../../../../spinner";

export const Label = styled(TaskCheckboxFadedSmall.defaultProps.Label)`
  padding-left: 15px;
  padding-right: 15px;
`;

export const SmallFieldErrorText = styled(FieldErrorText)`
  font-size: 14px;
`;

export const PositionedBackdropSpinner = styled(BackdropSpinner)`
  margin: 5px -15px;

  .spinner {
    position: absolute;
  }
`;
