import React from "react";
import {Button, BlockField} from "@reside/ui";
import {Trans} from "@lingui/macro";
import {AdmissionStatus} from "../../../services/AdmissionsService";
import {logErrorToSentry} from "../../../utils/sentry";
import {usePreflightRouteParams} from "../route";
import {ButtonSpace} from "./styles";
import {PreFlightFieldsetProps} from "./types";

/**
 * Group of related fields used to create or edit pre-flight
 */
export const PreFlightFieldset = ({
  admissionDraft,
  isLastSlide,
  uiActions,
  currentSlide,
  queryVariables,
  isPatchingDraft,
  isSavingDraft,
  isPatchingAdmission,
  isSendingToResident,
  createDraft,
  patchDraft,
  createAdmission,
  patchAdmission,
}: PreFlightFieldsetProps): JSX.Element => {
  const {admissionId} = usePreflightRouteParams();

  const isEditing = typeof admissionId === "string";
  const inPreflightStatus =
    admissionDraft.status === AdmissionStatus.PRE_FLIGHT;

  return (
    <fieldset
      disabled={
        isSendingToResident ||
        isPatchingAdmission ||
        isPatchingDraft ||
        isSavingDraft
      }
    >
      {/* fieldset firefox implementation requires wrapping children in extra div */}
      <div>
        {currentSlide.children.map(block => (
          <BlockField
            key={block.id}
            block={block}
            queryVariables={queryVariables}
            onCatch={logErrorToSentry}
          />
        ))}
        <ButtonSpace>
          {inPreflightStatus && !isEditing && (
            <Button
              testId="saveDraftButton"
              onClick={() => createDraft()}
              disabled={isSavingDraft || isSendingToResident}
            >
              {isSavingDraft ? "Saving Pre-Flight..." : "Save Pre-Flight"}
            </Button>
          )}
          {inPreflightStatus && isEditing && (
            <Button
              testId="editDraftButton"
              onClick={() => patchDraft()}
              disabled={isPatchingDraft || isSendingToResident}
            >
              {isPatchingDraft ? "Saving Pre-Flight..." : "Save Pre-Flight"}
            </Button>
          )}
          {!isLastSlide && inPreflightStatus && (
            <Button
              testId="nextSlideButton"
              color="primary"
              onClick={() => uiActions.goToNextSlide()}
            >
              <Trans id={currentSlide.ctaButton.title.translationKey} />
            </Button>
          )}
          {isLastSlide && inPreflightStatus && (
            <Button
              testId="sendToResidentButton"
              color="primary"
              onClick={() => createAdmission()}
              disabled={isPatchingDraft || isSavingDraft || isSendingToResident}
            >
              {isSendingToResident ? (
                "Sending Pre-Flight..."
              ) : (
                <Trans id={currentSlide.ctaButton.title.translationKey} />
              )}
            </Button>
          )}
          {!inPreflightStatus && (
            <Button
              testId="editAdmissionButton"
              color="primary"
              onClick={() => patchAdmission()}
              disabled={isPatchingAdmission}
            >
              {isPatchingAdmission ? "Sending update..." : "Save changes"}
            </Button>
          )}
        </ButtonSpace>
      </div>
    </fieldset>
  );
};
