import React from "react";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {Button, Tooltip} from "@reside/ui";
import {buildResponseErrorMessage} from "../../../../services/api/errorHelpers";
import {QuicksightService} from "../../../../services/QuicksightService";

type Props = Readonly<{
  onSuccess: () => void;
}>;

export const ClearQuicksightCacheButton = ({onSuccess}: Props) => {
  const {mutate, isLoading} = useMutation(QuicksightService.clearCache, {
    onSuccess: () => {
      onSuccess();
      Alert.success("Quicksight cache cleared");
    },
    onError: async resp => {
      Alert.error(await buildResponseErrorMessage(resp));
    },
  });

  return (
    <Tooltip content="Clearing the cache may affect the performance speed. Use sparingly.">
      <Button
        color="danger"
        disabled={isLoading}
        onClick={() => mutate()}
        testId="clear-quicksight-cache-button"
      >
        {isLoading ? "Clearing Cache..." : "Reset Quicksight Cache"}
      </Button>
    </Tooltip>
  );
};
