import {useQuery} from "react-query";

import {facilityApi} from "../../../services/FacilityService";
import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";

export const useFacilitySettingsQuery = ({facilityId}: {facilityId: string}) =>
  useQuery(
    ["facilitySettings", facilityId],
    async ({queryKey: [_, facilityId]}) => {
      const {data} = await facilityApi.getFacilitySettingsUsingGET(
        facilityId,
        getAuthorizationHeader(),
      );

      return data;
    },
    {
      onError: async resp => {
        await buildResponseErrorMessage(
          resp,
          "Failed to fetch facility settings. Please, try again later.",
        );
      },
    },
  );
