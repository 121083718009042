import React from "react";
import {Tag} from "@reside/ui";
import {PccResidentAccountStatus} from "../../services/PccService";

type Props = Readonly<{
  status: PccResidentAccountStatus;
}>;

export const PccStatusTag = ({status}: Props) =>
  render[status] ? render[status]() : null;

const ActivePccStatusTag = () => (
  <Tag color="mint100">Active PointClickCare account</Tag>
);

const PendingPccStatusTag = () => (
  <Tag color="golden100">PointClickCare account waiting for approval</Tag>
);

const SkippedPccStatusTag = () => (
  <Tag color="darkBlue70">PointClickCare account skipped</Tag>
);

const DeletedPccStatusTag = () => (
  <Tag color="deepOrange100">PointClickCare account deleted</Tag>
);

const render: Record<PccResidentAccountStatus, () => JSX.Element> = {
  ACTIVE: ActivePccStatusTag,
  PENDING: PendingPccStatusTag,
  SKIPPED: SkippedPccStatusTag,
  DELETED: DeletedPccStatusTag,
};
