import {useQuery} from "react-query";
import {
  FindResidentsUsingGETQueryParams,
  handlePccRequestLimitReached,
  PccService,
} from "../../services/PccService";

export const useFindResidentQuery = (
  params: FindResidentsUsingGETQueryParams,
  {enabled}: {enabled: boolean},
) =>
  useQuery(
    ["findResident", params],
    async ({queryKey: [_, params]}) => {
      const {data = []} = await PccService.findResidents(
        params as FindResidentsUsingGETQueryParams,
      );

      const [resident = null] = data;

      return resident;
    },
    {
      onError: error => {
        handlePccRequestLimitReached(error);
      },
      enabled,
    },
  );
