import React, {useCallback} from "react";

import {dispatch} from "../../../store";
import {useClearAndCancelCache} from "../../../hooks/useClearAndCancelCache";
import {useHistory} from "react-router-dom";

import {Menu} from "@headlessui/react";

import {Button, FlexColumn, DropdownMenu, DropdownMenuItem} from "@reside/ui";
import {SignOutText, SignOutButton, FlexRowJustify} from "./styles";
import {literalStringToBoolean} from "../../../utils/helpers/litteralStringToBoolean";

export function SignOut({
  signoutAsDropdownItem = false,
}: {
  /** When screens are small signout will be appear in person dropdown item. In that case it should have simple function without openning separate dropdown. */
  signoutAsDropdownItem?: boolean;
}) {
  const history = useHistory();
  const {clearAndCancelCache} = useClearAndCancelCache();

  const SignOutTrigger = useCallback(
    () => (
      <SignOutButton flat>
        <SignOutText>Sign out</SignOutText>
      </SignOutButton>
    ),
    [],
  );

  /** When a form is dirty and user wants to sign out we need special checks. */
  const handleSignOut = async () => {
    if (literalStringToBoolean(sessionStorage.getItem("formDirty"))) {
      sessionStorage.setItem("stopSignOut", "true");
      window.dispatchEvent(new Event("storage"));
    } else {
      clearAndCancelCache();
      await dispatch.adminSession.logout();
      history.replace("/admin");
    }
  };

  if (!signoutAsDropdownItem)
    return (
      <DropdownMenu trigger={() => SignOutTrigger()}>
        <FlexColumn>
          <SignOutText fontWeight="600">Sign out of this account?</SignOutText>
          <FlexRowJustify>
            <Menu.Item>
              {({close}: any) => (
                <Button outline color="primary" onClick={() => close()}>
                  Cancel
                </Button>
              )}
            </Menu.Item>
            <Button flat color="secondary" onClick={handleSignOut}>
              Sign out
            </Button>
          </FlexRowJustify>
        </FlexColumn>
      </DropdownMenu>
    );

  return <DropdownMenuItem onClick={handleSignOut}>Sign out</DropdownMenuItem>;
}
