import React from "react";
import {Formik} from "formik";
import {validator} from "@reside/forms";
import {
  H1,
  H3,
  RadioGroupField,
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Box,
} from "@reside/ui";
import {CancellationReason} from "@reside/reside-api-app";
import {AdmissionStatusEnum} from "../../services/AdmissionsService";

type Values = Readonly<{
  reason: string;
}>;

type Props = Readonly<{
  cancellationReasons: ReadonlyArray<CancellationReason>;
  residentName: string;
  admissionCode: string;
  admissionStatus: AdmissionStatusEnum;
  isOpen: boolean;
  isLoading: boolean;
  onRequestClose: () => void;
  onCancel: (data: Values) => void;
}>;

export const DeleteAdmissionModal = ({
  cancellationReasons,
  residentName,
  admissionCode,
  admissionStatus,
  isOpen,
  isLoading,
  onRequestClose,
  onCancel,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={isLoading ? undefined : onRequestClose}
  >
    {({closeModal}) => (
      <Formik<Values>
        initialValues={{
          reason: "",
        }}
        validate={values =>
          validator.validateAll(values, {
            reason: "required",
          })
        }
        onSubmit={onCancel}
      >
        {({submitForm}) => (
          <>
            <ModalContent>
              <H1>Delete Admission for {residentName}</H1>
              <H3 color="gray100">Admission ID: {admissionCode}</H3>
              <RadioGroupField
                disabled={isLoading}
                name="reason"
                label="Please pick a reason to delete this admission:"
                options={cancellationReasons
                  .filter(
                    cancellationReason =>
                      cancellationReason.forStatuses.length === 0 ||
                      cancellationReason.forStatuses.some(
                        status => status === admissionStatus,
                      ),
                  )
                  .map(({cancellationReason}) => ({
                    value: cancellationReason,
                    label: cancellationReason,
                  }))}
              />
            </ModalContent>
            <ModalFooterButtons>
              <Box
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  outline
                  color="primary"
                  disabled={isLoading}
                  onClick={closeModal}
                  testId="closeDeleteAdmissionModalButton"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="danger"
                  disabled={isLoading}
                  onClick={submitForm}
                  testId="deleteAdmissionButton"
                >
                  {isLoading ? "Deleting..." : "Delete"}
                </Button>
              </Box>
            </ModalFooterButtons>
          </>
        )}
      </Formik>
    )}
  </Modal>
);
