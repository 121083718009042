import {useState} from "react";
import {useQuery} from "react-query";
import {RoleDto} from "@reside/reside-api-app";

import {UserService} from "../../../../../services/UserService";
import {Direction} from "../../../../../services/api/pagination";

type Params = {
  roles: ReadonlyArray<RoleDto.NameEnum>;
};

export const usePaginatedUserPermissionsQuery = ({roles}: Params) => {
  const [page, setPage] = useState(0);

  const [sort, setSort] = useState<{
    direction: Direction;
    properties: string[];
  }>({
    direction: "ASC",
    properties: ["firstName", "lastName"],
  });

  const query = useQuery(
    [
      "usersPermissionQuery",
      {
        page,
        size: 50,
        sort: sort.properties,
        direction: sort.direction,
        roles,
      },
    ],
    // eslint-disable-next-line
    //@ts-ignore Rest types may only be created from object types.ts(2700).
    async ({queryKey: [_, {roles, ...params}]}) => {
      const {data} = await UserService.fetchAll([], roles, [], params);

      return data;
    },
    {
      keepPreviousData: true,
    },
  );

  return {query, setSort, setPage, page};
};
