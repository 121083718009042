import {Dispatch, SetStateAction, useEffect} from "react";
import Alert from "react-s-alert";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import {Admission, ErrorResponseDto} from "@reside/reside-api-admission";

import {
  getValidationConfig,
  Template,
  Values,
  extractTemplateSlides,
} from "@reside/forms";
import {QueryVariableMap, useSafeSetState} from "@reside/ui";

import {PreflightSlideBlock} from "./types";
import {
  admissionsApi,
  isMissingMandatoryChecklistItemException,
} from "../../services/AdmissionsService";
import {
  coreValidation,
  fullValidation,
  getPreFlightCreateAdmissionPayload,
} from "./helpers";
import {useSlideUi} from "./useSlideUi";
import {createPreflightUrl, usePreflightRouteParams} from "./route";
import {getAuthorizationHeader} from "../../models/admin-session/selectors";
import {
  buildErrorMessage,
  buildResponseErrorMessage,
} from "../../services/api/errorHelpers";
import {useDashboardAdmissionsContext} from "../../atoms/dashboard-table/DashboardAdmissionsContext";
import {getFormValidationErrors} from "../../utils/formUtils";
import {useIsPreflightDirty} from "./useIsPreflightDirty";

type State = Readonly<{
  answers: Values;
  isValid: boolean;
  hasErrorsVisible: boolean;
  slides: PreflightSlideBlock[];
  hasSetInProgress: boolean;
  useCoreValidation: boolean;
}>;

export const usePreflight = ({
  template,
  admissionDraft,
  setAdmissionDraft,
  values = {},
  initialSlides = [],
  queryVariables = {},
}: {
  template: Template;
  admissionDraft: Admission;
  setAdmissionDraft: Dispatch<SetStateAction<Admission>>;
  values?: Values;
  initialSlides?: PreflightSlideBlock[];
  queryVariables?: QueryVariableMap;
}) => {
  const history = useHistory();
  const {admissionId} = usePreflightRouteParams();

  const [state, setState, getState] = useSafeSetState<State>(() => ({
    answers: values,
    isValid: false,
    hasErrorsVisible: false,
    slides: initialSlides,
    hasSetInProgress: false,
    useCoreValidation: false,
  }));

  const {answers, slides} = state;

  const {setAdmissionChecklistErrors} = useDashboardAdmissionsContext();

  const {isLastSlide, uiState, uiActions} = useSlideUi({
    slideCount: slides.length,
  });

  const beforeDraft = async () => {
    setHasErrorsVisible();
    await setAnswers(
      {
        ...answers,
      },
      true,
    );
    const nextState = await getState();
    if (!nextState.isValid) {
      return;
    }
    return nextState;
  };

  const currentSlide: PreflightSlideBlock =
    slides[uiState.activeSlideIndex] || slides[0];

  const {setDirtyFormState, dirtySlides, resetDirtyFormOnSaveSuccess} =
    useIsPreflightDirty({
      slides,
      values,
      currentSlide,
    });

  const onDraftSuccess = (admission: Admission) => {
    resetDirtyFormOnSaveSuccess();
    setAdmissionDraft(admission as any);

    const preflightUrl = createPreflightUrl({
      facilityId: admission.facilityId,
      admissionId: admission.id,
    });

    admissionId ? history.replace(preflightUrl) : history.push(preflightUrl);

    Alert.success("Admission data was saved successfully.");
  };

  const onDraftError = async (resp: any) => {
    Alert.error(
      await buildResponseErrorMessage(
        resp,
        "Could not save admission data at this time. Please, try again later.",
      ),
    );
  };

  const {mutate: saveDraft, isLoading: isSavingDraft} = useMutation(
    async (nextState: State) => {
      const {data} = await admissionsApi.createAdmissionUsingPOST(
        getPreFlightCreateAdmissionPayload({
          admissionDraft,
          queryVariables,
          ...nextState,
        }) as any,
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      onSuccess: onDraftSuccess,
      onError: onDraftError,
    },
  );

  const {mutate: patchDraftAdmission, isLoading: isPatchingDraft} = useMutation(
    async (nextState: State) => {
      const {data} = await admissionsApi.patchAdmissionUsingPATCH(
        getPreFlightCreateAdmissionPayload({
          admissionDraft,
          queryVariables,
          ...nextState,
        }) as any,
        admissionId,
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      onSuccess: onDraftSuccess,
      onError: onDraftError,
    },
  );

  const beforeAdmissionSave = async () => {
    setHasErrorsVisible();
    await setAnswers(
      {
        ...answers,
      },
      false,
    );

    const nextState = await getState();

    if (!nextState.isValid) {
      return;
    }

    return nextState;
  };

  const {mutate: sendToResident, isLoading: isSendingToResident} = useMutation(
    async (nextState: State) => {
      const payload = getPreFlightCreateAdmissionPayload({
        admissionDraft,
        queryVariables,
        ...nextState,
      }) as any;

      const {data} = admissionId
        ? await admissionsApi.patchAdmissionUsingPATCH(
            payload,
            admissionId,
            getAuthorizationHeader(),
          )
        : await admissionsApi.createAdmissionUsingPOST(
            payload,
            getAuthorizationHeader(),
          );

      const {data: updatedAdmission} =
        await admissionsApi.sendAdmissionToResidentUsingPATCH(
          data.id,
          getAuthorizationHeader(),
        );

      return updatedAdmission;
    },
    {
      onSuccess: admission => {
        resetDirtyFormOnSaveSuccess();
        setAdmissionDraft(admission as any);
        setState((state: State) => ({...state, hasSetInProgress: true}));
        Alert.closeAll();
        Alert.success("Admission was successfully sent to resident.");
      },
      onError: async (resp: any) => {
        const error: ErrorResponseDto = await resp.json();

        if (isMissingMandatoryChecklistItemException(error)) {
          setAdmissionChecklistErrors(
            admissionId ?? extractAdmissionIdFromError(error),
            getFormValidationErrors(error.validationErrors),
          );

          const [firstError] = error.validationErrors;

          if (!admissionId) {
            Alert.warning(
              "Admission was not sent to resident. Please complete the mandatory checklist items first.",
            );
          } else {
            Alert.error(firstError.message);
          }

          history.push("/admin/dashboard");
        } else {
          Alert.error(
            buildErrorMessage(
              error,
              "Could not send admission to resident. Please, try again later.",
            ),
          );
        }
      },
    },
  );

  const {mutate: patchInProgressAdmission, isLoading: isPatchingAdmission} =
    useMutation(
      async (nextState: State) => {
        const {data} = await admissionsApi.patchAdmissionUsingPATCH(
          getPreFlightCreateAdmissionPayload({
            admissionDraft,
            queryVariables,
            ...nextState,
          }) as any,
          admissionId,
          getAuthorizationHeader(),
        );
        return data;
      },
      {
        onSuccess: admission => {
          setAdmissionDraft(admission as any);
          Alert.closeAll();
          Alert.success("Admission data was saved successfully.");

          resetDirtyFormOnSaveSuccess();
        },
        onError: async (resp: any) => {
          Alert.error(
            await buildResponseErrorMessage(
              resp,
              "Could not save admission. Please, try again later.",
            ),
          );
        },
      },
    );

  const createDraft = async () => {
    const nextState = await beforeDraft();
    if (!nextState) return;
    saveDraft(nextState);
  };

  const patchDraft = async () => {
    const nextState = await beforeDraft();
    if (!nextState) return;
    patchDraftAdmission(nextState);
  };

  const createAdmission = async () => {
    const nextState = await beforeAdmissionSave();
    if (!nextState) return;
    sendToResident(nextState);
  };

  const patchAdmission = async () => {
    const nextState = await beforeAdmissionSave();
    if (!nextState) return;
    patchInProgressAdmission(nextState);
  };

  const selectSlidesAndValidation = ({
    template,
    answers,
    useCoreValidation,
  }: {
    template: Template;
    answers?: Values;
    useCoreValidation?: boolean;
  }) => {
    const slides = extractTemplateSlides(
      template,
      answers,
    ) as PreflightSlideBlock[];

    const validated = useCoreValidation
      ? coreValidation({
          answers,
          slides,
        })
      : fullValidation({
          answers,
          slides,
        });

    return {
      answers,
      ...validated,
    };
  };

  useEffect(() => {
    if (template && template.children.length > 0) {
      setState(state => {
        return {
          ...state,
          ...selectSlidesAndValidation({
            template,
            answers,
            useCoreValidation: state.useCoreValidation,
          }),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const setAnswers = async (answers: Values, useCoreValidation?: boolean) => {
    setState(state => ({
      ...state,
      ...selectSlidesAndValidation({
        template,
        answers,
        useCoreValidation,
      }),
      useCoreValidation,
    }));

    return getState();
  };

  const setHasErrorsVisible = (hasErrorsVisible = true) => {
    setState(state => ({...state, hasErrorsVisible}));
  };

  const {validate, validationMessages, validationRules} = getValidationConfig({
    children: currentSlide?.children ?? [],
    answers,
  });

  return {
    ...state,
    draft: {...admissionDraft, answers} as any as Admission,
    isLastSlide,
    uiState,
    uiActions,
    setAnswers,
    getAnswers: async () => (await getState())?.answers,
    currentSlide,
    validate,
    saveDraft,
    patchDraft,
    isSavingDraft,
    isPatchingDraft,
    sendToResident,
    isSendingToResident,
    patchAdmission,
    isPatchingAdmission,
    validationMessages,
    validationRules,
    createDraft,
    createAdmission,
    setDirtyFormState,
    dirtySlides,
    getIsCoreValidation: async () => (await getState())?.useCoreValidation,
  };
};

const extractAdmissionIdFromError = (error: ErrorResponseDto) => {
  const [, admissionId] = error.path.split("/").reverse();
  return admissionId;
};
