import React from "react";
import {format} from "date-fns";
import {styled, Text, Box} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/src/date-picker/date";
import {AdmissionNote} from "@reside/reside-api-admission";
import {Avatar} from "../../avatar";

import "./Note.scss";

export const Note = ({
  note,
  onRemove,
  index,
}: {
  note: AdmissionNote;
  index: number;
  onRemove: (id: string) => Promise<void>;
}) => (
  <div>
    <Box flexDirection="row" alignItems="center" className="note-0-50">
      <Avatar name={note.author} size={30} />
      <Box justifyContent="space-between" className="note-0-51">
        <Text fontWeight="bold">{note.author}</Text>
        <Text color="gray100">
          {format(new Date(note.created * 1000), DATE_FORMAT.FULL_DATE_TIME)}
        </Text>
      </Box>
    </Box>
    <Text lineHeight="18px">{note.note}</Text>
    <UnderlineButton
      onClick={async () => await onRemove(note.id)}
      data-test-id={`removeNote-${index}`}
    >
      Remove
    </UnderlineButton>
  </div>
);

const UnderlineButton = styled.div`
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  text-align: right;
  margin: 5px 0;
  margin-left: auto;
  color: ${({theme}) => theme.color.deepOrange100};

  &:hover {
    font-weight: 500;
    transform: translateY(-1px);
  }
`;
