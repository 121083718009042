import {useState, Children, Fragment} from "react";
import sort from "array-sort";
import objectPath from "object-path";
import {cx} from "@emotion/css";
import {Icon, theme, Text} from "@reside/ui";
import {SORT_DIRECTION} from "../../utils/constants";
import "./ListTable.scss";

type Props<T = Record<string, unknown>> = {
  sortDirection?: string;
  showDoubleArrowOnNotSorted?: boolean;
  children?: Array<any>;
  nextSortDirection?: Record<string, string>;
  indicatorName?: Record<string, string>;
  onSort?: (sortBy: any, sortDirection: any) => void;
  sortBy?: string;
  sortedData?: Array<T> | ReadonlyArray<T>;
  data?: Array<T> | ReadonlyArray<T>;
  getRowId?: (row: Row<T>) => string;
  rowHeight?: number;
  sortPredicates?: Record<string, Array<string>>;
  onRowClick?: (id: string, row: Row<T>) => void;
  noRowsMessage?: string;
  basic?: boolean;
  noRowBorder?: boolean;
  widthPercent?: boolean;
  height?: number;
  onButtonClick?: (rowId: string, row: Row<T>) => void;
  onIconClick?: (rowId: string, row: Row<T>) => void;
  activeRowIndex?: number;
};

type Row<T> = {
  rowData: T;
  rowIndex: number;
};

export const ListTable = <T extends object>({
  sortDirection: propsSortDirection = "ASC",
  showDoubleArrowOnNotSorted = true,
  nextSortDirection: propsNextSortDirection = {
    [SORT_DIRECTION.ASC]: SORT_DIRECTION.DESC,
    [SORT_DIRECTION.DESC]: SORT_DIRECTION.ASC,
  },
  indicatorName = {
    [SORT_DIRECTION.ASC]: "sort-up",
    [SORT_DIRECTION.DESC]: "sort-down",
  },
  ...props
}: Props<T>) => {
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [sortDirection, setSortDirection] = useState(propsSortDirection);

  const sortedData = props.sortedData
    ? props.sortedData
    : props.sortPredicates
    ? sortData({
        sortPredicates: props.sortPredicates,
        sortBy: sortBy,
        sortDirection: sortDirection,
        data: props.data,
      })
    : props.data;

  const setSortParams = (newSortBy: string) => {
    const nextSortDirection =
      newSortBy !== sortBy
        ? SORT_DIRECTION.ASC
        : propsNextSortDirection[sortDirection];

    setSortBy(newSortBy);
    setSortDirection(nextSortDirection);
    if (props.onSort) props.onSort(newSortBy, nextSortDirection);
  };

  const renderEmptyTable = () => (
    <tbody>
      <tr>
        <td
          colSpan={Children.count(props.children)}
          className="table__no-rows-renderer"
        >
          {typeof props.noRowsMessage === "string" ? (
            <Text fontSize="1.25em" fontWeight="medium" color="gray100">
              {props.noRowsMessage ? props.noRowsMessage : "No results found"}
            </Text>
          ) : (
            props.noRowsMessage
          )}
        </td>
      </tr>
    </tbody>
  );

  const renderHeader = () =>
    sortedData.length === 0 && props.noRowsMessage ? undefined : (
      <thead>
        <tr>
          {Children.map(props.children, (columnElement, idx) => {
            const sortKey = columnElement.props.sortKey;
            const isSortable =
              sortedData.length > 0 &&
              sortKey &&
              props.sortPredicates &&
              props.sortPredicates[sortKey] !== undefined;
            const isSortedByThisColumn = sortBy === sortKey;
            const currentIndicatorName = indicatorName[sortDirection];

            return (
              <th
                key={idx}
                style={
                  columnElement.props.width &&
                  (props.widthPercent
                    ? {width: columnElement.props.width + "%"}
                    : {width: columnElement.props.width + "px"})
                }
                onClick={() => {
                  isSortable && setSortParams(sortKey);
                }}
                data-test-id={`header-${columnElement.props.label}`}
                className={cx(
                  isSortable ? "sortable" : "notSortable",
                  isSortedByThisColumn ? "sorting" : "",
                )}
              >
                {columnElement.props.label}
                {isSortable && isSortedByThisColumn && currentIndicatorName && (
                  <Icon
                    name={currentIndicatorName as any}
                    color={theme.color.darkBlue70}
                    size={9}
                    className="listtable-0-52"
                  />
                )}
                {isSortable &&
                  !isSortedByThisColumn &&
                  showDoubleArrowOnNotSorted && (
                    <Icon
                      name="arrows"
                      color={theme.color.gray100}
                      size={9}
                      className="listtable-0-52"
                    />
                  )}
              </th>
            );
          })}
        </tr>
      </thead>
    );

  const renderBody = () => (
    <tbody>
      {sortedData.map((rowData, rowIdx) => (
        <Fragment key={rowIdx}>
          <tr
            className={cx(
              props.basic ? "list-table-basic__row" : "list-table__row",
              {
                "list-table__row--clickable": !!props.onRowClick,
              },
              props.activeRowIndex === rowIdx && "active",
            )}
            onClick={() => {
              const row = {rowData: rowData, rowIndex: rowIdx};

              if (props.onRowClick) {
                props.onRowClick(props.getRowId(row), row);
              }
            }}
          >
            {Children.map(props.children, (columnElement, cellIdx) => (
              <td
                key={cellIdx}
                onClick={() => {
                  const row = {rowData: rowData, rowIndex: rowIdx};

                  if (props.onButtonClick) {
                    props.onButtonClick(props.getRowId(row), row);
                  }
                  if (props.onIconClick && columnElement.props.iconClick) {
                    props.onIconClick(props.getRowId(row), row);
                  }
                }}
                style={
                  columnElement.props.width &&
                  (props.widthPercent
                    ? {width: columnElement.props.width + "%"}
                    : {width: columnElement.props.width + "px"})
                }
              >
                {columnElement.props.cellRenderer({
                  rowIndex: rowIdx,
                  rowData: rowData,
                })}
              </td>
            ))}
          </tr>
          {!props.basic && (
            <tr className="list-table__empty-row">
              <td colSpan={Children.count(props.children)} />
            </tr>
          )}
        </Fragment>
      ))}
    </tbody>
  );

  return (
    <table
      className={cx({
        "list-table": !props.basic,
        "list-table-basic": props.basic,
        "no-row-border": props.noRowBorder,
      })}
    >
      {renderHeader()}
      {renderBody()}
      {sortedData.length === 0 && renderEmptyTable()}
    </table>
  );
};

const compareWithEmptyStringsLast =
  (prop: string) => (A: object, B: object) => {
    const a = objectPath.get(A, prop);
    const b = objectPath.get(B, prop);

    if (typeof a === "number" && typeof b === "number") {
      return a - b;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (a === b) {
      return 0;
    }

    return a < b ? -1 : 1;
  };

const sortData = <T extends object>({
  sortPredicates,
  sortBy,
  data,
  sortDirection,
}: Pick<Props<T>, "sortPredicates" | "sortBy" | "data" | "sortDirection">) => {
  const predicates = sortPredicates[sortBy];

  if (predicates && data) {
    const comparators = predicates.map(compareWithEmptyStringsLast);

    return sort([...data], ...comparators, {
      reverse: sortDirection === SORT_DIRECTION.DESC,
    });
  }

  return data;
};
