import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useIntersection} from "react-use";

export const useIsInViewport = () => {
  const [wasInViewport, setWasInViewport] = useState(false);
  const ref = useRef(null);

  const {intersectionRatio = 0} =
    useIntersection(ref, {
      // TODO: parametrize
      root: document.querySelector(".js-reporting-scroll-container"),
      rootMargin: "0px",
      threshold: 1,
    }) ?? {};

  useEffect(() => {
    if (intersectionRatio === 1) {
      setWasInViewport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersectionRatio]);

  return {ref, wasInViewport};
};

export const UseIsInViewport = ({
  children,
}: {
  children: (props: ReturnType<typeof useIsInViewport>) => ReactNode;
}) => <>{children(useIsInViewport())} </>;
