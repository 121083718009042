import React, {useEffect, useState} from "react";
import moment from "moment";
import {connect} from "react-redux";
import {cx} from "@emotion/css";
import {FocusedInputShape, isInclusivelyBeforeDay} from "react-dates";

import {Button} from "@reside/ui";
import {Box} from "@reside/ui";

import {
  IDateRangeMomentGlobal,
  IFromTo,
} from "../../store/reporting/reporting.common";
import {select} from "../../store/index";
import {DateRangePicker} from "../date-range-picker";
import {CompareDateRangePresets} from "./CompareDateRangePresets";
import {CustomDateRangePreset} from "./DateRangePresets";

import "./CompareWrapper.scss";

type OwnProps = Readonly<{
  appliedCompareDateRange?: IFromTo;
  appliedDateRange?: IDateRangeMomentGlobal;
  chartId: string;
  className?: string;
  global?: boolean;
  onApply?: (dateRangeFromPicker: IDateRangeMomentGlobal) => void;
  onCloseComparePicker?: () => void;
}>;

type StateProps = Readonly<{
  adminSession?: any;
}>;

type Props = OwnProps & StateProps;

export const CompareWrapperRenderer = ({
  adminSession,
  appliedCompareDateRange,
  appliedDateRange,
  chartId,
  className,
  global,
  onApply,
  onCloseComparePicker,
}: Props) => {
  const [key, setKey] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputShape>("startDate");

  const clear = () => {
    setStartDate(null);
    setEndDate(null);
    setFocusedInput("startDate");
    onCloseComparePicker?.();
  };

  const apply = () => {
    if (!startDate || !endDate) {
      return;
    }

    onApply?.({
      ...CustomDateRangePreset,
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    });
  };

  const selectCompareRange = (range: string) => {
    const dd = calculateDiffDays(
      moment.utc(adminSession.activeDateRange.from),
      moment.utc(adminSession.activeDateRange.to),
    );

    if (range === "period") {
      setStartDate(
        moment.utc(adminSession.activeDateRange.from).subtract(dd + 1, "days"),
      );
      setEndDate(
        moment.utc(adminSession.activeDateRange.from).subtract(1, "day"),
      );
    } else if (range === "month") {
      setStartDate(
        moment.utc(adminSession.activeDateRange.from).subtract(1, "month"),
      );
      setEndDate(
        moment.utc(adminSession.activeDateRange.to).subtract(1, "month"),
      );
    } else {
      setStartDate(
        moment.utc(adminSession.activeDateRange.from).subtract(1, "year"),
      );
      setEndDate(
        moment.utc(adminSession.activeDateRange.to).subtract(1, "year"),
      );
    }
  };

  useEffect(() => {
    const dateRange = appliedCompareDateRange || appliedDateRange;

    if (dateRange) {
      setKey(moment.utc(dateRange.from).format("MM"));
      setStartDate(moment.utc(dateRange.from));
      setEndDate(moment.utc(dateRange.to));
    }
  }, [appliedCompareDateRange, appliedDateRange]);

  const renderButtons = () => (
    <div className="button-presets">
      {chartId !== "global" && (
        <div className="range">
          {CompareDateRangePresets.map(({label, range}, index) => {
            return (
              <button
                key={index}
                className="btn-daterange-preset"
                onClick={() => selectCompareRange(range)}
              >
                {label}
              </button>
            );
          })}
        </div>
      )}
      <Box width="100%" justifyContent="space-between" flexDirection="row">
        <Button onClick={clear}>Clear</Button>
        <Button
          color="primary"
          disabled={!startDate || !endDate}
          onClick={apply}
        >
          Apply
        </Button>
      </Box>
    </div>
  );

  return (
    <div
      className={cx("compare-wrapper", className, {
        global,
      })}
    >
      <span className="label-calendar-input">
        {global ? "Select date range" : "Compare to"}
      </span>
      <span className="label-date-input-start">Date from</span>
      <span className="label-date-input-end">Date to</span>
      <DateRangePicker
        key={key}
        startDate={startDate}
        startDateId={`start-date-id-${chartId}`}
        endDate={endDate}
        initialVisibleMonth={() => startDate || moment()}
        endDateId={`end-date-id-${chartId}`}
        focusedInput={focusedInput}
        firstDayOfWeek={1}
        minimumNights={0}
        noBorder
        small
        keepOpenOnDateSelect
        numberOfMonths={2}
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
        onClose={() => setFocusedInput(focusedInput)}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        onDatesChange={({startDate, endDate}) => {
          const now = moment().utc();

          const startDateWithTime = startDate
            ? getDateWithTime(startDate as any, now)
            : undefined;
          const endDateWithTime = endDate
            ? getDateWithTime(endDate as any, now)
            : undefined;

          setStartDate(startDateWithTime);
          setEndDate(endDateWithTime);
        }}
      />
      {renderButtons()}
    </div>
  );
};

const mapStateToProps = () =>
  select((models: any) => ({
    adminSession: models.adminSession.all,
  }));

export const CompareWrapper = connect(mapStateToProps)(CompareWrapperRenderer);

const calculateDiffDays = (a: moment.Moment, b: moment.Moment) =>
  b.diff(a, "days");

const getDateWithTime = (date: moment.Moment, now: moment.Moment) =>
  moment(date)
    .set("hours", now.get("hours"))
    .set("minutes", now.get("minutes"))
    .set("seconds", now.get("seconds"))
    .set("millisecond", now.get("milliseconds"));
