var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, } from "react";
import { Field } from "formik";
import { TextArea } from "../atoms/TextualInput";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { LockIcon } from "../atoms/LockIcon";
import { Label } from "../atoms/Label";
import { isInvalid } from "../formHelpers";
import { useDebounce } from "../../hooks/useDebounce";
import { ClearButton, TextAreaWrapper } from "./styles";
export var TextAreaField = function (_a) {
    var name = _a.name, disabled = _a.disabled, label = _a.label, autoResize = _a.autoResize, placeholder = _a.placeholder, FormField = _a.FormField, props = __rest(_a, ["name", "disabled", "label", "autoResize", "placeholder", "FormField"]);
    var _b = useState(false), focused = _b[0], setFocused = _b[1];
    var _c = useDebounce(name), innerValue = _c[0], setInnerValue = _c[1], handleOnChange = _c[2];
    var resizeTextArea = function (e) {
        e.currentTarget.style.height = "".concat(e.currentTarget.scrollHeight, "px");
    };
    return (React.createElement(FormField, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        return (React.createElement(Label, null,
            React.createElement(FloatingFieldLabel, { visible: Boolean(innerValue) }, label),
            React.createElement(TextAreaWrapper, null,
                React.createElement(TextArea, __assign({}, field, props, { value: innerValue || "", placeholder: placeholder || label, disabled: disabled, onBlur: function (event) {
                        setFocused(false);
                        field.onBlur(event);
                    }, onFocus: function () { return setFocused(true); }, onKeyUp: autoResize ? resizeTextArea : undefined, onChange: function (event) {
                        handleOnChange(event, form);
                    } })),
                field.value && !disabled && (React.createElement(ClearButton, { onClick: function () {
                        setInnerValue("");
                        form.setFieldValue(name, "");
                    } })),
                disabled && React.createElement(LockIcon, null)),
            React.createElement(FieldBorder, { focused: focused, invalid: isInvalid(name, form) }),
            React.createElement(FieldError, { name: name })));
    }));
};
TextAreaField.defaultProps = {
    FormField: Field,
};
