/** Check if string "true" is true and if string "false" is false. Usefull when dealing with storages. */

export const literalStringToBoolean = (value: string): boolean => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return false;
  }
};
