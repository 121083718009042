import {Facilities} from "../../models/types/AdmissionForm";

export const extractPccIntegrationStatusOfFacility = (
  facilities: Facilities,
  facilityId: string,
) => {
  const currentFacility = facilities?.find(
    facility => facility.id === facilityId,
  );

  return currentFacility?.pccIntegrationStatus;
};
