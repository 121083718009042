import jwtDecode from "jwt-decode";
import {isPast, toDate} from "date-fns";

enum SubjectType {
  /**
   * Token for listing/entering own admission from dashboard/QR code.
   */
  ADMISSION_ENTRY = "ADMISSION_ENTRY",
  /**
   * Token for listing/entering own admissions from email link.
   */
  ADMISSION_EMAIL_ENTRY = "ADMISSION_EMAIL_ENTRY",
  /**
   * Token for working with admission (resident experience).
   */
  ADMISSION = "ADMISSION",
  /**
   * Token for the admin app.
   */
  USER = "USER",
}

type Token = Readonly<{
  sub: string;
  exp: number;
  iat: number;
  subjectType: SubjectType;
  jti: string;
  single?: boolean;
}>;

const getTokenKey = (key: keyof Token) => (token: string) =>
  jwtDecode<Token>(token)[key];

export const getTokenSub = getTokenKey("sub");
export const getIsTokenSingleUse = getTokenKey("single");

const getTokenSubjectType = getTokenKey("subjectType");
const getTokenExp = getTokenKey("exp");

const isTokenType = (subjectType: SubjectType) => (token: string) =>
  getTokenSubjectType(token) === subjectType;

export const isAdmissionToken = isTokenType(SubjectType.ADMISSION);

export const isAdmissionEntryToken = isTokenType(SubjectType.ADMISSION_ENTRY);

export const isAdmissionEmailEntryToken = isTokenType(
  SubjectType.ADMISSION_EMAIL_ENTRY,
);

export const makeAuthorizationHeader = (token: string) => `Bearer ${token}`;

export const isTokenValid = (token: string) => {
  try {
    jwtDecode(token);
    return true;
  } catch {
    return false;
  }
};

export const tokenIsExpired = (token: string) =>
  isPast(toDate(1000 * (getTokenExp(token) as number)));
