import {useCallback} from "react";
import {Formik} from "formik";
import {FileField, FlexRow, Text} from "@reside/ui";

import {Space} from "../../../atoms/space";
import {useDocumentUpload} from "../hooks/useDocumentUpload";
import {UploadButton} from "./styles";
import {FormValues} from "../utils/types";

/** All upload logic. User selects a excel file than imports it on click of a button. */
export const UploadFileField = ({organizationId}: {organizationId: string}) => {
  const {mutate, isLoading} = useDocumentUpload(organizationId);

  const onClickImport = useCallback(
    ({file}: {file: File}, {resetForm}: {resetForm: () => void}) => {
      mutate(file);
      resetForm();
    },
    [mutate],
  );

  return (
    <Formik<FormValues>
      initialValues={{
        file: null,
      }}
      onSubmit={onClickImport}
    >
      {({submitForm, values}) => {
        return (
          <FlexRow>
            <Text color="gray100" fontWeight="600">
              Document Import
            </Text>
            <FlexRow>
              <Space>
                <UploadButton
                  color="success"
                  disabled={isLoading ? true : !values.file}
                  onClick={!isLoading && submitForm}
                >
                  {isLoading ? "Importing..." : "Import"}
                </UploadButton>
                <FileField
                  name="file"
                  title="Select File"
                  acceptedMediaTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                />
              </Space>
            </FlexRow>
          </FlexRow>
        );
      }}
    </Formik>
  );
};
