import React, {useCallback, useEffect} from "react";
import {PccFacilityMappingDto} from "@reside/reside-api-app";
import {Subtitle, Card} from "@reside/ui";
import {Formik} from "formik";

import {INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY} from "./constants";
import {BackdropSpinner} from "../../../../../atoms/spinner";
import {useFacilitySettingsParams} from "../../../hooks/useFacilitySettingsParams";
import {hasPccMappingData} from "./helpers";
import {FormButtons} from "./FormButtons";
import {FormFields} from "./FormFields";
import {
  usePccFacilityMappingQuery,
  usePccFacilityMappingMutation,
} from "../../../hooks";

/**
 * FacilityPccMappingForm component handles the form logic and UI for managing
 * PointClickCare (PCC) facility connection settings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isPccEnabled - Flag indicating if PCC is enabled for the facility.
 * @returns {JSX.Element} JSX element representing the PCC facility mapping form.
 *
 * @see {@link usePccFacilityMappingQuery} for fetching PCC facility mapping data.
 * @see {@link usePccFacilityMappingMutation} for updating the PCC facility mapping.
 *
 */
export const FacilityPccMappingForm = ({
  isPccEnabled,
}: {
  isPccEnabled: boolean;
}) => {
  const {facilityId} = useFacilitySettingsParams(); // Get facility ID from route parameters

  // State to hold the initial form values
  const [initialValues, setInitialValues] = React.useState(
    INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY,
  );

  // Fetch current PCC facility mapping data
  const {
    data: pccFacilityMapping,
    isFetching: isFetchingPccFacilityMapping,
    refetch: refetchPccFacilityMapping,
  } = usePccFacilityMappingQuery({
    facilityId,
  });

  // Mutation hook for updating the PCC facility mapping
  const {
    mutate: updatePccFacilityMapping,
    isLoading: isUpdatingPccFacilityMapping,
    isSuccess: isUpdatedPccFacilityMapping,
  } = usePccFacilityMappingMutation();

  // Handle form submission
  const onFormikSubmit = useCallback(
    (pccFacilityMapping: PccFacilityMappingDto) =>
      updatePccFacilityMapping({facilityId, pccFacilityMapping}),
    [updatePccFacilityMapping, facilityId],
  );

  // Refetch data if the update is successful
  useEffect(() => {
    if (isUpdatedPccFacilityMapping) refetchPccFacilityMapping();
  }, [isUpdatedPccFacilityMapping, refetchPccFacilityMapping]);

  // Re-set initial form values when data is fetched to avoid stale data
  useEffect(() => {
    if (hasPccMappingData(pccFacilityMapping))
      setInitialValues(pccFacilityMapping);
  }, [pccFacilityMapping]);

  return (
    <BackdropSpinner active={isFetchingPccFacilityMapping}>
      <Formik<PccFacilityMappingDto>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          onFormikSubmit(values);
        }}
      >
        {() => (
          <Card>
            <Subtitle>PointClickCare Connection Settings</Subtitle>

            <FormFields isPccEnabled={isPccEnabled} />

            <FormButtons
              isPccFacilityMappingUpdating={isUpdatingPccFacilityMapping}
            />
          </Card>
        )}
      </Formik>
    </BackdropSpinner>
  );
};
