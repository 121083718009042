import React from "react";
import {TextField, Text, List, theme} from "@reside/ui";
import {StyledCard} from "./styles";
import {generatePathPreview} from "./helpers";
import {PATH_VARIABLES} from "./consts";
import {EventTriggerForm} from "../../services/HubAndSpokeService";
import {useFormikContext} from "formik";

export const DocumentPaths = (): JSX.Element => {
  const {values} = useFormikContext<EventTriggerForm>();

  return (
    <StyledCard>
      <TextField
        type="document_path"
        name="document_path"
        label="Target"
        suffix=".pdf"
      />
      <div style={{marginBottom: theme.space[4], marginTop: theme.space[3]}}>
        <Text as="p" style={{marginBottom: theme.space[6]}}>
          <b>Path preview:</b> {generatePathPreview(values.document_path)}
        </Text>

        <Text fontSize="1.25em">
          The target file path where the PDF will be saved. The following
          variables are available:
        </Text>
      </div>
      <List
        items={PATH_VARIABLES.map(
          path => `${path.variable}: ${path.description}`,
        )}
      />
    </StyledCard>
  );
};
