import React from "react";
import Downshift, {DownshiftProps} from "downshift";
import {css} from "@emotion/css";

import {DropdownList, DataItemGroup, DataItem} from "./Dropdown";
import {RoundedSearchBox} from "./RoundedSearchBox";

type Props<Item = DataItem> = Pick<
  DownshiftProps<Item>,
  "itemToString" | "onChange"
> &
  Readonly<{
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressSpace?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    items?: ReadonlyArray<DataItemGroup<Item>>;
    isSearching: boolean;
    inputValue: string;
    onClearClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    showClearButton?: boolean;
    placeholder: string;
    hideSuggestion?: boolean;
    testId?: string;
    autoFocus?: boolean;
    autoComplete?: string;
  }>;

export const GeneralSearch = <Item extends DataItem>({
  hideSuggestion = false,
  inputValue,
  items,
  isSearching,
  itemToString,
  onChange,
  onClearClick,
  onInputChange,
  onPressEnter,
  onPressSpace,
  placeholder,
  autoFocus,
  autoComplete,
  showClearButton,
  testId,
}: Props<Item>) => (
  <Downshift itemToString={itemToString} onChange={onChange}>
    {({getInputProps, getItemProps, highlightedIndex, isOpen}) => (
      <div className={s.dropdownContainer}>
        <RoundedSearchBox
          placeholder={placeholder}
          value={inputValue}
          downshift={getInputProps}
          onInputChange={onInputChange}
          onPressEnter={highlightedIndex === null ? onPressEnter : () => {}}
          onPressSpace={onPressSpace}
          showClearButton={showClearButton}
          onClearClick={onClearClick}
          data-test-id={testId}
          autoComplete={autoComplete}
        />
        {!hideSuggestion && isOpen && (
          <DropdownList
            items={items}
            isSearching={isSearching && !hideSuggestion}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            data-test-id="search-results"
          />
        )}
      </div>
    )}
  </Downshift>
);

const s = {
  dropdownContainer: css`
    width: 100%;
    position: relative;
  `,
};
