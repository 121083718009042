import React from "react";

import {
  Button,
  styled,
  Text,
  ExternalLink,
  useSafeSetState,
  Tag,
} from "@reside/ui";
import {PccActivityLogDto} from "@reside/reside-api-admission";

import {Spinner} from "../../../atoms/spinner";
import * as url from "../../../utils/url";
import {PccModal} from "../../../atoms/pcc-modal";
import {
  colorMapActivityLogStatus,
  EventTypeEnumType,
  readableActivityLogEvent,
  readableActivityLogStatus,
} from "./pccHelpers";
import {useRetryActivity} from "../hooks";

const {EventTypeEnum, StatusEnum, ErrorCodeEnum} = PccActivityLogDto;

export const ActivityLogEventTypeCell = ({
  eventType,
}: {
  eventType: EventTypeEnumType;
}) => (
  <Text fontSize={3} fontWeight="medium" color="primary">
    {readableActivityLogEvent(eventType)}
  </Text>
);

export const ActivityLogStatusCell = ({
  activityLog,
}: {
  activityLog: PccActivityLogDto;
}) => (
  <ActivityLogStatusWrapper>
    <Tag color={colorMapActivityLogStatus(activityLog.status)}>
      {readableActivityLogStatus(activityLog.status)}
    </Tag>
    {activityLog ? <ActivityLogActionCell activityLog={activityLog} /> : null}
  </ActivityLogStatusWrapper>
);

const generateDownloadUrl = (admissionId: string) =>
  url.withAccessToken(url.toApi(`v3/admissions/${admissionId}/download-pdf`));

export const ActivityLogMessageCell = ({
  activity: {admissionId, message, eventType, status},
}: {
  activity: PccActivityLogDto;
}) => (
  <Text
    className="activity-log-message"
    color="gray100"
    fontSize={3}
    fontWeight="medium"
  >
    {message}
    {eventType === EventTypeEnum.UPLOAD_ADMISSION_PACKET &&
      status === StatusEnum.SUCCESS &&
      admissionId && (
        <ExternalLink
          href={generateDownloadUrl(admissionId)}
          className="sharedcells-link"
        >
          <br />
          Download PDF
        </ExternalLink>
      )}
  </Text>
);

export const ActivityLogActionCell = ({
  activityLog: {errorCode, id, facilityId, retryable, admissionId, eventType},
}: {
  activityLog: PccActivityLogDto;
}) => {
  const [showPccModal, setShowPccModal] = useSafeSetState(false);

  const {mutate, error, isLoading} = useRetryActivity(admissionId);

  const retryActivity = () =>
    mutate({
      activityId: id,
      facilityId,
    });

  const handleRetryClick = () =>
    errorCode === ErrorCodeEnum.ACCOUNT
      ? setShowPccModal(true)
      : retryActivity();

  const renderRetryButton = () => {
    if (!isLoading || error) {
      return (
        <Button color="primary" size="small" outline onClick={handleRetryClick}>
          Retry
        </Button>
      );
    }

    if (isLoading) {
      return (
        <>
          <CustomSpinner>
            <Spinner />
          </CustomSpinner>
          <UploadingWrapper>
            {eventType === EventTypeEnum.CREATE_PATIENT
              ? "Creating..."
              : "Uploading..."}
          </UploadingWrapper>
        </>
      );
    }
  };

  if (retryable) {
    return (
      <>
        {renderRetryButton()}
        {showPccModal && (
          <PccModal
            noSkipModal
            facilityId={facilityId}
            admissionId={admissionId}
            onRequestClose={retry => {
              setShowPccModal(false);
              retry && retryActivity();
            }}
          />
        )}
      </>
    );
  }

  return null;
};

const ActivityLogStatusWrapper = styled.div`
  width: 200px;

  > span,
  > button.small {
    width: 100%;
  }

  > button.small {
    margin-top: 5px;
    font-size: 13px;
    padding: 4px 10px;
`;

const UploadingWrapper = styled.div`
  text-align: center;
  color: ${({theme}) => theme.color.primary};
`;

const CustomSpinner = styled.div`
  .spinner-container {
    position: static;
    transform: translate(0);
  }
`;
