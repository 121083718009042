import React from "react";
import {styled, ThinScrollbar} from "@reside/ui";

import {
  DashboardGridColumn,
  DashboardGridColumnHeader,
} from "./DashboardGridColumn";
import {DASHBOARD_COLUMN_DATA} from "./DashboardTable";

export const DashboardGrid = () => (
  <ThinScrollbarArea>
    <ScrollableGrid>
      {DASHBOARD_COLUMN_DATA.map(({status, loadMoreButtonId}) => (
        <DashboardGridColumn
          key={status}
          status={status}
          loadMoreButtonId={loadMoreButtonId}
        />
      ))}
    </ScrollableGrid>
  </ThinScrollbarArea>
);

export const DashboardGridHeader = () => (
  <Grid>
    {DASHBOARD_COLUMN_DATA.map(({status}) => (
      <DashboardGridColumnHeader key={status} status={status} />
    ))}
  </Grid>
);

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 14px;
  justify-items: stretch;
  align-items: stretch;
`;

const ScrollableGrid = styled(Grid)`
  overflow-y: scroll;
  overflow-y: overlay; // Works everywhere except Firefox
`;

const ThinScrollbarArea = styled(ThinScrollbar)`
  display: flex;
  flex-grow: 1;
  height: 1px; // Hack for flex to grow to parent height, but limit children height so it's scrollable
`;
