import {history} from "../../utils/history";
import {select, store} from "../../store";
import {makeAuthorizationHeader} from "../../utils/token";

export const getCommonAuthorizationHeader = () =>
  makeAuthorizationHeader(
    history.location.pathname.startsWith("/admin")
      ? select.adminSession.getToken(store.getState())
      : select.admissionSession.getToken(store.getState()),
  );
