import React from "react";
import {Formik} from "formik";
import {Resident} from "../../page-admin-residents/types";
import {
  FormCard,
  FormSlide,
  GenderField,
  SelectField,
  SSNField,
  TextField,
  DateFieldInput,
  FlexRow,
} from "@reside/ui";
import {CreateResidentDto} from "../types";
import {UseMutateFunction} from "react-query";
import {ApiResponse} from "apisauce";
import {validate} from "./validation";
import {CreateFormActionButtons} from "./CreateFormActionButtons";
import {EditFormActionButtons} from "./EditFormActionButtons";
import {SimilarResidents} from "./SimilarResidents";
import {ResidentFormWrap} from "./ResidentFormWrap";
import {useOrganizationsQuery} from "../../page-admin-user-edit/hooks/useOrganizationsQuery";
import {AppState, select} from "../../../store";
import {connect} from "react-redux";
import {UserDto} from "@reside/reside-api-app";
import {isResideAdmin} from "../../../utils/helpers/users";

const Renderer = ({
  initialValues,
  handleSubmit,
  edit = false,
  isInactive,
  user,
}: {
  initialValues: CreateResidentDto;
  handleSubmit: UseMutateFunction<
    ApiResponse<Resident, Resident>,
    any,
    CreateResidentDto
  >;
  /** Enable component to know if it's in edit or create form  */
  edit?: boolean;
  /** Controll if input field will be locked */
  isInactive?: boolean;
  user: UserDto;
}): JSX.Element => {
  const isResideAdminUser = isResideAdmin(user);
  const {data: organizations, isLoading: fetchingOrganizations} =
    useOrganizationsQuery();

  const getOrganizations = () => {
    if (isResideAdminUser && organizations && !fetchingOrganizations) {
      return organizations.map(({id, name}) => ({
        label: name,
        value: id,
      }));
    }
    return [];
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      validate={(values: CreateResidentDto) =>
        validate(values, isResideAdminUser, edit)
      }
    >
      {({submitForm, dirty}) => {
        return (
          <ResidentFormWrap>
            <FormSlide>
              <FormCard title="Resident Information">
                {isResideAdminUser && !edit && (
                  <SelectField
                    name="organization_id"
                    label="Organization"
                    options={getOrganizations()}
                    disabled={!organizations || fetchingOrganizations}
                  />
                )}
                <TextField
                  label="First Name"
                  name="first_name"
                  disabled={isInactive}
                />
                <TextField
                  label="Middle Name"
                  name="middle_name"
                  disabled={isInactive}
                />
                <TextField
                  label="Last Name"
                  name="last_name"
                  disabled={isInactive}
                />
                <GenderField
                  label="Gender"
                  name="gender"
                  disabled={isInactive}
                />
                <DateFieldInput
                  label="Date of birth"
                  name="date_of_birth"
                  disabled={isInactive}
                  range={{
                    before: [150, "year"],
                    after: [0, "day"],
                  }}
                />
                <SSNField
                  label="Social security number"
                  name="ssn"
                  disabled={isInactive}
                />
                <TextField
                  label="Phone"
                  name="phone_number"
                  format="us-phone"
                  disabled={isInactive}
                />
                <TextField
                  label="Email Address"
                  name="email"
                  type="email"
                  disabled={isInactive}
                />

                {!edit && (
                  <SimilarResidents isResideAdminUser={isResideAdminUser} />
                )}

                <FlexRow
                  style={{
                    justifyContent: "flex-end",
                    gap: 16,
                    marginTop: 32,
                    // Position button outside of form visually but inside of it functionally
                    position: "absolute",
                    top: -40,
                    right: 0,
                  }}
                >
                  {edit ? (
                    <EditFormActionButtons
                      editFormSubmit={submitForm}
                      disabled={!dirty && !isInactive}
                    />
                  ) : (
                    <CreateFormActionButtons createFormSubmit={submitForm} />
                  )}
                </FlexRow>
              </FormCard>
            </FormSlide>
          </ResidentFormWrap>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const ResidentFormik = connect(mapStateToProps)(Renderer);
