import {pickBy, mapValues, isEqual} from "lodash";
import {ChecklistItem} from "@reside/reside-api-admission";
import {ChecklistValues} from "./types";

/**
 * The initial values become stalled "formValues" after user interacts with the form.
 * When the checklist item is updated (PCC document is uploaded) we pick the changed items from initial values.
 */
export const pickUpdatedChecklistItems = (
  initialValues: ChecklistValues,
  formValues: ChecklistValues,
) =>
  pickBy(initialValues, value => {
    if (value.id) {
      // object with id is ChecklistItem
      return !isEqual(value, formValues[value.id]);
    } else {
      return false;
    }
  });

/**
 * HACK: The "checked: false" is not caught by the `required` validator, so we drop the key.
 * The missing key causes the `required` validation rule to produce validation error.
 */
export const dropFalseCheckedValues = (values: Record<string, any>) =>
  mapValues(values, value => {
    if (value?.checked === false) {
      const {checked, ...val} = value;
      return val;
    }
    return value;
  });

export const getChecklistValidationRules = (
  checklist: ReadonlyArray<ChecklistItem>,
) =>
  checklist
    .filter(({mandatoryForStatuses = []}) =>
      mandatoryForStatuses.some(
        status => status === ChecklistItem.MandatoryForStatusesEnum.APPROVED,
      ),
    )
    .reduce<Validator.Rules>(
      (rules, item) => ({...rules, [item.id]: {checked: "required"}}),
      {},
    );
