import React, {useCallback, useMemo, useState} from "react";
import {Formik, Form} from "formik";
import {
  extractTemplateSlides,
  getValidationConfig,
  Values,
  Template,
} from "@reside/forms";
import {
  styled,
  FormikValidateAllOnSubmit,
  FormikAutoSaveValues,
  ScrollToError,
  ScrollableErrorArea,
  BlockField,
  BlockComponentContextProvider,
} from "@reside/ui";

import {components} from "../../../../atoms/block-components";

export type Props = Readonly<{
  template: Template;
  /**
   * Initial Form values for the template.
   */
  initialValues: Values;
  onSubmit?: (values: Values) => Promise<void>;
}>;

export const ResidentExperiencePreludeForm = ({
  template,
  initialValues,
  onSubmit,
}: Props) => {
  const [values, setValues] = useState<Values>(initialValues);

  const handleValuesChange = useCallback(
    values => {
      setValues(values);
    },
    [setValues],
  );

  const [{children: blocks}] = useMemo(
    () => extractTemplateSlides(template, values),
    [values, template],
  );

  const {validate} = useMemo(
    () =>
      getValidationConfig({
        children: blocks,
        answers: values,
      }),
    [blocks, values],
  );

  return (
    <Formik
      initialValues={values}
      validate={validate}
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);

        await onSubmit(values);

        formik.setSubmitting(false);
      }}
    >
      <Form autoComplete="off">
        <FormikValidateAllOnSubmit />
        <FormikAutoSaveValues
          disableDebounce
          onChangeValues={handleValuesChange}
        />
        <ScrollToError />
        <StyledScrollableErrorArea>
          <BlockComponentContextProvider value={components}>
            {blocks.map(block => (
              <BlockField key={block.id} block={block} />
            ))}
          </BlockComponentContextProvider>
        </StyledScrollableErrorArea>
      </Form>
    </Formik>
  );
};

ResidentExperiencePreludeForm.defaultProps = {
  onSubmit: async () => Promise.resolve(),
};

const StyledScrollableErrorArea = styled(ScrollableErrorArea)`
  padding: 40px 0;
  overflow: auto;
  max-height: 100vh; // the scroll breaks without explicit height
`;
