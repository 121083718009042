import React, {Component} from "react";
import Alert from "react-s-alert";
import {extractInitialFormValuesForBlock, Values} from "@reside/forms";

import {AdmissionStatus} from "../../../services/AdmissionsService";
import {SatisfactorySurveyOverlay} from "./satisfactory-survey-overlay";
import {SignOverlay} from "./SignOverlay";
import {BlockSlideOverlay} from "./BlockSlideOverlay";
import {Draft, DraftActions, UIActions, UIState} from "../model";
import {readFacilityNameAnswer} from "../../../models/AdmissionModel.helpers";

type Props = Readonly<{
  draft: Draft;
  draftActions: DraftActions;
  uiState: UIState;
  uiActions: UIActions;
  onRequestClose: () => void;
  logoutSession: () => void;
}>;

type State = Readonly<{
  isSigning: boolean;
  slideIndex: number;
}>;

export class SignAdmissionOverlay extends Component<Props, State> {
  state: State = {
    isSigning: false,
    slideIndex: 0,
  };

  continueToNextSlide = (values: Values) => {
    this.props.draftActions.setAfterAdmissionAnswers(values);

    this.setState({slideIndex: this.state.slideIndex + 1});
  };

  applySignature = async () => {
    try {
      const slide = this.props.draft.slides[this.props.uiState.slideIdx];

      await this.props.draftActions.sign(slide.id);
    } catch (resp) {
      const error = await resp.json();
      this.props.onRequestClose();

      if (error?.data) {
        const [slideErrors] = error.data.errors;

        if (slideErrors?.slideId) {
          this.props.uiActions.goToErrorSlide(slideErrors.slideId);
        }

        Alert.error("Please complete this slide before signing the Admission.");
      } else {
        Alert.error("An error occurred while signing the admission.");
      }
    }
  };

  signAdmission = () => {
    this.setState({isSigning: true});
    this.applySignature().finally(() => this.setState({isSigning: false}));
  };

  renderBeforeSignatureSlides = () => {
    const slides = this.props.draft.afterAdmissionSlides.filter(
      slide => slide?.state?.visible,
    );

    return this.state.slideIndex < slides.length ? (
      <BlockSlideOverlay
        slide={slides[this.state.slideIndex]}
        onSubmit={this.continueToNextSlide}
        initialValues={extractInitialFormValuesForBlock({
          children: slides[this.state.slideIndex].children,
          values: this.props.draft.answers,
        })}
      />
    ) : (
      <SatisfactorySurveyOverlay
        facilityName={readFacilityNameAnswer(this.props.draft.instance)}
        values={this.props.draft.instance.satisfactorySurvey}
        onChange={this.props.draftActions.setSatisfactorySurvey}
        onSubmit={async values => {
          await this.props.draftActions.setSatisfactorySurvey(values);
          this.props.logoutSession();
          this.props.onRequestClose();
        }}
      />
    );
  };

  render() {
    return this.props.draft.instance.status === AdmissionStatus.SIGNED &&
      !this.state.isSigning ? (
      this.renderBeforeSignatureSlides()
    ) : (
      <SignOverlay
        draft={this.props.draft}
        isSigning={this.state.isSigning}
        onRequestClose={this.props.onRequestClose}
        signAdmission={this.signAdmission}
        signOverlay={this.props.draft.afterAdmissionSlides.find(
          slide => slide.id === "cb0e2182-1644-4a0e-addc-eea9ee828a97", // unique ID of custom sign overlay slide
        )}
      />
    );
  }
}
