import React from "react";
import {formatDistance, format, isToday} from "date-fns";
import {DATE_FORMAT} from "@reside/ui";

type Props = Readonly<{
  dateTime: Date;
  /**
   * flag indicating whether to format the date as  "MMMM d, yyyy"
   * or just show the time difference between current date
   */
  onlyToday?: boolean;
  dateFormat?: string;
}>;

export const RelativeDateTime = ({dateTime, onlyToday, dateFormat}: Props) => (
  <time title={format(dateTime, DATE_FORMAT.FULL_DATE_TIME)}>
    {onlyToday
      ? renderWithDate(dateTime, dateFormat)
      : formatDistance(dateTime, Date.now(), {addSuffix: true})}
  </time>
);

RelativeDateTime.defaultProps = {
  onlyToday: true,
  dateFormat: DATE_FORMAT.DAY,
};

const renderWithDate = (dateTime: Date, dateFormat: string) =>
  isToday(dateTime)
    ? formatDistance(dateTime, Date.now(), {addSuffix: true})
    : format(dateTime, dateFormat);
