"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noneIn = void 0;
var noneIn = function (value, arr) {
    if (value === void 0) { value = ""; }
    // TODO zisti, preco sa tu niekedy dostane null
    if (value === null || typeof value === "string") {
        return !(value !== null && value.length > 0) || !arr.includes(value);
    }
    var values = value.filter(function (val) { return val !== ""; });
    if (values.length === 0 || arr.length === 0) {
        return true;
    }
    return !values.some(function (v) { return arr.includes(v); });
};
exports.noneIn = noneIn;
