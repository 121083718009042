import React, {FunctionComponent} from "react";
import {Link, LinkProps} from "react-router-dom";
import {styled, Button, ButtonProps} from "@reside/ui";

type Props = Readonly<Omit<ButtonProps, "title"> & Pick<LinkProps, "to">>;

export const ButtonLink: FunctionComponent<Props> = ({
  children,
  to,
  ...buttonProps
}) => (
  <PlainLink to={to}>
    <Button {...buttonProps}>{children}</Button>
  </PlainLink>
);

const PlainLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`;

ButtonLink.defaultProps = {
  outline: true,
  color: "primary",
};
