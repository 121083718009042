var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, } from "react";
import { Field } from "formik";
import { FlexRowCenter } from "../../box";
import { Input } from "../atoms/TextualInput";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { LockIcon } from "../atoms";
import { Label } from "../atoms/Label";
import { ClearFieldButton } from "../atoms/clear-field-button";
import { isInvalid } from "../formHelpers";
import { useDebounce } from "../../hooks/useDebounce";
import { formatFieldValue, isValueFilled, processValue } from "./helpers";
export var TextField = function (_a) {
    var name = _a.name, label = _a.label, type = _a.type, suffix = _a.suffix, capitalize = _a.capitalize, numberOfBlocks = _a.numberOfBlocks, disabled = _a.disabled, autoComplete = _a.autoComplete, placeholder = _a.placeholder, renderBefore = _a.renderBefore, renderAfter = _a.renderAfter, fractionalDigits = _a.fractionalDigits, uppercase = _a.uppercase, format = _a.format, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, FormField = _a.FormField, props = __rest(_a, ["name", "label", "type", "suffix", "capitalize", "numberOfBlocks", "disabled", "autoComplete", "placeholder", "renderBefore", "renderAfter", "fractionalDigits", "uppercase", "format", "onChange", "onFocus", "onBlur", "FormField"]);
    var _b = useState(false), focused = _b[0], setFocused = _b[1];
    var _c = useDebounce(name, numberOfBlocks), innerValue = _c[0], setInnerValue = _c[1], handleOnChange = _c[2];
    return (React.createElement(FormField, { name: name, type: type }, function (_a) {
        var _b;
        var field = _a.field, form = _a.form;
        var value = field.value;
        var setFieldValue = form.setFieldValue, setFieldTouched = form.setFieldTouched;
        var isFilled = isValueFilled(value);
        var hasError = isInvalid(name, form);
        var onReset = function () {
            setInnerValue(suffix !== null && suffix !== void 0 ? suffix : "");
            setFieldValue(name, suffix !== null && suffix !== void 0 ? suffix : "");
        };
        return (React.createElement(Label, null,
            label && (React.createElement(FloatingFieldLabel, { uppercase: uppercase, visible: isFilled }, label)),
            React.createElement(FlexRowCenter, null,
                renderBefore({ value: value }),
                React.createElement(Input, { autoComplete: autoComplete, name: name, value: innerValue !== undefined
                        ? innerValue
                        : isFilled
                            ? processValue(fractionalDigits, innerValue)
                            : "", placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onBlur: function (event) {
                        setFocused(false);
                        setFieldTouched(name, true);
                        if (fractionalDigits) {
                            if (event.currentTarget.value !== "") {
                                setInnerValue(Number(event.currentTarget.value).toFixed(fractionalDigits));
                                setFieldValue(name, Number(event.currentTarget.value).toFixed(fractionalDigits));
                            }
                        }
                        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
                    }, onChange: function (event) { return (onChange
                        ? onChange(event)
                        : handleOnChange(event, form, formatFieldValue(event.currentTarget.value, {
                            type: type,
                            name: name,
                            suffix: suffix,
                            format: format,
                            capitalize: capitalize,
                            fractionalDigits: fractionalDigits,
                        }))); }, onFocus: function (event) {
                        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
                        setFocused(true);
                    }, disabled: disabled, type: type, "data-test-id": (_b = props["data-test-id"]) !== null && _b !== void 0 ? _b : "" }),
                renderAfter({ value: value }),
                isFilled && !disabled && React.createElement(ClearFieldButton, { onClick: onReset }),
                disabled && React.createElement(LockIcon, null)),
            React.createElement(FieldBorder, { focused: focused, invalid: hasError }),
            React.createElement(FieldError, { name: name })));
    }));
};
TextField.defaultProps = {
    type: "text",
    capitalize: true,
    renderBefore: function () { return null; },
    renderAfter: function () { return null; },
    FormField: Field,
    /**
     * HACK: prevents chrome address autofill. Not standard value.
     */
    autoComplete: "none",
};
