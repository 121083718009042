import React from "react";
import {Text, styled, ProgressBar} from "@reside/ui";

export const PercentageCell = ({data}: {data: number}) => (
  <ProgressBar width={80} height={18} progress={data}>
    <PercentageText color="darkBlue100" fontWeight="medium">
      {`${data}%`}
    </PercentageText>
  </ProgressBar>
);

const PercentageText = styled(Text)`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 2px;
`;
