import React, {ReactNode} from "react";
import {css} from "@emotion/css";
import {styled, theme, Text, Icon, IconName} from "@reside/ui";

type Props = Readonly<{
  active?: boolean;
  iconName: IconName;
  title: ReactNode;
}>;

export const MenuCategory = ({active, iconName, title}: Props) => (
  <Category>
    <Icon
      name={iconName}
      size={20}
      color={active ? theme.color.primary : theme.color.gray100}
      className={css`
        margin-right: 12px;
      `}
    />
    <Text fontSize={3} color={active ? "primary" : "darkBlue100"}>
      {title}
    </Text>
  </Category>
);

const Category = styled.div`
  margin: 14px 0;
`;
