import React from "react";
import {styled} from "@reside/ui";

import {AdmissionStatus} from "../../services/AdmissionsService";
import {DashboardTableColumn} from "./DashboardTableColumn";

export const DASHBOARD_COLUMN_DATA = [
  {
    status: AdmissionStatus.PRE_FLIGHT,
    loadMoreButtonId: "loadMoreBtnPreFlight",
  },
  {
    status: AdmissionStatus.IN_PROGRESS,
    loadMoreButtonId: "loadMoreBtnInProgress",
  },
  {
    status: AdmissionStatus.SIGNED,
    loadMoreButtonId: "loadMoreBtnSigned",
  },
  {
    status: AdmissionStatus.APPROVED,
    loadMoreButtonId: "loadMoreBtnApproved",
  },
];

export const DashboardTable = () => (
  <Layout>
    {DASHBOARD_COLUMN_DATA.map(({status, loadMoreButtonId}) => (
      <DashboardTableColumn
        key={status}
        status={status}
        loadMoreButtonId={loadMoreButtonId}
      />
    ))}
  </Layout>
);

const Layout = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 -7px 15px;
`;
