import {PatchFacilityDto} from "@reside/reside-api-app";
import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
} from "react";

const context = createContext({
  facilityId: undefined,
  fields: undefined,
  toggle: (facilityId?: string, fields?: PatchFacilityDto) => {},
});

export const useEditFacilityModalContext = () => useContext(context);

export const EditFacilityModalProvider: FunctionComponent = ({children}) => {
  const [facilityId, setFacilityId] = useState<string>();
  const [fields, setFields] = useState<PatchFacilityDto>();

  return (
    <context.Provider
      value={{
        fields,
        facilityId,
        toggle: (newFacilityId?: string, newFields?: PatchFacilityDto) => {
          setFacilityId(newFacilityId);
          setFields(newFields);
        },
      }}
    >
      {children}
    </context.Provider>
  );
};
