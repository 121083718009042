import {useQuery, UseQueryOptions} from "react-query";
import Alert from "react-s-alert";

import {AdmissionsService} from "../services/AdmissionsService";
import {DocumentService} from "../services/DocumentService";

/**
 * Fetches the template version of the admission (when admissionId provided) or latest version for the facility.
 */
export const useTemplateVersionQuery = ({
  facilityId,
  admissionId,
  options,
}: {
  facilityId: string;
  admissionId?: string;
  options?: UseQueryOptions<{
    admission?: any;
    version: any;
  }>[];
}) =>
  useQuery(
    ["templateVersion", facilityId, admissionId],
    async () => {
      if (admissionId) {
        const {data: admission} = await AdmissionsService.get(admissionId);

        const {data: version} = await DocumentService.getTemplateVersion(
          facilityId,
          admission.templateId,
        );

        return {admission, version};
      }

      const {data: version} = await DocumentService.getLatestTemplateVersion(
        facilityId,
      );

      return {version};
    },
    {
      placeholderData: {version: undefined, admission: undefined},
      onError: () =>
        Alert.error(
          "Failed to load template version. Please, try again later.",
        ),
      ...options,
    },
  );
