import React from "react";

import {styled} from "@reside/ui";
export const FlagListHeader = () => (
  <Wrapper>
    <>Flagged Items</>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: 4px 4px 0 0;
  width: 100%;
  color: ${({theme}) => theme.color.darkBlue40};
  background-color: ${({theme}) => theme.color.gray5};
`;
