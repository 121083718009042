import {styled, Modal} from "@reside/ui";

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const LineFields = styled.div`
  display: flex;
  flex-direction: row;
  label:first-child {
    margin-right: 30px;
  }
`;

export const Space = styled.div`
  margin: ${({theme}) => theme.space[6]}px 0;
`;

export const Content = styled(Modal.defaultProps.Content)`
  padding-right: 10px;
`;
