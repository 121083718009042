import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  CompareDateLabels,
  getAggregationValues,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportAdmission,
  ReportAggregationTypes,
  ReportsType,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {Aggregation} from "../../../atoms/admin-reporting/atoms/aggregation";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  DateReportsCell,
  IconCheckCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";

export const MEDICARE_WAIVER_REPORT_ID = "medicare-waiver" as const;

export const ReportMedicareWaiver = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.facilityName,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle
          title="Waiver of Medicare Benefits"
          subtitle="by Admissions"
        />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>
      <RequiredUserPermission
        permission={TableUserDto.PermissionsEnum.MEDICARE_WAIVER}
      >
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.medicareWaiver,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled={props.reportingBlockSetters.enabled}
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <div className="aggregation-row">
                <Aggregation
                  reports
                  subject="YES"
                  values={getAggregationValues(
                    d,
                    ReportAggregationTypes.positiveAnswersTotal,
                  )}
                />
                <Aggregation
                  reports
                  subject="NO"
                  values={getAggregationValues(
                    d,
                    ReportAggregationTypes.negativeAnswersTotal,
                  )}
                />
              </div>
              <ListTableReporting<ReportAdmission>
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Admissions found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportAdmission>
                  sortKey={SortField.residentName}
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.residentName}
                    </TextCellEllipsis>
                  )}
                  label="Resident"
                  width={28}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.facilityName}
                    </TextCellEllipsis>
                  )}
                  label="Facility"
                  sortKey={SortField.facilityName}
                  width={27}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <DateReportsCell data={row.rowData.createdDate} />
                  )}
                  label="Created Date"
                  sortKey={SortField.createdDate}
                  width={15}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <DateReportsCell data={row.rowData.admissionDate} />
                  )}
                  label="Admission Date"
                  sortKey={SortField.admissionDate}
                  width={15}
                />

                <Column<ReportAdmission>
                  cellRenderer={(row: any) => (
                    <DateReportsCell data={row.rowData.sentToResidentDate} />
                  )}
                  label="Sent to Resident Date"
                  sortKey={SortField.sentToResidentDate}
                  width={15}
                />

                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <IconCheckCell accepted={row.rowData.answerIsPositive} />
                  )}
                  label="Yes"
                  sortKey={SortField.answerIsPositive}
                  width={10}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <IconCheckCell accepted={!row.rowData.answerIsPositive} />
                  )}
                  label="No"
                  sortKey={SortField.answerIsNegative}
                  width={10}
                />
              </ListTableReporting>
              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
