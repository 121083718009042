/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {TableUserDto} from "@reside/reside-api-app";

import {
  ConnectedReportingBlock,
  ReportingBlock,
} from "../../atoms/reporting-block";
import {
  AnalyticsAdmissionsTotal,
  AnalyticsAdmissionsCompletionOnTime,
  AnalyticsAdmissionsCompletionOverall,
  AnalyticsAdmissionsFacilityCompletionRate,
  AnalyticsArbitrationAgreementRate,
  AnalyticsAdmissionsTotalResidents,
  AnalyticsPayerSourceAge65_75,
  AnalyticsPayerSourceAge75_85,
  AnalyticsPayerSourceAge85,
  AnalyticsFlagsAge,
  AnalyticsTimeByStage,
  AnalyticsMedicareByFacility,
  AnalyticsFlagsBySlidePie,
  AnalyticsTimeToCloseByFacility,
  AnalyticsRepresentativeRateTimeSeries,
  AnalyticsRepresentativeRateByFacility,
  AnalyticsManagedMedicaidTimeSeries,
  AnalyticsManagedMedicaidByFacility,
  AnalyticsWithoutMedicalSupplementTimeSeries,
  AnalyticsWithoutMedicalSupplementByFacility,
  AnalyticsReAdmissionRateTimeSeries,
  AnalyticsReAdmissionRateByFacility,
  AnalyticsAdmissionsStaffCompletionRate,
  AnalyticsPayerSourceAge65_75ByCountry,
  AnalyticsPayerSourceAge75_85ByCountry,
  AnalyticsPayerSourceAge85ByCountry,
  ADMISSIONS_TOTAL_CHART_ID,
  TIME_TO_CLOSE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID,
  RE_ADMISSION_RATE_BY_FACILITY_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  FACILITY_MEDICARE_CHART_ID,
} from "./analytics";
import {
  ReportTotalAdmissions,
  ReportFacilityChanges,
  ReportSNFABNEstimatedCostAdmission,
  ReportArbitrationAgreement,
  ReportSlideInteractions,
  ReportMedicaidRequest,
  ReportPersonalResourceIncomeAgreement,
  ReportTrustFund,
  ReportLaundry,
  ReportInfluenza,
  ReportPneumococcal,
  ReportSurrogateDecisionMaking,
  ReportSurrogateDecisionMakingHelp,
  ReportExistingADProvided,
  ReportMedicareWaiver,
  ReportListOfResidents,
  ReportPhotos,
  ReportStaff,
  ReportFuneral,
  ReportADHelp,
  ReportChecklistItem,
  TOTAL_ADMISSIONS_REPORT_ID,
  LIST_OF_RESIDENTS_REPORT_ID,
  ARBITRATION_AGREEMENT_REPORT_ID,
  INCOME_REPORT_ID,
  TRUST_FUND_REPORT_ID,
  PNEUMOCOCCAL_REPORT_ID,
  INFLUENZA_REPORT_ID,
  SURROGATE_REPORT_ID,
  SURROGATE_HELP_REPORT_ID,
  AD_PROVIDED_REPORT_ID,
  AD_HELP_REPORT_ID,
  MEDICARE_WAIVER_REPORT_ID,
  MEDICAID_REQUEST_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_REPORT_ID,
  FACILITY_CHANGES_REPORT_ID,
  FUNERAL_REPORT_ID,
  LAUNDRY_REPORT_ID,
  PHOTOS_REPORT_ID,
  SLIDE_INTERACTIONS_REPORT_ID,
} from "./reports";
import {UrlProps} from "../../atoms/reporting-block";
import {IFromTo, Facility} from "../../store/reporting/reporting.common";

import {ReportingLayout} from "./reporting-layout";
import {Context, ReportContextConsumer} from "./ReportContext";
import {UrlSync} from "./UrlSync";
import {useChecklistItems} from "./reporting-menu/useChecklistItems";
import {RequiredUserPermissionsSome} from "../../atoms/admin-user-permissions/RequiredUserPermissionsSome";

import {Spinner} from "../../atoms/spinner";
import {Banner} from "../../atoms/banner";
import {DocumentTitle} from "../../atoms/document-title";

type Props = RouteComponentProps<UrlProps>;

const Renderer = ({match, location, history}: Props) => (
  <>
    <DocumentTitle
      title={`Reside - Admin - Full Report ${match.params.chartId}`}
    />
    <ReportContextConsumer>
      {({
        baseDateRange,
        activeFacilitiesIds,
        setActiveFacilities,
        setBaseDateRange,
      }) => {
        return (
          <UrlSync
            match={match}
            history={history}
            location={location}
            baseDateRange={baseDateRange}
            setBaseDateRange={setBaseDateRange}
            activeFacilitiesIds={activeFacilitiesIds}
            setActiveFacilities={setActiveFacilities}
          />
        );
      }}
    </ReportContextConsumer>
    <ReportingLayout>
      <ReportContextConsumer>
        {({
          baseDateRange,
          activeFacilitiesIds,
          pageLoading,
          setPageLoading,
        }) => {
          return (
            <>
              {match.params.type === "analytics" && (
                <AnalyticsBlocks
                  dateRange={baseDateRange}
                  facilities={activeFacilitiesIds}
                />
              )}
              {match.params.type === "reports" &&
              match.params.chartGroup === "checkboxes" ? (
                <RequiredUserPermissionsSome
                  permissions={[TableUserDto.PermissionsEnum.CHECKLIST_ITEMS]}
                >
                  <CheckboxesFullReport
                    baseDateRange={baseDateRange}
                    activeFacilitiesIds={activeFacilitiesIds}
                    pageLoading={pageLoading}
                    setPageLoading={setPageLoading}
                    chartId={match.params.chartId}
                  />
                </RequiredUserPermissionsSome>
              ) : (
                <ReportsBlocks
                  dateRange={baseDateRange}
                  facilities={activeFacilitiesIds}
                />
              )}
            </>
          );
        }}
      </ReportContextConsumer>
    </ReportingLayout>
  </>
);

const CheckboxesFullReport = ({
  chartId,
  baseDateRange,
  activeFacilitiesIds,
  pageLoading,
  setPageLoading,
}: Readonly<{chartId: string}> &
  Pick<
    Context,
    "baseDateRange" | "activeFacilitiesIds" | "pageLoading" | "setPageLoading"
  >) => {
  const {
    isSuccess,
    isFetched,
    isError,
    data: checklistItems,
  } = useChecklistItems();

  const checklistItem = checklistItems.find(item => item.id === chartId);

  return isFetched && isSuccess ? (
    checklistItem ? (
      <ReportingBlock
        chartId={chartId}
        permissionInChart={[TableUserDto.PermissionsEnum.CHECKLIST_ITEMS]}
        dateRange={baseDateRange}
        facilities={activeFacilitiesIds}
        setPageLoading={setPageLoading}
      >
        {reportingBlockSetters => (
          <ReportChecklistItem
            id={chartId}
            description={checklistItem.description}
            dateRange={baseDateRange}
            facilities={activeFacilitiesIds}
            reportingBlockSetters={reportingBlockSetters}
          />
        )}
      </ReportingBlock>
    ) : (
      <Banner title="Checklist Item Not Found">
        <p>Try to select another facility, date range or checkbox report.</p>
      </Banner>
    )
  ) : isError ? (
    <Banner title="Failed to load Checklist Items" />
  ) : (
    <Spinner />
  );
};

type BlocksProps = {
  dateRange: IFromTo;
  facilities: Facility[];
};

const AnalyticsBlocks = (props: BlocksProps) => (
  <>
    <ConnectedReportingBlock chartId={ADMISSIONS_TOTAL_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsTotal
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={TIME_TO_CLOSE_BY_FACILITY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsTimeToCloseByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={REPRESENTATIVE_RATE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsRepresentativeRateTimeSeries
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsRepresentativeRateByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={MANAGED_MEDICAID_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsManagedMedicaidTimeSeries
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={MANAGED_MEDICAID_BY_FACILITY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsManagedMedicaidByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsWithoutMedicalSupplementTimeSeries
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock
      chartId={WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID}
    >
      {reportingBlockSetters => (
        <AnalyticsWithoutMedicalSupplementByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock half chartId={RE_ADMISSION_RATE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsReAdmissionRateTimeSeries
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock
      half
      chartId={RE_ADMISSION_RATE_BY_FACILITY_CHART_ID}
    >
      {reportingBlockSetters => (
        <AnalyticsReAdmissionRateByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ADMISSIONS_TOTAL_RESIDENTS_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsTotalResidents
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FACILITY_COMPLETION_RATE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsFacilityCompletionRate
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={STAFF_COMPLETION_RATE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsStaffCompletionRate
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ADMISSIONS_COMPLETION_ON_TIME_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsCompletionOnTime
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ADMISSIONS_COMPLETION_OVERALL_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsAdmissionsCompletionOverall
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ARBITRATION_AGREEMENT_RATE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsArbitrationAgreementRate
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FACILITY_MEDICARE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsMedicareByFacility
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ADMISSIONS_TIME_BY_STAGE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsTimeByStage
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FLAGS_AGE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsFlagsAge
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FLAGS_BY_SLIDE_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsFlagsBySlidePie
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_65_75_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge65_75
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge65_75ByCountry
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_75_85_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge75_85
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge75_85ByCountry
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_85_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge85
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PAYER_SOURCE_85_BY_COUNTRY_CHART_ID}>
      {reportingBlockSetters => (
        <AnalyticsPayerSourceAge85ByCountry
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
  </>
);

const ReportsBlocks = (props: BlocksProps) => (
  <>
    <ConnectedReportingBlock chartId={TOTAL_ADMISSIONS_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportTotalAdmissions
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={LIST_OF_RESIDENTS_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportListOfResidents
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={ARBITRATION_AGREEMENT_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportArbitrationAgreement
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={INCOME_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportPersonalResourceIncomeAgreement
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={TRUST_FUND_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportTrustFund
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PNEUMOCOCCAL_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportPneumococcal
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={INFLUENZA_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportInfluenza
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={SURROGATE_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportSurrogateDecisionMaking
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={SURROGATE_HELP_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportSurrogateDecisionMakingHelp
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={AD_PROVIDED_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportExistingADProvided
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={AD_HELP_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportADHelp
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={MEDICARE_WAIVER_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportMedicareWaiver
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={MEDICAID_REQUEST_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportMedicaidRequest
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={SNFABN_COST_ADMISSION_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportSNFABNEstimatedCostAdmission
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={STAFF_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportStaff {...props} reportingBlockSetters={reportingBlockSetters} />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FACILITY_CHANGES_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportFacilityChanges
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={FUNERAL_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportFuneral
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={LAUNDRY_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportLaundry
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={PHOTOS_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportPhotos
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
    <ConnectedReportingBlock chartId={SLIDE_INTERACTIONS_REPORT_ID}>
      {reportingBlockSetters => (
        <ReportSlideInteractions
          {...props}
          reportingBlockSetters={reportingBlockSetters}
        />
      )}
    </ConnectedReportingBlock>
  </>
);

export const PageReportingFullReport = withRouter(Renderer);
