import {MouseEvent, useState} from "react";
import axios from "axios";
import Alert from "react-s-alert";
import {useQueryClient} from "react-query";
import {withAccessToken, toApi} from "../../../utils/url";
import {Button} from "@reside/ui";

/**
 * Row above import box containing export button.
 */
export const ExportButton = ({
  /**Id required by export endpoint */
  organizationId,
}: {
  organizationId: string;
}): JSX.Element => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const onClickExportFile = (e: MouseEvent): void => {
    e.preventDefault();

    setIsExporting(true);

    axios
      .get(
        withAccessToken(
          toApi(`import-export/organizations/${organizationId}/users/export`),
        ),
      )
      .then(res => {
        /**Not the happiest way to check for errors but response from this endpoint always return success.  */

        if (res.data.status !== "FAILED") {
          Alert.success("File export initated successfully");
        } else {
          Alert.error("File export failed. Please try again.");
        }
        setIsExporting(false);
        queryClient.invalidateQueries(["useGetTableData"]);
      });
  };

  return (
    <Button
      style={{width: "fit-content"}}
      color="primary"
      disabled={isExporting}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (isExporting) {
          return;
        }
        onClickExportFile(event);
      }}
    >
      Export
    </Button>
  );
};
