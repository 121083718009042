import {extractInitialFormValuesForBlock, NodeType} from "@reside/forms";
import {forOwn, forEach} from "lodash";
import {AnswerOfobject} from "@reside/reside-api-admission";

export function getInitialValuesForSection(slides, answers) {
  return slides.reduce((values, slide) => {
    values = {
      ...values,
      ...extractInitialFormValuesForBlock({
        children: slide.children,
        values: answers,
      }),
    };

    return values;
  }, {});
}

export function isSlideVisible(slide, sectionId, showIncompleteSlidesOnly) {
  const isVisible =
    slide.sectionId === sectionId &&
    slide.state.visible &&
    !slide.excludeFromTOC;

  if (showIncompleteSlidesOnly) {
    return isVisible && (!slide.state.complete || !!slide.state.flag);
  }

  return isVisible;
}

export function cleanupSlideValues(references, values) {
  return forOwn(values, (value, key) => {
    if (
      Object.prototype.hasOwnProperty.call(references, key) &&
      (references[key].type === AnswerOfobject.QuestionTypeEnum.RADIO ||
        references[key].type === AnswerOfobject.QuestionTypeEnum.CHECKBOX)
    ) {
      forEach(references[key].choices, referenceChoice => {
        if (
          referenceChoice.id !== value &&
          referenceChoice.children
        ) {
          forEach(referenceChoice.children, referenceChoiceChild => {
            if (referenceChoiceChild.type === NodeType.FORM_CONTROL && !referenceChoiceChild.disabled) {
              if (Array.isArray(values[referenceChoiceChild.reference])) {
                values[referenceChoiceChild.reference] = [];
              }

              if (typeof values[referenceChoiceChild.reference] === "string") {
                values[referenceChoiceChild.reference] = "";
              }
            }
          });
        }
      });
    }
  });
}
