import React from "react";
import {Box} from "@reside/ui";
import {Title, Value} from "./styles";

type ResidentCardNodeProps = {
  /** Resident object key to be displayed as title */
  title: string;
  /** Value of that key */
  value: string | number | Date;
};

/** One piece of data in resident card.*/
export const ResidentCardNode = ({
  title,
  value,
}: ResidentCardNodeProps): JSX.Element => {
  return (
    <Box>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Box>
  );
};
