import {useQuery, UseQueryOptions} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {EventTrigger, HubAndSpokeService} from "../HubAndSpokeService";

export const useEventTriggersQuery = ({
  eventTriggerId,
  templateName,
  options,
}: {
  eventTriggerId?: string;
  templateName?: string;
  options?:
    | UseQueryOptions<Array<EventTrigger> | EventTrigger | undefined>[]
    | {enabled: boolean};
}) =>
  useQuery(
    ["EVENT_TRIGGERS", `EVENT_TRIGGERS_${templateName}_${eventTriggerId}`],
    async () => {
      const {data} = await HubAndSpokeService.getEventTriggers({
        eventTriggerId,
        templateName,
      });
      return data;
    },
    {
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to get event triggers.");
      },
      cacheTime: 0,
      ...options,
    },
  );
