import Alert from "react-s-alert";
import {useMutation, useQueryClient} from "react-query";

import {OrganizationsService} from "../../../services/OrganizationsService";
import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";

/**
 * @param organizationId
 */

export const useDocumentUpload = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (file: File) => {
      const response = await OrganizationsService.importExportUsers.uploadFile(
        organizationId,
        file,
      );
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("File uploaded successfully");
        queryClient.invalidateQueries({
          queryKey: ["useGetTableData"],
        });
      },
      onError: async resp => {
        Alert.error(
          await buildResponseErrorMessage(resp, "Failed to upload file."),
        );
      },
    },
  );
};
