export const produceAnswersWithDistinctValueForArrayField =
  (arrayFieldId: string, fieldId: string, checkedValue = "true") =>
  (prevValues: Record<string, any>, values: Record<string, any>) => {
    const defaultReturn = {...prevValues, ...values};

    const checkAtIndex = (checkAt: number, value = checkedValue) => ({
      ...defaultReturn,
      [arrayFieldId]: (values[arrayFieldId] as Record<string, any>).map(
        (item: any, index: number) => {
          if (index === checkAt) {
            return {...item, [fieldId]: value};
          }

          return item;
        },
      ),
    });

    try {
      const hasOneItem = values[arrayFieldId].length === 1;
      const hasMultipleItems = values[arrayFieldId].length > 0;

      // Auto check first when has one item
      if (hasOneItem) {
        return checkAtIndex(0);
      }

      const filedValuesLength = (values[arrayFieldId] as any).filter(
        (item: any) => checkEmptyAnswer(item, fieldId),
      ).length;

      const hasChecked = filedValuesLength > 0;
      const hasMultipleChecked = filedValuesLength > 1;

      // Auto check first when nothing checked and multiple items
      if (!hasChecked && hasMultipleItems) {
        return checkAtIndex(0);
      }

      // Do nothing when no multiple checked
      if (!hasMultipleChecked) {
        return defaultReturn;
      }

      const prevCheckedIndex = (prevValues[arrayFieldId] as any).findIndex(
        (item: any) => checkEmptyAnswer(item, fieldId),
      );

      // Auto uncheck previous value when multiple checked
      return checkAtIndex(prevCheckedIndex, "");
    } catch {}

    return defaultReturn;
  };

const checkEmptyAnswer = (item: Record<string, any>, fieldId: string) =>
  item[fieldId] !== undefined && item[fieldId] !== "";
