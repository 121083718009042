import React from "react";
import * as Sentry from "@sentry/browser";
import {Button} from "@reside/ui";

import {Banner} from "../banner";

export const ErrorBanner = () => (
  <Banner title="Oops, an error occurred.">
    <Button
      type="link"
      onClick={() => {
        if (process.env.REACT_APP_SENTRY_DSN) {
          Sentry.showReportDialog();
        } else {
          // eslint-disable-next-line no-console
          console.warn("REACT_APP_SENTRY_DSN is missing");
        }
      }}
    >
      Report feedback
    </Button>
  </Banner>
);
