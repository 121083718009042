import React, {useState, useCallback} from "react";
import {isValid, parseISO} from "date-fns";
import {styled, Button, Tag} from "@reside/ui";

import {
  FindResidentsUsingGETQueryParams,
  handlePccRequestLimitReached,
  PccPatient,
  PccPatientDetail,
  PccService,
} from "../../services/PccService";
import {useFindResidentQuery} from "../../models/pcc/useFindResidentQuery";
import {useDebouncedValue} from "../../hooks/useDebouncedValue";

export type PccSearchIndicatorProps = {
  birthDate?: string;
  facilityId: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  onSearchResults?: (data: PccPatientDetail) => Promise<void>;
};

type PccSearchIndicatorRendererProps = {
  isBirthDateValid?: boolean;
  isFetching?: boolean;
  isFirstNameValid?: boolean;
  isGenderValid?: boolean;
  isLastNameValid?: boolean;
  isSearching?: boolean;
  onButtonClick?: () => void;
  result?: PccPatient;
  isSearched?: boolean;
};

export const PccSearchIndicatorRenderer = ({
  isBirthDateValid,
  isFetching,
  isFirstNameValid,
  isGenderValid,
  isLastNameValid,
  isSearching,
  onButtonClick,
  result,
  isSearched,
}: PccSearchIndicatorRendererProps) => (
  <PccIndicatorWrapper>
    <Title>PointClickCare Search</Title>
    <TagsWrapper>
      <PccTag
        color={
          isFirstNameValid && !result
            ? "primary"
            : result
            ? "green100"
            : "gray100"
        }
      >
        First Name
      </PccTag>
      <PccTag
        color={
          isLastNameValid && !result
            ? "primary"
            : result
            ? "green100"
            : "gray100"
        }
      >
        Last Name
      </PccTag>
      <PccTag
        color={
          isGenderValid && !result ? "primary" : result ? "green100" : "gray100"
        }
      >
        Gender
      </PccTag>
      <PccTag
        color={
          isBirthDateValid && !result
            ? "primary"
            : result
            ? "green100"
            : "gray100"
        }
      >
        Date of Birth
      </PccTag>
    </TagsWrapper>
    <ResultWrapper>
      <ResultTitle>Results</ResultTitle>
      <ResultLine>
        <ResultText>
          {isSearching
            ? "Searching for resident..."
            : resultRenderer(result, isSearched, isSearching)}
        </ResultText>
        <Button onClick={onButtonClick} disabled={!result || isFetching}>
          {isFetching ? "Fetching resident..." : "Capture PointClickCare Data"}
        </Button>
      </ResultLine>
    </ResultWrapper>
  </PccIndicatorWrapper>
);

PccSearchIndicatorRenderer.defaultProps = {
  isBirthDateValid: false,
  isFetching: false,
  isFirstNameValid: false,
  isGenderValid: false,
  isLastNameValid: false,
  isPccRequiredFieldsValid: false,
  isSearching: false,
  onButtonClick: (): any => null,
  result: null,
};

export const PccSearchIndicator = ({
  birthDate,
  facilityId,
  firstName,
  gender,
  lastName,
  onSearchResults,
}: PccSearchIndicatorProps) => {
  const [isFetching, setIsFetching] = useState(false);

  const params = {firstName, lastName, gender, birthDate, facilityId};

  const debouncedParams = useDebouncedValue(params);

  const {isBirthDateValid, isFirstNameValid, isGenderValid, isLastNameValid} =
    checkInputsValidity(debouncedParams);

  const isPccRequiredFieldsValid =
    isFirstNameValid && isLastNameValid && isGenderValid && isBirthDateValid;

  const {
    data: result,
    isFetching: isSearching,
    isFetched: isSearched,
  } = useFindResidentQuery(debouncedParams, {
    enabled: isPccRequiredFieldsValid,
  });

  const fetchPccData = useCallback(
    async payload => {
      setIsFetching(true);
      try {
        const {data} = await PccService.getResident(payload);

        onSearchResults(data);
      } catch (error) {
        handlePccRequestLimitReached(error);
      } finally {
        setIsFetching(false);
      }
    },
    [onSearchResults],
  );

  return (
    <PccSearchIndicatorRenderer
      isFirstNameValid={isFirstNameValid}
      isLastNameValid={isLastNameValid}
      isBirthDateValid={isBirthDateValid}
      isGenderValid={isGenderValid}
      isSearching={isSearching}
      result={result as any}
      onButtonClick={() =>
        fetchPccData({facilityId, patientId: result.patientId})
      }
      isFetching={isFetching}
      isPccRequiredFieldsValid={isPccRequiredFieldsValid}
      isSearched={isSearched}
    />
  );
};

PccSearchIndicator.defaultProps = {
  birthDate: null,
  facilityId: null,
  fistName: null,
  gender: null,
  lastName: null,
};

export const checkInputsValidity = ({
  firstName,
  lastName,
  gender,
  birthDate,
}: FindResidentsUsingGETQueryParams) => {
  const isFirstNameValid = firstName?.length > 0;
  const isLastNameValid = lastName?.length > 0;
  const isGenderValid = gender?.length > 0;
  const isBirthDateValid = isValid(parseISO(birthDate));

  return {
    isFirstNameValid,
    isLastNameValid,
    isGenderValid,
    isBirthDateValid,
  };
};

export const resultRenderer = (
  result: PccPatient,
  isSearched: boolean,
  isSearching: boolean,
) => {
  if (!isSearched && !isSearching) {
    return "Please fill the indicated fields to search for PCC user.";
  }

  return result ? (
    `${result.firstName || ""} ${result.lastName || ""}`
  ) : (
    <NoMatchFound>
      Match not found in PointClickCare. Please assure information is entered
      and correct in Reside and PointClickCare. Any inaccuracies or missing
      entries in either system will prevent sync. Please correct or continue
      manual entry.
    </NoMatchFound>
  );
};

const PccIndicatorWrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
  padding: 30px 80px;
  border: 1px solid ${({theme}) => theme.color.gray10};
  margin-bottom: 15px;
`;

const TagsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  margin-left: 5px;
  font-size: 1.2rem;
  font-weight: ${({theme}) => theme.fontWeight.medium};
`;

const PccTag = styled(Tag)`
  margin: 0 5px;
`;

const ResultWrapper = styled.div`
  margin-top: 20px;
  padding-left: 5px;
`;

const ResultTitle = styled.span`
  font-weight: ${({theme}) => theme.fontWeight.bold};
`;

const ResultLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NoMatchFound = styled.span`
  color: ${({theme}) => theme.color.deepOrange300};
`;

const ResultText = styled.span`
  padding: 10px 10px 0px 0px;
`;
