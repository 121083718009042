import React, {memo} from "react";
import {get} from "lodash";
import {TableUserDto} from "@reside/reside-api-app";

import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {
  AggregationType,
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsPercentageByFacility,
  numberToPercent,
  withCompare,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsRepresentativeRateByFacilityChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const barData = dataProcess_AnalyticsPercentageByFacility(data);

    return (
      <BarChart
        data={barData.stack}
        max={barData.max}
        labelLeft="Facility"
        labelRight="Percentage"
        full={isFullReport}
        itemsPerRow={1}
        dataLegend={[
          {name: "Admissions with Resident Representative"},
          {name: "Admissions without Resident Representative"},
        ]}
        axTickFormat={v => numberToPercent(v)}
        hoverDataPreprocessor={(data, line, index) => {
          const sum = get(
            line.original[index].aggregations,
            AggregationType.SUM,
            0,
          );
          const count = get(
            line.original[index].aggregations,
            AggregationType.COUNT,
            0,
          );

          return [sum, count - sum];
        }}
      />
    );
  },
);

export const REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID =
  "representative-rate-by-facility" as const;

export const AnalyticsRepresentativeRateByFacility = (props: Props) => (
  <RequiredUserPermission
    permission={
      TableUserDto.PermissionsEnum
        .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE
    }
  >
    <div className="chart-header">
      <CardTitle title="Percent of admissions completed with or without Resident Representative" />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.representativeRateByFacility,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsRepresentativeRateByFacilityChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
