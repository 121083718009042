import {useQuery} from "react-query";
import {uniqBy} from "lodash";
import {LOCAL_TIME_ZONE} from "@reside/ui/dist/date-picker/date";

import {Direction} from "../../../services/api/pagination";
import {
  ChecklistItem,
  ReportingService,
} from "../../../services/ReportingService";
import {useReportContext} from "../ReportContext";
import {LOADING_PLACEHOLDER} from "./ReportingMenu";

type ChecklistItemsParams = Readonly<{
  facilityIds: readonly string[];
  from: string;
  to: string;
  pageable: Readonly<{
    page: number;
    size: number;
    sort?: readonly string[];
    properties?: readonly string[];
    direction?: Direction;
  }>;
}>;

export const useChecklistItems = () => {
  const {activeFacilitiesIds, baseDateRange} = useReportContext();

  return useQuery(
    [
      "/reports/checklist-items",
      {
        facilityIds: activeFacilitiesIds ?? [],
        from: baseDateRange.from,
        to: baseDateRange.to,
        localTimeZone: LOCAL_TIME_ZONE,
        pageable: {
          // TODO: test once backend supports this
          page: 0,
          size: 100,
          properties: ["description"],
          direction: "ASC" as Direction,
        },
      },
    ],
    async ({queryKey: [_, params]}) => {
      const {data} = await ReportingService.getChecklistItems(
        params as ChecklistItemsParams,
      );

      // TODO: test env has items with duplicate id's, the items should have unique ids
      return uniqBy(data, "id") as any as ReadonlyArray<ChecklistItem>;
    },
    {
      placeholderData: [{id: LOADING_PLACEHOLDER, description: "Loading..."}],
    },
  );
};

export const UseChecklistItems = ({
  children,
}: {
  children: (query: ReturnType<typeof useChecklistItems>) => JSX.Element;
}) => children(useChecklistItems());
