import React from "react";
import {connect} from "react-redux";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {AppState, select} from "../../../store";
import {DeleteAdmissionModal} from "../../delete-admission-modal";
import {AdmissionsService} from "../../../services/AdmissionsService";
import {readResidentNameAnswer} from "../../../models/AdmissionModel.helpers";
import {useAdmissionsDeleteModalContext} from "./context";

type StateProps = Readonly<{
  activeFacilities: ReadonlyArray<any>;
  onDeleted?: () => void;
}>;

const Renderer = ({activeFacilities, onDeleted}: StateProps) => {
  const {admission, toggle} = useAdmissionsDeleteModalContext();

  const {cancellationReasons = []} =
    activeFacilities.find(
      facility =>
        facility.id === (admission?.facilityId || admission?.facility?.id),
    ) ?? {};

  const {mutate, isLoading} = useMutation(
    ({admissionId, reason}: {admissionId: string; reason: string}) =>
      AdmissionsService.delete(admissionId, reason),
    {
      onError: err => {
        Alert.error("Failed to delete the Admission.");
      },
      onSuccess: () => {
        Alert.success("Admission was successfully deleted.");
        onDeleted?.();
      },
      onSettled: () => {
        toggle();
      },
    },
  );

  return (
    <DeleteAdmissionModal
      isOpen={!!admission}
      admissionCode={admission?.code}
      residentName={readResidentNameAnswer(admission)}
      isLoading={isLoading}
      cancellationReasons={cancellationReasons}
      onCancel={data => mutate({admissionId: admission?.id, ...data})}
      onRequestClose={() => toggle()}
      admissionStatus={admission?.status}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  activeFacilities: select.adminSession.facilities(state),
});

export const AdmissionsDeleteAdmissionModal =
  connect(mapStateToProps)(Renderer);
