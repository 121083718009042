import {useFormikContext} from "formik";
import {useDeepCompareEffect} from "react-use";

type UseFormikWatchValuesChangeProps<T> = {
  onChangeValues: (values: T) => void;
};

export const useFormikWatchValuesChange = <T,>({
  onChangeValues,
}: UseFormikWatchValuesChangeProps<T>): null => {
  const formik = useFormikContext<T>();

  useDeepCompareEffect(() => {
    onChangeValues(formik.values);
  }, [formik.values]);

  return null;
};

export const FormikWatchValuesChange = <T,>(
  props: UseFormikWatchValuesChangeProps<T>,
) => <>{useFormikWatchValuesChange(props)}</>;
