import { useEffect, useRef } from "react";
import { useSetState } from "./useSetState";
export var useSafeSetState = function (initialState) {
    var _a = useSetState(initialState), state = _a[0], setState = _a[1], getState = _a[2];
    var mountedRef = useRef(false);
    useEffect(function () {
        mountedRef.current = true;
        return function () {
            mountedRef.current = false;
        };
    }, []);
    var safeSetState = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return mountedRef.current && setState.apply(void 0, args);
    };
    return [state, safeSetState, getState];
};
