import React from "react";
import {Button} from "@reside/ui";
import {ButtonLink} from "../../../atoms/button-link";
import {useParams} from "react-router";

export const EditFormActionButtons = ({
  editFormSubmit,
  disabled = false,
}: {
  editFormSubmit: () => void;
  disabled?: boolean;
}): JSX.Element => {
  const {resident_uuid} = useParams<{resident_uuid: string}>();

  return (
    <>
      <ButtonLink to={`/admin/residents/${resident_uuid}`}>Cancel</ButtonLink>

      <Button
        type="submit"
        color="primary"
        onClick={editFormSubmit}
        disabled={disabled}
      >
        Save Resident
      </Button>
    </>
  );
};
