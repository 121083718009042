import {styled} from "@reside/ui";

export const WarningContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 25px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.5s ease-in both;
  font-size: ${({theme}) => theme.fontSize[4]}px;
  color: ${({theme}) => theme.color.primary};
  background-color: ${({theme}) => theme.color.lightBlue};

  @media only screen and (max-width: 868px) {
    flex-direction: column;
    text-align: center;
  }
`;
