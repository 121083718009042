import React from "react";
import {
  H1,
  H3,
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Box,
} from "@reside/ui";
import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {useEditFacilityModalContext} from "./context";
import {FacilityService} from "../../services/FacilityService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";

export const EditFacilityConfirmationModal = () => {
  const {facilityId, fields, toggle} = useEditFacilityModalContext();

  const {mutate, isLoading} = useMutation(
    () => FacilityService.patch(facilityId, fields),
    {
      onSuccess: () => {
        Alert.success(
          "Facility was successfully updated. This change may take a few minutes to be active...",
        );
        toggle();
      },
      onError: async resp => {
        Alert.error(await buildResponseErrorMessage(resp));
        toggle();
      },
    },
  );
  return (
    <Modal isOpen={!!facilityId} onRequestClose={() => toggle()}>
      {({closeModal}) => (
        <>
          <ModalContent>
            <H1>Edit Facility Information</H1>
            <H3>
              Are you sure you want to update this facility's information?
            </H3>
          </ModalContent>
          <ModalFooterButtons>
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button outline color="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                color="success"
                onClick={() => mutate()}
                disabled={!!isLoading}
              >
                {isLoading ? "Loading..." : "Confirm"}
              </Button>
            </Box>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};
