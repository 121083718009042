import React from "react";
import {Button} from "@reside/ui";
import {ButtonLink} from "../../../atoms/button-link";

export const CreateFormActionButtons = ({
  createFormSubmit,
}: {
  createFormSubmit: () => void;
}): JSX.Element => {
  return (
    <>
      <ButtonLink to={"/admin/residents"}>Cancel</ButtonLink>

      <Button type="submit" color="primary" onClick={createFormSubmit}>
        Create Resident
      </Button>
    </>
  );
};
