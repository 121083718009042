import React from "react";
import {Text} from "@reside/ui";

import {Space} from "./ContactCell";

export const OrganizationAddressCell = ({data}: any) =>
  data ? (
    <Space>
      <Text>
        {data.number} {data.street}
      </Text>
      <br />
      <Text>
        {data.city}, {data.state.name} {data.zipcode}
      </Text>
    </Space>
  ) : (
    <Text>-</Text>
  );
