import {useQuery} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {featureFlagsApi} from "../../../services/FeatureFlagsService";

type Params = {
  facilityIds: ReadonlyArray<string>;
};

export const useFetchFeatureFlagsByFacilities = ({facilityIds}: Params) =>
  useQuery(
    ["useFetchFeatureFlagsByFacilities", facilityIds],
    async ({queryKey: [_, facilityIds]}) => {
      const {data} = await featureFlagsApi.getFacilityFeatureFlagsUsingPOST(
        facilityIds as ReadonlyArray<string>,
        getAuthorizationHeader(),
      );

      return data;
    },
  );
