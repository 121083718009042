import React, {Fragment, ReactNode, Children} from "react";
import cx from "classnames";
import {styled} from "@reside/ui";

type Props = Readonly<{
  children: ReactNode;
}>;

const getStyle = (idx: number, length: number) => {
  return cx({first: idx === 0, last: idx === length - 1});
};

export const ButtonGroup = ({children}: Props) => (
  <ButtonGroupList>
    {Children.map(children, (value, idx) => (
      <Fragment key={idx}>
        <ButtonGroupItem className={getStyle(idx, Children.count(children))}>
          {value}
        </ButtonGroupItem>
      </Fragment>
    ))}
  </ButtonGroupList>
);

const ButtonGroupItem = styled.li`
  button {
    border-radius: 0px;
  }
  &.first button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.last button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ButtonGroupList = styled.ul`
  display: flex;
`;
