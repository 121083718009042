var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import { pickBy, some } from "lodash";
import { flatten } from "@reside/forms/dist/flatten";
import { styled, theme } from "../../../../theme";
import { Drawer } from "../../../../drawer";
import { Icon } from "../../../../icon";
import { ItemLabel, AlignRight, RemoveButton, } from "../../BlockFieldArray";
import { useBlockComponentContext } from "../../../BlockComponentContext";
/**
 * FieldArray item renderer displaying items in collapsible drawers.
 */
export var CollapsibleItems = function (_a) {
    var id = _a.id, items = _a.items, headlineLabel = _a.headlineLabel, translationKey = _a.translationKey, onRemove = _a.onRemove, children = _a.children;
    var _b = useBlockComponentContext(), Translated = _b.Translated, translate = _b.translate, Markdown = _b.Markdown;
    var _c = useFormikContext(), errors = _c.errors, touched = _c.touched, isSubmitting = _c.isSubmitting, isValidating = _c.isValidating, setFieldTouched = _c.setFieldTouched;
    var _d = useField(id), _e = _d[1].initialValue, initialValue = _e === void 0 ? [] : _e;
    var _f = useState(null), openedItemIndex = _f[0], setOpenedItem = _f[1];
    // HACK: formik touched is sometimes flattend object and sometimes exploded structure
    // we normalise the array of object by flattening so we handle both cases in the same way later
    var normalisedTouched = flatten(pickBy(touched, function (_, key) { return key.startsWith(id); }));
    var clearTouchedState = function () {
        return initialValue.forEach(function (_, index) {
            if (!fieldArrayItemHasError(errors, normalisedTouched, id, index)) {
                // We remove touched state from items which had initial error and were fixed.
                setFieldTouched("".concat(id, ".").concat(index), false);
            }
        });
    };
    return (React.createElement(React.Fragment, null, items.map(function (item, index) {
        var _a;
        // Items added by clicking 'Add' are always expanded, only the initialItems are collapsed.
        var isCollapsible = initialValue.some(function (_a) {
            var id = _a.id;
            return id === item.id;
        });
        // When item has initial error, we prevent it from collapsing.
        var hasError = fieldArrayItemHasError(errors, normalisedTouched, id, index);
        // Read touched state for current item.
        var itemTouchedState = (_a = touched[id]) === null || _a === void 0 ? void 0 : _a[index];
        // HACK: after submit _every_ field is touched, even the internal 'id', we use this to filter false-positive programmatic touch (we care only about user's touches)
        var touchedByFormik = (itemTouchedState === null || itemTouchedState === void 0 ? void 0 : itemTouchedState.id) === true;
        // We keep touched items opened, so they don't collapse after some initial field error was fixed.
        // All the form fields are touched when the form is being submitted, so meanwhile we ignore the touched state when we are validating or submitting.
        var isTouched = Boolean(itemTouchedState) &&
            !isValidating &&
            !isSubmitting &&
            !touchedByFormik;
        var isOpened = isCollapsible
            ? hasError || isTouched || openedItemIndex === index
            : true;
        return (React.createElement(DrawerContainer, { key: "".concat(id).concat(index), hasError: hasError },
            React.createElement(Drawer, { isOpened: isOpened, disableAnimation: hasError, collapsible: isCollapsible && !hasError, testId: "".concat(id, "-headline-").concat(index), onToggle: function (isOpened) {
                    setOpenedItem(isOpened ? index : null);
                    clearTouchedState();
                }, header: React.createElement(React.Fragment, null,
                    React.createElement(Title, null, isCollapsible ? (React.createElement(React.Fragment, null,
                        React.createElement(Icon, { className: "arrow-icon", name: isOpened ? "down" : "right", color: theme.color.gray100, size: 14 }),
                        (headlineLabel === null || headlineLabel === void 0 ? void 0 : headlineLabel.translationKey) && (React.createElement(Translated, { translationKey: headlineLabel.translationKey, render: function (_a) {
                                var translation = _a.translation;
                                return (React.createElement(Markdown, { source: translation, values: {
                                        answers: items.find(function (_a) {
                                            var id = _a.id;
                                            return item.id === id;
                                        }),
                                    } }));
                            } })))) : (React.createElement(ItemLabel, null,
                        translate(translationKey),
                        " #",
                        index + 1))),
                    React.createElement(AlignRight, null,
                        React.createElement(RemoveButton, { translationKey: translationKey, size: "small", onClick: function (event) {
                                event.stopPropagation(); // Prevent toggling the drawer
                                clearTouchedState();
                                if (index === openedItemIndex) {
                                    setOpenedItem(null);
                                }
                                else if (index < openedItemIndex) {
                                    setOpenedItem(openedItemIndex - 1);
                                }
                                onRemove(index);
                            } }))) },
                React.createElement("fieldset", { disabled: !isOpened }, children(index)))));
    })));
};
var DrawerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .arrow-icon {\n    margin-right: 0.6em;\n  }\n\n  // We use old-school class because, restyling styled component breaks the collapsible animation\n  .drawer-container {\n    &:focus {\n      box-shadow: 0px 0px 2px\n        ", ";\n    }\n  }\n"], ["\n  .arrow-icon {\n    margin-right: 0.6em;\n  }\n\n  // We use old-school class because, restyling styled component breaks the collapsible animation\n  .drawer-container {\n    &:focus {\n      box-shadow: 0px 0px 2px\n        ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, hasError = _a.hasError;
    return hasError ? theme.color.deepOrange100 : theme.color.primary;
});
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.8em 0;\n"], ["\n  padding: 0.8em 0;\n"])));
var fieldArrayItemHasError = function (errors, touched, id, itemIndex) {
    return some(pickBy(errors, function (_, key) { return key.startsWith("".concat(id, ".").concat(itemIndex)); }), function (_, key) { return touched[key]; });
};
var templateObject_1, templateObject_2;
