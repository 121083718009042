import React from "react";
import {css} from "@emotion/react";
import {styled, Badge} from "@reside/ui";
import {Trans} from "@lingui/macro";

const defaultProps = {
  Wrapper: styled.div``,
  Sticky: styled.div`
    min-width: 300px;
    display: flex;
    flex-direction: column;
    width: auto;
    height: max-content;
    margin-right: 20px;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    background-color: #ffffff;
    top: 95px;
    position: sticky;
  `,

  Button: styled.div<{isActive: boolean}>`
    font-weight: 500;
    padding: 20px;
    line-height: 18px;
    cursor: pointer;
    position: relative;
    color: #2bb7d9;

    &:hover {
      background-color: #f2f6fa;
    }

    ${({isActive}) =>
      isActive &&
      css`
        font-weight: 700;
      `}
  `,
  hasErrorsVisible: false,
};

type SlideProps = {
  title: {
    translationKey: string;
  };
  onClick: () => void;
  isActive: boolean;
  errors?: Record<string, unknown>;
};

type Props = typeof defaultProps & {
  slides: SlideProps[];
  hasErrorsVisible?: boolean;
  className?: string;
};
type Children = (props: Props) => JSX.Element;

export const Menu = ({
  children,
  ...props
}: Props & {
  children: Children;
}) => children(props);

Menu.defaultProps = {
  ...defaultProps,
  children: ({
    Sticky,
    Wrapper,
    Button,
    slides,
    hasErrorsVisible,
    className,
  }: Props) => (
    <Wrapper>
      <Sticky className={className}>
        {slides.map((slide: SlideProps, index) => {
          const errorCount = Object.keys(slide.errors || {}).length;

          return (
            <Button
              key={slide.title.translationKey}
              data-test-id={`preflightSideMenu-${index}`}
              onClick={slide.onClick}
              isActive={slide.isActive}
            >
              {`${index + 1}. `}
              <Trans id={slide.title.translationKey} />
              {hasErrorsVisible && errorCount > 0 && (
                <ErrorCount>{errorCount}</ErrorCount>
              )}
            </Button>
          );
        })}
      </Sticky>
    </Wrapper>
  ),
};

const ErrorCount = styled(Badge)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
