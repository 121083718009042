import "core-js/features/array/flat-map";
import "core-js/features/object/from-entries";
import React from "react";
import ReactDOM from "react-dom";

import {App} from "./App";
import "./bootstrap/sentry";
import "./bootstrap/validator";

import "./css/index.css";
import "@reside/ui/dist/styles";

ReactDOM.render(<App />, document.getElementById("root"));

// eslint-disable-next-line no-console
console.table(process.env);
