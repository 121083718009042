import React, {ComponentType, useEffect} from "react";
import {connect} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

import {AppState} from "../store";

type GuardProps = {isAuthenticated: boolean};

export function createRouteGuard({
  redirectPath,
  authenticatedSelector,
  redirectToLastPage,
}: {
  authenticatedSelector: (state: AppState) => boolean;
  redirectPath: string;
  redirectToLastPage?: boolean;
}) {
  const mapState = (state: AppState) => ({
    isAuthenticated: authenticatedSelector(state),
  });

  /**
   * Note: Returning function so the the guard applied with config is properly identified & highlighted as function instead of variable.
   */
  return function Guard<OwnProps>(Component: ComponentType<OwnProps>) {
    function Redirect({isAuthenticated, ...props}: OwnProps & GuardProps) {
      const history = useHistory();
      const location = useLocation<{from?: string}>();

      useEffect(() => {
        const shouldGoBack = redirectToLastPage && location.state?.from;
        if (!isAuthenticated) {
          shouldGoBack
            ? history.goBack()
            : history.push({
                pathname: redirectPath,
                state: {
                  from: location.pathname,
                },
              });
        }
      });

      return isAuthenticated ? (
        <Component {...(props as any as OwnProps)} />
      ) : null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return connect(mapState)(Redirect);
  };
}
