import React, {memo} from "react";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsArbitrationAgreementRateChart = memo(({data}: ChartProps) => (
  <LineChart
    data={lineGraphData(data)}
    domain={{
      x: [
        new Date(data[0].dataPath.query.from),
        new Date(data[0].dataPath.query.to),
      ],
      y: [0, 100],
    }}
  />
));

export const ARBITRATION_AGREEMENT_RATE_CHART_ID =
  "arbitration-agreement-rate" as const;

export const AnalyticsArbitrationAgreementRate = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.ARBITRATION_AGREEMENT_COMPLETED}
  >
    <div className="chart-header">
      <CardTitle
        title="Completion Rate of Arbitration Agreement"
        subtitle="by Admissions"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.arbitrationAgreementRate,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsArbitrationAgreementRateChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
