import React, {useState} from "react";
import {Text, theme} from "@reside/ui";
import {format} from "date-fns";
import {Admission} from "@reside/reside-api-document";
import {useHistory} from "react-router";
import {ResidentAdmission} from "../types";
import {FacilityIconsWrapper, HouseIcon, UserIcon} from "./styles";
import {
  Column,
  ListTable,
  AdmissionStatusCell,
  PercentageCell,
} from "../../../atoms/list-table";
import {ActionMenuCell} from "../../../atoms/admin-admissions-table/ActionMenuCell";
import {ModalsProviders} from "./ModalsProviders";

const ADMISSION_STATUSES = [
  Admission.StatusEnum.IN_PROGRESS,
  Admission.StatusEnum.SIGNED,
  Admission.StatusEnum.APPROVED,
  Admission.StatusEnum.CANCELLED,
  Admission.StatusEnum.ARCHIVED,
];

export const ResidentAdmissions = ({
  admissions = [],
  showMoreOptions = true,
}: {
  admissions: ResidentAdmission[];
  showMoreOptions?: boolean;
}): JSX.Element => {
  const [activeRowIndex, setActiveRowIndex] = useState<number>();

  const history = useHistory();

  return (
    <ModalsProviders>
      <ListTable<ResidentAdmission>
        key={"Resident Admissions"}
        getRowId={row => row.rowData.id}
        data={admissions}
        rowHeight={120}
        sortPredicates={{
          last_modified: ["last_modified"],
          date_of_admission: ["date_of_admission"],
          date_added: ["date_added"],
        }}
        onRowClick={(id: string, row) => {
          const admission = admissions.find(admission => admission.id === id);

          // If admission is pre-flight reside admin can't access it.
          if (!admission || row.rowData.status === "PRE_FLIGHT") {
            return;
          }

          if (ADMISSION_STATUSES.includes(admission.status)) {
            history.push(`/admin/admissions/${admission.id}`);
          }

          // This will be needed when facility admins get access to the page to take them to preflight page.
          // if (admission.status === Admission.StatusEnum.PRE_FLIGHT) {
          //   history.push(
          //     createPreflightUrl({
          //       facilityId: admission.facility.id,
          //       admissionId: admission.id,
          //     }),
          //   );
          // }
        }}
        noRowsMessage="No Admissions found for this resident..."
        activeRowIndex={activeRowIndex}
      >
        <Column
          cellRenderer={(row: any) => (
            <FacilityIconsWrapper>
              <HouseIcon size={14} color={theme.color.gray100} name="house" />
              <Text color="gray100">{row.rowData?.facility?.name}</Text>
              <br />
              <UserIcon size={14} color={theme.color.gray100} name="user" />
              <Text color="gray100">
                {row.rowData?.facility?.organization?.name}
              </Text>
            </FacilityIconsWrapper>
          )}
          label="Facility"
          sortKey="residentFullName"
          width={110}
        />
        <Column
          cellRenderer={(row: any) => <Text>{row.rowData.code}</Text>}
          label="Admission id"
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <AdmissionStatusCell status={row.rowData.status} />
          )}
          label="Status"
          sortKey="status"
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <PercentageCell data={row.rowData.completionPercentage ?? 0} />
          )}
          label="Completed"
          sortKey="completed"
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <Text>
              {format(new Date(row.rowData.date_of_admission), "MM/dd/yyyy")}
            </Text>
          )}
          label="Date of Admission"
          sortKey={"date_of_admission"}
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <Text>
              {row.rowData.signature_date
                ? format(new Date(row.rowData.signature_date), "MM/dd/yyyy")
                : "—"}
            </Text>
          )}
          label="signature date"
          sortKey="signature_date"
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <Text>
              {row.rowData.created
                ? format(new Date(row.rowData.created), "MM/dd/yyyy")
                : "—"}
            </Text>
          )}
          label="Date added"
          sortKey="date_added"
          width={60}
        />

        <Column
          cellRenderer={(row: any) => (
            <Text>
              {row.rowData.updated
                ? format(new Date(row.rowData.updated), "MM/dd/yyyy")
                : "—"}
            </Text>
          )}
          label="Last Modified"
          sortKey="last_modified"
          width={60}
        />

        <Column
          cellRenderer={(row: any) =>
            showMoreOptions && (
              <ActionMenuCell
                data={row.rowData}
                onOpen={() => setActiveRowIndex(row.rowIndex)}
                onClose={() => setActiveRowIndex(undefined)}
              />
            )
          }
          label=""
          width={40}
        />
      </ListTable>
    </ModalsProviders>
  );
};
