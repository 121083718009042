import {AppState} from "../store";
import {
  ReportsDataPath,
  IReportingReportsDataState,
  StateWithReportsType,
  isReportsDataPath,
  ReportsKey,
} from "./reporting-reports.common";
import {StateWithFromTo} from "../reporting/reporting.common";
import {selectWithFromTo} from "../reporting/reporting.selectors";

const selectWithReportsKey =
  <S>(key: ReportsKey) =>
  (state: StateWithReportsType<S> | undefined) =>
    state === undefined ? undefined : state[key];

export function createSelectorsReports(path: ReportsDataPath) {
  const byDataPath = (state: AppState) => {
    if (isReportsDataPath(path)) {
      const selectTypeFn = selectWithReportsKey<
        StateWithFromTo<IReportingReportsDataState>
      >(path.key ?? path.type);

      const selectFromToFn = selectWithFromTo<IReportingReportsDataState>(
        path.query,
      );

      const state1 = state.reporting.reports;
      const state2 = selectTypeFn(state1);

      return selectFromToFn(state2);
    }
  };

  return {
    byDataPath,
    state: (state: AppState) => state,
    currentPage: (state: AppState) => byDataPath(state).currentPage,
    totalPages: (state: AppState) => byDataPath(state).totalPages,
  };
}
