import React from "react";
import {styled, theme, Text, Icon} from "@reside/ui";

import "./Trigger.scss";

export const Trigger = ({
  slideIndex,
  notesCnt,
  onOpen,
}: {
  notesCnt: number;
  slideIndex: number;
  onOpen: () => void;
}) => (
  <Wrapper onClick={onOpen} data-test-id={`showNotes-${slideIndex}`}>
    <Icon
      name="message-alt"
      color={notesCnt === 0 ? theme.color.primary : theme.color.deepOrange100}
      size={24}
    />
    <Text
      fontWeight="bold"
      fontSize={3}
      color={notesCnt === 0 ? "darkBlue100" : "deepOrange100"}
      className="trigger-0-55"
    >
      {`${notesCnt} Note${notesCnt === 1 ? "" : "s"}`}
    </Text>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  cursor: pointer;

  &:hover span,
  &:hover i {
    color: ${theme.color.darkBlue40};
  }
`;
