import React from "react";
import { cx } from "@emotion/css";
import { Icon } from "../icon";
import { Text } from "../text";
export var DropdownSelectText = function (_a) {
    var bigger = _a.bigger, outline = _a.outline, isOpened = _a.isOpened, label = _a.label, iconPosition = _a.iconPosition;
    var iconObj = React.createElement(Icon, { name: isOpened ? "up" : "down", size: 10 });
    return (React.createElement("button", { className: cx("button", { outline: outline, big: bigger }), type: "button" },
        iconPosition === "before" && iconObj,
        React.createElement(Text, null, label),
        iconPosition === "after" && iconObj));
};
DropdownSelectText.defaultProps = {
    bigger: false,
    outline: false,
    isOpened: false,
    iconPosition: "after",
};
