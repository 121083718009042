import React from "react";
import {DropdownSelect, DropdownSelectText} from "@reside/ui";
import {ResidentStatus} from "../types";
import {useFilterResidentsContext} from "../context/useFilterResidentsContext";

const residentStatusItems: Array<{id: ResidentStatus | null; label: string}> = [
  {id: null, label: "All"},
  {id: "ACTIVE", label: "Active"},
  {id: "DELETED", label: "Inactive"},
];

export const ResidentStatusSelector = (): JSX.Element => {
  const {selectedStatus, setSelectedStatus} = useFilterResidentsContext();

  const selectedItem = residentStatusItems.find(
    status => status.id === selectedStatus,
  );

  return (
    <DropdownSelect
      trigger={({isOpened}) => (
        <DropdownSelectText label={selectedItem?.label} isOpened={isOpened} />
      )}
      selectItem={selectedItem => {
        setSelectedStatus(selectedItem.id);
      }}
      selectedItem={selectedItem}
      items={residentStatusItems}
      label="Status:"
    />
  );
};
