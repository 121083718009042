var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../../theme";
import { Icon } from "../../../icon";
export var ClearFieldButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(Wrapper, { onClick: onClick },
        React.createElement(CloseIcon, { name: "close" })));
};
var CloseIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  color: ", ";\n  font-size: 0.65em;\n"], ["\n  display: grid;\n  color: ", ";\n  font-size: 0.65em;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.white;
});
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  place-items: center;\n  min-width: 1.2em;\n  min-height: 1.2em;\n  width: 1.2em;\n  height: 1.2em;\n  position: relative;\n  cursor: pointer;\n  border-radius: 50%;\n  background-color: ", ";\n"], ["\n  display: grid;\n  place-items: center;\n  min-width: 1.2em;\n  min-height: 1.2em;\n  width: 1.2em;\n  height: 1.2em;\n  position: relative;\n  cursor: pointer;\n  border-radius: 50%;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue30;
});
var templateObject_1, templateObject_2;
