import {useQuery} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {featureFlagsApi} from "../../../services/FeatureFlagsService";

export const useFetchAllFacilityFeatureFlags = () =>
  useQuery("useFetchAllFacilityFeatureFlags", async () => {
    const {data} = await featureFlagsApi.getFacilityFeatureFlagsTypesUsingGET(
      getAuthorizationHeader(),
    );
    return data;
  });
