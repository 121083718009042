import {Button, Icon, theme} from "@reside/ui";
import React from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {Dictionary} from "lodash";

import {select} from "../../../../store";
import {createPreflightUrl} from "../../../../pages/page-admin-preflight/route";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";
import {ButtonGroup} from "../../../button-group";
import {getResidentTestId} from "../../helper";
import {ModalName, useDashboardModalContext} from "../../DashboardModalContext";

type StateProps = Readonly<{
  isAdmin: boolean;
  isOrgAdmin: boolean;
  facilityFlagsById: Dictionary<{[key: string]: boolean}>;
}>;

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

const PccReadmittedActionsRenderer = ({
  admission,
  isAdmin,
  isOrgAdmin,
  facilityFlagsById,
}: Props & StateProps) => {
  const history = useHistory();

  const {id: admissionId, facilityId} = admission;
  const {toggleModal} = useDashboardModalContext(ModalName.CANCEL);

  const hasOrgAdminOnlyDeleteAdmissions =
    facilityFlagsById[facilityId]?.facilityHasOrgAdminOnlyDeleteAdmissions;
  const isDisabled = hasOrgAdminOnlyDeleteAdmissions ? !isOrgAdmin : false;

  const onEditPreflight = () =>
    history.push(createPreflightUrl({facilityId, admissionId}));

  return (
    <ButtonGroup>
      <Button
        size="small"
        onClick={onEditPreflight}
        testId={getResidentTestId(admission, "editPccPreflight")}
        disabled={isAdmin}
      >
        Edit
      </Button>
      <Button
        size="small"
        onClick={() => !isDisabled && toggleModal(admission)}
        testId={getResidentTestId(admission, "deletePccPreflight")}
        disabled={isDisabled}
        icon={
          <Icon
            name="trash"
            size={11}
            color={theme.color.deepOrange100}
            style={{top: 0, left: 0}}
          />
        }
      />
    </ButtonGroup>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  isAdmin: select.adminSession.isResideAdmin(state),
  isOrgAdmin: select.adminSession.isOrgAdmin(state),
  facilityFlagsById: select.adminSession.facilityFlagsById(state),
});

export const PccReadmittedActions = connect(mapStateToProps)(
  PccReadmittedActionsRenderer,
);
