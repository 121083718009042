"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
// _-,:/.()&+​01234789abcdefghijklmnopqrstuvwxyz
var FACILITY_NAME_REGEX = new RegExp(/^[-,.()&\w\s]*$/);
var UNDERSCORE = new RegExp(/_/);
exports.Validator = {
    name: "facility-name",
    errorMessage: 'This field may only contain alpha-numeric characters, as well "-,.()&".',
    validate: function (value) {
        return FACILITY_NAME_REGEX.test(value) && !UNDERSCORE.test(value);
    },
};
