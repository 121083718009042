import React from "react";
import {
  BlockComponentContextType,
  Markdown,
  SnapshotControlField,
} from "@reside/ui";
import {Trans} from "@lingui/react";

import {t} from "../i18n-provider";
import {withAccessToken} from "../../utils/url";
import {convertFilesToBlobFixRotationAndCompress} from "@reside/ui/dist/form/snapshot-control-field/loadImage";
import {useAdmissionContext} from "../admission-explorer/AdmissionContext";

export const transComponents: BlockComponentContextType = {
  Translated: ({translationKey, values, render}) => (
    <Trans id={translationKey} values={values} render={render} />
  ),
  translate: key => t(key),
};

export const commonComponents: BlockComponentContextType = {
  SnapshotControlField: props => (
    <SnapshotControlField
      {...props}
      transformUrl={withAccessToken}
      loadImageFiles={convertFilesToBlobFixRotationAndCompress}
      textSize={useAdmissionContext()?.uiState?.textSize || 1}
    />
  ),
  Markdown: props => <Markdown {...props} transformUrl={withAccessToken} />,
};

export const components = {...transComponents, ...commonComponents};
