import {action} from "typesafe-actions";
import {Dispatch} from "redux";
import {CancelTokenSource} from "axios";
import {TableUserDto} from "@reside/reside-api-app";

import {
  UserId,
  GRANT_PERMISSION,
  REVOKE_PERMISSION,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_PERMISSION_BEGIN,
  FETCH_BY_PERMISSION_SUCCESS,
  FETCH_BY_PERMISSION_FAILURE,
  RESET_PERMISSION,
  UsersByPermissionType,
} from "./user-permissions.common";
import {AppState} from "../store";
import {
  resolverGetUserPermissions,
  resolverGetPermissionTypeUsers,
  resolverGrantPermission,
  resolverMyPermissions,
  resolverRevokePermission,
} from "./user-permissions.resolvers";
import {
  createShouldFetchPermissionsSelector,
  selectShouldFetchUsersByPermission,
} from "./user-permissions.selectors";

export const createActions = (userId: UserId) => {
  const selectShouldFetchPermissions = createShouldFetchPermissionsSelector(
    () => userId,
  );

  const fetchBegin = () => action(FETCH_BEGIN, {userId});
  const fetchSuccess = (permissions: TableUserDto.PermissionsEnum[]) =>
    action(FETCH_SUCCESS, {userId, permissions});
  const fetchFailure = (error: any) => action(FETCH_FAILURE, {userId, error});
  const grantPermission = (permission: TableUserDto.PermissionsEnum) =>
    action(GRANT_PERMISSION, {userId, permission});
  const revokePermission = (permission: TableUserDto.PermissionsEnum) =>
    action(REVOKE_PERMISSION, {userId, permission});

  const requestGrantPermission =
    (permission: TableUserDto.PermissionsEnum) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(grantPermission(permission));
      await resolverGrantPermission(userId, permission);
    };

  const requestRevokePermission =
    (permission: TableUserDto.PermissionsEnum) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(revokePermission(permission));
      await resolverRevokePermission(userId, permission);
    };

  const requestPermissionsForUser =
    (cancelTokenSource: CancelTokenSource) =>
    async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
      const state = getState();

      if (selectShouldFetchPermissions(state)) {
        dispatch(fetchBegin());
        try {
          let response;

          if ((state as any).adminSession.user.id === userId) {
            response = await resolverMyPermissions(cancelTokenSource);
          } else {
            response = await resolverGetUserPermissions(
              userId,
              cancelTokenSource,
            );
          }

          if (response.success) {
            const permissionsArr = response.data
              .filter(permission => permission.status === "ACTIVE")
              .map(permission => permission.name);

            dispatch(fetchSuccess(permissionsArr));
          } else {
            dispatch(fetchFailure("response not ok"));
          }
        } catch (e) {
          dispatch(fetchFailure(e));
        }
      }
    };

  return {
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    grantPermission,
    revokePermission,
    requestGrantPermission,
    requestRevokePermission,
    requestPermissionsForUser,
  };
};

export const resetPermission = () => action(RESET_PERMISSION);

export const createActionsByPermission = () => {
  const fetchBegin = () => action(FETCH_BY_PERMISSION_BEGIN);
  const fetchSuccess = (usersByPerm: UsersByPermissionType) =>
    action(FETCH_BY_PERMISSION_SUCCESS, usersByPerm);
  const fetchFailure = () => action(FETCH_BY_PERMISSION_FAILURE);
  const grantPermission = (
    userId: string,
    permission: TableUserDto.PermissionsEnum,
  ) => action(GRANT_PERMISSION, {userId, permission});
  const revokePermission = (
    userId: string,
    permission: TableUserDto.PermissionsEnum,
  ) => action(REVOKE_PERMISSION, {userId, permission});

  const requestUsersByPermission =
    (cancelTokenSource: CancelTokenSource) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
      if (selectShouldFetchUsersByPermission(getState())) {
        dispatch(fetchBegin());
        try {
          const response = await resolverGetPermissionTypeUsers(
            "REPORTING",
            cancelTokenSource,
          );

          if (response.success) {
            dispatch(fetchSuccess(response.data));
          } else {
            dispatch(fetchFailure());
          }
        } catch {
          dispatch(fetchFailure());
        }
      }
    };

  const requestRevokePermission =
    (userId: string, permissionName: TableUserDto.PermissionsEnum) =>
    async (dispatch: Dispatch) => {
      dispatch(revokePermission(userId, permissionName));
      await resolverRevokePermission(userId, permissionName);

      return Promise.resolve();
    };

  const requestGrantPermission =
    (userId: string, permissionName: TableUserDto.PermissionsEnum) =>
    async (dispatch: Dispatch) => {
      dispatch(grantPermission(userId, permissionName));
      await resolverGrantPermission(userId, permissionName);

      return Promise.resolve();
    };

  return {
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    requestUsersByPermission,
    requestRevokePermission,
    requestGrantPermission,
  };
};
