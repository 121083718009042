import {useIsAutoLoggedOut} from "../../hooks/useIsAutoLoggedOut";

import {Icon, Text} from "@reside/ui";
import {WarningContainer} from "./styles";

export const AutoLogoutInfoBar = (): JSX.Element => {
  const isAutoLoggedOut = useIsAutoLoggedOut();

  if (isAutoLoggedOut) {
    return (
      <WarningContainer>
        <Icon name="info" />
        <Text>Your session has expired for security reasons.</Text>
      </WarningContainer>
    );
  }
  return null;
};
