import {v4} from "uuid";
import {pickBy} from "lodash";
import {blockIsVisible, computeChildrenBlock} from "@reside/forms";

import {
  getCompleteSlides,
  getFlaggedSlides,
  getIncompleteSlides,
  getSectionSlides,
  getVisibleSlides,
} from "./helpers/slide";
import {AdmissionStatus} from "../services/AdmissionsService";
import {calculatePrintName} from "./AdmissionModel.helpers";

export function computeProps(instance, answers, overrides) {
  return {
    readOnly:
      [AdmissionStatus.IN_PROGRESS, AdmissionStatus.PRE_FLIGHT].includes(
        instance.status,
      ) === false,
    printName: calculatePrintName(answers),
    ...overrides,
  };
}

export function calculateCompletionPercentage(slides) {
  let completionPercentage = 0;

  if (slides) {
    const totalSlides = slides.filter(
      slide => slide.state.visible && !slide.state.last,
    );
    const completeSlides = totalSlides.filter(slide => slide.state.complete);

    completionPercentage = Math.round(
      completeSlides.length / (totalSlides.length / 100),
    );
  }

  return completionPercentage;
}

export function extractAfterAdmissionSlides({template, answers}) {
  const afterAdmissionSlides = [];

  template.children[template.children.length - 1].children.forEach(section => {
    const sectionIsVisible = blockIsVisible(answers, section);
    section.children
      .filter(slide => slide.afterAdmission)
      .forEach(slide => {
        afterAdmissionSlides.push({
          ...slide,
          children: slide.children?.map(node =>
            computeChildrenBlock({
              node,
              answers,
              template,
            }),
          ),
          sectionId: section.id,
          sectionTitle: section.title,
          state: {
            disabled: false,
            complete: false,
            fullscreen:
              !!slide.backgroundUrl ||
              slide.children[0].type === "tableOfContent",
            last: false,
            visible: sectionIsVisible && blockIsVisible(answers, slide),
            flag: null,
          },
        });
      });
  });

  return afterAdmissionSlides;
}

export const getPreflightedAnswers = answers =>
  pickBy(answers, answer => answer.preFlight);

export function extractFlags(instance) {
  return instance.flags.reduce((obj, flag) => {
    obj[flag.id] = {
      id: flag.id,
      author: flag.author,
      comment: flag.comment,
      created: flag.created,
      slideId: flag.slideId,
    };

    return obj;
  }, {});
}

export function extractNotes(instance) {
  return instance.notes.reduce((obj, note) => {
    if (!obj[note.slideId]) {
      obj[note.slideId] = [];
    }
    obj[note.slideId].push(note);

    return obj;
  }, {});
}

export function buildToc(template, slides, incomplete) {
  const indexedSlides = slides.map((s, idx) => ({...s, idx: idx}));
  const visibleSlides = getVisibleSlides(indexedSlides);
  const filteredSlides = incomplete
    ? getIncompleteSlides(visibleSlides)
    : visibleSlides;
  const state = {
    totalSlides: filteredSlides.length,
    completedSlides: getCompleteSlides(filteredSlides).length,
  };
  const groups = template.children.map(group => {
    const groupState = group.children.reduce(
      (groupCnt, section) => {
        const totalSlides = getSectionSlides(filteredSlides, section.id);
        const completedSlides = getCompleteSlides(totalSlides);
        const flaggedSlides = getFlaggedSlides(totalSlides);

        return {
          totalSlides: groupCnt.totalSlides + totalSlides.length,
          completedSlides: groupCnt.completedSlides + completedSlides.length,
          flaggedSlides: groupCnt.flaggedSlides + flaggedSlides.length,
        };
      },
      {totalSlides: 0, completedSlides: 0, flaggedSlides: 0},
    );

    return {
      id: group.id,
      title: group.title,
      state: groupState,
      children: group.children.map(section => {
        const slidesInSection = getSectionSlides(filteredSlides, section.id);
        const uniqueNamedSlides = getUniqueNamedSlides(slidesInSection);

        return {
          id: section.id,
          title: section.title,
          children: uniqueNamedSlides,
        };
      }),
    };
  }, {});

  return {groups: groups, state: state};
}

const equalTitles = (tOne, tTwo) =>
  tOne.title.translationKey === tTwo.title.translationKey;
const getUniqueNamedSlides = slides => {
  let idx = 0;

  return slides.reduce((arr, slide) => {
    if (idx > 0 && equalTitles(slides[idx - 1], slide)) {
      const slideHasFlag = slide.state.flag !== null ? 1 : 0;

      arr.find(s => equalTitles(s, slide)).state.totalFlags += slideHasFlag;
    } else {
      const modifiedSlide = {...slide};

      modifiedSlide.state.totalFlags =
        modifiedSlide.state.flag !== null ? 1 : 0;
      arr.push(modifiedSlide);
    }
    idx += 1;

    return arr;
  }, []);
};

/**
 * Remap key-value object to structure suitable to be sent to server.
 */
export function buildCompletionChecklist(slides) {
  return slides.reduce((arr, slide) => {
    if (slide.state.visible && slide.inCompletionChecklist) {
      arr.push({
        id: slide.id,
        title: slide.title,
        complete: slide.state.complete,
      });
    }

    return arr;
  }, []);
}

/**
 * Create Flag
 */
export function createFlag(payload) {
  return {
    id: v4(),
    created: new Date().getTime(),
    author: payload.author,
    comment: payload.comment,
    slideId: payload.slideId,
  };
}
