import {
  RoleDto,
  UserControllerApi,
  CreateStaffUserDto,
  CreateResetPasswordDto,
  UserDto,
  ProfileUserDto,
  UpdateStaffDto,
  ParametersDto,
  ChangePasswordDto,
  VerificationUserDto,
  ChangeUserPasswordDto,
} from "@reside/reside-api-app";
import portableFetch from "portable-fetch";

import {Pageable} from "./api/pagination";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {BASE_PATH} from "../config";

const {StatusesEnum} = ParametersDto;
export const userApi = new UserControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);

export type UserStatusEnum = keyof typeof UserDto.StatusEnum;

export const UserService = {
  create: (data: CreateStaffUserDto) =>
    userApi.createStaffUserUsingPOST(data, getAuthorizationHeader()),

  fetchUsers: (params: any) =>
    userApi.fetchUsersUsingPOST(params, getAuthorizationHeader()),

  delete: (userId: string) =>
    userApi.deleteStaffUserUsingDELETE(userId, getAuthorizationHeader()),

  find: (userId: string) =>
    userApi.getUserUsingGET(userId, getAuthorizationHeader()),

  search: (query: string, roles: ReadonlyArray<RoleDto.NameEnum>) =>
    userApi.queryUsersUsingGET(
      query,
      getAuthorizationHeader(),
      undefined,
      roles,
    ),

  resendActivationEmail: (userId: string) =>
    userApi.resendActivationEmailUsingPUT(userId, getAuthorizationHeader(), {
      // for some reason the generated api call is missing the content type...
      headers: {"Content-Type": "application/json"},
    }),

  resetPassword: (data: CreateResetPasswordDto) =>
    userApi.resetPasswordUsingPUT(data),

  changePassword: (data: ChangePasswordDto) =>
    userApi.changeForgottenPasswordUsingPUT(data),

  changeUserPassword: (data: ChangeUserPasswordDto, userId: string) =>
    userApi.changeUserPasswordUsingPUT(data, userId, getAuthorizationHeader()),

  verifyAccount: (data: VerificationUserDto) =>
    userApi.verifyAccountUsingPUT(data),

  update: (userId: string, data: UpdateStaffDto) =>
    userApi.updateStaffUserUsingPUT(data, userId, getAuthorizationHeader()),

  patch: (userId: string, data: ProfileUserDto) =>
    userApi.updateProfileUserUsingPATCH(data, userId, getAuthorizationHeader()),

  putReportingPermissions: (
    userId: string,
    permissions: ReadonlyArray<string>,
  ) =>
    userApi.updateReportingPermissionsUsingPUT(
      permissions,
      userId,
      getAuthorizationHeader(),
    ),

  fetchAll: (
    facilityIds: string[],
    roles: ReadonlyArray<ParametersDto.RolesEnum>,
    statuses: ReadonlyArray<ParametersDto.StatusesEnum>,
    {sort, ...pageable}: Pageable,
  ) =>
    userApi.fetchUsersUsingPOST(
      {
        facilityIds: facilityIds ? facilityIds : [],
        pageable: {
          ...pageable,
          properties: sort,
        } as any,
        roles,
        statuses:
          statuses[0] !== null
            ? statuses
            : [StatusesEnum.NEW, StatusesEnum.ACTIVE],
      },
      getAuthorizationHeader(),
    ),
};
