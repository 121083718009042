import React, {FunctionComponent} from "react";

import {ErrorBoundary} from "@reside/ui";

import {CellHeader, CellTextBig, DashboardCell} from "../dashboard-cell";
import {logErrorToSentry} from "../../utils/sentry";

export const AdmissionCellErrorBoundary: FunctionComponent = ({children}) => (
  <ErrorBoundary
    onCatch={logErrorToSentry}
    renderError={() => (
      <DashboardCell>
        <CellHeader>
          <CellTextBig>Oops, this admission is faulty.</CellTextBig>
        </CellHeader>
        <p>We've recorded the error.</p>
      </DashboardCell>
    )}
  >
    {children}
  </ErrorBoundary>
);
