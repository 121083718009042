import React, {FunctionComponent} from "react";
import {QueryClient, QueryClientProvider, setLogger} from "react-query";

import {isProd} from "../../config";

if (isProd) {
  setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const ReactQueryClientProvider: FunctionComponent = ({children}) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
