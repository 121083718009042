import {zonedTimeToUtc} from "date-fns-tz";

const FULL_DATE = "MM/dd/yyyy";
const FULL_TIME = "hh:mm:ss aaa";

export const DATE_FORMAT = {
  DAY: "MMMM d, yyyy",
  SHORT_DAY: "MMM d, yyyy",
  DAY_IN_MONTH: "MMMM d",
  SHORT_DAY_IN_MONTH: "MMM d",
  ISO_DATE: "yyyy-MM-dd",
  MONTH_NAME: "MMMM",
  FULL_DATE,
  FULL_TIME,
  FULL_DATE_TIME: `${FULL_DATE} ${FULL_TIME}`,
  DAY_SHORT_MONTH: "d MMM",
  DAY_SHORT_MONTH_YEAR: "d MMM yy",
};

const readTimeZone = () => {
  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

  /**
   * There might be invalid timezone not supported by the library, so we fallback to to Chicago in such case.
   */
  try {
    zonedTimeToUtc(new Date(), timeZone);

    return timeZone;
  } catch {
    return "America/Chicago";
  }
};

export const LOCAL_TIME_ZONE = readTimeZone();
