import {PayloadAction} from "typesafe-actions";
import {RoleDto, TableUserDto} from "@reside/reside-api-app";

export const GRANT_PERMISSION = "@USER_PERMISSIONS/GRANT_PERMISSION";
export const REVOKE_PERMISSION = "@USER_PERMISSIONS/REVOKE_PERMISSION";
export const FETCH_BEGIN = "@USER_PERMISSIONS/FETCH_BEGIN";
export const FETCH_SUCCESS = "@USER_PERMISSIONS/FETCH_SUCCESS";
export const FETCH_FAILURE = "@USER_PERMISSIONS/FETCH_FAILURE";
export const RESET_PERMISSION = "@USER_PERMISSIONS/RESET_PERMISSION";

export const FETCH_BY_PERMISSION_BEGIN =
  "@USER_PERMISSIONS/FETCH_BY_PERMISSION_BEGIN";
export const FETCH_BY_PERMISSION_SUCCESS =
  "@USER_PERMISSIONS/FETCH_BY_PERMISSION_SUCCESS";
export const FETCH_BY_PERMISSION_FAILURE =
  "@USER_PERMISSIONS/FETCH_BY_PERMISSION_FAILURE";

export const ALLOWED_ACTIONS = [GRANT_PERMISSION, REVOKE_PERMISSION];

export type UserPermissionsAction =
  | PayloadAction<
      typeof GRANT_PERMISSION,
      {userId: UserId; permission: TableUserDto.PermissionsEnum}
    >
  | PayloadAction<
      typeof REVOKE_PERMISSION,
      {userId: UserId; permission: TableUserDto.PermissionsEnum}
    >
  | PayloadAction<typeof FETCH_BEGIN, {userId: UserId}>
  | PayloadAction<
      typeof FETCH_SUCCESS,
      {userId: UserId; permissions: TableUserDto.PermissionsEnum[]}
    >
  | PayloadAction<typeof FETCH_FAILURE, {userId: UserId; error: any}>
  | PayloadAction<typeof RESET_PERMISSION, {userId: UserId}>;

export type UserPermissionsByTypeAction =
  | PayloadAction<typeof FETCH_BY_PERMISSION_BEGIN, {type: string}>
  | PayloadAction<typeof FETCH_BY_PERMISSION_SUCCESS, UsersByPermissionType>
  | PayloadAction<
      typeof FETCH_BY_PERMISSION_FAILURE,
      {permissionType: string; error: any}
    >;

export interface UserPermissionsStatePartial {
  fetching?: boolean;
  success?: boolean;
  permissions?: TableUserDto.PermissionsEnum[];
}

export interface UserByPermission {
  facilityName: string;
  fullName: string;
  id: string;
  facilities: any[];
  role: {name: RoleDto.NameEnum};
  typeValue: RoleDto.NameEnum;
}

export type UsersByPermissionType = {
  [name in TableUserDto.PermissionsEnum]?: UserByPermission[];
};
export interface UsersByPermissionsStatePartial {
  fetching?: boolean;
  success?: boolean;
  byName?: UsersByPermissionType;
}

export interface UserPermissionsResponseItem {
  id: string;
  name: TableUserDto.PermissionsEnum;
  category: string;
  status: "ACTIVE" | "INACTIVE";
  type: "REPORTING" | "ANALYTICS";
}

export type UserId = string;

export interface StateWithUserId<S> {
  [userId: string]: S;
}
