import {FlexColumn, Button, styled} from "@reside/ui";

export const Card = styled(FlexColumn)`
  gap: ${({theme}) => theme.space[4]}px;
  margin-top: ${({theme}) => theme.space[8]}px;
`;

export const BackButton = styled(Button)`
  width: 88px;
`;
