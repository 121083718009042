var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { NavLink } from "react-router-dom";
import { styled, theme } from "../../../theme";
import { Card } from "../../../card";
var Link = function (_a) {
    var _b = _a.activeStyle, activeStyle = _b === void 0 ? { fontWeight: theme.fontWeight.bold } : _b, disabled = _a.disabled, props = __rest(_a, ["activeStyle", "disabled"]);
    return disabled ? (React.createElement(DisabledLink, null, props.children)) : (React.createElement(StyledNavLink, __assign({ activeStyle: activeStyle }, props)));
};
var StyledNavLink = styled(NavLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 25px 35px;\n  text-decoration: none;\n  font-weight: ", ";\n  color: ", " !important;\n  display: inline-block;\n  cursor: pointer;\n  width: 100%;\n  box-sizing: border-box;\n  &:hover {\n    color: ", " !important;\n    font-weight: 600;\n  }\n"], ["\n  padding: 25px 35px;\n  text-decoration: none;\n  font-weight: ", ";\n  color: ", " !important;\n  display: inline-block;\n  cursor: pointer;\n  width: 100%;\n  box-sizing: border-box;\n  &:hover {\n    color: ", " !important;\n    font-weight: 600;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.normal;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var DisabledLink = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 25px 35px;\n  text-decoration: none;\n  font-weight: ", ";\n  color: ", " !important;\n  display: inline-block;\n  cursor: not-allowed;\n  width: 100%;\n  opacity: 50%;\n"], ["\n  padding: 25px 35px;\n  text-decoration: none;\n  font-weight: ", ";\n  color: ", " !important;\n  display: inline-block;\n  cursor: not-allowed;\n  width: 100%;\n  opacity: 50%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.normal;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
});
export var SideMenuItem = function (_a) {
    var children = _a.children;
    return (React.createElement(StyledCard, null, children({ Link: Link })));
};
var StyledCard = styled(Card)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  margin-bottom: 10px;\n"], ["\n  padding: 0;\n  margin-bottom: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
