import React from "react";
import {styled} from "@reside/ui";

import {
  getCheckListItemKey,
  ReportAdmission,
  ReportsTypeAdmission,
  SortDirection,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {Aggregation} from "../../../atoms/admin-reporting/atoms/aggregation";
import {Column} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  DateReportsCell,
  IconCheckCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";
import {
  CompareDateLabels,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {ChecklistItem} from "../../../services/ReportingService";
import {BackdropSpinner} from "../../../atoms/spinner";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {ListTableReporting} from "../../../atoms/admin-reporting";
import {LoadMoreButton} from "../atoms/load-more-button";

type Props = Readonly<ChecklistItem> & ReportProps;

export const CHECKLIST_ITEM_REPORT_ID = "checklist-item" as const;

const CenterSpinner = styled.div`
  .spinner-backdrop .spinner {
    position: absolute;
    margin: 0;
  }
`;

export const ReportChecklistItem = ({
  id,
  description,
  dateRange,
  facilities,
  reportingBlockSetters,
}: Props) => {
  const [sortDirection, sortField, onSort] = useServerSort(
    SortField.name,
    SortDirection.ASC,
  );

  const query = {
    from: dateRange.from,
    to: dateRange.to,
    sortDirection: sortDirection,
    sortField: sortField,
    facilities,
  };

  const checklistItem = {id, description};

  return (
    <ReportingContextReports
      dataPaths={withCompare({
        key: getCheckListItemKey({
          type: ReportsTypeAdmission.checklistItems,
          query,
          checklistItem,
        }),
        type: ReportsTypeAdmission.checklistItems,
        query,
        checklistItem,
      })}
      enabled={reportingBlockSetters.enabled}
      onDataPathChange={
        reportingBlockSetters
          ? reportingBlockSetters.setExportDataPath
          : undefined
      }
    >
      {(d, loadMore) => {
        const isLoading = d[0]?.dataState?.fetching;
        const negativeAnswersTotal = d[0]?.dataState?.negativeAnswersTotal;
        const positiveAnswersTotal = d[0]?.dataState?.positiveAnswersTotal;

        return (
          <CenterSpinner>
            <div className="chart-header">
              <CardTitle title={description} />
              <CompareDateLabels dateRange={dateRange} />
            </div>
            <>
              <div className="aggregation-row">
                <Aggregation
                  reports
                  subject="Complete"
                  values={isLoading ? [] : [positiveAnswersTotal]}
                />
                <Aggregation
                  reports
                  subject="Incomplete"
                  values={isLoading ? [] : [negativeAnswersTotal]}
                />
              </div>
              <BackdropSpinner active={isLoading}>
                <ListTableReporting
                  data={d}
                  full={reportingBlockSetters.isFullReport}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  onSortChange={onSort}
                >
                  <Column<ReportAdmission>
                    sortKey={SortField.residentName}
                    cellRenderer={row => (
                      <TextCellEllipsis small>
                        {row.rowData.residentName}
                      </TextCellEllipsis>
                    )}
                    label="Resident Name"
                    width={26}
                  />
                  <Column<ReportAdmission>
                    cellRenderer={row => (
                      <TextCellEllipsis small>
                        {row.rowData.facilityName}
                      </TextCellEllipsis>
                    )}
                    label="Facility Name"
                    sortKey={SortField.facilityName}
                    width={29}
                  />
                  <Column<ReportAdmission>
                    cellRenderer={row => (
                      <DateReportsCell data={row.rowData.admissionDate} />
                    )}
                    label="Admission Date"
                    sortKey={SortField.admissionDate}
                    width={14}
                  />
                  <Column<ReportAdmission>
                    cellRenderer={row => (
                      <DateReportsCell data={row.rowData.answerDate} />
                    )}
                    label="Date when received"
                    sortKey={SortField.answerDate}
                    width={22}
                  />
                  <Column<ReportAdmission>
                    cellRenderer={row => (
                      <IconCheckCell accepted={row.rowData.answerIsPositive} />
                    )}
                    label="Complete"
                    sortKey={SortField.answerIsPositive}
                    width={10}
                  />
                  <Column<ReportAdmission>
                    cellRenderer={row => (
                      <IconCheckCell accepted={!row.rowData.answerIsPositive} />
                    )}
                    label="Incomplete"
                    sortKey={SortField.answerIsNegative}
                    width={10}
                  />
                </ListTableReporting>
                <LoadMoreButton
                  hasMorePages={hasMorePages(
                    d,
                    reportingBlockSetters.isFullReport,
                  )}
                  onClick={loadMore}
                />
              </BackdropSpinner>
            </>
          </CenterSpinner>
        );
      }}
    </ReportingContextReports>
  );
};
