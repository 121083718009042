import React from "react";
import {connect} from "react-redux";
import {Admission} from "@reside/reside-api-admission";
import {Text, DropdownMenuItem} from "@reside/ui";
import {Dictionary} from "lodash";

import {select, AppState} from "../../../../store";
import {getResidentTestId} from "../../helper";
import {ModalName, useDashboardModalContext} from "../../DashboardModalContext";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";

export const Renderer = ({
  admission,
  isOrgAdmin,
  facilityFlagsById,
}: {
  admission: AdmissionFromSearch;
  isOrgAdmin: boolean;
  facilityFlagsById: Dictionary<{[key: string]: boolean}>;
}) => {
  const {toggleModal} = useDashboardModalContext(ModalName.CANCEL);
  const hasOrgAdminOnlyDeleteAdmissions =
    facilityFlagsById[admission.facilityId]
      ?.facilityHasOrgAdminOnlyDeleteAdmissions;
  const isDisabled = hasOrgAdminOnlyDeleteAdmissions ? !isOrgAdmin : false;

  return (
    <DropdownMenuItem
      onClick={() => {
        !isDisabled && toggleModal(admission);
      }}
      testId={getResidentTestId(admission, Admission.StatusEnum.CANCELLED)}
      disabled={isDisabled}
    >
      <Text color="deepOrange100">Delete</Text>
    </DropdownMenuItem>
  );
};

const mapStateToProps = (state: AppState) => ({
  isOrgAdmin: select.adminSession.isOrgAdmin(state),
  facilityFlagsById: select.adminSession.facilityFlagsById(state),
});

export const DeleteAdmissionDropdownItem = connect(mapStateToProps)(Renderer);
