import React, {useMemo, createContext, ReactNode, useContext} from "react";
import {QueryVariables} from "@reside/ui";

import {
  SlideNode,
  UIState,
  UIActions,
  Draft,
  DraftActions,
  CompletionCheck,
} from "./model";

type Props = Readonly<{
  draft: Draft;
  draftActions: DraftActions;
  uiState: UIState;
  uiActions: UIActions;
  completionChecklist: CompletionCheck[];
  logoutSession: () => void;
  loadingPatchAdmission: boolean;
  hasClearHiddenFieldsFlag: boolean,
  children: ReactNode;
}> &
  QueryVariables;

export type AdmissionContextType = Omit<Props, "children"> &
  Readonly<{
    currentSlide: SlideNode;
  }>;

export const AdmissionContext = createContext<AdmissionContextType>(undefined);

export const AdmissionContextConsumer = ({
  children,
}: {
  children: (context: AdmissionContextType) => ReactNode;
}) => (
  <AdmissionContext.Consumer>
    {context => {
      if (context === undefined) {
        throw new Error(
          "AdmissionContextConsumer must be used within the AdmissionContextProvider",
        );
      }

      return children(context);
    }}
  </AdmissionContext.Consumer>
);

export const AdmissionContextProvider = (props: Props) => {
  const {
    draft,
    draftActions,
    uiState,
    uiActions,
    completionChecklist,
    logoutSession,
    loadingPatchAdmission,
    children,
    queryVariables,
    hasClearHiddenFieldsFlag,
  } = props;

  const value = useMemo(() => {
    return {
      currentSlide: draft.slides[uiState.slideIdx],
      draft,
      draftActions,
      uiActions,
      uiState,
      completionChecklist,
      logoutSession,
      loadingPatchAdmission,
      queryVariables,
      hasClearHiddenFieldsFlag
    };
  }, [
    draft,
    draftActions,
    uiState,
    uiActions,
    completionChecklist,
    logoutSession,
    loadingPatchAdmission,
    queryVariables,
    hasClearHiddenFieldsFlag,
  ]);

  return (
    <AdmissionContext.Provider value={value}>
      {children}
    </AdmissionContext.Provider>
  );
};

export const useAdmissionContext = () => useContext(AdmissionContext);
