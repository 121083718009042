import {PieData} from "../../../../pages/page-reporting/analytics/helpers";

export const getCommonLabels = (data: PieData[]) => {
  const result = data.reduce((acc, currentItem) => {
    currentItem.stack.forEach(item => {
      acc.add(item.x);
    });

    return acc;
  }, new Set());

  return Array.from(result);
};
