import React from "react";
import {useToggle} from "react-use";
import {Button} from "@reside/ui";

import {UserDeleteModal} from "./UserDeleteModal";

export const DeleteUserButton = () => {
  const [isDeleteModalOpen, toggleIsDeleteModalVisible] = useToggle(false);

  return (
    <div style={{marginTop: "24px"}}>
      <UserDeleteModal
        isOpen={isDeleteModalOpen}
        onRequestClose={toggleIsDeleteModalVisible}
      />
      <Button onClick={toggleIsDeleteModalVisible} color="danger" outline>
        Delete
      </Button>
    </div>
  );
};
