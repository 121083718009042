import React from "react";

import {H3, StarRatingField, RadioGroupField} from "@reside/ui";

export const SurveyForm = () => (
  <>
    <H3>Please rate your experience with the Reside Admissions</H3>
    <div className="star-rating-filed-container">
      <StarRatingField name="admissionExperienceAndOverallExperience" />
    </div>
    <H3>How easy was it to complete the Reside Admissions Experience?</H3>
    <div className="star-rating-filed-container">
      <StarRatingField name="admissionExperienceAndEaseOfUse" />
    </div>
    <RadioGroupField
      label={
        <H3>
          Has this admission process improved your impression of this Facility?
        </H3>
      }
      name="facilityImpressionDidImprove"
      options={[
        {label: "Yes", value: true},
        {label: "No", value: false},
      ]}
    />
  </>
);
