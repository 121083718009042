import {DropdownMenuButtonStyled, UserName} from "./styles";
import {Avatar} from "../../avatar";

/** Button that triggers dropdown menu to open. */
export const DropdownButton = (open: boolean, name: string): JSX.Element => (
  <DropdownMenuButtonStyled open={open} data-test-id="userMenu">
    <Avatar size={34} name={name} />
    <UserName>{name}</UserName>
  </DropdownMenuButtonStyled>
);
