import React, { useState } from "react";
import { Field } from "formik";
import { Label } from "../atoms/Label";
import { FieldError, LockIcon } from "../atoms";
import { FlexRowCenter } from "../../box";
import { Column, Input, YearInput, MainLabel, SeparateFieldError, } from "./styles";
import { useHandleSeparateFields } from "./useHandleSeparateFields";
import { toggleErrorColor, validateRange } from "./helpers";
export var DateFieldInput = function (_a) {
    var label = _a.label, name = _a.name, disabled = _a.disabled, range = _a.range;
    var _b = useHandleSeparateFields(name, disabled, range), onBlurDay = _b.onBlurDay, onBlurMonth = _b.onBlurMonth, onBlurYear = _b.onBlurYear, onChangeDay = _b.onChangeDay, onChangeMonth = _b.onChangeMonth, onChangeYear = _b.onChangeYear, onPasteDate = _b.onPasteDate, yearRef = _b.yearRef, monthRef = _b.monthRef, dayRef = _b.dayRef, error = _b.error;
    var _c = useState(""), rangeErr = _c[0], setRangeErr = _c[1];
    return (React.createElement(Field, { name: name, validate: function (value) {
            var _a = validateRange(new Date(value), range), isAfter = _a.isAfter, isBefore = _a.isBefore;
            var colorFields = function (color) {
                toggleErrorColor(monthRef, color);
                toggleErrorColor(dayRef, color);
                toggleErrorColor(yearRef, color);
            };
            if (range) {
                if (isAfter) {
                    setRangeErr("Date is in future");
                    colorFields("red");
                }
                else if (isBefore) {
                    setRangeErr("Date is in past");
                    colorFields("red");
                }
                else if (rangeErr) {
                    setRangeErr("");
                    colorFields("white");
                }
            }
        } }, function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Label, null,
                React.createElement(MainLabel, { visible: true, "data-test-id": "label-".concat(name) }, label),
                React.createElement(FlexRowCenter, null,
                    React.createElement(Column, null,
                        React.createElement(Label, null, "Month"),
                        React.createElement(Input, { label: "Month", testId: "month-".concat(name), type: "tel", ref: monthRef, maxLength: 2, placeholder: "MM", onChange: onChangeMonth, onBlur: onBlurMonth, onPaste: onPasteDate, disabled: disabled })),
                    React.createElement(Column, null,
                        React.createElement(Label, null, "Day"),
                        React.createElement(Input, { label: "Day", testId: "day-".concat(name), type: "tel", ref: dayRef, maxLength: 2, placeholder: "DD", onChange: onChangeDay, onBlur: onBlurDay, onPaste: onPasteDate, disabled: disabled })),
                    React.createElement(Column, null,
                        React.createElement(Label, null, "Year"),
                        React.createElement(YearInput, { label: "Year", testId: "year-".concat(name), type: "tel", ref: yearRef, minLength: 4, maxLength: 4, placeholder: "YYYY", onChange: onChangeYear, onPaste: onPasteDate, onBlur: onBlurYear, disabled: disabled })),
                    disabled && (React.createElement("div", { style: { marginLeft: "auto" } },
                        React.createElement(LockIcon, null))))),
            (error || rangeErr) && !disabled && (React.createElement(SeparateFieldError, { "data-test-id": "separate-field-error" }, error || rangeErr)),
            React.createElement(FieldError, { name: name })));
    }));
};
