import {useMutation} from "react-query";
import {ResidentService} from "../../../services/ResidentServices";
import Alert from "react-s-alert";
import {useHistory} from "react-router";

export const useInactivateResident = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const history = useHistory();

  return useMutation(
    (resident_id: string) => ResidentService.inactivateResident(resident_id),
    {
      onError: () => {
        Alert.error("Failed to delete resident.");
      },
      onSuccess: () => {
        Alert.success("Resident was successfully deleted.");
        history.push("/admin/residents");
        closeModal();
      },
    },
  );
};
