import React from "react";

import {ScrollReminder} from "../../scroll-reminder";
import {useScrollContext} from "../scroll-context";

/**
 * Display scroll reminder based on scroll state.
 */
export const TrackedScrollReminder = () => {
  const {scrollable, didScroll} = useScrollContext();

  return scrollable && <ScrollReminder hidden={didScroll} />;
};
