import React from "react";
import {useToggle} from "react-use";

import {Button} from "@reside/ui";

import {FacilityDeleteModal} from "../facility-delete-modal";

export const FacilityDeleteButton = () => {
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  return (
    <>
      <Button color="danger" onClick={toggleModalOpen}>
        Delete
      </Button>
      <FacilityDeleteModal
        isOpen={isModalOpen}
        onRequestClose={toggleModalOpen}
      />
    </>
  );
};
