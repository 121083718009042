import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
  IReportingAnalyticsAction,
  SET_ANALYTICS,
  CLEAR_ANALYTICS,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  StateWithFromTo,
  StateWithResolution,
  IReportingAnalyticsDataState,
  isWithResolution,
  isAnalyticsDataPath,
  StateWithAnalyticsType,
  StateWithTo,
  StateWithFrom,
  StateWithFacility,
  ActiveFacilities,
  getFacilitiesKey,
} from "./reporting.common";
import {combineReducers, Reducer} from "redux";
import {reportsRootReducer} from "../reporting-reports/reporting-reports.reducers";
import {setActiveFacilities} from "./reporting.actions";

const INITIAL_STATE_WITH_FROMTO = {};

export const withFacility: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithFacility<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = {}, action) => {
    if (state !== undefined) {
      const facilitiesKey = getFacilitiesKey(action.payload.path.facilities);
      const selectedState = state[facilitiesKey];

      if (selectedState !== undefined) {
        return update(state, {
          [facilitiesKey]: {
            $apply: (obj: any) =>
              update(obj, {
                $set: baseReducer(obj, action),
              }),
          },
        });
      } else {
        return update(state, {
          [facilitiesKey]: {
            $set: baseReducer(selectedState, action),
          },
        });
      }
    }

    return state;
  };

const withFrom: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithFrom<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      const selectedState = state[action.payload.path.query.from];

      if (selectedState !== undefined) {
        return update(state, {
          [action.payload.path.query.from]: {
            $apply: (obj: any) => update(obj, {$set: baseReducer(obj, action)}),
          },
        });
      } else {
        return update(state, {
          [action.payload.path.query.from]: {
            $set: baseReducer(selectedState, action),
          },
        });
      }
    }

    return state;
  };

const withTo: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithTo<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      const selectedState = state[action.payload.path.query.to];

      if (selectedState !== undefined) {
        return update(state, {
          [action.payload.path.query.to]: {
            $apply: (obj: any) =>
              update(obj, {
                // this is bad because it trashes whole state under,
                $set: baseReducer(selectedState, action),
              }),
          },
        });
      } else {
        return update(state, {
          [action.payload.path.query.to]: {
            $set: baseReducer(selectedState, action),
          },
        });
      }
    }

    return state;
  };

export const withFromTo: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithFromTo<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_FROMTO, action) => {
    if (state !== undefined) {
      return withFrom(withTo(baseReducer))(state, action);
    }

    return state;
  };

const INITIAL_STATE_WITH_RESOLUTION = {};

export const withResolution: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithResolution<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_RESOLUTION, action) => {
    if (action.payload && isWithResolution(action.payload.path)) {
      const resolution = action.payload.path.query.resolution;
      const selectedState = state[resolution];

      return update(state, {
        [action.payload.path.query.resolution]: {
          $apply: (obj = {}) =>
            update(obj || {}, {
              $set: baseReducer(selectedState, action),
            }),
        },
      });
    }

    return state;
  };

export const withoutResolution: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<S, IReportingAnalyticsAction> = baseReducer => (state, action) => {
  if (action.payload && !isWithResolution(action.payload.path)) {
    return baseReducer(state, action);
  }
  if (!state) {
    return baseReducer(state, action);
  }

  return state;
};

const INITIAL_STATE_WITH_TYPE = {};

export const withAnalyticsType: <S>(
  baseReducer: Reducer<S, IReportingAnalyticsAction>,
) => Reducer<StateWithAnalyticsType<S>, IReportingAnalyticsAction> =
  baseReducer =>
  (state = INITIAL_STATE_WITH_TYPE, action) => {
    if (action.payload && isAnalyticsDataPath(action.payload.path)) {
      const selectedState = state[action.payload.path.type];

      return update(state, {
        [action.payload.path.type]: {
          $apply: (obj: any) =>
            update(obj || {}, {$set: baseReducer(selectedState, action)}),
        },
      });
    }

    return state;
  };

const INITAIL_STATE_DATA = {};
const analyticsReducer: Reducer<
  IReportingAnalyticsDataState,
  IReportingAnalyticsAction
> = (state = INITAIL_STATE_DATA, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return {...state, data: action.payload.data};
    case FETCH_BEGIN:
      return {...state, fetching: true, success: false};
    case FETCH_SUCCESS:
      return {...state, fetching: false, success: true};
    case FETCH_FAILURE:
      return {...state, fetching: false, success: false};
    case CLEAR_ANALYTICS:
      return {};
    default:
      return state;
  }
};

const INITIAL_ACTIVE_FACILITIES: ActiveFacilities = {
  checkedKeys: undefined,
  checkedLeafKeys: undefined,
};

const activeFacilities = createReducer(INITIAL_ACTIVE_FACILITIES).handleAction(
  setActiveFacilities,
  (_, {payload}) => payload,
);

export const rootReportingReducerMap = {
  analyticsWithResolution: withAnalyticsType(
    withFromTo(withResolution(withFacility(analyticsReducer))),
  ),
  analyticsWithoutResolution: withoutResolution(
    withAnalyticsType(withFromTo(withFacility(analyticsReducer))),
  ),
  reports: reportsRootReducer,
  activeFacilities,
};

export const rootReportingReducer = combineReducers(rootReportingReducerMap);
