"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = exports.MAX_ITEMS_RULE_NAME = void 0;
exports.MAX_ITEMS_RULE_NAME = "max_items";
exports.Validator = {
    name: exports.MAX_ITEMS_RULE_NAME,
    errorMessage: "This array field may not have more than :".concat(exports.MAX_ITEMS_RULE_NAME, " items."),
    validate: function (value, requirement) {
        return Array.isArray(value) && value.length <= Number(requirement);
    },
};
