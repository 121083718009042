import {useEffect} from "react";

import {useAppVersionQuery} from "./useAppVersionQuery";

const useAppUpdateReload = () => {
  const {data} = useAppVersionQuery();

  const {updateAvailable = false} = data;

  useEffect(() => {
    if (updateAvailable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.location.reload(true);
    }
  }, [updateAvailable]);
};

export const AppUpdateReload = (): JSX.Element => {
  useAppUpdateReload();

  return null;
};
