import React, {createRef, useState} from "react";
import {connect} from "react-redux";
import AnnounceKit from "announcekit-react";
import {useUnmount} from "react-use";
import {keyframes} from "@emotion/css";
import {UserDto} from "@reside/reside-api-app";
import {Icon, Tooltip, styled} from "@reside/ui";
import {AppState, select} from "../../store";
import {segmentationDataAnnounceKit, userAnnounceKit} from "./helpers";

const ANNOUNCEKIT_WIDGET_URL = process.env["REACT_APP_ANNOUNCEKIT_URL"];

type Props = {
  user: UserDto;
};

export const Renderer = ({user}: Props) => {
  const ref = createRef<AnnounceKit>();
  const [unread, setUnread] = useState(0);

  const destroyWidget = async () => {
    const instance: any = await ref.current?.instance();
    /* As the widget is loaded with an external script, we need to destroy it when component unmounts. Otherwise it would be still visible */
    instance?.destroy();
  };

  useUnmount(() => destroyWidget());

  return (
    !!ANNOUNCEKIT_WIDGET_URL && (
      <Container onClick={async () => await ref.current?.open()}>
        <AnnounceKit
          ref={ref}
          widget={ANNOUNCEKIT_WIDGET_URL}
          user={user && userAnnounceKit(user)}
          data={user && segmentationDataAnnounceKit(user)}
          boosters={false}
          onWidgetUnread={(unreadPosts: number) => setUnread(unreadPosts)}
          onWidgetOpen={() => setUnread(0)}
        />
        <Tooltip content="What's new">
          <AlertIcon name="flash-outline" />
          {unread > 0 && <UnreadIcon />}
        </Tooltip>
      </Container>
    )
  );
};

const Container = styled.div`
  position: relative;
  width: 32px;
  margin-right: ${({theme}) => theme.space[2]}px;
`;

const AlertIcon = styled(Icon)`
  cursor: pointer;
`;

const shake = keyframes`
  0% {
    transform: translateX(-10%);
  }
  20% {
    transform: translateX(10%);
  }
  40% {
    transform: translateX(-10%);
  }
  50% {
    transform: translateX(0%);
  }
`;

const UnreadIcon = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  width: 12px;
  height: 12px;
  background: ${({theme}) =>
    `linear-gradient(90deg, ${theme.color.pink100}, ${theme.color.deepOrange100})`};
  color: ${({theme}) => theme.color.white};
  border-radius: 50%;
  animation: ${shake} 800ms infinite;
`;

const mapStateToProps = (state: AppState): Props => ({
  user: select.adminSession.user(state),
});

export const AnnounceKitWidget = connect(mapStateToProps)(Renderer);
