import {useEffect, useRef} from "react";
import {ReportsDataPath} from "../../store/reporting-reports/reporting-reports.common";

export const dataPathsDeepCompareEquals = (
  a: ReportsDataPath[],
  b: ReportsDataPath[],
) => {
  if (a === b) {
    return true;
  }

  if (!!a !== !!b) {
    return false;
  }

  if (!a && !b) {
    return true;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    const dpa = a[i];
    const dpb = b[i];

    if (
      dpa.query.facilities !== dpb.query.facilities ||
      dpa.query.from !== dpb.query.from ||
      dpa.query.to !== dpb.query.to ||
      dpa.type !== dpb.type ||
      dpa.query.sortDirection !== dpb.query.sortDirection ||
      dpa.query.sortField !== dpb.query.sortField
    ) {
      return false;
    }
  }

  return true;
};

export const useDataPathChange = (
  dataPath: ReportsDataPath,
  onDataPathChange?: (dataPath: ReportsDataPath) => void,
) => {
  const effect = () => {
    if (onDataPathChange) {
      onDataPathChange(dataPath);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [
    dataPath.facilities,
    dataPath.query.from,
    dataPath.query.to,
    dataPath.query.sortDirection,
    dataPath.query.sortField,
    dataPath.type,
  ]);
};

export const useDataPathsDeepCompareMemoize = (value: ReportsDataPath[]) => {
  const ref = useRef<ReportsDataPath[]>();

  if (!dataPathsDeepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
