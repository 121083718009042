var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
export var FileInput = forwardRef(function (_a, ref) {
    var acceptedMediaTypes = _a.acceptedMediaTypes, onChange = _a.onChange, disabled = _a.disabled, props = __rest(_a, ["acceptedMediaTypes", "onChange", "disabled"]);
    return (React.createElement("input", __assign({}, props, { ref: ref, hidden: true, type: "file", accept: (acceptedMediaTypes === null || acceptedMediaTypes === void 0 ? void 0 : acceptedMediaTypes.length)
            ? acceptedMediaTypes.join(", ")
            : undefined, disabled: disabled, onChange: function (event) {
            if (disabled) {
                return undefined;
            }
            onChange(event);
            event.currentTarget.value = null;
        } })));
});
FileInput.defaultProps = {
    acceptedMediaTypes: [],
    multiple: false,
};
