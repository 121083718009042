import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Text,
  Title,
} from "@reside/ui";
import {useFormikContext} from "formik";
import {dispatch} from "../../store";
import {useClearAndCancelCache} from "../../hooks/useClearAndCancelCache";
import {literalStringToBoolean} from "../../utils/helpers/litteralStringToBoolean";

type SaveBeforeLeavePromptProps = {
  /** If you need to validate if form has changes on your own (see useIsPreflightDirty) */
  nonFormikDirty?: boolean;
  /** Title for custom popup suitable for the page you want it to appear on. */
  title?: string;
  /** Warning message for custom popup suitable for the page you want it to appear on. */
  warningMessage?: string;
};

/** Component that controlls navigation out of form that is being edited, when changes are not saved. It will show a custom prompt when user is navigating internaly (with router) and default browser behaviour when he is reloading the page or closing a tab.
 */
export function SaveBeforeLeavePrompt(props: SaveBeforeLeavePromptProps) {
  const {
    title = "Unsaved Changes",
    warningMessage = "All unsaved changes will be lost. Proceed?",
    nonFormikDirty,
  } = props;

  const history = useHistory();

  const {dirty: formikDirty, isSubmitting} = useFormikContext();
  const {clearAndCancelCache} = useClearAndCancelCache();

  const [showPrompt, setShowPrompt] = useState(false);
  /** When a form is dirty and user wants to sign out we need to handle it separately. */
  const [showPromptOnSignout, setShowPromptOnSignout] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef(null);

  const handleShowModal = () => {
    setShowPrompt(true);
  };

  const onCancel = () => {
    setShowPrompt(false);
    setShowPromptOnSignout(false);
  };

  useEffect(() => {
    sessionStorage.setItem(
      "formDirty",
      nonFormikDirty?.toString() ?? formikDirty?.toString(),
    );

    const onSignOut = () => {
      if (literalStringToBoolean(sessionStorage.getItem("stopSignOut"))) {
        setShowPromptOnSignout(true);
      }
    };

    window.addEventListener("storage", onSignOut);

    /** Default browser control for reload of the page and closing a tab when form is dirty. It is not possible to change this to much, style it or add custom message: https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup*/
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isSubmitting ? false : nonFormikDirty ?? formikDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    /** Control internal app navigation via react-router */
    unblockRef.current = history.block(
      //@ts-expect-error - this ok
      location => {
        if (isSubmitting ? false : nonFormikDirty ?? formikDirty) {
          setCurrentPath(location.pathname);
          handleShowModal();
          return false;
        }
        return true;
      },
    );

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("storage", onSignOut);
      unblockRef?.current();
      sessionStorage.removeItem("formDirty");
      sessionStorage.removeItem("stopSignOut");
    };
  }, [formikDirty, isSubmitting, history, nonFormikDirty]);

  const handleConfirm = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  };

  return showPrompt || showPromptOnSignout ? (
    <Modal
      isOpen={showPrompt || showPromptOnSignout}
      onRequestClose={onCancel}
      size="small"
      noCloseButton
    >
      {({closeModal}) => (
        <>
          <ModalContent>
            <Title>{title}</Title>
            <Text fontSize="1rem">{warningMessage}</Text>
          </ModalContent>
          <ModalFooterButtons>
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button color="primary" outline onClick={closeModal}>
                Continue Editing
              </Button>

              {showPromptOnSignout ? (
                <Button
                  color="danger"
                  onClick={async () => {
                    clearAndCancelCache();
                    await dispatch.adminSession.logout();
                    history.replace("/admin");
                  }}
                >
                  Discard Changes
                </Button>
              ) : (
                <Button color="danger" onClick={handleConfirm}>
                  Discard Changes
                </Button>
              )}
            </Box>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  ) : null;
}
