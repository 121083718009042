import {
  AdmissionFromSearch,
  AdmissionStatus,
} from "../../../services/AdmissionsService";
import {getPdfUrl, withAccessToken} from "../../../utils/url";
import {readResidentEmailAnswer} from "../../../models/AdmissionModel.helpers";

export const hasPrimaryContactWithMail = (admission: AdmissionFromSearch) =>
  admission.answers.emergencyContact?.value.some(
    ({
      email,
      primaryContact,
    }: {
      email: string;
      primaryContact: "true" | "false";
    }) => primaryContact === "true" && email?.length,
  );

export const hasResidentEmail = (admission: AdmissionFromSearch) =>
  Boolean(readResidentEmailAnswer(admission as any)?.length);

export const redirectToAdmissionPDF = (admission: AdmissionFromSearch) =>
  window.open(
    admission.status === AdmissionStatus.APPROVED
      ? withAccessToken(admission.resources.finalPdf.downloadUrl)
      : getPdfUrl(admission.id),
    "_blank",
  );
