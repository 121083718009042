import React from "react";
import {DropdownMenuLink} from "@reside/ui";

import {UserDropdownMenuProps} from "./types";
import {DropdownMenuMedia} from "./styles";
import {DropdownButton} from "./DropdownButton";
import {SignOut} from "./SignOut";

/** This dropdown button in shape of user profile is shown on small screens. It will show options to navigate to profile or sign out. */
export const UserProfileAsDropdownMenu = ({user}: UserDropdownMenuProps) => {
  const name = `${user.firstName} ${user.lastName}`;

  return (
    <DropdownMenuMedia trigger={(open: boolean) => DropdownButton(open, name)}>
      <DropdownMenuLink
        to={`/admin/user/${user.id}/profile`}
        data-test-id="profileLink"
      >
        Profile
      </DropdownMenuLink>

      <SignOut signoutAsDropdownItem />
    </DropdownMenuMedia>
  );
};
