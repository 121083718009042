import {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Admission} from "@reside/reside-api-admission";

import {AppState, select} from "../../store";
import {Column, ListTable} from "../list-table";
import {AdmissionStatusCell, ResidentCell} from "../list-table/AdmissionsCells";
import {DateCell, FlagsCell, TextCell} from "../list-table/SharedCells";
import {PercentageCell} from "../list-table/PercentageCells";
import {SortDirection} from "../../store/reporting-reports/reporting-reports.common";
import {createPreflightUrl} from "../../pages/page-admin-preflight/route";
import {readDateOfAdmissionAnswer} from "../../models/AdmissionModel.helpers";
import {ActionMenuCell} from "./ActionMenuCell";
import {
  AdmissionsDeleteModalProvider,
  AdmissionsDeleteAdmissionModal,
} from "./admission-delete-modal";
import {
  AdmissionsRestoreAdmissionModal,
  AdmissionsRestoreModalProvider,
} from "./admission-restore-modal";
import {
  ChangeHistoryModal,
  ChangeHistoryModalProvider,
} from "../dashboard-table/atoms/change-history-modal";

const SHOWABLE_STATUSES = [
  Admission.StatusEnum.IN_PROGRESS,
  Admission.StatusEnum.SIGNED,
  Admission.StatusEnum.APPROVED,
  Admission.StatusEnum.CANCELLED,
  Admission.StatusEnum.ARCHIVED,
];

export const AdminAdmissionsTableRenderer = ({
  admissions,
  onSort,
  sortBy,
  sortDirection,
  tableName,
  isResideAdmin,
  onAdmissionDeleted,
  isAllowedToSeeAdmissions = true,
}: {
  sortBy?: string;
  sortDirection?: SortDirection;
  tableName: string;
  onSort?: (sortBy: string, sortDirection: SortDirection) => void;
  admissions: ReadonlyArray<Admission>;
  isResideAdmin: boolean;
  onAdmissionDeleted: () => void;
  isAllowedToSeeAdmissions?: boolean;
}) => {
  const [activeRowIndex, setActiveRowIndex] = useState<number>();
  const history = useHistory();

  return (
    <AdmissionsDeleteModalProvider>
      <AdmissionsRestoreModalProvider>
        <ChangeHistoryModalProvider>
          <AdmissionsDeleteAdmissionModal onDeleted={onAdmissionDeleted} />
          <AdmissionsRestoreAdmissionModal onRestored={onAdmissionDeleted} />
          <ChangeHistoryModal />
          <ListTable<Admission>
            key={tableName}
            showDoubleArrowOnNotSorted
            sortedData={isAllowedToSeeAdmissions ? admissions : []}
            getRowId={row => row.rowData.id}
            rowHeight={120}
            sortPredicates={{
              residentFullName: [
                "answers.resident_lastName.value",
                "answers.resident_firstName.value",
              ],
              code: ["code"],
              status: ["status"],
              created: ["created"],
              updated: ["updated"],
              dateOfAdmission: ["answers.preflight_dateOfAdmission.value"],
            }}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={onSort}
            onRowClick={(id: string) => {
              const admission = admissions.find(
                admission => admission.id === id,
              );

              if (!admission) {
                return;
              }

              if (SHOWABLE_STATUSES.includes(admission.status)) {
                history.push(`/admin/admissions/${admission.id}`);
              }

              if (
                admission.status === Admission.StatusEnum.PRE_FLIGHT &&
                !isResideAdmin
              ) {
                history.push(
                  createPreflightUrl({
                    facilityId: admission.facilityId,
                    admissionId: admission.id,
                  }),
                );
              }
            }}
            noRowsMessage="No Admissions found with these parameters..."
            activeRowIndex={activeRowIndex}
          >
            <Column
              cellRenderer={(row: any) => (
                <ResidentCell admission={row.rowData} />
              )}
              label="Resident"
              sortKey="residentFullName"
              width={350}
            />

            <Column
              cellRenderer={(row: any) => (
                <TextCell subtle data-test-id="admission-id">
                  {row.rowData.code}
                </TextCell>
              )}
              label="Admission ID"
              sortKey="code"
              width={150}
            />
            <Column
              cellRenderer={(row: any) => (
                <AdmissionStatusCell status={row.rowData.status} />
              )}
              label="Status"
              sortKey="status"
              width={150}
            />
            <Column
              cellRenderer={(row: any) => (
                <PercentageCell data={row.rowData.completionPercentage || 0} />
              )}
              label="Completed"
              sortKey="completed"
              width={150}
            />
            <Column
              cellRenderer={(row: any) => (
                <DateCell data={readDateOfAdmissionAnswer(row.rowData)} />
              )}
              label="Date of Admission"
              sortKey="dateOfAdmission"
              width={200}
            />
            <Column
              cellRenderer={(row: any) => (
                <DateCell
                  data={row.rowData.signatureDate}
                  timeZone={row.rowData.facilityTimeZoneId}
                />
              )}
              label="Signature Date"
              sortKey="signatureDate"
              width={130}
            />
            <Column
              cellRenderer={(row: any) => (
                <DateCell data={row.rowData.created} />
              )}
              label="Date added"
              sortKey="created"
              width={130}
            />
            <Column
              cellRenderer={(row: any) => (
                <DateCell data={row.rowData.updated} />
              )}
              label="Last modified"
              sortKey="updated"
              width={130}
            />
            <Column
              cellRenderer={(row: any) => (
                <FlagsCell data={row.rowData.flags.length} />
              )}
              label=""
              width={60}
            />
            <Column
              cellRenderer={(row: any) => (
                <ActionMenuCell
                  data={row.rowData}
                  onOpen={() => setActiveRowIndex(row.rowIndex)}
                  onClose={() => setActiveRowIndex(undefined)}
                />
              )}
              label=""
              width={60}
            />
          </ListTable>
        </ChangeHistoryModalProvider>
      </AdmissionsRestoreModalProvider>
    </AdmissionsDeleteModalProvider>
  );
};

const mapState = (state: AppState) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const AdminAdmissionsTable = connect(mapState)(
  AdminAdmissionsTableRenderer,
);
