import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {PccFacilityMappingDto} from "@reside/reside-api-app";

import {facilityApi} from "../../../services/FacilityService";
import {getAuthorizationHeader} from "../../../models/admin-session/selectors";

type Params = {
  facilityId: string;
  pccFacilityMapping: PccFacilityMappingDto;
};

export const usePccFacilityMappingMutation = () =>
  useMutation(
    (params: Params) =>
      facilityApi.createOrUpdateFacilityMappingUsingPUT(
        params.pccFacilityMapping,
        params.facilityId,
        getAuthorizationHeader(),
      ),
    {
      onSuccess: () => {
        Alert.success("PointClickCare mapping updated.");
      },
      onError: data => {
        Alert.error(
          "An error occurred while updating PointClickCare connection data",
        );
      },
    },
  );
