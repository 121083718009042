import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  ReportingBlock,
  ReportingBlockSetters,
} from "../../atoms/reporting-block";
import {DocumentTitle} from "../../atoms/document-title";
import {RequiredUserPermissionsSome} from "../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {ReportingGroupRenderer} from "../../atoms/admin-reporting/atoms/reporting-group/ReportingGroup";
import {ReportingLayout} from "./reporting-layout";
import {ReportChecklistItem} from "./reports";
import {ReportContextConsumer} from "./ReportContext";
import {UseChecklistItems} from "./reporting-menu/useChecklistItems";
import {Banner} from "../../atoms/banner";

export const PageReportingReportsCheckboxes = () => {
  return (
    <ReportingLayout>
      <DocumentTitle title="Reside - Admin - Reporting | Checkboxes" />
      <RequiredUserPermissionsSome
        permissions={[TableUserDto.PermissionsEnum.CHECKLIST_ITEMS]}
        noPermissionChildren={
          <Banner title="No reports are available for this section.">
            <p>Please choose a different report from the left menu.</p>
          </Banner>
        }
      >
        <ReportContextConsumer>
          {({baseDateRange, activeFacilitiesIds, setPageLoading}) => (
            <UseChecklistItems>
              {({data: checklistItems, isFetched}) =>
                isFetched && (
                  <>
                    {checklistItems.map(({id, description}) => (
                      <ReportingGroupRenderer
                        key={id}
                        id={id}
                        title={description}
                      >
                        <ReportingBlock
                          chartId={id}
                          permissionInChart={[
                            TableUserDto.PermissionsEnum.CHECKLIST_ITEMS,
                          ]}
                          dateRange={baseDateRange}
                          facilities={activeFacilitiesIds}
                          setPageLoading={setPageLoading}
                        >
                          {(reportingBlockSetters: ReportingBlockSetters) => (
                            <ReportChecklistItem
                              id={id}
                              description={description}
                              dateRange={baseDateRange}
                              facilities={activeFacilitiesIds}
                              reportingBlockSetters={reportingBlockSetters}
                            />
                          )}
                        </ReportingBlock>
                      </ReportingGroupRenderer>
                    ))}
                  </>
                )
              }
            </UseChecklistItems>
          )}
        </ReportContextConsumer>
      </RequiredUserPermissionsSome>
    </ReportingLayout>
  );
};
