import React, {useState, useMemo, ReactNode} from "react";
import {theme} from "@reside/ui";
import {cx} from "@emotion/css";
import {
  VictoryAxis,
  VictoryBar,
  VictoryLegend,
  VictoryStack,
  VictoryTooltip,
} from "victory";

import {
  ChartProps,
  getColorScale,
  colorScale,
  colorScaleRemaining,
  chartTheme,
} from "../../chart.helpers";
import {BarStackNew} from "../../../../pages/page-reporting/analytics/helpers";
import {SortDirection} from "../../../../store/reporting-reports/reporting-reports.common";
import {ContainerWidthSizer} from "../../ContainerWidthSizer";
import {SortIndicator} from "../sort-indicator";
import {HoverLabel, HoverLabelWrapper} from "../hover-label";
import {EmptyChart} from "../empty";

import "./style.scss";

const defaultProps = {
  data: [] as BarStackNew[],
  defaultSortColumn: "right",
  defaultSortDirection: SortDirection.DESC,
};

type Props = Readonly<{
  labelLeft?: ReactNode;
  labelRight?: ReactNode;
  max: number;
  itemsPerRow?: number;
  dataLegend?: any[];
  small?: boolean;
  tickFormat?: (val: any) => string;
  tickCount?: number;
  axTickFormat?: (val: any) => string;
  hoverDataPreprocessor?: (
    data: any[],
    line: BarStackNew,
    index: number,
  ) => any[];
  multiBarColoring?: boolean;
  fullWidth?: boolean;
  leftSortCustomMap?: any;
  defaultSortDirection?: SortDirection;
  defaultSortColumn?: "left" | "right";
  colorScale?: any[];
}>;

const BarChartRenderer = ({
  axTickFormat,
  data,
  dataLegend,
  defaultSortColumn,
  defaultSortDirection,
  full,
  hoverDataPreprocessor,
  labelLeft,
  labelRight,
  leftSortCustomMap,
  max,
  multiBarColoring,
  small,
  tickFormat,
  tickCount,
}: ChartProps<BarStackNew> & Props) => {
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [sortColumn, setSortColumn] = useState<"left" | "right">(
    defaultSortColumn,
  );
  const dataSorted = useMemo(() => {
    let sorted;

    if (sortColumn !== undefined && sortDirection) {
      const compareNumbers = (a: BarStackNew, b: BarStackNew) => {
        const na = parseFloat(
          sortColumn === "right" && a[sortColumn] !== "-"
            ? a.values[0].toString()
            : a[sortColumn],
        );
        const nb = parseFloat(
          sortColumn === "right" && b[sortColumn] !== "-"
            ? b.values[0].toString()
            : b[sortColumn],
        );

        if (isNaN(na)) {
          return 1;
        }
        if (isNaN(nb)) {
          return -1;
        }

        return (
          (na > nb ? 1 : -1) * (sortDirection === SortDirection.ASC ? 1 : -1)
        );
      };

      const compareStrings = (a: BarStackNew, b: BarStackNew) => {
        const a_value = leftSortCustomMap
          ? leftSortCustomMap[a[sortColumn]]
          : a[sortColumn];
        const b_value = leftSortCustomMap
          ? leftSortCustomMap[b[sortColumn]]
          : b[sortColumn];

        return (
          (a_value > b_value ? 1 : -1) *
          (sortDirection === SortDirection.ASC ? 1 : -1)
        );
      };

      sorted = data
        .slice()
        .sort(sortColumn === "left" ? compareStrings : compareNumbers);
    } else {
      sorted = data;
    }

    return sorted;
  }, [sortColumn, sortDirection, data, leftSortCustomMap]);

  let maxValuesLen = useMemo(
    () =>
      dataSorted.reduce(
        (max, stack) =>
          Math.max(
            max,
            stack.values.reduce(
              (max2, values) => Math.max(max2, values.length),
              0,
            ),
          ),
        0,
      ),
    [dataSorted],
  );

  if (multiBarColoring && dataLegend && dataLegend.length > 0) {
    maxValuesLen = dataLegend.length;
  }

  const extendedColorScale = getColorScale(
    maxValuesLen,
    colorScale,
    multiBarColoring,
  );

  const multiBarColoringFnc = (d: any) => {
    return extendedColorScale[
      dataLegend
        .map(function (e) {
          return e.name;
        })
        .indexOf(d.x)
    ];
  };

  const multiBarColoringStyle = multiBarColoring
    ? {
        style: {
          data: {
            fill: multiBarColoringFnc,
          },
        },
      }
    : {};

  const getItemsPerRow = (width: number) => {
    return Math.max(Math.floor(width / 250), 1);
  };

  const heightOfLegend = (width: number) => {
    let resultHeight;

    if (dataLegend && dataLegend.length >= getItemsPerRow(width)) {
      resultHeight = Math.ceil(dataLegend.length / getItemsPerRow(width)) * 40;
    } else {
      resultHeight = 45;
    }

    return resultHeight + 20;
  };

  // TODO: Should use some sort of sortable table
  return (
    <ContainerWidthSizer>
      {({width}) => (
        <div className={cx({"graph-bar-wrapper": !full})}>
          <table className="graph-bar">
            <thead>
              <tr>
                <th
                  style={{width: "25%"}}
                  onClick={() => {
                    if (sortColumn === "left") {
                      setSortDirection(nextSortDirection(sortDirection));
                    }
                    setSortColumn("left");
                  }}
                >
                  {labelLeft}{" "}
                  <SortIndicator
                    sortDirection={sortDirection}
                    sortColumn={sortColumn}
                    thisColumn="left"
                  />
                </th>
                <th />
                {labelRight && (
                  <th
                    onClick={() => {
                      if (sortColumn === "right") {
                        setSortDirection(nextSortDirection(sortDirection));
                      }
                      setSortColumn("right");
                    }}
                  >
                    {labelRight}{" "}
                    <SortIndicator
                      sortDirection={sortDirection}
                      sortColumn={sortColumn}
                      thisColumn="right"
                    />
                  </th>
                )}
              </tr>
            </thead>
            <tbody
              className={cx({
                "graph-bar-full": full,
                oneFacility: dataSorted.length === 1,
              })}
            >
              {dataSorted.map((line, i: number) => (
                <tr key={i}>
                  <td style={{width: "28%"}}>
                    <div className="graph-bar-text">
                      <span title={line.left}>{line.left}</span>
                    </div>
                  </td>
                  <td className="graph-bar-bar">
                    {line.values.map((values, i) => (
                      <HoverLabelWrapper
                        key={i}
                        labelComponent={
                          <HoverLabel
                            values={
                              hoverDataPreprocessor
                                ? hoverDataPreprocessor(values, line, i)
                                : values
                            }
                            legend={dataLegend}
                            colorScale={extendedColorScale}
                            tickFormat={tickFormat}
                          />
                        }
                      >
                        <div
                          className={i > 0 ? "graph-bar-second" : ""}
                          key={i}
                        >
                          <VictoryStack
                            colorScale={extendedColorScale}
                            standalone
                            horizontal
                            height={8}
                            theme={chartTheme}
                            padding={{
                              bottom: 0,
                              left: 5,
                              right: 5,
                              top: 0,
                            }}
                            width={width}
                          >
                            {values.length === 0 ? (
                              <VictoryBar
                                style={{
                                  data: {
                                    fill: colorScaleRemaining,
                                  },
                                }}
                                data={[1]}
                                key={-1}
                              />
                            ) : (
                              values.map((v, i) => (
                                <VictoryBar
                                  {...multiBarColoringStyle}
                                  data={[v]}
                                  key={i}
                                  labelComponent={
                                    <VictoryTooltip
                                      theme={chartTheme}
                                      orientation="left"
                                    />
                                  }
                                />
                              ))
                            )}
                          </VictoryStack>
                        </div>
                      </HoverLabelWrapper>
                    ))}
                  </td>
                  {labelRight && (
                    <td style={{width: !small ? "10%" : "15%"}}>
                      <div className="graph-bar-text">{line.right}</div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="graph-bar-custom-axis">
            <table>
              <tbody>
                <tr>
                  <td style={{width: "28%"}} />
                  <td>
                    <VictoryAxis
                      domain={{x: [0, max || 1]}}
                      orientation="bottom"
                      fixLabelOverlap
                      height={50}
                      width={width}
                      horizontal
                      style={{
                        axis: {
                          marginTop: 20,
                          stroke: theme.color.gray10,
                        },
                        tickLabels: {
                          fontSize: 18,
                          paddingTop: 10,
                          color: theme.color.darkBlue100,
                          opacity: 0.7,
                        },
                        grid: {stroke: "#parent", strokeWidth: 0},
                      }}
                      padding={{
                        top: 0,
                        right: 15,
                        bottom: 30,
                        left: 15,
                      }}
                      tickFormat={axTickFormat}
                      tickCount={tickCount}
                    />
                    {dataLegend && (
                      <VictoryLegend
                        x={0}
                        y={20}
                        colorScale={extendedColorScale}
                        orientation="horizontal"
                        gutter={{left: 0, right: 14}}
                        theme={chartTheme}
                        data={dataLegend}
                        height={heightOfLegend(width)}
                        width={width}
                        padding={{top: 0, left: 10, right: 10, bottom: 0}}
                        itemsPerRow={getItemsPerRow(width)}
                        style={{
                          labels: {
                            fontSize: 18,
                          },
                        }}
                      />
                    )}
                  </td>
                  {labelRight && <td style={{width: !small ? "10%" : "15%"}} />}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </ContainerWidthSizer>
  );
};

const nextSortDirection = (sortDirection: SortDirection) =>
  sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

export const BarChart = (props: ChartProps<BarStackNew> & Props) =>
  props.data?.length ? <BarChartRenderer {...props} /> : <EmptyChart />;

BarChartRenderer.defaultProps = defaultProps;
