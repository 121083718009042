import {Link as LinkStyle} from "react-router-dom";
import {
  Button as ButtonStyle,
  styled,
  Text as DefaultText,
  DropdownMenu,
  DropdownMenuButton,
  FlexRowCenter,
  Icon,
} from "@reside/ui";

// AdmissionMenuDropdownButton
export const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  margin-right: 8px;
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
`;

export const AdminIcon = styled(Icon)<{open: boolean}>`
  color: ${({open, theme}) =>
    open ? theme.color.primary : theme.color.darkBlue100};
  &:hover {
    color: ${({theme}) => theme.color.primary};
  }
`;

// User Profile
export const Link = styled(LinkStyle)`
  position: absolute;
  inset: 0;
`;

export const Text = styled(DefaultText)`
  color: ${({theme}) => theme.color.darkBlue100};
  text-decoration: none;
  font-size: ${({theme}) => theme.fontSize[3]}px;

  &:hover {
    opacity: 1;
  }
`;

export const Button = styled(ButtonStyle)`
  > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > span {
      padding-top: 0.125rem;
    }
  }
`;

// Signout
export const SignOutButton = styled(ButtonStyle)`
  span {
    color: ${({theme}) => theme.color.darkBlue100};
  }

  :is(&:hover, &:focus-within, &:focus) span {
    text-decoration: underline;
  }
`;

export const SignOutText = styled(DefaultText)`
  padding-block: 0.125rem 1rem;
  user-select: none;
  text-align: center;
`;

export const FlexRowJustify = styled(FlexRowCenter)`
  gap: 1rem;
  justify-content: space-between;
`;

// Dropdown menu
export const DropdownMenuButtonStyled = styled(DropdownMenuButton)`
  height: 100px;
`;

export const DropdownMenuMedia = styled(DropdownMenu)`
  @media (min-width: 1170px) {
    display: none;
  }
`;

export const UserName = styled(DefaultText)`
  font-size: ${({theme}) => theme.fontSize[3]}px;
  font-weight: ${({theme}) => theme.fontWeight.medium};
  margin-left: 10px;
`;
