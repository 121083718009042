import React, {useCallback} from "react";

import {ChecklistItem} from "@reside/reside-api-admission";
import {useMutation} from "react-query";

import {usePccDocumentUploadModalContext} from "../../pcc-document-upload-modal";
import {admissionHasActivePccResidentAccountStatus} from "../../../../../services/PccService";
import {ChecklistValues} from "../../checklist";
import {getUpdatesAdmissionChecklist} from "../../../helper";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {useDashboardAdmissionsContext} from "../../../DashboardAdmissionsContext";
import {AdmissionChecklistRenderer} from "./AdmissionChecklistRenderer";
import {PositionedBackdropSpinner} from "./style";

export const AdmissionChecklist = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => {
  const {
    patchAdmissionChecklistItem,
    facilityFlagsById,
    getAdmissionChecklistErrors,
    clearAdmissionChecklistErrors,
  } = useDashboardAdmissionsContext();
  const {isPccDashboardCheckboxesFeatureEnabled, openPccUploadModal} =
    usePccDocumentUploadModalContext();

  const {facilityHasPccTurnedOn = false, facilityHasPccUploadActive = false} =
    facilityFlagsById[admission?.facilityId] || {};

  const {id: admissionId, checklist = []} = admission;

  const isPccDocumentUploadEnabled =
    isPccDashboardCheckboxesFeatureEnabled &&
    facilityHasPccTurnedOn &&
    facilityHasPccUploadActive &&
    admissionHasActivePccResidentAccountStatus(admission);

  const onUpload = useCallback(
    (checklistItem: ChecklistItem) => {
      openPccUploadModal({admission, checklistItem});
    },
    [admission, openPccUploadModal],
  );

  const {mutate, isLoading} = useMutation(async (values: ChecklistValues) => {
    const updates = getUpdatesAdmissionChecklist(checklist, values);

    const [firstUpdate] = updates;

    if (firstUpdate) {
      const [checklistItemId] = firstUpdate;

      clearAdmissionChecklistErrors(admissionId, checklistItemId);
    }

    await Promise.all(
      updates.map(([checklistItemId, checklistItem]) =>
        patchAdmissionChecklistItem({
          admissionId,
          checklistItemId,
          data: checklistItem,
        }),
      ),
    );
  });

  return (
    <PositionedBackdropSpinner active={isLoading}>
      <AdmissionChecklistRenderer
        isPccDocumentUploadEnabled={isPccDocumentUploadEnabled}
        checklist={checklist}
        validationErrors={getAdmissionChecklistErrors(admission.id)}
        onChange={mutate}
        onUpload={onUpload}
      />
    </PositionedBackdropSpinner>
  );
};
