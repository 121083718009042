import {
  H1,
  H2,
  Box,
  Button,
  Paragraph,
  TextField,
  Icon,
  theme,
} from "@reside/ui";
import {useMutation, MutationStatus} from "react-query";
import {Formik, Form} from "formik";
import {validator} from "@reside/forms/dist/validator";
import Alert from "react-s-alert";
import {EmailAdmissionEntryLinkDto} from "@reside/reside-api-admission";

import {Layout} from "../../atoms/layout";
import {AdmissionEntryService} from "../../services/AdmissionEntryService";
import {AutoLogoutInfoBar} from "../../atoms/auto-logout";
import {Center, Description, SubmitButton} from "./style";

type PageWelcomeRendererProps = Readonly<{
  status: MutationStatus;
  onSubmit: (values: EmailAdmissionEntryLinkDto) => void;
  onReset: () => void;
}>;

export const PageWelcomeRenderer = ({
  status,
  onSubmit,
  onReset,
}: PageWelcomeRendererProps) => (
  <Layout backgroundImageUrl="/assets/getStartedBg.jpg">
    <Box width="100%">
      <img
        src="/assets/reside_logo_extended.svg"
        width="250"
        height="67"
        alt="Reside"
      />
      <Box width="100%" textAlign="center" marginTop={60}>
        <H1 fontSize={[6, 7]} lineHeight="70px">
          Welcome to the Reside Resident Experience
        </H1>
      </Box>
      <Center>
        {status === "success" ? (
          <Box textAlign="center">
            <Icon name="email" size={128} color={theme.color.mint100} />
            <H2>Check your inbox!</H2>
            <Paragraph fontSize={4}>
              Click the link in your email to enter your admission! It may take
              some time to receive an email. In case you didn't receive the
              email please contact your facility representative.
            </Paragraph>
            <Button color="success" onClick={onReset}>
              Ok
            </Button>
          </Box>
        ) : (
          <Formik<EmailAdmissionEntryLinkDto>
            onSubmit={onSubmit}
            initialValues={{email: ""}}
            validate={values =>
              validator.validateAll(
                values,
                {
                  email: ["required", "email"],
                },
                {
                  required: "Please fill in your email.",
                  email: "Sorry, but that email is invalid.",
                },
              )
            }
          >
            {() => (
              <Form>
                <Description>
                  <H2>Login with your email</H2>
                  <Paragraph fontSize={4}>
                    To start admission, please enter your email address. We'll
                    send you a link for password-free login.
                  </Paragraph>
                </Description>
                <TextField
                  disabled={status === "loading"}
                  type="email"
                  name="email"
                  label="E-mail Address"
                  placeholder="E-mail Address (e.g. example@reside.com)"
                />
                <SubmitButton
                  size="large"
                  type="submit"
                  testId="sendLoginLinkButton"
                  color="primary"
                  disabled={status === "loading"}
                >
                  {status === "loading" ? "Sending Link..." : "Send Link"}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        )}
      </Center>
    </Box>
  </Layout>
);

export const PageWelcome = () => {
  const {mutate, status, reset} = useMutation(
    AdmissionEntryService.emailLoginLink,
    {
      onError: async (response: any) => {
        const error = await response.json();
        Alert.error(
          error?.status === 404
            ? "The email address you have entered doesn't match any account, please contact your Admission Representative."
            : "Could not send email at this time. Please, try again later.",
        );
      },
    },
  );

  return (
    <>
      <AutoLogoutInfoBar />
      <PageWelcomeRenderer status={status} onSubmit={mutate} onReset={reset} />
    </>
  );
};
