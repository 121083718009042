import React from "react";
import {styled, H1, TagsField, TextField, Card} from "@reside/ui";
import {UserDto, RoleDto} from "@reside/reside-api-app";

import {Avatar} from "../../../../atoms/avatar";
import {AdminRoles} from "../../../../atoms/admin-roles";

export type Props = {
  user: UserDto;
};

export const EditProfileForm = ({
  user: {firstName, lastName, facilities},
}: Props) => (
  <Container>
    <H1>User Details</H1>
    <AvatarContainer>
      <Avatar size={82} name={`${firstName} ${lastName}`} />
    </AvatarContainer>
    <TextField name="firstName" type="text" label="First Name" />
    <TextField name="lastName" type="text" label="Last Name" />
    <TextField name="role" type="text" label="Role" disabled />
    <TextField name="email" type="text" label="Email Address" disabled />
    <TextField name="phone" type="tel" label="Phone" format="us-phone" />
    <AdminRoles
      roles={[
        RoleDto.NameEnum.FACILITY_ADMIN,
        RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
        RoleDto.NameEnum.REGIONAL_ADMIN,
        RoleDto.NameEnum.CLERK,
      ]}
    >
      <TagsField
        name="facilities"
        options={facilities.map(({name, id}) => ({
          label: name,
          value: id,
        }))}
        label="Facilities assigned"
        disabled
        testId="facilitySelect"
      />
    </AdminRoles>
  </Container>
);

const AvatarContainer = styled.div`
  margin-bottom: 10px;
`;

const Container = styled(Card)`
  padding: 50px 45px;
`;
