import React, {FunctionComponent, ReactNode} from "react";
import {theme, styled, H3, Text, FlexColumn} from "@reside/ui";
import {css} from "@emotion/react";

type Props = Readonly<{
  footer?: ReactNode;
  testId?: string;
}>;

export const ColumnHeader = ({
  title,
  subtitle,
  className,
}: {
  className?: string;
  title: ReactNode;
  subtitle: ReactNode;
}) => (
  <header className={className}>
    <H3>{title}</H3>
    <Text color="gray100" fontSize={1}>
      {subtitle}
    </Text>
  </header>
);

export const Column: FunctionComponent<Props> = ({
  footer,
  children,
  testId = "",
}) => (
  <Wrapper data-test-id={testId}>
    <FlexColumnBody>
      {children}
      {footer && <footer>{footer}</footer>}
    </FlexColumnBody>
  </Wrapper>
);

const columnStyle = css`
  background-color: ${theme.color.lightBlue10};
  border-radius: 4px;
  padding: 10px;

  header {
    padding-left: 8px;
  }

  footer {
    padding: 0 15px 15px;
  }
`;

export const ColumnBody = styled.div`
  ${columnStyle}
`;

export const FlexColumnBody = styled(FlexColumn)`
  height: 100%;
  ${columnStyle}
`;

const Wrapper = styled.div`
  padding: 0 7px;
  width: 25%;
`;
