import React from "react";
import {css, cx} from "@emotion/css";

import {theme} from "@reside/ui";

type Props = Readonly<{
  current: number;
  total: number;
}>;

export const ProgressBar = ({current, total}: Props) => {
  const progression = computeProgression(current, total);

  return (
    <div
      className={cx(style, {full: progression === 100})}
      style={{width: `${progression}%`}}
    />
  );
};

const computeProgression = (current: number, total: number) =>
  (current / total) * 100;

const style = css`
  position: absolute;
  top: 100%;
  height: 6px;
  background-color: ${theme.color.primary};
  display: block;
  transition: width 0.5s ease-in-out;
  border-radius: 0px 3px 3px 0px;

  &.full {
    border-radius: 0px;
  }
`;
