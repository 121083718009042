import {ComponentType, useCallback, useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {useTimeoutFn} from "react-use";
import {dispatch} from "../../store";
import {
  AUTO_LOGOUT_TIMES,
  LOGGED_OUT_DUE_TO_INACTIVITY,
  SHOULD_LOGOUT_RE,
  SHOULD_START_TIMEOUT_LOGOUT_ADMIN,
  SHOULD_START_TIMEOUT_LOGOUT_RE,
  STARTED_TIMEOUT_LOGOUT_RE,
} from "../../utils/constants/autoLogout";
import {useAutoLogoutDueToInactivity} from "../../hooks/useAutoLogoutDueToInactivity";
import {AutoLogoutWarningBar} from "../auto-logout";

/**
 * There's a rule in place: if two tabs (dashboard and RE) are open in the same browser, the app will log you out of the tab that's been out of focus for more than 90 seconds.
 *
 * When resident experience is started this function will set variables into local storage that will trigger timer for logging out Admin.
 */

export function AutoLogoutRE<OwnProps>(Component: ComponentType<OwnProps>) {
  function SyncLocalStorage(props: RouteComponentProps) {
    const logout = async () => {
      await dispatch.admissionSession.logout();
    };

    // Remove item from storage on every login so that, in case of regular signout, auto logout message is not shown.
    sessionStorage.removeItem(LOGGED_OUT_DUE_TO_INACTIVITY);

    const {showLogoutWarning, resetOnButtonClick, secondsRemaining} =
      useAutoLogoutDueToInactivity({
        logout,
      });

    const [, cancel, reset] = useTimeoutFn(async () => {
      // Let's tell the other tabs that they should logout too
      localStorage.setItem(SHOULD_LOGOUT_RE, "true");
      await logout();
    }, AUTO_LOGOUT_TIMES.RESIDENT_EXPERIENCE_OR_ADMIN_PAGE);

    const logoutAdminAndCancelLogoutRE = useCallback(() => {
      cancel();
      localStorage.setItem(SHOULD_START_TIMEOUT_LOGOUT_ADMIN, "true");

      localStorage.setItem(SHOULD_START_TIMEOUT_LOGOUT_RE, "false");
      localStorage.setItem(STARTED_TIMEOUT_LOGOUT_RE, "false");
      localStorage.setItem(SHOULD_LOGOUT_RE, "false");
    }, [cancel]);

    const onLocalStorageChange = useCallback(async () => {
      const shouldStartTimeoutLogoutRE = JSON.parse(
        localStorage.getItem(SHOULD_START_TIMEOUT_LOGOUT_RE),
      ) as boolean;
      const startedTimeoutLogoutRE = JSON.parse(
        localStorage.getItem(STARTED_TIMEOUT_LOGOUT_RE),
      ) as boolean;
      const shouldLogoutRE = JSON.parse(
        localStorage.getItem(SHOULD_LOGOUT_RE),
      ) as boolean;

      // We should start the timeout for logging out of Admin
      if (shouldStartTimeoutLogoutRE && !startedTimeoutLogoutRE) {
        // Timeout hasn't been started by another tab so we start it here
        reset();
        localStorage.setItem(STARTED_TIMEOUT_LOGOUT_RE, "true");
      }
      // Another tab reached the end of the timeout so we should logout this tab too
      if (shouldStartTimeoutLogoutRE && shouldLogoutRE) await logout();
    }, [reset]);

    useEffect(() => {
      cancel();

      logoutAdminAndCancelLogoutRE();
      window.addEventListener("storage", onLocalStorageChange);
      window.addEventListener("focus", logoutAdminAndCancelLogoutRE);

      return () => {
        window.removeEventListener("storage", onLocalStorageChange);
        window.removeEventListener("focus", logoutAdminAndCancelLogoutRE);
      };
    }, [cancel, onLocalStorageChange, logoutAdminAndCancelLogoutRE]);

    return (
      <>
        {showLogoutWarning && (
          <AutoLogoutWarningBar
            resetAutoLogout={resetOnButtonClick}
            secondsRemaining={secondsRemaining}
          />
        )}
        <Component {...(props as any)} />
      </>
    );
  }

  return SyncLocalStorage;
}
