import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import {select, AppState} from "../../store";
import {AdminLayout} from "../../atoms/admin-layout";
import {UserDetail} from "../../store/users/usersModel";
import {Facilities} from "../../models/types/AdmissionForm";
import {AdminPccActivityLogsTableContainer} from "./AdminPccActivityLogsTableContainer";
import {useFacilitySettingsParams} from "../page-admin-facility-settings/hooks/useFacilitySettingsParams";

type Props = Readonly<{
  user: UserDetail;
  facilities: Facilities;
}>;

const PageAdminActivityLogRenderer = ({facilities, user}: Props) => {
  const history = useHistory();
  const {facilityId} = useFacilitySettingsParams();

  useEffect(() => {
    if (facilityId === "_") {
      history.push("/admin/facility/_/facility-settings");
    }
  }, [facilityId, history]);

  return (
    <AdminLayout>
      <AdminPccActivityLogsTableContainer
        facilities={facilities}
        facilityId={facilityId}
      />
    </AdminLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilities: select.adminSession.facilities(state),
  user: select.adminSession.user(state),
});

export const PageAdminActivityLog = connect(mapStateToProps)(
  PageAdminActivityLogRenderer,
);
