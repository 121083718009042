import {useMutation} from "react-query";
import {useHistory} from "react-router";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useCreateEventTrigger = () => {
  const history = useHistory();
  return useMutation(
    async (body: {
      document: string;
      facilities: Array<string>;
      template_field_id: string;
      sftp_config_id: string;
      document_path: string;
    }) => {
      const response = await HubAndSpokeService.createEventTrigger(body);
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("Event trigger successfully created!");
        history.push("/admin/hub-and-spoke/event-triggers");
      },
      onError: (err: any) => {
        logErrorToSentry(err);
        Alert.error(err?.data?.detail);
      },
    },
  );
};
