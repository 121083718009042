import React, {useCallback, useMemo, useState, ReactNode} from "react";
import {Formik} from "formik";
import {Prompt} from "react-router-dom";
import {RoleDto} from "@reside/reside-api-app";
import {
  Template,
  getValidationConfig,
  extractTemplateSlides,
} from "@reside/forms";
import {
  BlockField,
  QueryVariableMap,
  BlockComponentContextProvider,
  FormikValidateAllOnSubmit,
  FormikAutoSaveValues,
  ThinScrollbar,
  ScrollToError,
} from "@reside/ui";

import {transComponents} from "../../../../atoms/block-components";

import {
  FacilitySettingsVariables,
  FieldArrayItem,
} from "../../../../services/FacilitySettingsService";
import {FormikReinitializePcc} from "../../hooks/useFormikReinitializePcc";
import {AdminRoles, NotResideAdmin} from "../../../../atoms/admin-roles";
import {FacilityPccMappingForm} from "./forms/FacilityPccMappingForm";
import {
  EditFacilityConfirmationModal,
  EditFacilityModalProvider,
} from "../../../../atoms/edit-facility-confirmation-modal";
import {EditFacilityForm} from "./forms/EditFacilityForm";
import {DownloadTemplateForm} from "./forms/DownloadTemplateForm";
import {StyledForm, StyledScrollableErrorArea} from "./styles";
import {getChecklistWithNormalizedIds} from "../../utils";

export type Props = Readonly<{
  header: ReactNode;
  template: Template;
  templateNames: string[];
  queryVariables?: QueryVariableMap;
  /**
   * Initial Form values for the template.
   */
  initialValues: FacilitySettingsVariables;
  isPccEnabled?: boolean;
  onSubmit?: (
    values: FacilitySettingsVariables,
  ) => Promise<FacilitySettingsVariables>;
}>;

export const FacilitySettingsForm = ({
  header,
  template,
  initialValues,
  onSubmit,
  queryVariables,
  isPccEnabled,
}: Props) => {
  const [values, setValues] =
    useState<FacilitySettingsVariables>(initialValues);

  const [{children: blocks}] = useMemo(
    () => extractTemplateSlides(template, values as any),
    [values, template],
  );

  const {validate, validationRules} = useMemo(
    () =>
      getValidationConfig({
        children: blocks,
        answers: values,
      }),
    [blocks, values],
  );

  // TODO(RESIDE-3488): Support relative rules instead of this
  const estimatedMaxCostForSNFABN = (
    (validationRules.estimatedMaxCostForSNFABN || []) as ReadonlyArray<string>
  ).map(rule =>
    rule === "min_value:0"
      ? `min_value:${values.estimatedCostForSNFABN}`
      : rule,
  );

  const validateForm = useCallback(
    values =>
      validate(values, {
        estimatedMaxCostForSNFABN,
        facilityName: ["required", "facility-name"],
      }),
    [validate, estimatedMaxCostForSNFABN],
  );

  return (
    <EditFacilityModalProvider>
      <EditFacilityConfirmationModal />
      <Formik<FacilitySettingsVariables>
        initialValues={values}
        validate={validateForm}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);

          const {checklist, isPccEnabled, userRole, ...rest} = values;

          const savedValues = await onSubmit({
            checklist: getChecklistWithNormalizedIds(
              checklist as ReadonlyArray<FieldArrayItem>,
            ),
            ...rest,
            isPccEnabled,
            userRole,
          });

          formik.setSubmitting(false);

          if (savedValues) {
            formik.resetForm({
              values: {...savedValues, isPccEnabled, userRole},
            });
          }
        }}
      >
        {formik => (
          <StyledForm>
            <FormikValidateAllOnSubmit />
            <FormikReinitializePcc isPccEnabled={isPccEnabled} />
            <FormikAutoSaveValues disableDebounce onChangeValues={setValues} />
            <Prompt
              when={formik.dirty}
              message="After leaving all of your unsaved changes will be lost."
            />
            <ScrollToError offsetTop={200} />
            {header}
            <ThinScrollbar>
              <StyledScrollableErrorArea>
                <BlockComponentContextProvider value={transComponents}>
                  {blocks.map(block => (
                    <BlockField
                      key={block.id}
                      block={block}
                      queryVariables={queryVariables}
                    />
                  ))}
                  <AdminRoles roles={[RoleDto.NameEnum.RESIDE_ADMIN]}>
                    <EditFacilityForm templateName={template.templateName} />
                    <FacilityPccMappingForm isPccEnabled={isPccEnabled} />
                  </AdminRoles>

                  <NotResideAdmin>
                    <DownloadTemplateForm
                      facilityTemplate={template.templateName}
                    />
                  </NotResideAdmin>
                </BlockComponentContextProvider>
              </StyledScrollableErrorArea>
            </ThinScrollbar>
          </StyledForm>
        )}
      </Formik>
    </EditFacilityModalProvider>
  );
};
