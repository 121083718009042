import React from "react";
import {Trans} from "@lingui/macro";
import {useFormikContext} from "formik";
import {styled, InfoBar} from "@reside/ui";
import {TranslationNode} from "@reside/forms";

import {useAdmissionContext} from "../AdmissionContext";
import {t} from "../../i18n-provider";

type Props = Readonly<{
  infoMessage: TranslationNode;
}>;

export const SlideInfoBar = ({infoMessage}: Props) => {
  const {errors} = useFormikContext();
  const {currentSlide} = useAdmissionContext();

  const {
    state: {complete: isSlideComplete},
    ctaButton,
  } = currentSlide;

  const isSlideFilled = Object.keys(errors).length === 0;

  return isSlideFilled && !isSlideComplete ? (
    <InfoBar
      accent="info"
      icon="info"
      title={`Please ${t(
        ctaButton.title.translationKey,
      ).toLowerCase()} the changes`}
    />
  ) : isSlideComplete ? (
    <DelayedFadeOut>
      <InfoBar accent="success" icon="check" title="Done" />
    </DelayedFadeOut>
  ) : (
    <InfoBar
      accent="info"
      icon="info"
      title={<Trans id={infoMessage.translationKey} />}
    />
  );
};

const DelayedFadeOut = styled.div`
  animation: fade-out 0.5s ease-out both;
  animation-delay: 4s;

  div {
    animation: none;
  }
`;
