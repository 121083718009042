import React from "react";
import {RouteComponentProps} from "react-router-dom";
import Alert from "react-s-alert";
import {connect} from "react-redux";
import {AxiosResponse} from "axios";
import {useQuery} from "react-query";
import {Paragraph} from "@reside/ui";

import {AppState, Dispatch, select} from "../../store";
import {
  getIsTokenSingleUse,
  getTokenSub,
  makeAuthorizationHeader,
} from "../../utils/token";
import {getRefreshedToken} from "../../services/api";
import {Banner} from "../../atoms/banner";
import {ButtonLink} from "../../atoms/button-link";
import {Spinner} from "../../atoms/spinner";
import {ADMISSION_WELCOME_ROUTE} from "../page-admission-welcome/route";
import {ADMISSION_VERIFY_ANSWERS_ROUTE} from "../page-admission-verify-answers/route";
import {withTokenSearchParam, TokenProps} from "./withTokenSearchParam";
import {enterAdmission} from "./entryApiClient";

type StateProps = Readonly<{
  activeTokenSub: string;
  hasAdmissionToken: boolean;
}>;

type DispatchProps = Readonly<{
  setToken: (token: string) => void;
  logout: () => void;
}>;

/**
 * Enter page for links containing the ADMISSION_ENTRY token.
 * The responsibility of this page is to use the token, and if succeeded set the refreshed token to store.
 * If there is existing session, we don't resume it, instead the enter procedure starts the new session.
 */
export const PageEnterAdmissionRenderer = ({
  history,
  activeTokenSub,
  hasAdmissionToken,
  setToken,
  logout,
  token,
}: RouteComponentProps & StateProps & DispatchProps & TokenProps) => {
  const {isLoading, error} = useQuery(
    "tryToken",
    async () => {
      try {
        await enterAdmission(
          {
            resident_dateOfBirth: "",
            resident_firstName: "",
            resident_lastName: "",
          } as any,
          makeAuthorizationHeader(token),
        );
      } catch ({response}) {
        // Te answers were incorrect (expected), so the token is still valid
        if (response.status === 403) {
          return response;
        }

        throw response;
      }
    },
    {
      onError: (response: AxiosResponse) => {
        /**
         * The token expired or was revoked.
         */
        if (response?.status === 401) {
          if (!activeTokenSub) {
            /**
             * When we don't have a session to resume, user can request new login link.
             */
            Alert.error(
              "Your link has expired. Please request a new login link.",
            );
          } else if (
            activeTokenSub === getTokenSub(token) &&
            hasAdmissionToken
          ) {
            /**
             * When there is active session AND it's of the same admission/email, it is resumed.
             */
            Alert.info("Your link has expired. Resuming previous session.");
          } else {
            /**
             * Failed to enter admission B, while admission A has active session -> logout.
             */
            Alert.error(
              "Your link has expired. Please request a new login link.",
            );
            logout();
          }
        } else if (response?.status === 404) {
          /**
           * This case is *unlikely* to happen, as we don't provide login links for users without admissions.
           * But still we cover the case - e.g. admission can be deleted after the email is send. (This is uncommon scenario).
           */
          Alert.info(
            "There are no admissions for you. Please request login link again.",
          );
          logout();
          history.push(ADMISSION_WELCOME_ROUTE);
        } else {
          Alert.error(
            "Can't enter admission this time. Please try again later.",
          );
          logout();
        }

        history.push(ADMISSION_WELCOME_ROUTE);
      },
      onSuccess: (response: AxiosResponse) => {
        if (getIsTokenSingleUse(token)) {
          // we set the refreshed token to store
          const {headers} = response;
          setToken(getRefreshedToken(headers));
        } else {
          // we set the 7day token to store
          setToken(token);
        }
        history.push(ADMISSION_VERIFY_ANSWERS_ROUTE);
      },
    },
  );

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Banner title="Unable to list admissions.">
      <Paragraph>Something went wrong. We have recorded the error.</Paragraph>
      <ButtonLink
        to="/"
        onClick={() => {
          logout();
        }}
      >
        Request login link
      </ButtonLink>
    </Banner>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  activeTokenSub: select.admissionSession.getTokenSub(state),
  hasAdmissionToken: select.admissionSession.hasAdmissionToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setToken: dispatch.admissionSession.setToken,
  logout: dispatch.admissionSession.logout,
});

export const PageEnterAdmission = withTokenSearchParam(
  connect(mapStateToProps, mapDispatchToProps)(PageEnterAdmissionRenderer),
);
