import React, {memo} from "react";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsFlagsAgeChart = memo(({data}: ChartProps) => (
  <LineChart data={lineGraphData(data)} yFormat={y => `${y}`} />
));

export const FLAGS_AGE_CHART_ID = "flags-age" as const;

export const AnalyticsFlagsAge = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.AGE_OF_HELP_FLAGS}
    onChildRendered={props.onChildRendered}
  >
    <div className="chart-header">
      <CardTitle title={"Age of the “help flags”"} subtitle="by Facility" />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.flagsAge,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsFlagsAgeChart data={data} isFullReport={isFullReport} />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
