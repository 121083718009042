import React, {ReactNode, FunctionComponent} from "react";
import {useParams} from "react-router-dom";
import {styled} from "@reside/ui";

import {UseScrollSpy} from "../../../../hooks/useScrollSpy";
import {
  reportingMenuGeneral,
  reportingMenuOther,
} from "../../../../pages/page-reporting/constants";
import {TemporaryHideChart} from "../../../../pages/page-reporting/TemporaryHideChart";
import {UrlProps} from "../../../reporting-block";
import {ReportingRow} from "../reporting-row";

const menuSections = [...reportingMenuGeneral, ...reportingMenuOther];

type Props = Readonly<{
  id: string;
  title?: ReactNode;
  children: ReactNode;
}>;

export const ReportingGroupRenderer = ({id, title, children}: Props) => (
  <UseScrollSpy id={id}>
    {({scrollSpyRef}) => (
      <div ref={scrollSpyRef}>
        <Group>
          {title && <Title id={id}>{title}</Title>}
          <ReportingRow>{children}</ReportingRow>
        </Group>
      </div>
    )}
  </UseScrollSpy>
);

export const ReportingGroup: FunctionComponent<Props> = ({
  id,
  title,
  children,
}) => {
  const {chartId: isFullReport} = useParams<UrlProps>();

  return (
    <TemporaryHideChart
      chartIds={
        menuSections.find(({urlComponent}) => urlComponent === id).sectionItems
      }
    >
      <ReportingGroupRenderer title={isFullReport ? undefined : title} id={id}>
        {children}
      </ReportingGroupRenderer>
    </TemporaryHideChart>
  );
};

const Group = styled.div`
  & + & {
    margin-top: 64px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  padding: 24px 0;
`;
