import React from "react";
import {DropdownMenuLink as DropdownMenuLinkBase, styled} from "@reside/ui";

import {getResidentTestId} from "../../helper";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";

export const ViewAdmissionDropdownItem = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => (
  <DropdownMenuLink
    to={`/admin/admissions/${admission.id}`}
    target="_blank"
    testId={getResidentTestId(admission, "view")}
  >
    View Admission
  </DropdownMenuLink>
);

const DropdownMenuLink = styled(DropdownMenuLinkBase)`
  &:hover {
    opacity: 1;
    background-color: ${({theme}) => theme.color.lightBlue10};
  }
`;
