var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
export var Navigation = function (_a) {
    var children = _a.children;
    return (React.createElement(Wrapper, null,
        React.createElement(ContentContainer, null, children)));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 998;\n  top: 0;\n  width: 100%;\n  height: 70px;\n  background-color: ", ";\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);\n"], ["\n  position: fixed;\n  z-index: 998;\n  top: 0;\n  width: 100%;\n  height: 70px;\n  background-color: ", ";\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.white;
});
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  max-width: Min(1920px, 100vw - 4rem);\n  align-items: center;\n  justify-content: space-between;\n  height: 70px;\n  margin: 0 auto;\n"], ["\n  display: flex;\n  max-width: Min(1920px, 100vw - 4rem);\n  align-items: center;\n  justify-content: space-between;\n  height: 70px;\n  margin: 0 auto;\n"])));
var templateObject_1, templateObject_2;
