import React from "react";
import {ReportsDataPath} from "../../../store/reporting-reports/reporting-reports.common";
import {IFromTo} from "../../../store/reporting/reporting.common";

export const withCompareCompute: (
  dataPath: ReportsDataPath,
  fromTo?: IFromTo,
) => ReportsDataPath[] = (dataPath, fromTo) => {
  if (fromTo) {
    const dataPath2 = JSON.parse(JSON.stringify(dataPath));

    dataPath2.query.from = fromTo.from;
    dataPath2.query.to = fromTo.to;

    return [dataPath, dataPath2];
  }

  return [dataPath];
};
export const withCompare: (
  dataPath: ReportsDataPath,
  fromTo?: IFromTo,
) => ReportsDataPath[] = (dataPath, fromTo) => {
  dataPath = {
    ...dataPath,
    query: {
      from: dataPath.query.from,
      to: dataPath.query.to,
      sortDirection: dataPath.query.sortDirection,
      sortField: dataPath.query.sortField,
      facilities: dataPath.query.facilities,
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return React.useMemo(
    () => withCompareCompute(dataPath, fromTo),
    [dataPath, fromTo],
  );
};
