import React from "react";
import {Icon, Text, styled, theme} from "@reside/ui";
import {Admission} from "@reside/reside-api-admission";

import {
  readFacilityNameAnswer,
  readFacilityOrganizationNameAnswer,
  readResidentNameAnswer,
} from "../../models/AdmissionModel.helpers";
import {AdmissionStatusEnum} from "../../services/AdmissionsService";
import {AdmissionStatusTag} from "../admission-status-tag";

const {color} = theme;

export const AdmissionStatusCell = ({status}: {status: AdmissionStatusEnum}) =>
  status ? (
    <AdmissionStatusTag status={status} />
  ) : (
    <Text color="darkBlue70">NOT STARTED</Text>
  );

export const ResidentCell = ({
  admission,
  showResidentFullName = true,
}: {
  admission: Admission;
  showResidentFullName?: boolean;
}) => {
  const residentName = readResidentNameAnswer(admission);

  return (
    <Wrapper
      data-test-id={"admission-" + (admission.answers ? residentName : "")}
    >
      {showResidentFullName && (
        <>
          <Text color="darkBlue100" fontSize={3} fontWeight="bold">
            {residentName}
          </Text>
          <br />
        </>
      )}
      <HouseIcon size={14} color={color.gray100} name="house" />
      <Text color="gray100">{readFacilityNameAnswer(admission)}</Text>
      <br />
      <UserIcon size={14} color={color.gray100} name="user" />
      <Text color="gray100">
        {readFacilityOrganizationNameAnswer(admission)}
      </Text>
    </Wrapper>
  );
};

const HouseIcon = styled(Icon)`
  margin-right: 6px;
`;

const UserIcon = styled(Icon)`
  margin-right: 6px;
`;

const Wrapper = styled.div`
  line-height: 20px;
`;
