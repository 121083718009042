import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {css} from "@emotion/css";
import {RoleDto, TableUserDto} from "@reside/reside-api-app";
import {styled, Modal, Box, Button, H2, Text} from "@reside/ui";
import {AppState, select} from "../../../../store";
import {AdminUsersSearch} from "../../../../atoms/admin-search";
import {BackdropSpinner} from "../../../../atoms/spinner";
import {Pagination} from "../../../../atoms/pagination";
import {useScrollTopEffect} from "../../../../hooks/useScrollTopEffect";
import {ListTableUserPermissions} from "../list-table-permissions";
import {PermissionManagementForm} from "./PermissionManagementForm";
import {useUserPermissionsMutation} from "./hooks/useUserPermissionsMutation";
import {usePaginatedUserPermissionsQuery} from "./hooks/usePaginatedUserPermissionsQuery";
import {usePermissionManagementModalContext} from "./PermissionManagementModalContext";

type TableHeaderProps = {
  onUserSelect: (user: any) => void;
};

const TableHeader = ({onUserSelect}: TableHeaderProps) => (
  <Box flexDirection="row" alignItems="center" width="100%" padding="30px">
    <Text fontWeight="bold" className={s.modalTitle}>
      Permission management
    </Text>
    <div className={s.searchBox}>
      <AdminUsersSearch onSelect={onUserSelect} />
    </div>
  </Box>
);

type FormHeaderProps = {
  isSaving: boolean;
  onBack: () => void;
  user: TableUserDto;
};

const FormHeader = ({user, onBack, isSaving}: FormHeaderProps) => {
  const [statusText, setStatusText] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    clearTimeout(timer);
    if (!user) {
      setIsFirst(true);

      return;
    }
    if (isFirst) {
      setIsFirst(false);

      return;
    }

    if (isSaving) {
      setStatusText("Saving...");
    } else {
      setStatusText("Saved");
      const timer = window.setTimeout(() => setStatusText(""), 3000);

      setTimer(timer);
    }
    //This should run only when isSaving changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  return (
    <Box
      flexDirection="row"
      width="90%"
      padding="30px"
      justifyContent="space-between"
    >
      <Box flexDirection="row" alignItems="center">
        <Button
          className={s.backButton}
          outline
          color="primary"
          icon="arrow-left"
          size="small"
          onClick={onBack}
        >
          Back to list
        </Button>
        <PermissionFormTitle fontWeight="bold">{`${user.firstName} ${
          user.lastName
        } | ${user.facilities
          .map(x => x.name)
          .join(", ")}`}</PermissionFormTitle>
      </Box>
      <Box
        flexDirection="row"
        width="150px"
        alignItems="center"
        justifyContent="end"
      >
        <Text
          fontWeight="bold"
          className={s.modalTitle}
          color={isSaving ? "darkBlue100" : "green100"}
        >
          {statusText}
        </Text>
      </Box>
    </Box>
  );
};

type StateProps = {
  roles: RoleDto.NameEnum[];
};

type Props = StateProps;

const PermissionManagementModalRenderer = ({roles}: Props) => {
  const {isOpen, toggleModal} = usePermissionManagementModalContext();

  const {
    page,
    setPage,
    setSort,
    query: {refetch, isLoading, isFetching, error, data},
  } = usePaginatedUserPermissionsQuery({roles});

  const [user, setUser] = useState<TableUserDto | undefined>(undefined);

  const {activePermissions, isSaving, isSuccess, onSetPermission} =
    useUserPermissionsMutation(user);

  useScrollTopEffect("#permissionsModal .ReactModal__InnerContent", [
    user,
    data?.content,
  ]);

  const users = data?.content ?? [];

  return (
    <Modal
      id="permissionsModal"
      size="large"
      closeButtonPosition="right"
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      topControls={
        user ? (
          <FormHeader
            isSaving={isSaving}
            onBack={() => {
              setUser(undefined);
              refetch();
            }}
            user={user}
          />
        ) : (
          <TableHeader onUserSelect={user => setUser(user)} />
        )
      }
      onRequestClose={toggleModal}
    >
      {({Content, Footer}) =>
        error ? (
          <div className={s.error}>
            <H2>Error while fetching users</H2>
            <Button color="primary" onClick={() => refetch()}>
              Retry
            </Button>
          </div>
        ) : (
          <>
            <Content>
              {user ? (
                <PermissionManagementForm
                  userId={user.id}
                  activePermissions={activePermissions}
                  permissionsPersisted={isSuccess && !isSaving}
                  onSetPermission={onSetPermission}
                />
              ) : (
                <BackdropSpinner active={isLoading || isFetching}>
                  {data ? (
                    <ListTableUserPermissions
                      height={200}
                      users={users}
                      onRowClick={(id, row) => setUser(row.rowData)}
                      onSort={(properties, direction) =>
                        setSort({properties, direction})
                      }
                    />
                  ) : (
                    <LoadingPlaceholder />
                  )}
                </BackdropSpinner>
              )}
            </Content>

            {!user && (
              <Footer>
                {data && (
                  <Pagination
                    current={page + 1}
                    pageSize={data.size}
                    total={data.totalElements}
                    onChange={current => setPage(current - 1)}
                  />
                )}
              </Footer>
            )}
          </>
        )
      }
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  roles: select.adminSession.visibleRoles(state),
});

export const PermissionManagementModal = connect(mapStateToProps)(
  PermissionManagementModalRenderer,
);

const s = {
  spinnerContainer: css`
    position: relative;
  `,
  searchBox: css`
    width: 360px;
  `,
  backButton: css`
    margin-right: 12px;
  `,
  modalTitle: css`
    line-height: 19px;
    font-size: 17px;
    margin: 0 80px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  error: css`
    text-align: center;
    padding: 100px;

    button {
      margin: 40px auto;
    }
  `,
};

const PermissionFormTitle = styled(Text)`
  line-height: 19px;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 550px;
`;

const LoadingPlaceholder = styled.div`
  height: 300px;
`;
