import React, {memo} from "react";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsReAdmissionRateTimeSeriesChart = memo(({data}: ChartProps) => (
  <LineChart data={lineGraphData(data)} />
));

export const RE_ADMISSION_RATE_CHART_ID = "re-admission-rate" as const;

export const AnalyticsReAdmissionRateTimeSeries = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.READMISSION_RATE}
  >
    <div className="chart-header">
      <CardTitle title="Resident Admission History" subtitle="by Admissions" />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.readmissionRate,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsReAdmissionRateTimeSeriesChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
