import React from "react";
import {useMutation} from "react-query";
import {z} from "zod";
import {Formik, Form} from "formik";
import {errorUtil} from "zod/lib/helpers/errorUtil";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {messages} from "@reside/forms";

import {
  Button,
  PublicFormLayout,
  TextField,
  Paragraph,
  styled,
} from "@reside/ui";
import {CreateResetPasswordDto} from "@reside/reside-api-app";

import {UserService} from "../../services/UserService";
import {AsyncRequestError} from "../../atoms/error";
import {ButtonLink} from "../../atoms/button-link";

const Schema = z.object({
  email: z.string().email(messages.email as errorUtil.ErrMessage),
});

export const PagePasswordReset = () => {
  const {mutate, isLoading, isSuccess, error} = useMutation(
    UserService.resetPassword,
  );

  return (
    <Formik<CreateResetPasswordDto>
      initialValues={{email: ""}}
      onSubmit={values => mutate(values)}
      validationSchema={toFormikValidationSchema(Schema)}
    >
      {({submitForm}) => (
        <PublicFormLayout
          title="Reset Password"
          footer={
            <Center>
              <ButtonLink
                to="/admin"
                underline
                style={{border: "none", background: "transparent"}}
              >
                Return to Log In
              </ButtonLink>
            </Center>
          }
        >
          {isSuccess ? (
            <Center>
              <Paragraph fontWeight="medium" fontSize={3}>
                Password reset link was sent to your email address.
              </Paragraph>
            </Center>
          ) : (
            <Form>
              <TextField
                name="email"
                type="email"
                label="E-mail address"
                placeholder="Enter your E-mail Address"
              />
              <AsyncRequestError error={error} />
              <ButtonCenter>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  {isLoading ? <>Sending a link...</> : <>Send a link</>}
                </Button>
              </ButtonCenter>
            </Form>
          )}
        </PublicFormLayout>
      )}
    </Formik>
  );
};

const ButtonCenter = styled.div`
  width: fit-content;
  margin: 18px auto 0;
`;

const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`;
