import {useCallback, useState} from "react";
import {connect} from "react-redux";
import {Text} from "@reside/ui";
import {format} from "date-fns";

import {Spinner} from "../../../atoms/spinner";
import {ListTable, Column} from "../../../atoms/list-table";
import {
  TextCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";

import {SortDirection} from "../../../store/reporting-reports/reporting-reports.common";
import {AppState, select} from "../../../store";

import {ImportExportHistory, RendererProps, RowDataProps} from "../utils/types";
import {saveExcel} from "../../../services/FileService";
import {useGetTableData} from "../hooks/useGetTableData";
import {TextCellStyle, AvailableButton, CapitalizedTextTag} from "./styles";
import {FILE_TYPES, FILE_ACTIONS} from "../utils/constants";

/**
 * Table with history of file imports/exports
 */

export const Renderer = ({organizationId}: RendererProps) => {
  const {ORIGINAL, PROCESSED} = FILE_TYPES;
  const {USER_IMPORT} = FILE_ACTIONS;

  const {data: tableData = [], isFetching} = useGetTableData({
    organizationId,
  });

  const [sortBy, setSortBy] = useState<string>("created");
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);

  const onSort = useCallback((sortBy: string, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection || SortDirection.NONE);
  }, []);

  const getRowId = useCallback((row: RowDataProps) => row.rowData.id, []);

  const filenameCellRenderer = useCallback(
    ({rowData}: RowDataProps) => (
      <TextCellEllipsis>{rowData.filename}</TextCellEllipsis>
    ),
    [],
  );

  const createdCellRenderer = useCallback(({rowData}: RowDataProps) => {
    return (
      <TextCellStyle>
        {format(new Date(rowData.created), "yyyy-MM-dd HH:mm")}
      </TextCellStyle>
    );
  }, []);

  const actionCellRenderer = useCallback(
    ({rowData}: RowDataProps) => {
      return (
        <TextCell>
          {rowData.action === USER_IMPORT ? "Import" : "Export"}
        </TextCell>
      );
    },
    [USER_IMPORT],
  );

  const usernameCellRenderer = useCallback(
    ({rowData}: RowDataProps) => <TextCell>{rowData.username}</TextCell>,
    [],
  );

  const statusCellRenderer = useCallback(({rowData}: RowDataProps) => {
    const status = rowData.status.toLowerCase();

    return (
      <CapitalizedTextTag
        color={
          status === "succeeded"
            ? "green100"
            : status === "failed"
            ? "pink100"
            : "yellow100"
        }
      >
        {status}
      </CapitalizedTextTag>
    );
  }, []);

  const saveCSVCallback = useCallback(
    (fileType: string, rowData: ImportExportHistory) => async () => {
      if (fileType === ORIGINAL && rowData.originalFileId) {
        await saveExcel(rowData.originalFileId, rowData.filename);
      }

      if (fileType === PROCESSED && rowData.processedFileId) {
        await saveExcel(rowData.processedFileId, rowData.filename);
      }
    },
    [ORIGINAL, PROCESSED],
  );

  const downloadFileCellRenderer = useCallback(
    ({rowData}: RowDataProps) => (
      <AvailableButton
        available={rowData.originalFileId}
        type="link"
        onClick={saveCSVCallback(ORIGINAL, rowData)}
      >
        <Text>Download Input File</Text>
      </AvailableButton>
    ),
    [saveCSVCallback, ORIGINAL],
  );

  const downloadSummaryCellRenderer = useCallback(
    ({rowData}: RowDataProps) => (
      <AvailableButton
        available={rowData.processedFileId}
        type="link"
        onClick={saveCSVCallback(PROCESSED, rowData)}
      >
        <Text>Download Output File</Text>
      </AvailableButton>
    ),
    [saveCSVCallback, PROCESSED],
  );

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <ListTable
      key={organizationId}
      // : sort data
      data={tableData}
      sortBy={sortBy}
      sortDirection={sortDirection}
      sortPredicates={{
        filename: ["filename"],
        created: ["created"],
        username: ["username"],
        status: ["status"],
        action: ["action"],
      }}
      // : events
      onSort={onSort}
      getRowId={getRowId}
      // : styling
      noRowsMessage="No organization import"
      showDoubleArrowOnNotSorted
    >
      <Column
        cellRenderer={filenameCellRenderer}
        label="File"
        sortKey="filename"
        width={200}
      />
      <Column
        cellRenderer={createdCellRenderer}
        label="Transfer Date"
        sortKey="created"
        width={150}
      />

      <Column
        cellRenderer={actionCellRenderer}
        label="Action"
        sortKey="action"
        width={75}
      />

      <Column
        cellRenderer={usernameCellRenderer}
        label="Updated By"
        sortKey="username"
        width={80}
      />
      <Column
        cellRenderer={statusCellRenderer}
        label="Status"
        sortKey="status"
        width={100}
      />
      <Column
        cellRenderer={downloadFileCellRenderer}
        width={100}
        title="Download File"
      />
      <Column
        cellRenderer={downloadSummaryCellRenderer}
        width={100}
        title="Download Summary"
      />
    </ListTable>
  );
};

/**
 * @param state
 */
const mapState = (state: AppState) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
});

export const AdminOrganizationImportExportUsersTable =
  connect(mapState)(Renderer);
