import {useQuery} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {featureFlagsApi} from "../../../services/FeatureFlagsService";

export const useFetchFeatureFlagsByOrganizations = () =>
  useQuery("useFetchFeatureFlagsByOrganizations", async () => {
    const {data} = await featureFlagsApi.getOrganizationFeatureFlagsUsingGET(
      getAuthorizationHeader(),
    );
    return data;
  });
