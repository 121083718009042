"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = exports.RESIDE_DOMAIN = void 0;
exports.RESIDE_DOMAIN = "resideadmissions.com";
exports.Validator = {
    name: "whitelist-domain-name",
    errorMessage: "User email domain does not belong to the organization approved domains list.",
    validate: function (value, requirements) {
        var userEmailDomain = value
            .substring(value.indexOf("@") + 1)
            .toLowerCase();
        var requiredDomainNames = requirements
            .split(",")
            .map(function (d) { return d.trim().toLowerCase(); });
        return requirements
            ? userEmailDomain === exports.RESIDE_DOMAIN ||
                requiredDomainNames.includes(userEmailDomain)
            : true;
    },
};
