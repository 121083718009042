var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FlexColumn } from "../../box";
import { FloatingFieldLabel } from "../atoms";
import { BaseInput } from "../atoms/TextualInput";
import { styled } from "../../theme";
export var MainLabel = styled(FloatingFieldLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: ", "px 0;\n"], ["\n  margin: ", "px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space[4];
});
export var Column = styled(FlexColumn)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", "px ", "px;\n"], ["\n  padding: ", "px ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space[1];
}, function (_a) {
    var theme = _a.theme;
    return theme.space[2];
});
export var Input = styled(BaseInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background: ", ";\n  min-width: 3rem;\n  max-width: 4rem;\n  :focus {\n    border: 1px solid ", ";\n  }\n"], ["\n  border: 1px solid ", ";\n  background: ", ";\n  min-width: 3rem;\n  max-width: 4rem;\n  :focus {\n    border: 1px solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.borderGray;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
export var YearInput = styled(BaseInput)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background: ", ";\n  min-width: 5rem;\n  max-width: 7rem;\n  :focus {\n    border: 1px solid ", ";\n  }\n"], ["\n  border: 1px solid ", ";\n  background: ", ";\n  min-width: 5rem;\n  max-width: 7rem;\n  :focus {\n    border: 1px solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.borderGray;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
/** We validate manually each separate input box. This styles that error message. */
export var SeparateFieldError = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.25em;\n  padding: 10px 5px;\n  font-weight: ", ";\n  text-align: left;\n"], ["\n  color: ", ";\n  font-size: 1.25em;\n  padding: 10px 5px;\n  font-weight: ", ";\n  text-align: left;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.deepOrange100;
}, function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
