import {history} from "../utils/history";
import {SlideBlock, UIState} from "../atoms/admission-explorer/model";

export const navigateToPageIndex = (pageIndex = 0, validate = false) => {
  if (!history.location.pathname.startsWith("/admin")) {
    const search = new URLSearchParams(history.location.search);

    search.set("page", `${pageIndex}`);

    if (validate) {
      search.set("validate", `${validate}`);
    }

    history.push({...history.location, search: search.toString()});
  }
};

export const getPageIndexFromUrl = () => {
  const search = new URLSearchParams(history.location.search);
  const pageIndex = parseInt(search.get("page"), 10);

  return isNaN(pageIndex) ? 0 : pageIndex;
};

export function findNextVisibleSlideIndex(
  uiState: UIState,
  slides: ReadonlyArray<SlideBlock>,
) {
  const index = slides.findIndex((slide, idx) => {
    if (idx <= uiState.slideIdx) {
      return false;
    }

    if (uiState.showIncompleteSlidesOnly && slide.state.visible) {
      return slide.state.complete === false || slide.state.flag !== null;
    }

    return slide.state.visible;
  });

  return Math.max(uiState.slideIdx, index);
}

export function findPreviousVisibleSlideIndex(
  uiState: UIState,
  slides: ReadonlyArray<SlideBlock>,
) {
  const currentIndex = uiState.slideIdx;
  const distanceToPreviousVisibleIndex = slides
    .filter((p, idx) => idx < currentIndex)
    .reverse()
    .findIndex(slide => {
      return uiState.showIncompleteSlidesOnly
        ? (slide.state.complete === false || slide.state.flag !== null) &&
            slide.state.visible
        : slide.state.visible;
    });
  const index = currentIndex - distanceToPreviousVisibleIndex - 1;

  return Math.max(0, index);
}
