import update from "immutability-helper";
import {combineReducers, Reducer} from "redux";
import {
  StateWithUserId,
  UserPermissionsAction,
  UserPermissionsStatePartial,
  ALLOWED_ACTIONS,
  FETCH_BEGIN,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UsersByPermissionsStatePartial,
  UserPermissionsByTypeAction,
  FETCH_BY_PERMISSION_BEGIN,
  FETCH_BY_PERMISSION_SUCCESS,
  FETCH_BY_PERMISSION_FAILURE,
  GRANT_PERMISSION,
  REVOKE_PERMISSION,
  RESET_PERMISSION,
} from "./user-permissions.common";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterActions: <S>(
  baseReducer: Reducer<S, UserPermissionsAction>,
) => Reducer<S | undefined, UserPermissionsAction> =
  baseReducer => (state, action) => {
    if (ALLOWED_ACTIONS.includes(action.type)) {
      return baseReducer(state, action);
    }

    return state;
  };

const withUserId: <S>(
  baseReducer: Reducer<S, UserPermissionsAction>,
) => Reducer<StateWithUserId<S>, UserPermissionsAction> =
  baseReducer =>
  (state = {}, action) => {
    // toto celkovo ma problem, ze vola baseReducer na divnych miestach
    // (eg. ak by bola zavolana action {type: 'nieco', payload: {userId: 123}}
    // tak ten normalne vracia state ale ak ten nie je spravny ...)
    // nieco ako filterActions vyssie by to mohlo vyriesit
    if (action.payload?.userId) {
      const s = action.payload.userId;
      const selectedState = state[s];

      return update(state, {[s]: {$set: baseReducer(selectedState, action)}});
    } else if (action.type === RESET_PERMISSION) {
      return {};
    } else {
      return state;
    }
  };

const INITIAL_STATE: UserPermissionsStatePartial = {permissions: []};
const userPermissionsReducer: Reducer<
  UserPermissionsStatePartial,
  UserPermissionsAction
> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BEGIN:
      return update(state, {$merge: {fetching: true}});
    case FETCH_SUCCESS:
      return update(state, {
        $set: {
          fetching: false,
          success: true,
          permissions: action.payload.permissions,
        },
      });
    case FETCH_FAILURE:
      return update(state, {$set: {fetching: false, success: false}});
    case GRANT_PERMISSION:
      return update(state, {
        permissions: {
          $apply: (permissions: UserPermissionsStatePartial["permissions"]) => {
            return [...permissions, action.payload.permission];
          },
        },
      });
    case REVOKE_PERMISSION:
      return update(state, {
        permissions: {
          $apply: (permissions: UserPermissionsStatePartial["permissions"]) => {
            if (permissions) {
              return permissions.filter(p => p !== action.payload.permission);
            } else {
              return [];
            }
          },
        },
      });
    default:
      return state;
  }
};

const usersByPermission: Reducer<
  UsersByPermissionsStatePartial,
  UserPermissionsByTypeAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BY_PERMISSION_BEGIN:
      return update(state, {$set: {fetching: true}});
    case FETCH_BY_PERMISSION_SUCCESS:
      return update(state, {
        $set: {fetching: false, success: true, byName: action.payload},
      });
    case FETCH_BY_PERMISSION_FAILURE:
      return update(state, {$set: {fetching: false, success: false}});
    default:
      return state;
  }
};

const rootUserPermissionsReducerMap = {
  users: withUserId(userPermissionsReducer),
  usersByPermission,
};

export const rootUserPermissionsReducer = combineReducers(
  rootUserPermissionsReducerMap,
);
