import {IntegrationsServiceApi} from "@reside/reside-api-integrations";
import portableFetch from "portable-fetch";

import {BASE_PATH} from "../config";
import {http} from "./api";
import {getAuthorizationHeader} from "../models/admin-session/selectors";

export type QuicksightDashboard = Readonly<{
  id: string;
  name: string;
}>;

export const QuicksightService = {
  getDashboard: (dashboardId: string) =>
    integrationsApi.integrationsServiceGetDashboard(dashboardId, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  putPermissions: (
    userId: string,
    data: {dashboardId: string; checked: boolean},
  ) => http.post(`/integrations/quicksight/users/${userId}/dashboards`, data),

  getAdminDashboards: () =>
    integrationsApi.integrationsServiceGetAdminDashboards({
      headers: {Authorization: getAuthorizationHeader()},
    }),

  getUserDashboards: (userId: string) =>
    integrationsApi.integrationsServiceGetDashboardsUser(userId, {
      headers: {Authorization: getAuthorizationHeader()},
    }),

  getCurrentUserDashboards: () =>
    integrationsApi.integrationsServiceGetDashboards({
      headers: {Authorization: getAuthorizationHeader()},
    }),

  clearCache: () =>
    integrationsApi.integrationsServiceDeleteQuickSightCache({
      headers: {Authorization: getAuthorizationHeader()},
    }),
};

export const integrationsApi = new IntegrationsServiceApi(
  undefined,
  BASE_PATH,
  portableFetch,
);
