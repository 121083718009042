import React, {FunctionComponent} from "react";
import {Form} from "formik";
import {UserDto} from "@reside/reside-api-app";
import {Text, styled, FlexRow} from "@reside/ui";

import {AdminLayout} from "../../../atoms/admin-layout";
import {AdminPageTitle} from "../../../atoms/admin-layout/AdminPageTitle";
import {Spinner} from "../../../atoms/spinner";
import {SubmitButtons} from "./submit-buttons";
import {SidebarMenu} from "./SidebarMenu";

export const PageAdminProfileLayout: FunctionComponent<{
  user: UserDto;
  isLoading?: boolean;
}> = ({user, children, isLoading}) => (
  <AdminLayout>
    {!user && !!isLoading ? (
      <Spinner />
    ) : user ? (
      children
    ) : (
      <NoUserFound>
        <Text>No user found with these parameters...</Text>
      </NoUserFound>
    )}
  </AdminLayout>
);

export const ProfileFormLayout: FunctionComponent<{user: UserDto}> = ({
  user,
  children,
}) => (
  <Form>
    <Header>
      <AdminPageTitle>{`${user.firstName} ${user.lastName}`}</AdminPageTitle>
      <SubmitButtons />
    </Header>
    <FlexRow>
      <SidebarMenu user={user} />
      <FormWrapper>{children}</FormWrapper>
    </FlexRow>
  </Form>
);

const Header = styled(FlexRow)`
  margin-bottom: 15px;
  align-items: center;
`;

const FormWrapper = styled.div`
  flex: 3;
`;

const NoUserFound = styled.div`
  margin: 20px;
`;
