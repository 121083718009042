import {
  PageableDto,
  PageOfAdmission,
  Admission,
} from "@reside/reside-api-admission";
import {useInfiniteQuery, useQueryClient} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {admissionsApi} from "../../../services/AdmissionsService";
import {logErrorToSentry} from "../../../utils/sentry";

const ADMISSIONS_BY_STATUS_QUERY_KEY = "admissionsByStatus";

type Params = {
  facilityIds: ReadonlyArray<string>;
  status?: Admission.StatusEnum;
  sortBy?: string;
  sortDirection?: PageableDto.DirectionEnum;
  isAllowedToSeeAdmissions?: boolean;
};

const defaultSortParams = {
  sortBy: "updated",
  sortDirection: PageableDto.DirectionEnum.DESC,
};

export const useAdmissionsByStatusQuery = ({
  facilityIds,
  status,
  sortBy = defaultSortParams.sortBy,
  sortDirection = defaultSortParams.sortDirection,
  pageSize = 10,
  isAllowedToSeeAdmissions,
}: Params & {
  pageSize?: number;
}) =>
  useInfiniteQuery(
    [
      ADMISSIONS_BY_STATUS_QUERY_KEY,
      {facilityIds, status, sortBy, sortDirection},
    ],
    async ({pageParam = 0}) => {
      const {data} = await admissionsApi.searchAdmissionUsingPOST(
        {
          facilityIds,
          statuses: status ? [status as any] : undefined,
          pageable: {
            page: pageParam,
            size: pageSize,
            properties: [sortBy],
            direction: sortDirection,
          },
        },
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      getNextPageParam: ({content, totalPages}, allPages) => {
        const page = allPages.length;
        const isLastPage = totalPages === page;

        return content.length === pageSize && !isLastPage ? page : false;
      },
      cacheTime: 0,
      refetchOnMount: false,
      onError: async (err: Response) => {
        logErrorToSentry(err, {
          queryKey: ADMISSIONS_BY_STATUS_QUERY_KEY,
          facilityIds,
          status,
          sortBy,
          sortDirection,
        });
      },
      enabled: isAllowedToSeeAdmissions,
    },
  );

export const useAdmissionsByStatusQueryUtils = () => {
  const cache = useQueryClient();

  return {
    refetchAdmissionsByStatusQuery: (params: Params) =>
      cache.refetchQueries([
        ADMISSIONS_BY_STATUS_QUERY_KEY,
        {...defaultSortParams, ...params},
      ]),
    getAdmissionsByStatusQueryData: (params: Params) =>
      cache.getQueryData<{pages: PageOfAdmission[]}>([
        ADMISSIONS_BY_STATUS_QUERY_KEY,
        {...defaultSortParams, ...params},
      ]),
    setAdmissionsByStatusQueryData: (params: Params, updater: any) =>
      cache.setQueryData(
        [ADMISSIONS_BY_STATUS_QUERY_KEY, {...defaultSortParams, ...params}],
        updater,
      ),
  };
};
