import React from "react";
import {Button, ButtonProps} from "@reside/ui";
import {styled} from "@reside/ui";

type Props = Readonly<{
  hasMorePages: boolean;
}> &
  ButtonProps;

export const LoadMoreButton = ({hasMorePages, ...props}: Props) =>
  hasMorePages ? (
    <LoadMoreButtonWrapper>
      <Button size="small" color="primary" {...props}>
        Load More
      </Button>
    </LoadMoreButtonWrapper>
  ) : null;

const LoadMoreButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;
