var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
export var Logo = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b;
    return (React.createElement(LogoImage, { className: "image-reside-logo ".concat(className), width: "149", height: "96", alt: "RESIDE" }));
};
var LogoImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n"], ["\n  display: block;\n  margin: 0 auto;\n"])));
var templateObject_1;
