import React from "react";
import {Switch} from "react-router-dom";
import {SentryRoute as Route} from "../utils/sentryRoute";

import {PageWelcome} from "../pages/page-admission-welcome";
import {PageEnterAdmission} from "../pages/page-admission-enter";
import {PageAdmissionVerifyAnswers} from "../pages/page-admission-verify-answers";
import {PageAdmissionPrelude} from "../pages/page-admission-prelude";
import {PageAdmissionExperience} from "../pages/page-admission-experience";
import {PageExpiredSession} from "../pages/page-admission-expired-session";

import {ADMISSION_WELCOME_ROUTE} from "../pages/page-admission-welcome/route";
import {ADMISSION_ENTER_ROUTE} from "../pages/page-admission-enter/route";
import {ADMISSION_VERIFY_ANSWERS_ROUTE} from "../pages/page-admission-verify-answers/route";
import {ADMISSION_PRELUDE_ROUTE} from "../pages/page-admission-prelude/route";
import {ADMISSION_EXPERIENCE_ROUTE} from "../pages/page-admission-experience/route";
import {ADMISSION_EXPIRED_SESSION_ROUTE} from "../pages/page-admission-expired-session/route";

import {UserIsLoggedOut} from "../models/admission-session/UserIsLoggedOut";
import {UserHasAdmissionEntryToken} from "../models/admission-session/UserHasAdmissionEntryToken";
import {UserHasAdmissionToken} from "../models/admission-session/UserHasAdmissionToken";
import {Page404} from "../pages/page-404";
import {TokenIsNotExpired} from "../models/admission-session/TokenIsNotExpired";
import {AdmissionApiClientConfiguration} from "./useApiClientConfiguration";
import {AutoLogoutRE} from "../atoms/auto-logout-re";

export const AdmissionRoutes = () => (
  <>
    <AdmissionApiClientConfiguration />
    <Switch>
      <Route
        path={ADMISSION_WELCOME_ROUTE}
        component={UserIsLoggedOut(PageWelcome)}
      />
      <Route
        path={ADMISSION_EXPIRED_SESSION_ROUTE}
        component={PageExpiredSession}
      />
      <Route
        path={ADMISSION_ENTER_ROUTE}
        component={AutoLogoutRE(PageEnterAdmission)}
      />
      <Route
        path={ADMISSION_VERIFY_ANSWERS_ROUTE}
        component={UserHasAdmissionEntryToken(
          TokenIsNotExpired(AutoLogoutRE(PageAdmissionVerifyAnswers)),
        )}
      />
      <Route
        path={ADMISSION_PRELUDE_ROUTE}
        component={UserHasAdmissionToken(
          TokenIsNotExpired(AutoLogoutRE(PageAdmissionPrelude)),
        )}
      />
      <Route
        path={ADMISSION_EXPERIENCE_ROUTE}
        component={UserHasAdmissionToken(
          TokenIsNotExpired(AutoLogoutRE(PageAdmissionExperience)),
        )}
      />
      <Route component={Page404} />
    </Switch>
  </>
);
