import React from "react";
import {useHistory} from "react-router-dom";
import Alert from "react-s-alert";
import {connect} from "react-redux";

import {
  Box,
  H3,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Button,
} from "@reside/ui";
import {useDeleteFacilityMutation} from "../../hooks";
import {AppState, select} from "../../../../store";
import {ActiveFacilities} from "../../../../store/reporting/reporting.common";
import {useFetchFacilitiesQuery} from "../../../../routers/AdminAppRefresh";
import {useFacilitySettingsParams} from "../../hooks/useFacilitySettingsParams";

type Props = Readonly<{
  isOpen: boolean;
  onRequestClose: () => void;
  setActiveFacilities?: (data: ActiveFacilities) => void;
  activeFacilitiesTreeKeys?: ReadonlyArray<string>;
}>;

const Render = ({
  isOpen,
  onRequestClose,
  setActiveFacilities,
  activeFacilitiesTreeKeys,
}: Props) => {
  const {facilityId} = useFacilitySettingsParams();

  const history = useHistory();

  const {
    mutateAsync: deleteFacility,
    isIdle,
    isError,
  } = useDeleteFacilityMutation();

  const {fetchFacilitiesQuery} = useFetchFacilitiesQuery();

  const onSuccess = async () => {
    const newActiveFacilities = activeFacilitiesTreeKeys?.filter(
      facility => facility !== facilityId,
    );
    await fetchFacilitiesQuery();
    setActiveFacilities({
      checkedLeafKeys: newActiveFacilities,
      checkedKeys: newActiveFacilities,
    });
    history.push("/admin/dashboard");
    Alert.success("Facility deleted successfully");
  };

  const onFacilityDelete = () => {
    deleteFacility({facilityId, onSuccess});
  };

  const disabled = !isIdle && !isError;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {({closeModal}) => (
        <>
          <ModalContent>
            <H3>Do you really want to delete this facility?</H3>
            <p>
              You can delete the facility only if all its admissions are
              archived or deleted. All archived and deleted admissions will
              still be available in the database after the facility has been
              removed.
            </p>
          </ModalContent>
          <ModalFooterButtons>
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                outline
                color="primary"
                onClick={closeModal}
                testId="closeDeleteFacilityButton"
                disabled={disabled}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="danger"
                testId="deleteFacilityButton"
                onClick={onFacilityDelete}
                disabled={disabled}
              >
                {disabled ? "Deleting" : "Delete"}
              </Button>
            </Box>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};

const mapState = (state: AppState & {loading: any}, props: Props) => ({
  activeFacilitiesTreeKeys: select.adminSession.activeFacilitiesTreeKeys(state),
});

const mapDispatch = (dispatch: any) => ({
  setActiveFacilities: (payload: ActiveFacilities) => {
    dispatch.adminSession.setActiveFacilities(payload);
  },
});

export const FacilityDeleteModal = connect(mapState, mapDispatch)(Render);
