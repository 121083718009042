import {TableUserDto} from "@reside/reside-api-app";

// TODO(reports/single-source-of-truth): import report list struct from page

export const GeneralTypes = [
  {
    label: "Admissions Overview",
    value: TableUserDto.PermissionsEnum.ADMISSION_IN_TOTAL,
  },
  {
    label: "Completion Time",
    value:
      TableUserDto.PermissionsEnum.COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED,
  },
  {
    label: "Photo/Payer ID",
    value: TableUserDto.PermissionsEnum.PHOTOS,
  },
  {
    label: "Arbitration",
    value: TableUserDto.PermissionsEnum.ARBITRATION_AGREEMENT,
  },
];

export const CheckboxesTypes = [
  {
    label: "Admission Checklist Items",
    value: TableUserDto.PermissionsEnum.CHECKLIST_ITEMS,
  },
];

export const DashboardsTypes = [
  {
    label: "Quicksight Dashboards",
    value: TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS,
  },
];

export const OtherTypes = [
  {
    label: "Approved Admission in total",
    value: TableUserDto.PermissionsEnum.APPROVED_ARCHIVED_ADMISSIONS,
  },
  {
    label: "Admissions completed with represent",
    value:
      TableUserDto.PermissionsEnum
        .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE,
  },
  {
    label: "Avg. time spent to close admission",
    value: TableUserDto.PermissionsEnum.AVG_TIME_SPENT_TO_CLOSE_ADMISSION,
  },
  {
    label: "Total number of Residents over time",
    value: TableUserDto.PermissionsEnum.ANALYTICS_ADMISSION_IN_TOTAL,
  },
  {
    label: "Residents with Medicaid",
    value: TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID,
  },
  {
    label: "Re-admission rate",
    value: TableUserDto.PermissionsEnum.READMISSION_RATE,
  },
  {
    label: "Patients with Medicare",
    value: TableUserDto.PermissionsEnum.PATIENTS_WITH_MEDICAL_SUPPLEMENT,
  },
  {
    label: "Admission Completion Rate per Facility Staff",
    value: TableUserDto.PermissionsEnum.ADMISSION_COMPLETION_RATE,
  },
  {
    label: "Admissions completion on time",
    value: TableUserDto.PermissionsEnum.COMPLETION_TIME,
  },
  {
    label: "Most frequently flagged slides",
    value: TableUserDto.PermissionsEnum.MOST_FREQUENTLY_FLAGGED_SLIDES,
  },
  {
    label: "Payer Source Age 65-75 ",
    value: TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_65_75,
  },
  {
    label: "Payer Source Age 75-85 ",
    value: TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_75_85,
  },
  {
    label: "Payer Source Age 85+ ",
    value: TableUserDto.PermissionsEnum.PAYER_SOURCE_AGE_85,
  },
  {
    label: "Arbitration agreement completed",
    value: TableUserDto.PermissionsEnum.ARBITRATION_AGREEMENT_COMPLETED,
  },
  {
    label: "Patients without Medicare",
    value: TableUserDto.PermissionsEnum.PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT,
  },
  {
    label: "Avg. time spent of each stage of admission",
    value:
      TableUserDto.PermissionsEnum.AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION,
  },
  {
    label: "Age of help flags",
    value: TableUserDto.PermissionsEnum.AGE_OF_HELP_FLAGS,
  },
  {
    label: "Slide Interactions",
    value: TableUserDto.PermissionsEnum.SLIDE_INTERACTIONS,
  },
  {
    label: "Staff",
    value: TableUserDto.PermissionsEnum.STAFF_ADMISSIONS,
  },
  {
    label: "Income & Personal Resource",
    value: TableUserDto.PermissionsEnum.INCOMES_AND_PERSONAL_RESOURCE,
  },
  {
    label: "Trust Fund",
    value: TableUserDto.PermissionsEnum.TRUST_FUND_POLICY,
  },
  {
    label: "Laundry Consent",
    value: TableUserDto.PermissionsEnum.LAUNDRY_CONSENT,
  },
  {
    label: "Pneumococcal",
    value: TableUserDto.PermissionsEnum.PNEUMOCOCCAL_VACCINES,
  },
  {
    label: "Influenza",
    value: TableUserDto.PermissionsEnum.INFLUENZA_VACCINES,
  },
  {
    label: "Surrogate Decision Making",
    value: TableUserDto.PermissionsEnum.SURROGATE_DECISION_MAKING,
  },
  {
    label: "Surrogate Decision Making Requests",
    value:
      TableUserDto.PermissionsEnum
        .SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION,
  },
  {
    label: "Existing Advanced Directive Provided",
    value: TableUserDto.PermissionsEnum.EXISTING_ADVANCED_DIRECTIVE_PROVIDED,
  },
  {
    label: "Residents requesting for help with Advanced Directive",
    value:
      TableUserDto.PermissionsEnum
        .RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE,
  },
  {
    label: "Funeral",
    value: TableUserDto.PermissionsEnum.FUNERAL,
  },
  {
    label: "Waiver of Medicare Benefits",
    value: TableUserDto.PermissionsEnum.MEDICARE_WAIVER,
  },
  {
    label: "Medicaid Request",
    value:
      TableUserDto.PermissionsEnum
        .RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID,
  },
  {
    label: "SNFABN Estimated Cost",
    value: TableUserDto.PermissionsEnum.ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE,
  },
  {
    label: "Facility Settings",
    value: TableUserDto.PermissionsEnum.FACILITY_CHANGES,
  },
];
