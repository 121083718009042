import {ErrorResponseDto, ValidationError} from "@reside/reside-api-admission";

export const buildResponseErrorMessage = async (
  response: any,
  defaultMessage?: string,
) => {
  let error = {};

  try {
    error = await response.json();
  } catch {}

  return buildErrorMessage(error, defaultMessage);
};

export const buildErrorMessage = (
  error: Partial<ErrorResponseDto>,
  defaultMessage = "Something went wrong",
): string => {
  if (error?.validationErrors?.length > 0) {
    return errorMessageFromErrorList(error.validationErrors);
  }

  return error?.message ? error?.message : defaultMessage;
};

const errorMessageFromErrorList = (
  validationErrors: ReadonlyArray<ValidationError>,
): string => {
  if (validationErrors.length === 0) {
    return "";
  }

  return validationErrors
    .map(error => `${error.field} - ${error.message}`)
    .join("\n");
};
