import React from "react";
import {theme, Tooltip} from "@reside/ui";
import {css} from "@emotion/css";

export const DisabledNotApplicableStatusOption = ({
  StyledDropdownSelectOption,
  icon,
}: any) => (
  <StyledDropdownSelectOption
    disabled
    className={css`
      background: ${theme.color.gray5};
      color: ${theme.color.gray20};

      .icon {
        color: ${theme.color.gray20};
      }

      &:hover {
        cursor: not-allowed;
        background-color: ${theme.color.gray5} !important;
      }
    `}
  >
    {icon}
    <Tooltip content="The PCC Document Upload is mandatory">
      Not Applicable
    </Tooltip>
  </StyledDropdownSelectOption>
);
