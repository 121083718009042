export const formatCurrency = (
  value: number,
  options: Intl.NumberFormatOptions = {
    currency: "USD",
  },
): string => {
  const formatter = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    currency: options.currency,
    style: "currency",
  });

  return formatter.format(value);
};
