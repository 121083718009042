import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const usePatchTemplateField = () =>
  useMutation(
    async ({
      templateFieldId,
      friendlyName,
      isAvailableInRule,
    }: {
      templateFieldId: string;
      friendlyName: string;
      isAvailableInRule?: boolean;
    }) => {
      const response = await HubAndSpokeService.patchTemplateFields({
        templateFieldId,
        friendlyName,
        isAvailableInRule,
      });
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("Field successfully updated!");
      },
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to patch template field.");
      },
    },
  );
