import {RouteComponentProps} from "react-router-dom";
import {get} from "lodash";
import qs from "qs";
import {useEffect} from "react";

import {UrlProps} from "../../atoms/reporting-block";
import {Context} from "./ReportContext";
import {Facility, IFromTo} from "../../store/reporting/reporting.common";
import {
  CustomDateRangePreset,
  findDateRangePreset,
} from "../../atoms/reporting-compare";

type Props = RouteComponentProps<UrlProps> &
  Pick<
    Context,
    | "activeFacilitiesIds"
    | "setActiveFacilities"
    | "baseDateRange"
    | "setBaseDateRange"
  >;

export const UrlSync = ({
  match,
  location,
  history,
  baseDateRange,
  setBaseDateRange,
  activeFacilitiesIds,
  setActiveFacilities,
}: Props): JSX.Element => {
  const getFacilitiesIdsFromUrl = (): Facility[] => {
    const {facilities} = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return facilities;
  };

  useEffect(() => {
    const facilities = getFacilitiesIdsFromUrl();

    if (facilities) {
      setActiveFacilities({
        checkedKeys: facilities,
        checkedLeafKeys: facilities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlDate = {
      from: match.params.from1,
      to: match.params.to1,
    };

    const activeDateRange = findDateRangePreset(urlDate.from, urlDate.to) || {
      ...CustomDateRangePreset,
      from: CustomDateRangePreset.from(),
      to: CustomDateRangePreset.to(),
    };

    if (activeDateRange.id === CustomDateRangePreset.id) {
      activeDateRange.from = urlDate.from;
      activeDateRange.to = urlDate.to;
    }

    setBaseDateRange({
      ...activeDateRange,
      to: activeDateRange.to,
      from: activeDateRange.from,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUrlState = () => {
      const fromOverview = get(location, "state.fromOverview", false);
      const previousUrl = get(location, "state.previousUrl", null);

      return {fromOverview, previousUrl};
    };

    history.push(
      createFullReportUrl(
        match.params,
        {from: baseDateRange.from, to: baseDateRange.to},
        activeFacilitiesIds,
      ),
      getUrlState(),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFacilitiesIds, baseDateRange]);

  return null;
};

const createFullReportUrl = (
  {type, chartGroup, chartId}: UrlProps,
  dateRange: IFromTo,
  facilities?: Facility[],
) => {
  if (dateRange?.from && dateRange.to) {
    const urlParts = [
      "/admin/reporting",
      type,
      chartGroup,
      chartId,
      dateRange.from,
      dateRange.to,
    ];

    const pathname = urlParts.join("/");
    const search = qs.stringify({facilities}, {arrayFormat: "brackets"});

    return `${pathname}?${search}`;
  }
};
