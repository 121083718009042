import axios from "axios";
import {VerificationAnswersDto, TokenDto} from "@reside/reside-api-admission";

const client = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || process.env.STORYBOOK_REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

/**
 * TODO: remove once we can access response headers from the generated api clients
 */
export const enterAdmission = (
  data: VerificationAnswersDto,
  Authorization: string,
) =>
  client.post<TokenDto>("/v3/admissions/enter", data, {
    headers: {Authorization},
  });
