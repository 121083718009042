import React, {Fragment} from "react";

import {AdmissionCellErrorBoundary} from "./AdmissionCellErrorBoundary";
import {AdmissionCellRenderer} from "./AdmissionCellRenderer";

export const AdmissionCellList = ({admissions}: {admissions: any}) => (
  <>
    {admissions.map((admission: any, idx: any) => (
      <Fragment key={admission.id}>
        <AdmissionCellErrorBoundary>
          <AdmissionCellRenderer key={admission.id} admission={admission} />
        </AdmissionCellErrorBoundary>
      </Fragment>
    ))}
  </>
);
