import React, {FunctionComponent, useState} from "react";
import {Formik, useFormikContext} from "formik";
import {validator} from "@reside/forms";
import {
  CreateStaffUserDto,
  OrganizationDto,
  UserDto,
} from "@reside/reside-api-app";
import {
  FormGrid,
  FormSlide,
  FormCard,
  SelectField,
  TextField,
  TagsField,
  ThinScrollbar,
} from "@reside/ui";
import {css} from "@emotion/css";

import {userTypes, visibleRolesFor} from "../../../utils/constants";
import {readableRole} from "../../../atoms/list-table/helpers";
import {AppState, select} from "../../../store";
import {connect} from "react-redux";
import {useOrganizationsQuery} from "../hooks/useOrganizationsQuery";
import {useFacilitiesQuery} from "../hooks/useFacilitiesQuery";
import {BackdropSpinner} from "../../../atoms/spinner";
import {StyledScrollableErrorArea} from "./styles";
import {normalizeValues, getValidationRules} from "./helpers";
import {SaveBeforeLeavePrompt} from "../../../atoms/save-before-leave-prompt/SaveBeforeLeavePrompt";

type Props = Readonly<{
  initialValues: CreateStaffUserDto;
  onSubmit: (values: CreateStaffUserDto) => void;
  user: UserDto;
  userFormHeader: React.ReactNode;
  deleteUserButton?: React.ReactNode;
  resetPasswordButton?: React.ReactNode;
  resendActivationEmailButton?: React.ReactNode;
}>;

export const useUserFormikContext = () =>
  useFormikContext<CreateStaffUserDto>();

export const Renderer: FunctionComponent<Props> = ({
  initialValues,
  onSubmit,
  user,
  userFormHeader,
  deleteUserButton = null,
  resetPasswordButton = null,
  resendActivationEmailButton = null,
}) => {
  const [organizationId, setOrganizationId] = useState<string>(
    initialValues?.organizationId,
  );

  const roles = visibleRolesFor(user.role.name);
  const {data: organizations = [], isFetching: isFetchingOrganizations} =
    useOrganizationsQuery();

  const validate = (values: CreateStaffUserDto) =>
    validator.validateAll(values, getValidationRules(values, organizations));

  const {data: facilities = [], isFetching: isFetchingFacilities} =
    useFacilitiesQuery({
      organizationId,
    });

  const facilityOptions = facilities.map(facility => ({
    value: facility.id,
    label: facility.name,
  }));

  return (
    <Formik<CreateStaffUserDto>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(normalizeValues(values))}
      validate={validate}
    >
      {({values, setValues, setFieldTouched}) => (
        <BackdropSpinner
          active={isFetchingOrganizations || isFetchingFacilities}
        >
          <>
            <SaveBeforeLeavePrompt />
            {userFormHeader}
            <ThinScrollbar>
              <StyledScrollableErrorArea>
                <FormGrid>
                  <FormSlide>
                    <FormCard title="User Details">
                      <TextField label={"First Name"} name="firstName" />
                      <TextField label={"Last Name"} name="lastName" />
                      <SelectField
                        label={"Role"}
                        name="roleId"
                        options={roles}
                        getOptionLabel={role => readableRole(role)}
                        getOptionValue={role => userTypes[role]}
                      />
                      <TextField
                        label={"Email Address"}
                        name="email"
                        type="email"
                      />
                      <TextField
                        label={"Phone"}
                        name="phone"
                        format="us-phone"
                      />
                      {resetPasswordButton}
                      {resendActivationEmailButton}
                    </FormCard>

                    {values.roleId !== userTypes.RESIDE_ADMIN && (
                      <FormCard title="Organization">
                        <SelectField<OrganizationDto>
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          label="Select Organization"
                          name="organizationId"
                          options={organizations}
                          onChange={event => {
                            setOrganizationId(event.currentTarget.value);
                            if (values.email) setFieldTouched("email");
                            setValues({
                              ...values,
                              organizationId: event.currentTarget.value,
                              facilities: [],
                            });
                          }}
                          className={css(`
                      & > select {
                        padding-left: 0px;
                      }
                  `)}
                        />
                      </FormCard>
                    )}

                    {values.roleId !== userTypes.ORGANIZATION_ADMIN &&
                      values.roleId !== userTypes.RESIDE_ADMIN && (
                        <FormCard title="Facilities">
                          <TagsField
                            name="facilities"
                            options={facilityOptions}
                            placeholder="Start typing facility name"
                            label="Select Facility"
                            testId="facilitySelect"
                          />
                        </FormCard>
                      )}
                    {deleteUserButton}
                  </FormSlide>
                </FormGrid>
              </StyledScrollableErrorArea>
            </ThinScrollbar>
          </>
        </BackdropSpinner>
      )}
    </Formik>
  );
};

/**
 * Map data from redux state to component props;
 * @param state - the redux state;
 * @returns - the user from the redux state;
 */
const mapState = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const UserFormik = connect(mapState)(Renderer);
