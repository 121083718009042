import React from "react";
import {format} from "date-fns-tz";
import {Link} from "react-router-dom";
import {toDate, parseISO} from "date-fns";
import {theme, Text, styled, Tag} from "@reside/ui";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {Icon} from "@reside/ui";

import {colorMap, getUserOrResidentValue, readableStatus} from "./helpers";
import {Avatar} from "../avatar";
import {FlagCountIcon} from "../flag-count-icon";

type TextProps = {
  small?: boolean;
  subtle?: boolean;
  children: React.ReactNode;
};

type LinkProps = {
  link: string;
  children: React.ReactNode;
};

type DateProps = {
  data: string | number | Date;
};

type IconProps = {
  accepted: boolean;
};

export const AvatarCell = ({data}: any) => {
  const firstName = getUserOrResidentValue(data, "firstName");
  const lastName = getUserOrResidentValue(data, "lastName");
  const fullName = `${firstName} ${lastName}`;

  return (
    <AvatarCellWrapper data-test-id={`resident-${fullName}`}>
      <Avatar size={34} name={fullName} />
      <AvatarText color={"darkBlue100"} fontSize={3} fontWeight="bold">
        {fullName}
      </AvatarText>
    </AvatarCellWrapper>
  );
};

const AvatarText = styled(Text)`
  padding-left: 15px;
`;

const AvatarCellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextCell = ({small, subtle, children, ...props}: TextProps) => (
  <Text
    fontSize={small ? 1 : 3}
    fontWeight="medium"
    color={subtle ? "gray100" : undefined}
    {...props}
  >
    {children}
  </Text>
);

const safeToString = (node: React.ReactNode) =>
  node?.toString && typeof node.toString === "function" ? node.toString() : "";

export const TextCellEllipsis = (props: TextProps) => (
  <div className="ellipsis">
    <Text
      fontSize={props.small ? 1 : 3}
      fontWeight="medium"
      color={props.subtle ? "gray100" : undefined}
      title={safeToString(props.children)}
    >
      {props.children}
    </Text>
  </div>
);

export const LinkCell = (props: LinkProps) => (
  <SharedCellsLink to={props.link}>
    <Text fontSize={3} fontWeight="medium">
      {props.children}
    </Text>
  </SharedCellsLink>
);

const SharedCellsLink = styled(Link)`
  color: #a8b6ca;
`;

export const StatusCell = (props: any) => (
  <Tag color={colorMap(props.data)}>{readableStatus(props.data)}</Tag>
);

type FormatDate = {
  date: number | string | Date;
  formatString: string;
  timeZone?: string;
  isMillisecond?: boolean;
};

const formatDate = ({
  date,
  formatString,
  timeZone,
  isMillisecond,
}: FormatDate) => {
  const dateObj =
    typeof date === "number"
      ? toDate(isMillisecond ? date : date * 1000)
      : typeof date === "string"
      ? parseISO(date)
      : date;

  return format(dateObj, formatString);
};

export const DateCell = (props: any) => (
  <TextCell subtle>
    {props.data
      ? formatDate({
          date: props.data,
          formatString: DATE_FORMAT.FULL_DATE,
          timeZone: props.timeZone,
        })
      : "-"}
  </TextCell>
);

export const DateReportsCell = (props: DateProps) => (
  <TextCellEllipsis small>
    {props.data
      ? formatDate({
          date: props.data,
          formatString: DATE_FORMAT.SHORT_DAY,
          isMillisecond: true,
        })
      : "-"}
  </TextCellEllipsis>
);

export const IconCheckCell = (props: IconProps) => (
  <Text color="primary">
    {props.accepted && (
      <Icon name="check" color={theme.color.primary} size={10} />
    )}
  </Text>
);

type IconCrossCellProps = {
  color?: string;
};

export const IconCrossCell = ({color}: IconCrossCellProps) => (
  <Text color="primary" fontWeight="bold">
    <Icon name="clear" color={color} size={10} />
  </Text>
);

IconCrossCell.defaultProps = {
  color: theme.color.primary,
};

export const DatetimeCell = (props: any) => (
  <TextCell subtle>
    {props.data
      ? formatDate({
          date: props.data,
          formatString: DATE_FORMAT.FULL_DATE_TIME,
        })
      : "-"}
  </TextCell>
);

export const FlagsCell = (props: {data: number}) => (
  <CellWrapper>
    <FlagCountIcon count={props.data} />
  </CellWrapper>
);

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
