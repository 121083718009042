var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from "react";
import { css } from "@emotion/react";
import { Listbox } from "@headlessui/react";
import { styled } from "../theme";
import { Text } from "../text";
export var DropdownSelect = function (_a) {
    var trigger = _a.trigger, disabled = _a.disabled, items = _a.items, selectItem = _a.selectItem, selectedItem = _a.selectedItem, label = _a.label, className = _a.className;
    return (React.createElement(Listbox, { value: selectedItem, onChange: selectItem },
        React.createElement(Container, { className: className },
            label && React.createElement(Listbox.Label, { as: Label }, label),
            disabled ? (React.createElement(TriggerWrapper, { "data-test-id": "dropdown-select-".concat(label) }, trigger({ isOpened: false, selectedItem: selectedItem }))) : (React.createElement(Listbox.Button, { as: Fragment }, function (_a) {
                var open = _a.open;
                return (React.createElement(TriggerWrapper, { "data-test-id": "dropdown-select-".concat(label) }, trigger({ isOpened: open, selectedItem: selectedItem })));
            })),
            React.createElement(Listbox.Options, { as: DropdownSelectList }, items.map(function (item, index) { return (React.createElement(Fragment, { key: index }, item.type === "DIVIDER" ? (React.createElement(Divider, null)) : (React.createElement(Listbox.Option, { as: Fragment, key: item.id, value: item, "data-test-id": "dropdown-select-item-".concat(item.id) }, function (_a) {
                var active = _a.active;
                return (React.createElement(DropdownSelectOption, { active: active }, item.label));
            })))); })))));
};
DropdownSelect.defaultProps = {
    className: "",
    trigger: function (_a) {
        var selectedItem = _a.selectedItem;
        return selectedItem.label;
    },
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n"], ["\n  display: inline-block;\n  position: relative;\n"])));
var Label = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 14px;\n  line-height: 17px;\n  margin-right: 6px;\n"], ["\n  font-weight: ", ";\n  font-size: 14px;\n  line-height: 17px;\n  margin-right: 6px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
});
var TriggerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  cursor: pointer;\n"], ["\n  display: inline-block;\n  cursor: pointer;\n"])));
export var DropdownSelectList = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  background-color: ", ";\n  margin-top: 10px;\n  z-index: 100;\n  white-space: nowrap;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);\n\n  &:focus-visible {\n    outline: none;\n    box-shadow: 0px 0px 2px ", ",\n      0 2px 10px 0 rgba(0, 0, 0, 0.25);\n  }\n"], ["\n  position: absolute;\n  right: 0;\n  background-color: ", ";\n  margin-top: 10px;\n  z-index: 100;\n  white-space: nowrap;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);\n\n  &:focus-visible {\n    outline: none;\n    box-shadow: 0px 0px 2px ", ",\n      0 2px 10px 0 rgba(0, 0, 0, 0.25);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
export var DropdownSelectOption = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 12px 16px;\n  cursor: pointer;\n  font-weight: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n"], ["\n  padding: 12px 16px;\n  cursor: pointer;\n  font-weight: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.bold;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue10;
}, function (_a) {
    var active = _a.active, theme = _a.theme;
    return active && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), theme.color.borderLightGray);
});
var Divider = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
