import React from "react";
import {connect} from "react-redux";

import {
  AnalyticsDataPath,
  AnyDataPath,
  isAnalyticsDataPath,
} from "../../store/reporting/reporting.common";
import {AppState} from "../../store/store";
import {createSelectors} from "../../store/reporting";
import {
  FileExportDropdownSelect,
  Props as FileExportProps,
} from "./file-export-dropdown-select/FileExportDropdownSelect";
import {ReportHeader} from "./ReportingBlock";
import {createSelectorsReports} from "../../store/reporting-reports/reporting-reports.selectors";
import {ReportsDataPath} from "../../store/reporting-reports/reporting-reports.common";
import {hasEmptySegments} from "./utils/helpers";

type OwnProps = Readonly<{
  dataPath: AnyDataPath;
  onExport: FileExportProps["onSelect"];
}>;

type StateProps = Readonly<{
  hasData: boolean;
}>;

type Props = OwnProps & StateProps;

const HeaderPresenter = ({onExport, hasData}: Props & StateProps) => (
  <ReportHeader>
    <FileExportDropdownSelect onSelect={onExport} disabled={!hasData} />
  </ReportHeader>
);

const mapStateToProps = (state: AppState, {dataPath}: OwnProps): StateProps => {
  return {
    hasData: dataPath
      ? isAnalyticsDataPath(dataPath)
        ? selectAnalyticsDataPathHasData(state, dataPath)
        : selectReportingDataPathHasData(state, dataPath)
      : false,
  };
};

const selectAnalyticsDataPathHasData = (
  state: AppState,
  dataPath: AnalyticsDataPath,
) => {
  const {byDataPath: selectByDataPath} = createSelectors(dataPath);
  const result = selectByDataPath(state);

  return result?.success && result.data && !hasEmptySegments(result.data);
};

const selectReportingDataPathHasData = (
  state: AppState,
  dataPath: ReportsDataPath,
) => {
  const {byDataPath: selectByDataPath} = createSelectorsReports(dataPath);
  const result = selectByDataPath(state);

  return result?.success && result.data && !hasEmptySegments(result.data);
};

export const Header = connect(mapStateToProps)(HeaderPresenter);
