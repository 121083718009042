import React from "react";
import {Switch, Redirect, RouteComponentProps} from "react-router-dom";
import {SentryRoute as Route} from "../utils/sentryRoute";

import {
  PageReportingReportsCheckboxes,
  PageReportingFullReport,
  PageReportingDashboards,
  PageReportingGeneral,
  PageReportingOther,
} from "../pages/page-reporting";
import {PageAdmin404} from "../pages/page-admin-404";
import {ConnectedReportContextProvider} from "../pages/page-reporting/ReportContext";
import {AdminHasReportingAccess} from "../models/admin-session/AdminHasReportingAccess";

export const ReportingRouterRenderer = ({match}: RouteComponentProps) => (
  <ConnectedReportContextProvider>
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        component={() => <Redirect to={`${match.path}/general`} />}
      />
      <Route
        exact
        path={`${match.path}/:type(analytics)/:chartGroup/:chartId/:from1/:to1`}
        component={PageReportingFullReport}
      />
      <Route
        exact
        path={`${match.path}/:type(reports)/:chartGroup/:chartId/:from1/:to1`}
        component={PageReportingFullReport}
      />
      <Route
        exact
        path={`${match.path}/general`}
        component={PageReportingGeneral}
      />
      <Route
        exact
        path={`${match.path}/dashboards`}
        component={PageReportingDashboards}
      />
      <Route
        exact
        path={`${match.path}/checkboxes`}
        component={PageReportingReportsCheckboxes}
      />
      <Route
        exact
        path={`${match.path}/other`}
        component={PageReportingOther}
      />
      <Route exact path={`${match.path}/`} component={PageReportingGeneral} />
      <Route component={PageAdmin404} />
    </Switch>
  </ConnectedReportContextProvider>
);

export const ReportingRouter = AdminHasReportingAccess(ReportingRouterRenderer);
