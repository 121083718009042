import React, {ReactNode} from "react";
import {Formik} from "formik";
import {connect} from "react-redux";
import {styled, FormikAutoSaveValues, Collapsible} from "@reside/ui";
import {ChecklistItem} from "@reside/reside-api-admission";

import {select} from "../../../../store";

export type ChecklistValues = Record<string, ChecklistItem>;

type OwnProps = Readonly<{
  disabled: boolean;
  initialValues?: ChecklistValues;
  checklist?: ReadonlyArray<ChecklistItem>;
  onChange: (values: ChecklistValues) => void;
  validationErrors?: Record<string, string>;
  children: (props: {
    checklistItem: ChecklistItem;
    disabled: boolean;
    validationError?: string;
  }) => ReactNode;
}>;

// Display 5 checkboxes if there's up to 5 total
const MAX_CHECKBOXES = 5;
// Display 4 checkboxes if there are 6 or more in total
const MAX_CHECKBOXES_IF_MORE = 4;

export const ChecklistRenderer = ({
  checklist,
  disabled,
  children,
  initialValues,
  validationErrors,
  onChange,
}: OwnProps) => (
  <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
    <Wrapper>
      <FormikAutoSaveValues
        disableDebounce
        onChangeValues={(values, initial) => {
          if (initial) {
            return;
          }
          onChange(values as any);
        }}
      />
      <Collapsible
        splitIndex={
          checklist.length > MAX_CHECKBOXES
            ? MAX_CHECKBOXES_IF_MORE
            : MAX_CHECKBOXES
        }
        openTitle={`View ${checklist.length - MAX_CHECKBOXES_IF_MORE} more`}
        closeTitle="Hide"
      >
        {checklist.map(checklistItem =>
          children({
            checklistItem,
            disabled,
            validationError: validationErrors?.[checklistItem.id],
          }),
        )}
      </Collapsible>
    </Wrapper>
  </Formik>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    height: 16px; // eliminate icon size

    .icon-file-pdf {
      position: relative;
      top: -3px;
    }

    .icon-upload-cloud {
      position: relative;
      top: -2px;
    }
  }

  .ReactCollapse--collapse + button {
    margin-left: 15px;
  }
`;

ChecklistRenderer.defaultProps = {
  initialValues: {},
  checklist: [],
  disabled: false,
};

export const Checklist = connect(state => ({
  disabled: select.adminSession.isResideAdmin(state),
}))(ChecklistRenderer);
