import React from "react";
import {DropdownSelect, DropdownSelectText} from "@reside/ui";

import {UserStatus} from "../../../store/users/usersModel";

export type UserStatusOptions = UserStatus | null;

type UserStatusItem = {
  id: UserStatusOptions;
  label: string;
};

const userStatusItems: Array<UserStatusItem> = [
  {id: null, label: "All"},
  {id: "ACTIVE", label: "Active"},
  {id: "NEW", label: "New"},
];

type Props = Readonly<{
  selectedStatus: UserStatusOptions;
  onStatusSelect: (status: UserStatusOptions) => void;
}>;

export const UsersStatusSelect = ({selectedStatus, onStatusSelect}: Props) => {
  const selectedItem = userStatusItems.find(
    status => status.id === selectedStatus,
  );

  return (
    <DropdownSelect<UserStatusItem>
      trigger={({isOpened}) => (
        <DropdownSelectText label={selectedItem.label} isOpened={isOpened} />
      )}
      selectItem={selectedItem => onStatusSelect(selectedItem.id)}
      selectedItem={selectedItem}
      items={userStatusItems}
      label="Status:"
    />
  );
};
