import {addDays, subDays, subMonths, isSameDay, subHours} from "date-fns";

type DateRangePresents = {
  from: () => string | null;
  to: () => string | null;
  readonly id: number;
  label: string;
};

export const DateRangePresets: DateRangePresents[] = [
  {
    id: 1,
    label: "Last 1 day",
    from: () => subHours(new Date(), 24).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 2,
    label: "Last 7 days",
    from: () => subDays(new Date(), 6).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 3,
    label: "Last 10 days",
    from: () => subDays(new Date(), 9).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 4,
    label: "Last 14 days",
    from: () => subDays(new Date(), 13).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 5,
    label: "Last 30 days",
    from: () => subDays(new Date(), 29).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 6,
    label: "Last 3 months",
    from: () => subMonths(new Date(), 3).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 7,
    label: "Last 6 months",
    from: () => subMonths(new Date(), 6).toISOString(),
    to: () => new Date().toISOString(),
  },
  {
    id: 8,
    label: "Last 12 months",
    from: () => subMonths(addDays(new Date(), 1), 12).toISOString(),
    to: () => new Date().toISOString(),
  },
];

export const CustomDateRangePreset: DateRangePresents = {
  id: 0,
  label: "Custom",
  from: () => null,
  to: () => null,
};

export const findDateRangePreset = (from: string, to: string) => {
  const preset = DateRangePresets.find(
    item =>
      !!item.from &&
      isSameDay(new Date(item.from()), new Date(from)) &&
      !!item.to &&
      isSameDay(new Date(item.to()), new Date(to)),
  );

  if (!preset) {
    return;
  }

  return {
    ...preset,
    from: preset.from(),
    to: preset.to(),
  };
};
