import {FeatureFlagsTable} from "./atoms/";
import {BackdropSpinner} from "../../atoms/spinner";
import {
  useFetchAllOrganizationFeatureFlags,
  useFetchFeatureFlagsByOrganizations,
} from "./hooks/";

export const PageAdminFeatureFlagsByOrganizations = () => {
  const {data: allFeatureFlags, isFetching: isFetchingAllFeatureFlags} =
    useFetchAllOrganizationFeatureFlags();

  const {data, isFetching} = useFetchFeatureFlagsByOrganizations();

  return (
    <BackdropSpinner active={isFetching || isFetchingAllFeatureFlags}>
      <FeatureFlagsTable
        title="Feature Flags"
        subtitle="By Organizations"
        allFeatureFlags={allFeatureFlags}
        data={data}
        header="Organization"
      />
    </BackdropSpinner>
  );
};
