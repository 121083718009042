import React from "react";
import {styled, BlockField, BlockSlide as BlockSlideWrapper} from "@reside/ui";

import {LastSlideWithSignatureValuesSetter} from "../last-slide";
import {SlideBlock} from "../model";
import {useAdmissionContext} from "../AdmissionContext";

type Props = SlideBlock;

export const BlockSlide = ({
  id,
  state,
  children,
  darkBackground,
  verticalContentAlignment,
}: Props) => {
  const {draft, uiState, uiActions} = useAdmissionContext();

  return (
    <BlockSlideWrapper
      darkBackground={darkBackground}
      verticalContentAlignment={verticalContentAlignment}
    >
      <Column data-slide-id={id} data-admission-id={draft.instance?.id}>
        {state.last ? (
          <LastSlideWithSignatureValuesSetter
            draft={draft}
            uiActions={uiActions}
            uiState={uiState}
          />
        ) : (
          children.map(block => <BlockField key={block.id} block={block} />)
        )}
        <SlideSpacer />
      </Column>
    </BlockSlideWrapper>
  );
};

export const Column = styled.div`
  padding: ${({theme}) => theme.space[5]}px;

  @media (min-width: ${({theme}) => theme.breakpoint.md}px) {
    padding: 30px 80px 60px 80px;
  }
`;

/**
 * Offsets slide content from the bottom, so shadow from the footer does not overlap the text.
 */
const SlideSpacer = styled.div`
  background-color: transparent;
  display: block;
  width: 1px;
  min-height: 18px;
`;
