import React from "react";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import {Formik} from "formik";
import {validator} from "@reside/forms";
import {ChangeUserPasswordDto} from "@reside/reside-api-app";
import {dispatch} from "../../store";
import {useUserQuery} from "../page-admin-user-edit/hooks/useUserQuery";
import {DocumentTitle} from "../../atoms/document-title";
import {UserService} from "../../services/UserService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";
import {ChangePasswordForm} from "./atoms/ChangePasswordForm";
import {useUserProfileRouteParams} from "./route";
import {
  PageAdminProfileLayout,
  ProfileFormLayout,
} from "./atoms/PageAdminProfileLayout";

function validate(values: ChangeUserPasswordDto) {
  return validator.validateAll(
    values,
    {
      oldPassword: "required",
      password: "required|min:8|different:oldPassword",
      confirmPassword: "required|same:password",
    },
    {
      same: 'The "New Password" and "Confirm New Password" fields must match.',
      different:
        'The "New Password" and "Current Password" fields must be different.',
    },
  );
}

export const PageAdminChangePassword = () => {
  const {userId} = useUserProfileRouteParams();
  const {data: user, isLoading} = useUserQuery({userId});

  const {mutate} = useMutation(
    (values: ChangeUserPasswordDto) =>
      UserService.changeUserPassword(values, user.id),
    {
      onSuccess: () => {
        dispatch.adminSession.logout();
        Alert.success(
          "Password successfully changed. Please log in with your new password.",
        );
      },
      onError: async (resp: any) => {
        Alert.error(
          await buildResponseErrorMessage(
            resp,
            "There was an error changing the password",
          ),
        );
      },
    },
  );

  return (
    <>
      <DocumentTitle
        title={`Reside - Admin - Change Password${
          user ? ` - ${user.firstName} ${user.lastName}` : ""
        }`}
      />
      <PageAdminProfileLayout user={user} isLoading={isLoading}>
        <Formik<ChangeUserPasswordDto>
          initialValues={{
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={(values, {setSubmitting}) => {
            mutate(values, {onSettled: () => setSubmitting(false)});
          }}
          validate={values => validate(values)}
        >
          <ProfileFormLayout user={user}>
            <ChangePasswordForm />
          </ProfileFormLayout>
        </Formik>
      </PageAdminProfileLayout>
    </>
  );
};
