import {CancelTokenSource} from "axios";
import {PayloadAction} from "typesafe-actions";
import {AppState} from "../store";
import {ReportsDataPath} from "../reporting-reports/reporting-reports.common";
import {getTime, parseISO} from "date-fns";

export type DateStr = string;

export type Facility = string;

export type ActiveFacilities = {
  checkedKeys: undefined | Facility[];
  checkedLeafKeys: undefined | Facility[];
};

export enum ReportingContextType {
  analyticsWithResolution = "analyticsWithResolution",
  analyticsWithoutResolution = "analyticsWithoutResolution",
}

export enum AggregationType {
  SUM = "SUM",
  COUNT = "COUNT",
  WEIGHTED_AVG = "RATE",
}

/*
  Actions
*/

export const SET_ANALYTICS = "@REPORTING/ANALYTICS/SET_ANALYTICS";
export const CLEAR_ANALYTICS = "@REPORTING/ANALYTICS/CLEAR_ANALYTICS";
export const FETCH_BEGIN = "@REPORTING/ANALYTICS/FETCH_BEGIN";
export const FETCH_SUCCESS = "@REPORTING/ANALYTICS/FETCH_SUCCESS";
export const FETCH_FAILURE = "@REPORTING/ANALYTICS/FETCH_FAILURE";

export type IReportingAnalyticsAction =
  | PayloadAction<typeof SET_ANALYTICS, IReportingAnalyticsPayload>
  | PayloadAction<typeof CLEAR_ANALYTICS, IReportingAnalyticsPayload>
  | PayloadAction<typeof FETCH_BEGIN, IReportingAnalyticsPayloadBase>
  | PayloadAction<typeof FETCH_SUCCESS, IReportingAnalyticsPayloadBase>
  | PayloadAction<typeof FETCH_FAILURE, IReportingAnalyticsPayload>;

export interface StartFetchAnalyticsActionFactory {
  (cancelTokenSource: CancelTokenSource): void;
}

export interface IReportingAnalyticsPayloadBase {
  path: AnalyticsDataPath;
}

export interface IReportingAnalyticsPayload
  extends IReportingAnalyticsPayloadBase {
  data: ReportingAnalyticsResponse;
}

/*
  With Analytic Type
*/

export enum AnalyticsTypeWithResolution {
  admissionsCompletionRate = "/stats/completion-rate",
  timeToClose = "/stats/time-to-close",
  timeToCloseFromSignature = "/stats/time-to-close-from-signature",
  totalAdmissions = "/stats/total-admissions",
  approvedAndArchivedAdmissions = "/stats/approved-archived-admissions",
}

export const AnalyticsTypeWithResolutionNames: Record<
  AnalyticsTypeWithResolution,
  string
> = {
  [AnalyticsTypeWithResolution.admissionsCompletionRate]:
    "Admissions Completion Rate",
  [AnalyticsTypeWithResolution.timeToClose]: "Time to Close",
  [AnalyticsTypeWithResolution.timeToCloseFromSignature]:
    "Time to Close by Facility",
  [AnalyticsTypeWithResolution.totalAdmissions]: "Total Admissions",
  [AnalyticsTypeWithResolution.approvedAndArchivedAdmissions]:
    "Total Number Of Approved Admissions",
};

export enum AnalyticsTypeWithoutResolution {
  ADRate = "/stats/ad-rate",
  admissionsCompletionOnTime = "/stats/completion-time",
  admissionsCompletionOverall = "/stats/completion-overall",
  arbitrationAgreementRate = "/stats/arbitration-agreement-rate",
  admissionsFacilityCompletionRate = "/stats/facility-completion-rate",
  admissionsStaffCompletionRate = "/stats/staff-completion-rate",
  DNRRate = "/stats/dnr-rate",
  flagsAge = "/stats/flags-age",
  flagsBySlide = "/stats/flags-by-slide",
  managedMedicaidRate = "/stats/managed-medicaid-rate",
  managedMedicaidRateByFacility = "/stats/managed-medicaid-rate-by-facility",
  medicareByFacility = "/stats/medicare-by-facility",
  missingMedicareRate = "/stats/missing-medicare-rate",
  missingMedicareRateByFacility = "/stats/missing-medicare-rate-by-facility",
  OBRARate = "/stats/obra-rate",
  payerSourceAge65_75 = "/stats/payer-source-age-65-75",
  payerSourceAge65_75ByCountry = "/stats/payer-source-age-65-75-by-country",
  payerSourceAge75_85 = "/stats/payer-source-age-75-85",
  payerSourceAge75_85ByCountry = "/stats/payer-source-age-75-85-by-country",
  payerSourceAge85 = "/stats/payer-source-age-85",
  payerSourceAge85ByCountry = "/stats/payer-source-age-85-by-country",
  readmissionRate = "/stats/readmission-rate",
  readmissionRateByFacility = "/stats/readmission-rate-by-facility",
  representativeRate = "/stats/representative-rate",
  representativeRateByFacility = "/stats/representative-rate-by-facility",
  timeByStage = "/stats/time-by-stage",
  timeToCloseFromSignatureByFacility = "/stats/time-to-close-from-signature-by-facility",
}

export const AnalyticsTypeWithoutResolutionNames: Record<
  AnalyticsTypeWithoutResolution,
  string
> = {
  [AnalyticsTypeWithoutResolution.ADRate]: "Advanced Directive Rate",
  [AnalyticsTypeWithoutResolution.admissionsCompletionOnTime]:
    "Admission Completion Time",
  [AnalyticsTypeWithoutResolution.admissionsCompletionOverall]:
    "Admission Completion Overall",
  [AnalyticsTypeWithoutResolution.admissionsFacilityCompletionRate]:
    "Admissions Facility Completion Rate",
  [AnalyticsTypeWithoutResolution.admissionsStaffCompletionRate]:
    "Admissions User Completion Rate",
  [AnalyticsTypeWithoutResolution.arbitrationAgreementRate]:
    "Arbitration Agreement Rate",
  [AnalyticsTypeWithoutResolution.DNRRate]: "DNR Rate",
  [AnalyticsTypeWithoutResolution.flagsAge]: "Flags Age",
  [AnalyticsTypeWithoutResolution.flagsBySlide]: "Flags by Slide",
  [AnalyticsTypeWithoutResolution.managedMedicaidRate]: "Medicaid Rate",
  [AnalyticsTypeWithoutResolution.managedMedicaidRateByFacility]:
    "Medicaid Rate by Facility",
  [AnalyticsTypeWithoutResolution.medicareByFacility]: "Medicare by Facility",
  [AnalyticsTypeWithoutResolution.missingMedicareRate]: "Missing Medicare Rate",
  [AnalyticsTypeWithoutResolution.missingMedicareRateByFacility]:
    "Missing Medicare Rate by Facility",
  [AnalyticsTypeWithoutResolution.OBRARate]: "OBRA Rate",
  [AnalyticsTypeWithoutResolution.payerSourceAge65_75]:
    "Payer Source for Age 65-75",
  [AnalyticsTypeWithoutResolution.payerSourceAge65_75ByCountry]:
    "Payer Source for Age 65-75 by Country",
  [AnalyticsTypeWithoutResolution.payerSourceAge75_85]:
    "Payer Source for Age 75-85",
  [AnalyticsTypeWithoutResolution.payerSourceAge75_85ByCountry]:
    "Payer Source for Age 75-85 by Country",
  [AnalyticsTypeWithoutResolution.payerSourceAge85]:
    "Payer Source for Age above 85",
  [AnalyticsTypeWithoutResolution.payerSourceAge85ByCountry]:
    "Payer Source for Age above 85 by Country",
  [AnalyticsTypeWithoutResolution.readmissionRate]: "Readmission Rate",
  [AnalyticsTypeWithoutResolution.readmissionRateByFacility]:
    "Readmission Rate by Facility",
  [AnalyticsTypeWithoutResolution.representativeRate]: "Representative Rate",
  [AnalyticsTypeWithoutResolution.representativeRateByFacility]:
    "Representative Rate by Facility",
  [AnalyticsTypeWithoutResolution.timeByStage]: "Time by Stage",

  [AnalyticsTypeWithoutResolution.timeToCloseFromSignatureByFacility]:
    "Time to Close by Facility",
};

export const AnalyticsType = {
  ...AnalyticsTypeWithResolution,
  ...AnalyticsTypeWithoutResolution,
};

export const AnalyticsTypeNames = {
  ...AnalyticsTypeWithResolutionNames,
  ...AnalyticsTypeWithoutResolutionNames,
};

export interface StateWithAnalyticsType<T> {
  [analyticType: string]: T;
}

/*
  With Resolution
*/

export enum Resolution {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export interface IResolution {
  resolution?: Resolution;
}

export type StateWithResolution<T> = {[resolution in Resolution]?: T};

export function isWithResolution(
  path: AnalyticsDataPath | undefined,
): path is AnalyticsDataPathWithResolution {
  if (path === undefined) {
    return false;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    !!path && Object.values(AnalyticsTypeWithResolution).includes(path.type)
  );
}

/*
  With From & To (dates)
*/

export interface IFromTo {
  facilities?: string[];
  from: DateStr;
  to: DateStr;
}

export interface IDateRangeMomentGlobal {
  from: string | null;
  to: string | null;
  readonly id: number;
  label: string;
}

export interface IDateRangeGlobal extends IFromTo {
  readonly id: number;
  label: string;
}

export type StateWithFrom<T> = {[from in DateStr]?: T};
export type StateWithTo<T> = {[to in DateStr]?: T};
export type StateWithFromTo<T> = StateWithFrom<StateWithTo<T>>;

/*
  With Facility
*/

export type StateWithFacility<T> = {[facilityId in Facility]: T};

/*
  Data Path
*/

export interface CommonDataPath {
  facilities?: Facility[];
  type: any;
}

export interface GIDataPath<W extends ReportingContextType, T, Q>
  extends CommonDataPath {
  what: W;
  type: T;
  query: Q;
}

export type AnalyticsDataPathWithoutResolution = GIDataPath<
  ReportingContextType.analyticsWithoutResolution,
  AnalyticsTypeWithoutResolution,
  IFromTo
>;
export type AnalyticsDataPathWithResolution = GIDataPath<
  ReportingContextType.analyticsWithResolution,
  AnalyticsTypeWithResolution,
  IFromTo & IResolution
>;

export type AnalyticsDataPath =
  | AnalyticsDataPathWithResolution
  | AnalyticsDataPathWithoutResolution;

export type DataPath = AnalyticsDataPath;

export type AnyDataPath = AnalyticsDataPath | ReportsDataPath;

export function isAnalyticsDataPath(
  path: AnyDataPath | undefined,
): path is AnalyticsDataPath {
  if (path === undefined) {
    return false; // maybe antipattern?
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return path && Object.values(AnalyticsType).includes(path.type);
}

/*
  State
*/

export type StateWithAnalyticsWithAnalyticsTypeWithReso<T> = {
  [analyticType in AnalyticsTypeWithResolution]?: T;
};

export type StateWithAnalyticsWithAnalyticsTypeWithoutReso<T> = {
  [analyticType in AnalyticsTypeWithoutResolution]?: T;
};

export type ReportingAnalyticsState =
  StateWithAnalyticsWithAnalyticsTypeWithReso<
    StateWithFromTo<StateWithResolution<IReportingAnalyticsDataState>>
  > &
    StateWithAnalyticsWithAnalyticsTypeWithoutReso<
      StateWithFromTo<IReportingAnalyticsDataState>
    >;

export type ReportingState = AppState["reporting"];
/*
  Data
*/

export interface AnalyticSegment {
  [x: string]: any;
  aggregations?: {[aggregationType in AggregationType]?: number};
  data?: MetricValue[];
  nestedSegmentationType?: "NONE" | "FACILITY" | "SLIDE" | "ADMISSION_ON_TIME";
  segmentInfo?: SegmentInfo;
  segments?: AnalyticSegment[];
}

export interface MetricValue {
  date: string;
  value: number;
  id?: string;
  metric?:
    | "TOTAL_ADMISSIONS"
    | "COMPLETION_ON_TIME"
    | "ADMISSIONS_BY_FACILITY_AND_APPROVAL"
    | "COMPLETION_RATE"
    | "DNR_RATE"
    | "ADVANCED_DIRECTIVE_RATE"
    | "RESIDENT_REPRESENTATIVE_RATE"
    | "ARBITRATION_AGREEMENT_RATE"
    | "MANAGED_MEDICAID_RATE"
    | "NOT_MEDICARE_SUPPLEMENT_RATE"
    | "READMISSION_RATE"
    | "OBRA_RATE"
    | "TIME_TO_CLOSE"
    | "FLAGS_BY_SLIDE";
  metricSegment?: MetricSegment;
  resolution?: Resolution;
  sampleSize?: number;
}

interface MetricSegment {
  admissionApprovalStatus?:
    | "APPROVED_ON_TIME"
    | "APPROVED_LATE"
    | "INCOMPLETE_OPEN"
    | "INCOMPLETE_CLOSED";
  facilityId?: Facility;
  slidePosition?: number;
  stateAbbreviation?: string;
  stateName?: string;
}

interface SegmentInfo {
  id?: string;
  name?: string;
  type?: "NONE" | "FACILITY" | "SLIDE" | "ADMISSION_ON_TIME";
}

export interface IReportingAnalyticsDataState {
  data?: ReportingAnalyticsResponse;
  fetching?: boolean;
  success?: boolean;
}

export type ReportingAnalyticsResponse = AnalyticSegment;

/**
 * Data resolver helper
 */

export interface ResolvedResponse {
  success: boolean;
  data?: ReportingAnalyticsResponse;
}

export interface DataResolver {
  (
    query: DataPath,
    cancelTokenSource: CancelTokenSource,
  ): Promise<ResolvedResponse>;
}

export interface DataStatePath {
  dataState: IReportingAnalyticsDataState;
  dataPath: DataPath;
}

export const MAIN_URLS = {
  OVERVIEW: "/admin/reporting",
  GENERAL: "/admin/reporting/general",
  DASHBOARDS: "/admin/reporting/dashboards",
  CHECKBOXES: "/admin/reporting/checkboxes",
  OTHER: "/admin/reporting/other",
};

// TODO: use better approach to store string[] as redux state key
export const getFacilitiesKey = (facilities: Facility[]) =>
  facilities ? facilities.join() : "";

export const getRequestUnitTimestamps = (from: string, to: string) => {
  return {
    from: getTime(parseISO(from)),
    to: getTime(parseISO(to)),
  };
};
