import {saveAs} from "file-saver";
import {http} from "./api";
import Alert from "react-s-alert";

export type FileReference = Readonly<{
  /**
   * UUID.
   */
  id: string;
  /**
   * S3 public link.
   */
  url: string;
}>;

export const FileService = {
  get: (fileId: string) => http.get(`/files/${fileId}`),
  upload: (data: FormData) => http.post<FileReference>("/files", data),
  download : (fileId: string) => http.get<string>(`/files/${fileId}`,
    {
      headers: {
        Accept: "application/vnd.ms-excel",
      }
    },
    {
      responseType: "blob"
    })
};

export const saveExcel = async (fileId: string, fileName: string) => {
  try {
    const {data} = await FileService.download(fileId);
    saveAs(data, fileName);
  } catch {
    Alert.error("Failed to download Excel.");
  }
}
