import React from "react";
import {styled, Button, Text} from "@reside/ui";
import {AdmissionNote} from "@reside/reside-api-admission";

import {Note} from "./Note";
import {CloseButton} from "./CloseButton";

import "./NotesList.scss";

export const NotesList = ({
  notes,
  slideIndex,
  onClose,
  onRemoveNote,
  onAddNote,
}: {
  notes: ReadonlyArray<AdmissionNote>;
  slideIndex: number;
  onClose: () => void;
  onRemoveNote: (id: string) => Promise<void>;
  onAddNote: () => void;
}) => (
  <div className="noteslist-0-53" data-test-id={`notes-${slideIndex}`}>
    <CloseButton onClose={onClose} />
    <NoteList>
      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div key={index} className="noteslist-0-52">
            <Note note={note} index={index} onRemove={onRemoveNote} />
          </div>
        ))
      ) : (
        <Text fontWeight="medium">No Notes</Text>
      )}
    </NoteList>
    <Button
      color="primary"
      onClick={onAddNote}
      className="noteslist-0-54"
      testId="addNote"
    >
      Add Note
    </Button>
  </div>
);

const NoteList = styled.div`
  max-height: 400px;
  overflow-y: scroll;

  & *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  & *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
