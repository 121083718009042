import {AdmissionStatusTransition} from "@reside/reside-api-admission";

export function mapTransitionStatus(
  status: AdmissionStatusTransition.StatusEnum,
): string {
  switch (status) {
    case "PRE_FLIGHT":
      return "Pre-Flight";
    case "IN_PROGRESS":
      return "In Progress";
    case "CANCELLED":
      return "Deleted";
    case "ARCHIVED":
      return "Archived";
    case "SIGNED":
      return "Signed";
    case "APPROVED":
      return "Approved";
    case "INCOMPLETE":
      return "Incomplete";
    default:
      return "N/A";
  }
}
