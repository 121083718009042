import React from "react";
import {theme, Icon} from "@reside/ui";

import {SortDirection} from "../../../../store/reporting-reports/reporting-reports.common";

type Props = Readonly<{
  sortDirection: SortDirection;
  sortColumn: string;
  thisColumn: string;
}>;

export const SortIndicator = ({
  sortDirection,
  thisColumn,
  sortColumn,
}: Props) => {
  const iconName =
    thisColumn !== sortColumn
      ? "arrows"
      : sortDirectionToIconName(sortDirection);

  return iconName ? (
    <Icon name={iconName} color={theme.color.gray100} size={9} />
  ) : null;
};

const sortDirectionToIconName = (sortDirection?: SortDirection) => {
  switch (sortDirection) {
    case SortDirection.DESC:
      return "sort-down";
    case SortDirection.ASC:
      return "sort-up";
    default:
      return undefined;
  }
};
