import {useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";

/** Use to debounce textaul input fields */
export const useDebouncedPrimitiveValue = (value: string, timeout = 1000) => {
  const [debounced, setDebounced] = useState(value);

  const debounceValue = useMemo(
    () => debounce(value => setDebounced(value), timeout),
    [setDebounced, timeout],
  );

  useEffect(() => {
    debounceValue(value);
  }, [value, debounceValue]);

  return debounced;
};
