var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { get } from "lodash";
import { renderToString } from "react-dom/server";
import { isQueryChoicesNode, isStaticChoicesNode, } from "@reside/forms";
import { makeFormattedRenderer } from "../helpers";
export var getChoices = function (reference, queryVariables, translateLabel) {
    // TODO: the hack guard messes with the next guards in if below, Typescript issue, so we cast to ANY
    var hackReference = reference;
    if (isQueryChoicesNode(hackReference)) {
        return get(queryVariables, hackReference.query.path, []);
    }
    if (isStaticChoicesNode(reference)) {
        var _a = reference.choices, choices = _a === void 0 ? [] : _a;
        return choices.map(function (choice) { return ({
            value: choice.id,
            label: translateLabel(choice.translationKey),
            children: choice.children,
        }); });
    }
};
// LIMITATION: query can be max 1 level deep
export var getCustomOptionLabel = function (reference, option, translate) {
    var _a;
    var id = option.id, labelFields = __rest(option, ["id"]);
    var labelPattern = reference.optionLabel
        ? translate(reference.optionLabel.translationKey)
        : Object.keys(labelFields)
            .map(function (key) { return "[[query.".concat(reference.query.path, ".").concat(key, "]]"); })
            .join(" ");
    var FormattedRenderer = makeFormattedRenderer({
        query: (_a = {},
            _a[reference.query.path] = option,
            _a),
    });
    var htmlString = renderToString(React.createElement(FormattedRenderer, { translation: labelPattern }));
    var _b = htmlString.match(RegExp('<span data-reactroot="">(.*?)</span>')), label = _b[1];
    return label;
};
// Return as an element to enable markdown syntax (italic, bold, etc)
export var getCustomOptionLabelAsElement = function (reference, option, translate) {
    var _a;
    var id = option.id, labelFields = __rest(option, ["id"]);
    var labelPattern = reference.optionLabel
        ? translate(reference.optionLabel.translationKey)
        : Object.keys(labelFields)
            .map(function (key) { return "[[query.".concat(reference.query.path, ".").concat(key, "]]"); })
            .join(" ");
    var FormattedRenderer = makeFormattedRenderer({
        query: (_a = {},
            _a[reference.query.path] = option,
            _a),
    });
    return React.createElement(FormattedRenderer, { translation: labelPattern });
};
