import React from "react";
import Alert from "react-s-alert";
import {RouteComponentProps} from "react-router-dom";
import {UserDto, CreateStaffUserDto} from "@reside/reside-api-app";
import {useMutation} from "react-query";
import {FormCard, Text} from "@reside/ui";

import {DocumentTitle} from "../../atoms/document-title";
import {AdminLayout} from "../../atoms/admin-layout";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {UserService} from "../../services/UserService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";
import {UserFormHeader} from "./atoms/UserFormHeader";
import {UserFormik} from "./atoms/UserFormik";
import {useUserQuery} from "./hooks/useUserQuery";
import {Spinner} from "../../atoms/spinner";
import {PageAdmin404} from "../page-admin-404";
import {DeleteUserButton} from "./atoms/DeleteUserButton";
import {ResetPasswordButton} from "../../atoms/reset-password-button";
import {ResendActivationEmailButton} from "../../atoms/resend-activation-email-button";

type Props = RouteComponentProps<{userId: string}>;

export const PageAdminUserEdit = ({match, history}: Props) => {
  const {userId} = match.params;

  const {data: user, isFetching: isFetchingUser} = useUserQuery({userId});

  const {mutate: updateUser} = useMutation(
    (data: CreateStaffUserDto) => UserService.update(user.id, data),
    {
      onSuccess: () => {
        Alert.success("User was successfully updated.");
        history.push("/admin/users");
      },
      onError: async (resp: any) => {
        Alert.error(
          await buildResponseErrorMessage(
            resp,
            "An error occurs when updating user.",
          ),
        );
      },
    },
  );

  if (isFetchingUser) {
    return (
      <AdminLayout>
        <Spinner />
      </AdminLayout>
    );
  }

  if (!user) {
    return (
      <AdminLayout>
        <PageAdmin404 />
      </AdminLayout>
    );
  }

  return (
    <>
      <DocumentTitle
        title={`Reside - Admin - Edit User - ${user.firstName} ${user.lastName}`}
      />
      <AdminLayout>
        <UserFormik
          initialValues={getInitialValues(user)}
          onSubmit={updateUser}
          userFormHeader={
            <UserFormHeader
              title={<AdminPageTitle>Edit User</AdminPageTitle>}
            />
          }
          resetPasswordButton={
            user.status === UserDto.StatusEnum.ACTIVE &&
            user.email && <ResetPasswordButton email={user.email} />
          }
          resendActivationEmailButton={
            user.status === UserDto.StatusEnum.NEW && (
              <ResendActivationEmailButton userId={user.id} />
            )
          }
          deleteUserButton={
            user.status !== UserDto.StatusEnum.DELETED && (
              <FormCard title="Delete user">
                <Text>This action can&apos;t be undone.</Text>
                <DeleteUserButton />
              </FormCard>
            )
          }
        />
      </AdminLayout>
    </>
  );
};

const getInitialValues = ({
  firstName,
  lastName,
  phone,
  email,
  role,
  type,
  organization,
  facilities = [],
}: UserDto): CreateStaffUserDto => ({
  firstName,
  lastName,
  phone,
  email,
  roleId: role.id,
  type,
  organizationId: organization?.id ?? "",
  facilities: facilities.map(({id}) => id),
});
