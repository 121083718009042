import React, {ReactElement} from "react";
import {styled, Box} from "@reside/ui";

type Props = Readonly<{
  facilitySelector: ReactElement;
  dateSelector: ReactElement;
}>;

export const ReportingActionRow = ({facilitySelector, dateSelector}: Props) => (
  <Wrapper>
    <Box flexDirection="row" flex="2" alignItems="center">
      {facilitySelector}
    </Box>
    <Box
      className="date-selector"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {dateSelector}
    </Box>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: stretch;
  place-content: stretch flex-start;
  margin-bottom: 15px;
  padding: 20px;
  border: 1px solid ${({theme}) => theme.color.gray10};
  background: ${({theme}) => theme.color.white};

  .facility-dropdown-tree-select {
    .trigger {
      font-size: 18px;
    }

    .rc-tree {
      overflow-y: scroll;
    }
  }
`;
