import React, {useState, useMemo, useCallback, FormEvent} from "react";
import {css, cx} from "@emotion/css";
import {flatMap} from "lodash";
import RcTree from "rc-tree";
import {DataNode} from "rc-tree/lib/interface";
import {theme, Icon, ThinScrollbar} from "@reside/ui";

import {GeneralSearch} from "../search-box";

import "./TreeSelect.scss";

export type Props = Readonly<{
  defaultSearchValue?: string;
  checkedKeys: ReadonlyArray<string>;
  treeData: ReadonlyArray<DataNode>;
  EmptySearch?: () => JSX.Element;
  searchPlaceholder?: string;
  onCheck: (checkedKeys: string[], checkedLeafKeys: string[]) => void;
  hasOnlyOne?: boolean;
  autoComplete?: string;
}>;

export const TreeSelect = ({
  defaultSearchValue,
  checkedKeys,
  treeData,
  EmptySearch,
  searchPlaceholder,
  onCheck,
  hasOnlyOne,
  autoComplete,
}: Props) => {
  const [searchValue, handleSearch] = useState(defaultSearchValue);
  const isSearchEmpty = searchValue === "";
  const filterValue = searchValue.toLowerCase();
  const nodes = useMemo(
    () => flatMap(treeData, node => [node, ...node.children]),
    [treeData],
  );
  const allKeys = useMemo(() => nodes.map(({key}) => key), [nodes]);
  const allLeafKeys = useMemo(
    () => allKeys.filter(key => !treeData.find(node => node.key === key)),
    [allKeys, treeData],
  );

  const filterKeys = useMemo(
    () =>
      isSearchEmpty
        ? []
        : nodes
            .filter(({title}) => title.toLowerCase().includes(filterValue))
            .map(({key}) => key),
    [nodes, filterValue, isSearchEmpty],
  );
  const handleSelectAll = useCallback(() => {
    onCheck(
      isSearchEmpty ? allKeys : filterKeys,
      isSearchEmpty ? allLeafKeys : filterKeys,
    );
  }, [onCheck, allKeys, allLeafKeys, filterKeys, isSearchEmpty]);

  const handleClear = useCallback(() => {
    onCheck([], []);
  }, [onCheck]);

  return (
    <div className={cx(s.treeSelect, {"single-select-mode": hasOnlyOne})}>
      <GeneralSearch
        hideSuggestion
        isSearching={false}
        inputValue={searchValue}
        showClearButton={!isSearchEmpty}
        onInputChange={(event: FormEvent<HTMLInputElement>) =>
          handleSearch(event.currentTarget.value)
        }
        onPressSpace={(event: FormEvent<HTMLInputElement>) => {
          if (searchValue.trim().length > 0) handleSearch(searchValue + " ");
        }}
        placeholder={searchPlaceholder}
        onClearClick={() => handleSearch("")}
        autoComplete={autoComplete}
      />
      {!hasOnlyOne && (
        <div className={s.macroActions}>
          <span onClick={handleSelectAll}>Select All</span> |{" "}
          <span onClick={handleClear}>Clear</span>
        </div>
      )}
      {!isSearchEmpty && filterKeys.length === 0 && (
        <div className={s.emptySearch}>
          <EmptySearch />
        </div>
      )}
      <ThinScrollbar>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
      // @ts-expect-error */}
        <RcTree
          multiple={!hasOnlyOne}
          autoExpandParent
          checkable={
            !hasOnlyOne && <Icon name="check" color="white" size={12} />
          }
          treeData={treeData as DataNode[]}
          selectable={hasOnlyOne}
          onSelect={
            hasOnlyOne &&
            (checkedKeys => {
              // We exclude the parent items, so only leaf items are selectable
              const checkedLeafKeys = checkedKeys.filter(checkedKey =>
                allLeafKeys.includes(checkedKey as string),
              );

              // When already selected item is clicked, it's unselected (the checkedLeafKeys is empty)
              // so we ignore the unselect as the tree select is in single-select mode
              if (checkedLeafKeys.length) {
                onCheck(undefined, checkedLeafKeys as string[]);
              }
            })
          }
          selectedKeys={hasOnlyOne ? checkedKeys : []}
          expandedKeys={isSearchEmpty ? allKeys : filterKeys}
          checkedKeys={hasOnlyOne ? undefined : checkedKeys}
          filterTreeNode={({props: {eventKey}}) =>
            !!(searchValue && eventKey.includes(searchValue))
          }
          onCheck={checkedKeys => {
            if (checkedKeys instanceof Array) {
              // When there is only one ORG, parent is hidden and checkable set to false
              // When all children are unchecked, parent won't change state and still stays in checkedKeys
              // So we filter out everything that is not checkable
              const checkedWithoutUncheckable = checkedKeys.filter(
                checkedKey => {
                  return (
                    (treeData.find(data => data.key === checkedKey) as any)
                      ?.checkable !== false
                  );
                },
              );

              onCheck(
                hasOnlyOne ? [] : (checkedWithoutUncheckable as string[]),
                checkedWithoutUncheckable.filter(checkedKey =>
                  allLeafKeys.includes(checkedKey as string),
                ) as string[],
              );
            }
          }}
        />
      </ThinScrollbar>
    </div>
  );
};

TreeSelect.defaultProps = {
  defaultSearchValue: "",
  searchPlaceholder: "Search...",
  EmptySearch: () => <span>No matching items</span>,
};

const s = {
  treeSelect: css`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.single-select-mode {
      .rc-tree-treenode-switcher-open:only-child {
        .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
          display: none;
        }
      }

      .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
        text-transform: uppercase;
        margin-left: 24px;
        color: ${theme.color.gray100};
      }

      .rc-tree-child-tree {
        .rc-tree-treenode-switcher-open:hover {
          cursor: pointer;
          color: ${theme.color.darkBlue70};
        }
      }

      .rc-tree-node-selected {
        color: ${theme.color.primary};
      }
    }
  `,
  macroActions: css`
    color: ${theme.color.darkBlue10};
    margin: 8px;
    margin-top: 16px;

    span {
      color: ${theme.color.primary};

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  `,
  emptySearch: css`
    margin: 32px 24px;
    text-align: center;
  `,
};
