import React, {memo} from "react";
import {Box} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";

import {LineChart} from "../../../atoms/admin-reporting/atoms/line-chart";
import {
  AggregationAdmissionsInTotal,
  AggregationAdmissionsRepresentativeRate,
} from "../Aggregations";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
  Resolution,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  lineGraphData,
  withCompare,
} from "./helpers";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsAdmissionsTotalChart = memo(({data}: ChartProps) => (
  <LineChart data={lineGraphData(data)} yFormat={y => `${y}`} />
));

export const ADMISSIONS_TOTAL_CHART_ID = "admissions-total" as const;

export const AnalyticsAdmissionsTotal = (props: Props) => (
  <>
    <Box flexDirection="row" flexWrap="wrap" marginBottom="40px">
      <AggregationAdmissionsInTotal
        dateRange={props.dateRange}
        facilities={props.facilities}
        compareDateRange={props.dateRange}
      />
      <AggregationAdmissionsRepresentativeRate
        dateRange={props.dateRange}
        facilities={props.facilities}
        compareDateRange={props.dateRange}
      />
    </Box>
    <RequiredUserPermission
      permission={TableUserDto.PermissionsEnum.APPROVED_ARCHIVED_ADMISSIONS}
    >
      <div className="chart-header">
        <CardTitle
          small
          title="Total Number Of Approved Admissions Over Time"
        />
        <CompareDateLabelsWrapper>
          <CompareDateLabel dateRange={props.dateRange} global />
        </CompareDateLabelsWrapper>
      </div>
      <ReportingContext
        dataPaths={withCompare({
          what: ReportingContextType.analyticsWithResolution,
          type: AnalyticsType.approvedAndArchivedAdmissions,
          facilities: props.facilities,
          query: {...props.dateRange, resolution: Resolution.DAY},
        })}
        onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
        isFullReport={props.reportingBlockSetters.isFullReport}
        enabled={props.reportingBlockSetters.enabled}
      >
        {(data, isFullReport) => (
          <AnalyticsAdmissionsTotalChart
            data={data}
            isFullReport={isFullReport}
          />
        )}
      </ReportingContext>
    </RequiredUserPermission>
  </>
);
