import React from "react";
import {styled, theme, Text, Icon} from "@reside/ui";

type Props = Readonly<{
  /**
   * Number of active flags.
   */
  count: number;
  /**
   * Toggle active visual when the flag is clicked, opened or focused.
   */
  active?: boolean;
}>;

export const FlagCountIcon = ({count, active}: Props) => {
  const hasFlags = count > 0;

  return (
    <FlagContainer hasFlags={hasFlags}>
      {hasFlags && (
        <IconText color="white" fontSize={1} fontWeight="medium">
          {count}
        </IconText>
      )}
      <Icon
        name={hasFlags ? "flag" : "flag-outlined"}
        size={20}
        color={
          hasFlags
            ? active
              ? theme.color.darkBlue100
              : theme.color.pink100
            : theme.color.gray100
        }
      />
    </FlagContainer>
  );
};

FlagCountIcon.defaultProps = {
  active: false,
};

const FlagContainer = styled.div<{hasFlags: boolean}>`
  display: inline-block;
  position: relative;
  cursor: ${({hasFlags}) => (hasFlags ? "pointer" : "default")};

  .icon {
    margin-bottom: 5px;

    &:before {
      margin: 0;
    }
  }
`;

const IconText = styled(Text)`
  position: absolute;
  text-align: center;
  width: 100%;
  padding-top: 3px;
  user-select: none;
`;
