import React from "react";
import {FormGrid, ThinScrollbar} from "@reside/ui";
import {StyledScrollableErrorArea} from "../../page-admin-hub-and-spoke/atoms/styled";

/** This component exists to overcome deepsource - "Validation of JSX maximum depth
JS-0415" rule*/
export const ResidentFormWrap = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  return (
    <ThinScrollbar>
      <StyledScrollableErrorArea>
        <FormGrid>{children}</FormGrid>
      </StyledScrollableErrorArea>
    </ThinScrollbar>
  );
};
