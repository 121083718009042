import React from "react";
import {Button} from "@reside/ui";

import {ExternalLinkStyled} from "./styles";
import {templateUrl} from "../../../utils";
import {useFacilitySettingsParams} from "../../../hooks/useFacilitySettingsParams";

export const DownloadButton = ({
  facilityTemplate,
  label,
}: {
  facilityTemplate: string;
  label: string;
}): JSX.Element => {
  const {facilityId} = useFacilitySettingsParams();
  return (
    <ExternalLinkStyled href={templateUrl(facilityId, facilityTemplate)}>
      <Button color="primary" outline>
        {label}
      </Button>
    </ExternalLinkStyled>
  );
};
