import {styled, NavLink} from "@reside/ui";
import {useRouteMatch} from "react-router-dom";

export const SubMenu = () => {
  const match = useRouteMatch();

  return (
    <Menu>
      <NavLink to={`${match.path}/sftp-configurations`}>
        SFTP Configurations
      </NavLink>
      <NavLink to={`${match.path}/template-fields`}>Template Fields</NavLink>
      <NavLink to={`${match.path}/event-triggers`}>Event Triggers</NavLink>
    </Menu>
  );
};

const Menu = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.color.white};
  padding: ${({theme}) => `${theme.space[4]}px ${theme.space[4]}px`};
  position: fixed;
  top: 70px;
  height: 60px;
  left: 0;
  text-align: center;
  box-shadow: ${({theme}) => `${theme.color.gray10} 0 2px ${theme.space[1]}px`};
  z-index: 1;
`;
