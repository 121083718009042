import {useQuery} from "react-query";

import {OrganizationsService} from "../../../services/OrganizationsService";
/**
 * @param organizationId
 */
export const useGetOrganizationData = ({
  organizationId,
}: {
  organizationId: string;
}) =>
  useQuery(
    ["useGetOrganizationData", organizationId],
    async ({queryKey}: {queryKey: string[]}) => {
      const {data} = await OrganizationsService.find(queryKey[1]);
      return data;
    },
  );
