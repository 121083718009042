import React from "react";
import Alert from "react-s-alert";
import {Formik} from "formik";
import {useMutation} from "react-query";
import {
  ChecklistItem,
  DocumentUploadCategory,
} from "@reside/reside-api-admission";
import {validator} from "@reside/forms/dist/validator";
import {
  Box,
  Button,
  FileField,
  H1,
  H5,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Paragraph,
  SelectField,
  styled,
  ExternalLink,
  H2,
} from "@reside/ui";

import {PccService} from "../../../../services/PccService";
import {useDocumentUploadCategoriesQuery} from "../../../../models/pcc";
import {Spinner} from "../../../spinner";
import {useDashboardAdmissionsContext} from "../../DashboardAdmissionsContext";
import {ModalName, useDashboardModalContext} from "../../DashboardModalContext";
import {
  AdmissionFromSearch,
  AdmissionsService,
} from "../../../../services/AdmissionsService";
import {getAdmissionResidentName} from "../../helper";
import {AdmissionId} from "../../../dashboard-cell";

type Values = Readonly<{
  documentCategoryId: number;
  file: File;
}>;

export const PccDocumentUploadModalRenderer = ({
  isOpen,
  checklistItem,
  isLoadingPccCategories,
  documentUploadCategories,
  onRequestClose,
  isLoading,
  onSubmit,
  admission,
}: Readonly<{
  isOpen: boolean;
  checklistItem?: ChecklistItem;
  isLoadingPccCategories: boolean;
  documentUploadCategories: readonly DocumentUploadCategory[];
  onRequestClose: () => void;
  isLoading: boolean;
  onSubmit: (values: Values) => void;
  admission: AdmissionFromSearch;
}>) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    {({closeModal}) => (
      <Formik<Values>
        initialValues={{
          documentCategoryId: checklistItem?.pccDocumentCategoryId
            ? checklistItem.pccDocumentCategoryId
            : undefined,
          file: null,
        }}
        validate={values =>
          validator.validateAll(values, {
            documentCategoryId: ["required"],
            file: ["required", "max_file_size:5"],
          })
        }
        onSubmit={onSubmit}
      >
        {({submitForm}) => (
          <>
            <ModalContent>
              <ModalTitle>PointClickCare Document Upload</ModalTitle>
              {admission && <H2>{getAdmissionResidentName(admission)}</H2>}
              {admission && <AdmissionId code={admission.code} />}
              <Spacing />
              {isLoadingPccCategories && (
                <SpinnerWrapper>
                  <Spinner relative />
                </SpinnerWrapper>
              )}
              <SelectField
                name="documentCategoryId"
                label={
                  isLoadingPccCategories
                    ? "Loading, PointClickCare Document Categories"
                    : "PointClickCare Document Category (Choose from list)"
                }
                disabled={isLoadingPccCategories}
                options={documentUploadCategories ?? []}
                getOptionValue={category => category.id as any}
                getOptionLabel={category => category.name}
              />
              <Spacing />
              <FileField
                name="file"
                label="Document Upload"
                title="Select File"
              />
              {isLoading && (
                <Paragraph>
                  Please wait while the file is uploading...
                </Paragraph>
              )}
              {!isLoading && checklistItem?.pccDocumentUploaded && (
                <Paragraph>
                  File was successfully uploaded. You can upload a new file for
                  this checkbox as an addition to existing file or files. The
                  successfully uploaded file can be only removed from
                  PointClickCare.
                </Paragraph>
              )}
              {checklistItem?.pccDocumentLink && (
                <>
                  <H5>Uploaded File</H5>
                  <ExternalLink
                    href={checklistItem.pccDocumentLink}
                    title={`Uploaded File ${checklistItem.pccDocumentLink}`}
                  >
                    {`Link to '${
                      checklistItem.pccDocumentName || "file"
                    }' in PointClickCare`}
                  </ExternalLink>
                </>
              )}
            </ModalContent>
            <ModalFooterButtons>
              <Box
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  outline
                  color="primary"
                  disabled={isLoading}
                  onClick={closeModal}
                  testId="closePccUploadModalButton"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="success"
                  testId="uploadChecklistItemDocumentToPccButton"
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  {isLoading ? "Uploading..." : "Upload"}
                </Button>
              </Box>
            </ModalFooterButtons>
          </>
        )}
      </Formik>
    )}
  </Modal>
);

export const PccDocumentUploadModal = ({
  admission,
  checklistItem,
  onRequestClose,
}: {
  admission: AdmissionFromSearch;
  checklistItem?: ChecklistItem;
  onRequestClose: () => void;
}) => {
  const isOpen = !!checklistItem;

  const {setAdmission} = useDashboardModalContext(ModalName.APPROVE);

  const {updateAdmission, clearAdmissionChecklistErrors} =
    useDashboardAdmissionsContext();

  const {data: documentUploadCategories, isLoading: isLoadingPccCategories} =
    useDocumentUploadCategoriesQuery({
      facilityId: admission?.facilityId ?? "",
      enabled: isOpen,
    });

  const {mutate, isLoading} = useMutation(
    async ({documentCategoryId, file}: Values) => {
      const {data: admissionResponse} =
        await PccService.postChecklistItemDocumentToPcc(
          admission.id,
          checklistItem?.id,
          documentCategoryId,
          file,
        );

      return admissionResponse;
    },
    {
      onSuccess: updatedAdmisison => {
        updateAdmission({admission: updatedAdmisison});
        setAdmission(updatedAdmisison as any);

        AdmissionsService.patchAdmissionChecklistItem({
          admissionId: admission.id,
          checklistItemId: checklistItem.id,
          data: updatedAdmisison,
        });

        Alert.success("File Successfully Uploaded to PointClickCare.");

        clearAdmissionChecklistErrors(admission.id, checklistItem.id);
        onRequestClose();
      },
      onError: async (resp: any) => {
        if (resp.status === 409) {
          Alert.error(
            "File with the same name already exists in PointClickCare. Please select a different file or rename your file.",
          );
        } else {
          Alert.error("File Upload to PointClickCare Failed.");
        }
      },
    },
  );

  return (
    <PccDocumentUploadModalRenderer
      checklistItem={checklistItem}
      isOpen={isOpen}
      documentUploadCategories={documentUploadCategories}
      isLoadingPccCategories={isLoadingPccCategories}
      onSubmit={mutate}
      isLoading={isLoading}
      onRequestClose={onRequestClose}
      admission={admission}
    />
  );
};

const SpinnerWrapper = styled.div`
  margin: auto;
  display: block;
  top: 10px;
  height: 0;
`;

const Spacing = styled.div`
  height: 20px;
`;

const ModalTitle = styled(H1)`
  margin-bottom: 0;
`;
