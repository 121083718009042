import React from "react";
import {useMutation} from "react-query";
import {Button} from "@reside/ui";
import Alert from "react-s-alert";

import {UserService} from "../../services/UserService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";

export const ResendActivationEmailButton = ({userId}: {userId: string}) => {
  const {mutate, isLoading} = useMutation(UserService.resendActivationEmail, {
    onSuccess: () => {
      Alert.success("Activation email has been resent.");
    },
    onError: async (resp: any) => {
      Alert.error(
        await buildResponseErrorMessage(
          resp,
          "An error occurs when sending an activation email.",
        ),
      );
    },
  });

  return (
    <Button
      style={{marginTop: "24px"}}
      onClick={() => mutate(userId)}
      testId="sendActivationEmail"
      color="primary"
      outline
      disabled={isLoading}
    >
      Send Activation Email
    </Button>
  );
};
