import {styled, H1} from "@reside/ui";
import {HorizontallyAlignedNode} from "@reside/forms";

export const Title = styled(H1)<HorizontallyAlignedNode>`
  font-size: 2.625em;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: ${({horizontalAlignment}) => horizontalAlignment};
`;

export const Section = styled.div`
  margin-bottom: 40px;
`;

export const Strong = styled.strong`
  font-weight: ${({theme}) => theme.fontWeight.heavy};
`;
