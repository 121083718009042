var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment } from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { FlexRow } from "../../box";
import { RadioItem } from "../atoms/radio-item";
import { FieldError } from "../atoms/field-error";
import { FieldLabel } from "../atoms/FieldLabel";
import { LockIcon } from "../atoms/LockIcon";
import { OptionItem, OptionItemChildren } from "../atoms/option-item";
export var RadioGroupField = function (_a) {
    var disabled = _a.disabled, label = _a.label, name = _a.name, options = _a.options, renderOption = _a.renderOption, onChange = _a.onChange, OptionChildren = _a.OptionChildren, getOptionLabel = _a.getOptionLabel, getOptionValue = _a.getOptionValue, getOptionKey = _a.getOptionKey, isChecked = _a.isChecked, getRawOptionValue = _a.getRawOptionValue, FormField = _a.FormField;
    return (React.createElement(StyledBlock, null,
        label && React.createElement(GroupLabel, { disabled: disabled }, label),
        React.createElement(FieldError, { name: name }),
        React.createElement(FlexRow, null,
            React.createElement(FormField, { name: name }, function (_a) {
                var form = _a.form, field = _a.field;
                return (React.createElement(OptionItem, null, options.map(function (option, index) {
                    var checked = isChecked(option, field.value);
                    var element = (React.createElement(RadioItem, __assign({}, field, { value: getOptionValue(option), label: getOptionLabel(option), disabled: disabled || option.disabled, checked: checked, onChange: function (event) {
                            field.onBlur(event);
                            form.setFieldValue(name, getRawOptionValue(option), true);
                            onChange(event);
                        }, testId: "radio-".concat(name, "-").concat(getOptionValue(option)) }), checked && option.children && (React.createElement(OptionItemChildren, null, OptionChildren({
                        children: option.children,
                        index: index,
                    })))));
                    return (React.createElement(Fragment, { key: getOptionKey(option, name) }, renderOption(element, option, index)));
                })));
            }),
            disabled && React.createElement(LockIcon, null))));
};
RadioGroupField.defaultProps = {
    renderOption: function (element) { return element; },
    onChange: function () { },
    getOptionLabel: function (option) { return option.label; },
    getOptionValue: function (option) { return "".concat(option.value); },
    getOptionKey: function (option, name) {
        return "".concat(name, ".").concat(option.value);
    },
    isChecked: function (option, fieldValue) {
        return fieldValue === option.value;
    },
    getRawOptionValue: function (option) { return option.value; },
    FormField: Field,
};
var GroupLabel = styled(FieldLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 0.625em;\n  word-break: break-word;\n\n  &.disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"], ["\n  margin-bottom: 0.625em;\n  word-break: break-word;\n\n  &.disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"])));
var StyledBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2;
