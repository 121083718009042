import React from "react";
import {Button, FormCard} from "@reside/ui";
import {Formik} from "formik";
import {EventTriggerForm} from "../../services/HubAndSpokeService";
import {EventTriggersFormFields} from "./EventTriggersFormFields";
import {useCreateEventTrigger} from "../../services/hooks/useCreateEventTrigger";
import {FormWrapper} from "./styles";
import {generatePathPreview, validate} from "./helpers";
import {useTestUpload} from "../../services/hooks/useTestUpload";

const DOCUMENT_PATH =
  "{facility-name}/example_form_{current-date}-{resident-name}.pdf";

export const EventTriggerCreate = () => {
  const {mutate: createEventTrigger, isLoading: isCreating} =
    useCreateEventTrigger();

  const {mutate: testUpload} = useTestUpload();

  return (
    <FormWrapper>
      <FormCard title="Create Event Trigger">
        <Formik<EventTriggerForm>
          enableReinitialize
          initialValues={{
            templateName: "",
            document: "",
            template_field_id: "",
            facilities: [],
            sftp_config_id: "",
            document_path: DOCUMENT_PATH,
          }}
          // https://github.com/jaredpalmer/formik/issues/2675
          onSubmit={() => {
            return;
          }}
          validate={validate}
        >
          {({values}) => (
            <>
              <Button
                style={{position: "absolute", top: "65px", right: "50px"}}
                color="primary"
                onClick={() =>
                  testUpload({
                    sftp_config_id: values.sftp_config_id,
                    target_path: generatePathPreview(values.document_path),
                  })
                }
              >
                Test upload
              </Button>
              <EventTriggersFormFields
                isLoading={isCreating}
                onSubmit={values => createEventTrigger(values)}
              />
            </>
          )}
        </Formik>
      </FormCard>
    </FormWrapper>
  );
};
