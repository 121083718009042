import React, {memo} from "react";
import {get} from "lodash";
import {TableUserDto} from "@reside/reside-api-app";

import {BarChart} from "../../../atoms/admin-reporting/atoms/bar-chart";
import {
  AggregationType,
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContext} from "../ReportingContext";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsPercentageByFacility,
  numberToPercent,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsManagedMedicaidByFacilityChart = memo(
  ({data, isFullReport}: ChartProps) => {
    const bardata = dataProcess_AnalyticsPercentageByFacility(data);

    return (
      <BarChart
        data={bardata.stack}
        max={bardata.max}
        labelLeft="Facility"
        labelRight="Percentage"
        full={isFullReport}
        itemsPerRow={1}
        dataLegend={[
          {name: "Residents with Medicaid"},
          {name: "Residents without Medicaid"},
        ]}
        axTickFormat={v => numberToPercent(v)}
        hoverDataPreprocessor={(data, line, index) => {
          const sum = get(
            line.original[index].aggregations,
            AggregationType.SUM,
            0,
          );
          const count = get(
            line.original[index].aggregations,
            AggregationType.COUNT,
            0,
          );

          return [sum, count - sum];
        }}
      />
    );
  },
);

export const MANAGED_MEDICAID_BY_FACILITY_CHART_ID =
  "managed-medicaid-by-facility" as const;

export const AnalyticsManagedMedicaidByFacility = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID}
  >
    <div className="chart-header">
      <CardTitle
        title="Percent of residents with Medicaid"
        subtitle="by Facility"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.managedMedicaidRateByFacility,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsManagedMedicaidByFacilityChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
