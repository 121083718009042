import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  CompareDateLabels,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportFacility,
  ReportsType,
  SortField,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  DateReportsCell,
  TextCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";

export const FACILITY_CHANGES_REPORT_ID = "facility-changes" as const;

export const ReportFacilityChanges = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.changeDate,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle
          title="Facility Settings: at Corporate Admin level: ability to track changes & the originators of these changes"
          subtitle="by Facility"
        />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>

      <RequiredUserPermission
        permission={TableUserDto.PermissionsEnum.FACILITY_CHANGES}
      >
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.facilityChanges,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled={props.reportingBlockSetters.enabled}
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <ListTableReporting<ReportFacility>
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Settings Changes found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportFacility>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.name}
                    </TextCellEllipsis>
                  )}
                  label="Facility"
                  sortKey={SortField.name}
                  width={26}
                />

                <Column<ReportFacility>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.variableName}
                    </TextCellEllipsis>
                  )}
                  label="Variable Name"
                  width={15}
                  sortKey={SortField.variableName}
                />
                <Column<ReportFacility>
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.variableValue}</TextCell>
                  )}
                  label="Value"
                  width={12}
                />
                <Column<ReportFacility>
                  cellRenderer={row => (
                    <TextCell small>{row.rowData.changeAction}</TextCell>
                  )}
                  label="Action"
                  width={12}
                  sortKey={SortField.changeAction}
                />
                <Column<ReportFacility>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.changeOriginator}
                    </TextCellEllipsis>
                  )}
                  label="User"
                  width={19}
                  sortKey={SortField.changeOriginator}
                />
                <Column<ReportFacility>
                  cellRenderer={row => (
                    <DateReportsCell data={row.rowData.changeDate} />
                  )}
                  label="Date of Change"
                  width={16}
                  sortKey={SortField.changeDate}
                />
              </ListTableReporting>
              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
