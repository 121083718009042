import React from "react";
import {Trans} from "@lingui/macro";
import {styled, Text} from "@reside/ui";

import {NavigationButtons} from "./atoms/navigation-buttons";
import {ProgressBar} from "../progress-bar";
import {AdmissionContextConsumer} from "../AdmissionContext";
import {TocControlTrigger} from "../toc-control";
import {TextSizeControl} from "../text-size-control";
import {HelpControlTrigger} from "../help-control";

export const NavbarTop = () => (
  <AdmissionContextConsumer>
    {({
      currentSlide,
      draft,
      uiState: {slideIdx, textSize},
      uiActions: {setTextSize, openToc, openFlagUi},
    }) => (
      <Navbar fullscreen={currentSlide.state.fullscreen}>
        <Flex fullscreen={currentSlide.state.fullscreen}>
          {!currentSlide.state.fullscreen && (
            <>
              <HideOnMobile>
                <NavigationButtons />
              </HideOnMobile>
              <Title textSize={textSize}>
                <Trans id={currentSlide.sectionTitle.translationKey} />
              </Title>
            </>
          )}
          <MobileButtonStyles order={0} textSize={textSize}>
            <TocControlTrigger
              darkBackground={currentSlide.darkBackground}
              onClick={openToc}
            />
          </MobileButtonStyles>
          <HideOnMobile>
            <TextSizeControl
              darkBackground={currentSlide.darkBackground}
              textSize={textSize}
              onChange={setTextSize}
            />
          </HideOnMobile>
          <MobileButtonStyles order={2} textSize={textSize}>
            <HelpControlTrigger
              active={Boolean(currentSlide.state.flag)}
              darkBackground={currentSlide.darkBackground}
              disabled={currentSlide.excludeFromTOC}
              onClick={openFlagUi}
            />
          </MobileButtonStyles>
        </Flex>
        {!currentSlide.state.fullscreen && (
          <ProgressBar current={slideIdx + 1} total={draft.slides.length} />
        )}
      </Navbar>
    )}
  </AdmissionContextConsumer>
);

const Flex = styled.div<{fullscreen: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  align-content: stretch;
  align-self: stretch;
  flex-wrap: nowrap;

  ${({fullscreen, theme}) =>
    fullscreen &&
    `
  @media (min-width: ${theme.breakpoint.lg}px) {
    justify-content: flex-end;
  `}
`;

const Navbar = styled.div<{fullscreen: boolean}>`
  width: 100%;
  position: relative;
  height: 72px;
  color: ${({theme}) => theme.color.darkBlue100};
  background-color: ${({theme}) => theme.color.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;

  ${({fullscreen}) =>
    fullscreen &&
    `
      background-color: transparent;
    `}

  button:disabled {
    &,
    * {
      opacity: 0.4;
      cursor: not-allowed;
      filter: saturate(25%);
    }
  }

  button:not([disabled]) {
    &:hover {
      color: ${({theme}) => theme.color.deepOrange100};
      * {
        color: ${({theme}) => theme.color.deepOrange100};
      }
    }
  }
`;

const Title = styled(Text)<{textSize: number}>`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;

  font-size: ${({textSize}) => textSize * 100}%;
  line-height: 1.5;

  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    order: 1;
    flex-grow: 0;
  }
`;

const HideOnMobile = styled.div`
  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    display: none;
  }
`;

const MobileButtonStyles = styled.div<{order: number; textSize: number}>`
  > button {
    display: flex;
    align-items: center;

    &:hover {
      transition-delay: 100ms;
      > :is(i, span) {
        transition-delay: inherit;
      }
    }

    i {
      font-size: Min(${({textSize}) => textSize * 1.375}rem, 2rem);
    }
    span {
      font-size: Min(${({textSize}) => textSize}rem, 1.45rem);
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoint.lg}px) {
    order: ${({order}) => order};
    padding: 20px;

    span {
      display: none;
    }
  }
`;
