import {Checkbox, styled} from "@reside/ui";

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckboxWrapper = styled.label`
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)<{disabled: boolean}>`
  cursor: ${({disabled}) => (disabled ? "default" : "pointer")};
`;

export const StyledLine = styled.tr`
  border-top: thin solid rgb(235, 237, 240);
  border-bottom: thin solid rgb(235, 237, 240);
`;

export const StyledTd = styled.td<{isParent: boolean}>`
  height: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  color: ${({isParent, theme}) =>
    isParent ? theme.color.gray100 : theme.color.darkBlue100};
  font-weight: ${({isParent, theme}) =>
    isParent ? theme.fontWeight.bold : theme.fontWeight.normal};
  text-transform: ${({isParent}) => (isParent ? "uppercase" : "none")};
`;
