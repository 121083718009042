import {useMutation} from "react-query";
import {ResidentService} from "../../../services/ResidentServices";
import Alert from "react-s-alert";
import {Admission} from "@reside/reside-api-admission";
import {AdmissionFromSearch} from "../../../services/AdmissionsService";
import {useFetchResident} from "./useFetchResident";

export const useLinkResidentToAdmission = (
  toggleModal: (admission?: Admission | AdmissionFromSearch) => void,
) => {
  const {refetch} = useFetchResident();

  return useMutation(
    ({
      admission_id,
      resident_id,
    }: {
      admission_id: string;
      resident_id: string;
    }) => ResidentService.linkResidentToAdmission({admission_id, resident_id}),
    {
      onError: () => {
        Alert.error("Failed to link Admission.");
      },
      onSuccess: () => {
        Alert.success("Admission was successfully linked.");
        refetch();
        toggleModal();
      },
    },
  );
};
