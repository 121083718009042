import React, {FunctionComponent} from "react";
import {validator} from "@reside/forms";
import {Formik} from "formik";
import {UserDto, ProfileUserDto} from "@reside/reside-api-app";

import {readableRole} from "../../../../atoms/list-table/helpers";

export const ProfileFormik: FunctionComponent<{
  initialValues: UserDto;
  onSubmit: (values: ProfileUserDto) => void;
}> = ({children, onSubmit, initialValues, ...props}) => (
  <Formik<ReturnType<typeof buildInitialValues>>
    {...props}
    initialValues={buildInitialValues(initialValues)}
    onSubmit={({role, email, ...user}) => onSubmit(user)}
    validate={values =>
      validator.validateAll(values, {
        firstName: "required",
        lastName: "required",
        phone: "required|phone",
      })
    }
  >
    {children}
  </Formik>
);

export const buildInitialValues = ({
  firstName,
  lastName,
  email,
  phone,
  role,
}: UserDto) => ({
  firstName,
  lastName,
  email,
  phone,
  role: readableRole(role.name as any),
});
