import { useField, useFormikContext } from "formik";
import { createDateString, formatFieldsForPreFill } from "./helpers";
import { useEffect } from "react";
import { isValid } from "date-fns";
/** Handle changes to field in on paste action or when initial values exist */
export var useHandlePreFill = function (name, dayRef, monthRef, yearRef, setDay, setMonth, setYear, disabled) {
    var setFieldValue = useFormikContext().setFieldValue;
    var field = useField(name)[0];
    var preFillFields = function (value, fieldName) {
        var _a = formatFieldsForPreFill(value), formatedMonth = _a.formatedMonth, formatedDay = _a.formatedDay, formatedYear = _a.formatedYear;
        setDay(formatedDay);
        setMonth(formatedMonth);
        setYear(formatedYear);
        dayRef.current.value = formatedDay;
        monthRef.current.value = formatedMonth;
        yearRef.current.value = formatedYear;
        setFieldValue(fieldName, createDateString(formatedMonth, formatedDay, formatedYear));
    };
    useEffect(function () {
        // Fill date with initial values on page load, if they exist.
        preFillFields(field.value, name);
    }, []);
    useEffect(function () {
        // Signing admission will change field value thus update the disabled date field.
        disabled && preFillFields(field.value, name);
    }, [field.value]);
    var onPasteDate = function (e) {
        var pastedDate = new Date(e.clipboardData.getData("text/plain"));
        // MMDDYYYY format
        if (isValid(pastedDate)) {
            preFillFields(pastedDate, name);
        }
    };
    return {
        /** Controll what happens when date is pasted to field. */
        onPasteDate: onPasteDate,
    };
};
