import React from "react";
import {
  Button,
  Box,
  H1,
  Paragraph,
  Modal,
  ModalContent,
  ModalFooterButtons,
} from "@reside/ui";

type Props = Readonly<{
  residentName: string;
  isOpen: boolean;
  isLoading: boolean;
  onRequestClose: () => void;
  onArchive: () => void;
}>;

export const ArchiveModal = ({
  residentName,
  isOpen,
  isLoading,
  onArchive,
  onRequestClose,
}: Props) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    {({closeModal}) => (
      <>
        <ModalContent>
          <H1>Archive Admission {residentName}</H1>
          <Paragraph>
            Do you really wish to archive this admission? The changes are
            irreversible.
          </Paragraph>
        </ModalContent>
        <ModalFooterButtons>
          <Box width="100%" flexDirection="row" justifyContent="space-between">
            <Button color="primary" outline onClick={closeModal}>
              Cancel
            </Button>
            <Button color="danger" disabled={isLoading} onClick={onArchive}>
              {isLoading ? "Archiving..." : "Archive"}
            </Button>
          </Box>
        </ModalFooterButtons>
      </>
    )}
  </Modal>
);
