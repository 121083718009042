import {connect} from "react-redux";
import {Navigation} from "@reside/ui";
import {RoleDto} from "@reside/reside-api-app";
import {Link} from "react-router-dom";
import {NavLogo} from "../nav-logo";
import {select, AppState} from "../../store";
import {Tabs} from "./atoms/Tabs";
import {UserProfileAsLink} from "./atoms/UserProfileAsLink";
import {SignOut} from "./atoms/SignOut";
import {UserProfileAsDropdownMenu} from "./atoms/UserProfileAsDropdownMenu";
import {AnnounceKitWidget} from "../announcekit-widget";
import {StonlyButton} from "../../atoms/stonly/StonlyButton";
import {Icon, MediaWrapper} from "./styles";
import {AdminNavigationHeaderProps} from "./types";
import {AdministrationMenu} from "./atoms/AdministrationMenu";

export const Renderer = ({
  user,
  displayReportingTab,
  firstFacility,
}: AdminNavigationHeaderProps) => {
  const isClerk = user.role.name === RoleDto.NameEnum.CLERK;

  return (
    <Navigation>
      <Link to="/admin/dashboard">
        <NavLogo />
      </Link>
      <Tabs role={user.role.name} displayReportingTab={displayReportingTab} />
      <Icon />
      {!isClerk && (
        <AdministrationMenu
          role={user.role.name}
          firstFacilityId={firstFacility?.id}
        />
      )}
      <AnnounceKitWidget />
      <StonlyButton />
      <MediaWrapper>
        <UserProfileAsLink user={user} />
        <SignOut />
        <UserProfileAsDropdownMenu user={user} />
      </MediaWrapper>
    </Navigation>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: select.adminSession.user(state),
  displayReportingTab: select.adminSession.hasReportingAccess(state),
  firstFacility: select.adminSession.firstFacility(state),
});

export const AdminNavigationHeader = connect(mapStateToProps)(Renderer);
