import React from "react";
import {styled, H1, PasswordField, Card} from "@reside/ui";

export const ChangePasswordForm = () => (
  <Container>
    <H1>Change Password</H1>
    <PasswordField name="oldPassword" label="Current Password" />
    <PasswordField name="password" label="New Password" />
    <PasswordField name="confirmPassword" label="Confirm New Password" />
  </Container>
);

const Container = styled(Card)`
  padding: 50px 45px;
`;
