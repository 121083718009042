import {getSectionItemsPermissions} from "./permissions.helpers";
import {
  ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
  ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
  ADMISSIONS_TIME_BY_STAGE_CHART_ID,
  ADMISSIONS_TOTAL_CHART_ID,
  ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
  ARBITRATION_AGREEMENT_RATE_CHART_ID,
  FACILITY_COMPLETION_RATE_CHART_ID,
  FLAGS_AGE_CHART_ID,
  FLAGS_BY_SLIDE_CHART_ID,
  MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  MANAGED_MEDICAID_CHART_ID,
  PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_65_75_CHART_ID,
  PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_75_85_CHART_ID,
  PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
  PAYER_SOURCE_85_CHART_ID,
  RE_ADMISSION_RATE_CHART_ID,
  REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
  REPRESENTATIVE_RATE_CHART_ID,
  STAFF_COMPLETION_RATE_CHART_ID,
  TIME_TO_CLOSE_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID,
  WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
} from "./analytics";
import {
  AD_HELP_REPORT_ID,
  AD_PROVIDED_REPORT_ID,
  ARBITRATION_AGREEMENT_REPORT_ID,
  FACILITY_CHANGES_REPORT_ID,
  FUNERAL_REPORT_ID,
  INCOME_REPORT_ID,
  INFLUENZA_REPORT_ID,
  LAUNDRY_REPORT_ID,
  LIST_OF_RESIDENTS_REPORT_ID,
  MEDICAID_REQUEST_REPORT_ID,
  MEDICARE_WAIVER_REPORT_ID,
  PHOTOS_REPORT_ID,
  PNEUMOCOCCAL_REPORT_ID,
  SLIDE_INTERACTIONS_REPORT_ID,
  SNFABN_COST_ADMISSION_REPORT_ID,
  STAFF_REPORT_ID,
  SURROGATE_HELP_REPORT_ID,
  SURROGATE_REPORT_ID,
  TRUST_FUND_REPORT_ID,
} from "./reports";
import {MenuItem} from "./reporting-menu/helpers";

export enum ChartTypes {
  analytics = "analytics",
  reports = "reports",
}

export type Section = {
  id: string;
  title: string;
  sectionItems: ReadonlyArray<string>;
};

export const reportingOverview: Section = {
  id: "overview",
  title: "Admission Overview",
  sectionItems: [LIST_OF_RESIDENTS_REPORT_ID],
} as const;

export const reportingCompletionTime: Section = {
  id: "completion-time",
  title: "Completion Time",
  sectionItems: [TIME_TO_CLOSE_BY_FACILITY_CHART_ID],
} as const;

export const reportingPhotoPayerSourceId: Section = {
  id: "photo-payer-source-id",
  title: "Photo/Payer ID",
  sectionItems: [PHOTOS_REPORT_ID],
} as const;

export const reportingOverviewSection: Section = {
  id: "arbitration-agreement",
  title: "Arbitration",
  sectionItems: [ARBITRATION_AGREEMENT_REPORT_ID],
} as const;

export const analyticsResidentsAdmissionSection: Section = {
  id: "admission-section-1",
  title: "Admission",
  sectionItems: [
    ADMISSIONS_TOTAL_CHART_ID,
    ADMISSIONS_TOTAL_RESIDENTS_CHART_ID,
    FACILITY_COMPLETION_RATE_CHART_ID,
    STAFF_COMPLETION_RATE_CHART_ID,
    ADMISSIONS_COMPLETION_ON_TIME_CHART_ID,
    ADMISSIONS_COMPLETION_OVERALL_CHART_ID,
    FLAGS_BY_SLIDE_CHART_ID,
    REPRESENTATIVE_RATE_CHART_ID,
    REPRESENTATIVE_RATE_BY_FACILITY_CHART_ID,
    PAYER_SOURCE_65_75_CHART_ID,
    PAYER_SOURCE_65_75_BY_COUNTRY_CHART_ID,
    PAYER_SOURCE_75_85_CHART_ID,
    PAYER_SOURCE_75_85_BY_COUNTRY_CHART_ID,
    PAYER_SOURCE_85_CHART_ID,
    PAYER_SOURCE_85_BY_COUNTRY_CHART_ID,
    RE_ADMISSION_RATE_CHART_ID,
  ],
} as const;

export const analyticsResidentsArbitrationSection: Section = {
  id: "arbitration-1",
  title: "Arbitration",
  sectionItems: [ARBITRATION_AGREEMENT_RATE_CHART_ID],
} as const;

export const analyticsResidentsMedicaidSection: Section = {
  id: "medicaid-1",
  title: "Medicaid",
  sectionItems: [
    MANAGED_MEDICAID_CHART_ID,
    MANAGED_MEDICAID_BY_FACILITY_CHART_ID,
  ],
} as const;

export const analyticsResidentsMedicareSection: Section = {
  id: "medicare-3",
  title: "Medicare",
  sectionItems: [
    WITHOUT_MEDICARE_SUPPLEMENT_CHART_ID,
    WITHOUT_MEDICARE_SUPPLEMENT_BY_FACILITY_CHART_ID,
  ],
} as const;

export const analyticsStaffAdmissionSection: Section = {
  id: "admission-section-3",
  title: "Admission",
  sectionItems: [FLAGS_AGE_CHART_ID, ADMISSIONS_TIME_BY_STAGE_CHART_ID],
} as const;

export const reportsSlideAnswersAdmissionSection: Section = {
  id: "admission-section-2",
  title: "Admission",
  sectionItems: [SLIDE_INTERACTIONS_REPORT_ID, STAFF_REPORT_ID],
} as const;

export const reportsSlideAnswersTrustFundSection: Section = {
  id: "trust-fund",
  title: "Trust Fund",
  sectionItems: [INCOME_REPORT_ID, TRUST_FUND_REPORT_ID],
} as const;

export const reportsSlideAnswersLaundrySection: Section = {
  id: "laundry",
  title: "Laundry",
  sectionItems: [LAUNDRY_REPORT_ID],
} as const;

export const reportsSlideAnswersVaccinesSection: Section = {
  id: "vaccines",
  title: "Vaccines",
  sectionItems: [PNEUMOCOCCAL_REPORT_ID, INFLUENZA_REPORT_ID],
} as const;

export const reportsSlideAnswersSurrogateSection: Section = {
  id: "surrogate",
  title: "Copy of Surrogate",
  sectionItems: [SURROGATE_REPORT_ID, SURROGATE_HELP_REPORT_ID],
} as const;

export const reportsSlideAnswersAdvancedDirectiveSection: Section = {
  id: "advanced-directive",
  title: "Advanced Directive",
  sectionItems: [AD_PROVIDED_REPORT_ID, AD_HELP_REPORT_ID],
} as const;

export const reportsSlideAnswersFuneralSection: Section = {
  id: "funeral",
  title: "Funeral",
  sectionItems: [FUNERAL_REPORT_ID],
} as const;

export const reportsSlideAnswersMedicareSection: Section = {
  id: "medicare-4",
  title: "Medicare",
  sectionItems: [MEDICARE_WAIVER_REPORT_ID],
} as const;

export const reportsSlideAnswersMedicaidSection: Section = {
  id: "medicaid-2",
  title: "Medicaid",
  sectionItems: [MEDICAID_REQUEST_REPORT_ID],
} as const;

export const reportsStaffAdmissionSection: Section = {
  id: "admission-section-4",
  title: "Admission",
  sectionItems: [SNFABN_COST_ADMISSION_REPORT_ID, FACILITY_CHANGES_REPORT_ID],
} as const;

export const sectionToMenuItem = ({id, title, sectionItems}: Section) => ({
  label: title,
  permissions: getSectionItemsPermissions(sectionItems),
  urlComponent: id,
  sectionItems,
});

export const reportingMenuGeneral: ReadonlyArray<MenuItem> = [
  sectionToMenuItem(reportingOverview),
  sectionToMenuItem(reportingCompletionTime),
  sectionToMenuItem(reportingPhotoPayerSourceId),
  sectionToMenuItem(reportingOverviewSection),
];

export const reportingMenuOther: ReadonlyArray<MenuItem> = [
  sectionToMenuItem(analyticsResidentsAdmissionSection),
  sectionToMenuItem(analyticsResidentsArbitrationSection),
  sectionToMenuItem(analyticsResidentsMedicaidSection),
  sectionToMenuItem(analyticsResidentsMedicareSection),
  sectionToMenuItem(analyticsStaffAdmissionSection),
  sectionToMenuItem(reportsSlideAnswersAdmissionSection),
  sectionToMenuItem(reportsSlideAnswersTrustFundSection),
  sectionToMenuItem(reportsSlideAnswersLaundrySection),
  sectionToMenuItem(reportsSlideAnswersVaccinesSection),
  sectionToMenuItem(reportsSlideAnswersSurrogateSection),
  sectionToMenuItem(reportsSlideAnswersAdvancedDirectiveSection),
  sectionToMenuItem(reportsSlideAnswersFuneralSection),
  sectionToMenuItem(reportsSlideAnswersMedicareSection),
  sectionToMenuItem(reportsSlideAnswersMedicaidSection),
  sectionToMenuItem(reportsStaffAdmissionSection),
];
