var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
import { H1 } from "../text";
import { Card } from "../card";
import { Logo } from "../logo";
import { Link } from "react-router-dom";
export var PublicFormLayout = function (_a) {
    var children = _a.children, title = _a.title, footer = _a.footer;
    return (React.createElement(OffsetTop, null,
        React.createElement(Center, null,
            React.createElement(Link, { to: "/admin", style: { display: "inline-block" } },
                React.createElement(Logo, null))),
        React.createElement(Container, null,
            React.createElement(Center, null,
                React.createElement(H1, null, title)),
            children),
        footer));
};
var OffsetTop = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 5%;\n"], ["\n  padding-top: 5%;\n"])));
var Center = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 auto;\n  text-align: center;\n"], ["\n  margin: 0 auto;\n  text-align: center;\n"])));
var Container = styled(Card)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 auto;\n  max-width: 450px;\n  padding: ", ";\n"], ["\n  margin: 0 auto;\n  max-width: 450px;\n  padding: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "".concat(theme.space[6], "px ").concat(theme.space[8], "px ").concat(theme.space[8], "px");
});
var templateObject_1, templateObject_2, templateObject_3;
