import React, {ComponentProps} from "react";
import {format, utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import {LOCAL_TIME_ZONE, DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {SignatureField} from "@reside/ui";

import {logErrorToSentry} from "../../utils/sentry";

type Props = ComponentProps<SignatureField> &
  Readonly<{
    /**
     * Timezone by which to offset the sign date.
     */
    timeZone: string;
  }>;

/**
 * A customized SignatureField which sets the sign date reference by the timezone offset of a facility.
 */
export const TimezoneSignatureField = ({timeZone, ...props}: Props) => (
  <SignatureField
    key={props.name}
    {...props}
    onChange={(signature, form) => {
      // HACK: Due to async saving of answers in admission, we must set signature & date in one 'tick'
      form.setValues({
        ...form.values,
        [props.name]: signature,
        [props.signDateRef]: signature ? getSignDate(timeZone) : undefined,
      });
    }}
    onError={logErrorToSentry}
  />
);

const getSignDate = (timeZone: string) =>
  format(
    utcToZonedTime(zonedTimeToUtc(new Date(), LOCAL_TIME_ZONE), timeZone),
    DATE_FORMAT.ISO_DATE,
    {
      timeZone,
    },
  );
