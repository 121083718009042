import {store, select} from "../store";
import {reactAppEnvironment} from "../config";
import {ASSETS_BUCKET_URL} from "./constants";

export const parse = (urlStr: string) => {
  return new URL(urlStr);
};

export const serialize = (urlObj: URL) => {
  return urlObj.toString();
};

export const toApi = (path: string) => {
  const url = new URL(process.env.REACT_APP_API_URL);

  url.pathname = `${url.pathname}/${path}`.replace("//", "/");

  return url.toString();
};

// TODO: this is ugly
export const withAccessToken = (urlStr: string) => {
  const {adminToken, admissionToken} = select((models: any) => ({
    adminToken: models.adminSession.getToken,
    admissionToken: models.admissionSession.getToken,
  }))(store.getState());
  const url = parse(urlStr);

  url.searchParams.set("accessToken", adminToken || admissionToken);

  return serialize(url);
};

export const getPdfUrl = (admissionId: string) => {
  const pdfUrl = `${process.env.REACT_APP_API_URL}/v3/documents/${admissionId}/generate`;

  return withAccessToken(pdfUrl);
};

export const getUrlForEnv = (url = "") => {
  const nextBucketName =
    ASSETS_BUCKET_URL[reactAppEnvironment] || ASSETS_BUCKET_URL.PRODUCTION;

  return url.replace(ASSETS_BUCKET_URL.PRODUCTION, nextBucketName);
};
