import React, {ReactNode} from "react";

const defaultProps = {children: "No data"};

type Props = {
  children?: ReactNode;
};

export const EmptyChart = ({children}: Props) => (
  <div className="no-data">{children}</div>
);

EmptyChart.defaultProps = defaultProps;
