export const CompareDateRangePresets = [
  {
    id: 1,
    label: "Previous period",
    range: "period",
  },
  {
    id: 2,
    label: "Previous month",
    range: "month",
  },
  {
    id: 3,
    label: "Previous year",
    range: "year",
  },
];
