import React, {FormEvent, ReactNode} from "react";
import {CheckboxItem, Text, Icon, FlexColumn} from "@reside/ui";

type Option<Value = string> = {value: Value; label: string};

type Props<Value = string> = Readonly<{
  label: ReactNode;
  values: ReadonlyArray<Value>;
  options: ReadonlyArray<Option<Value>>;
  disabled: boolean;
  onChange: (values: ReadonlyArray<Value>) => void;
  FlexColumn: typeof FlexColumn;
  /**
   * The checkboxes in the list can be parametrized to have custom component rendering the actual Checkboxes.
   */
  CheckboxListItem: (
    props: Option<Value> & {
      disabled: boolean;
      checked: boolean;
      onChange: (event: FormEvent<HTMLInputElement>) => void;
    },
  ) => JSX.Element;
}>;

export const MacroCheckbox = <Value,>({
  label,
  options,
  values,
  disabled,
  onChange,
  FlexColumn,
  CheckboxListItem,
}: Props<Value>) => {
  const onGroupChange = (event: FormEvent<HTMLInputElement>) =>
    onChange(
      event.currentTarget.checked ? options.map(option => option.value) : [],
    );

  const onOneChange =
    (option: Option<Value>) => (event: FormEvent<HTMLInputElement>) =>
      onChange(
        event.currentTarget.checked
          ? [...values, option.value]
          : values.filter(value => value !== option.value),
      );

  const isActive = (values: ReadonlyArray<Value>, option: Option<Value>) =>
    values.includes(option.value);

  const everyChecked = options.every(option => isActive(values, option));

  const someChecked = options.some(option => isActive(values, option));

  return (
    <FlexColumn>
      <CheckboxItem
        label={
          <Text as="strong" fontWeight="bold">
            {label}
          </Text>
        }
        checked={someChecked}
        Icon={props =>
          someChecked && !everyChecked ? (
            <Icon {...props} name="minus-1" />
          ) : (
            <Icon {...props} />
          )
        }
        disabled={disabled}
        onChange={onGroupChange}
      />
      {options.map(option => (
        <CheckboxListItem
          key={String(option.value)}
          value={option.value}
          label={option.label}
          checked={everyChecked || isActive(values, option)}
          disabled={disabled}
          onChange={onOneChange(option)}
        />
      ))}
    </FlexColumn>
  );
};

MacroCheckbox.defaultProps = {
  disabled: false,
  FlexColumn,
  CheckboxListItem: CheckboxItem,
};
