import React, {Component, ErrorInfo, ReactNode} from "react";

import {ErrorBanner} from "./ErrorBanner";
import {logErrorToSentry} from "../../utils/sentry";

type State = Readonly<{
  hasError: boolean;
}>;

export class ErrorBoundary extends Component<
  {errorChildren: ReactNode},
  State
> {
  state: State = {
    hasError: false,
  };

  static defaultProps = {
    errorChildren: <ErrorBanner />,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    logErrorToSentry(error, info);
  }

  render() {
    return this.state.hasError ? this.props.errorChildren : this.props.children;
  }
}
