import React, {useRef, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooterButtons,
  H2,
  H3,
  styled,
  List,
  Button,
  Text,
  DATE_FORMAT,
  theme,
} from "@reside/ui";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import QRCode from "react-qr-code";
import {css} from "@emotion/react";
import {format, parseISO} from "date-fns";

import {ADMISSION_ENTER_ROUTE} from "../../../../pages/page-admission-enter/route";
import {useResidentLoginModalContext} from "./ResidentLoginModalContext";
import {CopyToClipboardButton} from "./CopyToClipboardButton";
import {PrintQRButton} from "./PrintQRButton";
import {
  readResidentFirstNameAnswer,
  readResidentLastNameAnswer,
} from "../../../../models/AdmissionModel.helpers";
import {AdmissionEntryService} from "../../../../services/AdmissionEntryService";

/**
 * Modals will display a QR code containing link to open admission at the prelude ("Tell us who you are" step).
 */
export const ResidentLoginModal = () => {
  const {admission, closeModal} = useResidentLoginModalContext();

  const {isLoading, data, mutate, reset} = useMutation(
    AdmissionEntryService.generateAdmissionEntryToken,
    {
      onError: () => {
        Alert.error("Unable to get the login link. Please try again later.");
        closeModal();
      },
    },
  );

  const {dateOfAdmission = ""} = admission ?? {};

  return (
    <ResidentLoginModalRenderer
      isLoading={isLoading}
      isOpen={Boolean(admission)}
      link={
        data?.data?.token
          ? `${window.location.origin}${ADMISSION_ENTER_ROUTE}?token=${data.data.token}`
          : undefined
      }
      onClose={() => {
        closeModal();
        reset();
      }}
      onGenerate={() => mutate(admission.id)}
      firstName={readResidentFirstNameAnswer(admission as any)}
      lastName={readResidentLastNameAnswer(admission as any)}
      dateOfAdmission={dateOfAdmission}
    />
  );
};

type ResidentLoginModalContentProps = Readonly<{
  isOpen: boolean;
  link?: string;
  firstName: string;
  lastName: string;
  dateOfAdmission: string;
  isLoading: boolean;
  onGenerate: () => void;
  onClose: () => void;
}>;

export const ResidentLoginModalRenderer = ({
  isOpen,
  link,
  firstName,
  lastName,
  dateOfAdmission,
  isLoading,
  onGenerate,
  onClose,
}: ResidentLoginModalContentProps) => {
  const [displayNonPrintable, setDisplayNonPrintable] = useState(true);

  const componentRef = useRef();

  return (
    <Modal size="small" isOpen={isOpen} onRequestClose={onClose}>
      {({closeModal}) => (
        <>
          <ModalContent>
            <PrintableArea ref={componentRef} isPrinting={!displayNonPrintable}>
              <H2>{link ? "Scan QR Code" : "Start Resident Experience"}</H2>
              <Center>
                {link && <QRCode size={180} value={link} />}
                {displayNonPrintable ? (
                  <ButtonsWrapper isVisible={displayNonPrintable}>
                    {/**
                     * In order to reset the clipboard hook state, we must remount the component for each token.
                     * We use the "key" react prop to force remount.
                     */}
                    {link ? (
                      <>
                        <CopyToClipboardButton key={link} link={link} />
                        <PrintQRButton
                          onBeforeGetContent={async () =>
                            setDisplayNonPrintable(false)
                          }
                          onBeforePrint={async () =>
                            setDisplayNonPrintable(true)
                          }
                          content={() => componentRef.current}
                          documentTitle={`${firstName}-${lastName}`}
                        />
                      </>
                    ) : (
                      <Button
                        color="primary"
                        disabled={isLoading}
                        onClick={onGenerate}
                      >
                        {isLoading
                          ? "Please wait..."
                          : "Activate Magic Link/QR Code"}
                      </Button>
                    )}
                  </ButtonsWrapper>
                ) : (
                  <Content>
                    <Text marginBottom={`${theme.space[2]}px`}>
                      Resident Name: {firstName} {lastName}
                    </Text>
                    <Text>
                      Date of Admission:{" "}
                      {format(parseISO(dateOfAdmission), DATE_FORMAT.FULL_DATE)}
                    </Text>
                  </Content>
                )}
              </Center>
              {link && (
                <>
                  <H3>Please use a camera to read the QR code:</H3>
                  <StyledList
                    ordered
                    listStyleType="decimal"
                    items={[
                      'From your tablet\'s homescreen, 3D Touch (tap and hold until a menu window opens) the "Google Chrome" app icon and choose "Scan QR Code"',
                      '"Position QR code in this frame" you scan the QR code, Chrome will launch a Google search for QR Code, then tap "go" on your keyboard to enter "Resident Experience"',
                    ]}
                  />
                </>
              )}
            </PrintableArea>
          </ModalContent>
          <ModalFooterButtons>
            <Button color="default" onClick={closeModal}>
              Close
            </Button>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};

const Center = styled.div`
  text-align: center;
  margin: ${({theme}) => theme.space[5]}px 0;

  button {
    margin: ${({theme}) => theme.space[5]}px auto 0 auto;
  }
`;

const StyledList = styled(List)`
  margin-top: ${({theme}) => theme.space[3]}px;
`;

const ButtonsWrapper = styled.span<{
  isVisible?: boolean;
}>`
  ${({isVisible}) =>
    !isVisible &&
    css`
      display: none;
    `};
`;

const PrintableArea = styled.div<{isPrinting: boolean}>`
  ${({isPrinting, theme}) =>
    isPrinting &&
    css`
      margin: ${theme.space[7]}px;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({theme}) => theme.space[3]}px 0;
`;
