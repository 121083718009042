import React, {Component} from "react";
import {Button, Paragraph, H1, styled} from "@reside/ui";
import {RouteComponentProps} from "react-router-dom";

import {dispatch} from "../../store";
import {Footer} from "../../atoms/footer";
import {ADMISSION_WELCOME_ROUTE} from "../page-admission-welcome/route";

export class PageExpiredSession extends Component<RouteComponentProps> {
  componentDidMount() {
    dispatch.admissionSession.reset();
  }

  render() {
    return (
      <PageExpiredSessionRenderer
        onContinue={() => this.props.history.push(ADMISSION_WELCOME_ROUTE)}
      />
    );
  }
}

type Props = Readonly<{
  onContinue: () => void;
}>;

export const PageExpiredSessionRenderer = ({onContinue}: Props) => (
  <Page>
    <Content>
      <img
        src="/assets/howLongWillItTake.svg"
        alt="Last slide baloons"
        width="240px"
      />
      <H1 marginTop={[7]} fontWeight="bold">
        Your session has expired
      </H1>
      <Paragraph fontSize={4}>
        Please click on Continue button to log in your Admission or ask your
        Facility Representative.
      </Paragraph>
    </Content>
    <Footer
      right={
        <Button size="large" color="primary" onClick={onContinue}>
          Continue
        </Button>
      }
    />
  </Page>
);

const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  padding: 80px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;
