import {Text, styled} from "@reside/ui";

export const TextLink = styled((props: any) => (
  <Text color="primary" lineHeight="1.75em" fontWeight="bold" {...props}>
    click here
  </Text>
))`
  cursor: pointer;
  text-decoration: underline;
`;
