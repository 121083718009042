import { addSuffixToValue, autoCapitalizeText, formatValue, removeMarkdownKeepTrailingSpace, } from "./format";
import { precision, isDecimal } from "./number";
export var removeLeadingZeroFromNumber = function (value) {
    var str = String(value).replace(/^0+/, "0");
    if (String(str).length === 1) {
        return str;
    }
    /* eslint-disable no-useless-escape */
    return String(str).replace(/^0+(?![.|,])/, "");
};
export var formatFieldValue = function (value, _a) {
    var type = _a.type, name = _a.name, suffix = _a.suffix, capitalize = _a.capitalize, format = _a.format, fractionalDigits = _a.fractionalDigits;
    return format
        ? formatValue(removeMarkdownKeepTrailingSpace(value), format)
        : type === "number" || fractionalDigits
            ? removeLeadingZeroFromNumber(removeMarkdownKeepTrailingSpace(value))
            : type === "password" || name.toLowerCase().includes("password")
                ? value
                : type === "email"
                    ? value
                    : type === "document_path"
                        ? addSuffixToValue(value, suffix)
                        : autoCapitalizeText(removeMarkdownKeepTrailingSpace(value), type, capitalize);
};
export var isValueFilled = function (value) { return value || value === 0; };
var shouldFormatDigits = function (fractionalDigits, value) {
    if (isDecimal(value)) {
        return fractionalDigits && precision(value) > fractionalDigits;
    }
    else {
        return false;
    }
};
export var processValue = function (fractionalDigits, value) {
    if (shouldFormatDigits(fractionalDigits, value)) {
        return Number(value).toFixed(fractionalDigits);
    }
    return value;
};
