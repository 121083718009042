import React from "react";
import {connect} from "react-redux";
import {Admission} from "@reside/reside-api-admission";
import {styled, Box, Button} from "@reside/ui";

import {AppState, select} from "../../store";
import {Spinner} from "../spinner";
import {Column, ColumnHeader} from "./Column";
import {AdmissionCellList} from "./AdmissionCellList";
import {useAdmissionsByStatusQuery} from "./hooks";
import {readableAdmissionStatus} from "../admission-status-tag";

const plural = (
  totalElements: number,
  zero: string,
  one: string,
  other: string,
) => {
  switch (totalElements) {
    case 0:
      return zero;
    case 1:
      return `${totalElements} ${one}`;
    default:
      return `${totalElements} ${other}`;
  }
};

const Renderer = ({
  status,
  facilityIds,
  loadMoreButtonId,
}: {
  status: Admission.StatusEnum;
  loadMoreButtonId: string;
  facilityIds: ReadonlyArray<string>;
}) => {
  const {data, hasNextPage, fetchNextPage, isFetching} =
    useAdmissionsByStatusQuery({facilityIds, status});

  const readableStatus = readableAdmissionStatus(status);

  const [{totalElements = 0} = {}] = data?.pages ?? [];

  const hasSelectedFacilities = facilityIds.length;
  return (
    <Column
      key={status}
      footer={
        <Box flexDirection="row" justifyContent="space-around">
          {hasNextPage && facilityIds.length > 0 && !isFetching && (
            <Button
              size="small"
              color="primary"
              onClick={() => {
                fetchNextPage();
              }}
              testId={loadMoreButtonId}
            >
              Load More
            </Button>
          )}
          {isFetching && <Spinner relative />}
        </Box>
      }
      testId={`${readableStatus.toLowerCase()}-column`}
    >
      <StyledColumnHeader
        title={readableStatus}
        subtitle={plural(
          hasSelectedFacilities ? totalElements : 0,
          "NO ADMISSIONS",
          "ADMISSION",
          "ADMISSIONS",
        )}
      />
      {data && (
        <AdmissionCellList
          admissions={
            facilityIds?.length ? data.pages.flatMap(item => item.content) : []
          }
        />
      )}
    </Column>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilityIds: select.adminSession.activeFacilitiesIds(state),
});

export const DashboardTableColumn = connect(mapStateToProps)(Renderer);

const StyledColumnHeader = styled(ColumnHeader)`
  margin-bottom: 15px;
`;
