import React from "react";
import { Text } from "../../text";
import { Box } from "../../box";
import { useBlockComponentContext } from "../../form-blocks";
export var SectionRow = function (_a) {
    var translationKey = _a.translationKey;
    var Translated = useBlockComponentContext().Translated;
    return (React.createElement(Box, { flexDirection: "row", alignItems: "center", className: "sectionrow-0-44" },
        React.createElement(Text, { fontWeight: "bold", fontSize: "1.25em", style: { paddingLeft: 45 } },
            React.createElement(Translated, { translationKey: translationKey }))));
};
