import {
  H1,
  Button,
  Modal,
  ModalContent,
  Text,
  ModalFooterButtons,
  Box,
  styled,
} from "@reside/ui";
import {
  AdmissionStatusEnum,
  AdmissionsService,
} from "../../services/AdmissionsService";
import {useQuery} from "react-query";
import Alert from "react-s-alert";
import {mapTransitionStatus} from "../../utils/helpers/mapTransitionStatus";

const ErrorBlock = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: #fecaca;
  color: ${(props: any) => props.theme.color.deepOrange300};
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
`;

type Props = Readonly<{
  outdatedContract: boolean;
  residentName: string;
  admissionId: string;
  admissionCode: string;
  admissionStatus: AdmissionStatusEnum;
  isOpen: boolean;
  isLoading: boolean;
  onRequestClose: () => void;
  onCancel: () => void;
}>;

export const RestoreAdmissionModal = ({
  residentName,
  admissionId,
  admissionCode,
  isOpen,
  isLoading,
  outdatedContract,
  onRequestClose,
  onCancel,
}: Props) => {
  const {data, isLoading: isLoadingTransitions} = useQuery({
    queryFn: () => AdmissionsService.transitions(admissionId),
    enabled: isOpen,
    onError: () => {
      Alert.error("Failed to get the last status for the admission.");
    },

    select: data => {
      const newData = [...(data?.data || [])];
      return newData[1]?.status
        ? mapTransitionStatus(newData[1]?.status)
        : "N/A";
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={isLoading ? undefined : onRequestClose}
    >
      {({closeModal}: {closeModal: () => void}) => (
        <>
          <ModalContent>
            <H1>Restore Admission for {residentName}</H1>
            <Box gap={24}>
              <Box gap={6}>
                <Text color="gray100" fontWeight="bold" fontSize={16} as="p">
                  Admission ID: <Text color="primary">{admissionCode}</Text>
                </Text>
                <Text color="gray100" fontWeight="bold" fontSize={16} as="p">
                  Last Status:{" "}
                  <Text color="primary">
                    {isLoadingTransitions ? "..." : <>{data}</>}
                  </Text>
                </Text>
                {outdatedContract ? (
                  <ErrorBlock>
                    You are about to restore a version of the admission packet
                    that predates the current version in use.
                  </ErrorBlock>
                ) : null}
              </Box>
              <Text fontSize={16}>
                Are you sure you want to restore admission for {residentName}?
              </Text>
            </Box>
          </ModalContent>
          <ModalFooterButtons>
            <Box width="100%" flexDirection="row" gap={12}>
              <Button
                type="button"
                onClick={onCancel}
                color="primary"
                disabled={isLoading}
                testId="restoreAdmissionButton"
              >
                {isLoading ? "Restoring..." : "Restore"}
              </Button>
              <Button
                outline
                color="primary"
                disabled={isLoading}
                onClick={closeModal}
                testId="closeRestoreAdmissionModalButton"
              >
                Cancel
              </Button>
            </Box>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};
