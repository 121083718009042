import React from "react";
import {VictoryPie, VictoryTooltip} from "victory";

import {PieData} from "../../../../pages/page-reporting/analytics/helpers";
import {
  colorScalePie,
  chartTheme,
  getColorScale,
  ChartProps,
} from "../../chart.helpers";
import {ContainerWidthSizer} from "../../ContainerWidthSizer";
import {EmptyChart} from "../empty";

import {getCommonLabels} from "./PieChart.helpers";

import "./style.scss";

export type Props<S> = ChartProps<S> &
  Readonly<{
    additionalComponent?: any;
  }>;

const PieChartRenderer = ({
  data,
  xFormat,
  additionalComponent,
}: Props<PieData>) => {
  const commonLabels = getCommonLabels(data);

  const extendedColorScale = getColorScale(
    commonLabels.length,
    colorScalePie,
    true,
  );

  return (
    <ContainerWidthSizer>
      {({width}) => (
        <div>
          {data.map((dataset: any, index: number) => (
            <div key={index}>
              <div className="row row-align-items-center">
                <div className="pie-chart">
                  <VictoryPie
                    key={index}
                    theme={chartTheme}
                    width={width}
                    data={dataset.stack}
                    padding={{
                      bottom: 50,
                      left: 55,
                      top: 50,
                      right: 50,
                    }}
                    style={{
                      data: {
                        fill: d =>
                          extendedColorScale[commonLabels.indexOf(d.x)],
                        fillOpacity: index > 0 ? 0.7 : 1,
                      },
                    }}
                    labelComponent={<VictoryTooltip theme={chartTheme} />}
                    labels={
                      xFormat
                        ? d => xFormat(d, dataset)
                        : d => `${d.x} (${d.y})`
                    }
                  />
                </div>
                {additionalComponent?.(dataset)}
              </div>
              <div className="row row-align-items-center">
                <ul className="pie-legend">
                  {dataset.stack.map((item: any, index: number) => (
                    <li key={index} className="pie-legend__item">
                      <div
                        className="pie-legend__bullet"
                        style={{
                          background: `#${
                            extendedColorScale[commonLabels.indexOf(item.x)]
                          }`,
                        }}
                      />
                      <span>{`[${
                        dataset.additional_data.positions
                          ? dataset.additional_data.positions[item.x]
                          : ""
                      }] ${item.x} (${item.y})`}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </ContainerWidthSizer>
  );
};

export const PieChart = (props: Props<PieData>) =>
  props.data ? <PieChartRenderer {...props} /> : <EmptyChart />;
