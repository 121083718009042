import React, {ReactNode} from "react";
import {styled, CheckboxItem, FlexColumn} from "@reside/ui";
import {TableUserDto} from "@reside/reside-api-app";
import {without} from "lodash";

import {MacroCheckbox} from "../../../../atoms/macro-checkbox";
import {
  TemporaryHideChart,
  Props as TemporaryHideChartProps,
} from "../../TemporaryHideChart";
import {PERMISSION_BLOCKS} from "../../permissions.helpers";

type Props = Readonly<{
  label: ReactNode;
  permissions: ReadonlyArray<{
    value: TableUserDto.PermissionsEnum;
    label: string;
  }>;
  activePermissions?: ReadonlyArray<TableUserDto.PermissionsEnum>;
  onSetPermission: (
    permission: ReadonlyArray<TableUserDto.PermissionsEnum>,
  ) => void;
  TemporaryHideChart: (props: TemporaryHideChartProps) => JSX.Element;
}>;

export const PermissionsMacroCheckbox = ({
  label,
  permissions,
  activePermissions = [],
  onSetPermission,
  TemporaryHideChart,
}: Props) => (
  <MacroCheckbox
    label={label}
    options={permissions}
    values={activePermissions}
    onChange={perms =>
      onSetPermission([
        ...perms,
        // here we remove the permission options for this Macro checkbox and combine with the rest of active permissions...
        ...without(activePermissions, ...permissions.map(({value}) => value)),
      ])
    }
    CheckboxListItem={({value, label, checked, onChange}) => (
      <TemporaryHideChart key={value} chartIds={PERMISSION_BLOCKS[value] || []}>
        <CheckboxItem label={label} checked={checked} onChange={onChange} />
      </TemporaryHideChart>
    )}
    FlexColumn={PermissionColumn}
  />
);

PermissionsMacroCheckbox.defaultProps = {
  TemporaryHideChart,
};

export const PermissionColumn = styled(FlexColumn)`
  margin-bottom: 50px;

  span {
    padding-left: 0.8em;
    font-size: 1.1em;
    line-height: 1.85em;
  }
`;
