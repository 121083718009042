import {Card, styled, ThinScrollbarArea} from "@reside/ui";
import {
  FacilityFeatureFlagsRootDto,
  OrganizationFeatureFlagsDto,
} from "@reside/reside-api-app";

import {FeatureFlagsTableRow} from "./";
import {CardTitle} from "../../../atoms/card-title";

export type TableData = ReadonlyArray<
  FacilityFeatureFlagsRootDto & OrganizationFeatureFlagsDto
>;

type Props = Readonly<{
  title: string;
  subtitle: string;
  allFeatureFlags: ReadonlyArray<string>;
  data: TableData;
  header: string;
  onChange?: ({
    id,
    featureTypes,
  }: {
    id: string;
    featureTypes: ReadonlyArray<string>;
  }) => void;
}>;

export const FeatureFlagsTable = ({
  title,
  subtitle,
  allFeatureFlags = [],
  data = [],
  header,
  onChange,
}: Props) => (
  <StyledCard>
    <CardTitle title={title} subtitle={subtitle} />
    <TableWrapper>
      <table>
        <thead>
          <tr>
            <th>{header}</th>
            {allFeatureFlags?.map(featureType => (
              <FeatureHeader key={featureType}>{featureType}</FeatureHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <FeatureFlagsTableRow
              {...row}
              allFeatureFlags={allFeatureFlags}
              key={row.id || row.organizationId}
              disableCheckbox={header === "Organization"}
              onChange={onChange}
            />
          ))}
        </tbody>
      </table>
    </TableWrapper>
  </StyledCard>
);

const FeatureHeader = styled.th`
  text-align: center;
  padding: 0px 8px;
`;

const StyledCard = styled(Card)`
  padding-block: 1rem;
  padding-left: 1rem;
`;

const TableWrapper = styled(ThinScrollbarArea)`
  overflow-x: auto;
  height: calc(100vh - 17.25rem);

  table th {
    position: sticky;
    top: 0;
    background-color: ${({theme}) => theme.color.white};
  }
`;
