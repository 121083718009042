import React, {useEffect, useState} from "react";
import Alert from "react-s-alert";
import {styled, Paragraph, Button, Modal, ModalProps} from "@reside/ui";

import {
  CheckResidentPccAccountStatusUsingGETQueryParams,
  handlePccRequestLimitReached,
  PccAccountStatus,
  PccService,
} from "../../services/PccService";
import {ApiResponse} from "apisauce";
import {Admission} from "@reside/reside-api-admission";

type Props = Readonly<{
  facilityId: string;
  admissionId: string;
  /**
   * Hides PCC account skip modal
   */
  noSkipModal?: boolean;
  /**
   * PCC account skipped
   */
  isPccSkipped?: boolean;
  onRequestClose: (success?: boolean) => void;
  /**
   * Callback if PCC account already exists
   */
  onAccountExists?: () => Promise<ApiResponse<Admission, Admission>>;
}>;

export const PccModal = ({
  facilityId,
  admissionId,
  noSkipModal,
  onRequestClose,
  isPccSkipped,
  onAccountExists,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pccPayload: CheckResidentPccAccountStatusUsingGETQueryParams = {
    facilityId,
    admissionId,
  };

  useEffect(() => {
    const checkPccStatus = async () => {
      try {
        const {data} = await PccService.getResidentAccountStatus(pccPayload);

        if (
          data.status === PccAccountStatus.AccountDoesNotExist ||
          (data.status === PccAccountStatus.AccountExists && isPccSkipped)
        ) {
          setIsOpen(true);
        }

        if (data.status === PccAccountStatus.AccountExists && !isPccSkipped) {
          // PointClickCare patient account already exists - skip modal
          await onAccountExists?.();
          onRequestClose();
        }
      } catch (error) {
        handlePccRequestLimitReached(error) ||
          Alert.error(
            "Error occurred while checking PointClickCare account status",
          );
      }
    };

    checkPccStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = (success?: boolean) => {
    setIsOpen(false);
    onRequestClose(success);
  };

  const createPccAccount = async () => {
    setIsLoading(true);
    try {
      await PccService.createPccAccount(pccPayload);
    } catch (error) {
      handlePccRequestLimitReached(error) ||
        Alert.error("Error occurred while creating PointClickCare account");
    } finally {
      closeModal(true);
    }
  };

  const skipPccAccount = async () => {
    if (isPccSkipped) {
      closeModal();

      return;
    }

    setIsLoading(true);
    try {
      await PccService.skipPccAccount(pccPayload);
    } catch (error) {
      handlePccRequestLimitReached(error) ||
        Alert.error("Error occurred while skipping PointClickCare");
    } finally {
      closeModal(true);
    }
  };

  return (
    <PccModalWindow
      isOpen={isOpen}
      onRequestClose={noSkipModal ? () => closeModal() : skipPccAccount}
      noCloseButton
      shouldCloseOnOverlayClick={!isPccSkipped}
      noSkipModal={noSkipModal}
      isLoading={isLoading}
      onCreate={createPccAccount}
    />
  );
};

export const PccModalWindow = ({
  noSkipModal,
  isLoading,
  onRequestClose,
  onCreate,
  ...modalProps
}: {
  noSkipModal: boolean;
  isLoading: boolean;
  onCreate: () => Promise<void>;
} & Omit<ModalProps, "children">) => (
  <Modal
    onRequestClose={onRequestClose}
    noCloseButton
    {...modalProps}
    title="PointClickCare integration"
  >
    {() => (
      <ModalContent data-test-id="createPccAccountModal">
        <Paragraph>
          {noSkipModal &&
            "If you want to retry to upload the document to PointClickCare for given resident, the resident needs to have PointClickCare account created first."}
        </Paragraph>
        <Paragraph>
          Do you want to create PointClickCare account for given resident?
        </Paragraph>
        <Paragraph>
          {!noSkipModal &&
            "You can switch between creating PointClickCare account or skip it. If you skip it now you can later re-create PointClickCare account from activity log."}
        </Paragraph>
        <ButtonsWrapper>
          <Button
            testId="skipPccAccountButton"
            disabled={isLoading}
            onClick={onRequestClose}
          >
            {noSkipModal ? "Cancel" : "Skip"}
          </Button>
          <Button
            testId="createPccAccountButton"
            color="primary"
            disabled={isLoading}
            onClick={onCreate}
          >
            Create
          </Button>
        </ButtonsWrapper>
      </ModalContent>
    )}
  </Modal>
);

const ModalContent = styled.div`
  padding: 0 35px 25px;
`;

const ButtonsWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;
