import {styled} from "@reside/ui";

export const Icon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 25px;
  margin-left: auto;
`;

/** Hide UserProfileAsLink and Signout button on small screens and show only dropdown menu toggle in shape of user profile and icon next to it. */
export const MediaWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1170px) {
    & > :is(div:nth-child(2), button) {
      display: none;
    }
  }

  @media (min-width: 1170px) {
    & > div:last-child {
      display: none;
  }
  `;
