import {useMutation} from "react-query";
import {useHistory, useParams} from "react-router";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useEditEventTrigger = () => {
  const {eventTriggerId} = useParams<{eventTriggerId: string}>();
  const history = useHistory();
  return useMutation(
    async (body: {
      document: string;
      facilities: Array<string>;
      template_field_id: string;
      sftp_config_id: string;
      document_path: string;
    }) => {
      const response = await HubAndSpokeService.editEventTrigger(
        eventTriggerId,
        body,
      );
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("Event trigger successfully edited!");
        history.push("/admin/hub-and-spoke/event-triggers");
      },
      onError: (err: any) => {
        logErrorToSentry(err);
        Alert.error(err?.data?.detail);
      },
    },
  );
};
