import React from "react";
import {VictoryBar, VictoryStack} from "victory";

import {chartTheme, colorScaleRemaining} from "../../chart.helpers";

type Props = Readonly<{
  color: string;
  rate: number;
}>;

export const AggregationChart = (props: Props) => (
  <VictoryStack
    colorScale={[props.color, colorScaleRemaining]}
    standalone
    horizontal
    height={8}
    theme={chartTheme}
    padding={{
      bottom: 0,
      left: 5,
      right: 5,
      top: 0,
    }}
    width={300}
  >
    <VictoryBar data={[props.rate]} />
    <VictoryBar data={[1 - props.rate]} />
  </VictoryStack>
);
