import React from "react";
import {Box} from "@reside/ui";
import {AdminLayoutFullHeightAndTwoCells} from "../../../atoms/admin-layout";
import {DocumentTitle} from "../../../atoms/document-title";
import {ResidentFormik} from "../atoms/ResidentFormik";
import {AdminActionRow} from "../../../atoms/admin-action-row";
import {useFetchResident} from "../../page-admin-residents/hooks/useFetchResident";
import {Spinner} from "../../../atoms/spinner";
import {useEditResident} from "../hooks/useEditResident";
import {resolveResidentStatus} from "../../page-admin-residents/helpers";

export const PageAdminResidentsEdit = (): JSX.Element => {
  const {
    data: resident,
    isLoading,
    remove: clearStaleResident,
  } = useFetchResident();
  const {mutate: editResident, isLoading: isEditing} = useEditResident(
    resident?.id,
  );

  const {isActive} = resolveResidentStatus(resident?.status);

  return (
    <AdminLayoutFullHeightAndTwoCells>
      {isLoading || isEditing ? (
        <Spinner backdrop />
      ) : (
        // Control position of form buttons
        <Box position="relative">
          <DocumentTitle title="Reside - Admin - Edit Resident" />

          <AdminActionRow title="Edit Resident" />
          <ResidentFormik
            edit
            initialValues={{
              first_name: resident.first_name ?? "",
              middle_name: resident.middle_name ?? "",
              last_name: resident.last_name ?? "",
              date_of_birth: resident.date_of_birth ?? "",
              gender: resident.gender ?? "",
              phone_number: resident.phone_number ?? "",
              email: resident.email ?? "",
              ssn: resident.ssn ?? "",
            }}
            handleSubmit={args => {
              editResident(args);
              clearStaleResident();
            }}
            isInactive={!isActive}
          />
        </Box>
      )}
    </AdminLayoutFullHeightAndTwoCells>
  );
};
