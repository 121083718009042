import {styled, theme} from "@reside/ui";
import {IconButton} from "../../../../icon-button";

export const NavbarIconButton = styled(IconButton)<{
  darkBackground: boolean;
  lightColor?: keyof typeof theme.color;
  darkColor?: keyof typeof theme.color;
}>`
  i {
    font-size: 22px;
  }

  ${({darkBackground, lightColor, darkColor, theme}) =>
    darkBackground
      ? `
    color: ${theme.color[lightColor]};

    i {
      color: ${theme.color[lightColor]};
    }
  `
      : `
    color: ${theme.color[darkColor]};

    i {
      color: ${theme.color[darkColor]};
    }
  `}
`;

NavbarIconButton.defaultProps = {
  lightColor: "white",
  darkColor: "darkBlue100",
};
