import React from "react";
import {Formik} from "formik";
import {CreateFacilityDto} from "@reside/reside-api-app";
import {validator} from "@reside/forms";
import {TIME_ZONES} from "@reside/ui/dist/form-blocks/block-form-control";
import {
  styled,
  Button,
  TextField,
  StatesField,
  Box,
  SelectField,
  Grid,
  FormGrid,
  FormSlide,
  FormCard,
} from "@reside/ui";

import unflatten from "obj-unflatten";

import {Space} from "../../../atoms/space";
import {AdminPageTitle} from "../../../atoms/admin-layout/AdminPageTitle";

type Props = Readonly<{
  onSubmit: (values: CreateFacilityDto) => void;
  onCancel: () => void;
  templateOptions: ReadonlyArray<string>;
  organizationId: string;
}>;

const validate = (values: CreateFacilityDto) => {
  const errors = validator.validateAll(values, {
    name: "required|facility-name",
    phone: "required|phone",
    email: "required|email",
    timeZoneId: "required",
    templateName: "required",
    address: {
      street: "required",
      zipcode: "required|zip-code",
      city: "required",
      state: "required",
    },
  });
  return unflatten(errors);
};

export const FacilityForm = ({
  onSubmit,
  onCancel,
  templateOptions,
  organizationId,
}: Props) => (
  <Formik<CreateFacilityDto>
    enableReinitialize
    initialValues={{
      name: "",
      organizationId: organizationId,
      description: "",
      phone: "",
      email: "",
      timeZoneId: "" as any,
      templateName: "",
      address: {
        street: "",
        number: "",
        zipcode: "",
        city: "",
        state: "",
      },
    }}
    onSubmit={onSubmit}
    validate={validate}
  >
    {formik => (
      <>
        <StyleBox
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <AdminPageTitle>New Facility</AdminPageTitle>
          </div>
          <Space>
            <Button color="primary" onClick={onCancel} outline>
              Cancel
            </Button>
            <Button color="success" onClick={formik.submitForm}>
              Save
            </Button>
          </Space>
        </StyleBox>
        <FormGrid>
          <FormSlide>
            <FormCard title="Facility Details">
              <TextField label="Facility Name" name="name" />
              <TextField label="Phone" name="phone" format="us-phone" />
              <TextField label="Email Address" name="email" type="email" />
              <SelectField
                label="Time Zone"
                name="timeZoneId"
                options={TIME_ZONES}
              />
              <SelectField
                label="Facility Template"
                name="templateName"
                options={templateOptions}
                getOptionLabel={value => value}
                getOptionValue={value => value}
              />
            </FormCard>
            <FormCard title="Address">
              <Grid columns={2}>
                <TextField
                  label="Address (234 Main St.)"
                  name="address.street"
                />
                <TextField
                  label="Address line 2 (Apartment, suite, unit)"
                  name="address.number"
                />
              </Grid>
              <Grid columns={2}>
                <TextField label="City" name="address.city" />
                <StatesField label="State" name="address.state" />
              </Grid>
              <Grid columns={2}>
                <TextField
                  label="Zip Code"
                  name="address.zipcode"
                  format="zip-code"
                />
              </Grid>
            </FormCard>
          </FormSlide>
        </FormGrid>
      </>
    )}
  </Formik>
);

const StyleBox = styled(Box)`
  margin-bottom: 15px;
`;
