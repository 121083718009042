import {Card, Subtitle} from "@reside/ui";

import {DownloadButton} from "./DownloadButton";

type DownloadTemplateFormProps = {
  facilityTemplate: string;
};

export const DownloadTemplateForm = ({
  facilityTemplate,
}: DownloadTemplateFormProps) => (
  <Card>
    <Subtitle>Files</Subtitle>
    <DownloadButton
      facilityTemplate={facilityTemplate}
      label="Download Empty Packet"
    />
  </Card>
);
