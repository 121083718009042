var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { css } from "@emotion/css";
import { theme } from "../../theme";
import { Box } from "../../box";
import { Text } from "../../text";
export var ProgressBar = function (_a) {
    var progress = _a.progress, total = _a.total, completed = _a.completed;
    return (React.createElement("div", { className: s.container },
        React.createElement(Box, { flexDirection: "row", justifyContent: "space-between", className: s.header },
            React.createElement(Text, { fontSize: "1.25em", className: s.progressText }, "Progress"),
            React.createElement(Text, { color: "darkBlue60", fontSize: "1.25em", className: s.progressValues },
                progress,
                "% - ",
                completed,
                " out of ",
                total,
                " pages")),
        React.createElement("div", { className: s.progressBarContainer },
            React.createElement("div", { className: s.progressBar, style: {
                    width: "".concat(progress, "%"),
                } }))));
};
var s = {
    container: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: 40px;\n  "], ["\n    margin-bottom: 40px;\n  "]))),
    header: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0 2px 15px 2px;\n    font-weight: bold;\n  "], ["\n    padding: 0 2px 15px 2px;\n    font-weight: bold;\n  "]))),
    progressBarContainer: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    height: 6px;\n    border-radius: 3px;\n    background-color: #dcdada;\n  "], ["\n    width: 100%;\n    height: 6px;\n    border-radius: 3px;\n    background-color: #dcdada;\n  "]))),
    progressText: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-right: 1em;\n  "], ["\n    margin-right: 1em;\n  "]))),
    progressValues: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    text-align: right;\n  "], ["\n    text-align: right;\n  "]))),
    progressBar: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    height: 6px;\n    border-radius: 3px;\n    background-color: ", ";\n  "], ["\n    height: 6px;\n    border-radius: 3px;\n    background-color: ", ";\n  "])), theme.color.primary),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
