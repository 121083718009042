import React, {FunctionComponent} from "react";
import {addMinutes} from "date-fns";
import Alert from "react-s-alert";
import {useSessionStorage, useInterval} from "react-use";
import {theme, Text, Icon, FlexRowCenter} from "@reside/ui";

type Props = Readonly<{
  disabled?: boolean;
}>;

/**
 * Reminds the "flag slide/help modal" feature every 10 minutes via info message.
 */
export const HelpControlReminder: FunctionComponent<Props> = ({
  disabled = false,
}) => {
  const [lastAppearanceDate, setLastAppearanceDate] = useSessionStorage(
    "help_reminder_appearance",
    null,
  );

  useInterval(
    () => {
      const currentDate = new Date();

      if (lastAppearanceDate === null) {
        setLastAppearanceDate(currentDate.toString());
      } else if (currentDate >= addMinutes(new Date(lastAppearanceDate), 10)) {
        setLastAppearanceDate(currentDate.toString());
        renderHelpReminder();
      }
    },
    !disabled ? 10000 : null,
  );

  return null;
};

export const renderHelpReminder = () =>
  Alert.info(
    (
      <FlexRowCenter>
        <Icon size={25} name="flag" color={theme.color.pink100} />
        <Text fontSize="1.25em" paddingLeft={15}>
          {
            "Don't forget you can flag the slide by using the Help Ribbon if you have questions or concerns on any slide."
          }
        </Text>
      </FlexRowCenter>
    ) as any,
    {timeout: 20000, offset: 85},
  );
