import React, {ReactNode, FunctionComponent} from "react";
import {
  connect,
  MergeProps,
  MapStateToProps,
  MapDispatchToPropsFunction,
} from "react-redux";
import {Dispatch, bindActionCreators} from "redux";
import {CancelTokenSource} from "axios";
import {TableUserDto} from "@reside/reside-api-app";

import {UserId} from "../../store/user-permissions/user-permissions.common";
import {AppState, select} from "../../store/store";
import {
  selectUserHasPermission,
  selectUserPermissions,
} from "../../store/user-permissions/user-permissions.selectors";
import {createActions} from "../../store/user-permissions/user-permissions.actions";
import {useFetchPermissions} from "./RequestPermissionsForUser";

type OwnProps = Readonly<{
  permissions: ReadonlyArray<TableUserDto.PermissionsEnum>;
  noPermissionChildren?: ReactNode;
  children: ReactNode;
}>;

type StateProps = Readonly<{
  currentUserId: UserId;
  currentUserPermissions: ReadonlyArray<TableUserDto.PermissionsEnum>;
  hasSomePermissions: boolean;
}>;

type DispatchProps = Readonly<{
  dispatch: Dispatch;
}>;

type MergedProps = OwnProps &
  StateProps & {
    requestPermissionsForUser: RPFU;
  };

type RPFU = (
  cancelTokenSource: CancelTokenSource,
) => (dispatch: Dispatch, getState: () => AppState) => Promise<void>;

const Renderer: FunctionComponent<MergedProps> = props => {
  useFetchPermissions(props.requestPermissionsForUser);

  return props.hasSomePermissions ? (
    <>{props.children}</>
  ) : (
    <>{props.noPermissionChildren}</>
  );
};

Renderer.defaultProps = {
  noPermissionChildren: null,
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps,
) => {
  const currentUserId: UserId = select.adminSession.user(state).id;

  return {
    currentUserId,
    currentUserPermissions: selectUserPermissions(state),
    hasSomePermissions: ownProps.permissions
      ? ownProps.permissions.some(permission =>
          selectUserHasPermission(permission)(state),
        )
      : true,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    dispatch,
  };
};

const mergeProps: MergeProps<
  StateProps,
  DispatchProps,
  OwnProps,
  MergedProps
> = (stateProps, dispatchProps, ownProps) => {
  const actions = createActions(stateProps.currentUserId);
  const mergeProps = bindActionCreators(
    {
      requestPermissionsForUser: actions.requestPermissionsForUser,
    },
    dispatchProps.dispatch,
  );

  return {
    ...ownProps,
    ...stateProps,
    ...mergeProps,
  };
};

export const RequiredUserPermissionsSome = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Renderer);
