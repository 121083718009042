import {useQuery} from "react-query";
import {PccService} from "../../services/PccService";

export const useDocumentUploadCategoriesQuery = ({
  facilityId,
  enabled,
}: {
  facilityId: string;
  enabled: boolean;
}) =>
  useQuery(
    ["useDocumentUploadCategoriesQuery", facilityId],
    async ({queryKey: [_, facilityId]}) => {
      const {data} = await PccService.getDocumentUploadCategories(facilityId);
      return data;
    },
    {
      // Note: when the enabled is undefined, the query will run!
      enabled: Boolean(enabled),
    },
  );
