import React, {FunctionComponent} from "react";
import {connect} from "react-redux";
import {useQuery, useQueryClient} from "react-query";
import Alert from "react-s-alert";
import {UserDto} from "@reside/reside-api-app";

import {AppState, dispatch, Dispatch, select} from "../store";
import {facilityApi} from "../services/FacilityService";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {isUnauthorized} from "../services/api";
import {useRefreshUserProfile} from "../hooks/useRefreshUserProfileQuery";

type StateProps = Readonly<{isLoggedIn: boolean; userId: string}>;

type DispatchProps = Readonly<{
  setUser: ({
    user,
    isUserLogin,
  }: {
    user: UserDto;
    isUserLogin: boolean;
  }) => Promise<void>;
  setFacilities: (data: any) => Promise<void>;
}>;

export const FacilitiesQueryName = "Facilities";

export const useFetchFacilitiesQuery = () => {
  const queryClient = useQueryClient();

  return {
    fetchFacilitiesQuery: () => queryClient.fetchQuery(FacilitiesQueryName),
  };
};

const Renderer: FunctionComponent<StateProps & DispatchProps> = ({
  children,
  setFacilities,
  setUser,
  isLoggedIn,
  userId,
}) => {
  useQuery(
    FacilitiesQueryName,
    async () => {
      const {data} = await facilityApi.getFacilitiesUsingGET(
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: isLoggedIn,
      onSuccess: data => setFacilities(data?.content),
      onError: (error: any) => {
        if (!isUnauthorized(error.status)) {
          Alert.error("Failed to refresh facilities.");
        } else {
          dispatch.adminSession.logout();
        }
      },
    },
  );

  useRefreshUserProfile({
    userId,
    setUser: user => setUser({user, isUserLogin: false}),
  });

  return <>{children}</>;
};

const mapState = (state: AppState) => ({
  isLoggedIn: select.adminSession.isLoggedIn(state),
  userId: select.adminSession.user(state)?.id,
});

const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setUser: dispatch.adminSession.setUser,
  setFacilities: dispatch.adminSession.setFacilities,
});

export const AdminAppRefresh = connect(mapState, mapDispatch)(Renderer);
