import {useHistory, useRouteMatch} from "react-router-dom";
import {Button, FlexRow} from "@reside/ui";
import {SftpConfig} from "../../services/HubAndSpokeService";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {ButtonLink} from "../../atoms/button-link";
import {Column, ListTable} from "../../atoms/list-table";
import {Space} from "../../atoms/space";
import {Spinner} from "../../atoms/spinner";
import {useSftpConfigsQuery} from "../../services/hooks/useSftpConfigsQuery";
import {useDeleteSftpConfig} from "../../services/hooks/useDeleteSftpConfig";

type Row = {
  rowData: SftpConfig;
};

export const SftpConfigurations = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const {data, isLoading} = useSftpConfigsQuery();
  const {
    mutate: deleteSftpConfig,
    isLoading: isLoadingDeleteSftpConfig,
    variables: deletedSftpConfigId,
  } = useDeleteSftpConfig();

  return (
    <>
      {!isLoading && data ? (
        <>
          <FlexRow>
            <AdminPageTitle>SFTP Configurations</AdminPageTitle>
            <Space>
              <ButtonLink
                to={`${match.path}/create`}
                color="primary"
                outline={false}
                testId="add-target-form-button"
              >
                New SFTP Configuration
              </ButtonLink>
            </Space>
          </FlexRow>
          <FlexRow>
            <ListTable
              key="sftp-configs"
              sortedData={data}
              getRowId={row => row.rowData.id}
              rowHeight={120}
            >
              <Column
                cellRenderer={(row: Row) => <p>{row.rowData.name}</p>}
                label="Name"
                sortKey="name"
                width={200}
              />
              <Column
                cellRenderer={(row: Row) => <p>{row.rowData.hostname}</p>}
                label="Hostname/IP Address"
                sortKey="hostname"
                width={200}
              />
              <Column
                cellRenderer={(row: Row) => <p>{row.rowData.port}</p>}
                label="Port"
                sortKey="port"
                width={200}
              />
              <Column
                cellRenderer={(row: Row) => <p>{row.rowData.notes}</p>}
                label="Notes"
                sortKey="notes"
                width={400}
              />
              <Column
                cellRenderer={({rowData: {id}}: Row) => (
                  <Button
                    onClick={() => history.push(`${match.path}/${id}/edit`)}
                    color="default"
                    testId="edit-target-form-button"
                  >
                    Edit
                  </Button>
                )}
                width={100}
              />
              <Column
                cellRenderer={({rowData: {id}}: Row) => (
                  <Button
                    onClick={() => deleteSftpConfig(id)}
                    disabled={
                      isLoadingDeleteSftpConfig && deletedSftpConfigId === id
                    }
                  >
                    {isLoadingDeleteSftpConfig && deletedSftpConfigId === id
                      ? "Deleting Configuration..."
                      : "Delete"}
                  </Button>
                )}
                width={100}
              />
            </ListTable>
          </FlexRow>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
