import {useEffect, useState} from "react";
import {useTimeoutFn} from "react-use";
import {
  EVENTS,
  AUTO_LOGOUT_TIMES,
  LOGGED_OUT_DUE_TO_INACTIVITY,
} from "../utils/constants/autoLogout";

export const useAutoLogoutDueToInactivity = ({
  logout,
}: {
  logout: () => Promise<void>;
}) => {
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(59);
  let countdownInterval: NodeJS.Timeout | null = null;

  // Clear countdown function
  const clearCountdownInterval = () => {
    if (countdownInterval) {
      clearInterval(countdownInterval);
      countdownInterval = null;
    }
  };

  // Function to start the countdown
  const startCountdown = () => {
    clearCountdownInterval(); // Clear existing interval if any
    setSecondsRemaining(59); // Reset the countdown to 59 seconds to avoid having 00:60 seconds displayed
    countdownInterval = setInterval(() => {
      setSecondsRemaining(seconds => {
        if (seconds <= 1) {
          clearCountdownInterval(); // Stop the countdown when it reaches 0
          return 0;
        }
        return seconds - 1;
      });
    }, 1000);
  };
  // Reset function to be called by event listeners

  const resetOnActivity = () => {
    if (!showLogoutWarning) {
      // skipcq - passing resetWarning as prop to satisfy "Detected the use of variables before they are defined" breaks event listeners
      resetWarning();
      // skipcq - passing resetLogout as prop to satisfy "Detected the use of variables before they are defined" breaks event listeners
      resetLogout();
    }
  };

  const [, , resetLogout] = useTimeoutFn(async () => {
    Object.values(EVENTS).forEach(event => {
      window.removeEventListener(event, resetOnActivity);
    });

    await logout();
    sessionStorage.setItem(LOGGED_OUT_DUE_TO_INACTIVITY, "true");
  }, AUTO_LOGOUT_TIMES.INACTIVITY_ACCROSS_APPLICATION); // Log out inactive user after 15 minutes of inactivity

  const [, cancelWarning, resetWarning] = useTimeoutFn(() => {
    // Remove event listeners to stop resetting on activity
    Object.values(EVENTS).forEach(event => {
      window.removeEventListener(event, resetOnActivity);
    });
    setShowLogoutWarning(true);
    startCountdown();
  }, AUTO_LOGOUT_TIMES.INACTIVITY_ACCROSS_APPLICATION - 60000); // Show warning 1 minute before logout

  // This function resets the logout and warning timeouts and re-adds the event listeners
  const resetOnButtonClick = () => {
    resetWarning(); // Resets the warning timeout
    resetLogout(); // Resets the logout timeout
    setShowLogoutWarning(false); // Hide the warning
    Object.values(EVENTS).forEach(event => {
      window.addEventListener(event, resetOnActivity);
    }); // Re-add event listeners to reset on activity
    setSecondsRemaining(59);
  };

  useEffect(() => {
    Object.values(EVENTS).forEach(event => {
      window.addEventListener(event, resetOnActivity);
    });

    return () => {
      cancelWarning();
      clearCountdownInterval();
      Object.values(EVENTS).forEach(event => {
        window.removeEventListener(event, resetOnActivity);
      });
    };
    // eslint-disable-next-line
  }, []);

  // Return the state to control the warning's visibility and the reset function for the button click
  return {showLogoutWarning, resetOnButtonClick, secondsRemaining};
};
