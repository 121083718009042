import React, {createContext, FunctionComponent, useContext} from "react";
import {useToggle} from "react-use";

const permissionManagementModalContext = createContext({
  isOpen: false,
  toggleModal: () => {},
});

export const usePermissionManagementModalContext = () =>
  useContext(permissionManagementModalContext);

export const PermissionModalContextProvider: FunctionComponent = ({
  children,
}) => {
  const [isOpen, toggleModal] = useToggle(false);

  return (
    <permissionManagementModalContext.Provider value={{isOpen, toggleModal}}>
      {children}
    </permissionManagementModalContext.Provider>
  );
};
