import {capitalize} from "lodash";
import {Color} from "@reside/ui";
import {RoleDto} from "@reside/reside-api-app";

import {UserStatus} from "../../store/users/usersModel";

const USER_STATUS_MAP: Record<UserStatus, string> = {
  ACTIVE: "Active",
  NEW: "New",
};

const ORGANIZATION_STATUS_MAP: Record<string, string> = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const readableStatus = (key: UserStatus) =>
  ({...USER_STATUS_MAP, ...ORGANIZATION_STATUS_MAP}[key]);

export const makeReadableEnum = <E>(
  someEnum: E,
  overrides: Record<string, string> = {},
) => {
  const translated = {
    ...Object.keys(someEnum).reduce<Record<string, string>>(
      (res, key) => ({
        ...res,
        [key]: key.split("_").map(capitalize).join(" "),
      }),
      {},
    ),
    ...overrides,
  };

  return (key: keyof E) => translated[key as string];
};

export const readableRole = makeReadableEnum(RoleDto.NameEnum);

const USER_STATUS_COLORMAP: Record<UserStatus, Color> = {
  ACTIVE: "mint100",
  NEW: "golden100",
};

export const colorMap = (key: UserStatus) => USER_STATUS_COLORMAP[key];

type TODO = any;

export const updateTableKey = (selectedStatus: TODO, facility: TODO) => {
  const sortPredicate = selectedStatus ? selectedStatus.id || "ALL" : "ALL";

  return sortPredicate.concat(facility ? facility.id : "");
};

export const getUserOrResidentValue = (data: TODO, key: TODO) => {
  if ("answers" in data) {
    return Object.prototype.hasOwnProperty.call(data.answers, `resident_${key}`)
      ? data.answers[`resident_${key}`].value
      : null;
  }

  return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : null;
};
