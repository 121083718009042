import React, {useState} from "react";
import {connect} from "react-redux";
import {
  Box,
  FlexRowCenter,
  styled,
  Button,
  ThinScrollbarArea,
} from "@reside/ui";
import {useInfiniteQuery} from "react-query";

import {DocumentTitle} from "../../atoms/document-title";
import {useSort} from "../../services/api/pagination";
import {AppState, select} from "../../store";
import {history} from "../../utils/history";
import {FacilityDropdownTreeSelect} from "../../atoms/facility-dropdown-tree-select";
import {BackdropSpinner, Spinner} from "../../atoms/spinner";
import {Space} from "../../atoms/space";
import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {AdminUsersSearch} from "../../atoms/admin-search";
import {UsersStatusSelect, UserStatusOptions} from "./atoms/UsersStatusSelect";
import {UsersTable, initialSortProperties} from "./atoms/UsersTable";
import {SortDirection} from "../../store/reporting-reports/reporting-reports.common";
import {UserService} from "../../services/UserService";
import {logErrorToSentry} from "../../utils/sentry";

const PAGE_SIZE = 30;

const Renderer = ({
  visibleRoles,
  activeFacilitiesTreeKeys,
  hasSelectedFacilities,
}: any) => {
  // TODO: merge into usePageable?
  const {sort, setSort} = useSort(initialSortProperties, SortDirection.ASC);
  const [page, setPage] = useState(0);
  const [userStatus, setUserStatus] = useState<UserStatusOptions>(null);
  const {data, hasNextPage, fetchNextPage, isFetchingNextPage} =
    useInfiniteQuery(
      [
        "usersTable",
        {sort, userStatus, facilities: activeFacilitiesTreeKeys, visibleRoles},
      ],
      async ({pageParam = 0}) => {
        const {data} = await UserService.fetchUsers({
          facilityIds: activeFacilitiesTreeKeys ?? [],
          statuses: userStatus ? [userStatus] : ["NEW", "ACTIVE"],
          roles: visibleRoles,
          pageable: {
            page: pageParam,
            size: PAGE_SIZE,
            ...sort,
          },
        });
        return data;
      },
      {
        getNextPageParam: ({content, totalPages}) => {
          const isLastPage = totalPages === (page + 1) * PAGE_SIZE;

          return content.length === PAGE_SIZE && !isLastPage ? page + 1 : false;
        },
        cacheTime: 0,
        refetchOnMount: false,
        onError: (err: Response) => {
          logErrorToSentry(err, {
            queryKey: "usersTable",
            sort,
            userStatus,
            facilities: activeFacilitiesTreeKeys,
            visibleRoles,
          });
        },
      },
    );

  return (
    <>
      <DocumentTitle title="Reside - Admin - Users" />
      <AdminLayoutFullHeightAndTwoCells>
        <ActionBar selectedStatus={userStatus} onStatusSelect={setUserStatus} />
        <ThinScrollbarArea>
          {!data ? (
            <Spinner />
          ) : (
            <BackdropSpinner active={!!isFetchingNextPage}>
              <UsersTable
                users={
                  hasSelectedFacilities
                    ? data.pages.flatMap(item => item?.content)
                    : []
                }
                sort={sort}
                onChangeSort={setSort}
                showLoadMore={hasNextPage}
                onLoadMore={() => {
                  setPage(page + 1);
                  fetchNextPage();
                }}
              />
            </BackdropSpinner>
          )}
        </ThinScrollbarArea>
      </AdminLayoutFullHeightAndTwoCells>
    </>
  );
};

const mapState = (state: AppState) => ({
  visibleRoles: select.adminSession.visibleRoles(state),
  activeFacilitiesTreeKeys: select.adminSession.activeFacilitiesTreeKeys(state),
  hasSelectedFacilities: select.adminSession.hasSelectedFacilities(state),
});

export const PageAdminUsers = connect(mapState)(Renderer);

const ActionBar = ({selectedStatus, onStatusSelect}: any) => {
  return (
    <FlexRowCenter style={{paddingBottom: 25}}>
      <Box flexDirection="row" flex="2" alignItems="center">
        <FacilityDropdownTreeSelect />
      </Box>
      <Space>
        <UsersStatusSelect
          selectedStatus={selectedStatus}
          onStatusSelect={onStatusSelect}
        />
        <SearchWrapper>
          <AdminUsersSearch />
        </SearchWrapper>
        <CreateUserButton />
      </Space>
    </FlexRowCenter>
  );
};

const SearchWrapper = styled.div`
  min-width: 300px;
`;

const CreateUserButton = () => (
  <Button
    testId="addUserButton"
    color="primary"
    onClick={() => history.push("/admin/users/create")}
  >
    New User
  </Button>
);
