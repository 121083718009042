import {validator} from "@reside/forms";
import {CreateResidentDto, CreateResidentValidationRules} from "../types";

const getValidationRules = (
  isResideAdminUser: boolean,
  isEditForm: boolean,
): CreateResidentValidationRules => {
  const validationRules: CreateResidentValidationRules = {
    first_name: "required",
    last_name: "required",
    email: ["email"],
    phone: ["phone"],
    date_of_birth: ["required", "date", "date_in_past"],
    gender: "required",
    ssn: "required",
  };

  return isResideAdminUser && !isEditForm
    ? {...validationRules, organization_id: "required"}
    : validationRules;
};

export const validate = (
  values: CreateResidentDto,
  isResideAdminUser: boolean,
  isEditForm: boolean,
) =>
  validator.validateAll(
    values,
    getValidationRules(isResideAdminUser, isEditForm),
  );
