var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import { styled } from "../theme";
export var Grid = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  width: 100%;\n  grid-column-gap: 10px;\n  justify-items: stretch;\n  align-items: stretch;\n\n  @media (min-width: ", "px) {\n    grid-template-columns: repeat(", ", 1fr);\n\n    ", ";\n    ", ";\n  }\n"], ["\n  display: grid;\n  width: 100%;\n  grid-column-gap: 10px;\n  justify-items: stretch;\n  align-items: stretch;\n\n  @media (min-width: ", "px) {\n    grid-template-columns: repeat(", ", 1fr);\n\n    ", ";\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoint.lg;
}, function (_a) {
    var columns = _a.columns;
    return columns;
}, function (_a) {
    var columnStart = _a.columnStart;
    return columnStart && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        grid-column-start: ", ";\n      "], ["\n        grid-column-start: ", ";\n      "])), columnStart);
}, function (_a) {
    var columnEnd = _a.columnEnd;
    return columnEnd && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        grid-column-end: ", ";\n      "], ["\n        grid-column-end: ", ";\n      "])), columnEnd);
});
var templateObject_1, templateObject_2, templateObject_3;
