import React from "react";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {SplitButton} from "../../../../split-button";
import {EditPreflightButton} from "../EditPreflightButton";
import {ResendEmailDropdownItem} from "./ResendEmailDropdownItem";
import {DeleteAdmissionDropdownItem} from "../DeleteAdmissionDropdownItem";
import {ResidentLoginDropdownItem} from "./ResidentLoginDropdownItem";
import {ViewAdmissionDropdownItem} from "../ViewAdmissionDropdownItem";
import {DownloadPDFDropdownItem} from "../DownloadPDFDropdownItem";
import {ChangeHistoryDropdownItem} from "./ChangeHistoryDropdownItem";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const InProgressSplitButton = ({admission}: Props) => (
  <SplitButton
    size="small"
    defaultOptions={<EditPreflightButton admission={admission} />}
  >
    <ResidentLoginDropdownItem admission={admission} />
    <ViewAdmissionDropdownItem admission={admission} />
    <ChangeHistoryDropdownItem admission={admission} />
    <ResendEmailDropdownItem admission={admission} />
    <DownloadPDFDropdownItem admission={admission} />
    <DeleteAdmissionDropdownItem admission={admission} />
  </SplitButton>
);
