var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { Field } from "formik";
import { CurrencySymbolMap } from "@reside/forms/dist/templateTypes";
import { FlexRowCenter } from "../../box";
import { FieldBorder } from "../atoms/FieldBorder";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { isValueFilled } from "../text-field";
import { Input } from "../atoms/TextualInput";
import { Label } from "../atoms/Label";
import { LockIcon } from "../atoms";
import { isInvalid } from "../formHelpers";
export var MATH_MAX_CALL_REGEX = /^(Math.max\()?(\(?[0-9.+\-*/(),]+\)?)+(,0\))?$/;
export var ComputationField = function (_a) {
    var name = _a.name, label = _a.label, placeholder = _a.placeholder, renderBefore = _a.renderBefore, restProps = __rest(_a, ["name", "label", "placeholder", "renderBefore"]);
    return (React.createElement(Field, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        return (React.createElement(Label, null,
            React.createElement(FloatingFieldLabel, { visible: isValueFilled(field.value) }, label),
            React.createElement(FlexRowCenter, null,
                renderBefore(field, restProps),
                React.createElement(ComputationInput, __assign({}, field, restProps, { update: function (value) {
                        return form.setFieldValue(name, String(value), false);
                    }, placeholder: (placeholder !== null && placeholder !== void 0 ? placeholder : typeof label === "string")
                        ? label
                        : "", value: field.value })),
                restProps.disabled && React.createElement(LockIcon, null)),
            React.createElement(FieldBorder, { focused: false, invalid: isInvalid(name, form) }),
            React.createElement(FieldError, { name: name })));
    }));
};
ComputationField.defaultProps = {
    renderBefore: function (field, props) {
        return props.currency && React.createElement(React.Fragment, null, CurrencySymbolMap[props.currency]);
    },
};
var ComputationInput = function (_a) {
    var type = _a.type, name = _a.name, value = _a.value, placeholder = _a.placeholder, disabled = _a.disabled, computation = _a.computation, formatter = _a.formatter, fractionalDigits = _a.fractionalDigits, onBlur = _a.onBlur, onChange = _a.onChange, update = _a.update;
    var expression = formatter(computation, 0);
    useEffect(function () {
        var prevValue = String(value);
        var nextValue = evalMathExpression(expression, {
            fractionalDigits: fractionalDigits,
        });
        if (prevValue !== nextValue) {
            update(nextValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computation, expression, fractionalDigits, update, value]);
    return (React.createElement(Input, { type: type, name: name, value: value, disabled: disabled, placeholder: placeholder, onBlur: onBlur, onChange: onChange }));
};
ComputationInput.defaultProps = {
    type: "number",
    onBlur: function () { },
    onChange: function () { },
    fractionalDigits: 2,
};
export var evalMathExpression = function (expression, options) {
    if (options === void 0) { options = {
        fractionalDigits: 2,
    }; }
    // eslint-disable no-useless-escape
    var expressionToTest = expression
        .replace(/new Date\(\S+\)/gi, "1")
        .replace(/Math.\w+/gi, "2+")
        .replace(/\?/gi, "-")
        .replace(/'[0-9-]*'/gi, "0")
        .replace(/===/gi, "-")
        .replace(/!==/gi, "-");
    if (!MATH_MAX_CALL_REGEX.test(expressionToTest)) {
        return "0";
    }
    // eslint-disable-next-line no-eval
    return String(Number(eval(expression)).toFixed(options.fractionalDigits));
};
