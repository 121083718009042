import React, {useState} from "react";
import {AdminPageTitle} from "../../../atoms/admin-layout/AdminPageTitle";
import {DocumentTitle} from "../../../atoms/document-title";
import {ResidentCard} from "./ResidentCard";
import {ResidentAdmissions} from "./ResidentAdmissions";
import {useFetchResident} from "../hooks/useFetchResident";
import {BackdropSpinner} from "../../../atoms/spinner";
import {AdminLayoutFullHeightAndTwoCells} from "../../../atoms/admin-layout";
import {ResidentAccountButton} from "./ResidentAccountButton";
import {AdminActionRow} from "../../../atoms/admin-action-row";
import {ButtonLink} from "../../../atoms/button-link";
import {ResidentStatusTag} from "./ResidentStatusTag";
import {AppState, select} from "../../../store";
import {connect} from "react-redux";
import {UserDto} from "@reside/reside-api-app";
import {Button} from "@reside/ui";
import {InactivationConfirmationModal} from "../../page-admin-residents-forms/atoms/InactivationConfirmationModal";
import {resolveResidentStatus} from "../helpers";
import {useActivateResident} from "../../page-admin-residents-forms/hooks/useActivateResident";

const Renderer = ({user}: {user: UserDto}): JSX.Element => {
  const {
    data: resident,
    isLoading,
    remove: clearStaleResident,
  } = useFetchResident();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {isActive} = resolveResidentStatus(resident?.status);
  const {mutate: activateResident} = useActivateResident();

  return (
    <AdminLayoutFullHeightAndTwoCells>
      <InactivationConfirmationModal
        residentId={resident?.id}
        isModalOpen={isModalOpen}
        closeModal={() => {
          clearStaleResident();
          setIsModalOpen(false);
        }}
      />
      <BackdropSpinner active={isLoading}>
        <DocumentTitle title="Reside - Admin - Resident Account" />
        {!isLoading ? (
          <div>
            <AdminActionRow
              title={
                <AdminPageTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    maxHeight: "80px",
                  }}
                >
                  <ResidentAccountButton />
                  <ResidentStatusTag status={resident?.status} />
                </AdminPageTitle>
              }
              actions={
                <>
                  {isActive && (
                    <Button
                      color="danger"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Delete resident
                    </Button>
                  )}

                  {!isActive ? (
                    <Button
                      type="submit"
                      color="primary"
                      onClick={() => {
                        activateResident(resident?.id);
                        clearStaleResident();
                      }}
                    >
                      Restore resident
                    </Button>
                  ) : (
                    <ButtonLink to={`/admin/residents/edit/${resident?.id}`}>
                      Edit
                    </ButtonLink>
                  )}
                </>
              }
            />

            <ResidentCard resident={resident} user={user} />

            <ResidentAdmissions admissions={resident?.admissions} />
          </div>
        ) : null}
      </BackdropSpinner>
    </AdminLayoutFullHeightAndTwoCells>
  );
};

const mapState = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const ResidentAccount = connect(mapState)(Renderer);
