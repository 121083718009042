import React from "react";
import {DropdownMenuItem} from "@reside/ui";
import {ChangeHistoryModalConsumer} from "../../change-history-modal";
import {getResidentTestId} from "../../../helper";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";

export const ChangeHistoryDropdownItem = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => (
  <ChangeHistoryModalConsumer>
    {({openModal}) => (
      <DropdownMenuItem
        testId={getResidentTestId(admission, "residentLogin")}
        onClick={() => {
          openModal(admission);
        }}
      >
        View document history
      </DropdownMenuItem>
    )}
  </ChangeHistoryModalConsumer>
);
