import React from "react";
import {connect} from "react-redux";
import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {AppState, select} from "../../store";
import {AdmissionsService} from "../../services/AdmissionsService";
import {DeleteAdmissionModal} from "../delete-admission-modal";
import {useDashboardAdmissionsContext} from "./DashboardAdmissionsContext";
import {ModalName, useDashboardModalContext} from "./DashboardModalContext";
import {readResidentNameAnswer} from "../../models/AdmissionModel.helpers";

type StateProps = Readonly<{
  activeFacilities: ReadonlyArray<any>;
}>;

const DashboardDeleteAdmissionModalRenderer = ({
  activeFacilities,
}: StateProps) => {
  const {refreshDashboard} = useDashboardAdmissionsContext();
  const {admission, isModalOpened, toggleModal} = useDashboardModalContext(
    ModalName.CANCEL,
  );
  const admissionId = admission?.id;
  const {cancellationReasons = []} =
    activeFacilities.find(facility => facility.id === admission?.facilityId) ??
    {};

  const {mutate, isLoading} = useMutation(
    ({admissionId, reason}: {admissionId: string; reason: string}) =>
      AdmissionsService.delete(admissionId, reason),
    {
      onError: () => {
        Alert.error("Failed to delete the Admission.");
      },
      onSuccess: () => {
        Alert.success("Admission was successfully deleted.");
      },
      onSettled: () => {
        toggleModal();
        refreshDashboard();
      },
    },
  );

  return (
    <DeleteAdmissionModal
      admissionCode={admission?.code}
      residentName={admission ? readResidentNameAnswer(admission as any) : ""}
      isOpen={isModalOpened}
      isLoading={isLoading}
      onRequestClose={() => toggleModal()}
      cancellationReasons={cancellationReasons}
      onCancel={data =>
        mutate({
          admissionId,
          ...data,
        })
      }
      admissionStatus={admission?.status}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  activeFacilities: select.adminSession.facilities(state),
});

export const DashboardDeleteAdmissionModal = connect(mapStateToProps)(
  DashboardDeleteAdmissionModalRenderer,
);
