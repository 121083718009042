import {useQuery, UseQueryOptions} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService, PDFTemplate} from "../HubAndSpokeService";

export const usePdfTemplatesQuery = ({
  templateName,
  options,
}: {
  eventTriggerId?: string;
  templateName?: string;
  options?: UseQueryOptions<Array<PDFTemplate> | undefined>[];
}) =>
  useQuery(
    ["GET_PDF_TEMPLATES", templateName],
    async () => {
      const {data} = await HubAndSpokeService.getPdfTemplates(templateName);
      return data;
    },
    {
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to get PDF templates.");
      },
      cacheTime: 0,
      ...options,
    },
  );
