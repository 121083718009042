import React, {useEffect, useState} from "react";
import {Text, Button, Modal, ModalContent} from "@reside/ui";
import {format} from "date-fns";
import {ListTable, Column} from "../../../atoms/list-table";
import {TextCell} from "../../../atoms/list-table/SharedCells";
import {getAge} from "../../page-admin-residents/helpers";
import {Resident} from "../../page-admin-residents/types";
import {useFetchSimilarResidents} from "../hooks/useFetchSimilarResidents";
import {useHandleSimilarResidents} from "../hooks/useHandleSimilarResidents";
import {ResidentAdmissions} from "../../page-admin-residents/atoms/ResidentAdmissions";

type Row = {rowData: Resident; rowIndex: number};

export const SimilarResidents = ({
  isResideAdminUser,
}: {
  isResideAdminUser: boolean;
}): JSX.Element => {
  const CellRenderer = (value: string | number | Date) => (
    <TextCell>{value}</TextCell>
  );

  const [openedAdmissionsModal, setOpenedAdmissionsModal] = useState("");

  const {shouldSearchSimilarResidents, similarResidentsSearchData} =
    useHandleSimilarResidents();

  const {
    data: remoteResidents,
    mutate: fetchSimilarResidents,
    isLoading: isFetchingSimilarResidents,
  } = useFetchSimilarResidents();

  const [
    shouldShowSimilarResidentsWindow,
    setShouldShowSimilarResidentsWindow,
  ] = useState<boolean>(true);

  // Whenever new similar residents are sought for check if window should be shown. It should be shown only when similar residents exist.
  useEffect(() => {
    setShouldShowSimilarResidentsWindow(!!remoteResidents?.total_count);
    // eslint-disable-next-line
  }, [isFetchingSimilarResidents]);

  let searchDuplicatesDeps = [
    similarResidentsSearchData.first_name,
    similarResidentsSearchData.last_name,
    similarResidentsSearchData.date_of_birth,
    fetchSimilarResidents,
  ];

  if (isResideAdminUser) {
    searchDuplicatesDeps = [
      similarResidentsSearchData.organization_id,
      ...searchDuplicatesDeps,
    ];
  }

  useEffect(() => {
    // Whenever conditions are met similar residents will be searched.
    if (shouldSearchSimilarResidents) {
      fetchSimilarResidents(similarResidentsSearchData);
    }
    // eslint-disable-next-line
  }, searchDuplicatesDeps);

  if (!shouldShowSimilarResidentsWindow) return null;

  return (
    <>
      <Text fontSize={24} margin="32px 0 16px 0" as="h1">
        Similar residents have been found
      </Text>
      <Text as="p" fontSize="1rem" marginBottom={32}>
        Please check if this resident already exists:
      </Text>

      <ListTable<Resident>
        key="12345"
        data={remoteResidents?.data ?? []}
        getRowId={row => row.rowData.id}
        noRowsMessage="No residents to display"
      >
        {/* <Column
          cellRenderer={({rowData}: Row) => (
            <Radio
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
              }}
              name="similar_resident"
              type="radio"
              // Stringify object so it can be used as a value of radio button.
              value={JSON.stringify({
                first_name: rowData.first_name,
                middle_name: rowData.middle_name,
                last_name: rowData.last_name,
                date_of_birth: rowData.date_of_birth,
                gender: rowData.gender,
                phone_number: rowData.phone_number,
                email: rowData.email,
                ssn: rowData.ssn,
              })}
            />
          )}
          width={100}
        /> */}

        <Column
          cellRenderer={({rowData}: Row) => {
            const name = `${rowData.first_name} ${
              rowData.middle_name ? rowData.middle_name : ""
            } ${rowData.last_name}`;

            return CellRenderer(name);
          }}
          label="Resident"
          width={250}
        />

        <Column
          cellRenderer={({rowData}: Row) => CellRenderer(rowData.gender)}
          label="Gender"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(
              rowData.date_of_birth
                ? format(new Date(rowData.date_of_birth), "MM/dd/yyyy")
                : "—",
            )
          }
          label="Date of Birth"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(getAge(rowData.date_of_birth).toString())
          }
          label="Age"
          width={100}
        />

        <Column
          cellRenderer={({rowData}: Row) => CellRenderer(rowData.ssn)}
          label="Social Security Number"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) => (
            <>
              <Button
                type="link"
                onClick={() => {
                  setOpenedAdmissionsModal(rowData.id);
                }}
              >
                See admissions
              </Button>
              <Modal
                closeButtonPosition="right"
                size="large"
                isOpen={openedAdmissionsModal === rowData.id}
                onRequestClose={() => setOpenedAdmissionsModal("")}
              >
                {({closeModal}) => (
                  <ModalContent style={{height: "80"}}>
                    <ResidentAdmissions
                      admissions={rowData.admissions}
                      showMoreOptions={false}
                    />
                  </ModalContent>
                )}
              </Modal>
            </>
          )}
          width={50}
        />
      </ListTable>

      {/** Since this code will actually be used when similar residents appear in admissions I leave it commented out and will work on it's functionality when the time comes. */}

      {/*  <FlexRow style={{justifyContent: "flex-end", gap: 16, marginTop: 32}}>
        <Button
          onClick={() => {
            setShouldShowSimilarResidentsWindow(false);
          }}
        >
          Resident doesn&apos;t exist
        </Button>
        <Button
          color="primary"
          disabled={!selectedExistingResident}
          onClick={() => {
            returnToResidentsPage();
            setShouldShowSimilarResidentsWindow(false);
          }}
        >
          Select account
        </Button>
      </FlexRow> */}
    </>
  );
};
