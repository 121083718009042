import React from "react";
import {useQuery} from "react-query";
import {css} from "@emotion/css";
import Alert from "react-s-alert";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
import {embedDashboard} from "amazon-quicksight-embedding-sdk";
import {BackdropSpinner} from "../../../atoms/spinner";

import {
  QuicksightDashboard,
  QuicksightService,
} from "../../../services/QuicksightService";
import {logErrorToSentry} from "../../../utils/sentry";
import {UseScrollSpy} from "../../../hooks/useScrollSpy";

type Props = Readonly<QuicksightDashboard>;

export const DASHBOARD_REPORT_ID = "dashboard" as const;

export const ReportQuicksightDashboard = ({id}: Props) => {
  const elementId = `embed-${id}`;

  const {isLoading} = useQuery(
    ["quicksightDashboard", id],
    async () => {
      const {data} = await QuicksightService.getDashboard(id);

      return data;
    },
    {
      onSuccess: data => {
        const dashboard = embedDashboard({
          url: data.uri,
          container: document.getElementById(elementId),
          scrolling: "no",
          footerPaddingEnabled: true,
        });

        dashboard.on("error", (error: any) => {
          logErrorToSentry("Failed to embed Quicksight dashboard.", error);
          Alert.error("Failed to embed the dashboard. Try to reload the page.");
        });
      },
      onError: error => {
        logErrorToSentry(error);
        Alert.error("Failed to get the dashboard URL.");
      },
    },
  );

  return (
    <UseScrollSpy id={id}>
      {({scrollSpyRef}) => (
        <BackdropSpinner
          active={isLoading}
          className={css`
            height: 100%;
          `}
        >
          {/* Note: the key is important to flush old iframes when switching dashboards. */}
          <div
            ref={scrollSpyRef}
            key={elementId}
            id={elementId}
            style={{height: "100%"}}
          />
        </BackdropSpinner>
      )}
    </UseScrollSpy>
  );
};
