var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "../theme";
var defaultComponents = {
    Wrapper: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    flex-wrap: wrap;\n\n    & > * {\n      margin-top: 0 !important;\n      margin-bottom: 0 !important;\n    }\n  "], ["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    flex-wrap: wrap;\n\n    & > * {\n      margin-top: 0 !important;\n      margin-bottom: 0 !important;\n    }\n  "]))),
    Left: styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n  "], ["\n    display: flex;\n  "]))),
    Right: styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    margin: 0;\n  "], ["\n    display: flex;\n    margin: 0;\n  "]))),
};
export var FormHeader = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return children(props);
};
FormHeader.defaultProps = defaultComponents;
var templateObject_1, templateObject_2, templateObject_3;
