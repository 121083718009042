import {useDocumentUploadCategoriesQuery} from "../../../models/pcc";
import {usePointClickCareStatus} from "../../../hooks/usePointClickCareStatus";
import {Facilities} from "../../../models/types/AdmissionForm";
import {useFacilitySettingsParams} from "./useFacilitySettingsParams";

export const useFacilityDocumentUploadCategoriesQuery = (
  facilities: Facilities,
) => {
  const {facilityId} = useFacilitySettingsParams();

  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  return useDocumentUploadCategoriesQuery({
    facilityId,
    enabled: isPccEnabled,
  });
};
