import React from "react";
import {IReactToPrintProps, useReactToPrint} from "react-to-print";
import Alert from "react-s-alert";

import {Button} from "@reside/ui";

import {logErrorToSentry} from "../../../../utils/sentry";

type Props = Pick<
  IReactToPrintProps,
  "onBeforeGetContent" | "onBeforePrint" | "content" | "documentTitle"
>;

export const PrintQRButton = (props: Props) => {
  const handlePrint = useReactToPrint(props);

  /**
   * Using try-catch for when the print is not supported/allowed in browser.
   */
  const onClick = () => {
    try {
      handlePrint();
    } catch (error) {
      Alert.error(
        "Can't print at this time. Please make sure dialog windows are allowed in your browser",
      );
      logErrorToSentry(error);
    }
  };

  return (
    <Button
      size="medium"
      icon="print"
      color="neutral"
      outline
      onClick={onClick}
    >
      Print QR Code
    </Button>
  );
};
