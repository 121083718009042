import {FeatureFlagsControllerApi} from "@reside/reside-api-app";
import portableFetch from "portable-fetch";

import {BASE_PATH} from "../config";

export const featureFlagsApi = new FeatureFlagsControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);
