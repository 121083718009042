import React, {ReactNode} from "react";

import {styled} from "@reside/ui";
type Props = Readonly<{
  title: ReactNode;
  subtitle?: ReactNode;
  small?: boolean;
}>;

export const CardTitle = ({title, subtitle, small}: Props) => (
  <Wrapper>
    <Title small={small}>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Wrapper>
);

const Wrapper = styled.div`
  line-height: 21px;
`;

const Title = styled.div<{small?: boolean}>`
  font-weight: ${({theme}) => theme.fontWeight.bold};
  font-size: ${({small}) => (small ? "12px" : "15px")};
`;

const Subtitle = styled.div`
  font-size: 12px;
`;
