import React from "react";
import {DropdownMenuItem} from "@reside/ui";

import {ResidentLoginModalConsumer} from "../../resident-login-modal";
import {getResidentTestId} from "../../../helper";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";

export const ResidentLoginDropdownItem = ({
  admission,
}: {
  admission: AdmissionFromSearch;
}) => (
  <ResidentLoginModalConsumer>
    {({openModal}) => (
      <DropdownMenuItem
        testId={getResidentTestId(admission, "residentLogin")}
        onClick={() => {
          openModal(admission);
        }}
      >
        Start Resident Experience
      </DropdownMenuItem>
    )}
  </ResidentLoginModalConsumer>
);
