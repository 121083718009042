import React, {ReactElement, useEffect} from "react";

import {Config, configureHttp} from "../services/api";
import {dispatch, select, store} from "../store";
import {AUTH_SIGN_IN_URL} from "../services/AuthService";
import {useClearAndCancelCache} from "../hooks/useClearAndCancelCache";

const useApiClientConfiguration = (config: Config) =>
  useEffect(() => {
    configureHttp(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

export const UseApiClientConfiguration = ({
  config,
  children = () => null,
}: {
  config: Config;
  children?: (props: any) => ReactElement;
}) => children(useApiClientConfiguration(config));

export const AdminApiClientConfiguration = () => {
  const {clearAndCancelCache} = useClearAndCancelCache();
  return (
    <UseApiClientConfiguration
      config={{
        getToken: () => select.adminSession.getToken(store.getState()),
        setToken: token =>
          dispatch.adminSession.setToken({token, isLoginToken: false}),
        logout: () => {
          dispatch.adminSession.logout();
          clearAndCancelCache();
        },
        // auth/sign-in will respond with status UNAUTHORIZED for bad password and login combination
        skipUnauthorizedHandling: config => {
          return config?.url === AUTH_SIGN_IN_URL;
        },
      }}
    />
  );
};

export const AdmissionApiClientConfiguration = () => (
  <UseApiClientConfiguration
    config={{
      getToken: () => select.admissionSession.getToken(store.getState()),
      setToken: dispatch.admissionSession.setToken,
      logout: dispatch.admissionSession.logout,
    }}
  />
);
