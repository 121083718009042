import React, {memo} from "react";
import {toDate} from "date-fns";
import {styled, Icon, DATE_FORMAT} from "@reside/ui";

import {RelativeDateTime} from "../relative-date-time";

type Props = Readonly<{
  /**
   * Unix time.
   */
  date: number;
}>;

export const RelativeDate = memo(({date}: Props) => {
  const parsedDate = toDate(date * 1000);

  return (
    <Container>
      <Icon name="clock" size={11} />
      <RelativeDateTime
        dateTime={parsedDate}
        dateFormat={DATE_FORMAT.DAY_IN_MONTH}
      />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  padding: 5px 10px 5px 0;

  .icon {
    margin: 1px 2px 0 -3px;
  }
`;
