import React from "react";
import {Icon, styled} from "@reside/ui";

export const CloseButton = ({onClose}: {onClose: () => void}) => (
  <Wrapper onClick={onClose} data-test-id="closeNoteForm">
    <Icon name="close" color="#000" size={14} />
  </Wrapper>
);

const Wrapper = styled.div`
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 10px;
  &:hover {
    opacity: 0.5;
  }
`;
