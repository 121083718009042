import {uniq} from "lodash";
import {IconName} from "@reside/ui";

import {TableUserDto} from "@reside/reside-api-app";
import {ChecklistItem} from "../../../services/ReportingService";
import {
  Section,
  reportingMenuGeneral,
  reportingMenuOther,
  sectionToMenuItem,
} from "../constants";
import {getSectionItemsPermissions} from "../permissions.helpers";
import {CHECKLIST_ITEM_REPORT_ID} from "../reports";
import {QuicksightDashboard} from "../../../services/QuicksightService";
import {DASHBOARD_REPORT_ID} from "../reports/ReportQuicksightDashboard";

export type MenuItem = Readonly<{
  label: string;
  icon?: IconName;
  urlComponent: string;
  children?: ReadonlyArray<MenuItem>;
  permissions?: ReadonlyArray<TableUserDto.PermissionsEnum>;
  sectionItems?: ReadonlyArray<string>;
  isNavigationEnabled?: boolean;
}>;

export const getMenuItemPermissions = (
  menuItem: MenuItem,
): TableUserDto.PermissionsEnum[] =>
  uniq([
    ...(menuItem.children ?? []).reduce(
      (permissions, childMenuItem) => [
        ...permissions,
        ...getMenuItemPermissions(childMenuItem),
      ],
      [],
    ),
    ...getSectionItemsPermissions(menuItem.sectionItems || []),
  ]);

export const getMenuItemSections = (item: MenuItem): string[] => [
  ...(item.sectionItems || []),
  ...(item.children || []).flatMap(getMenuItemSections),
];

/**
 * Create reporting menu definition with dynamic checkbox menu.
 */
export const getMenuDefinition = (
  checkboxMenu: ReadonlyArray<MenuItem>,
  dashboardMenu: ReadonlyArray<MenuItem>,
): ReadonlyArray<MenuItem> => [
  {
    label: "Reporting",
    urlComponent: "reporting",
    icon: "reports",
    children: [
      {
        label: "General",
        urlComponent: "general",
        children: reportingMenuGeneral,
      },
      {
        label: "Checkboxes",
        urlComponent: "checkboxes",
        children: checkboxMenu,
      },
      {
        label: "Other",
        urlComponent: "other",
        children: reportingMenuOther,
      },
    ],
  },
  {
    label: "Analytics",
    urlComponent: "analytics",
    icon: "analytics",
    children: [
      {
        label: "Dashboards",
        urlComponent: "dashboards",
        children: dashboardMenu,
      },
    ],
  },
];

export const mapDashboardsToMenuDefinition = (
  dashboards: ReadonlyArray<QuicksightDashboard>,
) =>
  dashboards.map(({id, name}) => ({
    label: name,
    urlComponent: id,
    sectionItems: [DASHBOARD_REPORT_ID],
    permissions: [TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS],
  }));

export const mapChecklistItemToMenuDefinition = (
  checklistItems: ReadonlyArray<ChecklistItem>,
) =>
  checklistItems
    .map<Section>(({id, description}) => ({
      id,
      title: description,
      sectionItems: [CHECKLIST_ITEM_REPORT_ID],
    }))
    .map(sectionToMenuItem);
