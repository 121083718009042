import React from "react";
import {Formik} from "formik";
import {validator} from "@reside/forms";
import {Button, TextAreaField} from "@reside/ui";

import {CloseButton} from "./CloseButton";

import "./NoteForm.scss";

type Values = {
  note: string;
};

export const NoteForm = ({
  onClose,
  onSubmit,
  slideIndex,
}: {
  onClose: () => void;
  onSubmit: (values: Values) => Promise<void>;
  slideIndex: number;
}) => (
  <Formik<Values>
    initialValues={{note: ""}}
    validate={values =>
      validator.validateAll(values, {
        note: "required",
      })
    }
    onSubmit={onSubmit}
  >
    {formik => (
      <div className="noteform-0-57" data-test-id={`newNote-${slideIndex}`}>
        <CloseButton onClose={onClose} />
        <TextAreaField
          name="note"
          label="Note text"
          className="noteform-0-58"
        />
        <Button
          type="submit"
          color="success"
          onClick={formik.submitForm}
          className="noteform-0-59"
        >
          Send Note
        </Button>
      </div>
    )}
  </Formik>
);
