var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import DayPickerInputComponent from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import { css } from "@emotion/react";
import { format, isValid, parse, parseISO } from "date-fns";
import { inRange } from "lodash";
import { styled } from "../theme";
import { YearMonthPicker } from "../year-month-picker";
import { BaseInput } from "../form/atoms/TextualInput";
import { formatFieldValue } from "../form/text-field";
import { hasNativeDatepicker, isDateTypeSupported } from "./helpers";
import { CaptionForm, Container } from "./DatePicker";
import { DATE_FORMAT } from "./date";
export var DatePickerInput = forwardRef(function (_a, ref) {
    var inputProps = _a.inputProps, Component = _a.component, value = _a.value, range = _a.range, style = _a.style, testId = _a.testId, onChange = _a.onChange, onDayChange = _a.onDayChange, parseDate = _a.parseDate, formatDate = _a.formatDate, fnIsDateTypeInput = _a.isDateTypeInput, fnHasNativeDatepicker = _a.hasNativeDatepicker, FORMAT = _a.FORMAT, IMPORTANT_MAX_DATE = _a.IMPORTANT_MAX_DATE;
    var pickerRef = useRef();
    var isDateTypeInput = fnIsDateTypeInput();
    var hasNativeDatepicker = fnHasNativeDatepicker();
    var props = __assign({ testId: "datepicker-input-".concat(testId !== null && testId !== void 0 ? testId : ""), max: IMPORTANT_MAX_DATE, maxLength: IMPORTANT_MAX_DATE.length, hasNativeDatepicker: hasNativeDatepicker }, inputProps);
    /**
     * We keep clone of the date, so we don't rely on parent storing the date and providing it back on every change.
     * Required to render the YearMonthSelect correctly.
     */
    var _b = useState(function () {
        return value ? parseISO(value) : undefined;
    }), dateClone = _b[0], setDateClone = _b[1];
    var _c = useState(function () { return (value ? parseISO(value) : new Date()); }), yearMonthDateClone = _c[0], setYearMonthDateClone = _c[1];
    var _d = useState(function () {
        return value
            ? isValid(parseISO(value)) && format(parseISO(value), FORMAT)
            : undefined;
    }), valueClone = _d[0], setValueClone = _d[1];
    var setDate = useCallback(function (date) {
        isDateTypeInput
            ? isValid(date) && setDateClone(date)
            : isValid(date) && setValueClone(format(date, FORMAT));
        isValid(date) && setYearMonthDateClone(date);
    }, [FORMAT, isDateTypeInput]);
    var inputValue = isDateTypeInput
        ? isValid(dateClone)
            ? format(dateClone, DATE_FORMAT.ISO_DATE)
            : ""
        : valueClone;
    useEffect(function () {
        if (value) {
            var date_1 = parseISO(value);
            setDate(date_1);
        }
    }, [setDate, value]);
    var handleYearMonthChange = useCallback(function (date) {
        if (isDateTypeInput) {
            // Update the year and month without changing the day
            var clonedDate = new Date(dateClone);
            clonedDate.setFullYear(date.getFullYear());
            clonedDate.setMonth(date.getMonth());
            setDateClone(clonedDate);
            setYearMonthDateClone(clonedDate);
        }
        else {
            setYearMonthDateClone(date);
        }
    }, [dateClone, isDateTypeInput]);
    var captionElement = useMemo(function () {
        /**
         * YearMonth component to be rendered in the date-picker-modal.
         * @param date - date currently selected in the date-picker-modal;
         * @returns JSX.Element - YearMonth picker component;
         */
        function YearMonth(_a) {
            var date = _a.date;
            return (React.createElement(CaptionForm, { className: "DayPicker-Caption" },
                React.createElement(YearMonthPicker, { date: date, range: range, tabIndex: -1, onChange: handleYearMonthChange })));
        }
        YearMonth.displayName = "YearMonth";
        return YearMonth;
    }, [range, handleYearMonthChange]);
    var dateProps = __assign({ type: "date", placeholder: "".concat(format(new Date(), FORMAT)), onKeyDown: function (event) {
            if (event.key === "Escape") {
                event.currentTarget.blur();
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                setDateClone(undefined);
                onChange("");
                event.currentTarget.blur();
                //NOTE: prevent from rendering of native datepicker after enter is pressed
                event.preventDefault();
            }
            if (event.key === "Enter") {
                var todayDateIsoString = format(new Date(), DATE_FORMAT.ISO_DATE);
                var formattedDateValue = isValid(parseISO(event.currentTarget.value))
                    ? event.currentTarget.value
                    : todayDateIsoString;
                setDateClone(parseISO(formattedDateValue));
                onChange(formattedDateValue);
                event.currentTarget.blur();
                //NOTE: prevent from rendering of native datepicker after enter is pressed
                event.preventDefault();
            }
        } }, props);
    var textProps = __assign({ type: "tel", format: "date", placeholder: "mm/dd/yyyy", onKeyDown: function (event) {
            if (event.key === "Escape") {
                event.currentTarget.blur();
                event.preventDefault();
            }
            if (event.key === "Enter") {
                var date_2 = new Date();
                var dateFromValue = parseDate(event.currentTarget.value, DATE_FORMAT.FULL_DATE);
                var formattedDateValue = isValid(dateFromValue)
                    ? format(dateFromValue, FORMAT)
                    : format(date_2, FORMAT);
                setValueClone(formattedDateValue);
                onChange(formattedDateValue);
                event.currentTarget.blur();
                //NOTE: prevent from rendering of native datepicker after enter is pressed
                event.preventDefault();
            }
        }, onChange: function (event) {
            var value = event.currentTarget.value;
            var formattedValue = formatFieldValue(value, {
                type: "tel",
                capitalize: false,
                format: "date",
            });
            setValueClone(formattedValue);
            var date = parseDate(formattedValue, DATE_FORMAT.FULL_DATE);
            if (isValid(date)) {
                onChange(format(date, DATE_FORMAT.ISO_DATE));
            }
            else if (valueClone !== "") {
                onChange("/");
            }
        } }, props);
    /* system native datepicker only when "type=date" is supported on mobile or when disabled */
    if (hasNativeDatepicker || props.disabled) {
        return (React.createElement(Container, { ref: ref, style: style, "data-test-id": testId && "datepicker-".concat(testId) },
            React.createElement(Component, __assign({}, (isDateTypeInput ? dateProps : textProps), { value: inputValue, disabled: props.disabled, onChange: function (event) {
                    var value = event.currentTarget.value;
                    setValueClone(value);
                    var date = parseDate(value, DATE_FORMAT.ISO_DATE);
                    if (isValid(date)) {
                        onChange(format(date, DATE_FORMAT.ISO_DATE));
                    }
                    else if (valueClone !== "") {
                        onChange("/");
                    }
                } }))));
    }
    var date = isDateTypeInput
        ? dateClone
        : parseDate(valueClone, DATE_FORMAT.FULL_DATE);
    var handleDayChange = function (day, DayModifiers, dayPickerInput) {
        setDate(day);
        if (onDayChange) {
            onDayChange(day, DayModifiers, dayPickerInput);
        }
        if (onChange && isValid(day)) {
            onChange(format(day, DATE_FORMAT.ISO_DATE));
        }
    };
    var getValidYear = function (date) {
        var currentYear = new Date().getFullYear();
        var enteredYear = date.getFullYear();
        if (!inRange(enteredYear, currentYear - range.before[0], currentYear + range.after[0]))
            return currentYear - range.before[0];
        return enteredYear;
    };
    return (React.createElement(Container, { ref: ref, style: style, "data-test-id": testId && "datepicker-".concat(testId) },
        React.createElement(DayPickerInputComponent, { ref: pickerRef, component: Component, value: inputValue, inputProps: isDateTypeInput ? dateProps : textProps, format: isDateTypeInput ? DATE_FORMAT.ISO_DATE : FORMAT, formatDate: formatDate, parseDate: parseDate, onDayChange: handleDayChange, dayPickerProps: {
                month: yearMonthDateClone,
                selectedDays: [date],
                navbarElement: function () { return null; },
                onDayClick: function (date) {
                    date.setFullYear(getValidYear(date));
                    setYearMonthDateClone(date);
                },
                onMonthChange: function (date) {
                    setYearMonthDateClone(date);
                },
                captionElement: captionElement,
            } })));
});
export var parseDate = function (value, formatString, locale) {
    if (formatString === void 0) { formatString = DATE_FORMAT.ISO_DATE; }
    var parsed = parse(value, formatString, new Date(), { locale: locale });
    if ((value === null || value === void 0 ? void 0 : value.length) === IMPORTANT_MAX_DATE.length && DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
};
var formatDate = function (date, formatString, locale) {
    if (formatString === void 0) { formatString = DATE_FORMAT.ISO_DATE; }
    if (!date) {
        return "";
    }
    return format(date, formatString, { locale: locale });
};
/**
 * This keeps the year 4 digit long. Do not remove.
 */
var IMPORTANT_MAX_DATE = "2999-12-31";
DatePickerInput.displayName = "DatePickerInput";
DatePickerInput.defaultProps = {
    /* hack for type='date' on mobile to displaying placeholder */
    component: styled(BaseInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", ";\n\n    ", ";\n  "], ["\n    ", ";\n\n    ", ";\n  "])), function (_a) {
        var value = _a.value, hasNativeDatepicker = _a.hasNativeDatepicker;
        return (value === null || value === void 0 ? void 0 : value.length) === 0 &&
            hasNativeDatepicker && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        &[type=\"date\"]:not(:focus):before {\n          content: attr(placeholder);\n        }\n      "], ["\n        &[type=\"date\"]:not(:focus):before {\n          content: attr(placeholder);\n        }\n      "])));
    }, function (_a) {
        var value = _a.value;
        /** Change color of placeholder text to match rest of the app */
        return (value === null || value === void 0 ? void 0 : value.length) === 0 && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        color: rgba(24, 37, 45, 0.75);\n      "], ["\n        color: rgba(24, 37, 45, 0.75);\n      "])));
    }),
    parseDate: parseDate,
    formatDate: formatDate,
    FORMAT: DATE_FORMAT.FULL_DATE,
    IMPORTANT_MAX_DATE: IMPORTANT_MAX_DATE,
    /*
     * use of type=date is preferable but on mobile and firefox the type=tel is used
     * the native datepicker would be displayed with our custom react-day-picker
     */
    isDateTypeInput: isDateTypeSupported,
    hasNativeDatepicker: hasNativeDatepicker,
};
var templateObject_1, templateObject_2, templateObject_3;
