import {Link} from "react-router-dom";
import {styled} from "@reside/ui";

export const ButtonCenter = styled.div`
  width: fit-content;
  margin: 18px auto;
`;

export const StyledLink = styled(Link)`
  line-height: 14px;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
  }
`;
