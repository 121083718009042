import React from "react";
import {Link} from "react-router-dom";
import {styled, Text} from "@reside/ui";

import {
  CellFooter,
  CellHeader,
  CellTextBig,
  DashboardCell,
  AdmissionId,
} from "../../../../dashboard-cell";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {RelativeDate} from "../../../../relative-date";
import {PdfUploadStatusIcon} from "../../pdf-upload-status-icon";
import {getAdmissionResidentName, getResidentTestId} from "../../../helper";
import {SplitButton} from "../../../../split-button";

import {AdmissionChecklist} from "../admission-checklist";
import {DownloadPDFDropdownItem} from "../DownloadPDFDropdownItem";
import {DownloadPartialPDFDropdownItem} from "./DownloadPartialPDFDropdownItem";
import {DeleteAdmissionDropdownItem} from "../DeleteAdmissionDropdownItem";
import {ArchiveAdmissionButton} from "./ArchiveAdmissionButton";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const AdmissionApprovedCell = ({admission}: Props) => (
  <DashboardCell>
    <CellHeader>
      <RelativeDate date={admission.updated} />
      <SplitButton
        size="small"
        defaultOptions={<ArchiveAdmissionButton admission={admission} />}
      >
        <DownloadPDFDropdownItem admission={admission} />
        <DownloadPartialPDFDropdownItem admission={admission} />
        <DeleteAdmissionDropdownItem admission={admission} />
      </SplitButton>
    </CellHeader>
    <CellTextBig data-test-id={getResidentTestId(admission, "editProfile")}>
      <Link to={`/admin/admissions/${admission.id}`}>
        <Text>{getAdmissionResidentName(admission)}</Text>
      </Link>
    </CellTextBig>
    <AdmissionId code={admission.code} />
    <AdmissionChecklist admission={admission} />
    <CellFooter>
      {admission.resources?.finalPdf?.pccUploadStatus && (
        <AlignRight>
          <PdfUploadStatusIcon
            pdfUploadStatus={
              admission.resources.finalPdf.pccUploadStatus as any
            }
          />
        </AlignRight>
      )}
    </CellFooter>
  </DashboardCell>
);

const AlignRight = styled.div`
  margin-left: auto;
`;
