import React, { useEffect, useState } from "react";
import { Text } from "../../../text";
import { Image, Label, SignatureContainer } from "./styles";
import { Button } from "../../../button";
/** Show signature image in places where you just want it to be displayed, not edited.  */
var RENDER_DELAY_MS = 1000;
export var SignatureAsImage = function (_a) {
    var urlWithAccessToken = _a.urlWithAccessToken, label = _a.label, hasSignature = _a.hasSignature, errorButtonText = _a.errorButtonText, onErrorButtonClick = _a.onErrorButtonClick;
    var _b = useState(true), canLoadImage = _b[0], setCanLoadImage = _b[1];
    /** There is an edge case: User gets to the last slide. He wants to change his signature, checks "Show only incomplete and flagged slides" checkbox than clicks on "If needed, please click here to update signature on 'Let's Get Started' slide." He changes his signature and only page he has left when he clicks accept button is the last slide. Problem is that he gets there faster than new signature is saved so the error is thrown. We set this delay to create a time gap that is unnoticable. Just in case user has really slow connection and the error appears, we set the message prompting him to reload the page and retry. */
    var _c = useState(true), delayRender = _c[0], setDelayRender = _c[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            setDelayRender(false);
        }, RENDER_DELAY_MS);
        return function () { return clearTimeout(timer); };
    }, []);
    return (React.createElement(SignatureContainer, { disabled: true, style: { textAlign: "center" } },
        React.createElement(Label, { "data-test-id": "residentSignatureLabel" }, label),
        !delayRender && (React.createElement(React.Fragment, null,
            React.createElement(Image, { "data-test-id": "residentSignature", src: urlWithAccessToken, style: { display: canLoadImage ? "block" : "none" }, alt: label, onError: function () {
                    setCanLoadImage(false);
                } }),
            hasSignature ? (React.createElement(Text, { fontSize: 20, style: {
                    display: canLoadImage ? "none" : "block",
                    margin: "auto",
                    marginTop: "20px",
                    marginBottom: "40px",
                } }, "Unable to load signature. Try to reload the page.")) : (React.createElement(Button, { style: {
                    display: "block",
                    margin: "auto",
                    marginTop: "20px",
                    marginBottom: "40px",
                }, outline: true, color: "primary", onClick: function () { return onErrorButtonClick(); } }, errorButtonText))))));
};
