import {Tooltip, styled, Icon, theme} from "@reside/ui";

export const StonlyButton = () => {
  return (
    <CustomTooltip content="Need Help?">
      <Button
        type="button"
        id="stonly-help-button"
        aria-label="help button - need some help?"
      >
        <Icon
          name="help-circled-alt"
          size={25}
          color={theme.color.darkBlue100}
        />
      </Button>
    </CustomTooltip>
  );
};

const CustomTooltip = styled(Tooltip)`
  margin-right: ${({theme}: any) => theme.space[5]}px;
`;

const Button = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;
