import React from "react";
import {Link} from "react-router-dom";
import {Text} from "@reside/ui";

import {
  AdmissionId,
  CellFooter,
  CellHeader,
  CellTextBig,
  DashboardCell,
  Left,
  Right,
} from "../../../../dashboard-cell";
import {DaysOfAdmissionTag} from "../../days-of-admission-tag";
import {RelativeDate} from "../../../../relative-date";
import {ProgressBar} from "../../progress-bar";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {AdmissionChecklist} from "../admission-checklist";
import {readDateOfAdmissionAnswer} from "../../../../../models/AdmissionModel.helpers";
import {FlagPopover} from "../../flag-popover";
import {getAdmissionResidentName, getResidentTestId} from "../../../helper";
import {InProgressSplitButton} from "./InProgressSplitButton";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const AdmissionInProgressCell = ({admission}: Props) => (
  <DashboardCell>
    <CellHeader>
      <RelativeDate date={admission.updated} />
      <InProgressSplitButton admission={admission} />
    </CellHeader>
    <CellTextBig data-test-id={getResidentTestId(admission, "editProfile")}>
      <Link to={`/admin/admissions/${admission.id}`}>
        <Text>{getAdmissionResidentName(admission)}</Text>
      </Link>
    </CellTextBig>
    <AdmissionId code={admission.code} />
    <ProgressBar progress={admission.completionPercentage} />
    <AdmissionChecklist admission={admission} />
    <CellFooter>
      <Left>
        {readDateOfAdmissionAnswer(admission as any) && (
          <DaysOfAdmissionTag
            date={readDateOfAdmissionAnswer(admission as any)}
          />
        )}
      </Left>
      {admission.flags.length > 0 && (
        <Right>
          <FlagPopover
            flagCount={admission.flags.length}
            admissionId={admission.id}
          />
        </Right>
      )}
    </CellFooter>
  </DashboardCell>
);
