import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Template, Version} from "@reside/forms";
import {
  BlockComponentContextProvider,
  FlexRow,
  H1,
  styled,
  useSafeSetState,
} from "@reside/ui";
import {Admission} from "@reside/reside-api-admission";

import {AdmissionStatus} from "../../services/AdmissionsService";
import {
  changePccStatusToActive,
  PccPatientDetail,
} from "../../services/PccService";
import {AdminLayout} from "../../atoms/admin-layout";
import {PccModal} from "../../atoms/pcc-modal";
import {Spinner} from "../../atoms/spinner";
import {components} from "../../atoms/block-components";
import {PccSearchIndicator} from "../../atoms/pcc-search-indicator";
import {preflightBlockComponents, PreFlightForm} from "./atoms/PreFlightForm";
import {selectMenuProps} from "./atoms/menu/selectMenuProps";
import {Menu} from "./atoms/menu";
import {usePreflight} from "./usePreflight";
import {usePreflightRouteParams} from "./route";
import {
  getInitialState,
  mapAdmissionAnswersToValues,
  pccPatientDetailToPreflightAnswers,
} from "./helpers";
import {usePointClickCareStatus} from "../../hooks/usePointClickCareStatus";
import {useFacilitySettingsQuery} from "../page-admin-facility-settings/hooks/useFacilitySettingsQuery";
import {useTemplateVersionQuery} from "../../hooks/useTemplateVersionQuery";
import {usePreflightTemplateQuery} from "../../hooks/useTemplateQuery";
import {AppState, select} from "../../store/store";
import {connect} from "react-redux";
import {Facilities} from "../../models/types/AdmissionForm";

const blockComponents = {...preflightBlockComponents, ...components};

export const PageAdminPreflightRenderer = ({
  facilities,
}: {
  facilities: Facilities;
}) => {
  const {admissionId, facilityId} = usePreflightRouteParams();

  const {isFetching: isFetchingFacilitySettings, data: facilitySettings} =
    useFacilitySettingsQuery({facilityId});

  const {data: {version, admission} = {}, isFetching: isFetchingVersion} =
    useTemplateVersionQuery({
      admissionId,
      facilityId,
    });

  const {data: template, isFetching: isFetchingTemplate} =
    usePreflightTemplateQuery({versionId: version?.id});

  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  return (
    <AdminLayout>
      {isFetchingFacilitySettings || isFetchingVersion || isFetchingTemplate ? (
        <Spinner />
      ) : (
        <PreflightLayout
          facilityId={facilityId}
          admission={admission}
          // eslint-disable-next-line
          // @ts-ignore
          version={version}
          facilitySettings={facilitySettings}
          admissionId={admissionId}
          template={template}
          isPointClickCareEnabled={isPccEnabled}
        />
      )}
    </AdminLayout>
  );
};

const PreflightLayout = ({
  admission,
  admissionId,
  facilityId,
  isPointClickCareEnabled,
  facilitySettings,
  template,
  version,
}: {
  admission?: Admission;
  admissionId?: string;
  facilityId: string;
  isPointClickCareEnabled: boolean;
  facilitySettings?: any;
  template: Template;
  version: Version;
}) => {
  const history = useHistory();

  const values = {
    ...facilitySettings,
    ...mapAdmissionAnswersToValues(admission.answers),
  };

  const [admissionDraft, setAdmissionDraft] = useSafeSetState<Admission>(
    getInitialState({
      ...admission,
      facilityId,
      admissionId,
      templateId: version.id,
      templateName: version.templateName,
    }),
  );

  const preflight = usePreflight({
    admissionDraft,
    queryVariables: facilitySettings,
    template,
    setAdmissionDraft,
    values,
  });

  const onSearchResults = async (data: PccPatientDetail) => {
    const {preflightAnswers} = pccPatientDetailToPreflightAnswers(data);

    await preflight.setAnswers({
      ...preflight.answers,
      ...preflightAnswers,
    });
  };

  const admissionWasCreated = !!(
    preflight.hasSetInProgress &&
    admissionDraft.status === AdmissionStatus.IN_PROGRESS
  );

  useEffect(() => {
    if (admissionWasCreated && !isPointClickCareEnabled) {
      history.push("/admin/dashboard");
    }
  }, [history, admissionWasCreated, isPointClickCareEnabled]);

  const {
    resident_firstName: firstName,
    resident_lastName: lastName,
    resident_gender: gender,
    resident_dateOfBirth: birthDate,
  } = preflight.answers;

  return (
    <>
      <H1>Edit Pre-Flight</H1>
      <FlexRow>
        {preflight.slides.length > 0 && (
          <>
            <Menu {...selectMenuProps(preflight)} />
            <FormWrapper>
              {isPointClickCareEnabled && (
                <PccSearchIndicator
                  firstName={firstName as string}
                  lastName={lastName as string}
                  gender={gender as string}
                  birthDate={birthDate as string}
                  facilityId={facilityId}
                  onSearchResults={onSearchResults}
                />
              )}
              <BlockComponentContextProvider value={blockComponents}>
                <PreFlightForm
                  {...preflight}
                  admissionDraft={preflight.draft}
                  queryVariables={facilitySettings}
                />
              </BlockComponentContextProvider>
            </FormWrapper>
            {isPointClickCareEnabled && admissionWasCreated && (
              <PccModal
                admissionId={admissionDraft.id}
                facilityId={admissionDraft.facilityId}
                onRequestClose={() => {
                  history.push("/admin/dashboard");
                }}
                onAccountExists={() =>
                  changePccStatusToActive(admissionDraft.id)
                }
              />
            )}
          </>
        )}
      </FlexRow>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilities: select.adminSession.facilities(state),
});

export const PageAdminPreflight = connect(mapStateToProps)(
  PageAdminPreflightRenderer,
);

PreflightLayout.defaultProps = {
  admission: {},
  version: {},
};

const FormWrapper = styled.div`
  width: 100%;
`;
