import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useTestUpload = () => {
  return useMutation(
    async (body: {sftp_config_id: string; target_path: string}) => {
      const response = await HubAndSpokeService.testUpload(body);
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("Test document successfully uploaded!");
      },
      onError: (err: any) => {
        logErrorToSentry(err);
        Alert.error(err?.data?.detail);
      },
    },
  );
};
