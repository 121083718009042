/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {init} from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import createRematchPersist, {getPersistor} from "@rematch/persist";
import selectPlugin from "@rematch/select";

import {combineReducers} from "redux";
import thunk from "redux-thunk";

import {makeTransform as makeAdmissionUiTransform} from "../models/transforms/admissionUiTransform";
import {model as adminAdmissionExperienceUi} from "../models/AdminAdmissionExperienceUiModel";
import {adminSessionModel} from "../models/admin-session/AdminSessionModel";
import {model as admissionSession} from "../models/admission-session/AdmissionSessionModel";
import {model as admissionExperienceUi} from "../models/AdmissionExperienceUiModel";
import {model as admissions} from "../models/AdmissionsModel";
import {model as locale} from "../models/LocaleModel";
import {rootReportingReducer} from "./reporting";
import {rootUserPermissionsReducer} from "./user-permissions";
import {ReportAction} from "./reporting/reporting.actions";
import {isDev} from "../config";

const reducersMap = {
  reporting: rootReportingReducer,
  userPermissions: rootUserPermissionsReducer,
};

const rootReducer = combineReducers(reducersMap);

export type AppState = ReturnType<typeof rootReducer>;

const middlewares = [thunk];

if (isDev && process.env.REACT_APP_REDUX_LOGGER_ENABLED === "true") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const {createLogger} = require("redux-logger");

  middlewares.push(createLogger());
}

export const store: any = init({
  models: {
    adminAdmissionExperienceUi,
    adminSessionModel,
    admissionSession,
    admissionExperienceUi,
    admissions,
    locale,
  },
  plugins: [
    selectPlugin(),
    createLoadingPlugin(),
    createRematchPersist({
      whitelist: [
        adminSessionModel.name,
        admissionExperienceUi.name,
        admissionSession.name,
      ],
      version: 1,
      debug: isDev,
      transforms: [makeAdmissionUiTransform(admissionExperienceUi.name)],
    }),
  ],
  redux: {
    middlewares,
    reducers: reducersMap,
  },
});

export const persistor = getPersistor();

export type Dispatch = typeof store.dispatch;

export const {dispatch, getState, select} = store;

declare module "typesafe-actions" {
  interface Types {
    RootAction: ReportAction;
  }
}
