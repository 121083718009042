import React, {ComponentProps, useCallback, useMemo} from "react";
import {Manager, Reference, Popper} from "react-popper";
import {styled, theme, Icon, Toggle, DropdownMenuStyled} from "@reside/ui";

import {TreeSelect, Props as TreeSelectProps} from "../tree-select/TreeSelect";
import {summarizeTree} from "../tree-select/helpers";

type Props = Readonly<{
  triggerLabel: string;
  summarizeTree: typeof summarizeTree;
  placement: ComponentProps<typeof Popper>["placement"];
  hideTrigger?: boolean;
  autoComplete?: string;
}> &
  TreeSelectProps;

export const DropdownTreeSelect = ({
  checkedKeys,
  EmptySearch,
  hideTrigger,
  onCheck,
  hasOnlyOne,
  placement,
  searchPlaceholder,
  summarizeTree,
  treeData,
  triggerLabel,
  autoComplete,
}: Props) => {
  const leafs = useMemo(
    () => treeData.reduce((keys, node) => [...keys, ...node.children], []),
    [treeData],
  );
  const allLeafKeys: ReadonlyArray<string> = useMemo(
    () => leafs.map(({key}) => key),
    [leafs],
  );

  const checkedLeafKeys = useMemo(
    () =>
      (checkedKeys || allLeafKeys).filter(
        key => !treeData.find(node => node.key === key),
      ),
    [checkedKeys, allLeafKeys, treeData],
  );

  const handleCheck = useCallback(
    (newCheckedKeys: string[], newCheckedLeafKeys: string[]) => {
      onCheck(newCheckedKeys, newCheckedLeafKeys);
    },
    [onCheck],
  );

  return (
    <Toggle>
      {({isOpened, setIsOpened, outsideClickIgnoreClass}) => (
        <Manager>
          <Reference>
            {({ref}) => (
              <Trigger
                ref={ref}
                onClick={
                  hideTrigger ? () => null : () => setIsOpened(!isOpened)
                }
              >
                {triggerLabel}:{" "}
                <strong>{summarizeTree(checkedLeafKeys, leafs)}</strong>
                {!hideTrigger && (
                  <TriggerIconWrapper>
                    <Icon
                      name={isOpened ? "up" : "down"}
                      size={12}
                      color={theme.color.primary}
                    />
                  </TriggerIconWrapper>
                )}
              </Trigger>
            )}
          </Reference>
          {isOpened && (
            <Popper placement={placement}>
              {({ref, style}) => (
                <DropdownMenu
                  ref={ref}
                  style={style}
                  className={outsideClickIgnoreClass}
                >
                  <TreeSelect
                    treeData={treeData}
                    searchPlaceholder={searchPlaceholder}
                    EmptySearch={EmptySearch}
                    checkedKeys={checkedKeys}
                    onCheck={(checkedKeys, checkedLeafKeys) => {
                      handleCheck(checkedKeys, checkedLeafKeys);
                      if (hasOnlyOne) {
                        setIsOpened(false);
                      }
                    }}
                    hasOnlyOne={hasOnlyOne}
                    autoComplete={autoComplete}
                  />
                </DropdownMenu>
              )}
            </Popper>
          )}
        </Manager>
      )}
    </Toggle>
  );
};

DropdownTreeSelect.defaultProps = {
  summarizeTree,
  placement: "bottom",
  hideTrigger: false,
};

const DropdownMenu = styled(DropdownMenuStyled)`
  margin-top: 8px;
  padding: 12px;
  width: 360px;
  z-index: 1001;

  .rc-tree {
    max-height: 65vh;

    @media (max-height: 740px) {
      max-height: 45vh;
    }
  }
`;

const Trigger = styled.div`
  &:hover {
    cursor: pointer;
  }
  font-size: 23px;
`;

const TriggerIconWrapper = styled.div`
  vertical-align: middle;
  padding: 4px;
  display: inline;
  font-size: 12px;
`;
