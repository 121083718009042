var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { NavLink as BaseNavLink } from "react-router-dom";
import { styled, theme } from "../theme";
export var NavLink = function (props) { return (React.createElement(StyledNavLink, __assign({}, props))); };
NavLink.defaultProps = {
    activeStyle: {
        fontWeight: theme.fontWeight.bold,
    },
};
var StyledNavLink = styled(BaseNavLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n  line-height: 1.75em;\n  color: ", ";\n  display: inline-block;\n  margin-left: 35px;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  text-decoration: none;\n  line-height: 1.75em;\n  color: ", ";\n  display: inline-block;\n  margin-left: 35px;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var templateObject_1;
