import React from "react";
import {TableUserDto} from "@reside/reside-api-app";

import {
  CompareDateLabels,
  getAggregationValues,
  hasMorePages,
  ReportProps,
  useServerSort,
} from "./Report.common";
import {
  ReportAdmission,
  ReportAggregationTypes,
  ReportsDataStatePath,
  ReportsType,
  SortField,
  SortDirection,
} from "../../../store/reporting-reports/reporting-reports.common";
import {CardTitle} from "../../../atoms/card-title";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {ReportingContextReports} from "../ReportingContextReports";
import {withCompare} from "./helpers";
import {LoadMoreButton} from "../atoms/load-more-button";
import {
  Column,
  ListTableReporting,
} from "../../../atoms/admin-reporting/list-table-reporting";
import {
  DateReportsCell,
  TextCell,
  TextCellEllipsis,
} from "../../../atoms/list-table/SharedCells";
import {readableAdmissionStatus} from "../../../atoms/admission-status-tag";
import {Aggregation} from "../../../atoms/admin-reporting/atoms/aggregation";
import {AdmissionStatus} from "../../../services/AdmissionsService";

export const LIST_OF_RESIDENTS_REPORT_ID = "list-of-residents" as const;

const renderListOfResidentsAggregations = (d: ReportsDataStatePath[]) => (
  <>
    <Aggregation
      reports
      subject="IN TOTAL"
      values={getAggregationValues(d, ReportAggregationTypes.total)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.PRE_FLIGHT)}
      values={getAggregationValues(d, ReportAggregationTypes.preFlight)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.IN_PROGRESS)}
      values={getAggregationValues(d, ReportAggregationTypes.inProgress)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.SIGNED)}
      values={getAggregationValues(d, ReportAggregationTypes.signed)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.APPROVED)}
      values={getAggregationValues(d, ReportAggregationTypes.approved)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.ARCHIVED)}
      values={getAggregationValues(d, ReportAggregationTypes.archived)}
    />
    <Aggregation
      reports
      subject={readableAdmissionStatus(AdmissionStatus.CANCELLED)}
      values={getAggregationValues(d, ReportAggregationTypes.cancelled)}
    />
  </>
);

export const ReportListOfResidents = (props: ReportProps) => {
  const [sortDirection, sortField, setSort] = useServerSort(
    SortField.residentName,
    SortDirection.ASC,
  );

  return (
    <>
      <div className="chart-header">
        <CardTitle title="Admissions Overview" subtitle="by Admissions" />
        <CompareDateLabels dateRange={props.dateRange} />
      </div>
      <RequiredUserPermission
        permission={TableUserDto.PermissionsEnum.ADMISSION_IN_TOTAL}
      >
        <ReportingContextReports
          dataPaths={withCompare({
            type: ReportsType.totalAdmissions,
            query: {
              from: props.dateRange.from,
              to: props.dateRange.to,
              sortDirection: sortDirection,
              sortField: sortField,
              facilities: props.facilities,
            },
          })}
          enabled
          onDataPathChange={
            props.reportingBlockSetters
              ? props.reportingBlockSetters.setExportDataPath
              : undefined
          }
        >
          {(d, loadMore) => (
            <>
              <div className="aggregation-row">
                {renderListOfResidentsAggregations(d)}
              </div>
              <ListTableReporting
                data={d}
                sortField={sortField}
                sortDirection={sortDirection}
                onSortChange={setSort}
                noRowsMessage="No Admissions found with these parameters..."
                full={props.reportingBlockSetters.isFullReport}
              >
                <Column<ReportAdmission>
                  sortKey={SortField.residentName}
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.residentName}
                    </TextCellEllipsis>
                  )}
                  label="Resident Name"
                  width={30}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <TextCellEllipsis small>
                      {row.rowData.facilityName}
                    </TextCellEllipsis>
                  )}
                  label="Facility Name"
                  sortKey={SortField.facilityName}
                  width={28}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <DateReportsCell data={row.rowData.createdDate} />
                  )}
                  label="Created Date"
                  sortKey={SortField.createdDate}
                  width={14}
                />
                <Column<ReportAdmission>
                  cellRenderer={row => (
                    <DateReportsCell data={row.rowData.admissionDate} />
                  )}
                  label="Admission Date"
                  sortKey={SortField.admissionDate}
                  width={14}
                />

                <Column<ReportAdmission>
                  label="Admission Status"
                  sortKey={SortField.admissionStatus}
                  cellRenderer={row => (
                    <TextCell small>
                      {readableAdmissionStatus(row.rowData.admissionStatus)}
                    </TextCell>
                  )}
                  width={20}
                />
              </ListTableReporting>
              <LoadMoreButton
                hasMorePages={hasMorePages(
                  d,
                  props.reportingBlockSetters.isFullReport,
                )}
                onClick={loadMore}
              />
            </>
          )}
        </ReportingContextReports>
      </RequiredUserPermission>
    </>
  );
};
