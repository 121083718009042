import React from "react";

import {styled} from "@reside/ui";
import {IconButton as BaseIconButton} from "../../icon-button";

type Props = Readonly<{
  onClick: () => void;
}>;

export const GoBackButton = ({onClick}: Props) => (
  <IconButton
    iconName="back"
    background="white"
    testId="go-back-button"
    onClick={onClick}
  >
    Go back
  </IconButton>
);

const IconButton = styled(BaseIconButton)`
  padding-right: 20px;
`;
