import {useQueryClient} from "react-query";

export const useClearAndCancelCache = () => {
  const cache = useQueryClient();

  return {
    clearAndCancelCache: () => {
      cache.clear();
      cache.cancelQueries();
      cache.cancelMutations();
    },
  };
};
