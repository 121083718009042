var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from "react";
import { useFormikContext } from "formik";
import { throttle } from "lodash";
import { useDeepCompareEffect } from "react-use";
import { useSafeSetState } from "./useSafeSetState";
export var useFormikAutoSaveValues = function (_a) {
    var onChangeValues = _a.onChangeValues, _b = _a.debounceMs, debounceMs = _b === void 0 ? 1000 : _b, _c = _a.disableDebounce, disableDebounce = _c === void 0 ? false : _c;
    var formik = useFormikContext();
    var _d = useSafeSetState({
        changedAt: "",
        isChanged: false,
    }), state = _d[0], setState = _d[1];
    var debouncedOnChange = useCallback(throttle(function (values) {
        onChangeValues(values, !state.isChanged);
        setState({ changedAt: new Date().toISOString(), isChanged: true });
    }, debounceMs), [debounceMs]);
    useDeepCompareEffect(function () {
        if (disableDebounce) {
            onChangeValues(formik.values, !state.isChanged);
            setState({ changedAt: new Date().toISOString(), isChanged: true });
        }
        else {
            debouncedOnChange(formik.values);
        }
    }, [debounceMs, disableDebounce, formik.values]);
    return state;
};
export var FormikAutoSaveValues = function (_a) {
    var _b = _a.children, children = _b === void 0 ? function () { return null; } : _b, props = __rest(_a, ["children"]);
    return children(useFormikAutoSaveValues(props));
};
