import { theme } from "../../theme";
import { getDaysInMonth, isAfter, isBefore, isValid } from "date-fns";
/** Use this year as default, if year is not entered, when validating days. When user types in a day, with year empty, we don't want to invalidate it immediately since we don't know if he will enter a leap year.  */
var LEAP_YEAR = 2024;
export var getErrorMessage = function (type, unit) {
    var messages = {
        future: "".concat(unit, " is in the future"),
        past: "".concat(unit, " is in the past"),
        invalid: "Invalid ".concat(unit),
    };
    return messages[type];
};
export var createDateString = function (newMonth, newDay, newYear) {
    if (newMonth && newDay && newYear) {
        return "".concat(newYear.padStart(4, "0"), "-").concat(newMonth.padStart(2, "0"), "-").concat(newDay.padStart(2, "0"));
    }
    else
        return "";
};
export var removeNonNumericCharacters = function (value) {
    return value.replace(/\D/g, "");
};
/** Toggle color of border when separte field has/hasn't error */
export var toggleErrorColor = function (fieldRef, color) {
    var errorColors = {
        red: theme.color.errorRed,
        white: theme.color.white,
    };
    fieldRef.current.style.backgroundColor = "".concat(errorColors[color], "50"); // add 50 opacity to color
};
export var clearError = function (fieldRef, setError) {
    toggleErrorColor(fieldRef, "white");
    setError("");
};
export var assignError = function (fieldRef, setError, errorMessage) {
    toggleErrorColor(fieldRef, "red");
    setError(errorMessage);
};
/**
 * Check if day enetered is valid number taking in consideration number of days entered month has.
 * */
export var isValidDay = function (month, day, year) {
    if (!month)
        return Boolean(Number(day) < 32);
    // Number entered is less than current month has days.
    return (Number(day) <=
        getDaysInMonth(new Date("".concat(Number(month), "/01/").concat(year || LEAP_YEAR))));
};
/**  When date is pasted or has initial values we need to format them to fit into belonging box.*/
export var formatFieldsForPreFill = function (value) {
    var date = new Date(value);
    var fields = {
        formatedMonth: "",
        formatedDay: "",
        formatedYear: "",
    };
    if (isValid(date)) {
        var newMonth = date.getUTCMonth() + 1;
        fields.formatedMonth = newMonth.toString().padStart(2, "0").substring(0, 2);
        fields.formatedDay = date
            .getUTCDate()
            .toString()
            .padStart(2, "0")
            .substring(0, 2);
        fields.formatedYear = date
            .getUTCFullYear()
            .toString()
            .padStart(4, "0")
            .substring(0, 4);
        return fields;
    }
    return fields;
};
/** Check does entered date fall between provided range */
export var validateRange = function (enteredDate, range) {
    if (!range)
        return {
            isBefore: false,
            isAfter: false,
        };
    var currentDate = new Date();
    var currentYear = currentDate.getUTCFullYear();
    var oldestAllowedDate = new Date(currentDate.setFullYear(currentYear - range.before[0]));
    var allowedYearsInFuture = new Date(currentDate.setFullYear(currentYear + range.after[0]));
    return {
        isBefore: isBefore(enteredDate, oldestAllowedDate),
        isAfter: isAfter(enteredDate, allowedYearsInFuture),
    };
};
