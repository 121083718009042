import React from "react";
import {
  styled,
  Button,
  H1,
  Paragraph,
  Modal,
  ModalContent,
  ModalFooterButtons,
} from "@reside/ui";

type Props = Readonly<{
  residentFirstName: string;
  residentLastName: string;
  isOpen: boolean;
  isLoading: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
}>;

export const ReturnToResidentModal = ({
  residentFirstName,
  residentLastName,
  isOpen,
  isLoading,
  onRequestClose,
  onSubmit,
}: Props) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false}>
    {({closeModal}) => (
      <>
        <ModalContent>
          <H1>
            Return Admission to {residentFirstName} {residentLastName}
          </H1>
          <Paragraph>
            Do you really wish to return this admission? The resident or
            representative must sign the admission after it is returned.
          </Paragraph>
        </ModalContent>
        <ModalFooterButtons>
          <ButtonWrapper>
            <Button
              outline
              color="primary"
              disabled={isLoading}
              onClick={closeModal}
              testId="cancelReturnToResidentButton"
            >
              Cancel
            </Button>
            <Button
              testId="submitReturnToResidentButton"
              color="danger"
              type="submit"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {isLoading ? "Returning to Resident..." : "Return to Resident"}
            </Button>
          </ButtonWrapper>
        </ModalFooterButtons>
      </>
    )}
  </Modal>
);

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
