import { useCallback, useEffect, useState } from "react";
/** To be able to rerender input fields when one item in field array is removed or added, we need to know its length. When the length changes triger field rerender. */
import { getFieldArrayLength } from "../form-blocks/block-field-array/helpers";
import { useDebouncedCallback } from "use-debounce";
import { useField, useFormikContext } from "formik";
var DELAY = 400;
var MIN_BLOCK_SIZE_FOR_DELAY = 5;
var MIN_INPUT_FIELDS_FOR_DELAY = 5;
export var useDebounce = function (fieldName, numberOfBlocks) {
    var _a = useState(""), innerValue = _a[0], setInnerValue = _a[1];
    var values = useFormikContext().values;
    var field = useField(fieldName)[0];
    /** We want to have delay only on very big forms and on very big pages. We don't need delay on small slides and blocks.  */
    var calculateAmountOfDelay = function () {
        var _a;
        if (numberOfBlocks && numberOfBlocks < MIN_BLOCK_SIZE_FOR_DELAY) {
            return 0;
        }
        else if (((_a = Object.keys(values)) === null || _a === void 0 ? void 0 : _a.length) < MIN_INPUT_FIELDS_FOR_DELAY) {
            return 0;
        }
        // Default is 400ms
        return DELAY;
    };
    var delay = calculateAmountOfDelay();
    useEffect(function () {
        setInnerValue(field.value);
    }, [field.value, getFieldArrayLength(values)]);
    var debouncedHandleOnChange = useDebouncedCallback(function (form) {
        form.setFieldValue(fieldName, innerValue, true);
    }, delay);
    var handleOnChange = useCallback(function (event, form, formatedFieldValues) {
        event.persist();
        var newValue = formatedFieldValues !== null && formatedFieldValues !== void 0 ? formatedFieldValues : event.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange(form);
    }, []);
    return [innerValue, setInnerValue, handleOnChange];
};
