"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = void 0;
var DOMAIN_NAME_REGEX = /^((?!-)[a-z0-9-]{1,63}\.)+[a-z]{2,3}$/i;
exports.Validator = {
    name: "domain-name-list",
    errorMessage: "Not all entered values are valid domain names.",
    validate: function (value) {
        var enteredDomainNames = value.split(",");
        var validDomainNames = enteredDomainNames.reduce(function (domainNames, domainName) {
            var trimmedDomainName = domainName.trim();
            if (DOMAIN_NAME_REGEX.test(trimmedDomainName))
                domainNames.push(trimmedDomainName);
            return domainNames;
        }, []);
        return enteredDomainNames.length === validDomainNames.length;
    },
};
