import React, {memo} from "react";
import {Color, Tag} from "@reside/ui";

import {
  AdmissionStatus,
  AdmissionStatusEnum,
} from "../../services/AdmissionsService";
import {makeReadableEnum} from "../list-table/helpers";

export const readableAdmissionStatus = makeReadableEnum(AdmissionStatus, {
  [AdmissionStatus.CANCELLED]: "Deleted",
  [AdmissionStatus.PRE_FLIGHT]: "Pre-Flight",
});

export const ADMISSION_STATUS_ORDER = [
  AdmissionStatus.PRE_FLIGHT,
  AdmissionStatus.IN_PROGRESS,
  AdmissionStatus.SIGNED,
  AdmissionStatus.APPROVED,
  AdmissionStatus.ARCHIVED,
  AdmissionStatus.CANCELLED,
];

const COLOR_MAP: Record<AdmissionStatusEnum, Color> = {
  [AdmissionStatus.PRE_FLIGHT]: "primary",
  [AdmissionStatus.IN_PROGRESS]: "golden100",
  [AdmissionStatus.SIGNED]: "slateBlue100",
  [AdmissionStatus.APPROVED]: "mint100",
  [AdmissionStatus.ARCHIVED]: "gray100",
  [AdmissionStatus.CANCELLED]: "gray100",
  // Deprecated
  [AdmissionStatus.INCOMPLETE]: "gray100",
};

const FONT_COLOR_MAP: Record<AdmissionStatusEnum, "black" | "white"> = {
  [AdmissionStatus.PRE_FLIGHT]: "black",
  [AdmissionStatus.IN_PROGRESS]: "black",
  [AdmissionStatus.SIGNED]: "white",
  [AdmissionStatus.APPROVED]: "black",
  [AdmissionStatus.ARCHIVED]: "black",
  [AdmissionStatus.CANCELLED]: "black",
  // Deprecated
  [AdmissionStatus.INCOMPLETE]: "black",
};
export const AdmissionStatusTag = memo(
  ({status}: {status: AdmissionStatusEnum}) => (
    <Tag color={COLOR_MAP[status]} fontColor={FONT_COLOR_MAP[status]}>
      {readableAdmissionStatus(status)}
    </Tag>
  ),
);

AdmissionStatusTag.displayName = "AdmissionStatusTag";
