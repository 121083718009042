import React, {FunctionComponent, ReactNode} from "react";
import {connect} from "react-redux";
import {UserDto, RoleDto} from "@reside/reside-api-app";

import {select} from "../../store";

type StateProps = Readonly<{
  user: UserDto;
}>;

type OwnProps = Readonly<{
  /**
   * If roles === undefined; the component is opaque & renders children for any role.
   */
  roles?: ReadonlyArray<RoleDto.NameEnum>;
  children: ReactNode;
}>;

const AdminRolesRenderer = ({roles, user, children}: OwnProps & StateProps) => (
  <>{!roles || roles?.includes(user.role.name) ? children : null}</>
);

const mapStateToProps = (state: any): StateProps => ({
  user: select.adminSession.user(state),
});

export const AdminRoles = connect(mapStateToProps)(AdminRolesRenderer);

const createRolesComponent =
  (roles: ReadonlyArray<RoleDto.NameEnum>): FunctionComponent =>
  ({children}) =>
    <AdminRoles roles={roles}>{children}</AdminRoles>;

const NOT_RESIDE_ADMIN_ROLES = [
  RoleDto.NameEnum.CLERK,
  RoleDto.NameEnum.ADMISSIONS_DIRECTOR,
  RoleDto.NameEnum.FACILITY_ADMIN,
  RoleDto.NameEnum.REGIONAL_ADMIN,
  RoleDto.NameEnum.ORGANIZATION_ADMIN,
];

export const NotResideAdmin = createRolesComponent(NOT_RESIDE_ADMIN_ROLES);
