import React, {Fragment} from "react";
import {Trans} from "@lingui/macro";
import {css, cx} from "@emotion/css";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Checkbox} from "@reside/ui";

import {select} from "../../../store/index";
import {FlagCountIcon} from "../../flag-count-icon";
import {
  MenuContainer,
  MenuGroup,
  MenuGroupTitle,
  MenuItem,
  MenuItemContainer,
} from "../../menu";
import {
  Draft,
  Group,
  Section,
  TableOfContent,
  UIActions,
  UIState,
} from "../../admission-explorer/model";

type FilterProps = Readonly<{
  isChecked: boolean;
  onToggle: () => void;
}>;

const Filter = ({isChecked, onToggle}: FilterProps) => (
  <label
    className={cx(
      css`
        margin-bottom: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
      `,
    )}
  >
    <Checkbox
      name="tocFilterMode"
      value="yes"
      checked={isChecked}
      onChange={onToggle}
    />
    &nbsp;&nbsp;Show incomplete and flagged slides
  </label>
);

type GroupEntryProps = Readonly<{
  data: Group;
  renderSectionEntries: (section: Section) => JSX.Element;
}>;

const GroupEntry = ({data, renderSectionEntries}: GroupEntryProps) => (
  <MenuGroup>
    <MenuGroupTitle>
      <Trans id={data.title.translationKey} />
    </MenuGroupTitle>
    {data.children.map(renderSectionEntries)}
  </MenuGroup>
);

type SectionEntryProps = Readonly<{
  data: Section;
  currentSection: string;
  flagsCount: number;
  onSelect: (id: string) => void;
}>;

const SectionEntry = ({
  data,
  flagsCount,
  currentSection,
  onSelect,
}: SectionEntryProps) => (
  <MenuItem
    active={data.id === currentSection}
    Container={({children}) => (
      <MenuItemContainer
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
        data-test-id="section-link"
        onClick={() => onSelect(data.id)}
      >
        {children}
        {flagsCount > 0 && <FlagCountIcon count={flagsCount} />}
      </MenuItemContainer>
    )}
  >
    <Trans id={data.title.translationKey} />
  </MenuItem>
);

type OwnProps = Readonly<{
  uiState: UIState;
  uiActions: UIActions;
  draft: Draft;
}>;

type StateProps = Readonly<{
  incompleteToc: TableOfContent;
  generalToc: TableOfContent;
}>;

type Props = OwnProps & StateProps;

const Renderer = ({
  draft,
  uiState,
  uiActions,
  generalToc,
  incompleteToc,
}: Props) => {
  const toc = uiState.showIncompleteSlidesOnly ? incompleteToc : generalToc;

  const history = useHistory();

  const renderGroupEntries = (group: Group) => (
    <Fragment key={group.id}>
      {group.state.totalSlides > 0 && (
        <GroupEntry data={group} renderSectionEntries={renderSectionEntries} />
      )}
    </Fragment>
  );

  const renderSectionEntries = (section: Section) => {
    const flagsCount = section.children.reduce((count, slide) => {
      count += slide.state.totalFlags;

      return count;
    }, 0);

    return (
      <Fragment key={section.id}>
        {section.children.length > 0 && (
          <SectionEntry
            data={section}
            onSelect={sectionId => {
              uiActions.goToSection(sectionId);
              history.push(
                `/admin/admissions/${draft.instance.id}/section/${sectionId}`,
              );
            }}
            currentSection={uiState.sectionId}
            flagsCount={flagsCount}
          />
        )}
      </Fragment>
    );
  };

  return (
    <MenuContainer
      className={css`
        width: 300px;
        min-width: 300px;
        margin-right: 15px;
      `}
    >
      <Filter
        isChecked={uiState.showIncompleteSlidesOnly}
        onToggle={uiActions.toggleShowIncompleteSlidesOnlyFlag}
      />
      {toc.groups.map(renderGroupEntries)}
    </MenuContainer>
  );
};

const mapState = (state: any, props: OwnProps) => {
  const admissionId = props.draft.instance.id;

  return select((models: any) => ({
    generalToc: models.admissions.fullTOC({admissionId}),
    incompleteToc: models.admissions.incompleteSlides({admissionId}),
  }));
};

export const Menu = connect(mapState)(Renderer);
