import {useMutation, useQueryClient} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {
  pccApi,
  handlePccRequestLimitReached,
  PccActivityLog,
} from "../../../services/PccService";
import {ACTIVITIES_LOG_QUERY} from "./constants";

type Params = {
  activityId: string;
  facilityId: string;
};

export const useRetryActivity = (admissionId: string) => {
  const cache = useQueryClient();

  return useMutation(
    async ({activityId, facilityId}: Params) => {
      const {data} = await pccApi.retryActivityUsingPOST(
        {activityId, facilityId},
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      onError: error => {
        handlePccRequestLimitReached(error);
      },
      onSuccess: (data: any) => {
        const queryData = cache.getQueryData<{
          pages: ReadonlyArray<
            ReadonlyArray<PccActivityLog & {residentName: string}>
          >;
        }>(ACTIVITIES_LOG_QUERY);

        cache.setQueryData(ACTIVITIES_LOG_QUERY, {
          ...queryData,
          pages: queryData.pages.map(dataPage =>
            dataPage.map(log =>
              log.admissionId === admissionId
                ? {...data, time: log.time, residentName: log.residentName}
                : log,
            ),
          ),
        });
      },
    },
  );
};
