import React, {FunctionComponent} from "react";

import {styled} from "@reside/ui";
import {Text} from "@reside/ui";

export const MenuItemContainer = styled.div`
  margin-top: 15px;
  padding-inline: 15px;

  cursor: pointer;

  a {
    display: block;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const MenuItem: FunctionComponent<{
  active?: boolean;
  className?: string;
  Container?: FunctionComponent;
}> = ({active, className, Container, children}) => (
  <Container>
    <Text
      className={className}
      fontWeight={active ? "bold" : "normal"}
      lineHeight="18px"
    >
      {children}
    </Text>
  </Container>
);

MenuItem.defaultProps = {
  Container: MenuItemContainer,
};
