import {useQuery, UseQueryOptions} from "react-query";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService, TemplateField} from "../HubAndSpokeService";

export const useTemplateFieldsQuery = (
  templateName?: string,
  options?: UseQueryOptions<Array<TemplateField> | undefined>[],
) => {
  return useQuery(
    ["GET_TEMPLATE_FIELDS", templateName],
    async () => {
      const {data} = await HubAndSpokeService.getTemplateFields({
        template_name: templateName,
      });
      return data;
    },
    {
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to get template fields.");
      },
      cacheTime: 0,
      enabled: !!templateName,
      ...options,
    },
  );
};
