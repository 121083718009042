import {Button, Box} from "@reside/ui";

import {Column, ListTable} from "../../../atoms/list-table";
import {
  ActivityLogEventTypeCell,
  ActivityLogStatusCell,
  ActivityLogMessageCell,
} from "./ActivityLogCells";
import {
  DatetimeCell,
  LinkCell,
  TextCell,
} from "../../../atoms/list-table/SharedCells";

export const AdminPccActivityLogsTable = ({
  activityLogs,
  header,
  isLoading,
  loadMore,
  loadMoreEnabled,
  tableName,
}: any) => (
  <>
    {header}
    <ListTable
      key={tableName}
      data={activityLogs}
      getRowId={(row: any) => row.rowData.id}
      showDoubleArrowOnNotSorted
      sortPredicates={{
        eventType: ["eventType"],
        time: ["time"],
        status: ["status"],
        message: ["message"],
        residentName: ["residentName"],
      }}
      sortBy="time"
      sortDirection="DESC"
      noRowsMessage="No activity logs found with these parameters..."
    >
      <Column
        cellRenderer={(row: any) => (
          <ActivityLogEventTypeCell eventType={row.rowData.eventType} />
        )}
        label="Event type"
        sortKey="eventType"
      />
      <Column
        cellRenderer={(row: any) =>
          row.rowData.admissionId ? (
            <LinkCell link={`/admin/admissions/${row.rowData.admissionId}`}>
              {row.rowData.residentName}
            </LinkCell>
          ) : (
            <TextCell subtle>{row.rowData.residentName}</TextCell>
          )
        }
        label="Resident"
        sortKey="residentName"
      />
      <Column
        cellRenderer={(row: any) => <DatetimeCell data={row.rowData.time} />}
        sortKey="time"
        label="Time"
      />
      <Column
        cellRenderer={(row: any) => (
          <ActivityLogMessageCell activity={row.rowData} />
        )}
        label="Message"
        sortKey="message"
      />
      <Column
        cellRenderer={(row: any) => (
          <ActivityLogStatusCell activityLog={row.rowData} />
        )}
        label="Status"
        sortKey="status"
        width={220}
      />
    </ListTable>
    <Box flexDirection="row" justifyContent="space-around">
      {loadMoreEnabled && (
        <Button color="primary" onClick={loadMore} disabled={isLoading}>
          {isLoading ? "Loading..." : "Load more"}
        </Button>
      )}
    </Box>
  </>
);
