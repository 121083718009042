import {createModel} from "@rematch/core";
import update from "immutability-helper";
import {
  shouldSaveAccess,
  shouldUpdateAdmissionState,
} from "./AdmissionSessionModel.helpers";

import {history} from "../../utils/history";
import {select} from "../../store";
import {DocumentService} from "../../services/DocumentService";
import {
  isAdmissionToken,
  isAdmissionEntryToken,
  isAdmissionEmailEntryToken,
  getTokenSub,
  tokenIsExpired,
  makeAuthorizationHeader,
} from "../../utils/token";

const INITIAL_STATE = {
  token: null,
};

export class AdmissionTemplateError extends Error {}

const getAdmissionSession = state => state.admissionSession;

export const model = createModel({
  name: "admissionSession",

  state: INITIAL_STATE,

  reducers: {
    reset() {
      return INITIAL_STATE;
    },
    setToken(state, payload) {
      return update(state, {token: {$set: payload}});
    },
  },

  selectors: (slice, createSelector) => ({
    getToken: () => state => getAdmissionSession(state).token,
    getAuthorizationHeader() {
      return createSelector(this.getToken, makeAuthorizationHeader);
    },
    isLoggedIn() {
      return createSelector(this.getToken, token => !!token);
    },
    tokenIsExpired() {
      return createSelector(this.getToken, tokenIsExpired);
    },
    hasAdmissionToken() {
      return createSelector(
        this.isLoggedIn,
        this.getToken,
        (isLoggedIn, token) => isLoggedIn && isAdmissionToken(token),
      );
    },
    hasAdmissionEntryToken() {
      return createSelector(
        this.isLoggedIn,
        this.getToken,
        (isLoggedIn, token) =>
          isLoggedIn &&
          (isAdmissionEntryToken(token) || isAdmissionEmailEntryToken(token)),
      );
    },
    getTokenSub() {
      return createSelector(
        this.getToken,
        token => token && getTokenSub(token),
      );
    },
    getAdmissionSession,
  }),

  effects: dispatch => ({
    async logout() {
      dispatch.admissionSession.reset();
    },

    async startSession({admissionId, payload}, rootState) {
      const {instance} = select(models => ({
        instance: models.admissions.instance({admissionId}),
      }))(rootState);

      try {
        const {data: template} = await DocumentService.getTemplate(
          instance.templateId,
        );

        dispatch.admissions.pushObject({
          instance,
          template,
        });
      } catch {
        throw new AdmissionTemplateError(
          "Something went wrong while fetching admission template. Please try again.",
        );
      }

      if (shouldSaveAccess(instance.status)) {
        if (shouldUpdateAdmissionState(instance.status)) {
          await dispatch.admissions.syncAdmissionSlides({
            admissionId,
            representativeAnswers: payload,
          });
        }

        dispatch.admissionExperienceUi.refreshPosition({
          admissionId,
        });
      }

      history.replace(
        `/admission/explore/${admissionId}?page=${
          instance.lastViewedSlideIdx || 0
        }`,
      );
    },
  }),
});
