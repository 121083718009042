import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
} from "react";

import {Admission} from "@reside/reside-api-admission";
import {AdmissionFromSearch} from "../../../services/AdmissionsService";

const RestoreAdmissionContext = createContext({
  admission: undefined,
  toggle: (admission?: Admission | AdmissionFromSearch) => {},
});

export const useAdmissionsRestoreModalContext = () =>
  useContext(RestoreAdmissionContext);

export const AdmissionsRestoreModalProvider: FunctionComponent = ({
  children,
}) => {
  const [admission, setAdmission] = useState<Admission | AdmissionFromSearch>(
    undefined,
  );

  return (
    <RestoreAdmissionContext.Provider value={{admission, toggle: setAdmission}}>
      {children}
    </RestoreAdmissionContext.Provider>
  );
};
