import React, {useCallback, useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {Manager, Popper, Reference} from "react-popper";
import {useToggle} from "react-use";
import {useHistory} from "react-router-dom";
import qs from "qs";
import Alert from "react-s-alert";
import {styled, ThinScrollbar} from "@reside/ui";
import {AdmissionFlag} from "@reside/reside-api-admission";

import {AdmissionsService} from "../../../../services/AdmissionsService";
import {Spinner} from "../../../spinner";
import {FlagList, FlagListHeader} from "../../../flag-list";
import {FlagCountIcon} from "../../../flag-count-icon";
import {buildResponseErrorMessage} from "../../../../services/api/errorHelpers";

type Props = Readonly<{
  flagCount: number;
  admissionId: string;
  initialIsLoading?: boolean;
  initialIsOpen?: boolean;
  initialFlags?: ReadonlyArray<AdmissionFlag>;
}>;

export const FlagPopover = ({
  flagCount,
  admissionId,
  initialIsLoading,
  initialIsOpen,
  initialFlags,
}: Props) => {
  const [open, toggle] = useToggle(initialIsOpen);
  const [flags, setFlags] = useState(initialFlags);
  const [isLoading, setIsLoading] = useState(initialIsLoading);

  const history = useHistory();

  const onFlagItemClick = (flagId: string, sectionId: string) => {
    history.push({
      pathname: `/admin/admissions/${admissionId}/section/${sectionId}`,
      search: qs.stringify({flagId}),
    });
  };

  const onIconClick = useCallback(async () => {
    toggle(true);

    try {
      setIsLoading(true);
      const {data} = await AdmissionsService.getFlags(admissionId);

      setFlags(data);
    } catch (resp) {
      Alert.error(
        await buildResponseErrorMessage(
          resp,
          "Could not load flags for admission.",
        ),
      );
    } finally {
      setIsLoading(false);
    }
  }, [admissionId, toggle]);

  const closeOverlay = () => {
    toggle(false);
  };

  return (
    <Manager>
      <div>
        <Reference>
          {({ref}) => (
            <div
              ref={ref}
              onClick={flagCount > 0 ? onIconClick : undefined}
              data-test-id={`admissionFlags-${admissionId}-${flagCount}`}
            >
              <FlagCountIcon count={flagCount} active={open} />
            </div>
          )}
        </Reference>
        {open && (
          <OutsideClickHandler onOutsideClick={closeOverlay}>
            <Popper placement="auto">
              {({ref, style}) => (
                <ThinScrollbar>
                  <Wrapper ref={ref} style={style}>
                    <FlagListHeader />
                    {flags && !isLoading ? (
                      <Scrollable>
                        <FlagList
                          onFlagItemClicked={onFlagItemClick}
                          flags={flags}
                        />
                      </Scrollable>
                    ) : (
                      <SpinnerWrapper>
                        <Spinner relative />
                      </SpinnerWrapper>
                    )}
                  </Wrapper>
                </ThinScrollbar>
              )}
            </Popper>
          </OutsideClickHandler>
        )}
      </div>
    </Manager>
  );
};

FlagPopover.defaultProps = {
  initialIsLoading: false,
  initialIsOpen: false,
  initialFlags: undefined,
};

const Wrapper = styled.div`
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  width: 400px;
  z-index: 1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
`;

const Scrollable = styled.div`
  height: inherit;
  max-height: inherit;
  overflow-y: scroll;
`;

const SpinnerWrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
  padding: 20px;
`;
