import {useMemo, useState} from "react";
import {useDeepCompareEffect} from "react-use";
import {debounce} from "lodash";

export const useDebouncedValue = <T>(value: T, timeout = 1000) => {
  const [debounced, setDebounced] = useState(value);

  const debounceValue = useMemo(
    () => debounce(value => setDebounced(value), timeout),
    [setDebounced, timeout],
  );

  useDeepCompareEffect(() => {
    debounceValue(value);
  }, [value]);

  return debounced;
};
