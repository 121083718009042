import {useMutation} from "react-query";
import {useHistory} from "react-router";
import Alert from "react-s-alert";
import {logErrorToSentry} from "../../utils/sentry";
import {HubAndSpokeService} from "../HubAndSpokeService";

export const useDeleteEventTrigger = () => {
  const history = useHistory();

  return useMutation(
    async (eventTriggerId: string) => {
      const response = await HubAndSpokeService.deleteEventTrigger(
        eventTriggerId,
      );
      return response;
    },
    {
      onSuccess: () => {
        Alert.success("Event trigger successfully deleted!");
        history.push("/admin/hub-and-spoke/event-triggers");
      },
      onError: err => {
        logErrorToSentry(err);
        Alert.error("Failed to delete event trigger");
      },
    },
  );
};
