import React, {
  useEffect,
  useRef,
  createContext,
  useState,
  FunctionComponent,
  useContext,
  ReactNode,
} from "react";
import {useIntersection} from "react-use";

type ScrollSpyProps = {
  id: string;
  onIntersection?: (id: string) => void;
};

export const useScrollSpyRef = ({id, onIntersection}: ScrollSpyProps) => {
  const [wasInViewport, setWasInViewport] = useState(false);
  const {setActiveId} = useScrollSpyContext();
  const scrollSpyRef = useRef(null);

  const {intersectionRatio = 0} =
    useIntersection(scrollSpyRef, {
      root: document.querySelector(".js-reporting-scroll-container"),
      rootMargin: "0px",
      threshold: 1,
    }) ?? {};

  useEffect(() => {
    if (intersectionRatio === 1) {
      setWasInViewport(true);
      setActiveId(id);
      onIntersection?.(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, intersectionRatio]);

  return {scrollSpyRef, wasInViewport};
};

export const UseScrollSpy = ({
  children,
  ...props
}: ScrollSpyProps & {
  children: (props: ReturnType<typeof useScrollSpyRef>) => ReactNode;
}) => <>{children(useScrollSpyRef(props))}</>;

const scrollSpyContext = createContext({
  activeId: "",
  setActiveId: (id: string) => {},
});

export const ScrollSpyProvider: FunctionComponent = ({children}) => {
  const [activeId, setActiveId] = useState("");

  return (
    <scrollSpyContext.Provider value={{activeId, setActiveId}}>
      {children}
    </scrollSpyContext.Provider>
  );
};

export const useScrollSpyContext = () => useContext(scrollSpyContext);
