import React from "react";
import {SelectField} from "@reside/ui";
import {Approval} from "@reside/reside-api-admission";

import {makeReadableEnum} from "../../../list-table/helpers";

export const ApproverSelectField = () => (
  <SelectField
    name="approverTitle"
    label="Title"
    placeholder="Title"
    options={options}
  />
);

const readableApprovalTitle = makeReadableEnum(Approval.ApproverTitleEnum);

const options = Object.values(Approval.ApproverTitleEnum).map(value => ({
  value,
  label: readableApprovalTitle(value),
}));
