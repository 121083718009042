import React from "react";
import {
  TaskCheckboxChildren,
  TaskCheckboxFadedSmall,
  TaskCheckboxContextProvider,
} from "@reside/ui";
import {Checklist} from "../../checklist";
import {getChecklistFormValues} from "../../../helper";
import {PccDocumentUploadTaskCheckbox} from "../../pcc-document-upload-task-checkbox";
import {AdmissionChecklistProps} from "./utils/types";
import {DisabledNotApplicableStatusOption} from "./DisableNotApplicableStatusOption";
import {Label, SmallFieldErrorText} from "./style";

export const AdmissionChecklistRenderer = ({
  checklist,
  validationErrors,
  isPccDocumentUploadEnabled,
  onChange,
  onUpload,
}: AdmissionChecklistProps) => {
  const initialValues = getChecklistFormValues(checklist);

  return (
    <TaskCheckboxContextProvider>
      <Checklist
        onChange={onChange}
        checklist={checklist}
        initialValues={initialValues}
        validationErrors={validationErrors}
      >
        {({checklistItem, validationError, disabled}) => (
          <TaskCheckboxFadedSmall
            key={checklistItem.id}
            name={checklistItem.id}
            disabled={disabled}
            Label={Label}
            label={checklistItem.description}
            onComplete={
              isPccDocumentUploadEnabled && checklistItem.documentUploadRequired
                ? ({setCheckboxClosed}) => {
                    setCheckboxClosed();
                    onUpload(checklistItem);
                  }
                : undefined
            }
            renderNotApplicableStatusOption={
              isPccDocumentUploadEnabled && checklistItem.documentUploadRequired
                ? DisabledNotApplicableStatusOption
                : undefined
            }
            children={
              isPccDocumentUploadEnabled
                ? (props: TaskCheckboxChildren) => (
                    <PccDocumentUploadTaskCheckbox
                      {...props}
                      checklistItem={checklistItem}
                      onUpload={onUpload}
                    />
                  )
                : undefined
            }
            CheckboxWrapper={
              (({children}: any) => (
                <>
                  <div>{children}</div>
                  {validationError && (
                    <SmallFieldErrorText>{validationError}</SmallFieldErrorText>
                  )}
                </>
              )) as any
            }
          />
        )}
      </Checklist>
    </TaskCheckboxContextProvider>
  );
};
