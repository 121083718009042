var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { FormikConsumer } from "formik";
import { styled } from "../../theme";
import { useBlockComponentContext } from "../BlockComponentContext";
import { isInvalid } from "../../form";
var defaultProps = {
    ValidationBlock: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 10px;\n    margin: 0 -10px;\n    border-radius: 4px;\n    margin-bottom: 5px;\n    border: 1px solid transparent;\n    ", ";\n  "], ["\n    padding: 10px;\n    margin: 0 -10px;\n    border-radius: 4px;\n    margin-bottom: 5px;\n    border: 1px solid transparent;\n    ", ";\n  "])), function (_a) {
        var isInvalid = _a.isInvalid;
        return isInvalid &&
            "\n        border: 1px solid #fac1c7;\n        background: #fff1f1;\n    ";
    }),
};
export var ValidationBlockBoundary = function (props) {
    var _a = __assign(__assign({}, props), useBlockComponentContext()), name = _a.name, children = _a.children, ValidationBlock = _a.ValidationBlock;
    return (React.createElement(FormikConsumer, null, function (formik) { return (React.createElement(ValidationBlock, { isInvalid: isInvalid(name, formik), "data-test-id": isInvalid(name, formik) }, children)); }));
};
ValidationBlockBoundary.defaultProps = defaultProps;
var templateObject_1;
