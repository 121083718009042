var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { NodeType } from "@reside/forms/dist/templateTypes";
import { useBlockComponentContext, } from "./BlockComponentContext";
/**
 * Please keep the blocks alphabetically ordered.
 */
import { BlockCard } from "./block-card";
import { BlockCollapsible } from "./block-collapsible";
import { BlockColumn } from "./block-column";
import { BlockCompletionChecklist } from "./block-completion-checklist";
import { BlockContinueButton } from "./block-continue-button";
import { BlockFragment } from "./block-fragment";
import { BlockFieldArray } from "./block-field-array";
import { BlockFormControl } from "./block-form-control";
import { BlockImage } from "./block-image";
import { BlockList } from "./block-list";
import { BlockMandatoryVideo } from "./block-mandatory-video";
import { BlockParagraph } from "./block-paragraph";
import { BlockSubtitle } from "./block-subtitle";
import { BlockTableOfContent } from "./block-table-of-content";
import { BlockTitle } from "./block-title";
import { ErrorBoundary } from "../error-boundary";
import { ErrorMessage } from "../error-message";
export var BlockByType = function (_a) {
    var block = _a.block, queryVariables = _a.queryVariables, numberOfBlocks = _a.numberOfBlocks, BlockCard = _a.BlockCard, BlockCollapsible = _a.BlockCollapsible, BlockCompletionChecklist = _a.BlockCompletionChecklist, BlockContinueButton = _a.BlockContinueButton, BlockFieldArray = _a.BlockFieldArray, BlockFragment = _a.BlockFragment, BlockFormControl = _a.BlockFormControl, BlockImage = _a.BlockImage, BlockList = _a.BlockList, BlockMandatoryVideo = _a.BlockMandatoryVideo, BlockParagraph = _a.BlockParagraph, BlockSubtitle = _a.BlockSubtitle, BlockTableOfContent = _a.BlockTableOfContent, BlockTitle = _a.BlockTitle, BlockColumn = _a.BlockColumn;
    switch (block.type) {
        // Note: Keep the types sorted alphabetically
        case NodeType.CARD:
            return React.createElement(BlockCard, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.COLLAPSIBLE:
            return React.createElement(BlockCollapsible, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.COLUMN:
            return React.createElement(BlockColumn, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.COMPLETION_CHECKLIST:
            return React.createElement(BlockCompletionChecklist, __assign({}, block));
        case NodeType.CONTINUE_BUTTON:
            return React.createElement(BlockContinueButton, __assign({}, block));
        case NodeType.FIELD_ARRAY:
            return React.createElement(BlockFieldArray, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.FORM_CONTROL:
            return (React.createElement(BlockFormControl, __assign({}, block, { queryVariables: queryVariables, numberOfBlocks: numberOfBlocks })));
        case NodeType.FRAGMENT:
            return React.createElement(BlockFragment, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.IMAGE:
            return React.createElement(BlockImage, __assign({}, block));
        case NodeType.LIST:
            return React.createElement(BlockList, __assign({}, block, { queryVariables: queryVariables }));
        case NodeType.PARAGRAPH:
            return React.createElement(BlockParagraph, __assign({}, block));
        case NodeType.MANDATORY_VIDEO:
            return React.createElement(BlockMandatoryVideo, __assign({}, block));
        case NodeType.SUBTITLE:
            return React.createElement(BlockSubtitle, __assign({}, block));
        case NodeType.TABLE_OF_CONTENT:
            return React.createElement(BlockTableOfContent, __assign({}, block));
        case NodeType.TITLE:
            return React.createElement(BlockTitle, __assign({}, block));
        default:
            throw new Error("Renderer not implemented ".concat(block.type));
    }
};
/**
 * The entry component for rendering dynamic form based on JSON Template.
 */
export var BlockField = function (_a) {
    var _b, _c;
    var onCatch = _a.onCatch, props = __rest(_a, ["onCatch"]);
    var blockComponents = useBlockComponentContext();
    // Important to check explicit false
    return ((_c = (_b = props.block) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.visible) === false ? null : (React.createElement(ErrorBoundary, { renderError: function (error) { return (React.createElement(ErrorMessage, { title: "Failed to render JSON block.", message: error.message })); }, onCatch: onCatch },
        React.createElement(BlockByType, __assign({}, blockComponents, props))));
};
BlockByType.defaultProps = {
    BlockCard: BlockCard,
    BlockCollapsible: BlockCollapsible,
    BlockColumn: BlockColumn,
    BlockCompletionChecklist: BlockCompletionChecklist,
    BlockFormControl: BlockFormControl,
    BlockFragment: BlockFragment,
    BlockImage: BlockImage,
    BlockList: BlockList,
    BlockMandatoryVideo: BlockMandatoryVideo,
    BlockParagraph: BlockParagraph,
    BlockSubtitle: BlockSubtitle,
    BlockTableOfContent: BlockTableOfContent,
    BlockTitle: BlockTitle,
    BlockFieldArray: BlockFieldArray,
    BlockContinueButton: BlockContinueButton,
};
