"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = exports.MIN_LENGTH_RULE_NAME = void 0;
exports.MIN_LENGTH_RULE_NAME = "min_length";
exports.Validator = {
    name: exports.MIN_LENGTH_RULE_NAME,
    errorMessage: "This field must be at least :".concat(exports.MIN_LENGTH_RULE_NAME, " characters."),
    validate: function (value, requirement) {
        return value.toString().length >= Number(requirement);
    },
};
