import React from "react";
import SAlert from "react-s-alert";
import {breakpoint} from "@reside/ui";
import {useWindowSize} from "react-use";

import {AlertWrapper} from "./AlertWrapper";

export const Alert = () => {
  const {width} = useWindowSize();

  return (
    <SAlert
      position={width > breakpoint.md ? "top-right" : "top"}
      contentTemplate={AlertWrapper}
    />
  );
};
