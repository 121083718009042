import React from "react";
import {cx} from "@emotion/css";
import {styled, Box, Caption} from "@reside/ui";

import {Avatar} from "../avatar";

import "./Dropdown.scss";

function isEmpty(items: ReadonlyArray<any>) {
  if (
    items.length > 1 &&
    items[0].data.length === 0 &&
    items[1].data.length === 0
  )
    return true;

  if (items.length === 1 && items[0].data.length === 0) return true;

  return false;
}

export type DataItem = {
  id?: string;
  value: string;
  description?: string;
  avatar?: string;
};

export type DataItemGroup<T = DataItem> = {
  label: string;
  data: ReadonlyArray<T>;
};

export const DropdownList = <Item extends DataItem>({
  isSearching,
  items,
  getItemProps,
  highlightedIndex,
  ...props
}: {
  isSearching: boolean;
  items: ReadonlyArray<DataItemGroup<Item>>;
  getItemProps: (val: {item: Item}) => any;
  highlightedIndex: number;
}) => {
  let offset = 0;

  return (
    <Wrapper {...props}>
      {isSearching ? (
        <div className="dropdown-0-25">
          <div className="dropdown-0-26">
            <Caption>SEARCHING</Caption>
            <div className="dot-wave">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
      ) : isEmpty(items) ? (
        <div className="dropdown-0-25">
          <div className="dropdown-0-26">
            <Caption>NO SEARCH RESULTS</Caption>
          </div>
        </div>
      ) : (
        items.map((item, idx) => {
          offset = idx === 0 ? 0 : offset + items[idx - 1].data.length;

          if (item.data.length > 0) {
            return (
              <div key={idx} className="dropdown-0-27">
                <div className="dropdown-0-28">
                  <Caption>{item.label.toUpperCase()}</Caption>
                </div>
                {item.data.map((data, idy) => (
                  <Row
                    key={idy}
                    className={cx({
                      active: idy + offset === highlightedIndex,
                    })}
                    {...getItemProps({
                      item: data,
                    })}
                  >
                    <Box flexDirection="row" alignItems="center">
                      <Box className="dropdown-0-29">
                        <Avatar size={40} name={data.value} />
                      </Box>
                      <Box>
                        <div className="dropdown-0-30">
                          <span>{data.value}</span>
                        </div>
                        {data.description && (
                          <div className="dropdown-0-31">
                            <span>{data.description}</span>
                          </div>
                        )}
                      </Box>
                    </Box>
                  </Row>
                ))}
              </div>
            );
          } else {
            return null;
          }
        })
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ebedf0;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;

  max-height: 450px;
  overflow: auto;
`;

const Row = styled.div`
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;

  &.active {
    background-color: rgba(168, 182, 202, 0.1);
  }
`;
