import {Button, styled} from "@reside/ui";

export const Description = styled.div`
  padding: ${({theme}) => theme.space[2]}px 0;

  ${({theme}) => theme.media.md} {
    padding: ${({theme}) => theme.space[6]}px 0;
    flex: 1;
  }
`;

export const Center = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: ${({theme}) => theme.space[6]}px;
`;
