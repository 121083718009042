import {styled, FlexRowCenter, Text, Icon} from "@reside/ui";
import {Field} from "formik";

export const Input = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding-top: 3px;
  padding-left: 32px;
  padding-right: 110px;
  font-weight: 500;
  font-size: 15px;
  font-family: Gilroy, sans-serif;
  background-color: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.darkBlue10};
  border-radius: 100px;

  color: ${({theme}) => theme.color.darkBlue100};

  &.hiddenEnter {
    padding-right: 26px;
  }
  #243745 ::placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  :-ms-input-placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  ::-ms-input-placeholder {
    color: ${({theme}) => theme.color.darkBlue100};
    opacity: 0.75;
  }
  &:focus {
    border-color: ${({theme}) => theme.color.primary};
    outline: none;
    ::placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
    :-ms-input-placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
    ::-ms-input-placeholder {
      color: ${({theme}) => theme.color.darkBlue100};
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 280px;
  height: 32px;
  box-sizing: border-box;
  position: relative;
`;

export const IconWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 8px;
  bottom: 9px;
  left: 8px;
  pointer-events: none;
  text-align: left;
`;

export const ClearButton = styled.span<{showClearButton: boolean}>`
  cursor: pointer;
  position: absolute;
  display: ${({showClearButton}) => (showClearButton ? "block" : "none")};
  top: 9px;
  right: 9px;
`;

export const ResidentCardWrapp = styled(FlexRowCenter)`
  padding: 24px;
  margin-bottom: 40px;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.color.borderLightGray};
  gap: 40px;
  height: fit-content;
  background-color: ${({theme}) => theme.color.white};
`;

export const Title = styled(Text)`
  text-transform: uppercase;
  color: ${({theme}) => theme.color.gray100};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: elipsis;
`;

export const Value = styled(Text)`
  padding: 8px;
`;

export const Radio = styled(Field)`
  &:before {
    width: 1.375em;
    height: 1.375em;
    min-width: 1.375em;
    max-height: 1.375em;
    border-radius: 50%;
    left: -6px;
    bottom: 5px;
    position: relative;
    background-color: ${({theme}) => theme.color.white};
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid ${({theme}) => theme.color.primary};
  }

  &:checked:after {
    width: 0.9em;
    height: 0.9em;
    min-width: 0.9em;
    max-height: 0.9em;
    border-radius: 50%;
    top: -25px;
    left: -1px;
    position: relative;
    background-color: ${({theme}) => theme.color.primary};
    content: "";
    display: inline-block;
    visibility: visible;
  }

  &:disabled:before {
    border: 2px solid ${({theme}) => theme.color.gray100};
  }
`;

export const HouseIcon = styled(Icon)`
  margin-right: 6px;
`;

export const UserIcon = styled(Icon)`
  margin-right: 6px;
`;

export const FacilityIconsWrapper = styled.div`
  line-height: 20px;
`;

export const ActionCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
