import React from "react";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {SplitButton} from "../../../../split-button";
import {DeleteAdmissionDropdownItem} from "../DeleteAdmissionDropdownItem";
import {ViewAdmissionDropdownItem} from "../ViewAdmissionDropdownItem";
import {ReturnToResidentDropdownItem} from "./ReturnToResidentDropdownItem";
import {ApproveAdmissionButton} from "./ApproveAdmissionButton";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const SignedSplitButton = ({admission}: Props) => (
  <SplitButton
    size="small"
    defaultOptions={<ApproveAdmissionButton admission={admission} />}
  >
    <ViewAdmissionDropdownItem admission={admission} />
    <ReturnToResidentDropdownItem admission={admission} />
    <DeleteAdmissionDropdownItem admission={admission} />
  </SplitButton>
);
