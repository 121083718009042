import React, {useState} from "react";
import {
  Button,
  FormCard,
  Modal,
  ModalContent,
  ModalFooterButtons,
} from "@reside/ui";
import {Formik} from "formik";
import {EventTriggerForm} from "../../services/HubAndSpokeService";
import {EventTriggersFormFields} from "./EventTriggersFormFields";
import {BackdropSpinner} from "../../atoms/spinner";
import {useEventTriggerFormFields} from "./hooks/useEventTriggerFormFields";
import {useEditEventTrigger} from "../../services/hooks/useEditEventTrigger";
import {useDeleteEventTrigger} from "../../services/hooks/useDeleteEventTrigger";
import {FormWrapper} from "./styles";
import {generatePathPreview, validate} from "./helpers";
import {useTestUpload} from "../../services/hooks/useTestUpload";

export const EventTriggerEdit = () => {
  const {
    mutate: deleteEventTrigger,
    isLoading: isDeletingEventTrigger,
    variables: deletedEventTriggerId,
  } = useDeleteEventTrigger();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const {isLoadingEventTrigger, initialValues, eventTriggerId} =
    useEventTriggerFormFields();

  const {mutate: editEventTrigger, isLoading: isEditing} =
    useEditEventTrigger();

  const {mutate: testUpload} = useTestUpload();

  if (isLoadingEventTrigger) return null;

  return (
    <BackdropSpinner
      active={isLoadingEventTrigger || isEditing || isDeletingEventTrigger}
    >
      <FormWrapper>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => {
            setIsDeleteModalOpen(false);
          }}
        >
          {({closeModal}) => (
            <>
              <ModalContent>
                Are you sure you want to delete this event trigger?
              </ModalContent>
              <ModalFooterButtons>
                <Button
                  onClick={() => {
                    closeModal();
                    setIsDeleteModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => deleteEventTrigger(eventTriggerId)}
                  disabled={
                    isDeletingEventTrigger &&
                    deletedEventTriggerId === eventTriggerId
                  }
                >
                  {isDeletingEventTrigger &&
                  deletedEventTriggerId === eventTriggerId
                    ? "Deleting Event Trigger..."
                    : "Delete"}
                </Button>
              </ModalFooterButtons>
            </>
          )}
        </Modal>

        <FormCard title="Edit Event Trigger">
          <Formik<EventTriggerForm>
            enableReinitialize
            initialValues={initialValues}
            // https://github.com/jaredpalmer/formik/issues/2675
            onSubmit={() => {
              return;
            }}
            validate={validate}
          >
            {({values}) => (
              <>
                <Button
                  style={{position: "absolute", top: "62px", right: "165px"}}
                  color="primary"
                  onClick={() =>
                    testUpload({
                      sftp_config_id: values.sftp_config_id,
                      target_path: generatePathPreview(values.document_path),
                    })
                  }
                >
                  Test upload
                </Button>
                <Button
                  style={{position: "absolute", right: "50px", top: "62px"}}
                  color="danger"
                  onClick={() => setIsDeleteModalOpen(true)}
                  disabled={
                    isDeletingEventTrigger &&
                    deletedEventTriggerId === eventTriggerId
                  }
                >
                  Delete
                </Button>

                <EventTriggersFormFields
                  onSubmit={values => editEventTrigger(values)}
                  isLoading={isEditing}
                />
              </>
            )}
          </Formik>
        </FormCard>
      </FormWrapper>
    </BackdropSpinner>
  );
};
