import React from "react";
import {ResidentCardNode} from "./ResidentCardNode";
import {ResidentCardWrapp} from "./styles";
import {Resident} from "../types";
import {format} from "date-fns";
import {getAge, maskSsnString, parseAPIDate} from "../helpers";
import {isEmpty} from "lodash";
import {UserDto} from "@reside/reside-api-app";
import {isResideAdmin} from "../../../utils/helpers/users";

type ResidentForCard = Pick<
  Resident,
  | "resident_id"
  | "first_name"
  | "last_name"
  | "date_of_birth"
  | "email"
  | "ssn"
  | "phone_number"
  | "gender"
  | "created_by"
  | "organization"
> & {age?: number};

export const ResidentCard = ({
  resident,
  user,
}: {
  resident: ResidentForCard;
  user: UserDto;
}): JSX.Element => {
  const emptyValue = "—";
  const residentDateOfBirth = !isEmpty(resident?.date_of_birth)
    ? `${format(parseAPIDate(resident.date_of_birth), "MM/dd/yyyy")} (${getAge(
        new Date(resident?.date_of_birth),
      )})`
    : emptyValue;

  return (
    <ResidentCardWrapp>
      {isResideAdmin(user) && (
        <ResidentCardNode title="Org." value={resident?.organization?.name} />
      )}
      <ResidentCardNode title="Resident ID" value={resident?.resident_id} />
      <ResidentCardNode title="First name" value={resident?.first_name} />
      <ResidentCardNode title="Last name" value={resident?.last_name} />
      <ResidentCardNode title="Date of birth" value={residentDateOfBirth} />
      <ResidentCardNode
        title="Email"
        value={!isEmpty(resident?.email) ? resident.email : emptyValue}
      />
      <ResidentCardNode
        title="SSN"
        value={
          !isEmpty(resident?.ssn) ? maskSsnString(resident.ssn) : emptyValue
        }
      />
      <ResidentCardNode
        title="Phone number"
        value={
          !isEmpty(resident?.phone_number) ? resident.phone_number : emptyValue
        }
      />
      <ResidentCardNode
        title="Gender"
        value={!isEmpty(resident?.gender) ? resident.gender : emptyValue}
      />
      <ResidentCardNode title="Created by" value={resident?.created_by} />
    </ResidentCardWrapp>
  );
};
