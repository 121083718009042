import {useQuery} from "react-query";
import {OrganizationsService} from "../../../services/OrganizationsService";

/**
 * @param organizationId
 */
export const useGetTableData = ({organizationId}: {organizationId: string}) =>
  useQuery(
    ["useGetTableData", organizationId],
    async ({queryKey}: {queryKey: string[]}) => {
      const {data} =
        await OrganizationsService.importExportUsers.getImportExportHistory(
          queryKey[1],
        );
      return data;
    },
  );
