import {FormEvent} from "react";

import {
  FacilityFeatureFlagsDto,
  OrganizationFeatureFlagsDto,
} from "@reside/reside-api-app";
import {
  FeatureWrapper,
  CheckboxWrapper,
  StyledCheckbox,
  StyledLine,
  StyledTd,
} from "./style";

type Props = {
  id?: string;
  name?: string;
  organizationName?: string;
  allFeatureFlags: ReadonlyArray<string>;
  featureFlags?: ReadonlyArray<any>;
  facilities?: ReadonlyArray<
    OrganizationFeatureFlagsDto | FacilityFeatureFlagsDto
  >;
  /** Disable actions for given feature flags. */
  disableCheckbox?: boolean;
  onChange?: ({
    id,
    featureTypes,
  }: {
    id: string;
    featureTypes: ReadonlyArray<string>;
  }) => void;
};

export const FeatureFlagsTableRow = ({
  organizationName,
  name,
  id,
  featureFlags,
  facilities,
  allFeatureFlags,
  disableCheckbox = false,
  onChange,
}: Props) => {
  return (
    <>
      <StyledLine>
        <StyledTd isParent={Boolean(organizationName)}>
          {organizationName ?? name}
        </StyledTd>
        {allFeatureFlags?.map((featureType: any) => (
          <td key={featureType}>
            <FeatureWrapper>
              {featureFlags && (
                <CheckboxWrapper>
                  <StyledCheckbox
                    checked={featureFlags.includes(featureType)}
                    disabled={disableCheckbox}
                    onChange={(event: FormEvent) =>
                      onChange({
                        id,
                        featureTypes: (event.currentTarget as any).checked
                          ? [...featureFlags, featureType]
                          : featureFlags.filter(
                              (feature: any) => feature !== featureType,
                            ),
                      })
                    }
                    value={id}
                    name={`${featureType}-${id}`}
                    testId={`${featureType}-${id}`}
                  />
                </CheckboxWrapper>
              )}
            </FeatureWrapper>
          </td>
        ))}
      </StyledLine>
      {facilities?.map(facility => (
        <FeatureFlagsTableRow
          {...facility}
          allFeatureFlags={allFeatureFlags}
          key={facility.id}
          onChange={onChange}
        />
      ))}
    </>
  );
};
