export enum PathVariablesEnum {
  FacilityName = "{facility-name}",
  OrganizationName = "{organization-name}",
  ResidentName = "{resident-name}",
  ResidentLastName = "{resident-last-name}",
  AdmissionDate = "{admission-date}",
  CurrentDate = "{current-date}",
  CurrentDateTime = "{current-datetime}",
  CurrentUnixTime = "{current-unix-time}",
  UUID = "{uuid}",
}

interface IPathVarable {
  variable: `${PathVariablesEnum}`;
  description: string;
}

export const PATH_VARIABLES: IPathVarable[] = [
  {
    variable: "{facility-name}",
    description: "The name of the Facility of the admission",
  },
  {
    variable: "{organization-name}",
    description: "The name of the organization of the admission",
  },
  {
    variable: "{resident-name}",
    description: "The full name of the resident",
  },
  {
    variable: "{resident-last-name}",
    description: "The last name of the resident",
  },
  {
    variable: "{admission-date}",
    description: "The date of admission (e.g. 20091110)",
  },
  {
    variable: "{current-date}",
    description: "Current date when the PDF file is generated (e.g. 20091110)",
  },
  {
    variable: "{current-datetime}",
    description:
      "Current date and time when the PDF file is generated (e.g. 20091110230000)",
  },
  {
    variable: "{current-unix-time}",
    description:
      "Current time when the PDF file is generated in UNIX format (e.g. 1698691379)",
  },
  {
    variable: "{uuid}",
    description:
      "UUID generated randomly (e.g. d8b17a23-cf70-4425-b6da-e5294c27aa8e)",
  },
];
