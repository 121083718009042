import React, {useState, useCallback, FormEvent} from "react";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";

import {OrganizationsService} from "../../services/OrganizationsService";
import {GeneralSearch} from "../search-box";

import "./AdminOrganizationsSearch.scss";

export const AdminOrganizationsSearch = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const {data = [], isLoading} = useQuery(
    ["searchOrganizations", searchQuery],
    async ({queryKey: [_, query]}) => {
      const {data} = await OrganizationsService.get({query});

      return data.content;
    },
    {enabled: searchQuery.length > 1},
  );

  const handleSelect = useCallback(
    organisation => {
      history.push(`/admin/organizations/${organisation.id}/edit`);
    },
    [history],
  );

  const reduceItems = () => [
    {
      label: "Organization",
      data: data.map(organization => ({
        id: organization.id,
        value: organization.name,
        description: organization.address.city,
      })),
    },
  ];

  return (
    <GeneralSearch
      placeholder="Search organization"
      items={reduceItems()}
      isSearching={isLoading}
      inputValue={searchQuery}
      onChange={handleSelect}
      onInputChange={event => setSearchQuery(event.target.value)}
      onPressSpace={(event: FormEvent<HTMLInputElement>) => {
        if (searchQuery.trim().length > 0) setSearchQuery(searchQuery + " ");
      }}
      itemToString={item => item?.value || ""}
    />
  );
};
