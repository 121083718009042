import {residentsHttp} from "./api/residentApiClient";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {Resident, ResidentStatus} from "../pages/page-admin-residents/types";
import {CreateResidentDto} from "../pages/page-admin-residents-forms/types";

export const SORT_RESIDENTS_BY = [
  "name",
  "date_of_birth",
  "created",
  "created_by",
] as const;

type SearchResidents = {
  facility_ids?: string[];
  search?: string;
  page?: number;
  page_size?: number;
  sort_by?: (typeof SORT_RESIDENTS_BY)[number];
  sort_direction?: "asc" | "desc";
  resident_status?: ResidentStatus | null;
};
type LinkResidentToAdmission = {
  admission_id: string;
  resident_id: string;
};
export type SearchSimilarResidents = {
  first_name: string;
  last_name: string;
  date_of_birth: Date | string;
};

export const ResidentService = {
  getResidentsViaPost: (body: SearchResidents) =>
    residentsHttp.post<{data: Resident[]; total_count: number}>(
      "/search/",
      body,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
  linkResidentToAdmission: (body: LinkResidentToAdmission) =>
    residentsHttp.post<Resident[]>("/link-admission/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  getOneResident: (resident_uuid: string) =>
    residentsHttp.get<Resident>(`/${resident_uuid}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  createResident: (body: CreateResidentDto) =>
    residentsHttp.post<Resident>("/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  editResident: (body: CreateResidentDto, resident_uuid: string) =>
    residentsHttp.put<Resident>(`/${resident_uuid}/`, body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  activateResident: (resident_uuid: string) =>
    residentsHttp.patch<Resident>(`/${resident_uuid}/activate/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  inactivateResident: (resident_uuid: string) =>
    residentsHttp.delete<Resident>(`/${resident_uuid}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  getSimilarResidentsViaPost: (body: SearchSimilarResidents) =>
    residentsHttp.post<{data: Resident[]; total_count: number}>(
      "/list-duplicates/",
      body,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
};
