import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {BlockComponentContextProvider, styled} from "@reside/ui";
import {useSearchParam} from "react-use";

import {DocumentTitle} from "../document-title";
import {LayoutContent} from "../admin-layout";
import {AdmissionContextProvider} from "../admission-explorer/AdmissionContext";
import {admissionBlockComponents} from "../admission-explorer/AdmissionBlockComponentsContextProvider";
import {Header} from "./header";
import {Menu} from "./menu";
import {Slides} from "./slides";

export const AdminAdmissionExplorerRenderer = props => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const explorerProps = {
    draft: props.draft,
    uiState: props.uiState,
    uiActions: props.uiActions,
    draftActions: props.draftActions,
    notes: props.notes,
    user: props.user,
  };

  const {sectionId} = useParams();
  const flagId = useSearchParam("flagId");

  useEffect(() => {
    const scrollToFlag = () => {
      if (flagId) {
        const element = document.getElementById(flagId);

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    };

    if (sectionId) {
      explorerProps.uiActions.goToSection(sectionId);
      scrollToFlag();
    }
    // NOTE: explorerProps needs to be as dependency because element with flagId does not exist before DOM is rendered
  }, [sectionId, flagId, explorerProps]);

  return (
    <>
      <DocumentTitle
        title={`Reside - Admin - Admission [${props.draft.instance.code}]`}
      />
      <Header
        draft={props.draft}
        onEdit={props.onEdit}
        onClose={props.onClose}
      />
      <Content>
        <Menu {...explorerProps} />
        <Slides {...explorerProps} />
      </Content>
    </>
  );
};

const Content = styled(LayoutContent)`
  flex-direction: row;
`;

export const AdminAdmissionExplorer = props => {
  return (
    <AdmissionContextProvider {...props}>
      <BlockComponentContextProvider value={admissionBlockComponents}>
        <AdminAdmissionExplorerRenderer {...props} />
      </BlockComponentContextProvider>
    </AdmissionContextProvider>
  );
};
