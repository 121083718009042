import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {QuicksightService} from "../../../services/QuicksightService";
import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";

export const useQuicksightDashboardsPermissionMutation = ({
  onConflict,
}: {
  onConflict: () => void;
}) =>
  useMutation(
    async ({
      userId,
      dashboard,
    }: {
      userId: string;
      dashboard: {dashboardId: string; checked: boolean};
      onSuccess: () => void;
    }) => QuicksightService.putPermissions(userId, dashboard),
    {
      onSuccess: (_, {onSuccess}) => {
        onSuccess();
      },
      onError: async (resp: any) => {
        if (resp.status === 409) {
          // 409 means that the dashboard no longer exists (cache is outdated)
          // so we refetch the list of dashboards after the service invalidates cache
          Alert.info(await buildResponseErrorMessage(resp));
          onConflict();

          return;
        }
        Alert.error(
          await buildResponseErrorMessage(resp, "Failed to update permission."),
        );
      },
    },
  );
