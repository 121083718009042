var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component } from "react";
import onClickOutside from "react-onclickoutside";
var ClickOutsideToggle = /** @class */ (function (_super) {
    __extends(ClickOutsideToggle, _super);
    function ClickOutsideToggle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpened: false,
        };
        _this.handleClickOutside = function () {
            return _this.props.closeOnClickOutside && _this.setState({ isOpened: false });
        };
        _this.handleChange = function (isOpened) { return _this.setState({ isOpened: isOpened }); };
        return _this;
    }
    ClickOutsideToggle.prototype.render = function () {
        var isOpened = this.state.isOpened;
        var outsideClickIgnoreClass = this.props.outsideClickIgnoreClass;
        return this.props.children({
            isOpened: isOpened,
            setIsOpened: this.handleChange,
            outsideClickIgnoreClass: outsideClickIgnoreClass,
        });
    };
    ClickOutsideToggle.defaultProps = {
        closeOnClickOutside: true,
    };
    return ClickOutsideToggle;
}(Component));
export var Toggle = onClickOutside(ClickOutsideToggle, {
    excludeScrollbar: true,
});
