import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {reactAppEnvironment} from "../config";
import {history} from "./history";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 1,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.01,
    environment: reactAppEnvironment,
  });
}

/** Errors that should not be logged to sentry, identified by message. */
const IGNORED_ERRORS = new Set(["Failed to fetch"]);

export const logErrorToSentry = process.env.REACT_APP_SENTRY_DSN
  ? (error: unknown, info: unknown = {}) => {
      if (
        typeof error === "object" &&
        error !== null &&
        "message" in error &&
        IGNORED_ERRORS.has((error as Error).message)
      ) {
        return; // Do not log ignored errors
      }

      Sentry.withScope(scope => {
        Object.keys(info).forEach(key => {
          // @ts-expect-error info error
          scope.setExtra(key, info[key]);
        });
        scope.setExtra("error", JSON.stringify(error));
        Sentry.captureException(error);
      });
    }
  : (error: unknown, info: unknown = {}) => {
      // eslint-disable-next-line no-console
      console.error(
        "Error logged to sentry:",
        error,
        "Info logged to sentry:",
        info,
      );
    };
