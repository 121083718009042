import React from "react";
import {differenceInDays, subDays, format, parseISO} from "date-fns";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {TableUserDto} from "@reside/reside-api-app";

import {
  IFromTo,
  Facility,
  ReportingContextType,
  AnalyticsType,
  AggregationType,
  Resolution,
} from "../../store/reporting/reporting.common";
import {RequiredUserPermission} from "../../atoms/admin-user-permissions/RequiredUserPermission";
import {Aggregation} from "../../atoms/admin-reporting/atoms/aggregation";
import {ReportingContext} from "./ReportingContext";
import {getAggregationValues, withCompare} from "./analytics/helpers";

type Props = Readonly<{
  dateRange: IFromTo;
  compareDateRange: IFromTo;
  facilities: Facility[];
}>;

export const withDefaultCompare: (
  dateRange: IFromTo,
  compareDateRange?: IFromTo,
) => IFromTo = (dateRange, compareDateRange) => {
  if (compareDateRange?.from && compareDateRange.to) {
    return compareDateRange;
  }

  const periodLength =
    differenceInDays(parseISO(dateRange.to), parseISO(dateRange.from)) + 1;

  return {
    from: format(
      subDays(parseISO(dateRange.from), periodLength),
      DATE_FORMAT.ISO_DATE,
    ),
    to: format(subDays(parseISO(dateRange.from), 1), DATE_FORMAT.ISO_DATE),
  };
};

export const AggregationAdmissionsInTotal = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.APPROVED_ARCHIVED_ADMISSIONS}
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithResolution,
          type: AnalyticsType.approvedAndArchivedAdmissions,
          query: {...props.dateRange, resolution: Resolution.DAY},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Approved Admissions"
          attribute="In total"
          emptyValue="0"
          values={getAggregationValues(d, AggregationType.SUM)}
          implicitCompare={!props.compareDateRange}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);

export const AggregationAdmissionsRepresentativeRate = (props: Props) => (
  <RequiredUserPermission
    permission={
      TableUserDto.PermissionsEnum
        .PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE
    }
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithoutResolution,
          type: AnalyticsType.representativeRate,
          query: {...props.dateRange},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Admissions"
          attribute="Signed by resident representative"
          units="%"
          emptyValue="NA"
          values={getAggregationValues(d, AggregationType.WEIGHTED_AVG).map(
            x => x * 100,
          )}
          formatOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          implicitCompare={!props.compareDateRange}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);

export const AggregationAdmissionsTimeToClose = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.AVG_TIME_SPENT_TO_CLOSE_ADMISSION}
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithResolution,
          type: AnalyticsType.timeToClose,
          query: {...props.dateRange, resolution: Resolution.DAY},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Avg. time"
          attribute="Spent to approve admission"
          units="h"
          emptyValue="NA"
          values={getAggregationValues(d, AggregationType.WEIGHTED_AVG)
            .map(x => x * 24)
            .reverse()}
          formatOptions={{minimumFractionDigits: 0, maximumFractionDigits: 1}}
          implicitCompare={!props.compareDateRange}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);

export const AggregationManagedMedicaidRate = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.RESIDENTS_WITH_MANAGED_MEDICAID}
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithoutResolution,
          type: AnalyticsType.managedMedicaidRate,
          query: {...props.dateRange},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Residents"
          attribute="with Medicaid"
          values={getAggregationValues(d, AggregationType.WEIGHTED_AVG).map(
            x => x * 100,
          )}
          formatOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          units="%"
          implicitCompare={!props.compareDateRange}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);

export const AggregationMissingMedicaidRate = (props: Props) => (
  <RequiredUserPermission
    permission={
      TableUserDto.PermissionsEnum.PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT
    }
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithoutResolution,
          type: AnalyticsType.missingMedicareRate,
          query: {...props.dateRange},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Residents"
          attribute="without Medicare"
          values={getAggregationValues(d, AggregationType.WEIGHTED_AVG).map(
            x => x * 100,
          )}
          formatOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          units="%"
          implicitCompare={!props.compareDateRange}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);

export const AggregationReadmissionRate = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.READMISSION_RATE}
  >
    <ReportingContext
      loaderComponent={null}
      dataPaths={withCompare(
        {
          what: ReportingContextType.analyticsWithoutResolution,
          type: AnalyticsType.readmissionRate,
          query: {...props.dateRange},
          facilities: props.facilities,
        },
        withDefaultCompare(props.dateRange, props.compareDateRange),
      )}
    >
      {d => (
        <Aggregation
          subject="Residents"
          attribute="Readmission Rate"
          values={getAggregationValues(d, AggregationType.WEIGHTED_AVG).map(
            x => x * 100,
          )}
          formatOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          units="%"
          implicitCompare={!props.compareDateRange}
          barGraph
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
