import {useState} from "react";
import {useQuery} from "react-query";

import {AppService, getAppVersion} from "../services/AppService";

export const useAppVersionQuery = () => {
  const [currentVersion] = useState(getAppVersion(document));

  return useQuery(
    "hasUpdateAvailable",
    async () => {
      const {
        data: {appVersion},
      } = await AppService.getAvailableAppVersion();

      return {
        currentVersion,
        latestVersion: appVersion,
        updateAvailable: appVersion ? currentVersion !== appVersion : false,
      };
    },
    {
      refetchInterval: 60000,
      placeholderData: {
        currentVersion,
        latestVersion: currentVersion,
        updateAvailable: false,
      },
    },
  );
};
