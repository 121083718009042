import React, {memo} from "react";
import {styled, Text, ProgressBar as BaseProgressBar} from "@reside/ui";

type Props = Readonly<{
  /**
   * Percentage of completion in range <0, 100>
   */
  progress: number;
  testId?: string;
}>;

export const ProgressBar = memo(({progress, testId}: Props) => (
  <Wrapper>
    <BaseProgressBar progress={progress} testId={testId} />
    <Text color="gray100" fontSize={1} fontWeight="normal">
      {progress === 0 ? "Not Started" : progress + "%"}
    </Text>
  </Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  margin-top: 5px;

  span {
    margin-left: 4px;
  }
`;
