var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import { styled } from "../theme";
export var Tag = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 4px 12px 3px 12px;\n  min-width: 90px;\n  border-radius: 10px;\n  font-size: 12px;\n  font-weight: 500;\n  color: ", ";\n  text-align: center;\n  box-sizing: border-box;\n  background-color: ", ";\n  border: 1px solid transparent;\n\n  ", "\n"], ["\n  display: inline-block;\n  padding: 4px 12px 3px 12px;\n  min-width: 90px;\n  border-radius: 10px;\n  font-size: 12px;\n  font-weight: 500;\n  color: ", ";\n  text-align: center;\n  box-sizing: border-box;\n  background-color: ", ";\n  border: 1px solid transparent;\n\n  ", "\n"])), function (_a) {
    var fontColor = _a.fontColor;
    return fontColor;
}, function (_a) {
    var theme = _a.theme, color = _a.color;
    return theme.color[color];
}, function (_a) {
    var color = _a.color, outline = _a.outline, theme = _a.theme;
    return outline && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: 1px solid ", ";\n      background-color: transparent;\n      color: ", ";\n    "], ["\n      border: 1px solid ", ";\n      background-color: transparent;\n      color: ", ";\n    "])), theme.color[color], theme.color[color]);
});
Tag.defaultProps = {
    color: "gray100",
    outline: false,
    fontColor: "white",
};
var templateObject_1, templateObject_2;
