import {useQuery} from "react-query";
import {UserDto} from "@reside/reside-api-app";
import Alert from "react-s-alert";

import {userApi} from "../services/UserService";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {isUnauthorized} from "../services/api";
import {dispatch} from "../store";

export const useRefreshUserProfile = ({
  userId,
  setUser,
}: {
  userId: string;
  setUser: (user: UserDto) => void;
}) =>
  useQuery(
    ["refreshUserProfile", userId],
    async ({queryKey: [_, userId]}) => {
      const {data} = await userApi.getUserUsingGET(
        userId,
        getAuthorizationHeader(),
      );
      return data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: false, // this query is used programmatically by the `refetch`
      onSuccess: data => setUser(data),
      onError: (error: any) => {
        if (!isUnauthorized(error.status)) {
          Alert.error("Failed to refresh user profile.");
        } else {
          dispatch.adminSession.logout();
        }
      },
    },
  );
