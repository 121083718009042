import {ReactNode} from "react";
import {connect} from "react-redux";
import {PersistGate as ReduxPersistGate} from "redux-persist/integration/react";
import {UserDto} from "@reside/reside-api-app";

import {Dispatch, persistor, select} from "./store";
import {history} from "../utils/history";
import {Spinner} from "../atoms/spinner";
import {useRefreshUserProfile} from "../hooks/useRefreshUserProfileQuery";

type DispatchProps = Readonly<{
  setUser: ({
    user,
    isUserLogin,
  }: {
    user: UserDto;
    isUserLogin: boolean;
  }) => Promise<void>;
}>;

/**
 * We use the gate to refresh user profile, to detect if the user session is still active.
 * This is done in a bit hacky way - initially the PersistGate connects to empty store,
 * but after it rehydrates the store, we get the user, and if there is one, we do an api call
 * to check if the session is still valid.
 * If there is no user, or we enter non-admin part of the app, we skip the api call.
 * We leverage the `onBeforeLift` which causes the loading spinner to be rendered
 * while the promise returned to the callback is pending.
 */
const Renderer = ({
  user,
  setUser,
  children,
}: {user?: UserDto; children: ReactNode} & DispatchProps) => {
  const isAdminApp = history.location.pathname.startsWith("/admin");

  const {refetch} = useRefreshUserProfile({
    userId: user?.id,
    setUser: user => setUser({user, isUserLogin: false}),
  });

  return (
    <ReduxPersistGate
      persistor={persistor}
      loading={<Spinner />}
      onBeforeLift={() => (isAdminApp && user ? refetch() : undefined)}
    >
      {children}
    </ReduxPersistGate>
  );
};

const mapState = (state: any) => ({
  user: select.adminSession.user(state),
});

const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setUser: dispatch.adminSession.setUser,
});

export const PersistGate = connect(mapState, mapDispatch)(Renderer);
