var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { styled } from "../../theme";
import { TextField, isValueFilled } from "../text-field";
export var PasswordField = function (props) {
    var _a = useState("password"), type = _a[0], setType = _a[1];
    return (React.createElement(TextField, __assign({}, props, { type: type, renderAfter: function (field) {
            return isValueFilled(field.value) && (React.createElement(VisibilityToggle, { onClick: function () { return setType(type === "password" ? "text" : "password"); } }, type === "password" ? "Show" : "Hide"));
        } })));
};
var VisibilityToggle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: end;\n  margin-inline-end: 0.625rem;\n  padding-block: 0.875rem;\n  width: 3.875rem;\n  font-size: 0.8125rem;\n  font-weight: 600;\n  color: ", ";\n  cursor: pointer;\n  text-transform: uppercase;\n  user-select: none;\n"], ["\n  display: flex;\n  justify-content: end;\n  margin-inline-end: 0.625rem;\n  padding-block: 0.875rem;\n  width: 3.875rem;\n  font-size: 0.8125rem;\n  font-weight: 600;\n  color: ", ";\n  cursor: pointer;\n  text-transform: uppercase;\n  user-select: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue40;
});
var templateObject_1;
