import {useMutation} from "react-query";
import {CreateResidentDto} from "../types";
import {ResidentService} from "../../../services/ResidentServices";
import Alert from "react-s-alert";
import {useHistory} from "react-router";
import {logErrorToSentry} from "../../../utils/sentry";

export const useEditResident = (resident_uuid: string) => {
  const history = useHistory();

  return useMutation(
    async (body: CreateResidentDto) => {
      return await ResidentService.editResident(body, resident_uuid);
    },
    {
      onSuccess: () => {
        Alert.success("Resident successfully updated!");
        history.push(`/admin/residents/${resident_uuid}`);
      },
      onError: (err: any) => {
        logErrorToSentry(err);

        Alert.error(
          err?.data?.detail ? err?.data?.detail : "Something went wrong",
        );
      },
    },
  );
};
