import React from "react";

import {DropdownMenuItem} from "@reside/ui";

import {getResidentTestId} from "../../helper";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";
import {getPdfUrl, withAccessToken} from "../../../../utils/url";

export const DownloadPDFDropdownItem = ({
  admission,
  ...props
}: {
  admission: AdmissionFromSearch;
}) => (
  <DropdownMenuItem
    pointer
    onClick={() => {
      openFinalPDF(admission);
    }}
    testId={getResidentTestId(admission, "downloadPdf")}
    {...props}
  >
    Download PDF
  </DropdownMenuItem>
);

const openFinalPDF = (admission: AdmissionFromSearch) =>
  window.open(
    admission.resources?.finalPdf?.downloadUrl
      ? withAccessToken(admission.resources.finalPdf.downloadUrl)
      : getPdfUrl(admission.id),
    "_blank",
  );
