import React from "react";
import {styled, TaskCheckboxChildren} from "@reside/ui";
import {ChecklistItem} from "@reside/reside-api-admission";

import {IconButton} from "../../../icon-button";

export const PccDocumentUploadTaskCheckbox = ({
  checklistItem,
  Checkbox,
  Label,
  FormattedDate,
  onUpload,
}: TaskCheckboxChildren & {
  checklistItem: ChecklistItem;
  onUpload: (checklistItem: ChecklistItem) => void;
}) => {
  /**
   * Override default checkbox check, so we open document upload modal
   * right away when checking is attempted.
   */
  const checkboxProps = checklistItem.documentUploadRequired
    ? {
        onChange: () => onUpload(checklistItem),
      }
    : {};

  return (
    <>
      <RowWrapper>
        <CheckboxWithLabelWrapper>
          <Checkbox {...checkboxProps} />
          <Label />
        </CheckboxWithLabelWrapper>
        <FormattedDate />
        {checklistItem.pccDocumentUploaded ? (
          <StyledIconButton
            frameless
            iconName="file-pdf"
            color="mint100"
            onClick={() => onUpload(checklistItem)}
          />
        ) : checklistItem.documentUploadEnabled ? (
          <StyledIconButton
            frameless
            iconName="upload-cloud"
            onClick={() => onUpload(checklistItem)}
          />
        ) : null}
      </RowWrapper>
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  && {
    overflow: visible;
  }

  i {
    margin-right: 0;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckboxWithLabelWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;
