import {createModel} from "@rematch/core";
import update from "immutability-helper";

const DEFAULT_STATE = {
  sectionId: null,
  showIncompleteSlidesOnly: false,
};

export const model = createModel({
  name: "adminAdmissionExperienceUi",

  state: {},

  selectors: (slice, createSelector, hasProps) => ({
    get: hasProps((state, {admissionId}) => {
      return slice(state => state[admissionId]);
    }),
  }),

  reducers: {
    "admissions/pushObject": (state, {template, instance}) => {
      const sectionId = template.children[0].children[0].id;
      const nextState = {
        ...DEFAULT_STATE,
        sectionId,
      };

      return update(state, {[instance.id]: {$set: nextState}});
    },

    "admissions/pushObjectForPrelude": (state, payload) => {
      return update(state, {
        [payload.instance.id]: {
          $set: {
            ...DEFAULT_STATE,
          },
        },
      });
    },

    goToSection(state, payload) {
      return update(state, {
        [payload.id]: {sectionId: {$set: payload.slideId}},
      });
    },

    toggleShowIncompleteSlidesOnlyFlag(state, payload) {
      return update(state, {
        [payload.id]: {showIncompleteSlidesOnly: slice => !slice},
      });
    },
  },
});

export function bindUiActionsForInstance(admissionId) {
  return dispatch => ({
    goToSection: slideId =>
      dispatch.adminAdmissionExperienceUi.goToSection({
        id: admissionId,
        slideId,
      }),
    toggleShowIncompleteSlidesOnlyFlag: () =>
      dispatch.adminAdmissionExperienceUi.toggleShowIncompleteSlidesOnlyFlag({
        id: admissionId,
      }),
  });
}
