import React from "react";
import {connect} from "react-redux";
import {select, AppState} from "../../store";
import {AdminLayout} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {UserDetail} from "../../store/users/usersModel";
import {Facilities} from "../../models/types/AdmissionForm";
import {PageBody} from "./PageAdminFacilitySettingsBody";

type Props = Readonly<{
  user: UserDetail;
  facilities: Facilities;
}>;

export const PageAdminFacilitySettingsRenderer = ({
  user,
  facilities,
}: Props) => {
  return (
    <>
      <DocumentTitle title="Reside - Admin - Facility Settings" />
      <AdminLayout>
        <PageBody user={user} facilities={facilities} />
      </AdminLayout>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: select.adminSession.user(state),
  facilities: select.adminSession.facilities(state),
});

export const PageAdminFacilitySettings = connect(mapStateToProps)(
  PageAdminFacilitySettingsRenderer,
);
