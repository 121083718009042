var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from "react";
import { Menu } from "@headlessui/react";
import { css } from "@emotion/react";
import { styled } from "../theme";
export var DropdownMenuItem = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Menu.Item, __assign({ as: Fragment }, props), function (_a) {
        var active = _a.active, disabled = _a.disabled;
        return (React.createElement(DropdownMenuItemBase, { active: active, disabled: disabled }, children));
    }));
};
export var DropdownMenuItemBase = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 15px;\n  color: ", ";\n  font-size: 15px;\n  font-weight: ", ";\n  position: relative;\n  cursor: ", ";\n  ", "\n\n  ", "\n"], ["\n  padding: 15px;\n  color: ", ";\n  font-size: 15px;\n  font-weight: ", ";\n  position: relative;\n  cursor: ", ";\n  ", "\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled
        ? "opacity: 0.5;"
        : "\n  &:hover {\n    background-color: ".concat(theme.color.lightBlue10, ";\n  }\n  ");
}, function (_a) {
    var active = _a.active, theme = _a.theme;
    return active && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), theme.color.lightBlue10);
});
var templateObject_1, templateObject_2;
