import React, {memo} from "react";

import {PieChart} from "../../../atoms/admin-reporting/atoms/pie-chart";
import {RequiredUserPermission} from "../../../atoms/admin-user-permissions/RequiredUserPermission";
import {TableUserDto} from "@reside/reside-api-app";
import {ReportingContext} from "../ReportingContext";
import {
  AnalyticsType,
  ReportingContextType,
} from "../../../store/reporting/reporting.common";
import {
  ChartProps,
  Props,
  useOnDataPathChange,
  dataProcess_AnalyticsFlagsBySlidePie,
  withCompare,
} from "./helpers";
import {CompareDateLabel} from "../../../atoms/reporting-compare";
import {CardTitle} from "../../../atoms/card-title";
import {CompareDateLabelsWrapper} from "../reports/Report.common";

const AnalyticsFlagsBySlidePieChart = memo(({data}: ChartProps) => {
  const pieData = dataProcess_AnalyticsFlagsBySlidePie(data);

  return (
    <PieChart
      data={pieData}
      xFormat={(d: any, dataset: any) =>
        `[${
          dataset.additional_data.positions
            ? dataset.additional_data.positions[d.x]
            : ""
        }] ${d.x} (${d.y})`
      }
      additionalComponent={(dataset: any) => (
        <div className="additional-data__container">
          <h3 className="additional-data__header">Slides with no flags</h3>
          <ul className="additional-data">
            {dataset.additional_data?.slidesWithoutFlags.map(
              (item: any, index: number) => {
                return (
                  <li
                    key={`slide-without-flag-${index}`} // skipcq: JS-0437
                  >{`[${dataset.additional_data.positions[item]}] ${item}`}</li>
                );
              },
            )}
          </ul>
        </div>
      )}
    />
  );
});

export const FLAGS_BY_SLIDE_CHART_ID = "flags-by-slide" as const;

export const AnalyticsFlagsBySlidePie = (props: Props) => (
  <RequiredUserPermission
    permission={TableUserDto.PermissionsEnum.MOST_FREQUENTLY_FLAGGED_SLIDES}
  >
    <div className="chart-header">
      <CardTitle
        title={"Most frequently flagged slides “help flags”"}
        subtitle="by Facility"
      />
      <CompareDateLabelsWrapper>
        <CompareDateLabel dateRange={props.dateRange} global border />
      </CompareDateLabelsWrapper>
    </div>
    <ReportingContext
      dataPaths={withCompare({
        what: ReportingContextType.analyticsWithoutResolution,
        type: AnalyticsType.flagsBySlide,
        query: {...props.dateRange},
        facilities: props.facilities,
      })}
      onDataPathChange={useOnDataPathChange(props.reportingBlockSetters)}
      isFullReport={props.reportingBlockSetters.isFullReport}
      enabled={props.reportingBlockSetters.enabled}
    >
      {(data, isFullReport) => (
        <AnalyticsFlagsBySlidePieChart
          data={data}
          isFullReport={isFullReport}
        />
      )}
    </ReportingContext>
  </RequiredUserPermission>
);
