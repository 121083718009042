import React from "react";
import {DateFieldInput} from "@reside/ui";
import {TimezoneSignatureField} from "../../../timezone-signature-field";
import {ApprovalSignatureProps} from "./utils/types";

export const ApprovalSignature = ({
  timeZone,
  name,
  dateFieldName,
}: ApprovalSignatureProps) => (
  <>
    <TimezoneSignatureField
      label="Signature"
      name={name}
      timeZone={timeZone}
      signDateRef={dateFieldName}
    />
    <DateFieldInput disabled name={dateFieldName} label="Date" />
  </>
);

ApprovalSignature.defaultProps = {
  name: "signature",
  dateFieldName: "created",
};
