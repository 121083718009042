import React, {ReactNode} from "react";
import {
  ButtonProps,
  Tooltip,
  DropdownMenu,
  DropdownMenuIconButton,
} from "@reside/ui";

import {ButtonGroup} from "../button-group";

type Props = Readonly<
  {
    offset?: number;
    defaultOptions: JSX.Element[] | JSX.Element;
    children: ReactNode;
  } & ButtonProps
>;

export const SplitButton = ({defaultOptions, ...props}: Props) => (
  <ButtonGroup>
    {defaultOptions}
    <DropdownMenu
      trigger={open => (
        <Tooltip content="More Options">
          <DropdownMenuIconButton {...props} open={open} />
        </Tooltip>
      )}
    >
      {props.children}
    </DropdownMenu>
  </ButtonGroup>
);
