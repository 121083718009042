var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { css } from "@emotion/react";
import { styled } from "../../../theme";
import { Icon } from "../../../icon";
import { FlexRow } from "../../../box";
import { LockIcon } from "../LockIcon";
export var PLACEHOLDER_OPTION_VALUE = "!_PLACEHOLDER_DISABLED_OPTION_VALUE_!";
export var Select = function (props) { return (React.createElement(Wrapper, null,
    props.disabled ? React.createElement(SelectLockIcon, null) : React.createElement(StyledIcon, { name: "down" }),
    React.createElement(StyledSelect, __assign({ isPlaceholder: props.value === PLACEHOLDER_OPTION_VALUE, disabled: props.disabled, "data-test-id": props.name }, props)))); };
var Wrapper = styled(FlexRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  box-sizing: border-box;\n  cursor: default;\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  flex-wrap: wrap;\n  align-items: center;\n  min-height: 35px;\n  text-overflow: ellipsis;\n"], ["\n  width: 100%;\n  box-sizing: border-box;\n  cursor: default;\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  flex-wrap: wrap;\n  align-items: center;\n  min-height: 35px;\n  text-overflow: ellipsis;\n"])));
var StyledSelect = styled.select(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  appearance: none;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: flex;\n  width: inherit;\n  outline: none;\n  border: 0;\n  background: transparent;\n\n  font-size: 1.375em;\n  font-family: Gilroy, sans-serif;\n  padding: 0.75em 4px;\n  color: ", ";\n  opacity: ", ";\n  ", "\n"], ["\n  appearance: none;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: flex;\n  width: inherit;\n  outline: none;\n  border: 0;\n  background: transparent;\n\n  font-size: 1.375em;\n  font-family: Gilroy, sans-serif;\n  padding: 0.75em 4px;\n  color: ", ";\n  opacity: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
}, function (_a) {
    var isPlaceholder = _a.isPlaceholder;
    return (isPlaceholder ? "0.75" : "1");
}, function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      opacity: 0.65;\n      padding-right: 5em;\n      cursor: not-allowed;\n      filter: saturate(25%);\n    "], ["\n      opacity: 0.65;\n      padding-right: 5em;\n      cursor: not-allowed;\n      filter: saturate(25%);\n    "])));
});
var SelectLockIcon = styled(LockIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n"], ["\n  position: absolute;\n  right: 0;\n"])));
var StyledIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 4px;\n  margin-left: 4px;\n  position: absolute;\n  right: 0;\n  font-size: 0.75em;\n  color: ", ";\n  pointer-events: none;\n"], ["\n  margin-right: 4px;\n  margin-left: 4px;\n  position: absolute;\n  right: 0;\n  font-size: 0.75em;\n  color: ", ";\n  pointer-events: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
