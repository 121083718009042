import React from "react";
import {connect} from "react-redux";
import {styled} from "@reside/ui";
import {select} from "../../store";
import {bindAdmissionActionsForInstance} from "../../models/AdmissionsModel";
import {Spinner} from "../../atoms/spinner";
import {AdminLayout} from "../../atoms/admin-layout";
import {AdminAdmissionExplorer} from "../../atoms/admin-admission-explorer";
import {bindUiActionsForInstance} from "../../models/AdminAdmissionExperienceUiModel";
import {createPreflightUrl} from "../page-admin-preflight/route";

class Renderer extends React.Component {
  componentDidMount() {
    this.props.draftActions.findAsync();
  }

  onEdit = () => {
    this.props.history.push(
      createPreflightUrl({
        facilityId: this.props.draft.instance.facilityId,
        admissionId: this.props.draft.instance.id,
      }),
    );
  };

  onClose = () => {
    this.props.history.push("/admin/dashboard");
  };

  render() {
    const explorerProps = {
      draft: this.props.draft,
      uiState: this.props.uiState,
      uiActions: this.props.uiActions,
      draftActions: this.props.draftActions,
      notes: this.props.notes,
      user: this.props.user,
      onEdit: this.onEdit,
      onClose: this.onClose,
      completionChecklist: this.props.completionChecklist,
    };

    return (
      <StyledAdminLayout>
        {!this.props.admissionIsLoaded && <Spinner />}
        {this.props.admissionIsLoaded && (
          <AdminAdmissionExplorer {...explorerProps} />
        )}
      </StyledAdminLayout>
    );
  }
}

const StyledAdminLayout = styled(AdminLayout)`
  max-width: unset;
  padding: 0 0 25px;

  @media (max-width: 1170px) {
    padding: 0 0 25px;
  }

  @media (max-width: 768px) {
    padding: 0 0 20px;
  }

  @media (max-width: 460px) {
    padding: 0 0 14px;
  }
`;

const mapState = (state, props) =>
  select(models => ({
    user: models.adminSession.user,
    admissionIsLoaded: models.admissions.fetchStatus({
      admissionId: selectAdmissionId(props),
    }),
    completionChecklist: models.admissions.completionChecklist({
      admissionId: selectAdmissionId(props),
    }),
    draft: models.admissions.experience({
      admissionId: selectAdmissionId(props),
      readOnly: true,
    }),
    notes: models.admissions.notes({admissionId: selectAdmissionId(props)}),
    uiState: models.adminAdmissionExperienceUi.get({
      admissionId: selectAdmissionId(props),
    }),
    loading: () => !!state.loading.effects.admissions.findAsync,
  }));

const mapDispatch = (dispatch, props) => {
  const admissionId = props.match.params.admissionId;

  return {
    draftActions: bindAdmissionActionsForInstance(admissionId)(dispatch),
    uiActions: bindUiActionsForInstance(admissionId)(dispatch),
  };
};

export const PageAdminAdmissionExperience = connect(
  mapState,
  mapDispatch,
)(Renderer);

function selectAdmissionId(props) {
  return props.match.params.admissionId;
}
