import {Card, styled} from "@reside/ui";
import {AdminLayout} from "../../atoms/admin-layout";

export const FormWrapper = styled.div`
  width: 100%;
  margin: 0 ${({theme}) => theme.space[2]}px;
  position: relative;
`;
export const TemplateFieldRow = styled.div`
  display: flex;
  width: 100%;
  align-items: bottom;
  margin: ${({theme}) => theme.space[2]}px 0;

  label {
    width: 100%;
  }
`;
export const TemplateFieldCard = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.color.gray5}`};
  margin: ${({theme}) => theme.space[2]}px 0;
  padding: ${({theme}) => theme.space[2]}px;
`;

export const StyledCard = styled(Card)`
  padding-top: ${({theme}) => theme.space[6]}px;
  margin: ${({theme}) => theme.space[4]}px 0;
`;

export const PageWrapper = styled(Card)`
  padding: ${({theme}) => theme.space[4]}px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: ${({theme}) => theme.space[4]}px;
`;

export const Layout = styled(AdminLayout)`
  padding-top: 80px;
`;
