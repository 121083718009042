import removeMarkdown from "remove-markdown";
var formatConfigs = {
    "us-phone": {
        pattern: /^(1)?([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,4})?/,
        delimiter: "-",
        groupPresence: [{ optional: true }],
    },
    ssn: {
        pattern: /^([0-9]{1,3})?([0-9]{1,2})?([0-9]{1,4})?/,
        delimiter: "-",
        groupPresence: [],
    },
    npi: {
        pattern: /^([0-9]{1,10})?/,
        delimiter: "",
        groupPresence: [],
    },
    "zip-code": {
        pattern: /^([0-9]{1,5})?([0-9]{1,4})?/,
        delimiter: "-",
        groupPresence: [],
    },
    date: {
        pattern: /^([0-9]{1,2})?([0-9]{1,2})?([0-9]{1,4})?/,
        delimiter: "/",
        groupPresence: [],
    },
    integer: {
        pattern: /^([0-9]?|[1-9]+[0-9]*)$/,
        delimiter: "",
        groupPresence: [],
    },
};
export function formatValue(value, format) {
    var _a = formatConfigs[format], pattern = _a.pattern, delimiter = _a.delimiter, groupPresence = _a.groupPresence;
    var match = value.replace(RegExp(delimiter, "g"), "").match(pattern);
    if (match === null) {
        if (format === "integer") {
            return value.replace(/\D/g, "");
        }
        return false;
    }
    var partials = match.slice(1);
    // Filter matched groups
    var take = true;
    return (partials
        .filter(function (match, idx) {
        // Keep optional groups so that we can get to obligatory groups
        var optional = (groupPresence[idx] || {}).optional;
        if (optional) {
            return true;
        }
        take = take ? match !== undefined : false;
        return take;
    })
        // Filter out empty groups
        .filter(function (match) { return match !== undefined; })
        // As we have all groups we are interested in, we are free to join them with delimiter
        .join(delimiter));
}
export function autoCapitalizeText(value, type, capitalize) {
    if (type === "text" && value.length === 1 && capitalize) {
        return value[0].toUpperCase();
    }
    return value;
}
export function removeMarkdownKeepTrailingSpace(value) {
    var removeMd = removeMarkdown(value, { stripListLeaders: false });
    return value.endsWith(" ") ? removeMd + " " : removeMd;
}
export function addSuffixToValue(value, suffix) {
    return value.split(".").slice(0, -1).join(".") + suffix;
}
