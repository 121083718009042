import React from "react";
import Axios, {CancelTokenSource} from "axios";
import {Dispatch} from "redux";
import {AppState} from "../../store/store";

export type RPFU = (
  cancelTokenSource: CancelTokenSource,
) => (dispatch: Dispatch, getState: () => AppState) => Promise<void>;

export const useFetchPermissions: (
  requestPermissionsForUser: RPFU,
) => React.MutableRefObject<CancelTokenSource> = requestPermissionsForUser => {
  const cancelTokenRef = React.useRef(Axios.CancelToken.source());

  React.useEffect(() => {
    cancelTokenRef.current = Axios.CancelToken.source();
    requestPermissionsForUser(cancelTokenRef.current);

    return () => {
      cancelTokenRef.current.cancel();
    };
  }, [requestPermissionsForUser]);

  return cancelTokenRef;
};
