import React, {FunctionComponent} from "react";
import {styled, H1, Card} from "@reside/ui";

type Props = Readonly<{
  title: string;
}>;

export const Banner: FunctionComponent<Props> = ({title, children}) => (
  <CenteredCard>
    <H1>{title}</H1>
    {children}
  </CenteredCard>
);

const CenteredCard = styled(Card)`
  width: 500px;
  padding: 50px;
  margin: 100px auto;
  text-align: center;
`;
