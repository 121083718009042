import React from "react";
import {Link} from "react-router-dom";
import {styled, Text} from "@reside/ui";
import {Admission} from "@reside/reside-api-admission";

import {
  Left,
  Right,
  CellFooter,
  CellHeader,
  CellTextBig,
  DashboardCell,
  AdmissionId,
} from "../../../../dashboard-cell";
import {readDateOfAdmissionAnswer} from "../../../../../models/AdmissionModel.helpers";
import {AdmissionFromSearch} from "../../../../../services/AdmissionsService";
import {RelativeDate} from "../../../../relative-date";
import {DaysOfAdmissionTag} from "../../days-of-admission-tag";
import {PdfUploadStatusIcon} from "../../pdf-upload-status-icon";
import {getAdmissionResidentName, getResidentTestId} from "../../../helper";
import {SignedSplitButton} from "./SignedSplitButton";
import {AdmissionChecklist} from "../admission-checklist";

type Props = Readonly<{
  admission: AdmissionFromSearch;
}>;

export const AdmissionSignedCell = ({admission}: Props) => (
  <DashboardCell>
    <CellHeader>
      <RelativeDate date={admission.updated} />
      <SignedSplitButton admission={admission} />
    </CellHeader>
    <CellTextBig data-test-id={getResidentTestId(admission, "editProfile")}>
      <Link to={`/admin/admissions/${admission.id}`}>
        <Text>{getAdmissionResidentName(admission)}</Text>
      </Link>
    </CellTextBig>
    <AdmissionId code={admission.code} />
    <AdmissionChecklist admission={admission} />
    <CellFooter>
      <Left>
        {admission.status !== Admission.StatusEnum.APPROVED &&
          readDateOfAdmissionAnswer(admission as any) && (
            <DaysOfAdmissionTag
              date={readDateOfAdmissionAnswer(admission as any)}
            />
          )}
      </Left>
      <Right>
        {admission.resources?.finalPdf?.pccUploadStatus && (
          <PdfIconContainer>
            <PdfUploadStatusIcon
              pdfUploadStatus={
                admission.resources.finalPdf.pccUploadStatus as any
              }
            />
          </PdfIconContainer>
        )}
      </Right>
    </CellFooter>
  </DashboardCell>
);

const PdfIconContainer = styled.div`
  margin-left: auto;
`;
