import {useCallback} from "react";
import {connect} from "react-redux";
import {RouteComponentProps, useHistory} from "react-router-dom";

import {AppState, select} from "../../store";
import {Button, FlexRow} from "@reside/ui";

import {Spinner} from "../../atoms/spinner";
import {CardTitle} from "../../atoms/card-title";
import {AdminLayout} from "../../atoms/admin-layout";
import {DocumentUpload} from "./atoms/DocumentUpload";
import {DocumentTitle} from "../../atoms/document-title";
import {AdminPageTitle} from "../../atoms/admin-layout/AdminPageTitle";
import {AdminOrganizationImportExportUsersTable} from "./atoms/AdminOrganizationImportExportUsersTable";
import {useGetOrganizationData} from "./hooks/useGetOrganizationData";
import {BackButton} from "./styles";
import {useQueryClient} from "react-query";

/**
 * Page for exporting a csv with the current list of all users that belong to a specific organization. Same csv file can be updated and uploaded again as well as any other csv file. User is able to download the template and example of user data csv file.
 */
export const Renderer = ({
  match,
}: RouteComponentProps<{organizationId: string}>) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {organizationId} = match.params;

  const {data: organization, isLoading} = useGetOrganizationData({
    organizationId,
  });

  const goBack = useCallback(() => {
    history.push(`/admin/organizations/${organization.id}/edit`);
  }, [history, organization?.id]);

  const reload = () => queryClient.invalidateQueries(["useGetTableData"]);
  if (isLoading && !organization) {
    return <Spinner />;
  }

  return (
    <>
      <DocumentTitle title="Reside - Admin - User Import" />
      <AdminLayout>
        <FlexRow>
          <AdminPageTitle>{organization.name}</AdminPageTitle>
          <BackButton onClick={goBack}>Back</BackButton>
        </FlexRow>
        <DocumentUpload organizationId={organization.id} />

        <FlexRow style={{margin: "45px 0"}}>
          <CardTitle title="Past Uploads" />
          <Button onClick={reload}>Reload</Button>
        </FlexRow>
        <AdminOrganizationImportExportUsersTable
          organizationId={organization.id}
        />
      </AdminLayout>
    </>
  );
};

/**
 * @param state
 */
const mapState = (state: AppState) => ({
  isResideAdmin: select.adminSession.isResideAdmin(state),
  user: select.adminSession.user(state),
});

export const PageAdminOrganizationImportExportUsers =
  connect(mapState)(Renderer);
