import {SelectField, Card, Subtitle, Button, CheckboxItem} from "@reside/ui";
import {PatchFacilityDto} from "@reside/reside-api-app";
import {validator} from "@reside/forms";
import Alert from "react-s-alert";
import {Formik} from "formik";

import {useEditFacilityModalContext} from "../../../../../atoms/edit-facility-confirmation-modal";
import {useTemplateNamesQuery} from "../../../../../services/hooks/useTemplateNamesQuery";
import {buildResponseErrorMessage} from "../../../../../services/api/errorHelpers";
import {useFacilitySettingsParams} from "../../../hooks/useFacilitySettingsParams";
import {BackdropSpinner} from "../../../../../atoms/spinner";
import {Space} from "../../../../../atoms/space";
import {DownloadButton} from "./DownloadButton";
import {FlexRowStyled} from "../styles";

export const EditFacilityForm = ({templateName}: {templateName: string}) => {
  const {facilityId} = useFacilitySettingsParams();
  const {toggle} = useEditFacilityModalContext();

  const {data: templateNames = [], isFetching: isFetchingTemplateNames} =
    useTemplateNamesQuery({
      onError: async (response: any) => {
        Alert.error(
          await buildResponseErrorMessage(
            response,
            "Unable to load template names",
          ),
        );
      },
    });

  return (
    <BackdropSpinner active={isFetchingTemplateNames}>
      <Formik<PatchFacilityDto>
        initialValues={{templateName, migrateAdmissions: true}}
        validate={values =>
          validator.validateAll(values, {
            templateName: "required",
          })
        }
        onSubmit={fields => toggle(facilityId, fields)}
      >
        {({values, setFieldValue, submitForm}) => (
          <Card>
            <Subtitle>Facility Information</Subtitle>
            <SelectField
              label="Facility Template"
              name="templateName"
              options={templateNames}
              getOptionLabel={value => value}
              getOptionValue={value => value}
            />
            <CheckboxItem
              name="migrateAdmissions"
              onChange={() =>
                setFieldValue("migrateAdmissions", !values.migrateAdmissions)
              }
              checked={values.migrateAdmissions}
              label="Migrate preflight admissions to the new template?"
            />
            <FlexRowStyled>
              <Space>
                <Button
                  color="success"
                  onClick={submitForm}
                  disabled={values.templateName === templateName}
                >
                  Save
                </Button>
                <DownloadButton
                  facilityTemplate={values.templateName}
                  label="Download Template"
                />
              </Space>
            </FlexRowStyled>
          </Card>
        )}
      </Formik>
    </BackdropSpinner>
  );
};
