import {isEqual} from "lodash";
import {Admission, ChecklistItem} from "@reside/reside-api-admission";

import {AdmissionFromSearch} from "../../services/AdmissionsService";
import {
  readResidentFirstNameAnswer,
  readResidentLastNameAnswer,
} from "../../models/AdmissionModel.helpers";
import {ChecklistValues} from "./atoms/checklist";

export const getAdmissionResidentName = (
  {answers}: Pick<Admission | AdmissionFromSearch, "answers">,
  separator = " ",
) =>
  [answers?.resident_firstName?.value, answers?.resident_lastName?.value].join(
    separator,
  );

export const getResidentTestId = (
  admission: Pick<Admission | AdmissionFromSearch, "answers">,
  elementId: string,
) =>
  `${readResidentFirstNameAnswer(admission as any)}${readResidentLastNameAnswer(
    admission as any,
  )}-${elementId}`;

export const getChecklistFormValues = (
  checklist: ReadonlyArray<ChecklistItem>,
) =>
  checklist.reduce<Record<string, ChecklistItem>>(
    (values, item) => ({
      ...values,
      [item.id]: item,
    }),
    {},
  );

export const getUpdatesAdmissionChecklist = (
  checklist: ReadonlyArray<ChecklistItem>,
  values: ChecklistValues,
) => {
  const currentValues = getChecklistFormValues(checklist);

  return Object.entries(currentValues)
    .filter(([id, item]) => !isEqual(item, values[id]))
    .map(([id]) => [id, values[id]] as [string, ChecklistItem]);
};
