import {PccIntegrationStatus} from "../services/PccService";

import {extractPccIntegrationStatusOfFacility} from "../utils/helpers/extractPccIntegrationStatusOfFacility";
import {Facilities} from "../models/types/AdmissionForm";

export const usePointClickCareStatus = (
  facilities: Facilities,
  facilityId: string,
) => {
  const pccStatus = extractPccIntegrationStatusOfFacility(
    facilities,
    facilityId,
  );

  return {
    isPccEnabled:
      pccStatus === PccIntegrationStatus.PccTurnedOn
        ? true
        : pccStatus === PccIntegrationStatus.PccTurnedOff
        ? false
        : undefined,
  };
};
