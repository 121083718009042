import {PccFacilityMappingDto} from "@reside/reside-api-app";

/**
 * Initial values for the form when PCC is disabled.
 *
 * @constant
 * @type {Object}
 * @default
 */
export const INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY: Omit<
  PccFacilityMappingDto,
  "defaultDocumentCategoryIdentifier"
> = {
  pccFacilityId: "" as unknown as number,
  pccOrganizationId: "",
};
