"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.someIn = void 0;
var someIn = function (value, arr) {
    if (Array.isArray(value)) {
        return value ? value.some(function (v) { return arr.includes(v); }) : false;
    }
    return arr.includes(value);
};
exports.someIn = someIn;
