import {http} from "./api";
import {getRequestUnitTimestamps} from "../store/reporting/reporting.common";
import {Pageable} from "./api/pagination";

type ChecklistItemsParams = Readonly<{
  facilityIds: ReadonlyArray<string>;
  from: string;
  to: string;
  pageable: Pageable;
}>;

type ChecklistItemReportParams = Readonly<{
  facilityIds: ReadonlyArray<string>;
  from: number;
  to: number;
  localTimeZone: string;
  pageable: Pageable;
}>;

export const ReportingService = {
  /**
   * Get list of Admission Checklist Items used in admissions from specified facilities and from the date range.
   */
  getChecklistItems: ({from, to, ...params}: ChecklistItemsParams) =>
    http.post<ReadonlyArray<ChecklistItem>>("/reports/checklist-items", {
      ...params,
      ...getRequestUnitTimestamps(from, to),
    }),

  /**
   * Get Report of admissions with specific ChecklistItem.
   */
  getChecklistItemReport: (
    checklistItemId: string,
    params: ChecklistItemReportParams,
  ) =>
    http.post<ChecklistItemReport>(
      `/reports/checklist-items/${checklistItemId}`,
      params,
    ),
};

export type ChecklistItem = Readonly<{
  id: string;
  description: string;
}>;

export type ChecklistItemReport = Readonly<{
  admissions: ReadonlyArray<{
    admissionDate: string;
    answerIsNegative: boolean;
    answerIsPositive: boolean;
    facilityName: string;
    residentName: string;
  }>;
  negativeAnswersTotal: number;
  positiveAnswersTotal: number;
  total: number;
}>;
