var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, } from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { Select } from "../atoms/select";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { PLACEHOLDER_OPTION_VALUE } from "../atoms/select/Select";
import { Label } from "../atoms/Label";
import { isInvalid } from "../formHelpers";
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export var SelectField = function (_a) {
    var name = _a.name, label = _a.label, options = _a.options, placeholder = _a.placeholder, getOptionLabel = _a.getOptionLabel, getOptionValue = _a.getOptionValue, onChange = _a.onChange, FormField = _a.FormField, inputProps = __rest(_a, ["name", "label", "options", "placeholder", "getOptionLabel", "getOptionValue", "onChange", "FormField"]);
    var _b = useState(false), focused = _b[0], setFocus = _b[1];
    return (React.createElement(FormField, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        var value = field.value || PLACEHOLDER_OPTION_VALUE;
        return (React.createElement(Label, null,
            React.createElement(FloatingFieldLabel, { visible: Boolean(field.value) }, label),
            React.createElement(SelectWrapper, null,
                React.createElement(Select, __assign({}, field, inputProps, { value: value, onFocus: function () { return setFocus(true); }, onBlur: function (event) {
                        setFocus(false);
                        field.onBlur(event);
                    }, onChange: function (event) {
                        if (event.target.value !== PLACEHOLDER_OPTION_VALUE) {
                            field.onChange(field.name)(event.target.value);
                            onChange === null || onChange === void 0 ? void 0 : onChange(event);
                        }
                    } }),
                    placeholder || label ? (React.createElement("option", { value: PLACEHOLDER_OPTION_VALUE, disabled: true }, placeholder !== null && placeholder !== void 0 ? placeholder : label)) : null,
                    options.map(function (option) { return (React.createElement("option", { key: getOptionValue(option), value: getOptionValue(option) }, getOptionLabel(option))); }))),
            React.createElement(FieldBorder, { focused: focused, invalid: isInvalid(name, form) }),
            React.createElement(FieldError, { name: name })));
    }));
};
SelectField.defaultProps = {
    getOptionLabel: function (option) { return option.label; },
    getOptionValue: function (option) { return option.value; },
    FormField: Field,
};
var SelectWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var templateObject_1;
