import React from "react";

import {Banner} from "../../atoms/banner";
import {ButtonLink} from "../../atoms/button-link";

export const PageAdmin404 = () => (
  <Banner title="This page no longer exists.">
    <ButtonLink to="/admin">Back to Dashboard</ButtonLink>
  </Banner>
);
