import {AppState} from "../store";

import {
  DataPath,
  StateWithFromTo,
  StateWithResolution,
  IFromTo,
  IResolution,
  isAnalyticsDataPath,
  isWithResolution,
  StateWithAnalyticsType,
  AnalyticsTypeWithResolution,
  AnalyticsTypeWithoutResolution,
  StateWithTo,
  StateWithFrom,
  IReportingAnalyticsDataState,
  ReportingContextType,
  StateWithFacility,
  Facility,
  getFacilitiesKey,
} from "./reporting.common";

const selectWithAnalyticsType =
  <S>(type: AnalyticsTypeWithResolution | AnalyticsTypeWithoutResolution) =>
  (state: StateWithAnalyticsType<S> | undefined) =>
    state === undefined ? undefined : state[type];

const selectWithFacility =
  <S>(facilityId: Facility) =>
  (state: StateWithFacility<S> | undefined) =>
    state === undefined ? undefined : state[facilityId];

const selectWithFrom =
  <S>(q: IFromTo) =>
  (state: StateWithFrom<S> | undefined) =>
    state === undefined ? undefined : state[q.from];

const selectWithTo =
  <S>(q: IFromTo) =>
  (state: StateWithTo<S> | undefined) =>
    state === undefined ? undefined : state[q.to];

export const selectWithFromTo =
  <S>(q: IFromTo) =>
  (state: StateWithFromTo<S> | undefined): S | undefined => {
    const stateFrom: StateWithTo<S> | undefined =
      selectWithFrom<StateWithTo<S>>(q)(state);
    const stateTo: any = selectWithTo(q)(stateFrom);

    return stateTo;
  };

const selectWithResolution =
  <S>(q: IResolution) =>
  (state: StateWithResolution<S> | undefined) =>
    state === undefined ? undefined : state[q.resolution];

const selectWhat = (what: ReportingContextType) => (state: AppState) =>
  state.reporting[what];

export function createSelectors(path: DataPath) {
  return {
    byDataPath: (state: AppState) => {
      if (isAnalyticsDataPath(path)) {
        const selectWhatFn = selectWhat(path.what);

        if (isWithResolution(path)) {
          const selectTypeFn = selectWithAnalyticsType<
            StateWithFrom<
              StateWithTo<
                StateWithResolution<
                  StateWithFacility<IReportingAnalyticsDataState>
                >
              >
            >
          >(path.type);

          const selectFromToFn = selectWithFromTo<
            StateWithResolution<StateWithFacility<IReportingAnalyticsDataState>>
          >(path.query);

          const selectResolutionFn = selectWithResolution<
            StateWithFacility<IReportingAnalyticsDataState>
          >(path.query);
          const selectFacilityFn =
            selectWithFacility<IReportingAnalyticsDataState>(
              getFacilitiesKey(path.facilities),
            );

          const state1 = selectWhatFn(state) as StateWithAnalyticsType<
            StateWithFrom<
              StateWithTo<
                StateWithResolution<
                  StateWithFacility<IReportingAnalyticsDataState>
                >
              >
            >
          >;
          const state2 = selectTypeFn(state1);
          const state3 = selectFromToFn(state2);
          const state4 = selectResolutionFn(state3);
          const state5 = selectFacilityFn(state4);

          return state5;
        } else {
          const selectTypeFn = selectWithAnalyticsType<
            StateWithFrom<
              StateWithTo<StateWithFacility<IReportingAnalyticsDataState>>
            >
          >(path.type);

          const selectFromToFn = selectWithFromTo<
            StateWithFacility<IReportingAnalyticsDataState>
          >(path.query);
          const selectFacilityFn =
            selectWithFacility<IReportingAnalyticsDataState>(
              getFacilitiesKey(path.facilities),
            );

          const state1 = selectWhatFn(state) as StateWithAnalyticsType<
            StateWithFrom<
              StateWithTo<StateWithFacility<IReportingAnalyticsDataState>>
            >
          >;
          const state2 = selectTypeFn(state1);
          const state3 = selectFromToFn(state2);
          const state4 = selectFacilityFn(state3);

          return state4;
        }
      }
    },
    state: (state: any) => state,
  };
}
