import React, {ReactNode} from "react";
import {useAsync} from "react-use";
import {theme} from "@reside/ui";
import {ErrorResponseDto} from "@reside/reside-api-admission";

export const Error = ({
  message,
  ...props
}: {
  message: ReactNode;
  className?: string;
}) => (
  <div
    style={{
      padding: "25px 0px",
      color: `${theme.color.deepOrange100}`,
      lineHeight: "1.75em",
      fontSize: "17.5px",
      fontWeight: 500,
    }}
    {...props}
  >
    <p>{message}</p>
  </div>
);

export const RequestError = ({payload, ...props}: any) => {
  const message = payload.data?.message || payload.problem;

  return <Error message={message} {...props} />;
};

export const AsyncRequestError = ({
  error,
  children = error => error.message,
}: {
  error: any;
  children?: (error: ErrorResponseDto) => ReactNode;
}) => {
  const {value} = useAsync(async () => {
    const res = await error.json();
    return res;
  }, [error]);

  return value ? <Error message={children(value)} /> : null;
};
