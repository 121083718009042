import React from "react";
import {cx} from "@emotion/css";
import {useTimeout} from "react-use";
import {styled, Text} from "@reside/ui";

import scrolldownImage from "./assets/arrow-scrolldown.svg";

type Props = Readonly<{
  hidden: boolean;
  hideTimeout?: number;
}>;

export const ScrollReminder = ({hidden, hideTimeout}: Props) => {
  const [isHidden] = useTimeout(hideTimeout);

  return (
    <Container
      className={cx({
        hidden: hidden || isHidden(),
      })}
    >
      <img src={scrolldownImage} alt="Arrow up" />
      <Text fontSize="1.25em" color="green70">
        Scroll for more
      </Text>
    </Container>
  );
};

ScrollReminder.defaultProps = {
  hidden: false,
  hideTimeout: 5300,
};

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 3;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: rgba(233, 251, 246, 0.95);
  bottom: 150px;
  animation: bounce 4s 0.25s 4 alternate both;
  pointer-events: none;

  img {
    margin-bottom: 20px;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
`;
