import {ChangeEvent, useMemo, useState} from "react";
import {
  SelectField,
  TextField,
  FieldLabel,
  FormCard,
  Button,
  CheckboxItem,
  FlexRow,
  Paragraph,
  Text,
} from "@reside/ui";
import {Form, Formik} from "formik";
import {useTemplateNamesQuery} from "../../services/hooks/useTemplateNamesQuery";
import {useTemplateFieldsQuery} from "../../services/hooks/useTemplateFieldsQuery";
import {usePatchTemplateField} from "../../services/hooks/usePatchTemplateField";
import {BackdropSpinner} from "../../atoms/spinner";
import {useEventTriggersQuery} from "../../services/hooks/useEventTriggersQuery";
import {FormWrapper, TemplateFieldCard, TemplateFieldRow} from "./styles";

export const TemplateFields = () => {
  const [templateName, setTemplateName] = useState<string>();
  const [offset, setOffset] = useState(30);

  const {isLoading: isLoadingTemplateNames, data: templateNames = []} =
    useTemplateNamesQuery();
  const {data: templateFields, isLoading: isLoadingTemplateFields} =
    useTemplateFieldsQuery(templateName);
  const {mutate: patchTemplateField, isLoading: isLoadingPatchTemplateField} =
    usePatchTemplateField();
  const {isLoading: isLoadingEventTriggers, data: eventTriggers = []} =
    useEventTriggersQuery({templateName});

  const templateFieldInUse = (templateFieldId: string) => {
    return Array.isArray(eventTriggers)
      ? eventTriggers
          .map(eventTrigger => eventTrigger.template_field.id)
          .includes(templateFieldId)
      : eventTriggers.id === templateFieldId;
  };

  const initialValues = useMemo(() => {
    return templateFields
      ? templateFields.reduce(
          (accum, templateField) => ({
            ...accum,
            [templateField.id]: templateField.friendly_name,
            [`available-${templateField.id}`]:
              templateField.is_available_in_rule,
          }),
          {templateName},
        )
      : {templateName};
  }, [templateFields, templateName]);

  const currentPage = useMemo(
    () => (templateFields ? templateFields.slice(0, offset) : []),
    [templateFields, offset],
  );

  return (
    <FormWrapper>
      <FormCard title="Template Fields">
        <Formik<Record<string, string | boolean>>
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => undefined}
        >
          {({values, setValues}) => (
            <Form>
              <BackdropSpinner
                active={isLoadingTemplateNames || isLoadingTemplateFields}
              >
                <SelectField
                  options={templateNames.map(template => ({
                    label: template,
                    value: template,
                  }))}
                  label="Template"
                  name="templateName"
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const newTemplateName = event.target.value;
                    setTemplateName(newTemplateName);
                  }}
                />
                {!templateName && (
                  <div style={{marginTop: "20px"}}>
                    <Text fontSize={20}>Select a template to continue.</Text>
                  </div>
                )}
                {currentPage.map(
                  templateField =>
                    !templateField.is_deleted && (
                      <TemplateFieldCard key={templateField.id}>
                        <Paragraph fontSize={18}>
                          {templateField.question_translation_text}
                        </Paragraph>
                        <FieldLabel>
                          {templateField.answer_translation_text}
                        </FieldLabel>
                        <FlexRow>
                          <CheckboxItem
                            label="Is available in rule?"
                            checked={!!values[`available-${templateField.id}`]}
                            onChange={event =>
                              setValues(values => ({
                                ...values,
                                [`available-${templateField.id}`]:
                                  event.target.checked,
                              }))
                            }
                            disabled={
                              isLoadingEventTriggers ||
                              templateFieldInUse(templateField.id)
                            }
                          />
                        </FlexRow>
                        <TemplateFieldRow>
                          <TextField
                            name={templateField.id}
                            label=""
                            disabled={isLoadingPatchTemplateField}
                          />
                          <div>
                            <Button
                              color="primary"
                              onClick={() =>
                                patchTemplateField({
                                  templateFieldId: templateField.id,
                                  friendlyName: values[
                                    templateField.id
                                  ] as string,
                                  isAvailableInRule: values[
                                    `available-${templateField.id}`
                                  ] as boolean,
                                })
                              }
                              disabled={isLoadingPatchTemplateField}
                            >
                              {isLoadingPatchTemplateField
                                ? "Loading..."
                                : "Save"}
                            </Button>
                          </div>
                        </TemplateFieldRow>
                      </TemplateFieldCard>
                    ),
                )}
                {templateFields && offset < templateFields.length ? (
                  <Button onClick={() => setOffset(offset + 30)}>
                    Load more
                  </Button>
                ) : (
                  ""
                )}
              </BackdropSpinner>
            </Form>
          )}
        </Formik>
      </FormCard>
    </FormWrapper>
  );
};
