import {useEffect, useCallback, ReactElement} from "react";
import {connect} from "react-redux";
import {FacilityDto, UserDto} from "@reside/reside-api-app";
import {AppState, select} from "../../store";

declare const window: any;

const buildFacilities = (facilities?: Readonly<FacilityDto[]>) =>
  facilities.reduce(
    (currentList, facility) => `${currentList}${facility.name},`,
    "",
  );

// Componetize this so it's easier to insert to the correct pages, and also get data from the redux store
const Stonly = ({user}: {user: UserDto}): ReactElement => {
  const initStonly = async () => {
    try {
      const {stonlyWidget} = await import("./stonlyscripts");
      window.STONLY_WID = "7addaf56-4298-11ec-83f1-062882f67cfe";
      stonlyWidget(
        window,
        document,
        "script",
        "https://stonly.com/js/widget/v2/",
      );
      window.StonlyWidget("setYOffset", 0);
    } catch (error) {
      throw error;
    }
  };
  const stonlyIdentify = useCallback(async () => {
    try {
      const {stonlyTracking} = await import("./stonlyscripts");

      stonlyTracking(
        window,
        document,
        "script",
        "https://stonly.com/js/tracker/stn.js",
      );
      window.stonlyTrack("init", window.STONLY_WID);
      window.stonlyTrack("identify", user.id, {
        "logged-in": true,
        role: user.role.name.toString(),
        organization: user?.organization?.name ?? "Reside",
        facilities: buildFacilities(user.facilities),
        email: user.email,
      });
    } catch (error) {
      throw error;
    }
  }, [user]);

  useEffect(() => {
    initStonly();

    return () => {
      if (window.StonlyWidget) {
        window.StonlyWidget("closeWidget");
        window.StonlyWidget("setYOffset", -100);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      stonlyIdentify();

      return () => {
        if (window.StonlyWidget && window.stonlyTrack) {
          window.StonlyWidget("closeWidget");
          window.StonlyWidget("setYOffset", -100);
          window.stonlyTrack("identify", null, {
            "logged-in": false,
            role: "",
            organization: "",
            facilities: "",
          });
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

const mapStateToProps = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const StonlyConfig = connect(mapStateToProps)(Stonly);
