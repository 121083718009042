import React, {
  createContext,
  useState,
  useMemo,
  FunctionComponent,
  useContext,
} from "react";
import {AdmissionFromSearch} from "../../../../services/AdmissionsService";

type Context = Readonly<{
  admission?: AdmissionFromSearch;
  openModal: (admission: AdmissionFromSearch) => void;
  closeModal: () => void;
}>;

const ResidentLoginModalContext = createContext<Context>(undefined);

export const ResidentLoginModalProvider: FunctionComponent = ({children}) => {
  const [admission, setAdmission] = useState<AdmissionFromSearch>(undefined);

  const value = useMemo(
    () => ({
      admission,
      openModal: setAdmission,
      closeModal: () => setAdmission(undefined),
    }),
    [admission, setAdmission],
  );

  return (
    <ResidentLoginModalContext.Provider value={value}>
      {children}
    </ResidentLoginModalContext.Provider>
  );
};

export const useResidentLoginModalContext = () =>
  useContext(ResidentLoginModalContext);

export const ResidentLoginModalConsumer = ResidentLoginModalContext.Consumer;
