import React, {ReactNode} from "react";
import {cx} from "@emotion/css";

import "./Spinner.scss";

type Props = Readonly<{
  relative?: boolean;
  backdrop?: boolean;
  icon?: ReactNode;
}>;

export const spinnerIcon = (
  <div className="spinner">
    <div />
    <div />
  </div>
);

export const Spinner = ({relative, backdrop, icon}: Props) => (
  <div
    className={cx("spinner-container", {
      "is-relative": relative,
      "on-backdrop": backdrop,
    })}
  >
    {icon}
  </div>
);

Spinner.defaultProps = {
  icon: spinnerIcon,
};
