import React from "react";
import {
  styled,
  theme,
  Button as BaseButton,
  Icon,
  Paragraph,
  Text,
  FullScreenModal,
  ModalContent,
  ModalFooterButtons,
} from "@reside/ui";

import {Footer} from "../../footer";

type Props = Readonly<{
  onLogout: () => void;
  onClose: () => void;
  isOpen: boolean;
  textSize: number;
}>;

export const PauseOverlayModal = ({
  onLogout,
  onClose,
  isOpen,
  textSize,
}: Props) => {
  return (
    <FullScreenModal isOpen={isOpen} onRequestClose={onClose} noCloseButton>
      {() => (
        <>
          <ModalContent>
            <StyledBox textSize={textSize}>
              <StyledIcon name="pause" color={theme.color.primary} size={62} />
              <Text fontSize={7} fontWeight="bold">
                Pause Admission
              </Text>
              <Paragraph fontSize={4}>
                You chose to pause your Admission. Don’t worry, you can always
                come back to the same stage and finish the remaining sections
                anytime, just Sign In again.
              </Paragraph>
            </StyledBox>
          </ModalContent>

          <ModalFooterButtons>
            <Footer
              hasShadow
              right={
                <Button
                  size="large"
                  color="primary"
                  onClick={onLogout}
                  testId="logout-button"
                  textSize={textSize}
                >
                  Logout
                </Button>
              }
              left={
                <Button
                  outline
                  size="large"
                  color="primary"
                  onClick={onClose}
                  testId="continue-button"
                  textSize={textSize}
                >
                  Continue
                </Button>
              }
            />
          </ModalFooterButtons>
        </>
      )}
    </FullScreenModal>
  );
};

const StyledIcon = styled(Icon)`
  margin-bottom: 20px;
  margin-left: -20px;
`;

const StyledBox = styled.div<{textSize: number}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  padding: 0 ${({theme}) => theme.space[5]}px;

  ${({textSize}) => `font-size: ${textSize * 1}rem;`}

  i.icon-pause {
    font-size: 3.875em;

    &::before {
      ${({textSize}) => `margin-left: ${0.2 / textSize / textSize}em;`}
    }

    ~ span {
      font-size: 3em;
    }

    ~ p {
      font-size: 1.25em;
    }
  }

  ${({theme}) => theme.media.md} {
    padding: 0 ${({theme}) => theme.space[9]}px;
  }
`;

const Button = styled(BaseButton)<{textSize: number}>`
  ${({textSize}) => `
      transform: scale(${(textSize - 1) / 4 + 1});

      &:not([disabled]):hover {
        transform: scale(${(textSize - 1) / 4 + 1.125});
      }
  `}
`;
