import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {get} from "lodash";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {facilityApi} from "../../../services/FacilityService";

type Params = {
  facilityId: string;
  onSuccess: () => void;
};

export const useDeleteFacilityMutation = () =>
  useMutation(
    ({facilityId}: Params) =>
      facilityApi.deleteFacilityUsingDELETE(
        facilityId,
        getAuthorizationHeader(),
      ),
    {
      onError: response => {
        Alert.error(
          get(
            response,
            "data.message",
            "Facility can be deleted only in case there are no admissions left in Pre-Flight, In-Progress, Signed or Approved swim lanes.",
          ),
        );
      },
      onSuccess: (_, {onSuccess}) => {
        onSuccess();
      },
    },
  );
