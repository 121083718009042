import React, {FunctionComponent} from "react";
import {styled, Text} from "@reside/ui";

export const MenuGroup = styled.div`
  margin-bottom: 24px;
`;

export const MenuGroupTitle: FunctionComponent = ({children}) => (
  <MenuGroupTitleContainer>
    <Text fontWeight="bold" fontSize={3} lineHeight="20px">
      {children}
    </Text>
  </MenuGroupTitleContainer>
);

const MenuGroupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
