"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractVisibleAfterAdmissionTemplateNodes = exports.extractVisibleTemplateNodes = exports.extractTemplateSlides = exports.isSignatureReference = exports.isCheckboxReference = exports.isRadioReference = exports.isSelectReference = exports.isListNode = exports.isStaticChoicesNode = exports.isQueryChoicesNode = exports.isBranchNode = exports.isMandatoryVideoNode = exports.isTableOfContentNode = exports.isSlideNode = exports.isPdfContextNode = exports.isResidentExperienceContextNode = exports.getFieldName = exports.blockIsVisible = exports.choiceIsActive = exports.computeChildrenBlock = void 0;
var templateTypes_1 = require("./templateTypes");
var json_logic_1 = require("./json-logic");
/**
 * Recompute block visibility based on form answers.
 */
var computeChildrenBlock = function (_a, forceVisible) {
    var _b;
    var node = _a.node, answers = _a.answers, template = _a.template, _c = _a.computed, computed = _c === void 0 ? { readOnly: false } : _c, _d = _a.preflightedAnswers, preflightedAnswers = _d === void 0 ? {} : _d, parentNode = _a.parentNode;
    if (forceVisible === void 0) { forceVisible = false; }
    var parent = isFieldArray(parentNode) ? parentNode : node;
    var state = {
        visible: isFieldArray(parentNode) || (0, exports.blockIsVisible)(answers, node) || forceVisible,
    };
    if ((0, exports.isListNode)(node)) {
        var items = node.items, listNode = __rest(node, ["items"]);
        return __assign(__assign({}, listNode), { state: state, items: items.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), { children: (_a = item.children) === null || _a === void 0 ? void 0 : _a.map(function (node) {
                        return (0, exports.computeChildrenBlock)({
                            node: node,
                            answers: answers,
                            template: template,
                            computed: computed,
                            preflightedAnswers: preflightedAnswers,
                            parentNode: parent,
                        }, forceVisible);
                    }) }));
            }) });
    }
    if (isFormControl(node)) {
        var refKey = node.reference, formControlNode = __rest(node, ["reference"]);
        var reference_1 = template.references[refKey];
        var fieldName = (0, exports.getFieldName)(reference_1);
        return __assign(__assign({}, formControlNode), { state: __assign(__assign({}, state), { disabled: computed.readOnly ||
                    node.disabled === true ||
                    !!preflightedAnswers[fieldName], preFlight: !!preflightedAnswers[fieldName] }), reference: (0, exports.isCheckboxReference)(reference_1) || (0, exports.isRadioReference)(reference_1)
                ? __assign(__assign({}, reference_1), { choices: (_b = reference_1.choices) === null || _b === void 0 ? void 0 : _b.map(function (choice) {
                        return computeChoiceChildrenBlock({
                            question: reference_1,
                            choice: choice,
                            answers: answers,
                            template: template,
                            computed: computed,
                            preflightedAnswers: preflightedAnswers,
                            parentNode: parent,
                        }, forceVisible);
                    }) }) : reference_1 });
    }
    if ((0, exports.isMandatoryVideoNode)(node)) {
        var refKey = node.reference, video = __rest(node, ["reference"]);
        return __assign(__assign({ state: state }, video), { reference: template.references[refKey] });
    }
    if ((0, exports.isBranchNode)(node)) {
        var children = node.children, branchNode = __rest(node, ["children"]);
        return __assign(__assign({ state: isFieldArray(node)
                ? __assign(__assign({}, state), { disabled: computed.readOnly ||
                        node.disabled === true ||
                        !!preflightedAnswers[(0, exports.getFieldName)(node)], preFlight: !!preflightedAnswers[(0, exports.getFieldName)(node)] }) : state }, branchNode), { children: children.map(function (node) {
                return (0, exports.computeChildrenBlock)({
                    node: node,
                    answers: answers,
                    template: template,
                    computed: computed,
                    preflightedAnswers: preflightedAnswers,
                    parentNode: parent,
                }, forceVisible);
            }) });
    }
    return __assign({ state: state }, node);
};
exports.computeChildrenBlock = computeChildrenBlock;
/**
 * If parent is FieldArray, we make the field visible,
 * because it might be visible for some of the items
 * and we validate only visible fields.
 */
var isFieldArray = function (node) {
    return (node === null || node === void 0 ? void 0 : node.type) === templateTypes_1.NodeType.FIELD_ARRAY;
};
var isFormControl = function (node) {
    return (node === null || node === void 0 ? void 0 : node.type) === templateTypes_1.NodeType.FORM_CONTROL;
};
/**
 * Compute state for leaf fields in choice sub-form.
 * LIMITATION: Only leaf nodes are allowed here.
 */
var computeChoiceChildrenBlock = function (_a, forceVisible) {
    var question = _a.question, answers = _a.answers, choice = _a.choice, template = _a.template, _b = _a.computed, computed = _b === void 0 ? { readOnly: false } : _b, _c = _a.preflightedAnswers, preflightedAnswers = _c === void 0 ? {} : _c, parentNode = _a.parentNode;
    var children = choice.children;
    if (!children) {
        return choice;
    }
    return __assign(__assign({}, choice), { children: children.map(function (node) {
            var _a;
            var visible = isFieldArray(parentNode) ||
                ((0, exports.choiceIsActive)(question, answers, choice) &&
                    (0, exports.blockIsVisible)(answers, node)) ||
                forceVisible;
            var state = {
                visible: visible,
            };
            if ((0, exports.isListNode)(node)) {
                var items = node.items, listNode = __rest(node, ["items"]);
                return __assign(__assign({}, listNode), { state: state, items: visible
                        ? items.map(function (item) {
                            var _a;
                            return (__assign(__assign({}, item), { children: (_a = item.children) === null || _a === void 0 ? void 0 : _a.map(function (node) {
                                    return (0, exports.computeChildrenBlock)({
                                        node: node,
                                        answers: answers,
                                        template: template,
                                        computed: computed,
                                        preflightedAnswers: preflightedAnswers,
                                        parentNode: parentNode,
                                    }, forceVisible);
                                }) }));
                        })
                        : [] });
            }
            if (isFormControl(node)) {
                var refKey = node.reference, formControlNode = __rest(node, ["reference"]);
                var reference_2 = template.references[refKey];
                var fieldName = (0, exports.getFieldName)(reference_2);
                return __assign(__assign({}, formControlNode), { state: __assign(__assign({}, state), { disabled: computed.readOnly ||
                            node.disabled === true ||
                            !!preflightedAnswers[fieldName], preFlight: !!preflightedAnswers[fieldName] }), reference: (0, exports.isCheckboxReference)(reference_2) || (0, exports.isRadioReference)(reference_2)
                        ? __assign(__assign({}, reference_2), { choices: visible
                                ? (_a = reference_2.choices) === null || _a === void 0 ? void 0 : _a.map(function (choice) {
                                    return computeChoiceChildrenBlock({
                                        question: reference_2,
                                        choice: choice,
                                        answers: answers,
                                        template: template,
                                        computed: computed,
                                        preflightedAnswers: preflightedAnswers,
                                        parentNode: parentNode,
                                    }, forceVisible);
                                })
                                : [] }) : reference_2 });
            }
            if ((0, exports.isMandatoryVideoNode)(node)) {
                var refKey = node.reference, video = __rest(node, ["reference"]);
                return __assign(__assign({ state: state }, video), { reference: template.references[refKey] });
            }
            if ((0, exports.isBranchNode)(node)) {
                var children_1 = node.children, branchNode = __rest(node, ["children"]);
                return __assign(__assign({ state: isFieldArray(node)
                        ? __assign(__assign({}, state), { disabled: computed.readOnly ||
                                node.disabled === true ||
                                !!preflightedAnswers[(0, exports.getFieldName)(node)], preFlight: !!preflightedAnswers[(0, exports.getFieldName)(node)] }) : state }, branchNode), { children: visible
                        ? children_1.map(function (node) {
                            return (0, exports.computeChildrenBlock)({
                                node: node,
                                answers: answers,
                                template: template,
                                computed: computed,
                                preflightedAnswers: preflightedAnswers,
                                parentNode: parentNode,
                            }, forceVisible);
                        })
                        : [] });
            }
            return __assign(__assign({}, node), { state: state });
        }) });
};
/**
 * Given question reference field and form values, check if the provided choice is active.
 */
var choiceIsActive = function (question, values, choice) {
    var activeChoices = values[(0, exports.getFieldName)(question)];
    if ((0, exports.isCheckboxReference)(question) && Array.isArray(activeChoices)) {
        return activeChoices.includes((0, exports.getFieldName)(choice));
    }
    else if ((0, exports.isRadioReference)(question) && typeof activeChoices === "string") {
        return activeChoices === (0, exports.getFieldName)(choice);
    }
    else {
        return false;
    }
};
exports.choiceIsActive = choiceIsActive;
var blockIsVisible = function (values, block) {
    if (typeof block.visible === "boolean") {
        return block.visible;
    }
    if (block.visible instanceof Object) {
        return json_logic_1.jsonLogic.apply(block.visible, values);
    }
    return true;
};
exports.blockIsVisible = blockIsVisible;
var getFieldName = function (item) { return item.id; };
exports.getFieldName = getFieldName;
var isResidentExperienceContextNode = function (node) {
    return node.type !== "pdf" &&
        (!node.visibilityContext ||
            node.visibilityContext.includes(templateTypes_1.VisibilityContextType.RE));
};
exports.isResidentExperienceContextNode = isResidentExperienceContextNode;
var isPdfContextNode = function (node) {
    var _a;
    return node.type === "pdf" ||
        ((_a = node.visibilityContext) === null || _a === void 0 ? void 0 : _a.includes(templateTypes_1.VisibilityContextType.PDF));
};
exports.isPdfContextNode = isPdfContextNode;
var isSlideNode = function (node) {
    return node.type === templateTypes_1.GroupNodeType.SLIDE;
};
exports.isSlideNode = isSlideNode;
var isTableOfContentNode = function (node) {
    return node.type === templateTypes_1.NodeType.TABLE_OF_CONTENT;
};
exports.isTableOfContentNode = isTableOfContentNode;
var isMandatoryVideoNode = function (node) {
    return node.type === templateTypes_1.NodeType.MANDATORY_VIDEO;
};
exports.isMandatoryVideoNode = isMandatoryVideoNode;
var isBranchNode = function (node) { return node.children; };
exports.isBranchNode = isBranchNode;
var isQueryChoicesNode = function (node) { return node.query; };
exports.isQueryChoicesNode = isQueryChoicesNode;
var isStaticChoicesNode = function (node) {
    return node.choices;
};
exports.isStaticChoicesNode = isStaticChoicesNode;
var isListNode = function (node) {
    return node.type === templateTypes_1.NodeType.LIST;
};
exports.isListNode = isListNode;
var isSelectReference = function (reference) { return reference.type === templateTypes_1.QuestionType.SELECT; };
exports.isSelectReference = isSelectReference;
var isRadioReference = function (reference) { return reference.type === templateTypes_1.QuestionType.RADIO; };
exports.isRadioReference = isRadioReference;
var isCheckboxReference = function (reference) {
    return reference.type === templateTypes_1.QuestionType.CHECKBOX;
};
exports.isCheckboxReference = isCheckboxReference;
var isSignatureReference = function (reference) { return reference.type === templateTypes_1.QuestionType.SIGNATURE; };
exports.isSignatureReference = isSignatureReference;
var extractTemplateSlides = function (template, answers) {
    return template.children.flatMap(function (sectionGroup) {
        return sectionGroup.children.flatMap(function (section) {
            var isSectionVisible = (0, exports.blockIsVisible)(answers, section);
            return section.children.filter(exports.isSlideNode).flatMap(function (slide) {
                return __assign(__assign({}, slide), { children: slide.children.map(function (node) {
                        return (0, exports.computeChildrenBlock)({
                            node: node,
                            answers: answers,
                            template: template,
                        });
                    }), state: {
                        visible: isSectionVisible && (0, exports.blockIsVisible)(answers, slide),
                    } });
            });
        });
    });
};
exports.extractTemplateSlides = extractTemplateSlides;
/**
 * Get array of visible template nodes - slides or pdf elements.
 */
var extractVisibleTemplateNodes = function (template, values, forceVisible) {
    if (forceVisible === void 0) { forceVisible = false; }
    return template.children.flatMap(function (sectionGroup) {
        return sectionGroup.children.flatMap(function (section) {
            var sectionIsVisible = (0, exports.blockIsVisible)(values, section) || forceVisible;
            return section.children.filter(function (slide) {
                return sectionIsVisible &&
                    !slide.afterAdmission &&
                    ((0, exports.blockIsVisible)(values, slide) || forceVisible);
            });
        });
    });
};
exports.extractVisibleTemplateNodes = extractVisibleTemplateNodes;
/**
 * Get array of visible after admission template nodes - slides or pdf elements.
 */
var extractVisibleAfterAdmissionTemplateNodes = function (template, values, forceVisible) {
    if (forceVisible === void 0) { forceVisible = false; }
    return template.children.flatMap(function (sectionGroup) {
        return sectionGroup.children.flatMap(function (section) {
            var sectionIsVisible = (0, exports.blockIsVisible)(values, section) || forceVisible;
            return section.children.filter(function (slide) {
                return sectionIsVisible &&
                    slide.afterAdmission &&
                    ((0, exports.blockIsVisible)(values, slide) || forceVisible);
            });
        });
    });
};
exports.extractVisibleAfterAdmissionTemplateNodes = extractVisibleAfterAdmissionTemplateNodes;
