import React from "react";
import {TableUserDto} from "@reside/reside-api-app";
import {RouteComponentProps} from "react-router-dom";

import {Spinner} from "../../atoms/spinner";
import {DocumentTitle} from "../../atoms/document-title";
import {Banner} from "../../atoms/banner";
import {RequiredUserPermissionsSome} from "../../atoms/admin-user-permissions/RequiredUserPermissionsSome";
import {useQuicksightCurrentUserDashboards} from "./hooks/useQuicksightCurrentUserDashboards";
import {ReportQuicksightDashboard} from "./reports/ReportQuicksightDashboard";
import {ReportingLayout} from "./reporting-layout";

export const PageReportingDashboards = ({location}: RouteComponentProps) => {
  const {data, isFetched} = useQuicksightCurrentUserDashboards({
    enabled: true,
  });

  const dashboard = data?.find(dashboard =>
    location.hash.endsWith(dashboard.id),
  );

  return (
    <ReportingLayout isActionRowVisible={false}>
      <DocumentTitle title="Reside - Admin - Reporting | Dashboards" />
      <RequiredUserPermissionsSome
        permissions={[TableUserDto.PermissionsEnum.QUICKSIGHT_DASHBOARDS]}
        noPermissionChildren={
          <Banner title="No reports are available for this section.">
            <p>Please choose a different report from the left menu.</p>
          </Banner>
        }
      >
        {!isFetched || !dashboard ? (
          <Spinner />
        ) : (
          <>
            <ReportQuicksightDashboard
              id={dashboard.id}
              name={dashboard.name}
            />
          </>
        )}
      </RequiredUserPermissionsSome>
    </ReportingLayout>
  );
};
