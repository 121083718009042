import React from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooterButtons,
  Text,
} from "@reside/ui";
import {useInactivateResident} from "../hooks/useInactivateResident";

export const InactivationConfirmationModal = ({
  residentId,
  closeModal,
  isModalOpen,
}: {
  residentId: string;
  closeModal: () => void;
  isModalOpen: boolean;
}): JSX.Element => {
  const {mutate: inactivateResident} = useInactivateResident({closeModal});

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      closeButtonPosition="right"
      size="small"
    >
      {({closeModal}) => (
        <>
          <ModalContent>
            <Text style={{fontSize: "32px", marginBottom: "16px"}} as="h1">
              Delete Resident?
            </Text>

            <Text fontSize={20}>
              This action will set the residents status to inactive.
            </Text>
            <Text marginTop="8px" fontSize={20}>
              You can restore them at any time.
            </Text>
          </ModalContent>
          <ModalFooterButtons style={{justifyContent: "space-between"}}>
            <Button color="neutral" outline onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() => inactivateResident(residentId)}
            >
              Delete resident
            </Button>
          </ModalFooterButtons>
        </>
      )}
    </Modal>
  );
};
