"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.explodeFieldArray = exports.getIndexedBlock = void 0;
var templateUtils_1 = require("./templateUtils");
var formUtils_1 = require("./formUtils");
/**
 * Scope the field name (tag) for each field array item by it's index.
 * Compute visibility of nested fields and blocks.
 * LIMITATION: the 'visible' json condition on nested fields can refer only to answers from single field array item.
 */
var getIndexedBlock = function (block, id, index, answers, isChoiceActive) {
    var _a;
    if (isChoiceActive === void 0) { isChoiceActive = true; }
    if ((0, formUtils_1.isFormControlBlock)(block)) {
        var reference_1 = block.reference;
        return __assign(__assign({}, block), { reference: (0, templateUtils_1.isCheckboxReference)(reference_1) || (0, templateUtils_1.isRadioReference)(reference_1)
                ? __assign(__assign({}, reference_1), { id: "".concat(id, ".").concat(index, ".").concat(reference_1.id), choices: (_a = reference_1.choices) === null || _a === void 0 ? void 0 : _a.map(function (choice) {
                        return choice.children
                            ? __assign(__assign({}, choice), { children: choice.children.map(function (block) {
                                    return (0, exports.getIndexedBlock)(block, id, index, answers, (0, templateUtils_1.choiceIsActive)(reference_1, answers, choice));
                                }) }) : choice;
                    }) }) : __assign(__assign({}, reference_1), { id: "".concat(id, ".").concat(index, ".").concat(reference_1.id) }), state: __assign(__assign({}, block.state), { visible: isChoiceActive && (0, templateUtils_1.blockIsVisible)(answers, block) }) });
    }
    return __assign(__assign({}, block), { state: __assign(__assign({}, block.state), { visible: isChoiceActive && (0, templateUtils_1.blockIsVisible)(answers, block) }) });
};
exports.getIndexedBlock = getIndexedBlock;
/**
 * Get indexed blocks for each of the form values.
 */
var explodeFieldArray = function (field, values, allValues) {
    if (values === void 0) { values = []; }
    if (allValues === void 0) { allValues = {}; }
    return values.flatMap(function (item, index) {
        return field.children.map(function (block) {
            return (0, exports.getIndexedBlock)(block, field.id, index, __assign(__assign(__assign({}, item), values), allValues));
        });
    });
};
exports.explodeFieldArray = explodeFieldArray;
