import apisauce, {
  create,
  PROBLEM_CODE,
  CANCEL_ERROR,
  ApiErrorResponse,
} from "apisauce";
import {AxiosRequestConfig, RawAxiosResponseHeaders} from "axios";
import qs from "qs";
import Alert from "react-s-alert";
import {v4} from "uuid";

import {logErrorToSentry} from "../../utils/sentry";
import {isTokenValid, makeAuthorizationHeader} from "../../utils/token";

export type ApiErrorData = Readonly<{
  status: number;
  message: string;
  timestamp: number;
  exception: string;
  path: string;
}>;

const HANDLED_PROBLEMS: PROBLEM_CODE[] = [
  apisauce.CLIENT_ERROR,
  apisauce.SERVER_ERROR,
];

const LOGGED_PROBLEMS: PROBLEM_CODE[] = [
  apisauce.SERVER_ERROR,
  apisauce.CONNECTION_ERROR,
  apisauce.NETWORK_ERROR,
  apisauce.TIMEOUT_ERROR,
  apisauce.UNKNOWN_ERROR,
];

enum AUTH_ERROR_STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

const IGNORED_RESPONSE_STATUSES = [
  400,
  404,
  409,
  412, // Pcc is disabled
  422,
  429, // PccRequestLimitReached
  1400, // offline mode
];

export type Config = {
  getToken: () => Promise<string>;
  setToken: (token: string) => void;
  /**
   * The UNAUTHORIZED status has a common handler, we might want to skip it when some route wants to have custom handling
   */
  skipUnauthorizedHandling?: (config: AxiosRequestConfig) => boolean;
  logout: () => void;
};

export const isUnauthorized = (status: number) =>
  AUTH_ERROR_STATUS.UNAUTHORIZED === status;

let config: Config = null;

export const http = create({
  baseURL:
    process.env.REACT_APP_API_URL || process.env.STORYBOOK_REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: "repeat",
      skipNulls: true,
    }),
});

export function configureHttp(cfg: Config) {
  config = cfg;
}

http.addAsyncRequestTransform(async request => {
  request.headers = request.headers || {};

  if (config) {
    const token = await config.getToken();

    if (token) {
      request.headers.Authorization =
        request.headers.Authorization ?? makeAuthorizationHeader(token);
    }
  }

  request.headers["X-Correlation-ID"] = v4();
});

http.addResponseTransform(response => {
  if (!response.problem) {
    const refreshedToken = getRefreshedToken(response.headers);

    if (isTokenValid(refreshedToken)) {
      config?.setToken(refreshedToken);
    }
  }

  if (LOGGED_PROBLEMS.includes(response.problem)) {
    logErrorToSentry(response.originalError, {response});
  }

  if (
    IGNORED_RESPONSE_STATUSES.includes(response.status) ||
    config?.skipUnauthorizedHandling?.(
      response.config as any as AxiosRequestConfig,
    )
  ) {
    // do nothing
  } else if (isUnauthorized(response.status)) {
    config?.logout();
    Alert.closeAll();
    Alert.info("Your session has expired. Try to login again.");

    throw response;
  } else if (AUTH_ERROR_STATUS.FORBIDDEN === response.status) {
    config?.logout();
  } else {
    if (HANDLED_PROBLEMS.includes(response.problem)) {
      Alert.error(
        "An error has occurred. Please contact your Clerk or Administrator.",
      );
    }
  }

  if (response.problem) {
    throw response;
  }
});

export const requestCancelled = <E = any>(error: ApiErrorResponse<E>) =>
  error.problem === CANCEL_ERROR;

export const getRefreshedToken = (headers: RawAxiosResponseHeaders) => {
  const token = headers?.["x-refreshed-jwt"];

  return typeof token == "string" ? token.split(" ")[1] : null;
};
