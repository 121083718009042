import { useFormikContext } from "formik";
import { useState, useRef, useEffect, } from "react";
import { assignError, clearError, createDateString, getErrorMessage, isValidDay, removeNonNumericCharacters, validateRange, } from "./helpers";
import { getDaysInMonth } from "date-fns";
import { useHandlePreFill } from "./useHandlePreFill";
import { inRange } from "lodash";
export var useHandleSeparateFields = function (name, disabled, range) {
    var _a = useState(""), error = _a[0], setError = _a[1];
    var monthRef = useRef();
    var _b = useState(""), month = _b[0], setMonth = _b[1];
    var dayRef = useRef();
    var _c = useState(""), day = _c[0], setDay = _c[1];
    var yearRef = useRef();
    var _d = useState(""), year = _d[0], setYear = _d[1];
    var _e = useState(false), isKeyBackspace = _e[0], setIsKeyBackspace = _e[1];
    var setFieldValue = useFormikContext().setFieldValue;
    var onPasteDate = useHandlePreFill(name, dayRef, monthRef, yearRef, setDay, setMonth, setYear, disabled).onPasteDate;
    var handleKeyUp = function (event) {
        if (event.key === "Backspace") {
            // Handle backspace key press
            setIsKeyBackspace(true);
        }
    };
    useEffect(function () {
        window.addEventListener("keyup", handleKeyUp);
        return function () { return window.removeEventListener("keyup", handleKeyUp); };
    }, []);
    // Month handlers
    var onChangeMonth = function (e) {
        if (!disabled) {
            e.target.value = removeNonNumericCharacters(e.target.value);
            var value = e.target.value;
            // When user types 51 he get's error. When deletes 1, 5 becomes valid month. That's why we clear errors on each change here.
            clearError(monthRef, setError);
            // This field can take maximum two charaters
            if (value.length > 2)
                return;
            if (value.length === 1 && inRange(Number(value), 2, 10)) {
                e.target.value = value.padStart(2, "0");
                dayRef.current.focus();
            }
            else if ((value.length === 2 && Number(value) > 12) ||
                (value.length === 2 && value === "00")) {
                assignError(monthRef, setError, getErrorMessage("invalid", "month"));
            }
            else if (value.length === 2 && Number(value) <= 12) {
                dayRef.current.focus();
            }
            setMonth(value);
            setFieldValue(name, createDateString(value, day, year));
        }
    };
    var onBlurMonth = function (e) {
        if (error || e.target.value === "0") {
            monthRef.current.focus();
            assignError(monthRef, setError, getErrorMessage("invalid", "month"));
        }
        if (e.target.value.length >= 1) {
            e.target.value = e.target.value.padStart(2, "0");
            var newMonth = month.padStart(2, "0");
            setMonth(newMonth);
            setFieldValue(name, createDateString(newMonth, day, year));
        }
    };
    // Day handlers
    var onChangeDay = function (e) {
        if (!disabled) {
            e.target.value = removeNonNumericCharacters(e.target.value);
            var value = e.target.value;
            clearError(dayRef, setError);
            // This field can take maximum two charaters
            if (value.length > 2)
                return;
            if (value.length === 1 && inRange(Number(value), 4, 10)) {
                e.target.value = value.padStart(2, "0");
                yearRef.current.focus();
            }
            else if ((value.length === 2 && !isValidDay(month, value, year)) ||
                (value.length === 2 && value === "00")) {
                assignError(dayRef, setError, getErrorMessage("invalid", "day"));
            }
            else if (value.length === 2 &&
                getDaysInMonth(Number(month)) >= Number(value)) {
                yearRef.current.focus();
            }
            setDay(value);
            setFieldValue(name, createDateString(month, value, year));
        }
    };
    var onBlurDay = function (e) {
        if (error || e.target.value === "0") {
            assignError(dayRef, setError, getErrorMessage("invalid", "day"));
            dayRef.current.focus();
        }
        if (e.target.value.length >= 1) {
            e.target.value = e.target.value.padStart(2, "0");
            var newDay = day.padStart(2, "0");
            setDay(newDay);
            setFieldValue(name, createDateString(month, newDay, year));
        }
    };
    // Year handlers
    var onChangeYear = function (e) {
        if (!disabled) {
            e.target.value = removeNonNumericCharacters(e.target.value);
            var value = e.target.value;
            var currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
            var twoDigitYear = parseInt(value, 10);
            setIsKeyBackspace(false);
            clearError(yearRef, setError);
            clearError(monthRef, setError);
            clearError(dayRef, setError);
            // This field can take maximum four charaters
            if (value.length > 4)
                return;
            // Whenever user types 2 digits in the year field we will try to pre-fill the year. 08 will become 2008, 90 -> 1990 etc. If 19 or 20 is typed we want to avoid prefil to avoid past century dates always evaulating to 2019 and current century always evaluating to 2020.
            if (value.length === 2 &&
                !isKeyBackspace &&
                value !== "19" &&
                value !== "20") {
                if (range) {
                    if (twoDigitYear <= currentYear ||
                        inRange(parseInt(value), 0, currentYear)) {
                        e.target.value = e.target.value.padStart(4, "20"); // set value of separate input field so that user can see it
                        value = e.target.value.padStart(4, "20"); // set value of field which get submited in form
                        var newYear = year.padStart(4, "20");
                        setYear(newYear);
                        setFieldValue(name, createDateString(month, day, newYear));
                    }
                    else {
                        e.target.value = e.target.value.padStart(4, "19");
                        value = e.target.value.padStart(4, "20");
                        var newYear = year.padStart(4, "19");
                        setYear(newYear);
                        setFieldValue(name, createDateString(month, day, newYear));
                    }
                }
                else {
                    e.target.value = e.target.value.padStart(4, "20");
                    value = e.target.value.padStart(4, "20");
                    var newYear = year.padStart(4, "20");
                    setYear(newYear);
                    setFieldValue(name, createDateString(month, day, newYear));
                }
            }
            // If user enters 02/29 before year, we need to check if year he eneters is actually leap year.
            if ((value.length === 4 && value === "0000") ||
                (value.length === 3 && value === "000")) {
                assignError(yearRef, setError, getErrorMessage("invalid", "year"));
            }
            else if (value.length === 4 && isValidDay(month, day, value)) {
                var _a = validateRange(new Date(createDateString(month, day, value)), range), isAfter = _a.isAfter, isBefore = _a.isBefore;
                if (!(isBefore || isAfter)) {
                    clearError(dayRef, setError);
                }
            }
            else if (value.length === 4 &&
                day.length === 2 &&
                !isValidDay(month, day, value)) {
                assignError(dayRef, setError, getErrorMessage("invalid", "day"));
            }
            setYear(value);
            setFieldValue(name, createDateString(month, day, value));
        }
    };
    var onBlurYear = function (e) {
        if (error) {
            yearRef.current.focus();
        }
        if (e.target.value.length === 3 ||
            e.target.value.length === 2 ||
            e.target.value.length === 1) {
            assignError(yearRef, setError, getErrorMessage("invalid", "year"));
            yearRef.current.focus();
        }
        setIsKeyBackspace(false);
    };
    return {
        onPasteDate: onPasteDate,
        onChangeMonth: onChangeMonth,
        onBlurMonth: onBlurMonth,
        onChangeDay: onChangeDay,
        onBlurDay: onBlurDay,
        onChangeYear: onChangeYear,
        onBlurYear: onBlurYear,
        month: month,
        monthRef: monthRef,
        day: day,
        dayRef: dayRef,
        year: year,
        yearRef: yearRef,
        error: error,
    };
};
